// libs
import {
  BrowserRouter as Router,
  Route,
  useParams,
  Routes,
} from "react-router-dom";
import { useState, useCallback, useEffect, lazy, Suspense } from "react";
import {
  MdAttachMoney,
  MdCalendarMonth,
  MdCheck,
  MdCheckCircle,
  MdDeleteForever,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdKeyboardArrowDown,
  MdLabelImportantOutline,
  MdMonetizationOn,
  MdOutlineAccountTree,
  MdOutlineAreaChart,
  MdOutlineCollectionsBookmark,
  MdOutlineHome,
  MdOutlineMenuBook,
  MdOutlinePersonPinCircle,
  MdOutlineReceipt,
  MdOutlineThumbsUpDown,
  MdOutlineTimelapse,
  MdPerson,
  MdPhone,
} from "react-icons/md";
import { LuFileText, LuClock4 } from "react-icons/lu";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BiCalendarEvent } from "react-icons/bi";
import { HiBellAlert } from "react-icons/hi2";
import { RiCheckDoubleLine } from "react-icons/ri";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import {
  CardMembership,
  IntegrationInstructions,
  LocationCityRounded,
  SettingsApplications,
} from "@mui/icons-material";
import { PiReceiptX } from "react-icons/pi";
import { FaRegClock, FaTruck } from "react-icons/fa";
import { RiDraftLine } from "react-icons/ri";
import { SiQuickbooks } from "react-icons/si";

// local
import SideNav, { NavItem, SidebarDataType } from "../components/SideNav";
import TopNav from "../components/TopNav";
import { RouteAuthRequired } from "../components/RouteAuthRequired";
import CustomerListContainer from "../Pages/Customers/CustomerListContainer";
import PriceBookItemListContainer from "../Pages/Estimates/PriceBookItemListContainer";
// import SchedulingContainer from "../Pages/Scheduling/SchedulingContainer";
import EstimateListContainer from "../Pages/Estimates/EstimateListContainer";
import InvoiceListContainer from "../Pages/Invoices/InvoiceListContainer";
// import WorkRecordListContainer from "../Pages/CraftList/WorkRecordListContainer";
import ReportsContainer from "./Reports/ReportsContainer";
import LocationListContainer from "./Admin/LocationsListContainer";
import CompaniesListContainer from "./Admin/CompaniesListContainer";
import UserManagementContainer from "./Admin/UserManagementContainer";
import UserSettings from "../Pages/UserSettings";
import { useAuthStore } from "../store/firebase-auth";
import { RouteAdminPermission } from "../components/RouteAdminPermission";
import * as urls from "../urls";
import CustomFieldsContainer from "./Admin/CustomFieldsContainer";
import SiteDetailsContainer from "./Admin/SiteDetailsContainer";
import MySitesContainer from "./Admin/MySitesContainer";
import EditUserContainer from "./Admin/EditUserContainer";
import AddUserContainer from "./Admin/AddUserContainer";
import EditChecklistContainer from "./EditChecklistContainer";
import TemplateDetailsContainer from "./TemplateDetailsContainer";
import TasksForTemplateContainer from "./TasksForTemplateContainer";
import EditItemContainer from "./EditItemContainer";
import ListTemplatesContainer from "./ListTemplatesContainer";
import NewTemplateContainer from "./NewTemplateContainer";
import AllChecklistsTasksContainer from "./AllChecklistsTasksContainer";
import TaskDetailsContainer from "./TaskDetailsContainer";
import CreateSiteContainer from "./CreateSite/CreateSiteContainer";
import WorkRecordAndTasksContainer from "./WorkRecordAndTasks/WorkRecordAndTasksContainer";
import CreateEstimateContainer from "./Estimates/CreateEstimateContainer";
import ViewEstimateContainer from "./Estimates/ViewEstimateContainer";
import NonAuthUserViewEstimateContainer from "./Estimates/NonAuthUserViewEstimateContainer";
// import CreateTaskForCustomerContainer from "./Customers/CreateTaskForCustomerContainer";
import ShowSingleCustomerContainer from "./Customers/ShowSingleCustomerContainer";
import PaymentsContainer from "./Payments/PaymentsContainer";
import PaymentsSelfCheckoutContainer from "./PaymentsSelfCheckout/PaymentsSelfCheckoutContainer";
import InventoryObjectListContainer from "./Inventory/InventoryObjectListContainer";
import InventoryTransactionListContainer from "./Inventory/InventoryTransactionListContainer";
// import DeletedWorkRecordListContainer from "./CraftList/DeletedWorkRecordListContainer";
// import TaskListContainer from "./TaskList/TaskListContainer";
// import DeletedTaskListContainer from "./TaskList/DeletedTaskListContainer";
import ComplianceContainer from "./Compliance/ComplianceContainer";
import FeedbackListContainer from "./Feedback/FeedbackListContainer";
import NewUserContainer from "./NewUserContainer";
import ApplyToExistingSiteContainer from "./ApplyToExistingSiteContainer";
import ReviewRequestContainer from "./Feedback/ReviewRequestContainer";
import CreateAccountContainer from "./CreateAccountContainer";
import Dashboard from "./Dashboard";
import Help from "./Help";
import LoginPage from "./LoginPage";
import AutoLogin from "./AutoLoginPage";
import ForgotPassword from "./ForgotPasswordPage";
import NotFound404 from "./NotFound404";
import Navbar from "../components/Navbar";
import { useSiteKeyDocStore } from "../store/site-key-doc";
import { ExistingSiteKey } from "../models/site-key";
import { CraftTypes, getReadableCraftType } from "../models/craft-types";
import WorkTypeIcon from "../assets/js/WorkTypeIcon";
import { TaskStatus } from "../models/task-status";
import { TaskTypes } from "../models/task-types";
import ReportSpecPage from "./Reports/ReportSpecPage";
// import MembershipsContainer from "./Memberships/MembershipsContainer";
import AssetsEquipmentContainer from "./AssetsEquipment/AssetsEquipmentContainer";
import PaymentListContainer from "./Payments/PaymentListContainer";
import MembershipTemplateListContainer from "./Memberships/MembershipTemplateListContainer";
import AddEditMembershipTemplateContainer from "./Memberships/AddEditMembershipTemplateContainer";
import QuickbooksContainer from "./Quickbooks/QuickbooksContainer";
import ErrorBoundary from "../components/ErrorBoundary";
import LoadingClipboardAnimation from "../components/LoadingClipBoardAnimation";
import MembershipDetailsContainer from "./Memberships/MembershipDetailsContainer";
import PriceBookItemCategoriesListContainer from "./Estimates/PriceBookItemCategoriesListContainer";
import GLAccountListContainer from "./GLAccounts/GLAccountListContainer";
import { BsFillGearFill } from "react-icons/bs";
import { TbRotate360 } from "react-icons/tb";
import InventoryLocationsListContainer from "./Inventory/InventoryLocationsListContainer";
import LightspeedContainer from "./Lightspeed/LightspeedContainer";

const LoadingComponentForPages = () => (
  <div className="flex h-full flex-auto flex-col items-center justify-center">
    <LoadingClipboardAnimation />
  </div>
);

// #region LAZY LOADED ROUTE CONTAINERS
// Lazy loading large page components. Also preloading for slightly improved
// user experience.
const promiseDeletedWorkRecordListContainer = import(
  "./CraftList/DeletedWorkRecordListContainer"
);
const LazyDeletedWorkRecordListContainer = lazy(
  () => promiseDeletedWorkRecordListContainer,
);

const promiseDeletedTaskListContainer = import(
  "./TaskList/DeletedTaskListContainer"
);
const LazyDeletedTaskListContainer = lazy(
  () => promiseDeletedTaskListContainer,
);

const promiseTaskListContainer = import("./TaskList/TaskListContainer");
const LazyTaskListContainer = lazy(() => promiseTaskListContainer);

const promiseWorkRecordListContainer = import(
  "./CraftList/WorkRecordListContainer"
);
const LazyWorkRecordListContainer = lazy(() => promiseWorkRecordListContainer);

const promiseMembershipsContainer = import(
  "./Memberships/MembershipsListContainer"
);
const LazyMembershipsContainer = lazy(() => promiseMembershipsContainer);

const promiseMembershipIssuesContainer = import(
  "./Memberships/MembershipIssuesContainer"
);
const LazyMembershipIssuesContainer = lazy(
  () => promiseMembershipIssuesContainer,
);

const promiseCreateTaskForCustomerContainer = import(
  "./Customers/CreateTaskForCustomerContainer"
);
const LazyCreateTaskForCustomerContainer = lazy(
  () => promiseCreateTaskForCustomerContainer,
);

const promiseSchedulingContainer = import("./Scheduling/SchedulingContainer");
const LazySchedulingContainer = lazy(() => promiseSchedulingContainer);

const promiseVehiclesContainer = import("./Vehicles/VehicleListContainer");
const LazyVehiclesContainer = lazy(() => promiseVehiclesContainer);

const promiseZonesContainer = import("./Zones/ZoneListContainer");
const LazyZonesContainer = lazy(() => promiseZonesContainer);

const promiseCallsListContainer = import("./Calls/CallsListContainer");
const LazyCallsListContainer = lazy(() => promiseCallsListContainer);

const promiseCommissionsListContainer = import(
  "./Commissions/CommissionsListContainer"
);
const LazyCommissionsListContainer = lazy(
  () => promiseCommissionsListContainer,
);

const promiseCallsEditContainer = import("./Calls/CallsEditContainer");
const LazyCallsEditContainer = lazy(() => promiseCallsEditContainer);

const promiseReportBuilderContainer = import("./ReportBuilderContainer");
const LazyReportBuilderContainer = lazy(() => promiseReportBuilderContainer);

const promiseKpisContainer = import("./KPIs/KPIsContainer");
const LazyKpisContainer = lazy(() => promiseKpisContainer);

// #endregion

interface ContainerWithKeyProps {
  siteKey: string;
}

type WorkRecordListParams = {
  craftTypeValue: string;
};

type TaskListParams = {
  taskParams: string;
};

type EstimateListParams = {
  estimateStatusParams: string;
};

// Wrapper to add key prop to WorkRecordListContainer, to trigger a re-render when the component is already mounted.
const WorkRecordListContainerWithKey: React.FC<ContainerWithKeyProps> = ({
  siteKey,
}) => {
  const { craftTypeValue } = useParams<WorkRecordListParams>();

  return (
    <Suspense fallback={<LoadingComponentForPages />}>
      <LazyWorkRecordListContainer siteKey={siteKey} key={craftTypeValue} />
    </Suspense>
  );
};
// Wrapper to add key prop to TaskListContainer, to trigger a re-render when the component is already mounted.
const TaskListContainerWithKey: React.FC<ContainerWithKeyProps> = ({
  siteKey,
}) => {
  const { taskParams } = useParams<TaskListParams>();

  return (
    <Suspense fallback={<LoadingComponentForPages />}>
      <LazyTaskListContainer siteKey={siteKey} key={taskParams} />
    </Suspense>
  );
};

// Wrapper to add key prop to EstimateListContainer, to trigger a re-render when the component is already mounted.
const EstimateListContainerWithKey: React.FC<ContainerWithKeyProps> = ({
  siteKey,
}) => {
  const { estimateStatusParams } = useParams<EstimateListParams>();

  console.log(estimateStatusParams);
  return <EstimateListContainer siteKey={siteKey} key={estimateStatusParams} />;
};

// FEATURE FLAGS
const FEATURE_FLAG_NEW_USER =
  import.meta.env.VITE_APP_FIREBASE_PROJECT !== "scaffoldtracker";
const FEATURE_FLAG_HELP_SECTION =
  import.meta.env.VITE_APP_FIREBASE_PROJECT !== "scaffoldtracker";
const FEATURE_FLAG_APPLY_TO_EXISTING_SITE =
  import.meta.env.VITE_APP_FIREBASE_PROJECT !== "scaffoldtracker";
const FEATURE_FLAG_CREATE_ACCOUNT =
  import.meta.env.VITE_APP_FIREBASE_PROJECT !== "scaffoldtracker";
const FEATURE_FLAG_CREATE_TRIAL_ACCOUNT =
  import.meta.env.VITE_APP_FIREBASE_PROJECT !== "scaffoldtracker";
const FEATURE_FLAG_REPORT_BUILDER =
  import.meta.env.VITE_APP_FIREBASE_PROJECT !== "scaffoldtracker";

function getSidebarData(siteKeyDoc: ExistingSiteKey): SidebarDataType {
  const sideBarData: NavItem[] = [];
  sideBarData.push(
    {
      title: "Dashboard",
      path: urls.HOME_URL,
      icon: <MdOutlineHome />,
      id: "dashboard",
    },
    {
      title: "Customers",
      path: urls.CUSTOMERS_URL,
      icon: <MdOutlinePersonPinCircle />,
      id: "customers",
    },
    {
      title: "Price Book",
      path: urls.PRICEBOOK_URL,
      icon: <MdOutlineMenuBook />,
      id: "price-book",
    },
  );
  // WORK RECORDS
  const jobsSubNavItems = [];
  jobsSubNavItems.push({
    title: "All",
    path: `${urls.WORK_RECORD_LIST_URL}/all`,
    icon: <MdOutlineAccountTree />,
    id: "jobs-all",
  });
  for (const craftType of siteKeyDoc.validCraftTypes) {
    const craftId = craftType.toString();
    if (craftType !== CraftTypes.CHECKLISTS)
      jobsSubNavItems.push({
        title: getReadableCraftType(craftType),
        path: `${urls.WORK_RECORD_LIST_URL}/${craftType.toString()}`,
        icon: <WorkTypeIcon craftType={craftType} size="24" />,
        id: craftId,
      });
  }
  jobsSubNavItems.push(
    {
      title: "Closed",
      path: `${urls.WORK_RECORD_LIST_URL}/closed`,
      icon: <AiOutlineCloseCircle />,
      id: "closed-jobs",
    },
    {
      title: "Deleted",
      path: `${urls.DELETED_WORK_RECORD_LIST_URL}`,
      icon: <MdDeleteForever />,
      id: "deleted-jobs",
    },
  );
  sideBarData.push({
    title: [<span>Jobs</span>, <MdKeyboardArrowDown className="text-xl" />],
    icon: <MdOutlineAccountTree />,
    subNav: jobsSubNavItems,
    id: "jobs-menu",
  });
  // TASKS
  const tasksSubNavItems = [];
  tasksSubNavItems.push({
    title: "All Open Tasks",
    path: `${urls.TASK_LIST_URL}/All`,
    icon: <MdFormatListBulleted />,
    id: "all-open-tasks",
  });
  if (siteKeyDoc.customizations.assignedToEnabled === true) {
    tasksSubNavItems.push({
      title: "Assigned To Me",
      path: `${urls.TASK_LIST_URL}/Assigned To Me`,
      icon: <MdPerson />,
      id: "my-assigned-tasks",
    });
  }
  if (
    siteKeyDoc.validTaskStatusCodes.includes(TaskStatus.AWAITING_ESTIMATE) &&
    siteKeyDoc.validTaskStatusCodes.includes(TaskStatus.AWAITING_APPROVAL)
  ) {
    tasksSubNavItems.push({
      title: "Estimate/Approval",
      path: `${urls.TASK_LIST_URL}/Estimate Approval`,
      icon: <MdOutlineTimelapse />,
      id: "estimate-approval-tasks",
    });
  }
  if (siteKeyDoc.validTaskStatusCodes.includes(TaskStatus.AWAITING_SCHEDULE)) {
    tasksSubNavItems.push({
      title: "Awaiting Schedule",
      path: `${urls.TASK_LIST_URL}/Awaiting Schedule`,
      icon: <MdOutlineTimelapse />,
      id: "awaiting-schedule-tasks",
    });
  }
  if (siteKeyDoc.validTaskStatusCodes.includes(TaskStatus.AWAITING_REVIEW)) {
    tasksSubNavItems.push({
      title: "Awaiting Review",
      path: `${urls.TASK_LIST_URL}/Awaiting Review`,
      icon: <MdOutlineTimelapse />,
      id: "awaiting-review-tasks",
    });
  }
  if (siteKeyDoc.validTaskStatusCodes.includes(TaskStatus.AWAITING_PARTS)) {
    tasksSubNavItems.push({
      title: "Awaiting Parts",
      path: `${urls.TASK_LIST_URL}/Awaiting Parts`,
      icon: <MdOutlineTimelapse />,
      id: "awaiting-parts-tasks",
    });
  }
  tasksSubNavItems.push(
    {
      title: "Scheduled",
      path: `${urls.TASK_LIST_URL}/Scheduled`,
      icon: <BiCalendarEvent />,
      id: "scheduled-tasks",
    },
    {
      title: "In Progress",
      path: `${urls.TASK_LIST_URL}/In Progress`,
      icon: <LuClock4 />,
      id: "in-progress-tasks",
    },
    {
      title: "Urgent",
      path: `${urls.TASK_LIST_URL}/Urgent`,
      icon: <HiBellAlert />,
      id: "urgent-tasks",
    },
    {
      title: "Backlog",
      path: `${urls.TASK_LIST_URL}/Backlog`,
      icon: <MdOutlineTimelapse />,
      id: "backlog-tasks",
    },
  );
  if (siteKeyDoc.validTaskTypes.includes(TaskTypes.INSPECTION)) {
    tasksSubNavItems.push({
      title: "Inspections",
      path: `${urls.TASK_LIST_URL}/Inspection`,
      icon: <MdCheckCircle />,
      id: "inspections",
    });
  }
  if (siteKeyDoc.customizations.membershipsEnabled) {
    tasksSubNavItems.push({
      title: "Membership Tasks",
      path: `${urls.TASK_LIST_URL}/Memberships`,
      icon: <MdOutlineCollectionsBookmark />,
      id: "membership-tasks",
    });
  }
  tasksSubNavItems.push(
    {
      title: "Completed",
      path: `${urls.TASK_LIST_URL}/Completed`,
      icon: <RiCheckDoubleLine />,
      id: "completed-jobs",
    },
    {
      title: "Deleted",
      path: `${urls.DELETED_TASK_LIST_URL}`,
      icon: <AiOutlineCloseCircle />,
      id: "deleted-tasks",
    },
  );
  if (siteKeyDoc.validTaskStatusCodes.includes(TaskStatus.CANCELED)) {
    tasksSubNavItems.push({
      title: "Canceled",
      path: `${urls.TASK_LIST_URL}/Canceled`,
      icon: <AiOutlineCloseCircle />,
      id: "canceled-tasks",
    });
  }
  sideBarData.push({
    title: [<span>Tasks</span>, <MdKeyboardArrowDown className="text-xl" />],
    icon: <MdFormatListBulleted />,
    subNav: tasksSubNavItems,
    id: "tasks-menu",
  });
  if (siteKeyDoc.customizations.estimatesEnabled) {
    //ESTIMATES
    const estimateSubNavItems = [];
    estimateSubNavItems.push(
      {
        title: "All Estimates",
        path: `${urls.ESTIMATES_URL_AUTH}/All`,
        icon: <MdOutlineReceipt />,
        id: "all-estimates",
      },
      {
        title: "Approved",
        path: `${urls.ESTIMATES_URL_AUTH}/Approved`,
        icon: <MdCheck />,
        id: "approved-estimates",
      },
      {
        title: "Awaiting Approval",
        path: `${urls.ESTIMATES_URL_AUTH}/Awaiting Approval`,
        icon: <FaRegClock />,
        id: "awaiting-approval-estimates",
      },
      {
        title: "Draft",
        path: `${urls.ESTIMATES_URL_AUTH}/Draft`,
        icon: <RiDraftLine />,
        id: "draft-estimates",
      },
      {
        title: "Rejected",
        path: `${urls.ESTIMATES_URL_AUTH}/Rejected`,
        icon: <PiReceiptX />,
        id: "rejected-estimates",
      },
    );
    sideBarData.push({
      title: [
        <span>Estimates</span>,
        <MdKeyboardArrowDown className="text-xl" />,
      ],
      icon: <MdOutlineReceipt />,
      subNav: estimateSubNavItems,
      id: "estimates-menu",
    });
  }
  if (siteKeyDoc.customizations.invoicesEnabled) {
    sideBarData.push({
      title: "Invoices",
      path: urls.INVOICES_URL,
      icon: <LiaFileInvoiceDollarSolid />,
      id: "invoices",
    });
  }

  if (siteKeyDoc.customizations.invoicesEnabled) {
    sideBarData.push({
      title: "Payments",
      path: urls.PAYMENTS_URL,
      icon: <MdAttachMoney />,
      id: "payments",
    });
  }
  //   if (siteKeyDoc.customizations.kpisEnabled) {
  //   sideBarData.push({
  //     title: "KPIs",
  //     path: urls.KPIS_URL,
  //     icon: <MdOutlineInsights />,
  //     id: "kpis",
  //   });
  // }
  if (siteKeyDoc.customizations.schedulingDispatchEnabled) {
    sideBarData.push({
      title: "Scheduling",
      path: urls.SCHEDULING_URL,
      icon: <MdCalendarMonth />,
      id: "scheduling",
    });
  }
  if (siteKeyDoc.customizations.vehiclesEnabled === true) {
    sideBarData.push({
      title: "Vehicles",
      path: urls.VEHICLES_URL,
      icon: <FaTruck />,
      id: "vehicles",
    });
  }
  sideBarData.push(
    // {
    //   title: "Usage Metrics",
    //   path: urls.USAGE_METRICS_URL,
    //   icon: <MdOutlineBarChart />,
    //   id: "usage-metrics",
    // },
    {
      title: "Reports",
      path: urls.REPORTS_URL,
      icon: <LuFileText />,
      id: "reports",
    },
  );
  // {
  //   title: "Assigned Tasks",
  //   path: urls.ASSIGNED_TASKS_URL,
  //   icon: <MdAssignmentInd />,
  //   id: "assigned-tasks",
  // },
  if (siteKeyDoc.validCraftTypes.includes(CraftTypes.CHECKLISTS)) {
    sideBarData.push({
      title: [
        <span>Checklists</span>,
        <MdKeyboardArrowDown className="text-xl" />,
      ],
      icon: <MdFormatListNumbered />,
      subNav: [
        {
          title: "Templates",
          path: `${urls.TEMPLATES_URL}`,
          icon: <RiCheckDoubleLine />,
          id: "checklist-templates",
        },
        {
          title: "Tasks",
          path: `${urls.CHECKLISTS_URL}`,
          icon: <MdFormatListBulleted />,
          id: "checklist-tasks",
        },
      ],
      id: "checklists",
    });
  }
  if (siteKeyDoc.customizations.membershipsEnabled) {
    const membershipSubNavItems = [];
    membershipSubNavItems.push(
      {
        title: "Templates",
        path: urls.MEMBERSHIP_TEMPLATES,
        id: "membership-templates",
        icon: <MdOutlineCollectionsBookmark />,
      },
      {
        title: "Memberships",
        path: urls.MEMBERSHIPS,
        id: "memberships",
        icon: <CardMembership />,
      },
      {
        title: "Membership Issues",
        path: urls.MEMBERSHIP_ISSUES,
        id: "membership-issues",
        icon: <MdLabelImportantOutline />, // feel free to change this lol
      },
    );
    sideBarData.push({
      title: [
        <span>Memberships</span>,
        <MdKeyboardArrowDown className="text-xl" />,
      ],
      id: "memberships-menu",
      subNav: membershipSubNavItems,
      icon: <CardMembership />,
    });
  }

  if (siteKeyDoc.customizations.inventoryEnabled) {
    const inventorySubNavItems = [];
    inventorySubNavItems.push(
      {
        title: "Objects",
        path: urls.INVENTORY_OBJECTS_URL,
        id: "inventory-objects",
        icon: <BsFillGearFill />,
      },
      {
        title: "Transactions",
        path: urls.INVENTORY_TRANSACTIONS_URL,
        id: "inventory-transactions",
        icon: <TbRotate360 />,
      },
      {
        title: "Locations",
        path: urls.INVENTORY_LOCATIONS_URL,
        id: "inventory-locations",
        icon: <LocationCityRounded />,
      },
    );
    sideBarData.push({
      title: [
        <span>Inventory</span>,
        <MdKeyboardArrowDown className="text-xl" />,
      ],
      id: "inventory-menu",
      subNav: inventorySubNavItems,
      icon: <BsFillGearFill />,
    });
  }

  if (siteKeyDoc.customizations.assetsEnabled) {
    sideBarData.push({
      title: "Assets/Equipment",
      path: urls.ASSETS,
      id: "assets",
      icon: <SettingsApplications />,
    });
  }
  if (siteKeyDoc.customizations.feedbackEnabled) {
    sideBarData.push({
      title: "Feedback",
      path: urls.FEEDBACK,
      icon: <MdOutlineThumbsUpDown />,
      id: "feedback",
    });
  }
  if (siteKeyDoc.customizations.commissions) {
    sideBarData.push({
      title: "Commissions",
      path: urls.COMMISSIONS_LIST_URL,
      icon: <MdMonetizationOn />,
      id: "commissions",
    });
  }
  if (
    siteKeyDoc.customizations.accounting?.pushToCodat ||
    siteKeyDoc.customizations.accounting?.pushToConductor ||
    siteKeyDoc.customizations.accounting?.pushToQBO
  ) {
    sideBarData.push({
      title: "QuickBooks",
      path: urls.QUICKBOOKS_URL,
      icon: <SiQuickbooks />,
      id: "quickbooks",
    });
  }
  if (siteKeyDoc.customizations.accounting?.lightspeedKey) {
    sideBarData.push({
      title: "Lightspeed",
      path: urls.LIGHTSPEED_URL,
      icon: <IntegrationInstructions />,
      id: "lightspeed",
    });
  }

  /* CALLS */
  if (siteKeyDoc.customizations.voip) {
    sideBarData.push({
      title: "Calls",
      path: urls.CALLS_LIST_URL,
      icon: <MdPhone />,
      id: "calls",
    });
  }

  if (
    FEATURE_FLAG_REPORT_BUILDER &&
    useSiteKeyDocStore.getState().flags.reportBuilderEnabled()
  ) {
    sideBarData.push({
      title: "Report Builder",
      path: urls.REPORT_BUILDER_URL,
      icon: <MdOutlineAreaChart />,
      id: "report-builder",
    });
  }

  if (useSiteKeyDocStore.getState().flags.reportTemplatesEnabled()) {
    sideBarData.push({
      title: "KPIs",
      path: urls.REPORT_TEMPLATES_URL,
      icon: <MdOutlineAreaChart />,
      id: "report-templates",
    });
  }

  // sideBarData.push({
  //   title: "GL Accounts",
  //   path: urls.GL_ACCOUNTS_URL,
  //   icon: <MdOutlineMenuBook />,
  //   id: "gl-accounts",
  // });

  return sideBarData;
}

let sidebarData: SidebarDataType = [
  {
    title: "Dashboard",
    path: urls.HOME_URL,
    icon: <MdOutlineHome />,
    id: "dashboard-init",
  },
];

interface Props {
  defaultSiteKey: string | undefined;
  showChecklistsNav: boolean;
}

/**
 * High-level component simimlar to App.tsx. Contains the newer React-only
 * layouts instead of using iframes to provide views inside the previous
 * Vue-based application.
 */
export default function ReactAppHome2(props: Props): JSX.Element {
  const [sidebar, setSidebar] = useState(
    typeof window !== "undefined" &&
      window.matchMedia("(min-width: 1280px)").matches,
  );

  const firebaseUser = useAuthStore((state) => state.firebaseUser);

  const showSidebar = useCallback(
    () => setSidebar((prevSidebar) => !prevSidebar),
    [],
  );

  const defaultSiteKey = props.defaultSiteKey;

  const [siteKeyDoc, flags] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.flags,
  ]);

  if (siteKeyDoc) {
    sidebarData = getSidebarData(siteKeyDoc);
  }

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1280px)");
    const handleResize = () => {
      setSidebar(mediaQuery.matches);
    };

    handleResize();

    mediaQuery.addEventListener("change", handleResize);

    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  /* KEYBOARD SHORTCUTS */
  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (e.key === "b" && (e.ctrlKey || e.metaKey)) showSidebar();
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showSidebar]);

  return (
    <div className="flex h-screen flex-col">
      <Router>
        {firebaseUser ? <TopNav showSidebar={showSidebar} /> : <></>}
        <div className="flex flex-grow flex-row">
          {firebaseUser ? (
            <SideNav
              className={"h-full " + (sidebar ? "w-72" : "")}
              sidebarData={sidebarData}
              sidebar={sidebar}
              siteKey={siteKeyDoc?.id}
            />
          ) : (
            <></>
          )}
          <div
            className={
              "w-full flex-grow items-center justify-center py-10 " +
              (firebaseUser && sidebar ? " pl-80 " : " ") +
              " sm:px-8"
            }
          >
            {/* Main Content Area */}
            <div className="mt-10">
              {/* TODO: once we stop using the Navbar component in other places,
              get rid of the wrapping div ^ and adjust the existing margin that's
              on the nav element (within Navbar) */}
              <Navbar showNav={props.showChecklistsNav} />
            </div>
            <ErrorBoundary>
              <Routes>
                {/* Start Admin Routes */}
                <Route
                  path={urls.ADMIN_LIST_LOCATIONS_URL}
                  element={
                    <RouteAdminPermission defaultSiteKey={defaultSiteKey}>
                      {typeof defaultSiteKey === "string" ? (
                        <LocationListContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAdminPermission>
                  }
                />
                <Route
                  path={urls.ADMIN_LIST_COMPANIES_URL}
                  element={
                    <RouteAdminPermission defaultSiteKey={defaultSiteKey}>
                      {typeof defaultSiteKey === "string" ? (
                        <CompaniesListContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAdminPermission>
                  }
                />
                <Route
                  path={`${urls.ADMIN_MY_SITES_URL}/:id/custom-fields/:workType`}
                  element={
                    <RouteAdminPermission defaultSiteKey={defaultSiteKey}>
                      <CustomFieldsContainer />
                    </RouteAdminPermission>
                  }
                />
                <Route
                  path={`${urls.ADMIN_MY_SITES_URL}/:id`}
                  element={
                    <RouteAdminPermission defaultSiteKey={defaultSiteKey}>
                      <SiteDetailsContainer />
                    </RouteAdminPermission>
                  }
                />
                <Route
                  path={urls.ADMIN_MY_SITES_URL}
                  element={
                    <RouteAdminPermission defaultSiteKey={defaultSiteKey}>
                      <MySitesContainer />
                    </RouteAdminPermission>
                  }
                />
                <Route
                  path={`${urls.ADMIN_EDIT_USER_URL}/:id`}
                  element={
                    <RouteAdminPermission defaultSiteKey={defaultSiteKey}>
                      {typeof defaultSiteKey === "string" ? (
                        <EditUserContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAdminPermission>
                  }
                />
                <Route
                  path={urls.ADMIN_ADD_USER_URL}
                  element={
                    <RouteAdminPermission defaultSiteKey={defaultSiteKey}>
                      {typeof defaultSiteKey === "string" ? (
                        <AddUserContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAdminPermission>
                  }
                />
                <Route
                  path={urls.ADMIN_USER_MANAGEMENT_URL}
                  element={
                    <RouteAdminPermission defaultSiteKey={defaultSiteKey}>
                      {typeof defaultSiteKey === "string" ? (
                        <UserManagementContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAdminPermission>
                  }
                />
                <Route
                  path={urls.ADMIN_USER_SETTINGS_URL}
                  element={
                    <RouteAdminPermission defaultSiteKey={defaultSiteKey}>
                      {firebaseUser != null &&
                      siteKeyDoc != null &&
                      typeof defaultSiteKey === "string" ? (
                        <UserSettings
                          siteKey={siteKeyDoc}
                          userId={firebaseUser.uid}
                        />
                      ) : (
                        <></>
                      )}
                    </RouteAdminPermission>
                  }
                />
                {/* Finish Admin Routes */}
                {/* Start Auth Routes */}
                <Route
                  path={`${urls.TEMPLATES_URL}/:id/edit`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <EditChecklistContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={`${urls.TEMPLATES_URL}/:id`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <TemplateDetailsContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={`${urls.TASKS_URL}/:id`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <TasksForTemplateContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={`${urls.TEMPLATE_ITEMS_URL}/:id/edit`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <EditItemContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={urls.TEMPLATES_URL}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <ListTemplatesContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={urls.NEW_TEMPLATE_URL}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <NewTemplateContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={urls.CHECKLISTS_URL}
                  element={
                    <RouteAuthRequired>
                      {siteKeyDoc ? (
                        <AllChecklistsTasksContainer siteKey={siteKeyDoc} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={`${urls.TASK_DETAILS_URL}/:id`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <TaskDetailsContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={urls.CREATE_SITE_URL}
                  element={
                    <RouteAuthRequired>
                      <CreateSiteContainer />
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={`${urls.WORK_RECORD_AND_TASKS_URL}/:workRecordID/:taskID?`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <WorkRecordAndTasksContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={`${urls.PRICEBOOK_URL}`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <PriceBookItemListContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={`${urls.PRICEBOOK_CATEGORIES_URL}`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <PriceBookItemCategoriesListContainer
                          siteKey={defaultSiteKey}
                        />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={`${urls.CUSTOMERS_URL}${urls.CREATE_ESTIMATE_URL}`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <CreateEstimateContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={`${urls.CUSTOMERS_URL}${urls.ESTIMATES_URL_AUTH}/:id`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <ViewEstimateContainer
                          siteKey={defaultSiteKey}
                          renderedInDialog={false}
                        />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={`${urls.ESTIMATES_URL_AUTH}/:estimateStatusParams`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <EstimateListContainerWithKey
                          siteKey={defaultSiteKey}
                        />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={`${urls.ESTIMATES_URL_NON_AUTH}/:id`}
                  element={<NonAuthUserViewEstimateContainer />}
                />
                <Route
                  path={`${urls.CUSTOMERS_URL}${urls.CREATE_TASK_URL}/:customerID?/:craftRecordID?`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <Suspense fallback={<LoadingComponentForPages />}>
                          <LazyCreateTaskForCustomerContainer
                            siteKey={defaultSiteKey}
                            assignedTo={null}
                          />
                        </Suspense>
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={`${urls.CUSTOMERS_URL}/:customerId`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <ShowSingleCustomerContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={urls.CUSTOMERS_URL}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <CustomerListContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={`${urls.INVOICES_URL}/:id`}
                  element={<PaymentsContainer />}
                />
                <Route
                  path={`${urls.SELF_CHECKOUT_URL}/:siteKey`}
                  element={<PaymentsSelfCheckoutContainer />}
                />
                <Route
                  path={urls.INVOICES_URL}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <InvoiceListContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={urls.PAYMENTS_URL}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <PaymentListContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={urls.INVENTORY_OBJECTS_URL}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <InventoryObjectListContainer
                          siteKey={defaultSiteKey}
                        />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={urls.INVENTORY_TRANSACTIONS_URL}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <InventoryTransactionListContainer
                          siteKey={defaultSiteKey}
                          taskID={null}
                          parentRecordID={null}
                          invoiceID={null}
                        />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={urls.INVENTORY_LOCATIONS_URL}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <InventoryLocationsListContainer
                          siteKey={defaultSiteKey}
                        />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={urls.SCHEDULING_URL}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <Suspense fallback={<LoadingComponentForPages />}>
                          <LazySchedulingContainer siteKey={defaultSiteKey} />
                        </Suspense>
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={urls.VEHICLES_URL}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <Suspense fallback={<LoadingComponentForPages />}>
                          <LazyVehiclesContainer siteKey={defaultSiteKey} />
                        </Suspense>
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={urls.ZONES_URL}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <Suspense fallback={<LoadingComponentForPages />}>
                          <LazyZonesContainer siteKey={defaultSiteKey} />
                        </Suspense>
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={urls.REPORTS_URL}
                  element={
                    <RouteAuthRequired>
                      {firebaseUser != null &&
                      typeof defaultSiteKey === "string" ? (
                        <ReportsContainer
                          siteKey={defaultSiteKey}
                          userId={firebaseUser.uid}
                        />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={`${urls.REPORTS_URL}/:reportType`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <ReportSpecPage />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={`${urls.WORK_RECORD_LIST_URL}/:craftTypeValue`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <WorkRecordListContainerWithKey
                          siteKey={defaultSiteKey}
                        />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={`${urls.DELETED_WORK_RECORD_LIST_URL}`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <Suspense fallback={<LoadingComponentForPages />}>
                          <LazyDeletedWorkRecordListContainer
                            siteKey={defaultSiteKey}
                          />
                        </Suspense>
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={`${urls.TASK_LIST_URL}/:taskParams`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <TaskListContainerWithKey siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={`${urls.DELETED_TASK_LIST_URL}`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <Suspense fallback={<LoadingComponentForPages />}>
                          <LazyDeletedTaskListContainer
                            siteKey={defaultSiteKey}
                          />
                        </Suspense>
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={urls.COMPLIANCE_URL}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <ComplianceContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={urls.FEEDBACK}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <FeedbackListContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={`${urls.MEMBERSHIP_TEMPLATES}${urls.CREATE_TEMPLATE}`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <AddEditMembershipTemplateContainer
                          siteKey={defaultSiteKey}
                        />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={`${urls.MEMBERSHIP_TEMPLATES}/:templateId`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <AddEditMembershipTemplateContainer
                          siteKey={defaultSiteKey}
                        />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={urls.MEMBERSHIPS}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <Suspense fallback={<LoadingComponentForPages />}>
                          <LazyMembershipsContainer siteKey={defaultSiteKey} />
                        </Suspense>
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={`${urls.MEMBERSHIPS}/:membershipID`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <MembershipDetailsContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={urls.MEMBERSHIP_TEMPLATES}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <MembershipTemplateListContainer />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={urls.MEMBERSHIP_ISSUES}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <Suspense fallback={<LoadingComponentForPages />}>
                          <LazyMembershipIssuesContainer
                            siteKey={defaultSiteKey}
                          />
                        </Suspense>
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={urls.ASSETS}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <AssetsEquipmentContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={urls.QUICKBOOKS_URL}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <QuickbooksContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={urls.LIGHTSPEED_URL}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <LightspeedContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                <Route
                  path={urls.GL_ACCOUNTS_URL}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <GLAccountListContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
                {FEATURE_FLAG_NEW_USER ? (
                  <Route
                    path={urls.NEW_USER_URL}
                    element={
                      <RouteAuthRequired>
                        <NewUserContainer />
                      </RouteAuthRequired>
                    }
                  />
                ) : null}
                {FEATURE_FLAG_APPLY_TO_EXISTING_SITE ? (
                  <Route
                    path={urls.APPLY_URL}
                    element={
                      <RouteAuthRequired>
                        <ApplyToExistingSiteContainer />
                      </RouteAuthRequired>
                    }
                  />
                ) : null}
                {/* Finish Auth Routes */}
                <Route
                  path={`${urls.REVIEW_REQUEST}/:id`}
                  element={<ReviewRequestContainer />}
                />
                {/* FEATURE FLAG! 🏴‍☠️ won't show up for builds that are connected to */}
                {/* the production database. */}
                {FEATURE_FLAG_CREATE_ACCOUNT ? (
                  <Route
                    path={urls.CREATE_ACCOUNT_URL}
                    element={
                      <CreateAccountContainer destination={urls.APPLY_URL} />
                    }
                  />
                ) : null}
                {FEATURE_FLAG_CREATE_TRIAL_ACCOUNT ? (
                  <Route
                    path={urls.CREATE_TRIAL_ACCOUNT_URL}
                    element={
                      <CreateAccountContainer destination={urls.NEW_USER_URL} />
                    }
                  />
                ) : null}

                {FEATURE_FLAG_REPORT_BUILDER && flags.reportBuilderEnabled() ? (
                  <Route
                    path={urls.REPORT_BUILDER_URL}
                    element={
                      <RouteAuthRequired>
                        {typeof defaultSiteKey === "string" ? (
                          <Suspense fallback={<LoadingComponentForPages />}>
                            <LazyReportBuilderContainer
                              siteKey={defaultSiteKey}
                            />
                          </Suspense>
                        ) : (
                          <></>
                        )}
                      </RouteAuthRequired>
                    }
                  />
                ) : null}

                {flags.reportTemplatesEnabled() ? (
                  <Route
                    path={urls.REPORT_TEMPLATES_URL}
                    element={
                      <RouteAuthRequired>
                        {typeof defaultSiteKey === "string" ? (
                          <Suspense fallback={<LoadingComponentForPages />}>
                            <LazyKpisContainer siteKey={defaultSiteKey} />
                          </Suspense>
                        ) : (
                          <></>
                        )}
                      </RouteAuthRequired>
                    }
                  />
                ) : null}

                {FEATURE_FLAG_HELP_SECTION ? (
                  <Route path="/help" element={<Help />} />
                ) : null}

                <Route
                  path={`${urls.COMMISSIONS_LIST_URL}`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <Suspense fallback={<LoadingComponentForPages />}>
                          <LazyCommissionsListContainer
                            siteKey={defaultSiteKey}
                          />
                        </Suspense>
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />

                <Route
                  path={`${urls.CALLS_LIST_URL}`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <Suspense fallback={<LoadingComponentForPages />}>
                          <LazyCallsListContainer siteKey={defaultSiteKey} />
                        </Suspense>
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />

                <Route
                  path={`${urls.CALLS_LIST_URL}/:id/edit`}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <Suspense fallback={<LoadingComponentForPages />}>
                          <LazyCallsEditContainer siteKey={defaultSiteKey} />
                        </Suspense>
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />

                <Route
                  path={urls.HOME_URL}
                  element={
                    <RouteAuthRequired>
                      <Dashboard />
                    </RouteAuthRequired>
                  }
                />
                <Route path={urls.LOGIN_URL} element={<LoginPage />} />
                <Route path={urls.AUTO_LOGIN_URL} element={<AutoLogin />} />
                <Route
                  path={urls.FORGOT_PASSWORD_URL}
                  element={<ForgotPassword />}
                />
                {/* 404 Not Found Fallback Route */}
                <Route path="*" element={<NotFound404 />} />
              </Routes>
            </ErrorBoundary>
          </div>
        </div>
      </Router>
    </div>
  );
}
