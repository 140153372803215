// libs
import { useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";

// local
import new_stilt_logo from "../images/new_stilt_logo.png";
import placeholder_avatar from "../images/placeholder_avatar.svg";
import { useEffect, useMemo, useState } from "react";
import { DbRead, DbWrite } from "../database";
import { ExistingStiltPhoneCall, StiltPhoneCallManager } from "../models/calls";
import { useSiteKeyDocStore } from "../store/site-key-doc";
import { useNavToCreateTask, useStiltNav } from "../navigation";
import IncomingCallAlert from "./IncomingCallAlert";
import IncomingCallListModal from "./IncomingCallListModal";
import { useIncomingCallsStore } from "../store/incoming-calls";
import * as strings from "../strings";
import { ADMIN_USER_SETTINGS_URL } from "../urls";

// TODO: if no match, goes to new create-task page. How do we want to associate
// the call with the task/customer?

type TopNavProps = {
  // className?: string;
  showSidebar: () => void;
  // topNavData: TopNavDataType;
};

// type TopNavItem = {
//   title: string;
//   path: string;
//   icon: JSX.Element;
//   component: React.ComponentType<any>;
// };

// export type TopNavDataType = TopNavItem[];

export default function TopNav({ showSidebar }: TopNavProps) {
  const navigate = useNavigate();
  const stiltNav = useStiltNav();
  const navToCreateTask = useNavToCreateTask();

  const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);

  const [showCallList, setShowCallList] = useState(false);
  const [callDocs, setCallDocs] = useState<ExistingStiltPhoneCall[]>([]);
  // Sort callDocs. Mutates but shouldn't trigger a re-render
  useMemo(() => {
    if (callDocs.length === 0) return [];
    return callDocs.sort((a, b) => {
      if (a.timestampCreated == null || b.timestampCreated == null) return 0;
      // Ascending order
      return b.timestampCreated.toMillis() - a.timestampCreated.toMillis();
    });
  }, [callDocs]);

  useEffect(() => {
    function listenForCalls() {
      if (!siteKeyDoc) return undefined;
      const unsubscribe = DbRead.calls.subscribeRecent({
        siteKey: siteKeyDoc.id,
        onChange: setCallDocs,
        minutes: 30,
        openCalls: true,
        direction: "inbound",
      });
      return unsubscribe;
    }

    const unsubscribeFn = listenForCalls();
    return () => unsubscribeFn && unsubscribeFn();
  }, [siteKeyDoc]);

  const customerID = useMemo((): string | null => {
    if (callDocs.length > 0) {
      return callDocs[0].customerID;
    }
    return null;
  }, [callDocs]);

  const callingCustomerName = useMemo((): string | undefined => {
    if (callDocs.length > 0) {
      return StiltPhoneCallManager.extractFullNameFromCustomData(callDocs[0]);
    }
    return undefined;
  }, [callDocs]);

  const handleClick = (path: string) => {
    navigate(path);
  };

  const setCurrentCall = useIncomingCallsStore((state) => state.setCurrentCall);

  return (
    <div className="z-50">
      <header className="fixed left-0 top-0 z-50 flex h-16 w-full items-center bg-black p-0 shadow-lg">
        {/* SideNav toggle */}
        <FaBars
          onClick={showSidebar}
          className="ml-2.5 h-9 w-9 cursor-pointer px-2 text-white"
        />

        <div className="flex w-full items-center justify-between">
          {/* Logo */}
          <div className="flex h-full items-center sm:ml-1.5">
            <img
              src={new_stilt_logo}
              alt="Stilt Logo"
              className="w-[230px] object-contain px-2"
            />
          </div>

          {/* Call Status */}
          {callDocs.length > 0 && (
            <div className="mx-auto hidden xs:block">
              <IncomingCallAlert
                phone={callDocs[0].caller ?? ""}
                activeCallCount={callDocs.length}
                callStatus={callDocs[0].callStatus ?? undefined}
                name={(callingCustomerName || customerID) ?? undefined}
                onGoToCustomer={() => {
                  if (customerID) {
                    setCurrentCall(callDocs[0]);
                    stiltNav.customerPage(customerID);
                  }
                }}
                onGoToCallList={() => setShowCallList(true)}
                onGoToCreateNewTaskPage={() => {
                  setCurrentCall(callDocs[0]);
                  return stiltNav.createNewTaskForCustomerPage();
                }}
                numberOfCustomerIDs={callDocs[0].possibleCustomerIDs?.length}
                onGoToCustomerSearch={() => {
                  const incomingCallNumber = callDocs[0].caller;
                  if (!incomingCallNumber) return;
                  setCurrentCall(callDocs[0]);
                  stiltNav.customerSearchPage({ query: incomingCallNumber });
                }}
              />
            </div>
          )}

          <div className="m-auto hidden items-center justify-center sm:mr-4 sm:flex sm:justify-end">
            {/* User name and photo */}
            <div className="flex items-center justify-center gap-4">
              {siteKeyDoc?.customizations?.voip?.callForwardingEnabled ===
                true && (
                <div className="italic text-white">Call Forwarding Enabled</div>
              )}
              <button
                type="button"
                onClick={() => navToCreateTask()}
                className="inline-flex h-9 items-center justify-center rounded-md bg-primaryDark px-12 py-2 text-lg uppercase tracking-wider text-black shadow-sm transition-colors hover:bg-black hover:text-primaryDark "
              >
                {strings.CREATE_JOB}
              </button>
            </div>

            <button
              onClick={() => handleClick(ADMIN_USER_SETTINGS_URL)}
              className="ml-3 rounded-md p-2 hover:bg-primaryButtonText"
            >
              <img
                src={placeholder_avatar}
                alt="User avatar"
                className="h-10 w-10 rounded-full"
              />
            </button>
          </div>
        </div>
      </header>
      <IncomingCallListModal
        open={showCallList}
        closeModal={() => setShowCallList(false)}
        callList={callDocs.map((call) => {
          return {
            id: call.id,
            caller: call.caller ?? "",
            callStatus: call.callStatus ?? "fallback",
            timestampCreated: call.timestampCreated,
            customerID: call.customerID ?? "",
            fullName: StiltPhoneCallManager.extractFullNameFromCustomData(call),
          };
        })}
        onClearCallStatus={async (callID) => {
          if (siteKeyDoc == null) return;
          try {
            await DbWrite.calls.clearCallStatus({
              callDocID: callID,
              siteKey: siteKeyDoc.id,
            });
          } catch (e) {
            console.error("Error clearing call status", e);
          }
        }}
        onGoToCustomer={(customerID) => {
          setShowCallList(false);
          return stiltNav.customerPage(customerID);
        }}
        onGoToCreateNewTaskPage={(callDocID) => {
          const call = callDocs.find((call) => call.id === callDocID);
          if (!call) return;
          setShowCallList(false);
          // Need to know which call we're modifiying when updating.
          setCurrentCall(call);
          stiltNav.createNewTaskForCustomerPage();
        }}
        onUpdateCustomer={(callDocID) => {
          const call = callDocs.find((call) => call.id === callDocID);
          if (!call) return;
          setShowCallList(false);
          // Need to know which call we're modifiying when updating.
          setCurrentCall(call);
          stiltNav.customerSearchPage({ query: call.caller ?? "" });
        }}
      />
    </div>
  );
}
