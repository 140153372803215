import React, { Fragment } from "react";
import LoadingSpinner from "./LoadingSpinner";

type Ref = HTMLButtonElement;

type Props = {
  children: React.ReactNode;
  isBusy?: boolean;
  /** if omitted, defaults to "Processing..." */
  busyText?: string;
  /** if omitted, defaults to "text-sm" */
  fontSize?: string;
  /** if provided, `busyText` is ignored. */
  overrideLoadingElement?: React.ReactNode;
  /** if omitted, defaults to "h-10" */
  height?: string;
  isSelected?: boolean;
} & React.ComponentPropsWithRef<"button">;

/**
 * Button component with a white background for secondary actions.
 */
const BaseButtonSecondary = React.forwardRef<Ref, Props>(
  (
    {
      isBusy = false,
      fontSize = "text-sm",
      busyText = "Processing...",
      overrideLoadingElement,
      height = "h-10",
      isSelected,
      children,
      ...props
    },
    ref,
  ): JSX.Element => {
    const LoadingElement: React.ReactNode = overrideLoadingElement ?? (
      <Fragment>
        <LoadingSpinner />
        {busyText}
      </Fragment>
    );
    return (
      <button
        {...props}
        ref={ref}
        disabled={isBusy || props.disabled}
        className={`${props.className ?? ""} ${isSelected ? "border-gray-500 bg-gray-50 ring-2 ring-primaryLight ring-offset-2" : "border-gray-300 bg-white"} ${
          props.disabled && !isBusy
            ? "text-gray-300"
            : "text-gray-700 hover:bg-gray-50"
        } ${fontSize} inline-flex ${height} min-h-10 items-center justify-center rounded-md border px-4 py-2 font-medium shadow-sm outline-none transition-colors focus:ring-2 focus:ring-primaryLight focus:ring-offset-2`}
      >
        {isBusy ? LoadingElement : children}
      </button>
    );
  },
);

export default BaseButtonSecondary;
