import { guardIsPlainObject } from "./isPlainObject";

/**
 * Intended to be used when updating a nested object in firestore.
 *
 * @param original nested object to convert
 * @param prefix use if you want your new object keys to be prepended with something
 * @returns non-nested object
 *
 * @example
 * const original = { a: { b: { c: 1, d: 2 }, e: 3 }, f: 4 };
 * OUTPUT: { "a.b.c": 1, "a.b.d": 2, "a.e": 3, f: 4 }
 */
export function convertNestedObjToDotSeparatedFields(
  original: Record<string, any>,
  prefix?: string,
): Record<string, any> {
  let flattenedObj: Record<string, any> = {};

  for (const [key, value] of Object.entries(original)) {
    const fieldName = prefix ? `${prefix}.${key}` : key;

    if (guardIsPlainObject(value)) {
      const nestedObj = convertNestedObjToDotSeparatedFields(value, fieldName);
      flattenedObj = { ...flattenedObj, ...nestedObj };
    } else {
      flattenedObj[fieldName] = value;
    }
  }

  return flattenedObj;
}
