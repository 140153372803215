/* eslint-disable no-nested-ternary */
//Libs
import { Fragment } from "react/jsx-runtime";

// Local
import * as strings from "../../strings";
import { ExistingCraftRecord } from "../../models/craft-record";

interface Props {
  // Data
  existingCraftRecord: ExistingCraftRecord | null;
  estimatesEnabled: boolean;
  assetsEnabled: boolean;
  membershipsEnabled: boolean;
  showEstimateSectionFirst: boolean;
  isCreatingFromEstimate: boolean;
  setCreateTaskDialogOpen:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined;
  // Components
  addCustomerButton: React.ReactNode;
  addLocationButton: React.ReactNode;
  selectCustomerButton: React.ReactNode;
  displayCustomerData: React.ReactNode;
  customerLocationSection: React.ReactNode;
  workAndTaskTypesSection: React.ReactNode;
  notesSection: React.ReactNode;
  schedulingSection: React.ReactNode;
  customFieldsSection: React.ReactNode;
  actionButtons: React.ReactNode;
  taskCalendar: React.ReactNode;
  showCalendarButton: React.ReactNode;
  customerMembershipPills: React.ReactNode;
  locationMembershipPills: React.ReactNode;
  siteKeyLocationSection: React.ReactNode;
  confirmationReminderToggles: React.ReactNode;
  membershipSection: React.ReactNode;
  assetsSection: React.ReactNode;
  estimatesSection: React.ReactNode;
}

export default function CreateTaskForCustomerPage(props: Props): JSX.Element {
  // const vueHomeLink = `${BASE_APP_URL}/home`;

  return (
    <div className="lg:max-w-screen-xxl mx-auto w-full space-y-6 px-2 pb-10 xxs:px-4">
      <section>
        <div className="mx-auto w-full lg:max-w-screen-lg">
          <h1 className="text-5xl font-semibold text-primary">
            {props.existingCraftRecord === null
              ? strings.NEW_JOB
              : strings.NEW_TASK_FOR_EXISTING_JOB}
          </h1>
          {/* SELECT A CUSTOMER OR CREATE NEW CUSTOMER */}
          <hr className="my-4 block w-full border border-gray-200" />
          {props.siteKeyLocationSection}
          <div className="grid gap-4 sm:grid-cols-3">
            <div className="flex gap-2">
              <CreateTaskSecondaryHeading text={strings.CUSTOMER} />
              <span className="text-3xl font-medium text-redFail">*</span>
            </div>
            {!props.existingCraftRecord && (
              <div className="flex flex-col gap-4 xs:items-start sm:col-span-2 sm:flex-row sm:justify-end md:gap-6">
                {props.selectCustomerButton}
                {props.addCustomerButton}
              </div>
            )}
          </div>

          {props.displayCustomerData}
          {props.customerMembershipPills}
          <hr className="my-4 block w-full border border-gray-200" />
          {/* SELECT CUSTOMER LOCATION */}
          <div className="grid gap-4 sm:grid-cols-2">
            <div className="flex gap-2">
              <CreateTaskSecondaryHeading text={strings.CUSTOMER_LOCATION} />
              <span className="text-3xl font-medium text-redFail">*</span>
            </div>
            {props.existingCraftRecord === null && (
              <div className="mb-6 flex xs:items-start sm:mb-0 sm:justify-end">
                {props.addLocationButton}
              </div>
            )}
          </div>

          {props.customerLocationSection}
          {props.locationMembershipPills}
          <hr className="my-4 block w-full border border-gray-200" />
          {/* CRAFT (/WORK) TYPE AND TASK TYPE */}
          <CreateTaskSecondaryHeading
            text={strings.WORK_AND_TASK_TYPE}
            description={strings.WORK_AND_TASK_TYPES_DESC}
          />
          {props.workAndTaskTypesSection}
          <hr className="my-4 block w-full border border-gray-200" />

          {props.showEstimateSectionFirst &&
          props.estimatesEnabled &&
          !props.isCreatingFromEstimate
            ? props.estimatesSection
            : null}
          {/* NOTES */}
          {props.existingCraftRecord === null && (
            <CreateTaskSecondaryHeading
              text={strings.JOB_NOTES}
              description={strings.PLACEHOLDER_VISIBLE_NOTE}
              label="setDescription"
            />
          )}
          <div className="flex-1">
            {props.existingCraftRecord === null ? props.notesSection : null}
          </div>
          {props.existingCraftRecord === null && (
            <hr className="my-4 block w-full border border-gray-200" />
          )}

          {/* SCHEDULING */}
          <div className="flex items-center justify-between">
            <CreateTaskSecondaryHeading text={strings.SCHEDULING} />
            {props.confirmationReminderToggles}
          </div>
          {props.schedulingSection}
          {props.showCalendarButton}
        </div>
        <div className="lg:max-w-screen-xxl mx-auto w-full space-y-6 px-2 pb-10 xxs:px-4">
          {props.taskCalendar}
        </div>
        <div className="mx-auto w-full lg:max-w-screen-lg">
          <hr className="my-4 block w-full border border-gray-200" />
          {/* CUSTOM FIELDS */}
          <CreateTaskSecondaryHeading text={strings.ADDITIONAL_FIELDS} />
          {props.customFieldsSection}
          <hr className="mb-4 mt-4 block w-full border border-gray-200" />
          {/* MEMBERSHIPS */}
          {props.membershipsEnabled && (
            <Fragment>
              <CreateTaskSecondaryHeading
                text={strings.MEMBERSHIPS}
                description={strings.SELECT_MEMBERSHIP}
              />
              {props.membershipSection}
              <hr className="mb-4 mt-4 block w-full border border-gray-200" />
            </Fragment>
          )}
          {props.assetsEnabled && (
            <Fragment>
              {/* ASSETS/EQUIPMENT */}
              <CreateTaskSecondaryHeading
                text={strings.ASSETS_EQUIPMENT}
                description={strings.SELECT_ASSET}
              />
              {props.assetsSection}
              <hr className="mb-4 mt-4 block w-full border border-gray-200" />
            </Fragment>
          )}

          {/* CREATE ESTIMATE */}
          {!props.showEstimateSectionFirst &&
          props.estimatesEnabled &&
          !props.isCreatingFromEstimate
            ? props.estimatesSection
            : null}
          <div className="flex w-full flex-col items-center justify-between gap-6 xs:flex-row">
            {props.actionButtons}
          </div>
        </div>
      </section>
    </div>
  );
}

export function CreateTaskSecondaryHeading(props: {
  text: string;
  description?: string;
  /** used as htmlFor */
  label?: string;
}): JSX.Element {
  if (!props.description && !props.label) {
    return (
      <h2 className="mb-4 max-w-fit text-3xl font-extrabold text-primary">
        {props.text}
      </h2>
    );
  } else if (!props.description && props.label) {
    return (
      <label
        htmlFor={props.label}
        className="mb-4 text-3xl font-extrabold text-primary"
      >
        {props.text}
      </label>
    );
  } else if (props.description && props.label) {
    return (
      <div>
        <label
          htmlFor={props.label}
          className="text-3xl font-extrabold text-primary"
        >
          {props.text}
        </label>
        <p className="mb-4 text-sm text-gray-500">{props.description}</p>
      </div>
    );
  } else {
    return (
      <div>
        <h2 className="text-3xl font-extrabold text-primary">{props.text}</h2>
        <p className="mb-4 text-sm text-gray-500">{props.description}</p>
      </div>
    );
  }
}
