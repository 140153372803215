//Libs
import React, { useState } from "react";

//Local
import BaseButtonSecondary from "../BaseButtonSecondary";
import { CustomerTypes } from "../../models/customer";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import * as strings from "../../strings";
import { StyledTooltip } from "../StyledTooltip";

interface Props {
  customerTypeSelected: (customerType: CustomerTypes | null) => void;
  isLoading: boolean;
}

const CustomerSchedulesDropdown = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    // const dropdown = useRef(null);
    const [isOpen, setOpen] = useState(false);

    function handleResidential() {
      props.customerTypeSelected("residential");
    }

    function handleCommercial() {
      props.customerTypeSelected("commercial");
    }

    function handleAll() {
      props.customerTypeSelected(null);
    }

    return (
      <StyledTooltip title={strings.TOOLTIP_GENERATE_SCHEDULES}>
        <div ref={ref}>
          <BaseButtonSecondary
            onClick={() => setOpen(!isOpen)}
            // ref={dropdown}
            isBusy={props.isLoading}
            onBlur={() => setOpen(false)}
            className="w-full sm:w-56"
          >
            <PictureAsPdfIcon
              aria-hidden="true"
              style={{
                display: "block",
                height: "28px",
                width: "28px",
                padding: "2px",
              }}
            />
            <span className="mx-1 inline-block">
              {strings.buttons.CUSTOMER_SCHEDULES}
            </span>
            <svg
              className="h-4 shrink-0"
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </BaseButtonSecondary>

          <div
            className={`relative z-10 w-44 divide-y divide-gray-100 rounded-md bg-white shadow ${
              isOpen ? "" : "hidden"
            }`}
          >
            <ul className="absolute z-10 w-44 divide-y divide-gray-100 rounded-md bg-white shadow ">
              <li>
                <a
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={handleResidential}
                  className="block cursor-pointer p-4 hover:bg-gray-100"
                >
                  Residential
                </a>
              </li>
              <li>
                <a
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={handleCommercial}
                  className="block cursor-pointer p-4 hover:bg-gray-100"
                >
                  Commercial
                </a>
              </li>
              <li>
                <a
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={handleAll}
                  className="block cursor-pointer p-4 hover:bg-gray-100"
                >
                  All
                </a>
              </li>
            </ul>
          </div>
        </div>
      </StyledTooltip>
    );
  },
);

CustomerSchedulesDropdown.displayName = "CustomerSchedulesDropdown";

export default CustomerSchedulesDropdown;
