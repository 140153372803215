// libs

// local
import { ExistingCustomer } from "../../models/customer";
import Breadcrumbs from "../../components/Breadcrumbs";
import { CUSTOMERS_URL } from "../../urls";
import { Fragment } from "react/jsx-runtime";

export interface Props {
  customer: ExistingCustomer;
  children: {
    AddOrEditLocationDialog: (
      customerType: ExistingCustomer["type"],
    ) => React.ReactNode;
    EditCustomerDialog: React.ReactNode;
    RescheduleTaskDialog: React.ReactNode;
    CustomerAndAddressDetails: React.ReactNode;
    CustomerTables: React.ReactNode;
    TableTabs: React.ReactNode;
    HandlePaymentDialog: React.ReactNode;
    CustomerMembershipPills: React.ReactNode;
    CustomerEquipmentPills: React.ReactNode;
    CustomerTagChips: React.ReactNode;
    CustomerNotes: React.ReactNode;
    ActionsDropdown: React.ReactNode;
  };
}

export default function ShowSingleCustomerPage(props: Props) {
  // For breadcrumbs
  const home = {
    name: "List of All Customers",
    href: CUSTOMERS_URL,
    current: false,
  };
  const pages = [
    {
      name: props.customer.name,
      href: `${CUSTOMERS_URL}/${props.customer.id}`,
      current: true,
    },
  ];

  return (
    <Fragment>
      {/* <div className="relative z-0 flex"> */}
      <div className="flex">
        <div className="flex min-w-[35rem] grow flex-col pr-4">
          <div className="space-y-2 md:flex md:items-center md:justify-between md:space-y-0">
            <Breadcrumbs home={home} pages={pages} />
            {props.children.ActionsDropdown}
          </div>
          <div className="my-8 space-y-2">
            <h1 className="text-2xl font-bold capitalize text-primary md:text-4xl">
              {props.customer.name}
            </h1>
            {props.children.CustomerTagChips}
          </div>
          <div className="flex flex-col gap-2">
            {props.children.CustomerMembershipPills}
            {props.children.CustomerEquipmentPills}
          </div>
          <hr className="my-4 block w-full border border-gray-200" />
          <div className="flex flex-col">
            {props.children.CustomerAndAddressDetails}
            <hr className="my-4 block w-full border border-gray-200" />
            {props.children.TableTabs}
            {props.children.CustomerTables}
          </div>
        </div>
        <div className="min-h-full border-l-2 border-gray-200">
          {props.children.CustomerNotes}
        </div>
      </div>
      {props.children.RescheduleTaskDialog}
      {props.children.AddOrEditLocationDialog(props.customer.type)}
      {props.children.HandlePaymentDialog}
      {props.children.EditCustomerDialog}
    </Fragment>
  );
}
