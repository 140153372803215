//Libs
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  CalendarDate,
  CalendarDateTime,
  ZonedDateTime,
  today,
  getLocalTimeZone,
  toCalendarDate,
} from "@internationalized/date";
import type { RangeValue } from "@react-types/shared";

//Local
import EstimateListPage from "./EstimateListPage";
import { DbRead } from "../../database";
import { EstimateStatus, ExistingEstimate } from "../../models/estimate";
import { useNavToViewEstimate } from "../../navigation";
import RADateRangePicker from "../../components/DateRangePicker/RADateRangePicker";
import { logger } from "../../logging";
import { useSiteKeyDocStore } from "../../store/site-key-doc";

export default function EstimateListContainer({
  siteKey,
}: {
  siteKey: string;
}) {
  type UrlParams = { estimateStatusParams: string };
  const { estimateStatusParams } = useParams<UrlParams>();

  const navToViewEstimate = useNavToViewEstimate();
  const [siteKeyDoc] = useSiteKeyDocStore((state) => [state.siteKeyDoc]);

  const [estimateList, setEstimateList] = useState<ExistingEstimate[]>([]);
  const [range, setRange] = useState<RangeValue<CalendarDate>>({
    start: today(getLocalTimeZone()).subtract({
      days: siteKeyDoc?.customizations?.defaultInvoicePageDateRangeDays ?? 30,
    }),
    end: today(getLocalTimeZone()),
  });

  function handleDateRangeChange(
    value: RangeValue<CalendarDate | ZonedDateTime | CalendarDateTime>,
  ) {
    logger.debug(
      `Invoked handleDateRangeChange for range: ${value.start.month}-${value.start.day} to ${value.end.month}-${value.end.day}`,
    );
    try {
      const convertedStart = toCalendarDate(value.start);
      const convertedEnd = toCalendarDate(value.end);
      const converted = { start: convertedStart, end: convertedEnd };
      setRange(converted);
      logger.debug(
        `set range to ${converted.start.month}-${converted.start.day} to ${converted.end.month}-${converted.end.day}`,
      );
    } catch (e) {
      if (e instanceof Error) {
        console.log("Error in handleDateRangeChange", e);
        logger.error("Error in handleDateRangeChange", e);
      }
    }
  }

  useEffect(() => {
    function getEstimates() {
      if (!siteKeyDoc) return undefined;
      const startDate = range.start.toDate(siteKeyDoc.timezone);
      const endDate = new Date(
        range.end.toDate(siteKeyDoc.timezone).setHours(23, 59, 59),
      );
      let unsubscribe = undefined;
      switch (estimateStatusParams) {
        case "All":
          unsubscribe = DbRead.estimates.subscribeAll(
            siteKey,
            startDate,
            endDate,
            null,
            setEstimateList,
          );
          return unsubscribe;
        case "Approved":
          unsubscribe = DbRead.estimates.subscribeAll(
            siteKey,
            startDate,
            endDate,
            [EstimateStatus.APPROVED],
            setEstimateList,
          );
          return unsubscribe;
        case "Awaiting Approval":
          unsubscribe = DbRead.estimates.subscribeAll(
            siteKey,
            startDate,
            endDate,
            [EstimateStatus.AWAITING_APPROVAL],
            setEstimateList,
          );
          return unsubscribe;
        case "Draft":
          unsubscribe = DbRead.estimates.subscribeAll(
            siteKey,
            startDate,
            endDate,
            [EstimateStatus.DRAFT],
            setEstimateList,
          );
          return unsubscribe;
        case "On Hold":
          unsubscribe = DbRead.estimates.subscribeAll(
            siteKey,
            startDate,
            endDate,
            [EstimateStatus.ON_HOLD],
            setEstimateList,
          );
          return unsubscribe;
        case "Rejected":
          unsubscribe = DbRead.estimates.subscribeAll(
            siteKey,
            startDate,
            endDate,
            [EstimateStatus.REJECTED],
            setEstimateList,
          );
          return unsubscribe;
        case "Locked":
          unsubscribe = DbRead.estimates.subscribeAll(
            siteKey,
            startDate,
            endDate,
            [EstimateStatus.LOCKED],
            setEstimateList,
          );
          return unsubscribe;
        default:
          return unsubscribe;
      }
    }

    const unsubscribeFn = getEstimates();
    return () => unsubscribeFn && unsubscribeFn();
  }, [estimateStatusParams, siteKey, range]);

  async function goToViewEstimate(estimate: ExistingEstimate) {
    const [estimateCustomer, estimateCustomerLocation, invoice] =
      await Promise.all([
        DbRead.customers.get(siteKey, estimate.customerID),
        DbRead.customerLocations.getSingle(
          siteKey,
          estimate.customerLocationID,
        ),
        DbRead.invoices.getByEstimateId(siteKey, estimate.id),
      ]);

    if (!estimateCustomer || !estimateCustomerLocation) return;
    navToViewEstimate(
      estimate.id,
      estimateCustomer,
      estimateCustomerLocation,
      invoice[0] ?? null,
    );
  }

  const dateRangePicker = (
    <div className="flex w-full flex-wrap items-center justify-end">
      <span className="pr-2 text-right">Date Created</span>
      <RADateRangePicker
        key={`${range.start.toString()}-${range.end.toString()}`}
        value={range}
        onChange={handleDateRangeChange}
      />
    </div>
  );

  return (
    <EstimateListPage
      estimateList={estimateList}
      siteKey={siteKey}
      goToViewEstimate={goToViewEstimate}
    >
      {{
        DateRangePicker: dateRangePicker,
      }}
    </EstimateListPage>
  );
}
