// Libs
import { initializeApp, getApp, getApps, FirebaseApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

// Local
import { useAuthStore } from "./store/firebase-auth";
import { useRootUserStore } from "./store/root-user";
import { logger } from "./logging";

// Your web app's Firebase configuration
// const firebaseProject = import.meta.env.VITE_APP_FIREBASE_PROJECT;
const firebaseProject = "scaffoldtracker";

let firebaseConfig: Record<string, string>;

// Uses the production Firebase project when building release version.
// Otherwise uses Stilt-Dev development Firebase project.
if (firebaseProject === "scaffoldtracker") {
  firebaseConfig = {
    apiKey: "AIzaSyDjQDwrqqMD17aSWc5AbCKIuoc8Vg4pEyg",
    authDomain: "scaffoldtracker.firebaseapp.com",
    databaseURL: "https://scaffoldtracker.firebaseio.com",
    projectId: "scaffoldtracker",
    storageBucket: "scaffoldtracker.appspot.com",
    messagingSenderId: "280039922335",
    appId: "1:280039922335:web:a888f42e802e2e6f",
    measurementId: "G-KHXG4NCLFL",
  };
} else if (firebaseProject === "stilt-dev") {
  firebaseConfig = {
    apiKey: "AIzaSyBPVPrVZI8HnMuUe3yT3erZDYt5G23a_70",
    authDomain: "stilt-dev.firebaseapp.com",
    databaseURL: "https://stilt-dev.firebaseio.com",
    projectId: "stilt-dev",
    storageBucket: "stilt-dev.appspot.com",
    messagingSenderId: "382623900204",
    appId: "1:382623900204:web:02c2bb64673545bcdb6789",
  };
} else {
  throw Error("Improperly configured FIREBASE_PROJECT env variable.");
}

// // Enable Firestore debug logs before initialization
// setLogLevel("debug");

// INITIALIZE FIREBASE
// Checking if a default firebase app already exists to avoid double init.
const apps = getApps();
export let firebaseApp: FirebaseApp;
if (apps.length === 0) {
  firebaseApp = initializeApp(firebaseConfig);
} else {
  firebaseApp = getApp();
}

// if we're not in test mode and we haven't already initialized firestore,
// attempt to initialize it with DB caching enabled.
// export let db: Firestore;
// if (process.env.NODE_ENV !== "test") {
//   if (apps.length === 0) {
//     try {
//       db = initializeFirestore(firebaseApp, {
//         localCache: persistentLocalCache(),
//       });
//       // logger.info("Firestore initialized with persistence enabled.");
//     } catch (e) {
//       logger.warn(
//         "Failed to initialize Firestore with persistence enabled:",
//         e,
//       );
//       db = getFirestore(firebaseApp);
//     }
//   } else {
//     // logger.info("don't double init");
//     db = getFirestore(firebaseApp);
//   }
// } else {
//   db = getFirestore(firebaseApp); // don't use persistence in tests
// }
export const db = getFirestore();

export const cloudFunctions = getFunctions(firebaseApp);
export const auth = getAuth();

// Enable use of local Cloud Functions Emulator.
if (import.meta.env.VITE_APP_USE_FUNCTIONS_EMULATOR === "true") {
  logger.warn("Using local cloud functions emulator");
  connectFunctionsEmulator(cloudFunctions, "localhost", 5001);
}

export async function signIn(email: string, password: string) {
  const userPasswordCredential = await signInWithEmailAndPassword(
    auth,
    email,
    password,
  );
  return userPasswordCredential;
}

export async function signUserOut() {
  return signOut(auth);
}

logger.info(`MODE: ${import.meta.env.MODE}`); // "development" | "production"

// Initialize a listener to set the firebase user in the AppState store.

onAuthStateChanged(auth, (user) => {
  if (user) {
    // This block executes when a user logs in.
    // Update the value in the app's global store.
    useAuthStore.setState((state) => {
      state.firebaseUser = user;
    });
    // Get the root user document that contains their defaultSiteKey.
    useRootUserStore.getState().subscribe(user.uid);
  } else {
    // This block executes when a user logs out.
    // Unsubscribe from the root user doc listener
    const { unsubscribeRootUser } = useRootUserStore.getState();
    if (unsubscribeRootUser) {
      unsubscribeRootUser();
    }

    // Reset the value in the app's global store back to it's initial value.
    useAuthStore.setState((state) => {
      state.firebaseUser = null;
    });
  }
});
