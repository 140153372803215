/* eslint-disable no-nested-ternary */
// libs
import { useNavigate, useParams } from "react-router-dom";
import { DbRead, DbWrite, timestampNow } from "../../database";
import { useMutation, useQuery, useQueryClient } from "react-query";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { DocumentData, Timestamp } from "firebase/firestore";
import { User } from "firebase/auth";
import cloneDeep from "lodash/cloneDeep";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";
import { PencilIcon } from "@heroicons/react/24/solid";
import { ICellRendererParams } from "ag-grid-community";
import AddIcon from "@mui/icons-material/Add";

// Local
import ShowSingleCustomerPage from "./ShowSingleCustomerPage";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import { StyledTooltip } from "../../components/StyledTooltip";
import * as strings from "../../strings";
import {
  CUSTOMERS_URL,
  whiteLabel,
  WORK_RECORD_AND_TASKS_URL,
} from "../../urls";
import {
  CustomerLocation_CreateAPI,
  CustomerLocation_UpdateAPI,
  CustomerLocationManager,
  customerLocationWithoutTimestamps,
  ExistingCustomerLocation,
} from "../../models/customer-location";
import { useToastMessageStore } from "../../store/toast-messages";
import { logger } from "../../logging";
import {
  convertFSTimestampToLuxonDT,
  createToastMessageID,
  getRoundedCurrency,
} from "../../utils";
import {
  BillingInfo,
  CardOnFile,
  Customer,
  customerWithoutTimestamps,
  ExistingCustomer,
  ExistingCustomerUpdate,
  getBillingInfoFromCustomerAndLocations,
} from "../../models/customer";
import EditCustomerDialog from "../../components/customers/EditCustomerDialog";
import { useAuthStore } from "../../store/firebase-auth";
import { diffObjects } from "../../assets/js/object-diff";
import StyledMessage from "../../components/StyledMessage";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import AddEditCustomerLocationDialog, {
  TemporaryLocation,
} from "../../components/customers/AddEditCustomerLocationDialog";
import LoadingSpinner from "../../components/LoadingSpinner";
import BaseInputText from "../../components/BaseInputText";
import { useUserPermissionsStore } from "../../store/user-permissions";
import {
  useNavToCreateEstimate,
  useNavToCreateTask,
  useNavToViewEstimate,
} from "../../navigation";
import RescheduleTaskDialog from "../../components/WorkRecordAndTasks/RescheduleTaskDialog";
import {
  ExistingTask,
  Task_CreateForCustomer,
  TaskRecordManager,
} from "../../models/task";
import { ExistingEstimate } from "../../models/estimate";
import { PencilIconWithRef } from "../../components/PencilEditButton";
import { CustomerDetails } from "../../components/estimates/CustomerDetails";
import CustomerTaskListTable from "../../components/customers/CustomerTaskListTable";
import { EditCalendarWithRef } from "../../components/Buttons/EditCalendarButton";
import { PlusIconWithRef } from "../../components/PlusAddButton";
import CustomerLocationsListTable from "../../components/customers/CustomerLocationsListTable";
import CustomerEstimateListTable from "../../components/customers/CustomerEstimateListTable";
import { TrashIconWithSpinner } from "../../components/ButtonDeleteTrash";
import TableTabs, { TableTabTypes } from "../../components/customers/TableTabs";
import { OTaskStatus, TaskStatus } from "../../models/task-status";
import { InvoiceIconWithSpinner } from "../../components/InvoiceCreateButton";
import HandlePaymentDialog from "../../components/Invoices/HandlePaymentDialog";
import {
  ExistingStiltInvoice,
  OStiltInvoiceStatus,
  StiltInvoice_UpdateAPI,
  StiltInvoiceManager,
  StiltInvoiceStatus,
  StiltInvoiceStatusValues,
  TemplatePaymentTerm,
} from "../../models/invoice";
import { ListIconWithRef } from "../../components/TaskViewButton";
import { filterInvoicesByDropdownSelection } from "../Invoices/InvoiceListContainer";
import { useMembershipTemplatesStore } from "../../store/membership-templates";
import MembershipsAndDiscountsDialog from "../../components/customers/MembershipsDialog";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import { getMembershipIdsCount } from "../../assets/js/memberships/getMembershipIdsCount";
import { getTemporaryDataForEditMemberships } from "../../assets/js/memberships/getTemporaryDataForEditMemberships";
import {
  ExistingMembership,
  Membership_DeleteAPI,
} from "../../models/membership";
import { repeatStringNumTimes } from "../../assets/js/memberships/repeatStringNumTimes";
import { isValidCraftType } from "../../models/craft-types";
import { isValidTaskType } from "../../models/task-types";
import { getTaskCustomFieldList } from "../../components/WorkRecordAndTasks/functions";
import { ExistingCustomField } from "../../models/custom-field";
import TaskStatusChangeDialog from "../../components/WorkRecordAndTasks/TaskStatusChangeDialog";
import { IMultipleUid_AssignUser } from "../../components/CustomFields/MultipleUidDialog";
import { useUserDisplayNamesStore } from "../../store/user-display-names-map";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { isWhiteLabel } from "../../white-label-check";
import RecordManualPaymentDialog, {
  NewManualPayment,
  RecordManualPaymentDialogProps,
} from "../../components/Invoices/RecordManualPaymentDialog";
import { SchedulingButton } from "../Scheduling/SchedulingContainer";
import {
  APIPaymentSavedCard,
  CreateMultiPayment,
  paymentMethods,
  StiltPayment_CreateAPI,
  StiltPaymentManager,
} from "../../models/stilt-payment";
import { generatePaymentUniqueLink } from "../../assets/js/generatePaymentUniqueLink";
import MergeCustomerDialog from "../../components/customers/MergeCustomerDialog";
import ViewInvoiceDialog, {
  ViewInvoiceDialogProps,
} from "../../components/Payments/ViewInvoiceDialog";
import ConfirmUpdateCustomerLocation from "../../components/WorkRecordAndTasks/ConfirmUpdateCustomerLocation";
import EditInvoiceDialog from "../../components/Invoices/EditInvoiceDialog";
import ChipTag from "../../components/ChipTag";
import { ExistingCustomerContact } from "../../models/customer-contact";
import { useSiteKeyUsersStore } from "../../store/site-key-users";
import { CustomerContacts } from "../../components/customers/CustomerContacts";
import { CustomerContactFormState } from "../../components/customers/AddEditCustomerContactForm";
import CustomerHistory from "../../components/customers/CustomerHistory";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { ExistingAsset } from "../../models/asset";
import AddAssetDialog from "../../components/AssetsEquipment/AddAssetDialog";
import EditBillingInfoDialog from "../../components/customers/EditBillingInfoDialog";
import { BillingInfoSection } from "../../components/estimates/BillingInfoSection";
import { generatePDF } from "../../components/Invoices/generatePDF";
import { ExistingStiltPhoto } from "../../models/stilt-photo";
import { getEmailList } from "../../utils/getEmailList";
import { PDFIconWithSpinner } from "../../components/PDFButton";
import BaseInputSelect from "../../components/BaseInputSelect";
import ConfirmationDialog, {
  ConfirmationDialogProps,
} from "../../components/ConfirmationDialog";
import { InvoiceActionTypes } from "../Invoices/InvoiceListPage";
import MembershipPill from "../../components/Memberships/MembershipPill";
import CustomerInvoiceListTablePage from "../../components/customers/CustomerInvoiceListTablePage";
import {
  getCustomerBalanceString,
  getCustomerBalanceTagColor,
  getCustomerBalanceTextColor,
} from "../../utils/customerBalanceTag";
import AlertV2, { type AlertType } from "../../components/AlertV2";
import {
  useIncomingCallsStore,
  type AlertStyle,
} from "../../store/incoming-calls";
import AlertV2Button from "../../components/AlertV2Button";
import OutboundCallDialog from "../../components/OutboundCallDialog";
import { getPermissionsMap } from "../Admin/UserManagementContainer";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import CustomerCallListTablePage from "../../components/customers/CustomerCallListTablePage";
import { ExistingStiltPhoneCall } from "../../models/calls";
import HandleSendEmailDialog from "../../components/estimates/HandleSendEmailDialog";
import AddEditCustomerContactDialog from "../../components/customers/AddEditCustomerContactDialog";
import { ExistingNote, Note, NoteManager } from "../../models/note";
import CustomerNotesCard from "../../components/customers/CustomerNotesCard";
import AddEditCustomerNote from "../../components/customers/AddEditCustomerNote";
import EquipmentPill from "../../components/AssetsEquipment/EquipmentPill";
import currencyFormatter from "../../currency";
import MultiPayCreditCardDialog, {
  MultiPayCreditCardDialogProps,
} from "../../components/Invoices/MultiPayCreditCardConfirmationDialog";
import { generateMultiPaymentUniqueLink } from "../../assets/js/generateMultiPaymentUniqueLink";
import { ExistingAttachment } from "../../models/attachment";
import { ExistingCraftRecord } from "../../models/craft-record";
import CustomerJobListTable from "../../components/customers/CustomerJobListTable";
import CustomerPhotosAndAttachments from "../../components/customers/CustomerPhotosAndAttachments";
import ConfirmDeleteCustomerDialog from "../../components/customers/ConfirmDeleteCustomerDialog";
import SingleCustomerActionsDropdown, {
  SingleCustomerActions,
} from "../../components/customers/SingleCustomerActionsDropdown";
import ManageCardsOnFileDialog from "../../components/Payments/ManageCardsOnFileDialog";
import CreateMembershipDialog from "../../components/Memberships/CreateMembershipDialog";
import CustomerTableDialog from "../../components/customers/CustomerTableDialog";

interface Props {
  siteKey: string;
}

export default function ShowSingleCustomerContainer({ siteKey }: Props) {
  const apiKey = import.meta.env.VITE_APP_GOOGLE_MAP_KEY;
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );
  const queryClient = useQueryClient();
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const [membershipTemplateList, isLoadingMembershipTemplateList] =
    useMembershipTemplatesStore((state) => [
      state.membershipTemplates,
      state.loading,
    ]);
  const [siteKeyDoc, siteKeyDocIsLoading] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.loading,
  ]);

  const [siteKeyLocationList, getLocationTitle] = useSiteKeyLocationsStore(
    (state) => [state.siteKeyLocationList, state.getLocationTitle],
  );
  const [membershipsForCustomer, setMembershipsForCustomer] = useState<
    ExistingMembership[]
  >([]);
  const [notesForCustomer, setNotesForCustomer] = useState<ExistingNote[]>([]);
  const [addEditNoteOpen, setAddEditNoteOpen] = useState(false);
  const [noteToBeEdited, setNoteToBeEdited] = useState<ExistingNote | null>(
    null,
  );

  const [confirmDeleteCustomerDialogOpen, setConfirmDeleteCustomerDialogOpen] =
    useState(false);

  /* HISTORY */
  const navigate = useNavigate();
  const navToCreateEstimateByTask = useNavToCreateEstimate();
  const navToViewEstimate = useNavToViewEstimate();
  const navToCreateTask = useNavToCreateTask();

  // Extract customer ID from the URL
  type UrlParams = { customerId: string };
  const data = useParams<UrlParams>();
  const customerId = data.customerId;
  if (typeof customerId !== "string") {
    throw new Error(`customerId was not a string: ${customerId}`);
  }

  function goToAllCustomers() {
    navigate(CUSTOMERS_URL);
  }

  function goToWorkRecordAndTasksPage(
    craftRecordID: ExistingTask["craftRecordID"],
  ) {
    navigate(`${WORK_RECORD_AND_TASKS_URL}/${craftRecordID}`);
  }

  function goToPaymentPage(paymentLink: string) {
    window.open(paymentLink, "_blank");
  }

  /* QUERIES */

  // Query to get customer memberships docs

  // Fetch the list of memberships for the selected customer
  useEffect(() => {
    async function getMembershipsForCustomer() {
      if (!customerId) return undefined;

      logger.debug("getMembershipsForCustomer useEffect called ");
      // Query payments via realtime updates. Set the list of invoices.
      const unsubscribeAllMembershipsByCustomerID =
        DbRead.memberships.subscribeByCustomerID({
          siteKey: siteKey,
          customerID: customerId,
          onChange: (snapshot) => {
            // don't show expired memberships other than the most recent expired membership
            const expiredMemberships = snapshot.filter(
              (m) => m.status === "expired",
            );
            let mostRecentExpiredMembership: ExistingMembership | null = null;
            if (expiredMemberships.length > 0) {
              mostRecentExpiredMembership = expiredMemberships.reduce(
                (prev, current) => {
                  return (prev.membershipEndDate ?? prev.timestampCreated) >
                    (current.membershipEndDate ?? prev.timestampCreated)
                    ? prev
                    : current;
                },
              );
            }
            const nonExpiredMemberships = snapshot.filter(
              (m) => m.status !== "expired",
            );

            const allMembershipsToDisplay = [...nonExpiredMemberships];

            if (mostRecentExpiredMembership != null) {
              allMembershipsToDisplay.push(mostRecentExpiredMembership);
            }

            setMembershipsForCustomer(allMembershipsToDisplay);
          },
          onError: (error) =>
            logger.error(
              `Error in getMembershipsForCustomer: ${error.message}`,
            ),
        });
      return unsubscribeAllMembershipsByCustomerID;
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    const unsubscribePromise = getMembershipsForCustomer();
    // Return an anonymous ƒn for cleanup.
    return () => {
      unsubscribePromise.then((unsubscribe) => {
        if (unsubscribe) {
          unsubscribe();
        }
      });
    };
  }, [customerId, siteKey]);

  const [customerLocationDoc, setCustomerLocationDoc] =
    useState<ExistingCustomerLocation | null>(null);

  const customerLocationMemberships = membershipsForCustomer.filter(
    (membership) => membership.customerLocationID !== null,
  );

  const filterCustomerLocationMemberships =
    customerLocationDoc &&
    membershipsForCustomer.filter(
      (membership) => membership.customerLocationID === customerLocationDoc.id,
    );

  // Query to get the `estimate` docs
  useEffect(() => {
    function getEstimates() {
      if (!customerId) return undefined;
      const unsubscribe = DbRead.estimates.subscribeByCustomerID(
        siteKey,
        customerId,
        setEstimateList,
        (error) =>
          logger.error(`Error in ${getEstimates.name}: ${error.message}`),
      );
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getEstimates();

    // eslint-disable-next-line consistent-return
    return () => unsubscribeFn && unsubscribeFn();
  }, [customerId, siteKey]);

  const [customerInvoiceList, setCustomerInvoiceList] = useState<
    ExistingStiltInvoice[]
  >([]);
  const [customerInvoiceListLoading, setCustomerInvoiceListLoading] =
    useState(false);

  useEffect(() => {
    function getCustomerInvoices() {
      if (!customerId) return undefined;
      setCustomerInvoiceListLoading(true);
      const unsubscribe = DbRead.invoices.subscribeByCustomerID({
        siteKey: siteKey,
        customerID: customerId,
        onChange: setCustomerInvoiceList,
        onError: (error) =>
          logger.error(
            `Error in ${getCustomerInvoices.name}: ${error.message}`,
          ),
      });
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getCustomerInvoices();
    setCustomerInvoiceListLoading(false);
    // eslint-disable-next-line consistent-return
    return () => unsubscribeFn && unsubscribeFn();
  }, [customerId, siteKey]);

  /* STATES */
  const [isInvoiceDialogOpen, setIsInvoiceDialogOpen] = useState(false);
  const [viewInvoiceDialogProps, setViewInvoiceDialogProps] =
    useState<ViewInvoiceDialogProps | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isInitiatingOutboundCall, setIsInitiatingOutboundCall] =
    useState(false);
  const [isMerging, setIsMerging] = useState(false);
  const [addCustomerLocationDialogOpen, setAddCustomerLocationDialogOpen] =
    useState(false);
  const [address, setAddress] = useState<string>("");
  const [billingAddressLine1, setBillingAddressLine1] = useState<string>("");
  const [geocoderResult, setGeocoderResult] = useState<
    google.maps.GeocoderResult[]
  >([]);
  const [displayAddressError, setDisplayAddressError] = useState(false);
  const [callDialogOpen, setCallDialogOpen] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string | null>(
    null,
  );
  // const [selectedOutboundNumber, setSelectedOutboundNumber] = useState<string>("");
  const [editCustomerDialogOpen, setEditCustomerDialogOpen] = useState(false);
  const [customerLocations, setCustomerLocationDocs] = useState<
    ExistingCustomerLocation[]
  >([]);
  const [rescheduleTaskDialogOpen, setRescheduleTaskDialogOpen] =
    useState(false);
  const [taskDoc, setTaskDoc] = useState<ExistingTask | null>(null);
  const [estimateList, setEstimateList] = useState<ExistingEstimate[]>([]);
  // const [showCustomerHistory, setShowCustomerHistory] =
  //   useState(false);
  const [activeTableTab, setActiveTableTab] = useState<TableTabTypes>(
    TableTabTypes.TASKS,
  );
  const [isHandlePaymentDialogOpen, setIsHandlePaymentDialogOpen] =
    useState(false);
  const [isTableHandlePaymentDialogOpen, setIsTableHandlePaymentDialogOpen] =
    useState(false);
  const [invoicePaymentLink, setInvoicePaymentLink] = useState<string | null>(
    null,
  );
  const [customerDoc, setCustomerDoc] = useState<ExistingCustomer | null>(null);
  const [isAddressLoading, setIsAddressLoading] = useState(false);
  const [isEditingBillingInfo, setEditingBillingInfo] = useState(false);
  const [stiltInvoiceStatusSelection, setStiltInvoiceStatusSelection] =
    useState<StiltInvoiceStatusValues | null>(null);
  const [taskForTaskStatusChangeDialog, setTaskForTaskStatusChangeDialog] =
    useState<ExistingTask | null>(null);
  const [
    originalTaskForTaskStatusChangeDialog,
    setOriginalTaskForTaskStatusChangeDialog,
  ] = useState<ExistingTask | null>(null);
  const [openTaskStatusChangeDialog, setOpenTaskStatusChangeDialog] = useState<
    string | null
  >(null);
  const [
    customFieldsForTaskStatusChangeDialog,
    setCustomFieldsForTaskStatusChangeDialog,
  ] = useState<ExistingCustomField[]>([]);
  const [workRecordTitle, setWorkRecordTitle] = useState<string | null>(null);

  const [userDisplayNamesMap, userDisplayNamesMapIsLoading] =
    useUserDisplayNamesStore((state) => [
      state.userDisplayNames,
      state.loading,
    ]);

  const [selectedDateTime, setSelectedDateTime] = useState<DateTime>(
    DateTime.now(),
  );
  const [stiltInvoiceID, setStiltInvoiceID] = useState<
    ExistingStiltInvoice["id"] | null
  >(null);
  const [selectEmailAddressesDialogOpen, setSelectEmailAddressesDialogOpen] =
    useState(false);

  const [mergeCustomerDialogOpen, setMergeCustomerDialogOpen] = useState(false);
  const [customerToMerge, setCustomerToMerge] =
    useState<ExistingCustomer | null>(null);
  const [selectCustomerDialogOpen, setSelectCustomerDialogOpen] =
    useState(false);
  const [showManualEntryForm, setShowManualEntryForm] = useState(false);
  const [bulkManualPaymentDialogProps, setBulkManualPaymentDialogProps] =
    useState<RecordManualPaymentDialogProps | null>(null);
  const [multiPayCreditCardDialogProps, setMultiPayCreditCardDialogProps] =
    useState<MultiPayCreditCardDialogProps | null>(null);

  // This confirmation dialog alerts the user that other locations use THIS
  // location as their billing address, so changing this address will affect
  // those locations
  const [
    updateCustomerLocationConfirmationDialogOpen,
    setUpdateCustomerLocationConfirmationDialogOpen,
  ] = useState(false);
  const [updateLocationData, setUpdateLocationData] =
    useState<customerLocationWithoutTimestamps | null>(null);

  const [customerContacts, setCustomerContacts] = useState<
    ExistingCustomerContact[] | null
  >(null);
  const [
    addEditCustomerContactDialogOpen,
    setAddEditCustomerContactDialogOpen,
  ] = useState(false);
  const [selectedCustomerContact, setSelectedCustomerContact] =
    useState<ExistingCustomerContact | null>(null);

  const [customerPhotoDocs, setCustomerPhotoDocs] = useState<
    ExistingStiltPhoto[]
  >([]);

  const [editBillingAddressDialogOpen, setEditBillingAddressDialogOpen] =
    useState(false);

  const [photos, setPhotos] = useState<any[]>([]);

  const [billingInfo, setBillingInfo] = useState<BillingInfo>({
    addressLine1: "",
    addressLine2: "",
    city: "",
    zipCode: "",
    state: "",
    name: "",
    email: "",
    phone: "",
  });

  const [selectedAsset, setSelectedAsset] = useState<ExistingAsset | null>(
    null,
  );

  const [assetDetailsDialogOpen, setAssetDetailsDialogOpen] = useState(false);

  const [addMembershipDialogOpen, setAddMembershipDialogOpen] = useState(false);
  const [
    addLocationMembershipsDialogOpen,
    setAddLocationMembershipsDialogOpen,
  ] = useState(false);
  const [locationTemporaryMemberships, setLocationTemporaryMemberships] =
    useState<Record<string, any>[]>([]);
  const [locationMembershipIds, setLocationMembershipIds] = useState<string[]>(
    [],
  );

  const invoiceStatus: (StiltInvoiceStatusValues | null)[] = [
    null,
    ...Object.values(OStiltInvoiceStatus),
  ];
  const filteredListOfInvoices = filterInvoicesByDropdownSelection(
    stiltInvoiceStatusSelection,
    customerInvoiceList,
  );

  const selectedInvoice = filteredListOfInvoices.find(
    (invoice) => invoice.id === stiltInvoiceID,
  );

  const [customerTasks, setCustomerTasks] = useState<ExistingTask[]>([]);
  const [customerCraftRecords, setCustomerCraftRecords] = useState<
    ExistingCraftRecord[]
  >([]);
  const [customerAttachments, setCustomerAttachments] = useState<
    ExistingAttachment[]
  >([]);
  const [workRecordAttachments, setWorkRecordAttachments] = useState<
    ExistingAttachment[]
  >([]);

  useEffect(() => {
    function getTaskList() {
      if (!customerDoc || !userPermissions) return undefined;

      const unsubscribe = DbRead.tasks.subscribeSingleCustomerTasks({
        siteKey: siteKey,
        permissions: userPermissions,
        customerID: customerDoc.id,
        onChange: setCustomerTasks,
        onError: (error) =>
          logger.error(`Error in ${getTaskList.name}: ${error.message}`),
      });
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getTaskList();

    // eslint-disable-next-line consistent-return
    return () => unsubscribeFn && unsubscribeFn();
  }, [siteKey, customerDoc, userPermissions]);

  useEffect(() => {
    function getAttachmentsList() {
      if (!customerDoc) return undefined;

      const unsubscribe = DbRead.attachments.subscribeAllForCustomer({
        siteKey: siteKey,
        customerID: customerDoc.id,
        onChange: setCustomerAttachments,
        onError: (error) =>
          logger.error(`Error in ${getAttachmentsList.name}: ${error.message}`),
      });
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getAttachmentsList();

    // eslint-disable-next-line consistent-return
    return () => unsubscribeFn && unsubscribeFn();
  }, [siteKey, customerDoc]);

  useEffect(() => {
    async function getAllCraftRecordAttachments() {
      if (!userPermissions) return;

      const allAttachments: ExistingAttachment[] = [];

      for (const craftRecord of customerCraftRecords) {
        const jobAttachments = await DbRead.attachments.getAllForWorkRecord({
          siteKey,
          workRecordID: craftRecord.id,
          userPermissions: userPermissions,
        });
        allAttachments.push(...jobAttachments);
      }

      setWorkRecordAttachments(allAttachments);
    }

    getAllCraftRecordAttachments();
  }, [siteKey, customerCraftRecords, userPermissions]);

  useEffect(() => {
    function getParentRecords() {
      if (!customerDoc) return undefined;

      const unsubscribe = DbRead.parentRecords.subscribeAllByCustomerID({
        siteKey: siteKey,
        customerID: customerDoc.id,
        onChange: setCustomerCraftRecords,
        onError: (error) =>
          logger.error(`Error in ${getParentRecords.name}: ${error.message}`),
      });
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getParentRecords();

    // eslint-disable-next-line consistent-return
    return () => unsubscribeFn && unsubscribeFn();
  }, [siteKey, customerDoc]);

  const siteKeyUsersList = useSiteKeyUsersStore(
    (state) => state.siteKeyUsersList,
  );

  /* Fetch the list of permissions when this component loads */
  const { data: permissionsMap = {} } = useQuery(
    ["permissions", siteKey],
    () => getPermissionsMap(siteKeyUsersList, siteKey),
    {
      enabled: siteKeyUsersList.length > 0,
    },
  );

  const vehiclesQueryKey = ["showSingleCustomer_vehicles", siteKey];
  const { data: vehicleList = [], isLoading: vehicleListIsLoading } = useQuery(
    vehiclesQueryKey,
    () => DbRead.vehicles.getAll(siteKey),
  );

  const userList = useRef<IMultipleUid_AssignUser[]>([]);

  const [editInvoiceDialogOpen, setEditInvoiceDialogOpen] = useState(false);
  const [selectedDueDate, setSelectedDueDate] = useState<DateTime | null>(null);
  const [selectedIssueDate, setSelectedIssueDate] = useState<DateTime>(
    DateTime.now(),
  );
  const [paymentTerms, setPaymentTerms] = useState<
    ExistingStiltInvoice["paymentTerms"] | null
  >(null);

  const templatesPaymentTerms: Record<string, TemplatePaymentTerm> =
    siteKeyDoc?.customizations.paymentTerms ?? {};

  /* membership customer section */
  const [
    addCustomerMembershipsDialogOpen,
    setAddCustomerMembershipsDialogOpen,
  ] = useState(false);
  const [customerTemporaryMemberships, setCustomerTemporaryMemberships] =
    useState<Record<string, any>[]>([]);
  const [customerMembershipIds, setCustomerMembershipIds] = useState<string[]>(
    [],
  );
  const customerMembershipIdsCount = getMembershipIdsCount(
    customerMembershipIds,
  );

  const existingCustomerMembershipIds: string[] =
    cloneDeep(customerDoc?.customData["membershipTemplateIDs"]) ?? [];

  /* membership location section */
  const locationMembershipIdsCount = getMembershipIdsCount(
    locationMembershipIds,
  );

  const [customerEquipmentDocs, setCustomerEquipmentDocs] = useState<
    ExistingAsset[]
  >([]);

  const [pageActionsBusy, setPageActionsBusy] = useState(false);
  const [isManageCardsDialogOpen, setIsManageCardsDialogOpen] = useState(false);

  const [invoiceTableActionsBusy, setInvoiceTableActionsBusy] = useState(false);
  const [confirmationDialogProps, setConfirmationDialogProps] =
    useState<ConfirmationDialogProps>({
      invoiceActionType: "",
      body: "",
      isOpen: false,
      handleConfirmAction: () => {},
      title: "",
      isSubmitting: false,
      onClose: () => {},
      pdfBatchActionButtons: false,
      pendingInvoiceIDsLength: null,
    });

  const emailList = getEmailList(null, customerDoc);

  const sortedEstimateList = estimateList.sort((a, b) =>
    a.timestampCreated < b.timestampCreated ? 1 : -1,
  );

  const [customerCalls, setCustomerCalls] = useState<ExistingStiltPhoneCall[]>(
    [],
  );

  useEffect(() => {
    async function getCustomerCalls() {
      if (!customerId) return undefined;
      logger.debug("getCustomerCalls useEffect called ");
      // Query payments via realtime updates. Set the list of invoices.
      const unsubscribe = DbRead.calls.subscribeSingleCustomer({
        siteKey: siteKey,
        customerID: customerId,
        onChange: setCustomerCalls,
        onError: (error) =>
          logger.error(`Error in getCustomerCalls: ${error.message}`),
      });
      return unsubscribe;
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    const unsubscribePromise = getCustomerCalls();
    // Return an anonymous ƒn for cleanup.
    return () => {
      unsubscribePromise.then((unsubscribe) => {
        if (unsubscribe) {
          unsubscribe();
        }
      });
    };
  }, [siteKey, customerId]);

  /* USE EFFECTS */

  useEffect(() => {
    async function getCustomer() {
      if (!customerId) return undefined;
      logger.debug("getCustomer useEffect called ");
      // Query payments via realtime updates. Set the list of invoices.
      const unsubscribe = DbRead.customers.subscribe({
        siteKey: siteKey,
        customerID: customerId,
        onChange: setCustomerDoc,
        onError: (error) =>
          logger.error(`Error in getCustomer: ${error.message}`),
      });
      return unsubscribe;
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    const unsubscribePromise = getCustomer();
    // Return an anonymous ƒn for cleanup.
    return () => {
      unsubscribePromise.then((unsubscribe) => {
        if (unsubscribe) {
          unsubscribe();
        }
      });
    };
  }, [siteKey, customerId]);

  useEffect(() => {
    async function getCustomerLocations() {
      if (!customerId) return undefined;
      logger.debug("getCustomer useEffect called ");
      // Query payments via realtime updates. Set the list of invoices.
      const unsubscribe = DbRead.customerLocations.subscribeByCustomerID({
        siteKey: siteKey,
        customerID: customerId,
        onChange: setCustomerLocationDocs,
        onError: (error) =>
          logger.error(`Error in getCustomer: ${error.message}`),
      });
      return unsubscribe;
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    const unsubscribePromise = getCustomerLocations();
    // Return an anonymous ƒn for cleanup.
    return () => {
      unsubscribePromise.then((unsubscribe) => {
        if (unsubscribe) {
          unsubscribe();
        }
      });
    };
  }, [siteKey, customerId]);

  useEffect(() => {
    if (!customerDoc || !customerLocations) return;
    const billingInfo = getBillingInfoFromCustomerAndLocations(
      customerDoc,
      customerLocations,
    );
    setBillingInfo(billingInfo);
    setBillingAddressLine1(billingInfo.addressLine1);
  }, [customerLocations, customerDoc, siteKey]);

  useEffect(() => {
    async function getUserList() {
      if (!siteKeyDoc) return;

      userList.current = siteKeyUsersList.map((user) => ({
        uid: user.id,
        name: user.displayName,
        company: user.companyName,
        avatarUrl: user.userPhoto_URL,
        isAssigned: false,
        assignedVehicle: null,
      }));
    }

    getUserList();
  }, [siteKeyDoc, siteKeyUsersList]);

  // Subscribe to realtime location updates for the selected customer.
  useEffect(() => {
    async function getPhotos() {
      if (!userPermissions) return;
      const allPhotos = [];
      for (const task of customerTasks) {
        const photos = await DbRead.parentRecordPhotos.getAll({
          siteKey,
          workRecordID: task.craftRecordID.split("/")[3],
          userPermissions,
        });
        allPhotos.push(...photos);
      }
      allPhotos.push(...customerPhotoDocs);

      allPhotos.sort((a, b) => {
        if (!!a.timestampCreated && b.timestampCreated) {
          return a.timestampCreated?.toMillis() - b.timestampCreated.toMillis();
        } else {
          return 0;
        }
      });
      const translated = allPhotos.map((photoDoc) => {
        let originalURL = photoDoc.photoURL_reduced;
        if (originalURL === "") {
          originalURL = photoDoc.photoURL;
        }
        if (originalURL === "") {
          originalURL = photoDoc.photoURL_thumb;
        }

        let thumbURL = photoDoc.photoURL_thumb;
        if (thumbURL === "") {
          thumbURL = photoDoc.photoURL_reduced;
        }
        if (thumbURL === "") {
          thumbURL = photoDoc.photoURL;
        }

        return {
          original: originalURL,
          thumbnail: thumbURL,
          originalHeight: 30,
          description: convertToReadableTimestamp(photoDoc.timestampCreated),
        };
      });
      setPhotos(translated);
    }

    getPhotos();
  }, [userPermissions, customerTasks, customerPhotoDocs, siteKey]);

  // Subscribe to realtime photo doc updates for the photos collection for this customer.
  useEffect(() => {
    function getCustomerPhotoDocs() {
      if (!customerId) return undefined;
      const unsubscribe = DbRead.photos.subscribeByCustomerID({
        siteKey,
        customerID: customerId as string,
        onChange: setCustomerPhotoDocs,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getCustomerPhotoDocs();
    return () => unsubscribeFn && unsubscribeFn();
  }, [customerId, siteKey]);

  // Subscribe to realtime note updates for the notes collection for this customer.
  useEffect(() => {
    function getCustomerNotes() {
      if (!customerId) return undefined;
      const unsubscribe = DbRead.notes.subscribeByCustomerID({
        siteKey,
        customerID: customerId as string,
        onChange: setNotesForCustomer,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getCustomerNotes();
    return () => unsubscribeFn && unsubscribeFn();
  }, [customerId, siteKey]);

  // Subscribe to realtime contact updates for the selected customer.
  useEffect(() => {
    function getSelectedCustomerContacts() {
      if (!customerId) return undefined;
      const unsubscribe = DbRead.customerContacts.subscribeByCustomerID({
        siteKey: siteKey,
        customerID: customerId,
        onChange: setCustomerContacts,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getSelectedCustomerContacts();
    return () => unsubscribeFn && unsubscribeFn();
  }, [customerId, siteKey]);

  useEffect(() => {
    if (!selectedInvoice) return;
    if (selectedInvoice.dueDate != null) {
      setSelectedDueDate(convertFSTimestampToLuxonDT(selectedInvoice.dueDate));
    }
    if (selectedInvoice.issueDate != null) {
      setSelectedIssueDate(
        convertFSTimestampToLuxonDT(selectedInvoice.issueDate),
      );
    }
    setPaymentTerms(selectedInvoice.paymentTerms);
  }, [selectedInvoice]);

  useEffect(() => {
    function getCustomerEquipment() {
      if (!customerId) return undefined;

      const unsubscribe = DbRead.assets.subscribeByCustomerID({
        siteKey: siteKey,
        customerID: customerId,
        onChange: setCustomerEquipmentDocs,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getCustomerEquipment();
    return () => unsubscribeFn && unsubscribeFn();
  }, [customerId, siteKey]);

  /* MUTATIONS - GENERAL */
  const mutateRescheduleTaskDoc = useMutation(
    async (args: { taskUpdate: DocumentData; taskID: string }) =>
      DbWrite.tasks.update(args.taskUpdate, siteKey, args.taskID),
  );

  const mutateRescheduleTaskSpecificDetails = useMutation(
    async (args: {
      taskSpecificDetailsUpdate: DocumentData;
      taskRefPath: string;
    }) => {
      DbWrite.taskSpecificDetails.update(
        args.taskRefPath,
        args.taskSpecificDetailsUpdate,
      );
    },
  );

  const mutateRecordManualPayment = useMutation(
    async (args: { paymentData: StiltPayment_CreateAPI }) => {
      await DbWrite.payments.manualPayment(args.paymentData);
    },
  );

  const mutateUpdateInvoice = useMutation(
    async (args: { validPartialInvoiceData: StiltInvoice_UpdateAPI }) => {
      await DbWrite.invoices.update(args.validPartialInvoiceData);
    },
  );

  const mutateCreateInvoice = useMutation(
    async (args: { siteKey: string; estimateID: string; version: string }) => {
      return DbWrite.invoices.create({
        siteKey: args.siteKey,
        estimateID: args.estimateID,
        version: args.version,
        platform: "web",
      });
    },
  );

  const mutateDeleteMemberships = useMutation(
    async (args: { membershipIdsToDelete: Membership_DeleteAPI[] }) => {
      args.membershipIdsToDelete.forEach(async (membershipID) => {
        await DbWrite.memberships.delete(siteKey, membershipID);
      });
    },
  );

  /* MUTATIONS - CUSTOMER */
  const mutateEditCustomer = useMutation(
    async (args: { editCustomer: ExistingCustomerUpdate }) => {
      await DbWrite.customers.update(args.editCustomer);
    },
  );

  const mutateDeleteCustomer = useMutation(
    async (args: { customerID: ExistingCustomer["id"] }) => {
      await DbWrite.customers.delete(siteKey, args.customerID);
    },
    {
      onSuccess: async () => {
        setIsDeleting(false);
        goToAllCustomers();
      },
    },
  );

  /**
   * For mutate an existing customer into the current one
   */
  const mutateMergeCustomer = useMutation(
    async (args: {
      customerIDToKeep: ExistingCustomer["id"];
      customerIDToMerge: ExistingCustomer["id"];
    }) => {
      await DbWrite.customers.merge({
        siteKey,
        customerIDToKeep: args.customerIDToKeep,
        customerIDToMerge: args.customerIDToMerge,
      });
    },
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries("showSingleCustomerEstimateItem"),
        ]);
      },
    },
  );

  /* MUTATIONS - CUSTOMER LOCATION*/
  /**
   * For add a new customer location on the DB
   */
  const mutateAddCustomerLocation = useMutation(
    async (args: { validLocationList: CustomerLocation_CreateAPI[] }) => {
      await DbWrite.customerLocations.create(args.validLocationList);
    },
    {
      onSuccess: async () => {
        /* reset the address field after the new location is added to the object */
        resetLocationDialog();
      },
    },
  );

  // const mutateAddMemberships = useMutation(
  //   async (args: { validMembershipList: Membership_CreateAPI[] }) => {
  //     await DbWrite.memberships.create(args.validMembershipList);
  //   },
  //   {
  //     onSuccess: async () =>
  //       await Promise.all([
  //         queryClient.invalidateQueries(customerLocationQueryKey),
  //         queryClient.invalidateQueries(customerQueryKey),
  //       ]),
  //   },
  // );

  /**
   * For delete an existing customer location on the DB
   */
  const mutateDeleteCustomerLocation = useMutation(
    async (args: { customerLocationID: ExistingCustomerLocation["id"] }) => {
      await DbWrite.customerLocations.delete(siteKey, args.customerLocationID);
    },
  );

  /**
   * For edit an existing customer location on the DB
   */
  const mutateEditCustomerLocation = useMutation(
    async (args: { editCustomerLocation: CustomerLocation_UpdateAPI }) => {
      await DbWrite.customerLocations.update(args.editCustomerLocation);
      /* after the customer location is saved, make the geocoder result and address states empty */
      setGeocoderResult([]);
    },
  );

  /* FUNCTIONS */
  function resetLocationDialog() {
    setAddress("");
    setGeocoderResult([]);
    setCustomerLocationDoc(null);
  }

  function resetMembershipForAddLocation() {
    setLocationTemporaryMemberships([]);
    setLocationMembershipIds([]);
  }

  function resetMembershipForEditLocation() {
    const tempData = getTemporaryDataForEditMemberships(
      locationMembershipIdsCount,
    );
    setLocationTemporaryMemberships(tempData);
    setLocationMembershipIds(existingCustomerMembershipIds ?? []);
  }

  async function handleEditInvoiceDialogOpen() {
    if (selectedInvoice) {
      setEditInvoiceDialogOpen(true);
    }
  }

  function handlePaymentTermsChange(
    event: React.ChangeEvent<HTMLSelectElement>,
  ) {
    const value = event.target.value;
    if (value === "") {
      setPaymentTerms(null);
    } else {
      setPaymentTerms(value);
      const selectedPaymentTermTemplate = Object.entries(
        templatesPaymentTerms,
      ).find(([key, _]) => key === value);
      if (!selectedPaymentTermTemplate) return;
      const newDueDate = selectedIssueDate.plus({
        days: selectedPaymentTermTemplate[1].daysUntilDue,
      });
      setSelectedDueDate(newDueDate);
    }
  }

  async function handleSaveUpdatedInvoice(
    updatedInvoice: Partial<ExistingStiltInvoice>,
  ) {
    if (!selectedInvoice) return;

    const updatedInvoiceFields: StiltInvoice_UpdateAPI = {
      ...updatedInvoice,
      paymentTerms: paymentTerms,
      dueDate: selectedDueDate ? selectedDueDate.toString() : null,
      issueDate: selectedIssueDate?.toString(),
    };

    const diffInvoiceValues: DocumentData = diffObjects(
      selectedInvoice,
      updatedInvoiceFields,
    ).diff;

    if (Object.keys(diffInvoiceValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    const editInvoiceToValidate: Partial<ExistingStiltInvoice> = {
      ...diffInvoiceValues,
      id: selectedInvoice.id,
      refPath: selectedInvoice.refPath,
    };

    /* validate values before sending to DB */
    const validatedEditInvoice = StiltInvoiceManager.parseUpdate(
      editInvoiceToValidate,
    );
    logger.info("validatedEditInvoice", validatedEditInvoice);

    try {
      await mutateUpdateInvoice.mutateAsync({
        validPartialInvoiceData: validatedEditInvoice,
      });

      logger.debug("Invoice has been updated successfully.");
      addToastMessage({
        id: createToastMessageID(),
        dialog: true,
        message: strings.successfulUpdate(
          `Invoice #${selectedInvoice.invoiceNumber}`,
        ),
        type: "success",
      });
      setIsInvoiceDialogOpen(false);
    } catch (error) {
      logger.error(`An error occurred during handleSaveUpdatedInvoice`, error);
      addToastMessage({
        id: createToastMessageID(),
        dialog: true,
        message: strings.UNEXPECTED_ERROR,
        type: "error",
      });
    }

    setStiltInvoiceID(null);
  }

  function goToViewEstimate(estimate: ExistingEstimate) {
    if (!customerDoc || !customerLocations) {
      return;
    }
    const customerLocation = customerLocations.find(
      (location) => location.id === estimate.customerLocationID,
    );
    if (!customerLocation) {
      return;
    }
    const invoice = customerInvoiceList.find(
      (invoice) => invoice.estimateID === estimate.id,
    );
    navToViewEstimate(
      estimate.id,
      customerDoc,
      customerLocation,
      invoice ?? null,
    );
  }

  async function handleMergeCustomer() {
    setIsMerging(true);
    if (!customerDoc || !customerToMerge) return;
    try {
      await mutateMergeCustomer.mutateAsync({
        customerIDToKeep: customerDoc.id,
        customerIDToMerge: customerToMerge.id,
      });
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.successfulMerge(
          customerToMerge.name,
          customerDoc.name,
        ),
        type: "success",
      });
    } catch (error) {
      logger.error(`error while running handleMergeCustomer: `, error);
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.ERR_MERGE_CUSTOMER,
        type: "error",
      });
    } finally {
      setIsMerging(false);
      setMergeCustomerDialogOpen(false);
    }
  }

  async function handleGenerateCustomerStatements(): Promise<void> {
    if (!customerId) return;
    setPageActionsBusy(true);
    const url = await DbRead.customers.generateCustomerStatement(
      siteKey,
      customerId,
    );
    setPageActionsBusy(false);
    window.open(url, "_blank")?.focus();
  }

  async function handleGenerateCustomerSchedule(): Promise<void> {
    if (!customerId) return;
    setPageActionsBusy(true);
    try {
      const responseData = await DbRead.customers.generateCustomerSchedule(
        siteKey,
        customerId,
      );

      if (responseData.code === 200) {
        if (typeof responseData?.data === "string") {
          window.open(responseData.data, "_blank")?.focus();
        } else {
          addToastMessage({
            id: createToastMessageID(),
            dialog: false,
            message: responseData?.message?.toString(),
            type: "error",
          });
        }
      } else {
        logger.error("Error in handleGenerateCustomerSchedule: ", responseData);
        addToastMessage({
          id: createToastMessageID(),
          dialog: false,
          message: strings.UNEXPECTED_ERROR,
          type: "error",
        });
      }

      setPageActionsBusy(false);
    } catch (error) {
      logger.error("Error in handleGenerateCustomerSchedule: ", error);
      setPageActionsBusy(false);
    }
  }

  async function handleDeleteCustomer() {
    if (customerDoc == null) return;

    if (customerId == null) return;

    setIsDeleting(true);

    const membershipIdsToDelete: string[] = [];
    if (
      existingCustomerMembershipIds &&
      existingCustomerMembershipIds.length > 0
    ) {
      const deletedMembershipIds: string[] = existingCustomerMembershipIds;
      /* for deletedMembershipIds array, needs to get the membershipID */
      const deletedIdsCount = getMembershipIdsCount(deletedMembershipIds);
      for (const [templateId, quantity] of Object.entries(deletedIdsCount)) {
        const membershipDocs = await DbRead.memberships.getByTemplateId(
          siteKey,
          customerDoc.id,
          null,
          templateId,
        );
        if (membershipDocs.length > 0) {
          for (let i = 0; i < quantity; i++) {
            membershipIdsToDelete.push(membershipDocs[i].id);
          }
        }
      }
    }

    if (customerLocations && customerLocations?.length !== 0) {
      for (const location of customerLocations) {
        if (
          location.customData["membershipTemplateIDs"] &&
          location.customData["membershipTemplateIDs"].length > 0
        ) {
          const deletedMembershipIds: string[] =
            location.customData["membershipTemplateIDs"];
          /* for deletedMembershipIds array, needs to get the membershipID */
          const deletedIdsCount = getMembershipIdsCount(deletedMembershipIds);
          for (const [templateId, quantity] of Object.entries(
            deletedIdsCount,
          )) {
            const membershipDocs = await DbRead.memberships.getByTemplateId(
              siteKey,
              customerDoc.id,
              location.id,
              templateId,
            );
            if (membershipDocs.length > 0) {
              for (let i = 0; i < quantity; i++) {
                membershipIdsToDelete.push(membershipDocs[i].id);
              }
            }
          }
        }
      }
    }

    try {
      if (customerLocations?.length === 0) {
        /* no locations, so delete only customer and its memberships */
        await Promise.all([
          mutateDeleteCustomer.mutateAsync({
            customerID: customerId,
          }),
          mutateDeleteMemberships.mutateAsync({
            membershipIdsToDelete: membershipIdsToDelete,
          }),
        ]);
        addToastMessage({
          id: createToastMessageID(),
          dialog: false,
          message: strings.successfulDelete("Customer"),
          type: "success",
        });
      } else {
        /* locations are present, so delete customer, locations and memberships of both */
        await Promise.all([
          mutateDeleteCustomer.mutateAsync({
            customerID: customerId,
          }),
          mutateDeleteMemberships.mutateAsync({
            membershipIdsToDelete: membershipIdsToDelete,
          }),
          customerLocations?.forEach((location) => {
            mutateDeleteCustomerLocation.mutateAsync({
              customerLocationID: location.id,
            });
          }),
        ]);
        addToastMessage({
          id: createToastMessageID(),
          dialog: false,
          message: strings.successfulDelete("Customer"),
          type: "success",
        });
      }
    } catch (error) {
      logger.error(`An error occurred during handleDeleteCustomer`, error);
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.UNEXPECTED_ERROR,
        type: "error",
      });
    }
  }

  async function handleDeleteContact(contactID: string): Promise<void> {
    if (!siteKeyDoc) return;
    await DbWrite.customerContacts.delete(
      siteKeyDoc.id,
      firebaseUser.uid,
      contactID,
    );
  }

  async function handleEditCustomerContact(
    updateCustomerContact: CustomerContactFormState,
  ) {
    if (!siteKeyDoc || !customerId || !selectedCustomerContact) return;

    const diffCustomerContactValues = diffObjects(
      selectedCustomerContact,
      updateCustomerContact,
    ).diff;

    if (Object.keys(diffCustomerContactValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    const customerContactUpdate = {
      ...diffCustomerContactValues,
      lastModifiedBy: firebaseUser.uid,
    };

    try {
      await DbWrite.customerContacts.update(
        siteKey,
        selectedCustomerContact.id,
        customerContactUpdate,
      );
      addToastMessage({
        dialog: false,
        id: createToastMessageID(),
        message: strings.successfulUpdate(selectedCustomerContact.type),
        type: "success",
      });
      setSelectedCustomerContact(null);
    } catch (error) {
      logger.error("error while updating customer contact", error);
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.UNEXPECTED_ERROR,
        type: "error",
      });
    }
  }

  async function handleSaveCustomerContact(
    customerContact: CustomerContactFormState,
  ) {
    if (!siteKeyDoc) return;
    if (!customerId) return;

    await DbWrite.customerContacts.create({
      ...customerContact,
      createdBy: firebaseUser.uid,
      customerID: customerId,
      customerLocationID: null,
      lastModifiedBy: firebaseUser.uid,
      siteKey: siteKeyDoc.id,
    });
  }

  async function handleGoToPaymentPage(): Promise<void> {
    if (invoicePaymentLink != null) {
      goToPaymentPage(invoicePaymentLink);
    } else {
      if (!stiltInvoiceID) return;
      const paymentLink = await generatePaymentUniqueLink(
        siteKey,
        stiltInvoiceID,
        "web",
      );
      if (paymentLink) {
        goToPaymentPage(paymentLink);
      } else {
        addToastMessage({
          id: createToastMessageID(),
          dialog: false,
          message: strings.ERROR_PAYMENT_LINK,
          type: "error",
        });
      }
    }
  }

  /** send itemized invoice along with payment link */
  async function emailInvoice(
    email: string[],
    includeJobPhotos: boolean,
  ): Promise<void> {
    if (!stiltInvoiceID) return;

    if (!selectedInvoice) {
      logger.error("emailInvoice missing selectedInvoice");
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.ERR_EMAILING_INVOICE,
        type: "error",
      });
      return;
    }
    if (email.length === 0) {
      addToastMessage({
        id: createToastMessageID(),
        message: strings.NO_EMAIL_FOR_CUSTOMER,
        dialog: false,
        type: "error",
      });
      return;
    }

    const paymentLink = await generatePaymentUniqueLink(
      siteKey,
      stiltInvoiceID,
      "email",
    );
    if (!paymentLink) {
      logger.error("emailInvoice: missing invoice link");
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.ERR_GEN_INVOICE_LINK,
        type: "error",
      });
      return;
    }

    try {
      await DbWrite.invoices.sendViaEmail({
        siteKey,
        invoiceURL: paymentLink,
        customerEmailList: email,
        includeJobPhotos: includeJobPhotos,
      });
      addToastMessage({
        id: createToastMessageID(),
        message: strings.EMAILED_CUSTOMER_INVOICE,
        dialog: false,
        type: "success",
      });
      setStiltInvoiceID(null);
      closeViewInvoiceDialog();
    } catch (e) {
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.ERR_EMAILING_INVOICE,
        type: "error",
      });
      logger.error("error emailing customer invoice", e);
      setStiltInvoiceID(null);
    }
  }

  function handleEmailReceipt(invoiceID: string) {
    setSelectEmailAddressesDialogOpen(true);
    setStiltInvoiceID(invoiceID);
  }

  async function sendEmailReceipt(emailList: string[]): Promise<void> {
    const invoice = customerInvoiceList.find(
      (invoice) => invoice.id === stiltInvoiceID,
    );

    if (invoice == null) {
      const idList = customerInvoiceList.map((inv) => inv.id);
      logger.error(
        `Invoice ID: ${stiltInvoiceID}. SiteKey: ${siteKey}. Couldn't locate invoice using customerInvoiceList, ids are: ${idList}`,
      );
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERR_EMAILING_RECEIPT_NOTIFIED,
        dialog: false,
        type: "error",
      });
      return;
    }

    if (emailList.length === 0) {
      addToastMessage({
        id: createToastMessageID(),
        message: strings.NO_EMAIL_FOR_CUSTOMER,
        dialog: false,
        type: "error",
      });
      return;
    }

    try {
      await DbWrite.payments.emailReceipt({
        siteKeyID: siteKey,
        invoiceID: invoice.id,
        customerEmailList: emailList,
      });
      addToastMessage({
        id: createToastMessageID(),
        message: strings.EMAILED_CUSTOMER_RECEIPT,
        dialog: false,
        type: "success",
      });
    } catch (e) {
      logger.error("Failed to email receipt to customer", e);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERR_EMAILING_RECEIPT_NOTIFIED,
        dialog: false,
        type: "error",
      });
    }
  }

  async function sendEmailFromHandlePaymentDialog(
    emails: string[],
    shouldIncludePhotos: boolean,
  ): Promise<void> {
    if (!selectedInvoice) return;
    if (selectedInvoice.amountDue > 0) {
      await emailInvoice(emails, shouldIncludePhotos);
    } else {
      await sendEmailReceipt(emails);
    }
  }

  async function handleGetPDF(invoiceID: string) {
    if (siteKeyDoc) {
      await generatePDF.invoice(
        siteKeyDoc,
        [invoiceID],
        true,
        siteKeyDoc?.customizations.defaultIncludeJobPhotos ?? false,
        userPermissions,
      );
    }
  }

  async function getEstimatePDF(estimateID: string) {
    if (!siteKeyDoc) return;
    await generatePDF.estimate(siteKeyDoc, [estimateID]);
  }

  function openEditCustomerDialog() {
    if (existingCustomerMembershipIds != null) {
      setCustomerMembershipIds(existingCustomerMembershipIds);
      const idsCount = getMembershipIdsCount(existingCustomerMembershipIds);
      const tempData = getTemporaryDataForEditMemberships(idsCount);
      setCustomerTemporaryMemberships(tempData);
    }
    setEditCustomerDialogOpen(true);
  }

  function openCallDialog(phoneNumber: string) {
    setSelectedPhoneNumber(phoneNumber);
    setCallDialogOpen(true);
  }

  function openRescheduleTaskDialog(taskDoc: ExistingTask) {
    setRescheduleTaskDialogOpen(true);
    setTaskDoc(taskDoc);
  }

  async function handleRescheduleTask(
    updatedTask: ExistingTask,
  ): Promise<void> {
    if (taskDoc == null) {
      return;
    }

    const { taskSpecificDetails, ...task } = updatedTask;

    const diffTaskValues: DocumentData = diffObjects(taskDoc, task).diff;
    const diffTaskSpecificDetails: DocumentData = diffObjects(
      taskDoc.taskSpecificDetails,
      taskSpecificDetails,
    ).diff;

    if (Object.keys(diffTaskValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    const taskUpdate = {
      ...diffTaskValues,
      timestampLastModified: timestampNow(),
      lastModifiedBy: firebaseUser.uid,
    };
    try {
      await mutateRescheduleTaskDoc.mutateAsync({
        taskUpdate: taskUpdate,
        taskID: taskDoc.id,
      });

      await mutateRescheduleTaskSpecificDetails.mutateAsync({
        taskSpecificDetailsUpdate: diffTaskSpecificDetails,
        taskRefPath: taskDoc.refPath,
      });

      logger.debug("Task has been updated successfully.");
      addToastMessage({
        dialog: false,
        id: createToastMessageID(),
        message: strings.successfulUpdate(updatedTask.title),
        type: "success",
      });
    } catch (error) {
      logger.error("An error occurred during handleRescheduleTask", error);
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.UNEXPECTED_ERROR,
        type: "error",
      });
    }
  }

  async function handleEditCustomer(updateCustomer: customerWithoutTimestamps) {
    if (customerDoc == null) {
      return;
    }

    const initialValuesForCustomer = cloneDeep(customerDoc);

    updateCustomer.customData["membershipTemplateIDs"] = customerMembershipIds;

    /* check the difference between the initial customer doc and the updated one */
    const diffCustomerValues: DocumentData = diffObjects(
      initialValuesForCustomer,
      updateCustomer,
    ).diff;

    if (Object.keys(diffCustomerValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    const validateEditCustomer: ExistingCustomerUpdate = {
      ...diffCustomerValues,
      id: customerDoc.id,
      refPath: customerDoc.refPath,
      lastModifiedBy: firebaseUser.uid,
      timestampLastModified: Timestamp.now(),
    };

    // const { newMembershipIds, deletedMembershipIds } = getUpdatesOnMemberships(
    //   initialValuesForCustomer.customData["membershipTemplateIDs"],
    //   customerMembershipIds,
    // );
    //
    // const validatedMembershipList: Membership_CreateAPI[] = [];
    //
    // /* for newMembershipIds array, needs to write the new docs to DB */
    // if (newMembershipIds.length > 0) {
    //   const newMembershipDocList: Membership_CreateAPI[] = newMembershipIds.map(
    //     (newMembershipId: string) => {
    //       const currentMembershipTemplate = membershipTemplateList.find(
    //         (membershipTemplate) => membershipTemplate.id === newMembershipId,
    //       );
    //
    //       return {
    //         customerID: customerDoc.id,
    //         customerLocationID: null,
    //         customerName: customerDoc.name,
    //         membershipTemplateID: newMembershipId,
    //         status: "awaitingPayment",
    //         notes: null,
    //
    //         createdBy: firebaseUser.uid,
    //         lastModifiedBy: firebaseUser.uid,
    //         siteKey: siteKey,
    //         frequency: currentMembershipTemplate
    //           ? currentMembershipTemplate.frequency
    //           : "indefinite"
    //       };
    //     },
    //   );
    //   const validObjectList = newMembershipDocList.map((membership) =>
    //     MembershipManager.parseCreate(membership),
    //   );
    //   validatedMembershipList.push(...validObjectList);
    // }
    //
    // /* for deletedMembershipIds array, needs to get the membershipID */
    // const membershipIdsToDelete: string[] = [];
    // if (deletedMembershipIds.length !== 0) {
    //   const deletedIdsCount = getMembershipIdsCount(deletedMembershipIds);
    //   for (const [templateId, quantity] of Object.entries(deletedIdsCount)) {
    //     const membershipDocs = await DbRead.memberships.getByTemplateId(
    //       siteKey,
    //       customerDoc.id,
    //       null,
    //       templateId,
    //     );
    //     if (membershipDocs.length > 0) {
    //       for (let i = 0; i < quantity; i++) {
    //         membershipIdsToDelete.push(membershipDocs[i].id);
    //       }
    //     }
    //   }
    // }

    try {
      // if (
      //   validatedMembershipList.length !== 0 &&
      //   membershipIdsToDelete.length !== 0
      // ) {
      //   /* case: edit customer, add new membership docs & delete membership docs */
      //   const promises = [
      //     mutateEditCustomer.mutateAsync({
      //       editCustomer: validateEditCustomer,
      //     }),
      //     mutateAddMemberships.mutateAsync({
      //       validMembershipList: validatedMembershipList,
      //     }),
      //     mutateDeleteMemberships.mutateAsync({
      //       membershipIdsToDelete: membershipIdsToDelete,
      //     }),
      //   ];
      //   await Promise.all(promises);
      // } else if (
      //   validatedMembershipList.length === 0 &&
      //   membershipIdsToDelete.length !== 0
      // ) {
      //   /* case: edit customer & delete membership docs */
      //   const promises = [
      //     mutateEditCustomer.mutateAsync({
      //       editCustomer: validateEditCustomer,
      //     }),
      //     mutateDeleteMemberships.mutateAsync({
      //       membershipIdsToDelete: membershipIdsToDelete,
      //     }),
      //   ];
      //   await Promise.all(promises);
      // } else if (
      //   validatedMembershipList.length !== 0 &&
      //   membershipIdsToDelete.length === 0
      // ) {
      //   /* case: edit customer & add membership docs */
      //   const promises = [
      //     mutateEditCustomer.mutateAsync({
      //       editCustomer: validateEditCustomer,
      //     }),
      //     mutateAddMemberships.mutateAsync({
      //       validMembershipList: validatedMembershipList,
      //     }),
      //   ];
      //   await Promise.all(promises);
      // } else {
      //   /* case: edit customer */
      //   await mutateEditCustomer.mutateAsync({
      //     editCustomer: validateEditCustomer,
      //   });
      // }
      /* case: edit customer */
      await mutateEditCustomer.mutateAsync({
        editCustomer: validateEditCustomer,
      });
      logger.debug("Customer has been updated successfully.");
      addToastMessage({
        dialog: false,
        id: createToastMessageID(),
        message: strings.successfulUpdate(
          updateCustomer.name ?? initialValuesForCustomer.name,
        ),
        type: "success",
      });
    } catch (error) {
      logger.error(`An error occurred during handleSaveNewCustomer`, error);
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.UNEXPECTED_ERROR,
        type: "error",
      });
    }
  }

  function onClickHandlePaymentButton(invoiceID: ExistingStiltInvoice["id"]) {
    setIsHandlePaymentDialogOpen(true);
    setStiltInvoiceID(invoiceID);
  }

  function onClickHandlePaymentTableRow(invoiceID: ExistingStiltInvoice["id"]) {
    setIsTableHandlePaymentDialogOpen(true);
    setStiltInvoiceID(invoiceID);
  }

  async function handleEditNote(partialNote: Pick<Note, "note" | "pinned">) {
    if (
      !customerId ||
      !userPermissions?.permissions.isSiteAdmin ||
      !noteToBeEdited
    )
      return;

    const now = Timestamp.now();

    const diffNoteValues: DocumentData = diffObjects(
      noteToBeEdited,
      partialNote,
    ).diff;

    if (Object.keys(diffNoteValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    const editNoteToValidate: Partial<ExistingNote> = {
      ...diffNoteValues,
      lastModifiedBy: firebaseUser.uid,
      timestampLastModified: now,
    };

    /* validate values before sending to DB */
    const validatedEditNote = NoteManager.parseUpdate(editNoteToValidate);

    try {
      await DbWrite.notes.update(siteKey, noteToBeEdited.id, validatedEditNote);
      logger.debug("Note has been updated successfully.");
      addToastMessage({
        id: createToastMessageID(),
        dialog: true,
        message: strings.successfulUpdate(`Note`),
        type: "success",
      });
      setAddEditNoteOpen(false);
      setNoteToBeEdited(null);
    } catch (error) {
      logger.error(`An error occurred during handleEditNote`, error);
      addToastMessage({
        id: createToastMessageID(),
        dialog: true,
        message: strings.UNEXPECTED_ERROR,
        type: "error",
      });
    }
  }

  async function handleAddNote(partialNote: Pick<Note, "note" | "pinned">) {
    try {
      if (!customerId) return;

      const now = Timestamp.now();

      const noteData: Note = {
        ...partialNote,
        customerID: customerId,
        createdBy: firebaseUser.uid,
        lastModifiedBy: firebaseUser.uid,
        timestampCreated: now,
        timestampLastModified: now,
        deleted: false,
      };

      const validatedData = NoteManager.convertForFirestore(noteData);

      await DbWrite.notes.add(siteKey, validatedData);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.successfulAdd("Customer Note"),
        dialog: false,
        type: "success",
      });
      setAddEditNoteOpen(false);
    } catch (error) {
      console.log(error);
      setAddEditNoteOpen(false);
    }
  }

  async function handleCreateInvoice(estimate: ExistingEstimate) {
    if (!isWhiteLabel(whiteLabel)) throw new Error(`Unexpected white label`);
    try {
      const { paymentURL } = await mutateCreateInvoice.mutateAsync({
        siteKey,
        estimateID: estimate.id,
        version: whiteLabel,
      });
      setIsTableHandlePaymentDialogOpen(true);
      setInvoicePaymentLink(paymentURL);
    } catch (error) {
      logger.error("error on handleCreateInvoice", error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  async function handleInitiateOutboundCall() {
    if (!customerId || !selectedPhoneNumber || !siteKeyDoc) {
      throw new Error(`Unexpected error`);
    }
    try {
      const userSipAccount =
        userPermissions?.customData.sipAccount ??
        siteKeyDoc.customizations.voip?.mainNumber;
      if (!userSipAccount) {
        logger.error(
          "error on handleInitiateOutboundCall",
          "Invalid SIP account configuration",
        );
        addToastMessage({
          id: createToastMessageID(),
          message: "Invalid SIP account configuration",
          dialog: false,
          type: "error",
        });
        return;
      }
      setIsInitiatingOutboundCall(true);
      // TODO: update these hard-coded values!
      await DbWrite.calls.initiateOutboundCall({
        siteKey: siteKey,
        customerPhoneNumber: selectedPhoneNumber,
        customerID: customerId,
        userSipAccount: userSipAccount,
      });
      setIsInitiatingOutboundCall(false);
      setCallDialogOpen(false);
    } catch (error) {
      logger.error("error on handleInitiateOutboundCall", error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  async function handleSelectAddress(selectedAddress: string) {
    if (selectedAddress === "") {
      setDisplayAddressError(true);
    } else {
      const results = await geocodeByAddress(selectedAddress);
      setGeocoderResult(results);
      setAddress(results[0].formatted_address);
    }
  }

  async function handleSelectBillingAddress(selectedAddress: string) {
    if (selectedAddress === "") {
      setGeocoderResult([]);
      setBillingAddressLine1("");
    } else {
      const results = await geocodeByAddress(selectedAddress);
      setGeocoderResult(results);
      setBillingAddressLine1(results[0].formatted_address);
    }
  }

  async function handleDeleteCustomerLocation(
    customerLocation: ExistingCustomerLocation,
  ) {
    // TODO: alert user that if they delete a location with a membership, the
    //  membership will be deleted and any open tasks associated with that
    //  location will be canceled
    const membershipIdsToDelete: string[] = [];
    if (
      customerLocation.customData["membershipTemplateIDs"] &&
      customerLocation.customData["membershipTemplateIDs"].length > 0
    ) {
      const deletedMembershipIds: string[] =
        customerLocation.customData["membershipTemplateIDs"];
      /* for deletedMembershipIds array, needs to get the membershipID */
      const deletedIdsCount = getMembershipIdsCount(deletedMembershipIds);
      for (const [templateId, quantity] of Object.entries(deletedIdsCount)) {
        const membershipDocs = await DbRead.memberships.getByTemplateId(
          siteKey,
          customerLocation.customerID,
          customerLocation.id,
          templateId,
        );
        if (membershipDocs.length > 0) {
          for (let i = 0; i < quantity; i++) {
            membershipIdsToDelete.push(membershipDocs[i].id);
          }
        }
      }
    }

    try {
      await Promise.all([
        mutateDeleteCustomerLocation.mutateAsync({
          customerLocationID: customerLocation.id,
        }),
        mutateDeleteMemberships.mutateAsync({
          membershipIdsToDelete: membershipIdsToDelete,
        }),
      ]);

      logger.debug("Customer Address has been deleted successfully");
      addToastMessage({
        id: createToastMessageID(),
        message: strings.successfulDelete(customerLocation.fullAddress!),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(`An error occurred during handleDeleteCustomer`, error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  function openViewInvoiceDialog(invoiceID: string): void {
    if (!siteKeyDoc) return;

    setViewInvoiceDialogProps({
      siteKeyID: siteKey,
      invoiceID,
      merchantName: siteKeyDoc.name,
      merchantLogoURL:
        siteKeyDoc.customizations.accounting?.merchantLogoURL ?? null,
    });
    setIsInvoiceDialogOpen(true);
    setStiltInvoiceID(invoiceID);
  }

  function closeViewInvoiceDialog(): void {
    setIsInvoiceDialogOpen(false);
    setViewInvoiceDialogProps(null);
  }

  async function handleRefund(
    paymentID: string,
    refundAmount: number,
  ): Promise<void> {
    // try/catch is in the InvoiceSummary component; don't want it here
    return DbWrite.payments.issueRefund(siteKey, paymentID, refundAmount);
  }

  async function openEditExistingCustomerLocation(
    location: ExistingCustomerLocation,
  ) {
    setCustomerLocationDoc(location);

    /* handle membership states */
    if (
      location.customData["membershipTemplateIDs"] &&
      location.customData["membershipTemplateIDs"].length !== 0
    ) {
      setLocationMembershipIds(location.customData["membershipTemplateIDs"]);
      const idsCount = getMembershipIdsCount(
        location.customData["membershipTemplateIDs"],
      );
      const tempData = getTemporaryDataForEditMemberships(idsCount);
      setLocationTemporaryMemberships(tempData);
    } else {
      setLocationMembershipIds([]);
    }

    if (location.fullAddress != null) {
      setAddress(location.fullAddress);
    }

    setAddCustomerLocationDialogOpen(true);
  }

  /* Checks to see if the address changes AND if other locations reference this
  address. If so, display a confirmation dialog*/
  async function handleConfirmUpdateAddress(
    updateLocation: customerLocationWithoutTimestamps,
  ) {
    if (!customerLocationDoc) {
      return;
    }

    if (customerLocationDoc.fullAddress !== updateLocation.fullAddress) {
      setUpdateCustomerLocationConfirmationDialogOpen(true);
      setUpdateLocationData(updateLocation);
    } else {
      handleEditCustomerLocation(updateLocation);
    }
  }

  async function handleEditCustomerLocation(
    updateLocation: customerLocationWithoutTimestamps,
  ) {
    if (customerLocationDoc == null) {
      logger.error("A customer location is not selected");
      return;
    }
    if (!customerDoc) {
      logger.error("Customer doc is null");
      return;
    }
    setIsAddressLoading(true);

    const initialValueForCustomerLocation = cloneDeep(customerLocationDoc);

    updateLocation.customData["membershipTemplateIDs"] = locationMembershipIds;

    /* check the difference between the initial customer doc and the updated one */
    const diffCustomerLocationValues: DocumentData = diffObjects(
      initialValueForCustomerLocation,
      updateLocation,
    ).diff;

    if (Object.keys(diffCustomerLocationValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    diffCustomerLocationValues["id"] = customerLocationDoc.id;
    diffCustomerLocationValues["refPath"] = customerLocationDoc?.refPath;

    /* validate values before sending to DB */
    const validateEditCustomerLocation = CustomerLocationManager.parseUpdate(
      diffCustomerLocationValues,
    );
    // @ts-ignore     ALEX FIXME:
    validateEditCustomerLocation["timestampLastModified"] = Timestamp.now();

    try {
      await mutateEditCustomerLocation.mutateAsync({
        editCustomerLocation: validateEditCustomerLocation,
      });
      logger.debug("Customer Location has been updated successfully");
      addToastMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(updateLocation.fullAddress!),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(
        `An error occurred during handleEditCustomerLocation`,
        error,
      );
      addToastMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    } finally {
      setCustomerLocationDoc(null);
      resetMembershipForEditLocation();
      setIsAddressLoading(false);
      setAddCustomerLocationDialogOpen(false);
      setUpdateLocationData(null);
      setShowManualEntryForm(false);
    }
  }

  async function handleSaveNewCustomerLocation(
    newLocationItem: TemporaryLocation,
  ) {
    if (typeof customerId !== "string") {
      throw new Error(`customerId was not a string: ${customerId}`);
    }
    if (!customerDoc) {
      logger.error("Customer doc is null");
      return;
    }
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    setIsAddressLoading(true);

    const customerLocationID = DbRead.randomDocID.get();

    const newLocation: CustomerLocation_CreateAPI = {
      ...newLocationItem,
      billToCustomerID: null,
      billToCustomerLocationID: null,
      siteKey: siteKey,
      uuid: customerLocationID,
      customerID: customerId,
      createdBy: firebaseUser.uid,
      lastModifiedBy: firebaseUser.uid,
    };

    // const validatedMembershipList: Membership_CreateAPI[] = [];
    //
    // if (locationMembershipIds.length > 0) {
    //   newLocation.customData["membershipTemplateIDs"] = locationMembershipIds;
    //
    //   const newMembershipDocList: Membership_CreateAPI[] =
    //     locationMembershipIds.map((newMembershipId: string) => {
    //       const currentMembershipTemplate = membershipTemplateList.find(
    //         (membershipTemplate) => membershipTemplate.id === newMembershipId,
    //       );
    //
    //       return {
    //         customerID: customerId,
    //         customerLocationID: customerLocationID,
    //         customerName: customerDoc?.name,
    //         membershipTemplateID: newMembershipId,
    //         status: "awaitingPayment",
    //         notes: null,
    //         createdBy: firebaseUser.uid,
    //         lastModifiedBy: firebaseUser.uid,
    //         siteKey: siteKey,
    //         frequency: currentMembershipTemplate
    //           ? currentMembershipTemplate.frequency
    //           : "indefinite"
    //       };
    //     });
    //   const validObjectList = newMembershipDocList.map((membership) =>
    //     MembershipManager.parseCreate(membership),
    //   );
    //   validatedMembershipList.push(...validObjectList);
    // }

    const validatedLocationList =
      CustomerLocationManager.parseCreate(newLocation);
    logger.info("Validated locations list:", validatedLocationList);
    //DB

    try {
      // if (validatedMembershipList.length > 0) {
      //   const promises = [
      //     mutateAddCustomerLocation.mutateAsync({
      //       validLocationList: [validatedLocationList],
      //     }),
      //     mutateAddMemberships.mutateAsync({
      //       validMembershipList: validatedMembershipList,
      //     }),
      //   ];
      //   await Promise.all(promises);
      // } else {
      //   await mutateAddCustomerLocation.mutateAsync({
      //     validLocationList: [validatedLocationList],
      //   });
      // }
      await mutateAddCustomerLocation.mutateAsync({
        validLocationList: [validatedLocationList],
      });
      addToastMessage({
        id: createToastMessageID(),
        message: strings.successfulAdd(newLocationItem.fullAddress!),
        type: "success",
        dialog: false,
      });
    } catch (error) {
      logger.error(
        `An error occurred during handleSaveNewCustomerLocation`,
        error,
      );
      addToastMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    } finally {
      setAddCustomerLocationDialogOpen(false);
      setIsAddressLoading(false);
      setShowManualEntryForm(false);
    }
  }

  async function handleCreateFollowUpTask(
    task: ExistingTask,
    followUpTaskStatus: TaskStatus | null,
  ) {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, refPath, ...rest } = task;

    const newTask: Task_CreateForCustomer = {
      ...rest,
      createdBy: firebaseUser.uid,
      lastModifiedBy: firebaseUser.uid,
      urgent: false,
      nextOpportunity: false,
      workOrder: "",
      crewCount: 0,
      durations: {},
      holdDurations: {},
      taskStatus: followUpTaskStatus ?? rest.taskStatus,
      taskSpecificDetails: {},
      timestampCreated: Timestamp.now().toMillis(),
      timestampLastModified: Timestamp.now().toMillis(),
      timestampScheduled: null,
      timestampAwaitingStart: null,
      timestampTaskCompleted: null,
      timestampTaskStarted: null,
    };

    // Convert to doc data
    const taskReady = TaskRecordManager.convertNewForFirestore(newTask);
    logger.info("taskReady", taskReady);

    await DbWrite.tasks.createForCustomer({
      siteKey: siteKey,
      taskDoc: taskReady,
      estimateID: null,
    });
  }

  async function handleUpdateTask(updateData: DocumentData, taskID: string) {
    await mutateRescheduleTaskDoc.mutateAsync({
      taskUpdate: updateData,
      taskID,
    });
  }

  async function handleUpdateBillingInfo(billingInfo: BillingInfo) {
    if (!customerDoc) {
      return;
    }

    const validateEditCustomer: ExistingCustomerUpdate = {
      billingInfo: billingInfo,
      id: customerDoc.id,
      refPath: customerDoc.refPath,
      lastModifiedBy: firebaseUser.uid,
      timestampLastModified: Timestamp.now(),
    };
    setEditingBillingInfo(true);

    try {
      await mutateEditCustomer.mutateAsync({
        editCustomer: validateEditCustomer,
      });
      logger.debug("Customer Billing Info has been updated successfully");
      addToastMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate("Billing Info"),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(`An error occurred during handleUpdateBillingInfo`, error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    } finally {
      setEditingBillingInfo(false);
      setEditBillingAddressDialogOpen(false);
      setShowManualEntryForm(false);
    }
  }

  async function handleUpdateTSD(updateData: DocumentData, refPath: string) {
    await mutateRescheduleTaskSpecificDetails.mutateAsync({
      taskSpecificDetailsUpdate: updateData,
      taskRefPath: refPath,
    });
  }

  async function handleOpenTaskStatusChangeDialogDueToScheduleChange(args: {
    updatedTask: ExistingTask;
    originalTask: ExistingTask;
  }) {
    if (!siteKeyDoc) {
      // should never happen
      logger.error(
        `missing siteKey doc while opening task status change dialog. task id: ${args.updatedTask.id}`,
      );
      return;
    }

    // Need to get the work record title
    const workRecordID = args.updatedTask.craftRecordID.split("/")[3];
    const workRecordDoc = await DbRead.parentRecords.get(siteKey, workRecordID);
    setWorkRecordTitle(workRecordDoc.title);

    // Get the siteKey's customizations for this task
    const targetWorkType = args.updatedTask.craftType;
    const targetTaskType = args.updatedTask.taskType;
    if (isValidCraftType(targetWorkType) && isValidTaskType(targetTaskType)) {
      const taskCustomFields = getTaskCustomFieldList({
        siteKey: siteKeyDoc,
        targetWorkType,
        targetTaskType,
      });
      setCustomFieldsForTaskStatusChangeDialog(taskCustomFields);
    }

    setTaskForTaskStatusChangeDialog(args.updatedTask);
    setOriginalTaskForTaskStatusChangeDialog(args.originalTask);
    setOpenTaskStatusChangeDialog(args.originalTask.id);
  }

  async function applyBulkManualPayment(
    selectedInvoices: ExistingStiltInvoice[],
    formValues: NewManualPayment,
  ): Promise<void> {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    // Create the array of multi-payments
    const payments: CreateMultiPayment[] = [];
    for (const invoice of selectedInvoices) {
      const values: CreateMultiPayment = {
        ...formValues,
        // IMPORTANT: `amount` must be `formValues.amount`. not the invoice amountDue
        timestampCreated: DateTime.now().toISO(),
        timestampPaymentMade: selectedDateTime.toISO(),
        customerID: invoice.customerID,
        invoiceID: invoice.id,
        customData: {},
        createdBy: firebaseUser.uid,
        billToCustomerID: invoice.billToCustomerID,
        locationID: invoice.locationID,
      };

      payments.push(values);
    }

    const data = {
      payments,
      siteKey: siteKey,
    };

    const validPaymentData = StiltPaymentManager.parseCreateMultiPayment(data);

    try {
      await DbWrite.payments.manualBulkPayment(validPaymentData);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.SUCCESS_MANUAL_PAYMENT_NO_RECEIPT,
        dialog: false,
        type: "success",
      });
    } catch (err) {
      if (err instanceof Error) {
        // 409 = "conflict" error - means that the payment amount did not
        // equal the sum of the amountDue of the given invoices
        if (err.message.includes("409")) {
          addToastMessage({
            id: createToastMessageID(),
            message: strings.PAYMENT_DID_NOT_MATCH_INVOICE_AMOUNTS,
            dialog: false,
            type: "error",
          });
          return;
        }
      }
      logger.error("handleRecordManualPayment", err);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        dialog: false,
        type: "error",
      });
      return;
    }
  }

  async function applyManualPayment(
    formValues: NewManualPayment,
  ): Promise<void> {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    if (!stiltInvoiceID) return;

    if (!selectedInvoice) {
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        dialog: isInvoiceDialogOpen,
        type: "error",
      });
      return;
    }

    const valuesToBeValidated: StiltPayment_CreateAPI = {
      ...formValues,
      stringTimestampPaymentMade: selectedDateTime.toISO(),
      customerID: selectedInvoice.customerID,
      billToCustomerID: selectedInvoice.billToCustomerID,
      locationID: selectedInvoice.locationID,
      invoiceID: stiltInvoiceID,
      customData: {},
      createdBy: firebaseUser.uid,
      siteKey: siteKey,
      deleted: false,
    };

    const validPaymentData =
      StiltPaymentManager.parseCreate(valuesToBeValidated);
    logger.info("validPaymentData", validPaymentData);

    try {
      await mutateRecordManualPayment.mutateAsync({
        paymentData: validPaymentData,
      });
      setStiltInvoiceID(null);
      if (
        validPaymentData.amount === selectedInvoice.amountDue &&
        selectedInvoice.status !== "draft" &&
        siteKeyDoc?.customizations.sendAutomatedReceiptToCustomers
      ) {
        if (selectedInvoice.email !== null) {
          addToastMessage({
            id: createToastMessageID(),
            message: strings.SUCCESS_MANUAL_PAYMENT_WITH_RECEIPT,
            dialog: isInvoiceDialogOpen,
            type: "success",
          });
        } else {
          addToastMessage({
            id: createToastMessageID(),
            message: strings.SUCCESS_MANUAL_PAYMENT_NO_EMAIL,
            dialog: isInvoiceDialogOpen,
            type: "info",
          });
        }
      } else {
        addToastMessage({
          id: createToastMessageID(),
          message: strings.SUCCESS_MANUAL_PAYMENT_NO_RECEIPT,
          dialog: isInvoiceDialogOpen,
          type: "success",
        });
      }
    } catch (err) {
      logger.error("handleRecordManualPayment", err);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        dialog: isInvoiceDialogOpen,
        type: "error",
      });
      return;
    }
  }

  async function handleGetPDFBatch(
    invoiceIDs: string[],
    includeJobPhotos: boolean,
    displayPaymentLink: boolean,
  ) {
    if (siteKeyDoc) {
      await generatePDF.invoice(
        siteKeyDoc,
        invoiceIDs,
        displayPaymentLink,
        includeJobPhotos,
        userPermissions,
      );
    }
  }

  async function handleChangeInvoiceStatusBatch(
    invoiceIDs: string[],
    newStatus: StiltInvoiceStatus,
  ) {
    if (siteKeyDoc) {
      await DbWrite.invoices.batchUpdateStatus(
        siteKeyDoc.id,
        invoiceIDs,
        newStatus,
      );
    }
  }

  async function handleInvoiceActionSelected(
    invoiceActionType: InvoiceActionTypes,
    selectedRows: ExistingStiltInvoice[],
  ) {
    if (invoiceActionType === "convertDraftToPending") {
      const invoiceIDs: any[] = [];
      for (const row of selectedRows) {
        // Only send if there's an email
        if (row.status === "draft") {
          invoiceIDs.push(row.id);
        }
      }
      setConfirmationDialogProps({
        invoiceActionType: "convertDraftToPending",
        body: `Of the ${selectedRows.length} invoices selected, ${invoiceIDs.length} will be converted to Pending. Confirm changing ${invoiceIDs.length} invoices to Pending?`,
        title: "Convert Invoices To Pending Status?",
        isSubmitting: false,
        isOpen: true,
        pdfBatchActionButtons: false,
        pendingInvoiceIDsLength: null,
        onClose: () =>
          setConfirmationDialogProps({
            ...confirmationDialogProps,
            isOpen: false,
          }),
        handleConfirmAction: async () => {
          setInvoiceTableActionsBusy(true);
          setConfirmationDialogProps({
            ...confirmationDialogProps,
            isOpen: false,
          });
          try {
            await handleChangeInvoiceStatusBatch(
              invoiceIDs,
              StiltInvoiceStatus.PENDING,
            );
            addToastMessage({
              id: createToastMessageID(),
              message: `Invoices converted to Pending`,
              dialog: false,
              type: "success",
            });
          } catch (e) {
            addToastMessage({
              id: createToastMessageID(),
              message: `Error converting invoices to pending`,
              dialog: false,
              type: "warning",
            });
          } finally {
            setInvoiceTableActionsBusy(false);
          }
        },
      });
    }
    if (invoiceActionType === "convertZeroAmountInvoicesToPaid") {
      const invoiceIDs: any[] = [];
      for (const row of selectedRows) {
        // Only allow this if invoice has no amountDue and is not canceled
        if (row.status !== "canceled" && row.amountDue === 0) {
          invoiceIDs.push(row.id);
        }
      }
      setConfirmationDialogProps({
        invoiceActionType: "convertZeroAmountInvoicesToPaid",
        body: `Of the ${selectedRows.length} invoices selected, ${invoiceIDs.length} will be converted to Paid. Confirm changing ${invoiceIDs.length} invoices to Paid?`,
        title: "Convert Invoices To Paid Status?",
        isSubmitting: false,
        isOpen: true,
        pdfBatchActionButtons: false,
        pendingInvoiceIDsLength: null,
        onClose: () =>
          setConfirmationDialogProps({
            ...confirmationDialogProps,
            isOpen: false,
          }),
        handleConfirmAction: async () => {
          setInvoiceTableActionsBusy(true);
          setConfirmationDialogProps({
            ...confirmationDialogProps,
            isOpen: false,
          });
          try {
            await handleChangeInvoiceStatusBatch(
              invoiceIDs,
              StiltInvoiceStatus.PAID,
            );
            addToastMessage({
              id: createToastMessageID(),
              message: `Invoices converted to Paid`,
              dialog: false,
              type: "success",
            });
          } catch (e) {
            addToastMessage({
              id: createToastMessageID(),
              message: `Error converting invoices to paid`,
              dialog: false,
              type: "warning",
            });
          } finally {
            setInvoiceTableActionsBusy(false);
          }
        },
      });
    }
    if (invoiceActionType === "generatePDF") {
      setInvoiceTableActionsBusy(true);
      const invoiceIDs: string[] = [];
      const pendingInvoiceIDs: string[] = [];
      for (const row of selectedRows) {
        if (typeof row.id === "string") {
          invoiceIDs.push(row.id);
        }
        if (row.status === StiltInvoiceStatus.PENDING) {
          pendingInvoiceIDs.push(row.id);
        }
      }
      if (pendingInvoiceIDs.length > 0) {
        setConfirmationDialogProps({
          invoiceActionType: "generatePDF",
          body: `Of the ${selectedRows.length} invoices selected, ${pendingInvoiceIDs.length} are still in Pending status. Would you like to change these invoices to Submitted?`,
          title: "Generate PDF",
          isSubmitting: false,
          isOpen: true,
          pdfBatchActionButtons: true,
          pendingInvoiceIDsLength: pendingInvoiceIDs.length,
          onClose: () =>
            setConfirmationDialogProps({
              ...confirmationDialogProps,
              isOpen: false,
            }),
          handleConfirmAction: async (
            status: StiltInvoiceStatus | null,
            includeJobPhotos: boolean,
            displayPaymentLink: boolean,
          ) => {
            setInvoiceTableActionsBusy(true);
            setConfirmationDialogProps({
              ...confirmationDialogProps,
              isOpen: false,
            });
            if (status === StiltInvoiceStatus.SUBMITTED) {
              try {
                await handleChangeInvoiceStatusBatch(
                  pendingInvoiceIDs,
                  StiltInvoiceStatus.SUBMITTED,
                );
                addToastMessage({
                  id: createToastMessageID(),
                  message: `Invoices set to submitted`,
                  dialog: false,
                  type: "success",
                });
              } catch (e) {
                addToastMessage({
                  id: createToastMessageID(),
                  message: `Error converting invoices to submitted`,
                  dialog: false,
                  type: "warning",
                });
              } finally {
                await handleGetPDFBatch(
                  invoiceIDs,
                  includeJobPhotos,
                  displayPaymentLink,
                );
                setInvoiceTableActionsBusy(false);
              }
            } else {
              await handleGetPDFBatch(
                invoiceIDs,
                includeJobPhotos,
                displayPaymentLink,
              );
              setInvoiceTableActionsBusy(false);
            }
          },
        });
      } else {
        setInvoiceTableActionsBusy(true);
        setConfirmationDialogProps({
          invoiceActionType: "generatePDF",
          body: ``,
          title: "Generate PDF",
          isSubmitting: false,
          isOpen: true,
          pdfBatchActionButtons: true,
          pendingInvoiceIDsLength: null,
          onClose: () =>
            setConfirmationDialogProps({
              ...confirmationDialogProps,
              isOpen: false,
            }),
          handleConfirmAction: async (
            status: StiltInvoiceStatus | null,
            includeJobPhotos: boolean,
            displayPaymentLink: boolean,
          ) => {
            setInvoiceTableActionsBusy(true);
            setConfirmationDialogProps({
              ...confirmationDialogProps,
              isOpen: false,
            });
            if (status === StiltInvoiceStatus.SUBMITTED) {
              try {
                await handleChangeInvoiceStatusBatch(
                  pendingInvoiceIDs,
                  StiltInvoiceStatus.SUBMITTED,
                );
                addToastMessage({
                  id: createToastMessageID(),
                  message: `Invoices set to submitted`,
                  dialog: false,
                  type: "success",
                });
              } catch (e) {
                addToastMessage({
                  id: createToastMessageID(),
                  message: `Error converting invoices to submitted`,
                  dialog: false,
                  type: "warning",
                });
              } finally {
                await handleGetPDFBatch(
                  invoiceIDs,
                  includeJobPhotos,
                  displayPaymentLink,
                );
                setInvoiceTableActionsBusy(false);
              }
            } else {
              await handleGetPDFBatch(
                invoiceIDs,
                includeJobPhotos,
                displayPaymentLink,
              );
              setInvoiceTableActionsBusy(false);
            }
          },
        });
      }

      setInvoiceTableActionsBusy(false);
    }
    if (invoiceActionType === "sendEmailInvoice") {
      const invoicesToEmail: ExistingStiltInvoice[] = [];
      for (const row of selectedRows) {
        // Only send if there's an email
        if (typeof row.email === "string") {
          invoicesToEmail.push(row);
        }
      }
      setConfirmationDialogProps({
        invoiceActionType: "sendEmailInvoice",
        body: `Of the ${selectedRows.length} invoices selected, ${invoicesToEmail.length} have email addresses.\n\nConfirm sending ${invoicesToEmail.length} invoices via email?\n\nThis will also update Draft and Pending invoices to Submitted status`,
        title: "Send Invoices Via Email?",
        isSubmitting: false,
        isOpen: true,
        pdfBatchActionButtons: true,
        pendingInvoiceIDsLength: null,
        onClose: () =>
          setConfirmationDialogProps({
            ...confirmationDialogProps,
            isOpen: false,
          }),
        handleConfirmAction: async () => {
          setInvoiceTableActionsBusy(true);
          setConfirmationDialogProps({
            ...confirmationDialogProps,
            isOpen: false,
          });
          const invoicesWithError = [];
          for (const invoice of invoicesToEmail) {
            if (!invoice.email) return;
            const paymentLink = await generatePaymentUniqueLink(
              siteKey,
              invoice.id,
              "email",
            );
            if (!paymentLink) {
              invoicesWithError.push(invoice);
            } else {
              try {
                // await new Promise((r) => setTimeout(r, 500));
                await DbWrite.invoices.sendViaEmail({
                  siteKey,
                  invoiceURL: paymentLink,
                  customerEmailList: [invoice.email],
                  includeJobPhotos: true,
                });
              } catch (_e) {
                invoicesWithError.push(invoice);
              }
            }
          }
          setInvoiceTableActionsBusy(false);
          if (invoicesWithError.length === 0) {
            addToastMessage({
              id: createToastMessageID(),
              message: `Sent invoices to ${invoicesToEmail.length} customers`,
              dialog: false,
              type: "success",
            });
          } else {
            addToastMessage({
              id: createToastMessageID(),
              message: `Error sending ${invoicesWithError.length} invoices`,
              dialog: false,
              type: "warning",
            });
          }
        },
      });
    }
    if (invoiceActionType === "applyManualPayment") {
      if (!siteKeyDoc) return;
      const invoicesToApplyPaymentTo: ExistingStiltInvoice[] = [];
      for (const row of selectedRows) {
        // exclude draft, canceled, and paid invoices
        if (
          row.status !== "paid" &&
          row.status !== "draft" &&
          row.status !== "canceled"
        ) {
          invoicesToApplyPaymentTo.push(row);
        }
      }

      let totalAmount = 0;
      for (const inv of invoicesToApplyPaymentTo) {
        if (inv.amountDue > 0) {
          totalAmount += inv.amountDue;
        } else {
          // remove inv from list
          invoicesToApplyPaymentTo.splice(
            invoicesToApplyPaymentTo.indexOf(inv),
            1,
          );
        }
      }
      totalAmount = getRoundedCurrency(totalAmount);

      if (invoicesToApplyPaymentTo.length === 0) {
        addToastMessage({
          id: createToastMessageID(),
          message:
            "No valid invoices to apply payment to. Only invoices that are in Pending, Submitted, or Partially Paid status can be paid with a multi-invoice payment.",
          dialog: false,
          type: "warning",
        });
        return;
      }

      const siteKeyCurrency =
        typeof siteKeyDoc.customizations.accounting.currency === "string"
          ? siteKeyDoc.customizations.accounting.currency
          : "USD";

      const invoiceMessage = `You are about to apply a payment of ${currencyFormatter(totalAmount, siteKeyCurrency)} to the preceding invoices. Please note that receipts must be sent manually after payment is applied.`;

      setBulkManualPaymentDialogProps({
        isDialogOpen: true,
        closeDialog: () => setBulkManualPaymentDialogProps(null),
        siteKeyDoc: siteKeyDoc,
        amountDue: totalAmount,
        onRecordManualPayment: async (formValues: NewManualPayment) => {
          await applyBulkManualPayment(invoicesToApplyPaymentTo, formValues);
        },
        children: {
          datePicker: (
            <DatePicker
              selected={selectedDateTime.toJSDate()}
              onChange={(date: Date) => {
                const luxonDate = DateTime.fromJSDate(date);
                setSelectedDateTime(luxonDate);
              }}
              // showTimeSelect
              customInput={<SchedulingButton />}
            />
          ),
          multiInvoiceList: (
            <ul>
              {invoicesToApplyPaymentTo.map((inv) => (
                <li key={inv.id}>
                  Invoice Number: {inv.invoiceNumber ?? "Unknown"} -{" "}
                  {currencyFormatter(
                    inv.amountDue,
                    siteKeyDoc?.customizations?.accounting?.currency ?? "USD",
                  )}
                </li>
              ))}
            </ul>
          ),
          multiInvoiceMessage: <p>{invoiceMessage}</p>,
        },
      });
    }
    if (invoiceActionType === "payViaCard") {
      if (!siteKeyDoc) return;
      const invoicesToApplyPaymentTo: ExistingStiltInvoice[] = [];
      for (const row of selectedRows) {
        // exclude draft, canceled, and paid invoices
        if (
          row.status !== "paid" &&
          row.status !== "draft" &&
          row.status !== "canceled"
        ) {
          invoicesToApplyPaymentTo.push(row);
        }
      }

      let totalAmount = 0;
      for (const inv of invoicesToApplyPaymentTo) {
        if (inv.amountDue > 0) {
          totalAmount += inv.amountDue;
        } else {
          // remove inv from list
          invoicesToApplyPaymentTo.splice(
            invoicesToApplyPaymentTo.indexOf(inv),
            1,
          );
        }
      }
      totalAmount = getRoundedCurrency(totalAmount);

      if (invoicesToApplyPaymentTo.length === 0) {
        addToastMessage({
          id: createToastMessageID(),
          message:
            "No valid invoices to apply payment to. Only invoices that are in Pending, Submitted, or Partially Paid status can be paid with a multi-invoice payment.",
          dialog: false,
          type: "warning",
        });
        return;
      }

      const siteKeyCurrency =
        typeof siteKeyDoc.customizations.accounting.currency === "string"
          ? siteKeyDoc.customizations.accounting.currency
          : "USD";

      const invoiceMessage = `You are about to open a payment link to pay ${currencyFormatter(totalAmount, siteKeyCurrency)} via credit card for the preceding invoices. Please note that receipts must be sent manually after payment is applied.`;

      setMultiPayCreditCardDialogProps({
        isDialogOpen: true,
        closeDialog: () => setMultiPayCreditCardDialogProps(null),
        amountDue: totalAmount,
        onSubmitPayment: async () => {
          await handleGetMultiPaymentCreditCardLink(
            invoicesToApplyPaymentTo,
            multiPayCreditCardDialogProps?.amountDue ?? 0,
          );
        },
        children: {
          multiInvoiceList: (
            <ul>
              {invoicesToApplyPaymentTo.map((inv) => (
                <li key={inv.id}>
                  Invoice Number: {inv.invoiceNumber ?? "Unknown"} -{" "}
                  {currencyFormatter(
                    inv.amountDue,
                    siteKeyDoc?.customizations?.accounting?.currency ?? "USD",
                  )}
                </li>
              ))}
            </ul>
          ),
          multiInvoiceMessage: <p>{invoiceMessage}</p>,
        },
      });
    }
  }

  async function payWithCardOnFile(args: {
    invoiceID: string;
    expiry: string;
    lastFour: number;
    amount: number;
  }): Promise<void> {
    if (!siteKeyDoc) throw Error("payWithCardOnFile missing siteKeyDoc");

    const processor = siteKeyDoc.customizations.accounting?.ziftAccountID
      ? "zift"
      : "paya";
    const data: APIPaymentSavedCard = {
      siteKeyID: siteKey,
      invoiceID: args.invoiceID,
      amount: args.amount,
      cardLastFour: args.lastFour,
      cardExpiry: args.expiry,
    };
    const valid = StiltPaymentManager.parseCreateWithSavedCard(data);
    await DbWrite.payments.createWithSavedCard(valid, processor);
  }

  /* RENDER ICON CELL */
  function renderTaskIconCell(params: ICellRendererParams) {
    return (
      <div className="flex items-center">
        <StyledTooltip title="Add Estimate">
          <PlusIconWithRef
            ref={(ref) => {
              if (!ref) return;

              ref.onclick = (e) => {
                e.stopPropagation();
                if (customerDoc) {
                  navToCreateEstimateByTask(customerDoc, params.data);
                }
              };
            }}
          />
        </StyledTooltip>
        <StyledTooltip title="Edit Schedule Date">
          <EditCalendarWithRef
            ref={(ref) => {
              if (!ref) return;

              ref.onclick = (e) => {
                e.stopPropagation();
                openRescheduleTaskDialog(params.data);
              };
            }}
          />
        </StyledTooltip>
      </div>
    );
  }

  function renderAddressIconCell(params: ICellRendererParams) {
    return (
      <div className="flex items-center pr-2">
        <StyledTooltip title="Edit Address">
          <PencilIconWithRef
            onClick={() => openEditExistingCustomerLocation(params.data)}
          />
        </StyledTooltip>
        <StyledTooltip title="Delete Address">
          <TrashIconWithSpinner
            data-testid={`delete-customer-button-${params.data.id}`}
            onDelete={async () => handleDeleteCustomerLocation(params.data)}
          />
        </StyledTooltip>
      </div>
    );
  }

  function renderEstimateIconCell(params: ICellRendererParams) {
    const currentInvoice = customerInvoiceList.find(
      (invoice) => invoice.estimateID === params.data.id,
    );

    return (
      <div className="flex items-center">
        {params.data.taskID == null && !currentInvoice ? (
          <StyledTooltip title="Add Task">
            <PlusIconWithRef
              ref={(ref) => {
                if (!ref) return;

                ref.onclick = (e) => {
                  e.stopPropagation();
                  if (customerDoc && customerLocations) {
                    const customerLocation = customerLocations.find(
                      (location) =>
                        location.id === params.data.customerLocationID,
                    );
                    navToCreateTask(
                      null,
                      customerDoc.id,
                      customerLocation,
                      params.data,
                    );
                  }
                };
              }}
            />
          </StyledTooltip>
        ) : (
          <></>
        )}
        {params.data.taskID !== null ? (
          <StyledTooltip title="View Task">
            <ListIconWithRef
              ref={(ref) => {
                if (!ref) return;

                ref.onclick = (e) => {
                  e.stopPropagation();
                  goToWorkRecordAndTasksPage(params.data.craftRecordID);
                };
              }}
            />
          </StyledTooltip>
        ) : (
          <></>
        )}
        {!currentInvoice ? (
          <StyledTooltip title="Create Invoice">
            <InvoiceIconWithSpinner
              onCreate={async () => handleCreateInvoice(params.data)}
            />
          </StyledTooltip>
        ) : null}
        <StyledTooltip title="Download PDF">
          <PDFIconWithSpinner
            onCreate={async () => getEstimatePDF(params.data.id)}
          />
        </StyledTooltip>
      </div>
    );
  }

  /* COMPONENTS */
  const addEditCustomerContactDialog = (
    <AddEditCustomerContactDialog
      closeDialog={() => {
        setAddEditCustomerContactDialogOpen(false);
        setSelectedCustomerContact(null);
      }}
      isDialogOpen={addEditCustomerContactDialogOpen}
      handleSave={handleSaveCustomerContact}
      handleEdit={handleEditCustomerContact}
      selectedCustomerContact={selectedCustomerContact}
    />
  );

  /** delete the given card on file. */
  function handleDeleteCard(cardToDelete: CardOnFile): void {
    if (!customerDoc || !customerDoc.cardsOnFile) return;
    if (!userPermissions || !userPermissions.permissions.isSiteAdmin) return;

    const foundIndex = customerDoc.cardsOnFile.findIndex(
      (c) =>
        c.expiry === cardToDelete.expiry &&
        c.lastFour === cardToDelete.lastFour &&
        c.name === cardToDelete.name &&
        c.token === cardToDelete.token &&
        c.type === cardToDelete.type,
    );
    const updatedCards = [...customerDoc.cardsOnFile];
    updatedCards.splice(foundIndex, 1);

    if (updatedCards.length + 1 !== customerDoc.cardsOnFile.length) {
      throw new Error(
        `Mismatch in card count: will not delete card ${JSON.stringify(cardToDelete, null, 2)} @ ${customerDoc.refPath}`,
      );
    }
    const update: ExistingCustomerUpdate = {
      id: customerDoc.id,
      refPath: customerDoc.refPath,
      timestampLastModified: Timestamp.now(),
      lastModifiedBy: firebaseUser.uid,
      cardsOnFile: updatedCards,
    };

    logger.info(
      `handleDeleteCard: updating customer ${JSON.stringify(update, null, 2)}`,
    );
    mutateEditCustomer.mutate({ editCustomer: update });
  }

  /** unset the primary card on file. */
  function handleUnsetPrimaryCard(): void {
    if (!customerDoc || !customerDoc.cardsOnFile) return;
    if (!userPermissions || !userPermissions.permissions.isSiteAdmin) return;

    const updatedCards: CardOnFile[] = [];
    for (const card of customerDoc.cardsOnFile) {
      updatedCards.push({
        ...card,
        isPrimary: false,
      });
    }
    const update: ExistingCustomerUpdate = {
      id: customerDoc.id,
      refPath: customerDoc.refPath,
      timestampLastModified: Timestamp.now(),
      lastModifiedBy: firebaseUser.uid,
      cardsOnFile: updatedCards,
    };

    logger.info(
      `handleUnsetPrimaryCard: updating customer ${JSON.stringify(update, null, 2)}`,
    );
    mutateEditCustomer.mutate({ editCustomer: update });
  }

  function updatePrimaryCardOnFile(card: CardOnFile): void {
    if (!customerDoc || !customerDoc.cardsOnFile) return;
    if (!userPermissions || !userPermissions.permissions.isSiteAdmin) return;
    const foundIndex = customerDoc.cardsOnFile.findIndex(
      (c) =>
        c.expiry === card.expiry &&
        c.lastFour === card.lastFour &&
        c.name === card.name &&
        c.token === card.token &&
        c.type === card.type,
    );

    const updatedCards: CardOnFile[] = [];
    for (let i = 0; i < customerDoc.cardsOnFile.length; i++) {
      updatedCards.push({
        ...customerDoc.cardsOnFile[i],
        isPrimary: i === foundIndex,
      });
    }

    const update: ExistingCustomerUpdate = {
      id: customerDoc.id,
      refPath: customerDoc.refPath,
      timestampLastModified: Timestamp.now(),
      lastModifiedBy: firebaseUser.uid,
      cardsOnFile: updatedCards,
    };
    logger.info(
      `updatePrimaryCardOnFile: updating customer ${JSON.stringify(update, null, 2)}`,
    );
    mutateEditCustomer.mutate({ editCustomer: update });
  }

  async function handleClickPageAction(
    actionType: SingleCustomerActions,
  ): Promise<void> {
    if (userPermissions?.permissions.isSiteAdmin !== true) {
      // skill issue
      throw Error("User does not have permission to perform this action");
    }
    switch (actionType) {
      case "generateSchedule":
        await handleGenerateCustomerSchedule();
        break;
      case "generateStatement":
        await handleGenerateCustomerStatements();
        break;
      case "manageCardsOnFile":
        setIsManageCardsDialogOpen(true);
        break;
      case "mergeCustomer":
        setMergeCustomerDialogOpen(true);
        break;
      case "deleteCustomer":
        setConfirmDeleteCustomerDialogOpen(true);
        break;
      default:
        const ec: never = actionType;
        throw new Error(`Unhandled action type: ${ec}`);
    }
  }

  const manageCardsOnFileDialog = customerDoc && (
    <ManageCardsOnFileDialog
      isDialogOpen={isManageCardsDialogOpen}
      closeDialog={() => setIsManageCardsDialogOpen(false)}
      customer={customerDoc}
      updatePrimaryCard={updatePrimaryCardOnFile}
      deleteCard={handleDeleteCard}
      unsetPrimaryCard={handleUnsetPrimaryCard}
    />
  );

  const addNewLocationButton = (
    <BaseButtonPrimary
      type="button"
      onClick={() => setAddCustomerLocationDialogOpen(true)}
      className="w-full sm:max-w-fit"
    >
      <AddCircleIcon fontSize="small" className="mr-2" />
      {strings.buttons.ADD_NEW_ADDRESS}
    </BaseButtonPrimary>
  );

  const dueDatePicker = selectedInvoice && (
    <div className="flex flex-col">
      {selectedDueDate ? `Due Date` : "Due Date Not Selected"}
      <div className="flex items-center gap-4">
        <DatePicker
          selected={
            selectedDueDate
              ? selectedDueDate.toJSDate()
              : DateTime.now().toJSDate()
          }
          onChange={(date: Date) => {
            const luxonDate = DateTime.fromJSDate(date);
            setSelectedDueDate(luxonDate);
            setPaymentTerms(null);
          }}
          customInput={<SchedulingButton />}
        />
      </div>
    </div>
  );

  const issueDatePicker = selectedInvoice && (
    <div className="flex flex-col">
      {selectedIssueDate ? `Issue Date` : "Issue Date Not Selected"}
      <DatePicker
        selected={
          selectedIssueDate
            ? selectedIssueDate.toJSDate()
            : DateTime.now().toJSDate()
        }
        onChange={(date: Date) => {
          const luxonDate = DateTime.fromJSDate(date);
          setSelectedIssueDate(luxonDate);
          setPaymentTerms(null);
        }}
        customInput={<SchedulingButton />}
      />
    </div>
  );

  const paymentTermsSelector = (
    <BaseInputSelect
      inputName="paymentTerms"
      text="Payment Terms"
      admin={true}
      required={true}
      className="capitalize"
      value={paymentTerms === null ? "" : paymentTerms}
      onChange={handlePaymentTermsChange}
    >
      <option value="">None</option>
      {Object.entries(templatesPaymentTerms).map(([key, paymentTerm]) => {
        return (
          <option key={key} value={key}>
            {paymentTerm.title}
          </option>
        );
      })}
    </BaseInputSelect>
  );

  const editInvoiceDialog = selectedInvoice && (
    <EditInvoiceDialog
      isDialogOpen={editInvoiceDialogOpen}
      closeDialog={() => setEditInvoiceDialogOpen(false)}
      invoiceDoc={selectedInvoice}
      handleSave={handleSaveUpdatedInvoice}
      dueDate={dueDatePicker}
      issueDate={issueDatePicker}
      paymentTerms={paymentTermsSelector}
    />
  );

  const confirmDeleteCustomerDialog = customerDoc && (
    <ConfirmDeleteCustomerDialog
      isOpen={confirmDeleteCustomerDialogOpen}
      onClose={() => setConfirmDeleteCustomerDialogOpen(false)}
      handleConfirmDelete={handleDeleteCustomer}
      isSubmitting={isDeleting}
      customer={customerDoc}
    />
  );

  const assetDialog = (
    <AddAssetDialog
      asset={selectedAsset}
      isDialogOpen={assetDetailsDialogOpen}
      customer={customerDoc ?? null}
      customerLocation={customerLocationDoc}
      customerLocationOptions={customerLocations}
      locationID={null}
      siteKey={siteKey}
      closeDialog={() => setAssetDetailsDialogOpen(false)}
      isSubmitting={false}
    ></AddAssetDialog>
  );

  async function handleGetMultiPaymentCreditCardLink(
    selectedInvoices: ExistingStiltInvoice[],
    amount: number,
  ): Promise<void> {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }
    if (!siteKeyDoc) {
      logger.error("siteKeyDoc is null");
      return;
    }

    const paymentSource = siteKeyDoc.customizations?.accounting?.ziftAccountID
      ? "ziftHPP"
      : "payaHPP";

    // Create the array of multi-payments
    const payments: CreateMultiPayment[] = [];
    for (const invoice of selectedInvoices) {
      const valuesToBeValidated: CreateMultiPayment = {
        amount: amount,
        checkNumber: null,
        memo: null,
        paymentMethod: "credit_card",
        paymentSource: paymentSource,
        // IMPORTANT: `amount` must be `formValues.amount`. not the invoice amountDue
        timestampCreated: DateTime.now().toISO(),
        timestampPaymentMade: selectedDateTime.toISO(),
        customerID: invoice.customerID,
        invoiceID: invoice.id,
        customData: {},
        createdBy: firebaseUser.uid,
        billToCustomerID: invoice.billToCustomerID,
        locationID: invoice.locationID,
      };

      payments.push(valuesToBeValidated);
    }

    const uniqueLink = await generateMultiPaymentUniqueLink(
      siteKey,
      selectedInvoices.map((inv) => inv.id),
      "web",
    );
    const paymentID = uniqueLink?.split("/").pop();
    if (!paymentID) {
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERROR_PAYMENT_LINK,
        dialog: false,
        type: "error",
      });
      return;
    }

    try {
      const paymentLink =
        await DbRead.payments.getMultiPayCreditCardData(paymentID);

      if (paymentLink) {
        window.open(paymentLink, "_blank")?.focus();
      } else {
        addToastMessage({
          id: createToastMessageID(),
          message: strings.ERROR_PAYMENT_LINK,
          dialog: false,
          type: "error",
        });
      }
    } catch (err) {
      if (err instanceof Error) {
        // 409 = "conflict" error - means that the payment amount did not
        // equal the sum of the amountDue of the given invoices
        if (err.message.includes("409")) {
          addToastMessage({
            id: createToastMessageID(),
            message: strings.PAYMENT_DID_NOT_MATCH_INVOICE_AMOUNTS,
            dialog: false,
            type: "error",
          });
          return;
        }
      }
      logger.error("handleGetMultiPaymentCreditCardLink", err);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERROR_PAYMENT_LINK,
        dialog: false,
        type: "error",
      });
      return;
    }
  }

  const datePicker = selectedInvoice && (
    <DatePicker
      selected={selectedDateTime.toJSDate()}
      onChange={(date: Date) => {
        const luxonDate = DateTime.fromJSDate(date);
        setSelectedDateTime(luxonDate);
      }}
      showTimeSelect
      customInput={<SchedulingButton />}
    />
  );

  const bulkManualPaymentDialog = bulkManualPaymentDialogProps && (
    <RecordManualPaymentDialog {...bulkManualPaymentDialogProps} />
  );

  const multiPayCreditCardDialog = multiPayCreditCardDialogProps && (
    <MultiPayCreditCardDialog {...multiPayCreditCardDialogProps} />
  );

  /** this one opens when the invoice dialog is already open */
  const handlePaymentDialog = isHandlePaymentDialogOpen &&
    siteKeyDoc &&
    selectedInvoice &&
    customerDoc &&
    userPermissions && (
      <HandlePaymentDialog
        isDialogOpen={isHandlePaymentDialogOpen}
        closeDialog={() => setIsHandlePaymentDialogOpen(false)}
        goToPaymentPage={handleGoToPaymentPage}
        invoiceID={selectedInvoice.id}
        invoiceStatus={selectedInvoice.status}
        invoiceAmount={selectedInvoice.amountDue}
        invoiceSentToCustomer={selectedInvoice.timestampSentToCustomer}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin === true}
        customer={customerDoc}
        payWithCardOnFile={payWithCardOnFile}
        defaultIncludePhotos={
          siteKeyDoc.customizations.defaultIncludeJobPhotos ?? false
        }
        emailList={getEmailList(selectedInvoice, customerDoc)}
        sendEmail={sendEmailFromHandlePaymentDialog}
        paymentMethods={
          siteKeyDoc.customizations.manualPaymentMethods ?? [...paymentMethods]
        }
        applyManualPayment={applyManualPayment}
      >
        {{
          DatePicker: datePicker,
        }}
      </HandlePaymentDialog>
    );

  /** this one opens when table row's action button is clicked */
  const tableHandlePaymentDialog = isTableHandlePaymentDialogOpen &&
    siteKeyDoc &&
    selectedInvoice &&
    customerDoc &&
    userPermissions && (
      <HandlePaymentDialog
        isDialogOpen={isTableHandlePaymentDialogOpen}
        closeDialog={() => {
          setIsTableHandlePaymentDialogOpen(false);
          setInvoicePaymentLink(null);
        }}
        goToPaymentPage={handleGoToPaymentPage}
        invoiceID={selectedInvoice.id}
        invoiceStatus={selectedInvoice.status}
        invoiceAmount={selectedInvoice.amountDue}
        invoiceSentToCustomer={selectedInvoice.timestampSentToCustomer}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin === true}
        customer={customerDoc}
        payWithCardOnFile={payWithCardOnFile}
        defaultIncludePhotos={
          siteKeyDoc.customizations.defaultIncludeJobPhotos ?? false
        }
        emailList={getEmailList(selectedInvoice, customerDoc)}
        sendEmail={sendEmailFromHandlePaymentDialog}
        paymentMethods={
          siteKeyDoc.customizations.manualPaymentMethods ?? [...paymentMethods]
        }
        applyManualPayment={applyManualPayment}
      >
        {{
          DatePicker: datePicker,
        }}
      </HandlePaymentDialog>
    );

  const viewInvoiceDialog = isInvoiceDialogOpen &&
    viewInvoiceDialogProps &&
    userPermissions &&
    !userDisplayNamesMapIsLoading && (
      <ViewInvoiceDialog
        isOpen={isInvoiceDialogOpen}
        onClose={closeViewInvoiceDialog}
        invoiceID={viewInvoiceDialogProps["invoiceID"]}
        siteKeyID={viewInvoiceDialogProps["siteKeyID"]}
        merchantLogoURL={viewInvoiceDialogProps["merchantLogoURL"]}
        merchantName={viewInvoiceDialogProps["merchantName"]}
        handleRefund={handleRefund}
        handlePayment={onClickHandlePaymentButton}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin === true}
        editInvoice={handleEditInvoiceDialogOpen}
        sendEmail={emailInvoice}
        deleteInvoice={handleDeleteInvoice}
        userDisplayNamesMap={userDisplayNamesMap}
        handleDeletePayment={handleDeletePayment}
      >
        {{
          EditInvoiceDialog: editInvoiceDialog,
          HandlePaymentDialog: handlePaymentDialog,
        }}
      </ViewInvoiceDialog>
    );

  const addressError = (
    <div className="mt-2 text-sm">
      <StyledMessage type="error">
        {{ message: strings.REQUIRED_FIELD }}
      </StyledMessage>
    </div>
  );

  function getAddressFieldComponent(args: {
    address: string;
    onChange: (value: string) => void;
    onSelect: (address: string, placeID: string) => void;
  }) {
    const addressField = (
      <div className="flex w-full items-center gap-8">
        <PlacesAutocomplete
          value={args.address}
          onChange={args.onChange}
          onSelect={args.onSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className="w-full">
              <BaseInputText
                {...getInputProps({
                  admin: true,
                  required: true,
                  inputName: "fullAddress",
                  text: "Address",
                })}
              />
              <div
                className={
                  suggestions.length > 0 || loading
                    ? "absolute z-10 max-w-sm rounded border bg-white p-2"
                    : ""
                }
              >
                {loading ? (
                  <div className="p-2 text-primary">
                    <LoadingSpinner />
                  </div>
                ) : null}

                {suggestions.map((suggestion, suggestionIdx) => {
                  const style = {
                    backgroundColor: suggestion.active
                      ? "var(--primary-opacity-90)"
                      : "#fff",
                    borderRadius: "0.25rem",
                    padding: "5px",
                  };
                  return (
                    <p
                      {...getSuggestionItemProps(suggestion, { style })}
                      key={suggestionIdx}
                    >
                      {suggestion.description}
                    </p>
                  );
                })}
              </div>
              {displayAddressError ? addressError : null}
            </div>
          )}
        </PlacesAutocomplete>
        <BaseButtonSecondary
          type="button"
          onClick={() => {
            setShowManualEntryForm(true);
          }}
          className="w-full text-primary sm:w-56"
        >
          <PencilIcon aria-label="save" className="mr-4 h-6" />
          {strings.MANUAL_ENTRY}
        </BaseButtonSecondary>
      </div>
    );
    return addressField;
  }

  const addressField = getAddressFieldComponent({
    address: address,
    onSelect: handleSelectAddress,
    onChange: (newAddress: string) => {
      if (newAddress === "") {
        setDisplayAddressError(true);
        setAddress(newAddress);
      } else {
        setAddress(newAddress);
        setDisplayAddressError(false);
      }
    },
  });

  const addressFieldBillingInfo = getAddressFieldComponent({
    address: billingAddressLine1,
    onSelect: handleSelectBillingAddress,
    onChange: (newAddress: string) => {
      if (newAddress === "") {
        setDisplayAddressError(true);
        setBillingAddressLine1(newAddress);
      } else {
        setBillingAddressLine1(newAddress);
        setDisplayAddressError(false);
      }
    },
  });

  const taskLocation = customerLocations?.find(
    (location) => location.id === originalTaskForTaskStatusChangeDialog?.id,
  );

  const invoicesForTask = taskForTaskStatusChangeDialog
    ? customerInvoiceList.filter(
        (invoice) =>
          invoice.taskID === taskForTaskStatusChangeDialog.id &&
          invoice.status !== "canceled",
      )
    : [];

  /**
   * this taskStatus change dialog is part of the reschedule task flow.
   * not intended to be used with the change taskStatus button.
   */
  const taskStatusChangeDialog = workRecordTitle &&
    taskForTaskStatusChangeDialog &&
    originalTaskForTaskStatusChangeDialog && (
      <TaskStatusChangeDialog
        // DIALOG BASICS
        open={
          openTaskStatusChangeDialog ===
          originalTaskForTaskStatusChangeDialog.id
        }
        onClose={() => {
          setOpenTaskStatusChangeDialog(null);
          setTaskForTaskStatusChangeDialog(null);
          setOriginalTaskForTaskStatusChangeDialog(null);
          setCustomFieldsForTaskStatusChangeDialog([]);
        }}
        // DATA
        showCraftPersistence={false}
        workRecordTitle={workRecordTitle}
        isReschedulingTask={true}
        task={taskForTaskStatusChangeDialog}
        originalTask={cloneDeep(originalTaskForTaskStatusChangeDialog)}
        siteKeyCustomFields={customFieldsForTaskStatusChangeDialog}
        nextTaskStatus={taskForTaskStatusChangeDialog.taskStatus}
        userList={userList.current}
        userDisplayNamesMap={userDisplayNamesMap}
        uid={firebaseUser.uid}
        // FUNCTIONS
        handleUpdateTask={handleUpdateTask}
        handleUpdateTSD={handleUpdateTSD}
        customerLocation={taskLocation ?? customerLocations[0]}
        emailList={emailList}
        createFollowUpTask={handleCreateFollowUpTask}
        invoices={invoicesForTask}
      />
    );

  const editBillingInfoDialog = (
    <EditBillingInfoDialog
      billingInfo={billingInfo}
      isSubmitting={isEditingBillingInfo}
      showManualEntryForm={showManualEntryForm}
      closeDialog={() => {
        setEditBillingAddressDialogOpen(false);
        setShowManualEntryForm(false);
      }}
      isDialogOpen={editBillingAddressDialogOpen}
      geocoderResult={geocoderResult[0]}
      handleUpdateBillingInfo={handleUpdateBillingInfo}
    >
      {{
        AddressField: addressFieldBillingInfo,
      }}
    </EditBillingInfoDialog>
  );

  const createMembershipDialog = siteKeyDoc &&
    siteKeyDoc.customizations.membershipsEnabled &&
    customerDoc && (
      <CreateMembershipDialog
        assetsEnabled={siteKeyDoc.customizations.assetsEnabled === true}
        defaultEmailDuesCollectedReceipt={
          siteKeyDoc.customizations
            .sendAutomatedReceiptToCustomersForMemberships === true
        }
        isDialogOpen={addMembershipDialogOpen}
        customer={customerDoc}
        customerLocation={
          customerLocations.length === 1 ? customerLocations[0] : null
        }
        customerLocationOptions={customerLocations}
        locationID={null}
        siteKey={siteKey}
        closeDialog={() => setAddMembershipDialogOpen(false)}
      />
    );

  const customerMembershipPills = siteKeyDoc?.customizations
    .membershipsEnabled ? (
    <div className="flex flex-wrap gap-2">
      <BaseButtonSecondary
        type="button"
        onClick={() => {
          setAddMembershipDialogOpen(true);
        }}
        className="w-fit uppercase tracking-wider text-primary"
      >
        <AddCircleIcon fontSize="small" className="mr-2" />
        {strings.NEW_MEMBERSHIP}
      </BaseButtonSecondary>
      {membershipsForCustomer.map((membership) => {
        const template = membershipTemplateList.find(
          (template) => template.id === membership.membershipTemplateID,
        );
        return (
          template && (
            <MembershipPill
              key={membership.id}
              membership={membership}
              title={template.title}
            />
          )
        );
      })}
    </div>
  ) : null;

  const customerEquipmentPills = siteKeyDoc?.customizations.assetsEnabled ? (
    <div className="flex flex-wrap gap-2">
      <BaseButtonSecondary
        type="button"
        onClick={() => {
          setAssetDetailsDialogOpen(true);
        }}
        className="col-span-2 w-fit justify-self-end uppercase tracking-wider text-primary"
      >
        <AddCircleIcon fontSize="small" className="mr-2" />
        {strings.CREATE_ASSET_EQUIPMENT}
      </BaseButtonSecondary>
      {customerEquipmentDocs.map((equipmentDoc) => {
        return (
          <EquipmentPill
            key={equipmentDoc.id}
            equipmentDoc={equipmentDoc}
            setAssetDetailsDialogOpen={setAssetDetailsDialogOpen}
            setSelectedAsset={setSelectedAsset}
          />
        );
      })}
    </div>
  ) : null;
  //  : siteKeyDoc?.customizations.assetsEnabled ? (
  //   <BaseButtonSecondary
  //     type="button"
  //     onClick={() => {
  //       setAssetDetailsDialogOpen(true);
  //       // setSelectedAssetID(null);
  //     }}
  //     className="col-span-2 w-fit justify-self-end uppercase tracking-wider text-primary"
  //   >
  //     <AddCircleIcon fontSize="small" className="mr-2" />
  //     {strings.CREATE_ASSET_EQUIPMENT}
  //   </BaseButtonSecondary>
  // ) : null;

  const customerTagChips = customerDoc && (
    <div className="mb-2 flex flex-wrap items-center">
      {customerDoc?.deleted === true && (
        <div className="m-1 rounded-full bg-red-500 px-2.5 py-1 text-xs font-medium capitalize text-white">
          {strings.DELETED}
        </div>
      )}
      {typeof customerDoc.balance === "number" && customerDoc.balance !== 0 && (
        <div
          className={`m-1 rounded-full ${getCustomerBalanceTagColor(customerDoc)} px-2.5 py-1 text-xs font-medium ${getCustomerBalanceTextColor(customerDoc)}`}
        >
          {getCustomerBalanceString(customerDoc, siteKeyDoc)}
        </div>
      )}
      {customerDoc.tags.map((tag, index) => {
        return <ChipTag key={index} tag={tag} />;
      })}
    </div>
  );

  function addOrEditLocationDialog(
    customerType: Customer["type"],
  ): JSX.Element {
    return (
      <div className="space-y-4">
        <AddEditCustomerLocationDialog
          closeDialog={() => {
            setAddCustomerLocationDialogOpen(false);
            resetLocationDialog();
            resetMembershipForAddLocation();
            setShowManualEntryForm(false);
          }}
          isSubmitting={isAddressLoading}
          isDialogOpen={addCustomerLocationDialogOpen}
          geocoderResult={geocoderResult[0]}
          customerType={customerType}
          handleSaveCustomerLocationWithAutocomplete={
            handleSaveNewCustomerLocation
          }
          handleSaveManualCustomerLocation={(
            newLocation: TemporaryLocation,
          ) => {
            newLocation.latitude = siteKeyDoc?.latitude ?? null;
            newLocation.longitude = siteKeyDoc?.longitude ?? null;
            handleSaveNewCustomerLocation(newLocation);
          }}
          showManualEntryForm={showManualEntryForm}
          customerLocationDoc={cloneDeep(customerLocationDoc)}
          handleEditCustomerLocation={(updateLocation) =>
            handleConfirmUpdateAddress(updateLocation)
          }
        >
          {{
            AddressField: addressField,
            AddMembershipsButton: addLocationMembershipButton,
          }}
        </AddEditCustomerLocationDialog>
      </div>
    );
  }

  const confirmUpdateCustomerLocation = updateLocationData && (
    <ConfirmUpdateCustomerLocation
      handleConfirmUpdatingCL={async () => {
        setUpdateCustomerLocationConfirmationDialogOpen(false);
        handleEditCustomerLocation(updateLocationData);
      }}
      isOpen={updateCustomerLocationConfirmationDialogOpen}
      onClose={() => {
        setUpdateCustomerLocationConfirmationDialogOpen(false);
        setUpdateLocationData(null);
      }}
    />
  );

  const rescheduleTaskDialog =
    userPermissions &&
    (taskDoc != null && siteKeyDoc != null ? (
      <RescheduleTaskDialog
        isOpen={rescheduleTaskDialogOpen}
        onClose={() => {
          setRescheduleTaskDialogOpen(false);
          setTaskDoc(null);
        }}
        task={taskDoc}
        handleRescheduleTask={handleRescheduleTask}
        siteKey={siteKeyDoc}
        siteKeyUserPermissions={userPermissions}
        handleOpenTaskStatusChangeDialogDueToScheduleChange={
          handleOpenTaskStatusChangeDialogDueToScheduleChange
        }
        permissionsMap={permissionsMap}
        scheduledAwaitingTaskList={customerTasks.filter(
          (t) =>
            t.taskStatus !== OTaskStatus.COMPLETE &&
            t.taskStatus !== OTaskStatus.CANCELED,
        )}
        siteKeyUsersList={siteKeyUsersList}
        siteLocationList={siteKeyLocationList}
        vehicleList={vehicleList}
      />
    ) : null);

  const createNewEstimateButton = customerDoc && (
    <BaseButtonPrimary
      type="button"
      onClick={() => navToCreateEstimateByTask(customerDoc)}
      className="ml-auto w-full sm:max-w-fit"
    >
      <AddCircleIcon fontSize="small" className="mr-2" />
      {strings.buttons.CREATE_NEW_ESTIMATE}
    </BaseButtonPrimary>
  );

  const customerAndAddressDetails = customerDoc && (
    <div className="my-4 flex flex-grow justify-between space-x-2">
      <div className="w-full space-y-4">
        <CustomerDetails
          customer={customerDoc}
          openEditCustomerDialog={openEditCustomerDialog}
          openCallDialog={
            siteKeyDoc?.customizations.voip ? openCallDialog : null
          }
        />
      </div>
      <div className="w-full">
        <BillingInfoSection
          billingInfo={billingInfo}
          onEditBillingAddress={() => setEditBillingAddressDialogOpen(true)}
        />
      </div>
      <div className="w-full">
        <CustomerContacts
          customerLocations={customerLocations ?? []}
          customerContacts={customerContacts ?? []}
          onContactDeleted={handleDeleteContact}
          onContactEdited={(customerContact: ExistingCustomerContact) => {
            setSelectedCustomerContact(customerContact);
            setAddEditCustomerContactDialogOpen(true);
          }}
          onContactAdded={() => setAddEditCustomerContactDialogOpen(true)}
        />
      </div>
    </div>
  );

  const createNewTaskButton = (
    <BaseButtonPrimary
      type="button"
      onClick={() => navToCreateTask(null, customerId)}
      className="w-full sm:max-w-fit"
    >
      <AddCircleIcon fontSize="small" className="mr-2" />
      {strings.NEW_TASK_FOR_THIS_CUSTOMER}
    </BaseButtonPrimary>
  );

  // const dropdownSelectionTasks = (
  //   <div className="mt-2 flex justify-end gap-2 lg:mt-0">
  //     <div className="flex items-center gap-4">
  //       <span className="mt-1">{createNewTaskButton}</span>
  //       {/*<DropdownSelectionTasksList*/}
  //       {/*  taskStatus={statusFilterOptions}*/}
  //       {/*  onSelectionStatus={setStatusFilter}*/}
  //       {/*/>*/}
  //     </div>
  //   </div>
  // );

  const customerTables =
    activeTableTab === TableTabTypes.HISTORY ? (
      <CustomerHistory
        customerID={customerId}
        siteKey={siteKey}
        closeDialog={() => {}}
        photos={photos}
        handleOpenViewPaymentDialog={openViewInvoiceDialog}
      />
    ) : activeTableTab === TableTabTypes.PHOTOS_AND_ATTACHMENTS ? (
      <CustomerPhotosAndAttachments
        customerID={customerId}
        siteKey={siteKey}
        closeDialog={() => {}}
        photos={photos}
        attachments={[...customerAttachments, ...workRecordAttachments]}
        userPermissions={userPermissions}
      />
    ) : activeTableTab === TableTabTypes.TASKS ? (
      <CustomerTaskListTable
        tasksTableData={customerTasks}
        renderIconCell={renderTaskIconCell}
        goToWorkRecordAndTasksPage={goToWorkRecordAndTasksPage}
      >
        {{ DropdownSelectionTasks: createNewTaskButton }}
      </CustomerTaskListTable>
    ) : activeTableTab === TableTabTypes.JOBS ? (
      <CustomerJobListTable
        jobsTableData={customerCraftRecords}
        goToWorkRecordAndTasksPage={goToWorkRecordAndTasksPage}
      />
    ) : activeTableTab === TableTabTypes.ADDRESSES ? (
      <CustomerLocationsListTable
        addressTableData={customerLocations ?? []}
        renderIconCell={renderAddressIconCell}
        membershipTemplateList={membershipTemplateList}
        customerLocationMemberships={customerLocationMemberships}
        onCellClicked={() => {}}
      >
        {{ AddNewLocationButton: addNewLocationButton }}
      </CustomerLocationsListTable>
    ) : activeTableTab === TableTabTypes.ESTIMATES ? (
      <Fragment>
        {createNewEstimateButton}
        <CustomerEstimateListTable
          customerLocations={customerLocations ?? []}
          estimateItemList={[]}
          estimateTableData={sortedEstimateList}
          customerTaskList={customerTasks}
          renderIconCell={renderEstimateIconCell}
          goToViewEstimate={goToViewEstimate}
          invoiceList={customerInvoiceList}
          currency={siteKeyDoc?.customizations.accounting?.currency ?? "USD"}
        />
      </Fragment>
    ) : activeTableTab === TableTabTypes.INVOICES ? (
      <CustomerInvoiceListTablePage
        currency={siteKeyDoc?.customizations.accounting?.currency ?? "USD"}
        openHandlePaymentDialog={onClickHandlePaymentTableRow}
        invoiceList={filteredListOfInvoices}
        emailReceipt={handleEmailReceipt}
        getPDF={handleGetPDF}
        openInvoiceDialog={openViewInvoiceDialog}
        actionsLoading={invoiceTableActionsBusy}
        invoiceStatus={invoiceStatus}
        setStiltInvoiceStatusSelection={setStiltInvoiceStatusSelection}
        invoiceActionSelected={handleInvoiceActionSelected}
        getLocationTitle={getLocationTitle}
        siteKeyLocationList={siteKeyLocationList}
      />
    ) : activeTableTab === TableTabTypes.CALLS ? (
      <CustomerCallListTablePage customerCalls={customerCalls} />
    ) : null;

  const tableTabs = (
    <TableTabs
      siteKeyCustomizations={siteKeyDoc?.customizations}
      activeTableTab={activeTableTab}
      setActiveTableTab={setActiveTableTab}
    />
  );

  const addCustomerMembershipButton = membershipTemplateList.length > 0 && (
    <Fragment>
      <BaseButtonSecondary
        type="button"
        onClick={() => setAddCustomerMembershipsDialogOpen(true)}
        className="w-full text-primary sm:w-48"
      >
        <AddCircleIcon fontSize="small" className="mr-2" />
        {strings.ADD_MEMBERSHIPS}
      </BaseButtonSecondary>
      <div className="flex flex-wrap gap-2">
        {membershipsForCustomer.map((membership) => {
          const template = membershipTemplateList.find(
            (template) => template.id === membership.membershipTemplateID,
          );
          return (
            template && (
              <MembershipPill
                key={membership.id}
                membership={membership}
                title={template.title}
              />
            )
          );
        })}
      </div>
    </Fragment>
  );

  const addLocationMembershipButton = membershipTemplateList.length > 0 && (
    <Fragment>
      <BaseButtonSecondary
        type="button"
        onClick={() => setAddLocationMembershipsDialogOpen(true)}
        className="w-full text-primary sm:w-48"
      >
        <AddCircleIcon fontSize="small" className="mr-2" />
        {strings.ADD_MEMBERSHIPS}
      </BaseButtonSecondary>
      {filterCustomerLocationMemberships &&
      filterCustomerLocationMemberships.length !== 0 ? (
        <div className="mt-2 flex items-center space-x-2">
          {filterCustomerLocationMemberships.map((membership) => {
            const template = membershipTemplateList.find(
              (template) => template.id === membership.membershipTemplateID,
            );
            return (
              template && (
                <MembershipPill
                  key={membership.id}
                  membership={membership}
                  title={template.title}
                />
              )
            );
          })}
        </div>
      ) : null}
    </Fragment>
  );

  const customerMembershipDialog = (
    <MembershipsAndDiscountsDialog
      closeDialog={() => setAddCustomerMembershipsDialogOpen(false)}
      setNewMembershipIds={setCustomerMembershipIds}
      isDialogOpen={addCustomerMembershipsDialogOpen}
      membershipTemplates={membershipTemplateList}
      membershipIDsCount={customerMembershipIdsCount}
      setTemporaryMemberships={setCustomerTemporaryMemberships}
      temporaryMemberships={customerTemporaryMemberships}
    />
  );

  const locationMembershipDialog = (
    <MembershipsAndDiscountsDialog
      closeDialog={() => setAddLocationMembershipsDialogOpen(false)}
      setNewMembershipIds={setLocationMembershipIds}
      isDialogOpen={addLocationMembershipsDialogOpen}
      membershipTemplates={membershipTemplateList}
      membershipIDsCount={locationMembershipIdsCount}
      setTemporaryMemberships={setLocationTemporaryMemberships}
      temporaryMemberships={locationTemporaryMemberships}
    />
  );

  const editCustomerDialog = customerDoc && (
    <EditCustomerDialog
      isDialogOpen={editCustomerDialogOpen}
      addMembershipsButton={addCustomerMembershipButton}
      closeDialog={() => {
        const tempData = getTemporaryDataForEditMemberships(
          customerMembershipIdsCount,
        );
        setCustomerTemporaryMemberships(tempData);
        setCustomerMembershipIds(existingCustomerMembershipIds ?? []);
        setEditCustomerDialogOpen(false);
      }}
      handleEditCustomer={handleEditCustomer}
      customer={cloneDeep(customerDoc)}
    >
      {{
        AddNewCustomerLocationDialog: addOrEditLocationDialog,
      }}
    </EditCustomerDialog>
  );

  const callDialog = customerDoc && (
    <OutboundCallDialog
      body={`Press OK to initiate a call with ${customerDoc.name}. Your phone will ring and you will then be connected to the customer.`}
      title="Start Outbound Call"
      isOpen={callDialogOpen}
      onClose={() => setCallDialogOpen(false)}
      handleConfirmDial={handleInitiateOutboundCall}
      isSubmitting={isInitiatingOutboundCall}
    />
  );

  const mergeCustomerActionButtons = (
    <div className="mt-4 flex w-full items-center justify-between gap-4">
      <BaseButtonSecondary
        type="button"
        className="w-full justify-center uppercase"
        onClick={() => {
          setMergeCustomerDialogOpen(false);
          setCustomerToMerge(null);
        }}
      >
        {strings.buttons.CANCEL}
      </BaseButtonSecondary>

      <BaseButtonPrimary
        disabled={isMerging}
        isBusy={isMerging}
        busyText={strings.buttons.BUSY_MERGING}
        type="submit"
        className="w-full justify-center uppercase"
        formNoValidate
        onClick={handleMergeCustomer}
      >
        {strings.buttons.CONFIRM}
      </BaseButtonPrimary>
    </div>
  );

  const selectCustomerDialog = customerDoc && (
    <CustomerTableDialog
      isOpen={selectCustomerDialogOpen}
      onClose={() => setSelectCustomerDialogOpen(false)}
      siteKey={siteKey}
      customerIDToExclude={customerDoc.id}
      onSelectCustomer={(c) => {
        setCustomerToMerge(c);
        setSelectCustomerDialogOpen(false);
      }}
      addCustomerButton={null}
    />
  );

  const mergeCustomerDialog = customerDoc && (
    <MergeCustomerDialog
      isOpen={mergeCustomerDialogOpen}
      onClose={() => {
        setCustomerToMerge(null);
        setMergeCustomerDialogOpen(false);
      }}
      setSelectCustomerDialogOpen={() => setSelectCustomerDialogOpen(true)}
      selectedCustomer={customerToMerge}
      mergeToCustomer={customerDoc}
      actionButtons={mergeCustomerActionButtons}
    >
      {{
        SelectCustomerDialog: selectCustomerDialog,
      }}
    </MergeCustomerDialog>
  );

  const selectEmailAddressesDialog = (
    <HandleSendEmailDialog
      defaultIncludeJobPhotos={
        siteKeyDoc?.customizations.defaultIncludeJobPhotos ?? false
      }
      closeDialog={() => setSelectEmailAddressesDialogOpen(false)}
      isDialogOpen={selectEmailAddressesDialogOpen}
      sendEmailReceipt={sendEmailReceipt}
      customerEmailList={emailList}
      title={strings.SEND_RECEIPT_TO_CUSTOMER}
      merchantName={siteKeyDoc?.name ?? ""}
      timestampSentToCustomer={null}
    />
  );

  const confirmationDialog = (
    <ConfirmationDialog
      invoiceActionType={confirmationDialogProps.invoiceActionType}
      isOpen={confirmationDialogProps.isOpen}
      onClose={confirmationDialogProps.onClose}
      handleConfirmAction={confirmationDialogProps.handleConfirmAction}
      isSubmitting={confirmationDialogProps.isSubmitting}
      title={confirmationDialogProps.title}
      body={confirmationDialogProps.body}
      pdfBatchActionButtons={confirmationDialogProps.pdfBatchActionButtons}
      pendingInvoiceIDsLength={confirmationDialogProps.pendingInvoiceIDsLength}
    />
  );

  /* Incoming Call Store */
  const currentCall = useIncomingCallsStore((state) => state.currentCall);
  const clearCurrentCall = useIncomingCallsStore((state) => state.clearCall);
  const alertStyle = useIncomingCallsStore((state) => state.alertStyle());
  const updateCallCustomer = useIncomingCallsStore(
    (state) => state.updateCallCustomer,
  );

  type AlertProps = {
    title: JSX.Element | string;
    message: JSX.Element | string;
    actionButtonText: string;
    style: AlertType;
  };
  const alertPropsMap: Record<AlertStyle, AlertProps> = {
    confirm: {
      style: "info",
      title: (
        <span>
          Confirm customer for call from <strong>{currentCall?.caller}</strong>
        </span>
      ),
      message: (
        <span>
          Confirm <strong>{customerDoc?.name}</strong> for this call?
        </span>
      ),

      actionButtonText: "Confirm",
    },
    match: {
      style: "warning",
      title: (
        <span>
          Customer match needed for call from{" "}
          <strong>{currentCall?.caller}</strong>
        </span>
      ),
      message: (
        <span>
          Match <strong>{customerDoc?.name}</strong> to this call?
        </span>
      ),
      actionButtonText: "Match",
    },
  };
  const alertProps = alertPropsMap[alertStyle];
  const callAlertStyle = alertStyle === "confirm" ? "info" : "warning";

  const callUpdate = useMutation(
    () => {
      if (!customerDoc) {
        throw new Error("Expected customer doc to be present.");
      }
      return updateCallCustomer({
        currentUserID: firebaseUser.uid,
        customerID: customerDoc.id,
      });
    },
    {
      onError: () => {
        logger.error("Could not update call document.");
        clearCurrentCall();
      },
    },
  );

  /* RENDER LOADING */
  if (
    !customerDoc ||
    customerInvoiceListLoading ||
    !userPermissions ||
    !siteKeyDoc ||
    isLoadingMembershipTemplateList ||
    siteKeyDocIsLoading ||
    vehicleListIsLoading
  ) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  // Placing these elements here after the loading check to reduce the amount
  // of needed type checking.
  /**
   * Either the info or warning style to prompt the user with an action.
   */
  const MatchCustomerAlertPrompt = (
    <AlertV2
      variant={callAlertStyle}
      title={alertProps.title}
      message={alertProps.message}
      onDismiss={clearCurrentCall}
      alignActions={"left"}
      actions={
        <>
          {callUpdate.isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <AlertV2Button
                variant={callAlertStyle}
                onClick={() => callUpdate.mutateAsync()}
              >
                {alertProps.actionButtonText}
              </AlertV2Button>
              <AlertV2Button
                variant={callAlertStyle}
                onClick={clearCurrentCall}
              >
                Dismiss
              </AlertV2Button>
            </>
          )}
        </>
      }
    />
  );

  // TODO: extract strings to strings or constants file.

  /**
   * A success alert to show the user that the customer has been matched to the call.
   */
  const MatchCustomerAlertSuccess = (
    <AlertV2
      variant={"success"}
      title={"Successfully updated customer for call"}
      onDismiss={() => {
        callUpdate.reset();
        clearCurrentCall();
      }}
    />
  );
  /**
   * An error alert to show the user that the customer could not be matched to the call.
   */
  const ErrorAlert = (
    <AlertV2
      variant={"error"}
      title={"Could not update call document."}
      message={
        "You can dismiss this alert and reselect the call to try again. If the issue persists, please contact support."
      }
      onDismiss={() => {
        callUpdate.reset();
        clearCurrentCall();
      }}
    />
  );

  const sortedNotes = getCustomerNotesSortedByPinAndTimestamp(notesForCustomer);

  function handleOpenNoteForEdit(note: ExistingNote) {
    setNoteToBeEdited(note);
    setAddEditNoteOpen(true);
  }

  async function handleDeleteCustomerNote(noteID: string) {
    if (!userPermissions?.permissions.isSiteAdmin) return;

    try {
      await DbWrite.notes.delete(siteKey, noteID);
      logger.debug("Note has been deleted successfully.");
      addToastMessage({
        id: createToastMessageID(),
        dialog: true,
        message: strings.successfulDelete(`Note`),
        type: "success",
      });
    } catch (error) {
      logger.error(`An error occurred during handleDeleteCustomerNote`, error);
      addToastMessage({
        id: createToastMessageID(),
        dialog: true,
        message: strings.UNEXPECTED_ERROR,
        type: "error",
      });
    }
  }

  // component that stays on the right side of the screen like a drawer that shows the stream of customer notes
  const customerNotes = (
    <div className="flex w-80 flex-col overflow-y-auto px-4">
      <div className="flex flex-row items-center justify-start gap-2">
        <BaseButtonPrimary
          type="button"
          onClick={() => {
            setNoteToBeEdited(null);
            setAddEditNoteOpen(true);
          }}
          className="w-full max-w-min text-primary"
        >
          <AddIcon />
        </BaseButtonPrimary>
        <h1 className="whitespace-nowrap py-2 text-3xl font-semibold text-primary">
          Customer Notes
        </h1>
      </div>
      {addEditNoteOpen && (
        // field for note with checkbox for pinning
        <AddEditCustomerNote
          onAddNote={handleAddNote}
          onEditNote={handleEditNote}
          closeAddEditNote={(bool: boolean) => {
            setAddEditNoteOpen(bool);
            setNoteToBeEdited(null);
          }}
          noteToBeEdited={noteToBeEdited}
        />
      )}
      <div className="flex flex-col space-y-1">
        {sortedNotes.map((note) => {
          return (
            <CustomerNotesCard
              key={note.id}
              note={note}
              userDisplayNamesMap={userDisplayNamesMap}
              userPermissions={userPermissions}
              onEditCustomerNote={handleOpenNoteForEdit}
              onDeleteCustomerNote={handleDeleteCustomerNote}
            />
          );
        })}
      </div>
    </div>
  );

  const pageActionsDropdown = userPermissions.permissions.isSiteAdmin && (
    <SingleCustomerActionsDropdown
      handleClick={handleClickPageAction}
      isBusy={pageActionsBusy}
    />
  );

  /* RENDER CONTENT */
  return (
    <Wrapper apiKey={apiKey ?? ""} libraries={["places"]}>
      {/* ALERT AREA */}
      {callUpdate.isSuccess && (
        <div className="mx-auto pb-2 lg:max-w-5xl">
          {MatchCustomerAlertSuccess}
        </div>
      )}
      {callUpdate.isError && (
        <div className="mx-auto pb-2 lg:max-w-5xl">{ErrorAlert}</div>
      )}
      {currentCall ? (
        <div className="mx-auto pb-2 lg:max-w-5xl">
          {MatchCustomerAlertPrompt}
        </div>
      ) : null}

      <ShowSingleCustomerPage customer={customerDoc}>
        {{
          AddOrEditLocationDialog: addOrEditLocationDialog,
          EditCustomerDialog: editCustomerDialog,
          RescheduleTaskDialog: rescheduleTaskDialog,
          CustomerAndAddressDetails: customerAndAddressDetails,
          CustomerNotes: customerNotes,
          CustomerTables: customerTables,
          TableTabs: tableTabs,
          HandlePaymentDialog: tableHandlePaymentDialog,
          CustomerMembershipPills: customerMembershipPills,
          CustomerEquipmentPills: customerEquipmentPills,
          CustomerTagChips: customerTagChips,
          ActionsDropdown: pageActionsDropdown,
        }}
      </ShowSingleCustomerPage>
      {customerMembershipDialog}
      {locationMembershipDialog}
      {taskStatusChangeDialog}
      {mergeCustomerDialog}
      {viewInvoiceDialog}
      {confirmUpdateCustomerLocation}
      {addEditCustomerContactDialog}
      {assetDialog}
      {createMembershipDialog}
      {editBillingInfoDialog}
      {selectEmailAddressesDialog}
      {confirmationDialog}
      {callDialog}
      {multiPayCreditCardDialog}
      {bulkManualPaymentDialog}
      {confirmDeleteCustomerDialog}
      {manageCardsOnFileDialog}
    </Wrapper>
  );
}

export function getUpdatesOnMemberships(
  initialMemberships: string[] | undefined,
  updatedMemberships: string[],
): { newMembershipIds: string[]; deletedMembershipIds: string[] } {
  /* convert string array to an object with templateIDs and quantity */
  const initialCount = initialMemberships
    ? getMembershipIdsCount(initialMemberships)
    : [];
  const updatedCount =
    updatedMemberships.length !== 0
      ? getMembershipIdsCount(updatedMemberships)
      : [];

  const newMembershipIds: string[] = [];
  const deletedMembershipIds: string[] = [];

  /* iterate over the initialCount object and compare it with the updatedCount object. check if the initialTemplateId is present: if not, send it to deletedMembershipIds  */
  Object.entries(initialCount).forEach(
    ([initialTemplateId, initialQuantity]) => {
      const answer = Object.keys(updatedCount).includes(initialTemplateId);
      if (answer === false) {
        const stringArray = repeatStringNumTimes(
          initialTemplateId,
          initialQuantity,
        );
        deletedMembershipIds.push(...stringArray);
      }
    },
  );

  /* iterate over the updatedCount object and compare it to the initialCount object. check if the updatedTemplateId is present: if not, repeat the templateId for the quantities and send the result to newMembershipIds  */
  Object.entries(updatedCount).forEach(
    ([updatedTemplateId, updatedQuantity]) => {
      const answer = Object.keys(initialCount).includes(updatedTemplateId);
      if (answer === false) {
        const stringArray = repeatStringNumTimes(
          updatedTemplateId,
          updatedQuantity,
        );
        newMembershipIds.push(...stringArray);
      } else {
        /* if the templateID is present on both updated & initial, I need to check if the quantity is still the same */
        Object.entries(initialCount).forEach(([key, value]) => {
          if (key === updatedTemplateId && updatedQuantity !== value) {
            if (updatedQuantity > value) {
              /* added more memberships */
              const result = updatedQuantity - value;
              const newTemplateIdList = repeatStringNumTimes(key, result);
              newMembershipIds.push(...newTemplateIdList);
            } else {
              /* removed some memberships but not completely */
              const result = value - updatedQuantity;
              const removedTemplateIdsList = repeatStringNumTimes(key, result);
              deletedMembershipIds.push(...removedTemplateIdsList);
            }
          }
        });
      }
    },
  );

  return {
    newMembershipIds,
    deletedMembershipIds,
  };
}

async function handleDeleteInvoice(
  siteKeyID: string,
  invoiceID: string,
): Promise<void> {
  return DbWrite.invoices.delete(siteKeyID, invoiceID);
}

function getCustomerNotesSortedByPinAndTimestamp(
  notesForCustomer: ExistingNote[],
): ExistingNote[] {
  const pinnedNotes = notesForCustomer.filter((note) => note.pinned === true);
  const notPinnedNotes = notesForCustomer.filter(
    (note) => note.pinned !== true,
  );

  const sortPinnedNotes = pinnedNotes.sort(
    (a, b) => b.timestampCreated.toMillis() - a.timestampCreated.toMillis(),
  );
  const sortNotPinnedNotes = notPinnedNotes.sort(
    (a, b) => b.timestampCreated.toMillis() - a.timestampCreated.toMillis(),
  );

  return [...sortPinnedNotes, ...sortNotPinnedNotes];
}

async function handleDeletePayment(
  siteKeyID: string,
  paymentID: string,
): Promise<void> {
  return DbWrite.payments.delete(siteKeyID, paymentID);
}
