import { DateTime } from "luxon";

/**
 * @param expiry - expiration date in the format "MM/YY" (given an ill-formatted
 * string, will return false)
 *
 * (FYI - card is considered expired if it expires this month.)
 */
export function isCardExpired(expiry: string): boolean {
  const today = DateTime.now();
  const [currentYear, currentMonth] = [
    Number(today.year.toString().slice(2)),
    today.month,
  ];
  const [expiryMonth, expiryYear] = expiry.split("/").map(Number);

  if (expiryYear < currentYear) return true;
  if (expiryYear === currentYear && expiryMonth < currentMonth) return true;
  if (expiryYear === currentYear && expiryMonth === currentMonth) return true;
  return false;
}
