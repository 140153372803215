//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import React, {
  forwardRef,
  Fragment,
  useEffect,
  useMemo,
  useState,
} from "react";
import { z } from "zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

//Local
import {
  AddNewPBItem,
  ExistingPriceBookItem,
  priceBookItemTypeValues,
} from "../../models/price-book-item";
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import { ErrorMessage } from "../ErrorMessage";
import BaseInputText from "../BaseInputText";
import StyledMessage from "../StyledMessage";
import BaseInputNumber from "../BaseInputNumber";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import StyledSwitchGroup from "../StyledSwitchGroup";
import { logger as devLogger } from "../../logging";
import BaseInputSelect from "../BaseInputSelect";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import { ExistingSiteKeyUserPermissions } from "../../models/site-key-user-permissions";
import { ConvertArrayToString } from "../../assets/js/HandleOptions";
import { handleTags } from "../../assets/js/HandleTags";
import ButtonColored from "../ButtonColored";
import { ExistingPriceBookItemCategory } from "../../models/price-book-item-category";

type TextareaProps = {
  text: string;
  value?: string;
} & React.ComponentPropsWithRef<"textarea">;
type Ref = HTMLTextAreaElement;
const LocalTextarea = forwardRef<Ref, TextareaProps>(
  (props, ref): JSX.Element => {
    return (
      <div className="relative">
        <textarea
          {...props}
          ref={ref}
          id="description"
          rows={2}
          className="block w-full rounded border border-black p-4 text-gray-700 focus:border-primaryLight focus:outline-none focus:ring focus:ring-primaryLight sm:text-sm"
          value={props.value}
        />
        <label
          htmlFor="description"
          className="absolute -top-3 left-3 bg-white px-2"
        >
          {props.text}
        </label>
      </div>
    );
  },
);

interface Props {
  /* DATA */
  isDialogOpen: boolean;
  siteKeyLocationList: ExistingSiteKeyLocation[];
  priceBookCategories: ExistingPriceBookItemCategory[];
  userPermissions: ExistingSiteKeyUserPermissions;
  priceBookItemDoc: ExistingPriceBookItem | null;
  allowEditingAccountNumbers: boolean;
  allowEditingCommissions: boolean;
  inventoryEnabled: boolean;
  /* FUNCTIONS */
  closeDialog: () => void;
  handleSaveNewPBItem: (newPBItem: AddNewPBItem) => Promise<void>;
  handleDeletePBItem: (pbItemID: string) => Promise<void>;
  handleEditPBItem: (
    updatePBItem: Partial<ExistingPriceBookItem>,
  ) => Promise<void>;
}

export const PBItemFormSchema = z.object({
  title: z.string().min(1).max(500),
  description: z.string().max(2000).nullable(),
  units: z.string().min(1).max(200),
  unitPrice: z.union([z.number(), z.literal("")]),
  type: z.enum(priceBookItemTypeValues),
  taxable: z.boolean(),
  editable: z.boolean(),
  discountable: z.boolean(),
  isInventoryObject: z.boolean().optional(),
  locationID: z.string().min(1).max(200).nullable(),
  account: z.string().max(200).optional(),
  costOfSaleAccount: z.string().max(200).optional(),
  cost: z.union([z.number(), z.literal("")]),
  tags: z.string().min(0).max(2000),
  categoryID: z.string().min(0).max(2000).optional(),
  residential_baseRate: z.union([z.number(), z.literal("")]),
  residential_upsellRate: z.union([z.number(), z.literal("")]),
  commercial_baseRate: z.union([z.number(), z.literal("")]),
  commercial_upsellRate: z.union([z.number(), z.literal("")]),
});

export type PBItemFormState = z.infer<typeof PBItemFormSchema>;

export default function AddEditPriceBookItemDialog({
  priceBookItemDoc,
  userPermissions,
  closeDialog,
  handleSaveNewPBItem,
  isDialogOpen,
  allowEditingAccountNumbers,
  allowEditingCommissions,
  inventoryEnabled,
  handleEditPBItem,
  handleDeletePBItem,
  siteKeyLocationList,
  priceBookCategories,
}: Props) {
  const [displayError, setDisplayError] = useState<boolean>(false);

  const PBItemFormDefaultValues: PBItemFormState = useMemo(() => {
    if (priceBookItemDoc != null) {
      return {
        title: priceBookItemDoc.title,
        description: priceBookItemDoc.description,
        units: priceBookItemDoc.units,
        unitPrice: priceBookItemDoc.unitPrice,
        type: priceBookItemDoc.type ?? priceBookItemTypeValues[0],
        account: priceBookItemDoc.customData.account,
        costOfSaleAccount: priceBookItemDoc.customData.costOfSaleAccount,
        tags: ConvertArrayToString(priceBookItemDoc.tags),
        taxable: priceBookItemDoc.taxable,
        editable: priceBookItemDoc.editable,
        discountable: priceBookItemDoc.discountable,
        isInventoryObject: priceBookItemDoc.isInventoryObject,
        locationID: priceBookItemDoc.locationID,
        cost: priceBookItemDoc.cost ?? "",
        categoryID: priceBookItemDoc.categoryID ?? "",
        residential_baseRate: priceBookItemDoc.commissions?.residential.baseRate
          ? priceBookItemDoc.commissions?.residential.baseRate * 100
          : "",
        residential_upsellRate: priceBookItemDoc.commissions?.residential
          .upsellRate
          ? priceBookItemDoc.commissions?.residential.upsellRate * 100
          : "",
        commercial_baseRate: priceBookItemDoc.commissions?.commercial.baseRate
          ? priceBookItemDoc.commissions?.commercial.baseRate * 100
          : "",
        commercial_upsellRate: priceBookItemDoc.commissions?.commercial
          .upsellRate
          ? priceBookItemDoc.commissions?.commercial.upsellRate * 100
          : "",
      };
    } else {
      return {
        title: "",
        description: "",
        units: "",
        unitPrice: "",
        type: priceBookItemTypeValues[0],
        taxable: false,
        discountable: false,
        isInventoryObject: false,
        editable: true,
        locationID: getDefaultLocationID(userPermissions, siteKeyLocationList),
        tags: "",
        account: "",
        costOfSaleAccount: "",
        cost: "",
        categoryID: "",
        residential_baseRate: "",
        residential_upsellRate: "",
        commercial_baseRate: "",
        commercial_upsellRate: "",
      };
    }
  }, [priceBookItemDoc, userPermissions, siteKeyLocationList]);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<PBItemFormState>({
    defaultValues: PBItemFormDefaultValues,
    resolver: zodResolver(PBItemFormSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(PBItemFormDefaultValues);
  }, [PBItemFormDefaultValues, reset]);

  /* fn that handle all the states that needs to be reset when the dialog is closed */
  function closeAndReset() {
    reset();
    setDisplayError(false);
    closeDialog();
  }

  const defaultSiteLocationTitle = siteKeyLocationList.find(
    (siteLocation) => siteLocation.id === PBItemFormDefaultValues.locationID,
  )?.title;

  const onSubmit: SubmitHandler<PBItemFormState> = async (formValues) => {
    if (priceBookItemDoc != null) {
      const partialPBItemValue: Partial<ExistingPriceBookItem> = {
        title: formValues.title,
        description: formValues.description,
        units: formValues.units,
        unitPrice: formValues.unitPrice === "" ? 0 : formValues.unitPrice,
        type: formValues.type,
        tags: handleTags(formValues.tags),
        taxable: formValues.taxable,
        discountable: formValues.discountable,
        editable: formValues.editable,
        cost: formValues.cost === "" ? 0 : formValues.cost,
        locationID: formValues.locationID,
      };

      if (allowEditingAccountNumbers) {
        partialPBItemValue.customData = {
          account: formValues.account,
          costOfSaleAccount: formValues.costOfSaleAccount,
        };
      }

      if (formValues.categoryID !== "") {
        partialPBItemValue.categoryID = formValues.categoryID;
      }

      if (inventoryEnabled) {
        partialPBItemValue.isInventoryObject = formValues.isInventoryObject;
      }

      if (allowEditingCommissions) {
        const residentialBaseRate =
          typeof formValues.residential_baseRate === "number"
            ? formValues.residential_baseRate / 100
            : null;
        const residentialUpsellRate =
          typeof formValues.residential_upsellRate === "number"
            ? formValues.residential_upsellRate / 100
            : null;
        const commercialBaseRate =
          typeof formValues.commercial_baseRate === "number"
            ? formValues.commercial_baseRate / 100
            : null;
        const commercialUpsellRate =
          typeof formValues.commercial_upsellRate === "number"
            ? formValues.commercial_upsellRate / 100
            : null;

        const commissionsObj = {
          residential: {
            baseRate: residentialBaseRate,
            upsellRate: residentialUpsellRate,
          },
          commercial: {
            baseRate: commercialBaseRate,
            upsellRate: commercialUpsellRate,
          },
        };
        partialPBItemValue.commissions = commissionsObj;
      }

      try {
        await handleEditPBItem(partialPBItemValue);
        // Close dialog if successful.
        closeAndReset();
      } catch (error) {
        setDisplayError(true);
        devLogger.error(error);
      }
    } else {
      const partialPBItemValue: AddNewPBItem = {
        title: formValues.title,
        description: formValues.description,
        units: formValues.units,
        unitPrice: formValues.unitPrice === "" ? 0 : formValues.unitPrice,
        cost: formValues.cost === "" ? 0 : formValues.cost,
        locationID: formValues.locationID,
        discountableForMemberships: true,
        type: formValues.type,
        tags: handleTags(formValues.tags),
        taxable: formValues.taxable,
        editable: formValues.editable,
        discountable: formValues.discountable,
        customData: {},
        deleted: false,
      };

      if (allowEditingAccountNumbers) {
        partialPBItemValue.customData = {
          account: formValues.account,
          costOfSaleAccount: formValues.costOfSaleAccount,
        };
      }

      if (formValues.categoryID !== "") {
        partialPBItemValue.categoryID = formValues.categoryID;
      }

      if (inventoryEnabled) {
        partialPBItemValue.isInventoryObject = formValues.isInventoryObject;
      }

      if (allowEditingCommissions) {
        const residentialBaseRate =
          typeof formValues.residential_baseRate === "number"
            ? formValues.residential_baseRate / 100
            : null;
        const residentialUpsellRate =
          typeof formValues.residential_upsellRate === "number"
            ? formValues.residential_upsellRate / 100
            : null;
        const commercialBaseRate =
          typeof formValues.commercial_baseRate === "number"
            ? formValues.commercial_baseRate / 100
            : null;
        const commercialUpsellRate =
          typeof formValues.commercial_upsellRate === "number"
            ? formValues.commercial_upsellRate / 100
            : null;

        const commissionsObj = {
          residential: {
            baseRate: residentialBaseRate,
            upsellRate: residentialUpsellRate,
          },
          commercial: {
            baseRate: commercialBaseRate,
            upsellRate: commercialUpsellRate,
          },
        };
        partialPBItemValue.commissions = commissionsObj;
      }

      try {
        await handleSaveNewPBItem(partialPBItemValue);
        // Close dialog if successful.
        closeAndReset();
      } catch (error) {
        setDisplayError(true);
        devLogger.error(error);
      }
    }
  };

  const priceBookItemHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {priceBookItemDoc ? strings.EDIT_PRICEBOOKITEM : strings.NEW_ITEM}
      </h1>
      <button type="button" onClick={() => closeAndReset()}>
        <XMarkIcon
          aria-label="close new requirement form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  const errorMessage = priceBookItemDoc ? (
    <ErrorMessage
      message={strings.ERROR_EDITING_PRICEBOOK_ITEM}
      clearMessage={() => setDisplayError(false)}
    />
  ) : (
    <ErrorMessage
      message={strings.ERROR_CREATING_PRICEBOOK_ITEM}
      clearMessage={() => setDisplayError(false)}
    />
  );

  return (
    <BaseModal
      closeModal={closeAndReset}
      open={isDialogOpen}
      title={priceBookItemHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="relative flex flex-col space-y-8 p-8 text-lg">
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-8"
        >
          {/* Field: Location */}
          {defaultSiteLocationTitle && siteKeyLocationList.length === 1 ? (
            <Fragment>
              <span className="pr-2 text-2xl capitalize text-primary">
                Default Site Location:
              </span>
              <span>{defaultSiteLocationTitle}</span>
            </Fragment>
          ) : null}

          {siteKeyLocationList.length > 1 && (
            <div>
              <Controller
                name="locationID"
                control={control}
                render={({ field }) => (
                  <BaseInputSelect
                    inputName="locationID"
                    text="Site Location"
                    admin={true}
                    required={true}
                    className="capitalize"
                    {...field}
                    value={field.value === null ? "" : field.value}
                    onChange={(event) =>
                      field.onChange(
                        event.target.value === "" ? null : event.target.value,
                      )
                    }
                  >
                    {/* The empty string for value tricks validation into failing */}
                    <option value="">None</option>
                    {siteKeyLocationList.map((siteLocation) => {
                      return (
                        <option key={siteLocation.id} value={siteLocation.id}>
                          {siteLocation.title}
                        </option>
                      );
                    })}
                  </BaseInputSelect>
                )}
              />
              {errors.locationID?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.locationID.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
          )}

          {/* Field: Title */}
          <div>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  text="Title"
                  inputName="title"
                  admin={true}
                  required={true}
                  {...field}
                />
              )}
            />
            {errors.title?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.title.message }}
                </StyledMessage>
              </div>
            )}
          </div>
          {/* Field: Description */}
          <div>
            <Controller
              name="description"
              rules={{ maxLength: 4000 }}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <LocalTextarea
                    text={"Description"}
                    {...field}
                    value={field.value ? field.value : undefined}
                    onChange={(ev) => field.onChange(ev.target.value ?? null)}
                  />
                  {fieldState.error &&
                    fieldState.error.type === "maxLength" && (
                      <div className="mt-2">
                        <StyledMessage type="error">
                          {{ message: strings.errExceededMaxLength(2000) }}
                        </StyledMessage>
                      </div>
                    )}
                </>
              )}
            />
          </div>
          {/* Field: Units */}
          <div>
            <Controller
              name="units"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  text="Units"
                  inputName="units"
                  placeholder="each, ft, gallon, etc"
                  admin={true}
                  required={true}
                  {...field}
                />
              )}
            />
            {errors.units?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.units.message }}
                </StyledMessage>
              </div>
            )}
          </div>
          {/* Field: Unit Price */}
          <div>
            <Controller
              name="unitPrice"
              control={control}
              render={({ field }) => (
                <BaseInputNumber
                  text="Unit Price"
                  inputName="unitPrice"
                  admin={true}
                  required={true}
                  {...field}
                  value={field.value}
                  onChange={(event) => {
                    const numberValue = event.target.valueAsNumber;
                    if (displayError === true && numberValue > 0) {
                      setDisplayError(false);
                    }
                    field.onChange(isNaN(numberValue) ? "" : numberValue);
                  }}
                />
              )}
            />
            {errors.unitPrice?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.unitPrice.message }}
                </StyledMessage>
              </div>
            )}
          </div>
          {/* Field: Type */}
          <div>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <BaseInputSelect
                  inputName="type"
                  text="Type"
                  admin={true}
                  required={true}
                  className="capitalize"
                  {...field}
                >
                  {priceBookItemTypeValues.map((type) => {
                    return (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    );
                  })}
                </BaseInputSelect>
              )}
            />
            {errors.type?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.type.message }}
                </StyledMessage>
              </div>
            )}
          </div>
          {/* Field: Type */}
          {priceBookCategories.length > 0 && (
            <div>
              <Controller
                name="categoryID"
                control={control}
                render={({ field }) => (
                  <BaseInputSelect
                    inputName="categoryID"
                    text="Category"
                    admin={true}
                    required={false}
                    className="capitalize"
                    {...field}
                  >
                    {" "}
                    <option value=""></option>
                    {priceBookCategories.map((category) => {
                      return (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      );
                    })}
                  </BaseInputSelect>
                )}
              />
              {errors.categoryID?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.categoryID.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
          )}
          {/* Field: Account */}
          {allowEditingAccountNumbers && (
            <div>
              <Controller
                name="account"
                control={control}
                render={({ field }) => (
                  <BaseInputText
                    text="Account"
                    inputName="account"
                    admin={true}
                    required={false}
                    {...field}
                    value={field.value === null ? "" : field.value}
                  />
                )}
              />
              {errors.type?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.type.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
          )}
          {/* Field: Cost of Sale Account */}
          {allowEditingAccountNumbers && (
            <div>
              <Controller
                name="costOfSaleAccount"
                control={control}
                render={({ field }) => (
                  <BaseInputText
                    text="Cost Of Sale Account"
                    inputName="costOfSaleAccount"
                    admin={true}
                    required={false}
                    {...field}
                    value={field.value === null ? "" : field.value}
                  />
                )}
              />
              {errors.type?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.type.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
          )}
          {/* Field: Tags */}
          <div>
            <Controller
              name="tags"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  text="Tags"
                  inputName="tags"
                  admin={true}
                  required={false}
                  placeholder="Insert your tags, separated by comma"
                  {...field}
                />
              )}
            />
            {errors.tags?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.tags.message }}
                </StyledMessage>
              </div>
            )}
          </div>
          {/* Field: Cost */}
          <div>
            <Controller
              name="cost"
              control={control}
              render={({ field }) => (
                <BaseInputNumber
                  text="Cost"
                  inputName="cost"
                  admin={true}
                  required={false}
                  {...field}
                  value={field.value}
                  onChange={(event) => {
                    const numberValue = event.target.valueAsNumber;
                    if (displayError === true && numberValue > 0) {
                      setDisplayError(false);
                    }
                    field.onChange(isNaN(numberValue) ? "" : numberValue);
                  }}
                />
              )}
            />
            {errors.cost?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.cost.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          {/* COMMISSIONS */}
          {allowEditingCommissions && (
            <div className="grid grid-cols-2">
              <div className="flex flex-col gap-4 border-r pr-4">
                Residential:
                <div>
                  <Controller
                    name="residential_baseRate"
                    control={control}
                    render={({ field }) => (
                      <BaseInputNumber
                        text="Base Rate %"
                        inputName="residential_baseRate"
                        admin={true}
                        required={false}
                        {...field}
                        value={field.value}
                        onChange={(event) => {
                          const numberValue = event.target.valueAsNumber;
                          if (displayError === true && numberValue > 0) {
                            setDisplayError(false);
                          }
                          field.onChange(isNaN(numberValue) ? "" : numberValue);
                        }}
                      />
                    )}
                  />
                  {errors.residential_baseRate?.message && (
                    <div className="mt-2 text-sm">
                      <StyledMessage type="error">
                        {{ message: errors.residential_baseRate.message }}
                      </StyledMessage>
                    </div>
                  )}
                </div>
                <div>
                  <Controller
                    name="residential_upsellRate"
                    control={control}
                    render={({ field }) => (
                      <BaseInputNumber
                        text="Upsell Rate %"
                        inputName="residential_upsellRate"
                        admin={true}
                        required={false}
                        {...field}
                        value={field.value}
                        onChange={(event) => {
                          const numberValue = event.target.valueAsNumber;
                          if (displayError === true && numberValue > 0) {
                            setDisplayError(false);
                          }
                          field.onChange(isNaN(numberValue) ? "" : numberValue);
                        }}
                      />
                    )}
                  />
                  {errors.residential_upsellRate?.message && (
                    <div className="mt-2 text-sm">
                      <StyledMessage type="error">
                        {{ message: errors.residential_upsellRate.message }}
                      </StyledMessage>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-4 pl-4">
                Commercial:
                <div>
                  <Controller
                    name="commercial_baseRate"
                    control={control}
                    render={({ field }) => (
                      <BaseInputNumber
                        text="Base Rate %"
                        inputName="commercial_baseRate"
                        admin={true}
                        required={false}
                        {...field}
                        value={field.value}
                        onChange={(event) => {
                          const numberValue = event.target.valueAsNumber;
                          if (displayError === true && numberValue > 0) {
                            setDisplayError(false);
                          }
                          field.onChange(isNaN(numberValue) ? "" : numberValue);
                        }}
                      />
                    )}
                  />
                  {errors.commercial_baseRate?.message && (
                    <div className="mt-2 text-sm">
                      <StyledMessage type="error">
                        {{ message: errors.commercial_baseRate.message }}
                      </StyledMessage>
                    </div>
                  )}
                </div>
                <div>
                  <Controller
                    name="commercial_upsellRate"
                    control={control}
                    render={({ field }) => (
                      <BaseInputNumber
                        text="Upsell Rate %"
                        inputName="commercial_upsellRate"
                        admin={true}
                        required={false}
                        {...field}
                        value={field.value}
                        onChange={(event) => {
                          const numberValue = event.target.valueAsNumber;
                          if (displayError === true && numberValue > 0) {
                            setDisplayError(false);
                          }
                          field.onChange(isNaN(numberValue) ? "" : numberValue);
                        }}
                      />
                    )}
                  />
                  {errors.commercial_upsellRate?.message && (
                    <div className="mt-2 text-sm">
                      <StyledMessage type="error">
                        {{ message: errors.commercial_upsellRate.message }}
                      </StyledMessage>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* SWITCH GROUP */}
          <div className="grid grid-cols-2 gap-2">
            {/* Field: Taxable */}
            <div>
              <Controller
                name="taxable"
                control={control}
                render={({ field }) => (
                  <StyledSwitchGroup
                    readableName="Taxable"
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    ref={field.ref}
                    checked={field.value}
                    id="taxable"
                    name={field.name}
                  />
                )}
              />
            </div>
            {/* Field: Discountable */}
            <div>
              <Controller
                name="discountable"
                control={control}
                render={({ field }) => (
                  <StyledSwitchGroup
                    readableName="Discountable"
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    ref={field.ref}
                    checked={field.value}
                    id="discountable"
                    name={field.name}
                  />
                )}
              />
            </div>
            {/* Field: Editable */}
            <div>
              <Controller
                name="editable"
                control={control}
                render={({ field }) => (
                  <StyledSwitchGroup
                    readableName="Editable"
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    ref={field.ref}
                    checked={field.value}
                    id="discountable"
                    name={field.name}
                  />
                )}
              />
            </div>
            {/* Field: Inventory Object */}
            {inventoryEnabled && (
              <div>
                <Controller
                  name="isInventoryObject"
                  control={control}
                  render={({ field }) => (
                    <StyledSwitchGroup
                      readableName="Track Inventory"
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      ref={field.ref}
                      checked={field.value ?? false}
                      id="isInventoryObject"
                      name={field.name}
                    />
                  )}
                />
              </div>
            )}
          </div>

          {/* Action Buttons */}
          <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
            {userPermissions.permissions.isSiteAdmin &&
              priceBookItemDoc?.id && (
                <ButtonColored
                  className="w-full justify-center uppercase"
                  kind="danger"
                  type="button"
                  onClick={() => handleDeletePBItem(priceBookItemDoc.id)}
                >
                  {strings.DELETE_ITEM}
                </ButtonColored>
              )}

            <BaseButtonSecondary
              type="button"
              className="w-full justify-center uppercase"
              onClick={closeAndReset}
            >
              {strings.buttons.CANCEL}
            </BaseButtonSecondary>

            <BaseButtonPrimary
              type="submit"
              formNoValidate
              disabled={isSubmitting}
              isBusy={isSubmitting}
              busyText={strings.buttons.BUSY_SAVING}
              className="w-full justify-center uppercase"
            >
              {strings.buttons.SAVE}
            </BaseButtonPrimary>
          </div>
        </form>
        {displayError ? (
          <span className="absolute bottom-10 left-1/2 w-3/4 -translate-x-1/2 sm:w-96">
            {errorMessage}
          </span>
        ) : null}
      </div>
    </BaseModal>
  );
}

export function getDefaultLocationID(
  userPermissions: ExistingSiteKeyUserPermissions,
  siteKeyLocationList: ExistingSiteKeyLocation[],
): string | null {
  if (userPermissions.defaultLocationID) {
    return userPermissions.defaultLocationID;
  } else if (
    userPermissions.defaultLocationID == null &&
    siteKeyLocationList.length === 1
  ) {
    return siteKeyLocationList[0].id;
  } else {
    return null;
  }
}
