// Libs
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/24/solid";
import { Fragment, useCallback, useEffect } from "react";
import { ICellRendererParams } from "ag-grid-community";

// Local
import { ExistingCustomer } from "../../../models/customer";
import { ExistingCustomerLocation } from "../../../models/customer-location";
import * as strings from "../../../strings";
import CustomerLocationsListTable from "../CustomerLocationsListTable";
import { ExistingMembershipTemplate } from "../../../models/membership-template";
import { ExistingMembership } from "../../../models/membership";
import { AddEditLocationManuallyFormState } from "../AddEditLocationManuallyForm";
import { StyledTooltip } from "../../StyledTooltip";
import { PencilIconWithRef } from "../../PencilEditButton";
import BaseInputSelect from "../../BaseInputSelect";

interface Props {
  allowSelection: boolean;
  selectedCustomer: ExistingCustomer | null;
  customerLocationOptions: ExistingCustomerLocation[] | null;
  membershipTemplateList: ExistingMembershipTemplate[];
  selectedCustomerLocation: ExistingCustomerLocation | null;
  customerLocationMemberships: ExistingMembership[];
  setSelectedCustomerLocation: (
    location: ExistingCustomerLocation | null,
  ) => void;
  openEditExistingCustomerLocation?: (
    location: ExistingCustomerLocation,
  ) => Promise<void>;
  // /** use the BaseInputSelect component instead of a headlessUI Listbox. does not apply if there are more than 4 locations to display. */
  /** use the BaseInputSelect component instead of a headlessUI Listbox. */
  useBaseInputSelect?: boolean;
}

export function CustomerLocationSection({
  allowSelection,
  selectedCustomer,
  customerLocationOptions,
  membershipTemplateList,
  selectedCustomerLocation,
  customerLocationMemberships,
  setSelectedCustomerLocation,
  openEditExistingCustomerLocation,
  useBaseInputSelect,
}: Props): JSX.Element {
  useEffect(() => {
    // If there's only one location, the user won't select anything in the dropdown,
    // therefore there will be no onChange event, and we need to set this manually.
    if (customerLocationOptions?.length === 1) {
      setSelectedCustomerLocation(customerLocationOptions[0]);
    }
  }, [customerLocationOptions, setSelectedCustomerLocation]);

  const renderEditAddressCell = useCallback(
    function (params: ICellRendererParams) {
      return (
        <div className="flex items-center">
          {openEditExistingCustomerLocation && (
            <StyledTooltip title="Edit Address">
              <PencilIconWithRef
                onClick={() =>
                  openEditExistingCustomerLocation &&
                  openEditExistingCustomerLocation(params.data)
                }
              />
            </StyledTooltip>
          )}
        </div>
      );
    },
    [openEditExistingCustomerLocation],
  );

  // If we aren't creating a new craft record (so, creating task from existing
  // craftRecord, then we won't let the user change the location, so simply just
  // display the location title
  if (!allowSelection && selectedCustomer) {
    return <div>{getAddress(selectedCustomerLocation)}</div>;
  }

  if (!selectedCustomer || customerLocationOptions === null) {
    return (
      <div className="text-gray-700">
        {/*{strings.selectBlankFirst("customer")}*/}
      </div>
    );
  }

  // TODO: alex - fix the search functionality when the table is displayed when
  // creating a membership; then move this block below the CustomerLocationsListTable block
  if (useBaseInputSelect) {
    return (
      <BaseInputSelect
        id="customer-location-select"
        inputName="customer-location-select"
        text="Customer Location"
        admin
        required
        value={selectedCustomerLocation?.id ?? ""}
        onChange={(event) => {
          const found = customerLocationOptions?.find(
            (location) => location.id === event.target.value,
          );
          setSelectedCustomerLocation(found ?? null);
        }}
      >
        {customerLocationOptions.length > 1 && (
          <option value="" disabled>
            Select a Customer Location
          </option>
        )}
        {customerLocationOptions.map((location) => (
          <option key={location.id} value={location.id}>
            {getAddress(location)}
          </option>
        ))}
      </BaseInputSelect>
    );
  }

  // If there's more than 4 locations for the customer, want to display a table
  // instead of a dropdown.
  if (customerLocationOptions.length > 4) {
    return (
      <div className="z-50">
        <h3 className="mb-2 text-xl text-primary">
          {!selectedCustomerLocation
            ? strings.SELECT_LOCATION
            : strings.SELECTED_LOCATION}
        </h3>
        {selectedCustomerLocation && (
          <div>
            <p>{selectedCustomerLocation.fullAddress}</p>
            <p>{selectedCustomerLocation.addressLine2 ?? ""}</p>
          </div>
        )}
        <div className="mt-4">
          <CustomerLocationsListTable
            addressTableData={customerLocationOptions}
            renderIconCell={
              openEditExistingCustomerLocation ? renderEditAddressCell : null
            }
            membershipTemplateList={membershipTemplateList}
            customerLocationMemberships={customerLocationMemberships}
            onCellClicked={(ev) => setSelectedCustomerLocation(ev.data)}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex gap-4">
      <div className="relative w-64 xs:w-96 md:w-fit md:min-w-[24rem]">
        <Listbox
          // value={theLocation}
          onChange={(event) => {
            // 'event' contains the fullAddress string. Find the corresponding object.
            const found = customerLocationOptions?.find(
              (location) => location.fullAddress === event,
            );
            setSelectedCustomerLocation(found ?? null);
          }}
        >
          <div className="relative mt-1">
            <Listbox.Button className="relative h-10 w-full cursor-pointer rounded-md border border-primary bg-white py-2 pl-3 pr-10 text-left outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primaryLight">
              <span className="block truncate">
                {getDropdownDisplayString(
                  customerLocationOptions,
                  selectedCustomerLocation,
                )}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-700"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute isolate z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {customerLocationOptions.map((location) => (
                  <Listbox.Option
                    key={location.id}
                    className={({ active, selected }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active || selected
                          ? "bg-primaryOpacity90 text-primary"
                          : "text-gray-700"
                      }`
                    }
                    value={location.fullAddress ?? ""}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {getAddress(location)}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
      {selectedCustomerLocation != null && openEditExistingCustomerLocation && (
        <StyledTooltip title="Edit Address">
          <PencilIconWithRef
            onClick={() =>
              selectedCustomerLocation &&
              openEditExistingCustomerLocation &&
              openEditExistingCustomerLocation(selectedCustomerLocation)
            }
          />
        </StyledTooltip>
      )}
    </div>
  );
}

function getDropdownDisplayString(
  locationOptions: ExistingCustomerLocation[],
  selectedCustomerLocation: Props["selectedCustomerLocation"],
): string {
  if (locationOptions.length === 1) {
    return getAddress(locationOptions[0]);
  } else if (!selectedCustomerLocation) {
    return "Select...";
  } else {
    return getAddress(selectedCustomerLocation);
  }
}

export function getAddress(
  location: ExistingCustomerLocation | AddEditLocationManuallyFormState | null,
): string {
  if (!location) return "";

  let address = "";

  if (location.addressLine1 != null && location.addressLine1.trim() !== "") {
    address = location.addressLine1;
  }

  if (location.addressLine2 != null && location.addressLine2.trim() !== "") {
    address += ", " + location.addressLine2;
  }

  if (location.city != null && location.city.trim() !== "") {
    address += ", " + location.city;
  }

  if (location.state != null && location.state.trim() !== "") {
    address += ", " + location.state;
  }

  if (location.zipCode != null && location.zipCode.trim() !== "") {
    address += " " + location.zipCode;
  }

  return address;
}
