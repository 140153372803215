//Libs
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  GetQuickFilterTextParams,
  GridReadyEvent,
  ICellRendererParams,
  ValueGetterFunc,
  ValueGetterParams,
} from "ag-grid-community";
import isEqual from "lodash/isEqual";

//Local
import { ExistingStiltInvoice } from "../../models/invoice";
import * as strings from "../../strings";
import { convertToReadableTimestampDate } from "../../assets/js/convertToReadableTimestamp";
import { StyledTooltip } from "../../components/StyledTooltip";
import { ButtonViewCustomer } from "../../components/ButtonViewCustomer";
import currencyFormatter from "../../currency";
import SearchBox from "../../components/SearchBox";
import {
  ExistingMembership,
  getReadableMembershipStatus,
} from "../../models/membership";
import { ExistingMembershipTemplate } from "../../models/membership-template";
import { PlusIconWithRef } from "../../components/PlusAddButton";
import { useNavToCreateTask } from "../../navigation";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import {
  getDateFromStartDateAndDaysAfterRenewal,
  getDateFromStartDateAndDaysAfterRenewalyyyymmdd,
} from "../../components/Memberships/MembershipTasksOverview";
import { getMembershipTagBgColor } from "./MembershipsListContainer";

interface Props {
  /* DATA */
  membershipTemplates: ExistingMembershipTemplate[];
  memberships: ExistingMembership[];
  currency: string;
  // dateRange: RangeValue<CalendarDate | ZonedDateTime | CalendarDateTime>;
  /* FUNCTIONS */
  goToCustomerPage: (customerID: ExistingStiltInvoice["customerID"]) => void;
  goToWorkRecordAndTasksPage: (
    craftRecordID: ExistingStiltInvoice["craftRecordID"],
  ) => void;
  goToPayment: (invoiceID: ExistingStiltInvoice["id"]) => void;
  goToMembershipDetailsPage: (membershipID: string) => void;
  // emailReceipt: (invoiceID: ExistingStiltInvoice["id"]) => Promise<void>;
  // handleOpenViewMembershipDetailsDialog: (membershipID: string) => void;
  CreateMembershipButton: React.ReactNode;
  /* CHILDREN */
  children: {
    ActionButtons: React.ReactNode;
    StatusDropdown: React.ReactNode;
    MembershipMetrics: React.ReactNode;
    // InvoiceDetailsBox: React.ReactNode;
    // InvoiceDateRangePicker: React.ReactNode;
  };
}

export function nextExpectedTaskDate(
  membershipTemplate: ExistingMembershipTemplate[],
  membership: ExistingMembership,
) {
  const expectedTaskDates = membershipTemplate[0].taskGeneration.map((task) => {
    return getDateFromStartDateAndDaysAfterRenewalyyyymmdd(membership, task);
  });
  const membershipTasksLength = membership.tasks
    ? Object.keys(membership.tasks).length
    : 0;
  if (membershipTasksLength === 0) {
    return expectedTaskDates[0];
  }

  if (
    membershipTasksLength === expectedTaskDates.length ||
    membershipTasksLength > expectedTaskDates.length
  ) {
    return "";
  }

  const nextExpectedTaskDate = expectedTaskDates[membershipTasksLength];

  return nextExpectedTaskDate;
}

export default function MembershipsListPage(props: Props) {
  const navToCreateTask = useNavToCreateTask();

  const renderTimestamp = (params: ICellRendererParams): JSX.Element => {
    if (params.value === null) {
      return <div></div>;
    }
    return <div>{convertToReadableTimestampDate(params.value)}</div>;
  };

  const renderLastRenewal = (params: ICellRendererParams): JSX.Element => {
    if (params.data.lastPaymentDate) {
      return (
        <div>
          {convertToReadableTimestampDate(params.value) +
            " - " +
            currencyFormatter(params.data.lastPaymentAmount, props.currency)}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  // const renderTimestampDueDate = (params: ICellRendererParams): JSX.Element => {
  //   if (params.data.amountDue === 0 || params.data.status === "paid") {
  //     return <div></div>;
  //   } else {
  //     if (params.data.dueDate == null) {
  //       return <div>0</div>;
  //     } else {
  //       const startDate: Timestamp = params.value;
  //       const endDate = Timestamp.now();

  //       //ACTION
  //       const daysDifference = getTimeDifferenceInDays({ startDate, endDate });
  //       return <div>{daysDifference}</div>;
  //     }
  //   }
  // };

  const getNextExpectedTaskTimestamp: ValueGetterFunc = useCallback(
    function (params: ValueGetterParams) {
      const membership = params.data as ExistingMembership;
      const membershipTemplate = props.membershipTemplates.filter(
        (mT) => mT.id === params.data.membershipTemplateID,
      );
      if (membershipTemplate.length !== 1) {
        return "";
      }

      return nextExpectedTaskDate(membershipTemplate, membership);
    },
    [props.membershipTemplates],
  );

  const renderNextExpectedTaskTimestamp = (
    params: ICellRendererParams,
  ): JSX.Element => {
    const membershipTemplate = props.membershipTemplates.filter(
      (mT) => mT.id === params.data.membershipTemplateID,
    );
    if (membershipTemplate.length !== 1) {
      return <div></div>;
    }

    const expectedTaskDates = membershipTemplate[0].taskGeneration.map(
      (task) => {
        return getDateFromStartDateAndDaysAfterRenewal(params.data, task);
      },
    );

    const membership = params.data as ExistingMembership;
    const membershipTasksLength = membership.tasks
      ? Object.keys(membership.tasks).length
      : 0;
    if (membershipTasksLength === 0) {
      return <div>{expectedTaskDates[0]}</div>;
    }

    if (
      membershipTasksLength === expectedTaskDates.length ||
      membershipTasksLength > expectedTaskDates.length
    ) {
      return <div></div>;
    }

    const nextExpectedTaskDate = expectedTaskDates[membershipTasksLength];

    return <div>{nextExpectedTaskDate}</div>;
  };

  const renderName = (params: ICellRendererParams): JSX.Element => (
    <div>{params.data.customerName ?? ""}</div>
  );

  const renderStatus = (params: ICellRendererParams): JSX.Element => {
    return (
      <div className="flex items-center">
        <div
          className={`m-1 rounded-full px-2.5 pb-1 pt-0.5 text-xs font-medium ${getMembershipTagBgColor(params.data.status)} capitalize`}
        >
          {getReadableMembershipStatus(params.data.status)}
        </div>
      </div>
    );
  };

  const renderTotalAmount = (params: ICellRendererParams): JSX.Element => {
    return (
      <div>{currencyFormatter(params.data.totalAmount, props.currency)}</div>
    );
  };

  const renderNotes = (params: ICellRendererParams): JSX.Element => {
    return <div>{params.data.notes}</div>;
  };

  const getTemplateTitle = (
    params: ICellRendererParams | ValueGetterParams | GetQuickFilterTextParams,
  ): string => {
    const membershipTemplate = props.membershipTemplates.filter(
      (mT) => mT.id === params.data.membershipTemplateID,
    );
    if (membershipTemplate.length === 1) {
      return membershipTemplate[0].title;
    }
    return "Unknown Membership";
  };

  const renderTasks = (params: ICellRendererParams): JSX.Element => {
    const membershipTemplate = props.membershipTemplates.filter(
      (mT) => mT.id === params.data.membershipTemplateID,
    );
    if (membershipTemplate.length === 1) {
      let tasksCompleted = 0;
      if (params.data.tasks) {
        tasksCompleted = Object.keys(params.data.tasks).length;
      }
      return (
        <div>
          {tasksCompleted + "/" + membershipTemplate[0].taskGeneration.length}
        </div>
      );
    }
    return <div>Unknown Membership</div>;
  };

  const renderIconCell = useCallback(
    function (params: ICellRendererParams) {
      return (
        <div className="flex items-center">
          <StyledTooltip title="Create Task">
            <PlusIconWithRef
              ref={(ref) => {
                if (!ref) return;

                ref.onclick = (e) => {
                  e.stopPropagation();
                  navToCreateTask(
                    null,
                    params.data.customerID,
                    params.data.customerLocationID,
                    undefined,
                    {
                      membershipTemplateID: params.data.membershipTemplateID,
                      membershipID: params.data.id,
                    },
                  );
                };
              }}
            />
          </StyledTooltip>
          <StyledTooltip title="View Customer">
            <ButtonViewCustomer
              ref={(ref) => {
                if (!ref) return;

                ref.onclick = (e) => {
                  e.stopPropagation();
                  props.goToCustomerPage(params.data.customerID);
                };
              }}
            />
          </StyledTooltip>
        </div>
      );
    },
    [navToCreateTask, props],
  );

  const [gridReady, setGridReady] = useState(false);
  const gridRef = useRef<any>(null);

  const onGridReady = useCallback(() => {
    setGridReady(true);
  }, []);

  useLayoutEffect(() => {
    if (gridRef.current && gridReady) {
      gridRef.current.api?.sizeColumnsToFit();
    }
  }, [gridReady]);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current!.api.setQuickFilter(
      (document.getElementById("filter-text-box") as HTMLInputElement).value,
    );
  }, []);

  return (
    <div className="space-y-4">
      <h1 className="text-5xl font-semibold text-primary">
        {strings.MEMBERSHIPS}
      </h1>
      {props.children.MembershipMetrics}
      {/*{props.children.InvoiceDetailsBox}*/}
      <div className={"ag-theme-alpine isolate h-[600px] pb-14"}>
        <div className="flex flex-col-reverse items-center gap-4 sm:flex-row sm:items-end sm:justify-between">
          <SearchBox
            onInput={onFilterTextBoxChanged}
            widthClasses="w-full sm:max-w-sm"
          />
          <div className="flex flex-row gap-4">
            <div>{props.children.StatusDropdown}</div>
            <div className="flex flex-col gap-4">
              <div>{props.CreateMembershipButton}</div>
            </div>
            {props.children.ActionButtons}
          </div>
        </div>
        <MembershipsTable
          memberships={props.memberships}
          // dateRange={props.dateRange}
          renderTimestamp={renderTimestamp}
          renderName={renderName}
          renderTotalAmount={renderTotalAmount}
          renderNotes={renderNotes}
          getTemplateTitle={getTemplateTitle}
          renderLastRenewal={renderLastRenewal}
          renderTasks={renderTasks}
          renderIconCell={renderIconCell}
          renderStatus={renderStatus}
          getNextExpectedTaskTimestamp={getNextExpectedTaskTimestamp}
          renderNextExpectedTaskTimestamp={renderNextExpectedTaskTimestamp}
          handleOpenViewMembershipDetailsDialog={
            props.goToMembershipDetailsPage
          }
          gridRef={gridRef}
          onGridReady={onGridReady}
        />
      </div>
    </div>
  );
}

/* Stuff for Table Component */

const commonColProps = {
  minWidth: 150,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass:
    "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase",
};

interface MembershipsTableProps {
  memberships: Props["memberships"];
  // dateRange: Props["dateRange"];
  renderTimestamp: (params: ICellRendererParams) => JSX.Element;
  renderName: (params: ICellRendererParams) => JSX.Element;
  renderTotalAmount: (params: ICellRendererParams) => JSX.Element;
  renderNotes: (params: ICellRendererParams) => JSX.Element;
  getTemplateTitle: (
    params: ICellRendererParams | ValueGetterParams | GetQuickFilterTextParams,
  ) => string;
  renderTasks: (params: ICellRendererParams) => JSX.Element;
  renderLastRenewal: (params: ICellRendererParams) => JSX.Element;
  renderIconCell: (params: ICellRendererParams) => JSX.Element;
  renderNextExpectedTaskTimestamp: (params: ICellRendererParams) => JSX.Element;
  renderStatus: (params: ICellRendererParams) => JSX.Element;
  getNextExpectedTaskTimestamp: ValueGetterFunc;
  handleOpenViewMembershipDetailsDialog: (membershipID: string) => void;
  gridRef: React.MutableRefObject<any>;
  onGridReady: (event: GridReadyEvent) => void;
}

/* TABLE COMPONENT */
const MembershipsTable = React.memo(
  ({
    memberships,
    // dateRange,
    renderTimestamp,
    renderName,
    // renderTotalAmount,
    getTemplateTitle,
    renderIconCell,
    renderTasks,
    renderNextExpectedTaskTimestamp,
    renderStatus,
    renderNotes,
    renderLastRenewal,
    handleOpenViewMembershipDetailsDialog,
    gridRef,
    getNextExpectedTaskTimestamp,
    onGridReady,
    // ...props
  }: MembershipsTableProps) => {
    // function tasksCompletedComparator(valueA: any, valueB: any) {
    //   console.log(valueA);
    //   // var date1Number = date1 && new Date(date1).getTime();
    //   // var date2Number = date2 && new Date(date2).getTime();

    //   if (valueA == null && valueB == null) {
    //     return 0;
    //   }

    //   if (valueA == null) {
    //     return -1;
    //   } else if (valueB == null) {
    //     return 1;
    //   }

    //   return valueA - valueB;
    // }

    const columnDefs: ColDef[] = [
      {
        headerName: "Customer Name",
        field: "customerID",
        width: 200,
        cellRenderer: renderName,
        valueGetter: (params) => params.data.customerName,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        getQuickFilterText: (params: GetQuickFilterTextParams) => {
          return params.data.customerName;
        },
        onCellClicked: (ev) =>
          handleOpenViewMembershipDetailsDialog(ev.data.id),
      },
      {
        field: "membershipTemplateID",
        headerName: "Membership",
        width: 200,
        cellRenderer: getTemplateTitle,
        valueGetter: getTemplateTitle,
        getQuickFilterText: getTemplateTitle,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        onCellClicked: (ev) =>
          handleOpenViewMembershipDetailsDialog(ev.data.id),
      },
      // {
      //   field: "tasksCompleted",
      //   headerName: "Tasks Completed",
      //   width: 200,
      //   cellRenderer: renderTasksCompleted,
      //   tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      //   onCellClicked: (ev) => handleOpenViewPaymentDialog(ev.data.id),
      // },
      {
        headerName: "Status",
        field: "status",
        cellRenderer: renderStatus,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        cellStyle: {
          height: "100%",
          display: "flex",
          alignItems: "center",
        },
        onCellClicked: (ev) =>
          handleOpenViewMembershipDetailsDialog(ev.data.id),
      },
      {
        headerName: "Tasks",
        cellRenderer: renderTasks,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        cellStyle: {
          height: "100%",
          display: "flex",
          alignItems: "center",
        },
        minWidth: 50,
        width: 120,
        comparator: (valueA, valueB, nodeA, nodeB, _isDescending) => {
          const tasksA = nodeA.data.tasks;
          const tasksB = nodeB.data.tasks;

          let tasksALength = 0;
          let tasksBLength = 0;

          if (tasksA) {
            tasksALength = Object.keys(tasksA).length;
          }

          if (tasksB) {
            tasksBLength = Object.keys(tasksB).length;
          }

          console.log(nodeA);
          if (tasksALength === tasksBLength) return 0;
          return tasksALength > tasksBLength ? 1 : -1;
        },
        onCellClicked: (ev) =>
          handleOpenViewMembershipDetailsDialog(ev.data.id),
      },
      {
        headerName: "Next Expected Task",
        valueGetter: getNextExpectedTaskTimestamp,
        cellRenderer: renderNextExpectedTaskTimestamp,
        onCellClicked: (ev) =>
          handleOpenViewMembershipDetailsDialog(ev.data.id),
      },
      {
        headerName: "Notes",
        field: "notes",
        width: 200,
        cellRenderer: renderNotes,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        cellStyle: {
          height: "100%",
          display: "flex",
          alignItems: "center",
        },
        onCellClicked: (ev) =>
          handleOpenViewMembershipDetailsDialog(ev.data.id),
      },
      {
        headerName: "Last Payment",
        field: "lastPaymentDate",
        cellRenderer: renderLastRenewal,
        onCellClicked: (ev) =>
          handleOpenViewMembershipDetailsDialog(ev.data.id),
      },
      {
        headerName: "Start",
        field: "membershipStartDate",
        cellRenderer: renderTimestamp,
        onCellClicked: (ev) =>
          handleOpenViewMembershipDetailsDialog(ev.data.id),
      },
      {
        headerName: "End",
        field: "membershipEndDate",
        cellRenderer: renderTimestamp,
        onCellClicked: (ev) =>
          handleOpenViewMembershipDetailsDialog(ev.data.id),
      },
      // {
      //   headerName: "Next Invoice",
      //   field: "nextInvoiceDate",
      //   cellRenderer: renderTimestamp,
      //   onCellClicked: (ev) =>
      //     handleOpenViewMembershipDetailsDialog(ev.data.id),
      // },
      {
        headerName: "Last Task",
        field: "lastCompletedTaskDate",
        cellRenderer: renderTimestamp,
        onCellClicked: (ev) =>
          handleOpenViewMembershipDetailsDialog(ev.data.id),
      },
      {
        headerName: "Next Task",
        field: "nextScheduledTaskDate",
        cellRenderer: renderTimestamp,
        onCellClicked: (ev) =>
          handleOpenViewMembershipDetailsDialog(ev.data.id),
      },
      // {
      //   headerName: "Member Since",
      //   field: "timestampCreated",
      //   cellRenderer: renderTimestamp,
      //   onCellClicked: (ev) =>
      //     handleOpenViewMembershipDetailsDialog(ev.data.id),
      // },
      {
        cellRenderer: renderIconCell,
        suppressMovable: true,
        suppressHeaderMenuButton: true,
        sortable: false,
        minWidth: 100,
        width: 100,
        flex: 0,
        cellStyle: {
          height: "100%",
          display: "flex",
          alignItems: "center",
        },
      },
    ];

    memberships.sort((a, b) => {
      if (a.nextInvoiceDate === null) {
        return 1;
      }
      if (b.nextInvoiceDate === null) {
        return -1;
      }
      return a.nextInvoiceDate.toMillis() - b.nextInvoiceDate.toMillis();
    });

    return (
      <AgGridReact
        ref={gridRef}
        onGridReady={onGridReady}
        defaultColDef={commonColProps}
        className="-z-10 mt-5 shadow"
        rowData={memberships}
        animateRows={true}
        rowSelection="single"
        rowHeight={50}
        cacheQuickFilter={true}
        columnDefs={columnDefs}
      />
    );
  },
  (previous, next) => isEqual(previous.memberships, next.memberships),
);
