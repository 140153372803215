import { useRef, useState } from "react";
// Local
import BaseButtonSecondary from "../BaseButtonSecondary";
import * as strings from "../../strings";

export type MembershipDetailsActions =
  | "scheduleNextTask"
  | "sendCustomEmail"
  | "manageCardsOnFile";

interface Props {
  handleClick: (actionType: MembershipDetailsActions) => void;
  /** don't want to show it if all tasks for this membership are accounted for */
  shouldShowScheduleTaskAction: boolean;
  /** not allowing non-admins to manage cards on file */
  userIsSiteAdmin: boolean;
}

export default function MembershipDetailsActionDropdown(props: Props) {
  const dropdown = useRef(null);
  const [isOpen, setOpen] = useState(false);

  function scheduleTask() {
    setOpen(false);
    props.handleClick("scheduleNextTask");
  }

  function sendCustomEmail() {
    setOpen(false);
    props.handleClick("sendCustomEmail");
  }

  function manageCardsOnFile() {
    setOpen(false);
    props.handleClick("manageCardsOnFile");
  }

  return (
    <div>
      <BaseButtonSecondary
        onClick={() => setOpen(!isOpen)}
        ref={dropdown}
        onBlur={() => setOpen(false)}
        className="w-48"
      >
        <p className="w-full text-left">Actions</p>

        <svg
          className="ml-2 h-4"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </BaseButtonSecondary>

      <div
        className={`relative z-10 w-48 divide-y divide-gray-100 rounded-md bg-white text-left shadow ${
          isOpen ? "" : "hidden"
        }`}
      >
        <ul className="absolute z-10 w-48 divide-y divide-gray-100 rounded-md bg-white text-sm shadow">
          {props.shouldShowScheduleTaskAction && (
            <li>
              <a
                onMouseDown={(e) => e.preventDefault()}
                onClick={scheduleTask}
                className="block cursor-pointer p-4 hover:bg-gray-100"
              >
                {strings.SCHEDULE_NEXT_TASK}...
              </a>
            </li>
          )}
          <li>
            <a
              onMouseDown={(e) => e.preventDefault()}
              onClick={sendCustomEmail}
              className="block cursor-pointer p-4 hover:bg-gray-100"
            >
              {strings.SEND_CUSTOM_EMAIL}...
            </a>
          </li>
          {props.userIsSiteAdmin && (
            <li>
              <a
                onMouseDown={(e) => e.preventDefault()}
                onClick={manageCardsOnFile}
                className="block cursor-pointer p-4 hover:bg-gray-100"
              >
                {strings.MANAGE_CARDS_ON_FILE}...
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
