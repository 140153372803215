// #region Imports
// Libs
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { User } from "firebase/auth";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { Wrapper } from "@googlemaps/react-wrapper";
import { DateTime } from "luxon";
import {
  DayPilotScheduler,
  DayPilotCalendar,
  DayPilot,
  CalendarProps,
  SchedulerProps,
} from "daypilot-pro-react";
import { DocumentData, Timestamp } from "firebase/firestore";
import { ICellRendererParams } from "ag-grid-community";
import cloneDeep from "lodash/cloneDeep";
import { PencilIcon } from "@heroicons/react/24/solid";

// Local
import CreateTaskForCustomerPage, {
  CreateTaskSecondaryHeading,
} from "./CreateTaskForCustomerPage";
import { DbRead, DbWrite } from "../../database";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import * as strings from "../../strings";
import AddNewCustomerDialog from "../../components/customers/AddNewCustomerDialog";
import { logger } from "../../logging";
import {
  BillingInfo,
  Customer,
  Customer_CreateAPI,
  CustomerManager,
  customerWithoutTimestamps,
  ExistingCustomer,
  ExistingCustomerUpdate,
  getBillingInfoFromCustomerAndLocations,
} from "../../models/customer";
import {
  CustomerLocation_CreateAPI,
  CustomerLocationManager,
  customerLocationWithoutTimestamps,
  ExistingCustomerLocation,
} from "../../models/customer-location";
import { useAuthStore } from "../../store/firebase-auth";
import BaseInputText from "../../components/BaseInputText";
import LoadingSpinner from "../../components/LoadingSpinner";
import AddEditCustomerLocationDialog, {
  TemporaryLocation,
} from "../../components/customers/AddEditCustomerLocationDialog";
import {
  CraftRecordManager,
  CraftRecord_CreateForCustomer,
  ExistingCraftRecord,
} from "../../models/craft-record";
import {
  CraftRecordPersistenceTypes,
  ExistingTask,
  TaskRecordManager,
  Task_CreateForCustomer,
  ExistingTaskWithCustomerLocation,
} from "../../models/task";
import { OCraftTypes, isValidCraftType } from "../../models/craft-types";
import { TaskStatus } from "../../models/task-status";
import {
  OTaskTypes,
  getTaskTypeValueFromReadableStr,
  isValidTaskType,
} from "../../models/task-types";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import { WORK_RECORD_AND_TASKS_URL } from "../../urls";
import {
  convertISOToFSTimestamp,
  convertLuxonDTToFSTimestamp,
  createToastMessageID,
  dropUndefined,
  getTimeDifferenceInHours,
} from "../../utils";
import { useToastMessageStore } from "../../store/toast-messages";
import { convertAddressToCoords } from "../../utils/googleGeocoding";
import {
  getCraftTypeStringFromReadableString,
  getCraftTypeFromString,
} from "../../models/craft-types";
import getWorkAndTaskTypesIntersection from "../../assets/js/getWorkAndTaskTypesIntersection";
import BaseInputTextArea from "../../components/BaseInputTextArea";
import {
  WorkAndTaskTypesSection,
  SchedulingSection,
  CustomFieldSection,
  CustomerLocationSection,
} from "../../components/customers/CreateTask";
import getScheduledServiceWindowEnd from "../../assets/js/getScheduledServiceWindowEnd";
import {
  CustomFieldResponse,
  ExistingCustomField,
  MultipleUidCustomField,
} from "../../models/custom-field";
import convertCustomizationsToCustomFields from "../../assets/js/convertCustomizationsToCustomFields";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import { getTaskStatusForApprovedTask } from "../../assets/js/tasks";
import { convertJSDateToFSTimestamp } from "../../utils/convertJSDateToFSTimestamp";
import { useUserPermissionsStore } from "../../store/user-permissions";
import { generateCalendarEvents } from "../../assets/js/scheduling/generateCalendarEvents";
import {
  generateResourcesForDayPilotScheduler,
  generateResourcesForDayPilotCalendar,
} from "../../assets/js/scheduling/generateResources";
import { IMultipleUid_AssignUser } from "../../components/CustomFields/MultipleUidDialog";
import getCurrentJSDateWithoutSeconds from "../../assets/js/getCurrentJSDateWithoutSeconds";
import StandaloneLocationDialog from "../../components/customers/StandaloneLocationDialog";
import { useMembershipTemplatesStore } from "../../store/membership-templates";
import {
  ExistingMembershipTemplate,
  MembershipDiscount,
} from "../../models/membership-template";
import { ScheduleByPriorityType } from "../../components/customers/CreateTask/SchedulingSection";
import {
  EstimateItemMembershipData,
  ExistingMembership,
} from "../../models/membership";
import {
  convertResponsesToExpectedTypes,
  getDefaultValuesForNewResponse,
} from "../../components/CustomFields";
import { Json } from "../../models/json-type";
import { diffObjects } from "../../assets/js/object-diff";
import { useUserDisplayNamesStore } from "../../store/user-display-names-map";
import { getCraftRecordTitle } from "../../assets/js/getCraftRecordTitle";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import { SiteKeyLocationSection } from "../../components/SiteKeyLocationSection";
import { getSiteLocationTitle } from "../Estimates/CreateEstimateContainer";
import StyledMessage from "../../components/StyledMessage";
import ButtonColored from "../../components/ButtonColored";
import CreateEstimateDetails from "../../components/estimates/CreateEstimateDetails";
import { getEstimateTotals } from "../../assets/js/estimateFunctions";
import {
  EstimateItemManager,
  EstimateItem_CreateAPI,
  ExistingEstimateItem,
  TemporaryEstimateItem,
  getEstimateItemList,
} from "../../models/estimate-item";
import { ExistingPriceBookItem } from "../../models/price-book-item";
import { typesensePriceBookItemsQuery } from "../../utils/typesenseQueries";
import { useTypesenseStore } from "../../store/typesense";
import { getTotalDiscounts } from "../../assets/js/memberships/getTotalDiscounts";
import { getMembershipDiscountsForCustomer } from "../../assets/js/memberships/getDiscountList";
import {
  Estimate,
  EstimateManager,
  EstimateStatus,
  Estimate_CreateAPI,
  ExistingEstimate,
} from "../../models/estimate";
import BaseInputNumber from "../../components/BaseInputNumber";
import BaseInputCheckbox from "../../components/BaseInputCheckbox";
import {
  EstimatePackageManager,
  EstimatePackage_CreateAPI,
} from "../../models/estimate-package";
import CustomerTableDialog from "../../components/customers/CustomerTableDialog";
import AssignedToCustomField from "../../components/AssignedToCustomField";
import { getSelectedUsers } from "../../components/CustomFields/getSelectedUsers";
import { getPermissionsMap } from "../Admin/UserManagementContainer";
import AddNewEstimateItemSelection from "../../components/estimates/AddNewEstimateItemSelection";
import EditEstimateItemLine from "../../components/estimates/EditEstimateItemLine";
import EstimateItemInline from "../../components/estimates/EstimateItemInline";
import { CustomerDetails } from "../../components/estimates/CustomerDetails";
import EditCustomerDialog from "../../components/customers/EditCustomerDialog";
import { useSiteKeyUsersStore } from "../../store/site-key-users";
import { getFontColorForBackground } from "../../utils/colors";
import {
  customizeHeader,
  stiltTaskBubble,
} from "../Scheduling/SchedulingContainer";
import { TaskCreationMembershipData } from "../../navigation";
import { ExistingStiltPhoto } from "../../models/stilt-photo";
import { BillingInfoSection } from "../../components/estimates/BillingInfoSection";
import EditBillingInfoDialog from "../../components/customers/EditBillingInfoDialog";
import { getCustomServiceWindowDuration } from "../../utils/getCustomServiceWindowDuration";
import CustomerEstimateListTable from "../../components/customers/CustomerEstimateListTable";
import { ExistingStiltInvoice } from "../../models/invoice";
import MembershipPill from "../../components/Memberships/MembershipPill";
import {
  useIncomingCallsStore,
  type AlertStyle,
} from "../../store/incoming-calls";
import type { AlertType } from "../../components/AlertV2";
import AlertV2 from "../../components/AlertV2";
import AlertV2Button from "../../components/AlertV2Button";
import { CalendarColumn, CalendarEvent } from "../../models/scheduling-types";
import StyledSwitchGroup from "../../components/StyledSwitchGroup";
import { ExistingAsset } from "../../models/asset";
import AssetEquipmentListCheckboxes from "../../components/Memberships/AssetEquipmentListCheckboxes";
import { ExistingSiteKeyUserPermissions } from "../../models/site-key-user-permissions";
import { ExistingVehicle } from "../../models/vehicle";
import ConfirmUpdateCustomerLocation from "../../components/WorkRecordAndTasks/ConfirmUpdateCustomerLocation";
import { convertObjectToFSTimestamp } from "../../utils/convertObjectToFSTimestamp";
import {
  ExistingStiltCalendarEvent,
  StiltCalendarEvent,
} from "../../models/stilt-calendar-event";
import { generateStiltCalendarEvents } from "../../assets/js/scheduling/generateStiltCalendarEvents";
import { ExistingCustomerContact } from "../../models/customer-contact";
import CustomerContactSection from "../../components/estimates/CustomerContactSection";
import AddEditCustomerContactDialog from "../../components/customers/AddEditCustomerContactDialog";
import { CustomerContactFormState } from "../../components/customers/AddEditCustomerContactForm";
import MembershipTaskLinkingSection from "../../components/Memberships/MembershipTaskLinkingSection";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { getDotColorWIPTask } from "../../assets/js/scheduling/getDotColor";
import { ExistingAssignedRoute } from "../../models/assigned-route";
import { ExistingCommissionAdjustment } from "../../models/commission-adjustment";
import { ExistingZone } from "../../models/zone";
import {
  InventoryTransaction,
  InventoryTransactionValues,
  OInventoryTransactionTypes,
} from "../../models/inventory-transaction";
import DropdownInventoryTransactionTypes from "../../components/inventory/DropdownInventoryTransactionTypes";
import { ExistingInventoryLocation } from "../../models/inventory-location";
import { ExistingInventoryObject } from "../../models/inventory-object";
// #endregion Imports

export default function CreateTaskForCustomerContainer(props: {
  siteKey: string;
  setCreateTaskDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  startDate?: Date;
  eventDuration?: number | null;
  assignedTo: string | null;
}): JSX.Element {
  // #region SECTION: useState, useEffect, data fetching & setup
  const isMounted = useRef(true);
  const userList = useRef<IMultipleUid_AssignUser[]>([]);
  const apiKey = import.meta.env.VITE_APP_GOOGLE_MAP_KEY;

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  // Grab customer doc id from the url params, if applicable.
  type UrlParams = { customerID?: string; craftRecordID?: string };
  const { customerID, craftRecordID } = useParams<UrlParams>();
  const craftRecordIdFromParams = craftRecordID ? craftRecordID : null;
  const estimateDoc = state?.estimateDoc ?? null;
  const membershipTemplateID = state?.membershipData?.membershipTemplateID;
  const membershipID = state?.membershipData?.membershipID;
  const { assetID } = state?.assetData ?? {};

  /* STORES */
  const addMessage = useToastMessageStore((state) => state.addToastMessage);
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );

  const userDisplayNamesMap = useUserDisplayNamesStore(
    (state) => state.userDisplayNames,
  );

  // This component is used within RouteAuthRequired, so we can typecast.
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const membershipTemplateList: ExistingMembershipTemplate[] =
    useMembershipTemplatesStore((state) => state.membershipTemplates);

  const [typesenseSearchKey, typesenseLoading] = useTypesenseStore((state) => [
    state.scopedSearchKey,
    state.loading,
  ]);

  // Need the validCraftTypes, validTaskTypes, and customizations out of the siteKey doc.
  const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);

  const permissionsDoc = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const siteKeyLocations = useSiteKeyLocationsStore(
    (state) => state.siteKeyLocationList,
  );

  const siteKeyUsersList = useSiteKeyUsersStore(
    (state) => state.siteKeyUsersList,
  );

  const localStorageTableOrientation = localStorage.getItem(
    "tableOrientationIsHorizontal",
  );

  const tableOrientationIsHorizontal = localStorageTableOrientation
    ? JSON.parse(localStorageTableOrientation) === true
    : false;

  const [craftRecord, setCraftRecord] = useState<ExistingCraftRecord | null>(
    null,
  );
  const [craftRecordLoading, setCraftRecordLoading] = useState<boolean>(false);

  /* Fetch the list of permissions when this component loads */
  const [permissionsMap, setPermissionsMap] = useState<
    Record<string, ExistingSiteKeyUserPermissions>
  >({});

  const [vehicleList, setVehicleList] = useState<ExistingVehicle[]>([]);
  const [vehicleListIsLoading, setVehicleListIsLoading] =
    useState<boolean>(false);

  // Used throughout this container and the connected components. Never not important.
  const [selectedCustomer, setSelectedCustomer] =
    useState<ExistingCustomer | null>(null);
  // This is set via the CustomerLocationSection component
  const [selectedCustomerLocation, setSelectedCustomerLocation] =
    useState<ExistingCustomerLocation | null>(state?.customerLocation ?? null);
  const [
    updateCustomerLocationConfirmationDialogOpen,
    setUpdateCustomerLocationConfirmationDialogOpen,
  ] = useState<boolean>(false);
  const [updateLocationData, setUpdateLocationData] =
    useState<customerLocationWithoutTimestamps | null>(null);

  const [customerContacts, setCustomerContacts] = useState<
    ExistingCustomerContact[] | null
  >(null);
  const [addCustomerContactDialogOpen, setAddCustomerContactDialogOpen] =
    useState<boolean>(false);
  const [primaryContactPhone, setPrimaryContactPhone] =
    useState<ExistingCustomerContact | null>(null);
  const [primaryContactEmail, setPrimaryContactEmail] =
    useState<ExistingCustomerContact | null>(null);

  const [estimateList, setEstimateList] = useState<ExistingEstimate[]>([]);

  useEffect(() => {
    if (state?.estimateDoc != null) {
      setCreateEstimate(false);
    } else {
      if (estimateList.length > 0) {
        setCreateEstimate(false);
      } else {
        setCreateEstimate(true);
      }
    }
  }, [estimateList, state?.estimateDoc]);

  //get all the estimate items related to the existing estimates for this work record
  const [existingEstimateItems, setExistingEstimateItems] = useState<
    ExistingEstimateItem[]
  >([]);

  const [inventorySectionIsExpanded, setInventorySectionIsExpanded] =
    useState<boolean>(false);
  const [pendingInventoryTransactions, setPendingInventoryTransactions] =
    useState<InventoryTransaction[]>([]);

  // Used if they add a location when adding a new customer
  const [
    locationDialogWithinCustomerDialogOpen,
    setLocationDialogWithinCustomerDialogOpen,
  ] = useState(false);
  // Used if they add a location when adding a new customer
  const [locations, setLocations] = useState<TemporaryLocation[]>([]);
  // Used with google places API
  const [address, setAddress] = useState("");
  const [billingAddressLine1, setBillingAddressLine1] = useState<string>(""); // Used with google places API
  const [geocoderResult, setGeocoderResult] = useState<
    google.maps.GeocoderResult[]
  >([]);
  const [showManualEntryForm, setShowManualEntryForm] =
    useState<boolean>(false);

  const [editCustomerDialogOpen, setEditCustomerDialogOpen] =
    useState<boolean>(false);

  // Used if they add a location in the customer location section
  const [standaloneLocationDialogOpen, setStandaloneLocationDialogOpen] =
    useState(false);

  // Options are set in a useEffect within this container, options are used in the
  // location dropdown (CustomerLocationSection component). Using a realtime listener
  // to subscribe to changes.
  const [customerLocationOptions, setCustomerLocationOptions] = useState<
    ExistingCustomerLocation[] | null
  >(null);

  // Added to the craftRecord and task docs. Set in a useEffect hook in this container
  const [latitude, setLatitude] = useState<number | null>(null);
  // Added to the craftRecord and task docs. Set in a useEffect hook in this container
  const [longitude, setLongitude] = useState<number | null>(null);
  // Added to the craftRecord and task docs. Set in the work type dropdown (WorkAndTaskTypesSection component)
  const [selectedWorkType, setSelectedWorkType] = useState<string>("Select...");
  // Added to the craftRecord and task docs. Set in the task type dropdown (WorkAndTaskTypesSection component)
  const [selectedTaskType, setSelectedTaskType] = useState<string | null>(null);
  // Added to the craftRecord and task docs. Set in the notes "component" (textarea)
  const [notes, setNotes] = useState<string | null>(null);
  // Added to craftDetails if applicable
  const [templateNotes, setTemplateNotes] = useState<string | null>(null);
  // Added to the task doc. Set in the scheduling section.
  const [scheduleDateTime, setScheduleDateTime] = useState(
    props.startDate ?? getCurrentJSDateWithoutSeconds(),
  );
  const [scheduledByPriority, setScheduledByPriority] =
    useState<ScheduleByPriorityType | null>(null);
  // Displayed in the scheduling section, if applicable to the current work/task type combo.
  const [assignedTo_TaskCF, setAssignedTo_TaskCF] =
    useState<MultipleUidCustomField | null>(null);
  const [assignedToUIDs, setAssignedToUIDs] = useState<string[] | null>(null);
  const [assignedVehicle, setAssignedVehicle] = useState<string | null>(null);
  const [resourceFromCalendarUID, setResourceFromCalendarUID] = useState<
    string | null
  >(null);
  // Default to 2 hours. Added to the task doc. Set in the scheduling section.
  const [serviceWindowDuration, setServiceWindowDuration] = useState(2);
  // Store the custom fields the user needs to respond to.
  const [customFields, setCustomFields] = useState<ExistingCustomField[]>([]);
  // Store the user's responses to craftDetails custom fields.
  const [responses_CD, setResponses_CD] = useState<CustomFieldResponse>({});
  // Store the user's responses to taskSpecificDetails custom fields.
  const [responses_TSD, setResponses_TSD] = useState<CustomFieldResponse>({});
  // For the button's loading spinner when they've clicked "create task"
  const [isSubmitting, setIsSubmitting] = useState(false);
  // For the button's loading spinner when they've clicked "save" inside add new address dialog
  const [isAddressLoading, setIsAddressLoading] = useState<boolean>(false);
  // date used for the daypilot calendar
  const calendarDateString = DateTime.fromJSDate(scheduleDateTime).toISODate();

  const [
    scheduledAwaitingTaskListIsLoading,
    setScheduledAwaitingTaskListIsLoading,
  ] = useState(false);

  const [scheduledAwaitingTaskList, setScheduledAwaitingTaskList] = useState<
    ExistingTaskWithCustomerLocation[]
  >([]);

  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  const [selectedSiteKeyLocationID, setSelectedSiteKeyLocationID] = useState<
    ExistingSiteKeyLocation["id"] | null
  >(null);

  const [displayAddressError, setDisplayAddressError] =
    useState<boolean>(false);

  const [billingInfo, setBillingInfo] = useState<BillingInfo>({
    addressLine1: "",
    addressLine2: "",
    city: "",
    zipCode: "",
    state: "",
    name: "",
    email: "",
    phone: "",
  });

  const [estimateItemPhotos, setEstimateItemPhotos] = useState<
    ExistingStiltPhoto[]
  >([]);
  const [estimateSectionIsExpanded, setEstimateSectionIsExpanded] =
    useState<boolean>(false);
  const [showExistingEstimates, setShowExistingEstimates] =
    useState<boolean>(false);

  const [editBillingAddressDialogOpen, setEditBillingAddressDialogOpen] =
    useState<boolean>(false);
  const [isEditingBillingInfo, setEditingBillingInfo] =
    useState<boolean>(false);

  const [estimateItemList, setEstimateItemList] = useState<
    TemporaryEstimateItem[]
  >([]);
  const [pbItemsFromTypesense, setPbItemsFromTypesense] = useState<
    ExistingPriceBookItem[]
  >([]);

  const [customerDiscount, setCustomerDiscount] = useState<
    MembershipDiscount[]
  >([]);
  const [selectedLocationDiscount, setSelectedLocationDiscount] = useState<
    MembershipDiscount[]
  >([]);
  const [globalDiscount, setGlobalDiscount] =
    useState<Estimate["discount"]>(null);

  const [estimateNotes, setEstimateNotes] = useState<Estimate["notes"]>(null);
  const [estimateInternalNotes, setEstimateInternalNotes] =
    useState<Estimate["internalNotes"]>(null);
  const [customerDialogStates, setCustomerDialogStates] = useState<
    Record<string, boolean>
  >({
    customerDialogOpen: false,
    selectCustomerDialogOpen: false,
  });

  const [sendBookingConfirmationEmail, setSendBookingConfirmationEmail] =
    useState<boolean>(false);
  const [sendJobReminderEmail, setSendJobReminderEmail] =
    useState<boolean>(false);

  const [
    priceBookItemsWithCommissionOverrides,
    setPriceBookItemsWithCommissionOverrides,
  ] = useState<ExistingPriceBookItem[]>([]);

  const [createEstimate, setCreateEstimate] = useState<boolean>(false);

  const [workRecordInvoiceList, setWorkRecordInvoiceList] = useState<
    ExistingStiltInvoice[]
  >([]);

  const [selectedCustomerLocations, setSelectedCustomerLocations] = useState<
    ExistingCustomerLocation[]
  >([]);
  const [selectedEstimate, setSelectedEstimate] =
    useState<ExistingEstimate | null>(null);

  const [membershipsForCustomer, setMembershipsForCustomer] = useState<
    ExistingMembership[]
  >([]);
  const [membershipIDsForTask, setMembershipIDsForTask] = useState<
    string[] | undefined
  >(membershipID ? [membershipID] : undefined);

  const [assetsForCustomer, setAssetsForCustomer] = useState<ExistingAsset[]>(
    [],
  );
  const [assetIDs, setAssetIDs] = useState<string[] | undefined>(
    assetID ? [assetID] : undefined,
  );

  const [calendarTasks, setCalendarTasks] = useState<CalendarEvent[]>([]);
  const [alreadyScheduledEvents, setAlreadyScheduledEvents] = useState<
    CalendarEvent[]
  >([]);
  const [stiltCalendarEvents, setStiltCalendarEvents] = useState<
    ExistingStiltCalendarEvent[]
  >([]);
  const [stiltCalendarEventsIsLoading, setStiltCalendarEventsIsLoading] =
    useState<boolean>(false);

  const [inventoryLocations, setInventoryLocations] = useState<
    ExistingInventoryLocation[]
  >([]);
  const [inventoryObjects, setInventoryObjects] = useState<
    ExistingInventoryObject[]
  >([]);

  const [overlappingTasksUserName, setOverlappingTasksUserName] = useState<
    string[]
  >([]);
  const [commissionAdjustments, setCommissionAdjustments] = useState<
    ExistingCommissionAdjustment[]
  >([]);

  useEffect(() => {
    if (!props.eventDuration) return;
    setServiceWindowDuration(props.eventDuration);
  }, [props.eventDuration]);

  useEffect(() => {
    function getCraftRecord() {
      if (!craftRecordIdFromParams) return undefined;
      setCraftRecordLoading(true);
      const unsubscribe = DbRead.parentRecords.subscribe(
        props.siteKey,
        craftRecordIdFromParams,
        (craftRecord) => setCraftRecord(craftRecord ?? null),
        (error) =>
          logger.error(`Error in ${getCraftRecord.name}: ${error.message}`),
      );
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getCraftRecord();
    setCraftRecordLoading(false);
    // eslint-disable-next-line consistent-return
    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey, craftRecordIdFromParams]);

  useEffect(() => {
    if (siteKeyUsersList.length === 0) return;

    async function getUsersPermissionsMap() {
      const result: Record<string, ExistingSiteKeyUserPermissions> =
        await getPermissionsMap(siteKeyUsersList, props.siteKey);
      setPermissionsMap(result);
    }

    getUsersPermissionsMap();
  }, [props.siteKey, siteKeyUsersList]);

  useEffect(() => {
    function getVehicleList() {
      setVehicleListIsLoading(true);
      const unsubscribe = DbRead.vehicles.subscribeAll({
        siteKey: props.siteKey,
        onChange: setVehicleList,
        onError: (error) =>
          logger.error(`Error in ${getVehicleList.name}: ${error.message}`),
      });
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getVehicleList();
    setVehicleListIsLoading(false);
    // eslint-disable-next-line consistent-return
    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey, craftRecordIdFromParams]);

  useEffect(() => {
    const pendingInvTrans: InventoryTransaction[] = [];
    estimateItemList.forEach((eI) => {
      if (inventoryObjects.find((iO) => iO.id === eI.priceBookItemID)) {
        pendingInvTrans.push({
          companyID: userPermissions?.companyID as string,
          inventoryObjectID: eI.priceBookItemID,
          parentRecordID: craftRecord?.id ?? null,
          taskID: null,
          type: OInventoryTransactionTypes.RESERVE,
          value: eI.quantity,
          timestampCreated: Timestamp.now(),
          createdBy: firebaseUser.uid,
          referenceTransactionID: null,
          inventoryLocationID: inventoryLocations[0].id,
        });
      }
    });
    setPendingInventoryTransactions(pendingInvTrans);
  }, [
    craftRecord?.id,
    estimateItemList,
    firebaseUser.uid,
    inventoryLocations,
    inventoryObjects,
    userPermissions?.companyID,
  ]);

  useEffect(() => {
    async function getInventoryLocations() {
      if (!siteKeyDoc) return undefined;
      const unsubscribe = DbRead.inventoryLocations.subscribeAll({
        siteKey: siteKeyDoc.id,
        onChange: (locations) => setInventoryLocations(locations),
        onError: (e) => {
          logger.error("Error subscribing to inventory locations", e);
        },
      });

      return unsubscribe;
    }
    const unsubscribePromise = getInventoryLocations();
    return () => {
      unsubscribePromise.then((unsub) => unsub && unsub());
    };
  }, [siteKeyDoc]);

  useEffect(() => {
    async function getInventoryObjects() {
      if (!siteKeyDoc) return undefined;
      const unsubscribe = DbRead.inventoryObjects.subscribeAll({
        siteKey: siteKeyDoc.id,
        onChange: (objects) => setInventoryObjects(objects),
        onError: (e) => {
          logger.error("Error subscribing to inventory objects", e);
        },
      });

      return unsubscribe;
    }
    const unsubscribePromise = getInventoryObjects();
    return () => {
      unsubscribePromise.then((unsub) => unsub && unsub());
    };
  }, [siteKeyDoc]);

  // Subscribe to realtime contact updates for the selected customer.
  useEffect(() => {
    function getSelectedCustomerContacts() {
      if (!selectedCustomer) return undefined;
      const unsubscribe = DbRead.customerContacts.subscribeByCustomerID({
        siteKey: props.siteKey,
        customerID: selectedCustomer.id,
        onChange: setCustomerContacts,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getSelectedCustomerContacts();
    return () => unsubscribeFn && unsubscribeFn();
  }, [selectedCustomer, props.siteKey]);

  useEffect(() => {
    function getEstimateList() {
      if (!userPermissions || !craftRecordIdFromParams) return undefined;
      const unsubscribe = DbRead.estimates.subscribeByCraftRecordId(
        props.siteKey,
        craftRecordIdFromParams,
        setEstimateList,
        (error) =>
          logger.error(`Error in ${getEstimateList.name}: ${error.message}`),
      );
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getEstimateList();

    // eslint-disable-next-line consistent-return
    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey, userPermissions, craftRecordIdFromParams]);

  useEffect(() => {
    if (estimateList.length === 0) return;

    async function getExistingEstimateItems() {
      const result: ExistingEstimateItem[] = await getEstimateItemList(
        estimateList,
        props.siteKey,
      );
      setExistingEstimateItems(result);
    }

    getExistingEstimateItems();
  }, [estimateList, props.siteKey]);

  useEffect(() => {
    if (!craftRecordIdFromParams) return undefined;

    function getInvoices() {
      if (typeof craftRecordIdFromParams !== "string") {
        throw new Error(
          `workRecordID was not a string: ${craftRecordIdFromParams}`,
        );
      }

      const unsubscribe = DbRead.invoices.subscribeByCraftRecordId(
        props.siteKey,
        craftRecordIdFromParams,
        setWorkRecordInvoiceList,
      );
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getInvoices();

    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey, craftRecordIdFromParams]);

  useEffect(() => {
    userList.current = siteKeyUsersList
      // we don't want system users to be selectable in the multiple-uid dialog
      .filter((user) => user.systemUser !== true)
      .map((user) => ({
        uid: user.id,
        name: user.displayName,
        company: user.companyName,
        avatarUrl: user.userPhoto_URL,
        isAssigned: false,
        assignedVehicle: null,
      }));
    // .sort((a, b) => a.name.localeCompare(b.name));
  }, [props.siteKey, siteKeyUsersList]);

  useEffect(() => {
    if (
      selectedCustomer &&
      selectedCustomer.defaultSendBookingConfirmationEmail
    ) {
      setSendBookingConfirmationEmail(
        selectedCustomer.defaultSendBookingConfirmationEmail,
      );
    } else {
      setSendBookingConfirmationEmail(false);
    }

    if (selectedCustomer && selectedCustomer.defaultSendJobReminderEmail) {
      setSendJobReminderEmail(selectedCustomer.defaultSendJobReminderEmail);
    } else {
      setSendJobReminderEmail(false);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    async function getPriceBookItemsWithCommissionOverrides() {
      if (!siteKeyDoc) return;

      if (!siteKeyDoc.customizations.commissions) return;

      const pbItems = await DbRead.priceBookItems.getAll(siteKeyDoc.id);
      setPriceBookItemsWithCommissionOverrides(
        pbItems.filter((pbItem) => pbItem.commissions),
      );
    }

    getPriceBookItemsWithCommissionOverrides();
  }, [siteKeyDoc]);

  useEffect(() => {
    async function checkTaskOverlapping() {
      setOverlappingTasksUserName([]);
      if (!assignedToUIDs) return;

      const startString = scheduleDateTime.toISOString();
      const endString = getScheduledServiceWindowEnd(
        startString,
        serviceWindowDuration,
      );

      const start: Timestamp = convertISOToFSTimestamp(startString);
      const end: Timestamp = convertISOToFSTimestamp(endString);

      for (const uid of assignedToUIDs) {
        const tasks = await DbRead.tasks.getAllByAssignedUserInDateRange(
          props.siteKey,
          uid,
          start,
          end,
        );

        const stiltEvents =
          await DbRead.calendarEvents.getAllByAssignedUserForOverlap(
            props.siteKey,
            uid,
            start,
            end,
          );

        for (const task of tasks) {
          const answer = hasOverlapWithTask(task, start, end);
          if (answer === true) {
            //if return true, send the uid to an array that will show the error message
            const userName = userDisplayNamesMap[uid];
            setOverlappingTasksUserName((prev) => [
              ...new Set([...prev, userName]),
            ]);
          }
        }

        for (const event of stiltEvents) {
          const answer = hasOverlapWithCalendarEvent(event, start, end);
          if (answer === true) {
            //if return true, send the uid to an array that will show the error message
            const userName = userDisplayNamesMap[uid];
            setOverlappingTasksUserName((prev) => [
              ...new Set([...prev, userName]),
            ]);
          }
        }
      }
    }

    checkTaskOverlapping();
  }, [
    assignedToUIDs,
    props.siteKey,
    scheduleDateTime,
    serviceWindowDuration,
    userDisplayNamesMap,
  ]);

  useEffect(() => {
    async function getEstimateItemPhotos() {
      if (!siteKeyDoc) return;

      const photos: ExistingStiltPhoto[] = [];
      for (const ei of estimateItemList) {
        if (ei.id) {
          const eiPhotos = await DbRead.photos.getByEstimateItemID({
            siteKey: siteKeyDoc.id,
            estimateItemID: ei.id,
          });
          photos.push(...eiPhotos);
        }
      }
      setEstimateItemPhotos(photos);
    }

    getEstimateItemPhotos();
  }, [siteKeyDoc, estimateItemList]);

  useEffect(() => {
    async function addDefaultPBItems() {
      if (!siteKeyDoc || !siteKeyDoc.id) {
        return;
      }
      const baseEstimateItems: TemporaryEstimateItem[] = [];
      for (const pbItemID of siteKeyDoc.customizations.addToAllEstimates) {
        const pbItem = await DbRead.priceBookItems.get(siteKeyDoc.id, pbItemID);
        if (
          pbItem &&
          (pbItem.locationID === null ||
            pbItem.locationID === selectedSiteKeyLocationID)
        ) {
          const newEstimateItem: TemporaryEstimateItem = {
            priceBookItemID: pbItem.id,
            title: pbItem.title,
            description: pbItem.description,
            units: pbItem.units,
            unitPrice: pbItem.unitPrice,
            cost: pbItem.cost,
            locationID: "",
            discountableForMemberships: pbItem.discountableForMemberships,
            type: pbItem.type ?? null,
            tags: pbItem.tags,
            customData: pbItem.customData,
            taxable: pbItem.taxable,
            editable: pbItem.editable,
            discountable: pbItem.discountable,
            quantity: 1,
            discount: 0,
            deleted: false,
            toBeEdited: pbItem.editable,
          };
          newEstimateItem.id = DbRead.randomDocID.get();
          baseEstimateItems.push(newEstimateItem);
        }
      }
      setEstimateItemList(baseEstimateItems);
    }

    if (siteKeyDoc?.customizations.addToAllEstimates) {
      addDefaultPBItems();
    }
  }, [siteKeyDoc, selectedSiteKeyLocationID]);

  useEffect(() => {
    function updateSWDuration() {
      if (!siteKeyDoc || props.eventDuration) return;
      const newDuration = getCustomServiceWindowDuration(
        siteKeyDoc,
        selectedWorkType,
        selectedTaskType,
      );
      setServiceWindowDuration(newDuration);
    }

    updateSWDuration();
  }, [selectedWorkType, selectedTaskType, siteKeyDoc, props.eventDuration]);

  // Need to clear the TSD responses if the schedulePriority, taskType, or
  // workType changes, because TSDs are dependent on task status
  useEffect(() => {
    setResponses_TSD({});
  }, [scheduledByPriority, selectedTaskType, selectedWorkType]);

  // #region useEffect
  useEffect(() => {
    if (!craftRecord) return;

    if (craftRecord.customerLocationID && customerLocationOptions) {
      const found = customerLocationOptions.find(
        (location) => location.id === craftRecord.customerLocationID,
      );

      if (found) setSelectedCustomerLocation(found);
    }
  }, [craftRecord, customerLocationOptions]);

  useEffect(() => {
    setTemplateNotes(selectedCustomer?.customData?.templateNotes ?? null);
  }, [selectedCustomer]);

  useEffect(() => {
    function getCustomer() {
      if (!customerID) return undefined;

      const unsubscribe = DbRead.customers.subscribe({
        siteKey: props.siteKey,
        customerID: customerID,
        onChange: setSelectedCustomer,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getCustomer();
    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey, customerID]);

  // Subscribe to realtime location updates for the selected customer.
  useEffect(() => {
    function getSelectedCustomerLocations() {
      if (!selectedCustomer) return undefined;

      const unsubscribe = DbRead.customerLocations.subscribeByCustomerID({
        siteKey: props.siteKey,
        customerID: selectedCustomer.id,
        onChange: setCustomerLocationOptions,
      });

      return unsubscribe;
    }

    const unsubscribeFn = getSelectedCustomerLocations();
    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey, selectedCustomer]);

  // Set the coordinates for the user-selected location.
  useEffect(() => {
    async function getCoords() {
      // Typeguards
      if (!selectedCustomerLocation) return;
      if (!selectedCustomerLocation.fullAddress) return;

      if (
        !selectedCustomerLocation.latitude ||
        !selectedCustomerLocation.longitude
      ) {
        const coords = await convertAddressToCoords(
          selectedCustomerLocation.fullAddress,
        );
        if (coords) {
          setLatitude(coords.latitude);
          setLongitude(coords.longitude);
        } else {
          logger.error(
            `Couldn't convert selected location's fullAddress (${selectedCustomerLocation.fullAddress}) to coordinates`,
          );
        }
      } else {
        // lat & long already on the location doc. use those values
        setLatitude(selectedCustomerLocation.latitude);
        setLongitude(selectedCustomerLocation.longitude);
      }
    }

    getCoords();
  }, [selectedCustomerLocation]);

  // Will be null while component loads / siteKey doc is fetched.
  const workAndTasksIntersectRef = useRef<Map<string, string[]> | null>(null);

  useEffect(() => {
    if (!siteKeyDoc) return;

    workAndTasksIntersectRef.current = getWorkAndTaskTypesIntersection({
      validWorkTypes: siteKeyDoc.validCraftTypes,
      validTaskTypes: siteKeyDoc.validTaskTypes,
    });
  }, [siteKeyDoc]);

  useEffect(() => {
    if (!props.assignedTo) return;
    setAssignedToUIDs([props.assignedTo]);
  }, [props.assignedTo]);

  useEffect(() => {
    async function getTasksForParentRecord() {
      if (!siteKeyDoc || !craftRecordIdFromParams || !userPermissions) return;
      const tasksForParentRecord = await DbRead.tasks.getAllByWorkRecord({
        siteKey: siteKeyDoc.id,
        workRecordID: craftRecordIdFromParams,
        userPermissions: userPermissions,
      });
      if (assignedToUIDs === null) {
        const sortedTasks = tasksForParentRecord.sort((a, b) => {
          return (
            b.timestampLastModified?.toMillis() -
            a.timestampLastModified.toMillis()
          );
        });
        if (sortedTasks.length > 0) {
          if (sortedTasks[0].taskSpecificDetails.assignedTo) {
            setAssignedToUIDs(
              sortedTasks[0].taskSpecificDetails.assignedTo as string[],
            );
          }
        }
      }
    }

    getTasksForParentRecord();
  }, [assignedToUIDs, craftRecordIdFromParams, siteKeyDoc, userPermissions]);

  useEffect(() => {
    // need to do an early return if selectedWorkType or selectedTaskType is null.
    // need to display in the UI that they need to select "____" first.
    if (!siteKeyDoc || !selectedWorkType || !selectedTaskType) return;

    const allCustomFields = convertCustomizationsToCustomFields({
      siteKeyDoc,
    });

    const workTypeStr = getCraftTypeStringFromReadableString(selectedWorkType);
    const workTypeInt = getCraftTypeFromString(workTypeStr);
    const taskTypeInt = getTaskTypeValueFromReadableStr(selectedTaskType);

    // Drop the custom fields that are not related to the selected work or task type.
    // eslint-disable-next-line array-callback-return
    const displayThese = allCustomFields.filter((customField) => {
      if (
        customField.craftRecordOrTask === "craftRecord" &&
        customField.craftType === workTypeInt
      ) {
        return customField;
      }

      if (
        customField.craftRecordOrTask === "task" &&
        customField.craftType === workTypeInt &&
        customField.taskType === taskTypeInt
      ) {
        return customField;
      }
      return undefined;
    });

    // Custom Implementation of job template feature
    // Will copy the customer's customData.templateNotes into the craftDetails
    // field for jobTemplateNotes.
    // Basically, this allows users to set up job templates with specific
    // Customers of theirs in Stilt and when they create jobs for those
    // Customers, the job templates will be copied into the Craft Record
    //
    // First we'll want to "locally" update the CustomFieldConfig for this
    // specific field so that it shows up as the placeholder in the textfield
    const configIndex = displayThese.findIndex(
      (cF) => cF.id === "jobTemplateNotes",
    );
    if (configIndex !== -1) {
      displayThese[configIndex].defaultValue = templateNotes;
    }

    // taskSpecificNotes is NOT a custom field -- it's just being displayed in the
    // custom fields section. it will be added to the task doc's description field.
    const taskSpecificNotes: ExistingCustomField = {
      id: "taskSpecificNotes",
      title: "Task-specific Notes",
      defaultValue: "",
      fieldType: "string-textarea",

      required:
        craftRecord &&
        siteKeyDoc.customizations.requireFollowUpTaskNotes === true
          ? true
          : false,
      craftRecordOrTask: "task",
      // Stuff that doesn't matter, just needs to be a full-fledged custom field
      craftType: workTypeInt || OCraftTypes["GENERAL_LABOR"],
      taskType: taskTypeInt || OTaskTypes["GENERAL_LABOR"],
      min: null,
      max: null,
      refPath: "notApplicable",
      exists: true,
      deleted: false,
      editable: true,
      // onTaskStatus?: TaskStatusValues[];
      // hideOnCraftRecordCreation?: boolean;
      adminAdjustable: true,
      adminRemovable: true,
    };
    displayThese.push(taskSpecificNotes);

    setCustomFields(displayThese);
  }, [
    craftRecord,
    selectedTaskType,
    selectedWorkType,
    siteKeyDoc,
    templateNotes,
  ]);

  // Get the currently displayed custom fields, split by workRecord and task.
  // workRecordCFsRef and taskCFsRef are used to make sure the user has filled out
  // all required fields before submission.
  const workRecordCFsRef = useRef<ExistingCustomField[]>([]);
  const taskCFsRef = useRef<ExistingCustomField[]>([]);
  useEffect(() => {
    const cfsToDisplay = getCustomFieldsToDisplay({
      customFields,
      scheduledByPriority,
      scheduleDate: scheduleDateTime,
    });

    taskCFsRef.current = cfsToDisplay.taskCFs;

    if (craftRecordIdFromParams === null) {
      workRecordCFsRef.current = cfsToDisplay.workRecordCFs.sort((a, b) =>
        b.title.localeCompare(a.title),
      );
    }
  }, [
    customFields,
    scheduleDateTime,
    scheduledByPriority,
    craftRecordIdFromParams,
  ]);

  useEffect(() => {
    if (!selectedCustomer || !customerLocationOptions) return;
    const billingInfo = getBillingInfoFromCustomerAndLocations(
      selectedCustomer,
      customerLocationOptions,
    );
    setBillingInfo(billingInfo);
    setBillingAddressLine1(billingInfo.addressLine1);
  }, [customerLocationOptions, selectedCustomer]);

  useEffect(() => {
    // If there's an assignedTo task custom field that is editable and whose
    // onTaskStatus array contains the current taskStatus, we want to display it
    // in the Scheduling section.
    const taskStatus = getTaskStatusForApprovedTask(
      scheduledByPriority,
      convertJSDateToFSTimestamp(scheduleDateTime),
    );
    const found = customFields.find(
      (cf) =>
        cf.id === "assignedTo" &&
        cf.craftRecordOrTask === "task" &&
        cf.fieldType === "multiple-uid",
    );
    if (found && found.editable && found.onTaskStatus?.includes(taskStatus)) {
      const assignedTo = found as MultipleUidCustomField;
      // ^ can safely typecast because the fieldType is multiple-uid.
      setAssignedTo_TaskCF(assignedTo);
      if (assignedToUIDs === null) {
        setAssignedToUIDs(assignedTo.defaultValue);
      }
    }
  }, [assignedToUIDs, customFields, scheduleDateTime, scheduledByPriority]);

  useEffect(() => {
    async function getScheduledAwaitingTaskList() {
      // typeguard
      if (!userPermissions) {
        logger.debug("No userPermissions yet...");
        return undefined;
      }
      // Start loading.
      if (isMounted.current) setScheduledAwaitingTaskListIsLoading(true);

      //setting the date selected to doesn't care about the hours selected but
      // check the entire day
      const startDate = DateTime.fromJSDate(scheduleDateTime).set({
        hour: 0,
        minute: 0,
        second: 0,
      });
      const endDate = DateTime.fromJSDate(scheduleDateTime).set({
        hour: 23,
        minute: 59,
        second: 59,
      });

      // Query for all awaiting scheduled tasks via realtime updates.
      const unsubscribeScheduledAwaitingTaskList =
        DbRead.tasks.subscribeScheduledAwaitingTaskList({
          siteKey: props.siteKey,
          permissions: userPermissions,
          startDate: convertLuxonDTToFSTimestamp(startDate),
          endDate: convertLuxonDTToFSTimestamp(endDate),
          onChange: async (list) => {
            const tasksWithCustomerLocations: ExistingTaskWithCustomerLocation[] =
              [];
            const customerLocationIDs = list.map(
              (task) => task.customerLocationID,
            );
            const uniqueCustomerLocationIDs = Array.from(
              new Set(customerLocationIDs),
            );
            // Promises all customerLocations
            const customerLocationPromises = uniqueCustomerLocationIDs.map(
              // eslint-disable-next-line consistent-return
              (clID) => {
                if (clID && siteKeyDoc) {
                  return DbRead.customerLocations.getSingle(
                    siteKeyDoc.id,
                    clID,
                  );
                }
              },
            );
            const customerLocations = await Promise.all(
              customerLocationPromises,
            );
            for (const task of list) {
              const taskWithCL: ExistingTaskWithCustomerLocation = task;
              // query customerLocation for each task
              if (task.customerLocationID) {
                const cL = customerLocations.find(
                  (cl) => cl?.id === task.customerLocationID,
                );
                taskWithCL.customerLocation = cL;
                tasksWithCustomerLocations.push(taskWithCL);
              }
            }

            setScheduledAwaitingTaskList(tasksWithCustomerLocations);
          },
          onError: (error) =>
            logger.error(
              `Error in ${getScheduledAwaitingTaskList.name}: ${error.message}`,
            ),
        });

      // If user hasn't navigated away from the page, stop rendering the loading icon.
      if (isMounted.current) {
        setScheduledAwaitingTaskListIsLoading(false);
      }

      return [unsubscribeScheduledAwaitingTaskList];
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromises variable.
    const unsubscribePromises = getScheduledAwaitingTaskList();

    // Return an anonymous ƒn for cleanup.
    return () => {
      logger.debug(
        "(CreateTaskForCustomerContainer) getScheduledAwaitingTaskList useEffect return ƒn just invoked.",
      );
      isMounted.current = false;

      // Because we're dealing with an async ƒn, we need to unpack its' return function before we invoke it.
      unsubscribePromises.then((unsubscribe) => {
        if (!Array.isArray(unsubscribe)) return;
        unsubscribe.forEach((unsub) => unsub());
      });
    };
  }, [props.siteKey, scheduleDateTime, siteKeyDoc, userPermissions]);

  useEffect(() => {
    if (estimateDoc) return;
    if (craftRecord) {
      setSelectedSiteKeyLocationID(craftRecord.locationID);
    } else {
      if (userPermissions && userPermissions.defaultLocationID) {
        setSelectedSiteKeyLocationID(userPermissions.defaultLocationID);
      } else if (siteKeyLocations.length === 1) {
        setSelectedSiteKeyLocationID(siteKeyLocations[0].id);
      }
    }
  }, [craftRecord, estimateDoc, siteKeyLocations, userPermissions]);

  useEffect(() => {
    if (!estimateDoc) return;
    setSelectedSiteKeyLocationID(estimateDoc.locationID);
  }, [estimateDoc]);

  useEffect(() => {
    function subscribeCommissionAdjustments() {
      if (!estimateDoc) return undefined;
      if (!siteKeyDoc) return undefined;
      const unsubscribe = DbRead.commissionAdjustments.subscribeByEstimateID({
        siteKey: siteKeyDoc.id,
        estimateID: estimateDoc.id,
        onChange: setCommissionAdjustments,
      });
      return unsubscribe;
    }

    const unsubscribeFn = subscribeCommissionAdjustments();
    return () => unsubscribeFn && unsubscribeFn();
  }, [estimateDoc, siteKeyDoc]);

  // #endregion useEffect

  useEffect(() => {
    setCustomerDiscount(
      getMembershipDiscountsForCustomer(
        membershipsForCustomer.filter((m) => m.customerLocationID === null),
        membershipTemplateList,
      ),
    );
  }, [selectedCustomer, membershipTemplateList, membershipsForCustomer]);

  useEffect(() => {
    setSelectedLocationDiscount(
      getMembershipDiscountsForCustomer(
        membershipsForCustomer.filter(
          (m) => m.customerLocationID === selectedCustomerLocation?.id,
        ),
        membershipTemplateList,
      ),
    );
  }, [
    membershipTemplateList,
    membershipsForCustomer,
    selectedCustomerLocation,
  ]);

  useEffect(() => {
    async function getPBItems() {
      if (!typesenseSearchKey) return;
      const pbItems = await typesensePriceBookItemsQuery(
        typesenseSearchKey,
        "",
      );
      if (
        siteKeyDoc?.customizations.filterPriceBookItemsByLocationID === true &&
        selectedSiteKeyLocationID != null
      ) {
        const filterPBItems = pbItems.filter(
          (item) =>
            item.locationID === selectedSiteKeyLocationID ||
            item.locationID === null,
        );
        setPbItemsFromTypesense(filterPBItems);
      } else {
        setPbItemsFromTypesense(pbItems);
      }
    }

    getPBItems();
  }, [
    typesenseSearchKey,
    selectedSiteKeyLocationID,
    siteKeyDoc?.customizations.filterPriceBookItemsByLocationID,
  ]);

  const totalSelectedDiscounts = getTotalDiscounts(
    customerDiscount,
    selectedLocationDiscount,
  );

  useEffect(() => {
    async function setNewCustomerLocations() {
      const newLocations = await DbRead.customerLocations.getByCustomerId(
        props.siteKey,
        selectedCustomer!.id,
      );
      setSelectedCustomerLocations(newLocations);
    }

    if (selectedCustomer) {
      setNewCustomerLocations();
    }
  }, [props.siteKey, selectedCustomer]);

  const onFilterTextBoxChanged = useCallback(
    async (searchTerm: string) => {
      if (!typesenseSearchKey) return;

      const pbItems = await typesensePriceBookItemsQuery(
        typesenseSearchKey,
        searchTerm,
      );

      if (
        siteKeyDoc?.customizations.filterPriceBookItemsByLocationID === true &&
        selectedSiteKeyLocationID != null
      ) {
        const filterPBItems = pbItems.filter(
          (item) =>
            item.locationID === selectedSiteKeyLocationID ||
            item.locationID === null,
        );
        setPbItemsFromTypesense(filterPBItems);
      } else {
        setPbItemsFromTypesense(pbItems);
      }
    },
    [
      typesenseSearchKey,
      siteKeyDoc?.customizations.filterPriceBookItemsByLocationID,
      selectedSiteKeyLocationID,
    ],
  );

  // Fetch the list of memberships for the selected customer
  useEffect(() => {
    async function getMembershipsForCustomer() {
      logger.info("selectedCustomer", customerID, selectedCustomer);
      if (!selectedCustomer) return undefined;

      logger.debug("getMembershipsForCustomer useEffect called ");
      // Query payments via realtime updates. Set the list of invoices.
      const unsubscribeAllMembershipsByCustomerID =
        DbRead.memberships.subscribeByCustomerID({
          siteKey: props.siteKey,
          customerID: selectedCustomer.id,
          onChange: (snapshot) => {
            // don't show expired memberships other than the most recent expired membership
            const expiredMemberships = snapshot.filter(
              (m) => m.status === "expired",
            );
            let mostRecentExpiredMembership: ExistingMembership | null = null;
            if (expiredMemberships.length > 0) {
              mostRecentExpiredMembership = expiredMemberships.reduce(
                (prev, current) => {
                  return (prev.membershipEndDate ?? prev.timestampCreated) >
                    (current.membershipEndDate ?? prev.timestampCreated)
                    ? prev
                    : current;
                },
              );
            }
            const nonExpiredMemberships = snapshot.filter(
              (m) => m.status !== "expired",
            );
            const allMembershipsToDisplay = [...nonExpiredMemberships];

            if (mostRecentExpiredMembership != null) {
              allMembershipsToDisplay.push(mostRecentExpiredMembership);
            }

            setMembershipsForCustomer(allMembershipsToDisplay);
          },
          onError: (error) =>
            logger.error(
              `Error in getMembershipsForCustomer: ${error.message}`,
            ),
        });
      return unsubscribeAllMembershipsByCustomerID;
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    const unsubscribePromise = getMembershipsForCustomer();
    // Return an anonymous ƒn for cleanup.
    return () => {
      unsubscribePromise.then((unsubscribe) => {
        if (unsubscribe) {
          unsubscribe();
        }
      });
    };
  }, [customerID, props.siteKey, selectedCustomer, selectedCustomerLocation]);

  // Fetch the asset documents for a customer
  useEffect(() => {
    async function getAssetsForCustomerLocation() {
      if (!selectedCustomerLocation?.id) return undefined;

      logger.debug("getAssetsForCustomer useEffect called ");
      // Query payments via realtime updates. Set the list of invoices.
      const unsubscribeAllAssetsByCustomerLocationID =
        DbRead.assets.subscribeByCustomerLocationID({
          siteKey: props.siteKey,
          customerLocationID: selectedCustomerLocation.id,
          onChange: setAssetsForCustomer,
          onError: (error) =>
            logger.error(
              `Error in getAssetsForCustomerLocation: ${error.message}`,
            ),
        });
      return unsubscribeAllAssetsByCustomerLocationID;
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    const unsubscribePromise = getAssetsForCustomerLocation();
    // Return an anonymous ƒn for cleanup.
    return () => {
      unsubscribePromise.then((unsubscribe) => {
        if (unsubscribe) {
          unsubscribe();
        }
      });
    };
  }, [props.siteKey, selectedCustomerLocation]);

  const [dailyVehicleUsersMap, setDailyVehicleUsersMap] = useState<
    ExistingAssignedRoute["vehicleUsersMap"]
  >({});
  const [dailyVehicleIsLoading, setDailyVehicleIsLoading] = useState(false);

  useEffect(() => {
    function getDailyVehicleUsersMap() {
      // typeguard
      if (!userPermissions) {
        logger.debug("No userPermissions yet...");
        return undefined;
      }
      logger.debug(`Running useEffect: ${getDailyVehicleUsersMap.name}.`);

      // Start loading.
      if (isMounted.current) setDailyVehicleIsLoading(true);

      // Query for daily assignedRoute via realtime updates.
      const unsubscribeDailyAssignment =
        DbRead.assignedRoutes.subscribeDailyVehicleUsersMap({
          siteKey: props.siteKey,
          isoDate: DateTime.fromJSDate(scheduleDateTime).toISODate(),
          onChange: setDailyVehicleUsersMap,
          onError: (error) => {
            logger.error(
              `Error in ${getDailyVehicleUsersMap.name}: ${error.message}`,
            );
          },
        });

      // If user hasn't navigated away from the page, stop rendering the loading icon.
      if (isMounted.current) {
        setDailyVehicleIsLoading(false);
      }
      return unsubscribeDailyAssignment;
    }

    const unsubscribe = getDailyVehicleUsersMap();

    // Return an anonymous ƒn for cleanup.
    return () => {
      logger.debug(
        "(SchedulingContainer) getDailyAssignment useEffect return ƒn just invoked.",
      );
      isMounted.current = false;
      if (unsubscribe) unsubscribe();
    };
  }, [scheduleDateTime, props.siteKey, userPermissions]);

  // #endregion useState, useEffect, data fetching & setup

  const resourcesMode: "technicians" | "vehicles" =
    siteKeyDoc?.customizations["dispatchBoardResourceTypeDefault"] ??
    "technicians";
  // FIXME: sometimes shows up as technicians when we're expecting vehicles --
  // not in CreateTaskForCustomer -- in SchedulingContainer

  const resourcesForCalendar = generateResourcesForDayPilotCalendar(
    vehicleList,
    siteKeyUsersList,
    siteKeyLocations,
    resourcesMode,
    permissionsMap,
    dailyVehicleUsersMap,
  );

  const filteredResourcesForCalendar = filterResourcesByTaskType(
    resourcesForCalendar,
    selectedTaskType,
    resourcesMode,
    selectedSiteKeyLocationID,
  );

  const resourcesForScheduler = generateResourcesForDayPilotScheduler(
    vehicleList,
    siteKeyUsersList,
    siteKeyLocations,
    resourcesMode,
    permissionsMap,
  );

  const [zoneList, setZoneList] = useState<ExistingZone[]>([]);
  const [zoneListIsLoading, setZoneListIsLoading] = useState<boolean>(false);

  // Fetch the list of zones when this component loads.
  useEffect(() => {
    async function getZones() {
      logger.debug("getZones useEffect called ");
      // Query zones via realtime updates. Set the list of zones.
      const unsubscribeAllZones = DbRead.zones.subscribeAll({
        siteKey: props.siteKey,
        onChange: (zoneList: ExistingZone[]) => {
          setZoneList(zoneList), setZoneListIsLoading(false);
        },
        onError: (error) => logger.error(`Error in getZones: ${error.message}`),
      });
      return unsubscribeAllZones;
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    setZoneListIsLoading(true);
    const unsubscribePromise = getZones();
    // Return an anonymous ƒn for cleanup.
    return () => {
      logger.debug(
        "(CreateTaskForCustomerContainer) getZones useEffect return ƒn just invoked.",
      );
      unsubscribePromise.then((unsubscribe) => {
        if (unsubscribe) {
          logger.debug("Now unsubscribing from zones.");
          unsubscribe();
        }
      });
      logger.debug("Done running cleanup ƒn for getZones useEffect.");
    };
  }, [props.siteKey]);

  useEffect(() => {
    // generate the list of events based on vehicle and tasks
    if (calendarTasks.length === alreadyScheduledEvents.length) {
      const events = generateCalendarEvents({
        allocatedTasks: scheduledAwaitingTaskList,
        vehicleList: vehicleList,
        searchTerm: "",
        mode: resourcesMode,
        inactiveUserIDs: siteKeyDoc?.inactiveUsers ?? [],
        unapprovedUserIDs: siteKeyDoc?.unapprovedUsers ?? [],
        zoneList: zoneList,
      });

      for (const event of events) {
        event.moveDisabled = true;
        event.resizeDisabled = true;
      }

      setAlreadyScheduledEvents(events);
      setCalendarTasks(events);
    }
  }, [
    alreadyScheduledEvents.length,
    calendarTasks.length,
    resourcesMode,
    scheduledAwaitingTaskList,
    siteKeyDoc?.inactiveUsers,
    siteKeyDoc?.unapprovedUsers,
    vehicleList,
    zoneList,
  ]);

  useEffect(() => {
    function getStiltEventsBySelectedDate() {
      // typeguard
      if (!userPermissions) {
        logger.debug("No userPermissions yet...");
        return undefined;
      }
      // Start loading.
      if (isMounted.current) setStiltCalendarEventsIsLoading(true);

      logger.debug(`Running useEffect: ${getStiltEventsBySelectedDate.name}.`);

      //setting the date selected to doesn't care about the hours selected but
      // check the entire day
      const startDate = DateTime.fromJSDate(scheduleDateTime)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
        })
        .minus({ days: 30 });
      const endDate = DateTime.fromJSDate(scheduleDateTime)
        .set({
          hour: 23,
          minute: 59,
          second: 59,
        })
        .plus({ days: 30 });

      // Query for all awaiting scheduled tasks via realtime updates.
      const unsubscribeCalendarEventList =
        DbRead.calendarEvents.subscribeByDate({
          siteKey: props.siteKey,
          permissions: userPermissions,
          userID: firebaseUser.uid,
          startDate: convertLuxonDTToFSTimestamp(startDate),
          endDate: convertLuxonDTToFSTimestamp(endDate),
          onChange: (events) => {
            setStiltCalendarEvents(events);
            logger.debug(`useEffect stiltCalendarEvents: ${events}`);
          },
          onError: (error) =>
            logger.error(
              `Error in ${getStiltEventsBySelectedDate.name}: ${error.message}`,
            ),
        });

      // If user hasn't navigated away from the page, stop rendering the loading icon.
      if (isMounted.current) {
        setStiltCalendarEventsIsLoading(false);
      }
      return unsubscribeCalendarEventList;
    }

    const unsubscribe = getStiltEventsBySelectedDate();

    // Return an anonymous ƒn for cleanup.
    return () => {
      isMounted.current = false;

      if (unsubscribe) unsubscribe();
    };
  }, [firebaseUser.uid, scheduleDateTime, props.siteKey, userPermissions]);

  const stiltEvents: CalendarEvent[] = [];
  if (stiltCalendarEvents.length !== 0) {
    const result = generateStiltCalendarEvents({
      stiltCalendarEvents,
      moveDisabled: true,
      resizeDisabled: true,
    });

    stiltEvents.push(...result);
  }

  // #region SECTION: Mutations
  const mutateAddCustomer = useMutation(
    async (args: {
      validCustomer: Customer_CreateAPI;
      validLocationList: CustomerLocation_CreateAPI[] | null;
    }) => {
      if (args.validLocationList == null) {
        await DbWrite.customers.create(args.validCustomer);
      } else {
        await DbWrite.customers.create(args.validCustomer);
        await DbWrite.customerLocations.create(args.validLocationList);
        // Clear location list after save
        setLocations([]);
      }
    },
  );

  const mutateEditCustomer = useMutation(
    async (args: { editCustomer: ExistingCustomerUpdate }) => {
      await DbWrite.customers.update(args.editCustomer);
    },
  );

  // #endregion Mutations

  // #region SECTION: Functions
  function resetLocationDialog() {
    setAddress("");
    setGeocoderResult([]);
  }

  async function handleSelectBillingAddress(selectedAddress: string) {
    if (selectedAddress === "") {
      setGeocoderResult([]);
      setBillingAddressLine1("");
    } else {
      const results = await geocodeByAddress(selectedAddress);
      setGeocoderResult(results);
      setBillingAddressLine1(results[0].formatted_address);
    }
  }

  async function handleUpdateBillingInfo(billingInfo: BillingInfo) {
    if (!selectedCustomer) {
      return;
    }

    const validateEditCustomer: ExistingCustomerUpdate = {
      billingInfo: billingInfo,
      id: selectedCustomer.id,
      refPath: selectedCustomer.refPath,
      lastModifiedBy: firebaseUser.uid,
      timestampLastModified: Timestamp.now(),
    };
    setEditingBillingInfo(true);

    try {
      await mutateEditCustomer.mutateAsync({
        editCustomer: validateEditCustomer,
      });
      logger.debug("Customer Billing Info has been updated successfully");
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate("Billing Info"),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(`An error occurred during handleUpdateBillingInfo`, error);
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    } finally {
      setEditingBillingInfo(false);
      setEditBillingAddressDialogOpen(false);
      setShowManualEntryForm(false);
    }
  }

  /** Stores the location that was created within the new customer dialog. */
  function handleSetLocation(newLocationItem: TemporaryLocation) {
    setIsAddressLoading(true);
    setLocations([...locations, newLocationItem]);
    /* reset the address field after the new location is added to the object */
    resetLocationDialog();
    setIsAddressLoading(false);
    setLocationDialogWithinCustomerDialogOpen(false);
    setShowManualEntryForm(false);
  }

  function handleSaveManualCustomerLocation(
    newLocationItem: TemporaryLocation,
  ) {
    setIsAddressLoading(true);

    //for now use the siteKey latitude or longitude, later we will allow the user to pinpoint the location on a map
    newLocationItem.latitude = siteKeyDoc?.latitude ?? null;
    newLocationItem.longitude = siteKeyDoc?.longitude ?? null;

    setLocations([...locations, newLocationItem]);
    resetLocationDialog();
    setIsAddressLoading(false);
    setLocationDialogWithinCustomerDialogOpen(false);
    setShowManualEntryForm(false);
  }

  /** Save new customer (& location(s)), set this new customer as the selected one. */
  async function handleSaveNewCustomer(
    formValues: Omit<
      Customer,
      | "timestampCreated"
      | "timestampLastModified"
      | "createdBy"
      | "lastModifiedBy"
    >,
  ) {
    /** This becomes the customer doc ID */
    const newCustomerID = DbRead.randomDocID.get();

    const newCustomer: Customer_CreateAPI = {
      name: formValues.name,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      phone: formValues.phone,
      notes: formValues.notes,
      type: formValues.type,
      createdBy: firebaseUser.uid,
      lastModifiedBy: firebaseUser.uid,
      tags: formValues.tags,
      customData: formValues.customData,
      isTaxExempt: formValues.isTaxExempt,
      notificationGroups: formValues.notificationGroups,
      website: formValues.website,
      quickbooksID: formValues.quickbooksID,
      deleted: formValues.deleted,
      siteKey: props.siteKey,
      uuid: newCustomerID,
      customerLocations: {},
    };

    if (
      siteKeyDoc?.customizations?.accounting?.currency === "CAD" ||
      siteKeyDoc?.customizations?.accounting?.currency === "cad"
    ) {
      newCustomer.isTaxExemptGST = formValues.isTaxExemptGST ?? false;
      newCustomer.isTaxExemptPST = formValues.isTaxExemptPST ?? false;
    }

    const validCustomer = CustomerManager.parseCreate(newCustomer);

    let validLocationList: CustomerLocation_CreateAPI[] | null = null;
    if (locations.length !== 0) {
      const newLocationList: CustomerLocation_CreateAPI[] = locations.map(
        (location) => {
          const customerLocationID = DbRead.randomDocID.get();

          return {
            ...location,
            siteKey: props.siteKey,
            uuid: customerLocationID,
            customerID: newCustomerID,
            createdBy: firebaseUser.uid,
            lastModifiedBy: firebaseUser.uid,
          };
        },
      );
      validLocationList = newLocationList.map((location) =>
        CustomerLocationManager.parseCreate(location),
      );
    }

    logger.info("Customer:", validCustomer);
    logger.info("Locations:", validLocationList);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { uuid, siteKey, ...rest } = validCustomer;
    const savedCustomer: ExistingCustomer = {
      id: newCustomerID,
      refPath: `siteKeys/${props.siteKey}/customers/${newCustomerID}`,
      // NOTE: We drop timestamps when calling the API, so we can use the current
      // time, even though it won't *quite* match what's been saved to the DB.
      timestampCreated: Timestamp.now(),
      timestampLastModified: Timestamp.now(),
      ...rest,
    };

    try {
      await mutateAddCustomer.mutateAsync({
        validCustomer: validCustomer,
        validLocationList: validLocationList,
      });
      // If successful, set the selected customer.
      setSelectedCustomer(savedCustomer);
    } catch (e) {
      addMessage({
        id: createToastMessageID(),
        message: strings.failedAdd("new customer"),
        dialog: false,
        type: "error",
      });
    }
  }

  async function handleSaveCustomerLocation(values: TemporaryLocation) {
    if (!selectedCustomer) {
      logger.error(
        "Attempted to save new location with no customer selected - should not happen",
      );
      return;
    }

    setIsAddressLoading(true);

    const customerLocationID = DbRead.randomDocID.get();

    const locationDoc: CustomerLocation_CreateAPI = {
      ...values,
      siteKey: props.siteKey,
      uuid: customerLocationID,
      customerID: selectedCustomer.id,
      createdBy: firebaseUser.uid,
      lastModifiedBy: firebaseUser.uid,
    };

    const validLocation = CustomerLocationManager.parseCreate(locationDoc);

    // try/catch is in the dialog, don't need it here.
    const result = await DbWrite.customerLocations.create([validLocation]);

    const { siteKey, ...rest } = validLocation;
    const newLocation: ExistingCustomerLocation = {
      id: result[0],
      refPath: `siteKeys/${siteKey}/customerLocations/${result[0]}`,
      // NOTE: We drop timestamps when calling the API, so we can use the current
      // time, even though it won't *quite* match what's been saved to the DB.
      timestampCreated: Timestamp.now(),
      timestampLastModified: Timestamp.now(),
      ...rest,
    };
    // Set this as the current location if db write was successful
    setSelectedCustomerLocation(newLocation);
    setStandaloneLocationDialogOpen(false);
    resetLocationDialog();
    setIsAddressLoading(false);
    setShowManualEntryForm(false);
  }

  async function handleSelectAddress(selectedAddress: string) {
    const results = await geocodeByAddress(selectedAddress);
    setGeocoderResult(results);
    setAddress(results[0].formatted_address);
  }

  function handleResponses(args: {
    responses: CustomFieldResponse;
    craftRecordOrTask: ExistingCustomField["craftRecordOrTask"];
  }) {
    if (args.craftRecordOrTask === "craftRecord") {
      console.log("setting craft details response", args.responses);
      setResponses_CD((prev) => ({ ...prev, ...args.responses }));
    } else if (args.craftRecordOrTask === "task") {
      setResponses_TSD((prev) => ({ ...prev, ...args.responses }));
    } else {
      logger.error(
        `create task for customer: custom field response does not belong to craftRecord or task. response: ${args.responses}. craftRecordOrTask: ${args.craftRecordOrTask}`,
      );
    }
  }

  const {
    scheduledServiceWindowStart,
    scheduledServiceWindowEnd,
    // timestampScheduled,
  } = getServiceWindowAndScheduledTS({
    scheduledByPriority,
    scheduledDateTime: scheduleDateTime,
    serviceWindowDuration,
  });

  // Get taskSpecificNotes out of responses_TSD, since it's not a custom field
  // and it should go on the task document instead.
  const { taskSpecificNotes, ...restTSD } = responses_TSD;
  const taskDescription =
    taskSpecificNotes &&
    taskSpecificNotes !== "" &&
    typeof taskSpecificNotes === "string"
      ? taskSpecificNotes
      : null;

  /** craft detail original default values */
  const originalDV_CD: Record<string, Json | Date> = {};
  workRecordCFsRef.current.forEach((cf) => {
    const dv = getDefaultValuesForNewResponse({
      type: cf.fieldType,
      defaultValue: cf.defaultValue,
      userDisplayNamesMap,
      selectionOptions:
        cf.fieldType === "selection" ? cf.selectionOptions : null,
    });

    if (cf.fieldType === "hours-minutes" && Array.isArray(dv)) {
      originalDV_CD[`${cf.id}_hoursValue`] = dv[0];
      originalDV_CD[`${cf.id}_minutesValue`] = dv[1];
      // } else if (cf.id === "jobTemplateNotes") {
      //   // UNSURE if we actually want this here.
      //   originalDV_CD[cf.id] = templateNotes ? templateNotes : "";
    } else {
      originalDV_CD[cf.id] = dv;
    }
  });

  /** task specific details original default values */
  const originalDV_TSD: Record<string, Json | Date> = {};
  taskCFsRef.current.forEach((cf) => {
    const dv = getDefaultValuesForNewResponse({
      type: cf.fieldType,
      defaultValue: cf.defaultValue,
      userDisplayNamesMap,
      selectionOptions:
        cf.fieldType === "selection" ? cf.selectionOptions : null,
    });

    if (cf.fieldType === "hours-minutes" && Array.isArray(dv)) {
      originalDV_TSD[`${cf.id}_hoursValue`] = dv[0];
      originalDV_TSD[`${cf.id}_minutesValue`] = dv[1];
    } else {
      originalDV_TSD[cf.id] = dv;
    }
  });

  const { prunedCD, prunedTSD } = pruneDynamicDetails({
    workRecOriginals: originalDV_CD,
    taskOriginals: originalDV_TSD,
    responses_CD: responses_CD,
    responses_TSD: restTSD,
  });

  const craftTitle =
    selectedCustomer && selectedCustomerLocation
      ? getCraftRecordTitle(selectedCustomer.name, selectedCustomerLocation)
      : "";

  // Convert selected work type into the expected integer
  const craftTypeStr = getCraftTypeStringFromReadableString(selectedWorkType);
  const craftTypeInt = getCraftTypeFromString(craftTypeStr);
  // Convert selected task type string into expected integer
  const taskTypeInt = selectedTaskType
    ? getTaskTypeValueFromReadableStr(selectedTaskType)
    : undefined;

  const taskResponses = convertResponsesToExpectedTypes({
    customFields: taskCFsRef.current,
    values: prunedTSD,
    userList: userList.current,
  });

  if (assignedTo_TaskCF) {
    // if we're here, it means there's an assignedTo custom field in the scheduling
    // section. we want to add that to the taskSpecificDetails:
    taskResponses["assignedTo"] = assignedToUIDs;
  }

  if (
    assignedVehicle != null &&
    siteKeyDoc?.customizations.vehiclesEnabled === true
  ) {
    taskResponses["assignedVehicleID"] = assignedVehicle;
  }

  // Construct taskSpecificDetails object
  const taskSpecificDetails = dropUndefined({
    scheduledServiceWindowStart,
    scheduledServiceWindowEnd,
    ...taskResponses,
  });

  async function handleSaveTask() {
    // #region Typeguards
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    if (selectedSiteKeyLocationID == null) {
      addMessage({
        id: createToastMessageID(),
        message: strings.NO_SITE_LOCATION_SELECTED,
        dialog: false,
        type: "error",
      });
      logger.error("selectedSiteKeyLocationID is null");
      return;
    }

    if (!selectedCustomer) {
      addMessage({
        id: createToastMessageID(),
        message: strings.NO_CUSTOMER_SELECTED,
        dialog: false,
        type: "error",
      });
      return;
    }

    if (!selectedCustomerLocation) {
      addMessage({
        id: createToastMessageID(),
        message: strings.NO_CUSTOMER_LOCATION_SELECTED,
        dialog: false,
        type: "error",
      });
      return;
    }

    if (!permissionsDoc) {
      addMessage({
        id: createToastMessageID(),
        message: strings.NO_USER_PERMISSIONS_DOC,
        dialog: false,
        type: "error",
      });
      logger.error(
        `create task for customer - no user permissions doc found for uid ${firebaseUser.uid}`,
      );
      return;
    }

    if (selectedCustomer.doNotService === true) {
      addMessage({
        id: createToastMessageID(),
        message: `Customer is marked as ${strings.DO_NOT_SERVICE}`,
        dialog: false,
        type: "error",
      });
      return;
    }

    if (!selectedWorkType) {
      addMessage({
        id: createToastMessageID(),
        message: strings.NO_WORK_TYPE_SELECTED,
        dialog: false,
        type: "error",
      });
      return;
    }

    if (!selectedTaskType) {
      addMessage({
        id: createToastMessageID(),
        message: strings.NO_TASK_TYPE_SELECTED,
        dialog: false,
        type: "error",
      });
      return;
    }

    if (notesCharacterCounter < 0) {
      addMessage({
        id: createToastMessageID(),
        message: strings.ERR_DESCRIPTION_TOO_LONG,
        dialog: false,
        type: "error",
      });
      return;
    }

    //I needed to move it before the last typeguard to allow the check for the next requirement before starting create the task
    const {
      // scheduledServiceWindowStart,
      // scheduledServiceWindowEnd,
      timestampScheduled,
    } = getServiceWindowAndScheduledTS({
      scheduledByPriority,
      scheduledDateTime: scheduleDateTime,
      serviceWindowDuration,
    });

    if (
      siteKeyDoc?.customizations.requireAssignedUserForScheduledTask &&
      timestampScheduled != null &&
      (!assignedToUIDs || assignedToUIDs.length === 0)
    ) {
      addMessage({
        id: createToastMessageID(),
        message: strings.NO_ASSIGNED_TO_USER,
        dialog: false,
        type: "error",
      });
      return;
    }

    // if (overlappingTasksUserName.length > 0) {
    //   addMessage({
    //     id: createToastMessageID(),
    //     message: strings.TASKS_OVERLAPPING,
    //     dialog: false,
    //     type: "error",
    //   });
    //   return;
    // }

    // Show loading indicator on "create task" button
    setIsSubmitting(true);

    if (!isValidCraftType(craftTypeInt)) {
      addMessage({
        id: createToastMessageID(),
        message: strings.UNRECOGNIZED_WORK_TYPE,
        dialog: false,
        type: "error",
      });
      logger.error(
        `create task for customer - Invalid craft type. Received ${selectedWorkType}, could not convert`,
      );
      setIsSubmitting(false);
      return;
    }

    if (!isValidTaskType(taskTypeInt)) {
      addMessage({
        id: createToastMessageID(),
        message: strings.UNRECOGNIZED_TASK_TYPE,
        dialog: false,
        type: "error",
      });
      logger.error(
        `create task for customer - Invalid task type. Received ${selectedTaskType}, could not convert`,
      );
      setIsSubmitting(false);
      return;
    }
    // #endregion Typeguards

    const craftTitle = getCraftRecordTitle(
      selectedCustomer.name,
      selectedCustomerLocation,
    );

    const taskStatus = getTaskStatusForApprovedTask(
      scheduledByPriority,
      convertJSDateToFSTimestamp(scheduleDateTime),
    );

    /** craft detail original default values */
    const originalDV_CD: Record<string, Json | Date> = {};
    workRecordCFsRef.current.forEach((cf) => {
      const dv = getDefaultValuesForNewResponse({
        type: cf.fieldType,
        defaultValue: cf.defaultValue,
        userDisplayNamesMap,
        selectionOptions:
          cf.fieldType === "selection" ? cf.selectionOptions : null,
      });

      if (cf.fieldType === "hours-minutes" && Array.isArray(dv)) {
        originalDV_CD[`${cf.id}_hoursValue`] = dv[0];
        originalDV_CD[`${cf.id}_minutesValue`] = dv[1];
      } else {
        originalDV_CD[cf.id] = dv;
      }
    });

    /** task specific details original default values */
    const originalDV_TSD: Record<string, Json | Date> = {};
    taskCFsRef.current.forEach((cf) => {
      const dv = getDefaultValuesForNewResponse({
        type: cf.fieldType,
        defaultValue: cf.defaultValue,
        userDisplayNamesMap,
        selectionOptions:
          cf.fieldType === "selection" ? cf.selectionOptions : null,
      });

      if (cf.fieldType === "hours-minutes" && Array.isArray(dv)) {
        originalDV_TSD[`${cf.id}_hoursValue`] = dv[0];
        originalDV_TSD[`${cf.id}_minutesValue`] = dv[1];
      } else {
        originalDV_TSD[cf.id] = dv;
      }
    });

    // CHECK FOR MISSING REQUIRED RESPONSES
    const missingTitles_CD = checkCFsMissingRequiredResponse({
      base: workRecordCFsRef.current,
      originals: originalDV_CD,
      responses: responses_CD,
    });

    const missingTitles_TSD = checkCFsMissingRequiredResponse({
      base: taskCFsRef.current,
      originals: originalDV_TSD,
      responses: responses_TSD,
    });
    if (missingTitles_CD.length > 0 || missingTitles_TSD.length > 0) {
      missingTitles_CD.forEach((title) => {
        addMessage({
          id: createToastMessageID(),
          message: strings.missingResponseForCF(title),
          dialog: false,
          type: "error",
        });
      });
      missingTitles_TSD.forEach((title) => {
        addMessage({
          id: createToastMessageID(),
          message: strings.missingResponseForCF(title),
          dialog: false,
          type: "error",
        });
      });
      setIsSubmitting(false);
      return;
    }

    const craftResponses = convertResponsesToExpectedTypes({
      customFields: workRecordCFsRef.current,
      values: prunedCD,
      userList: userList.current,
    });

    const craftDetailsFinal = dropUndefined(craftResponses);

    /** This becomes the craftRecord doc ID. */
    const craftRecordID = craftRecordIdFromParams ?? DbRead.randomDocID.get();
    /** This goes on the task doc as the craftRecordID property */
    const craftRecordPATH = `siteKeys/${props.siteKey}/parentRecords/${craftRecordID}`;

    const selectedSiteLocation = siteKeyLocations.find(
      (siteLocation) => siteLocation.id === selectedSiteKeyLocationID,
    );

    if (selectedSiteLocation) {
      const craftRecord: CraftRecord_CreateForCustomer = {
        createdBy: firebaseUser.uid,
        lastModifiedBy: firebaseUser.uid,
        assetID: null,
        closedTasks: [],
        closedTaskTypes: [],
        numClosedTasks: 0,
        numOpenTasks: 0,
        open: true,
        openTasks: [],
        openTaskTypes: [],
        thumbnailURL: null,
        timestampRecordClosed: null,
        timestampLastModified: Timestamp.now().toMillis(),
        timestampRecordCreated: Timestamp.now().toMillis(),
        customerID: selectedCustomer.id,
        customerLocationID: selectedCustomerLocation.id,
        // If lat and long aren't available, use data from siteKey location doc.
        latitude: latitude ?? selectedSiteLocation.latitude,
        longitude: longitude ?? selectedSiteLocation.longitude,
        title: craftTitle,
        authorizedCompanies: [permissionsDoc.companyID ?? ""],
        locationID: selectedSiteKeyLocationID,
        craftType: craftTypeInt,
        description: notes && notes.length > 0 ? notes : null,
        craftDetails: craftDetailsFinal,
      };

      const partialTask: Task_CreateForCustomer = {
        title: `${selectedTaskType} - ${craftTitle}`,
        createdBy: firebaseUser.uid,
        lastModifiedBy: firebaseUser.uid,
        thumbnailURL: null,
        urgent: scheduledByPriority === strings.URGENT,
        nextOpportunity: scheduledByPriority === strings.NEXT_OPPORTUNITY,
        workOrder: "",
        crewCount: 0,
        durations: {},
        holdDurations: {},
        notifyCompanyOnCreation: true,
        locationID: selectedSiteKeyLocationID,
        craftType: craftTypeInt,
        taskType: taskTypeInt,
        taskStatus: taskStatus,
        taskSpecificDetails: taskSpecificDetails,
        sendBookingConfirmationEmail: sendBookingConfirmationEmail,
        sendJobReminderEmail: sendJobReminderEmail,
        membershipIDs: membershipIDsForTask,
        // if membershipIDs is not empty, then this is a membership task
        isMembershipTask:
          membershipIDsForTask && membershipIDsForTask?.length > 0,
        primaryContactEmail: primaryContactEmail?.id,
        primaryContactPhone: primaryContactPhone?.id,
        timestampCreated: Timestamp.now().toMillis(),
        timestampLastModified: Timestamp.now().toMillis(),
        description: taskDescription,
        customerID: selectedCustomer.id,
        customerLocationID: selectedCustomerLocation.id,
        assignedCompanyID: permissionsDoc.companyID ?? "",
        timestampScheduled: timestampScheduled
          ? timestampScheduled.toMillis()
          : null,
        timestampAwaitingStart:
          taskStatus === TaskStatus.AWAITING
            ? Timestamp.now().toMillis()
            : null,
        craftRecordID: craftRecordPATH,
        timestampTaskCompleted: null,
        timestampTaskStarted: null,
        craftRecordPersistence: CraftRecordPersistenceTypes["KEEP"],
      };

      let fullTask: Task_CreateForCustomer;
      // if we don't have access to latitude or longitude, omit writing that property
      // lat & long are optional on task docs, but required on craft record docs.
      if (!latitude && longitude) {
        fullTask = {
          ...partialTask,
          longitude: longitude,
        };
      } else if (latitude && !longitude) {
        fullTask = {
          ...partialTask,
          latitude: latitude,
        };
      } else if (latitude && longitude) {
        fullTask = {
          ...partialTask,
          longitude: longitude,
          latitude: latitude,
        };
      } else {
        fullTask = partialTask;
      }

      // If there's membershipData, add that to the taskSpecificDetails
      const membershipData =
        state?.membershipData as TaskCreationMembershipData;
      if (membershipData?.membershipID) {
        fullTask.membershipIDs = [membershipData?.membershipID];
        fullTask.isMembershipTask = true;
      }

      // Convert to doc data
      const taskReady = TaskRecordManager.convertNewForFirestore(fullTask);
      const craftRecReady =
        CraftRecordManager.convertNewForFirestore(craftRecord);

      let estimateID: string | undefined = estimateDoc?.id;

      // #region create estimate
      if (
        siteKeyDoc?.customizations.estimatesEnabled &&
        estimateItemList.length !== 0 &&
        createEstimate === true
      ) {
        const estimatePackageID = DbRead.randomDocID.get();

        estimateID = DbRead.randomDocID.get();

        const newEstimatePackage: EstimatePackage_CreateAPI = {
          notes: null,
          customerID: selectedCustomer.id,
          createdBy: firebaseUser.uid,
          lastModifiedBy: firebaseUser.uid,
          siteKey: props.siteKey,
          uuid: estimatePackageID,
        };

        // Validate estimate package
        const validatedEstimatePackage =
          EstimatePackageManager.parseCreate(newEstimatePackage);
        logger.info("Validated estimate package:", validatedEstimatePackage);

        let totalDiscount = 0;

        if (globalDiscount !== null) {
          totalDiscount += globalDiscount;
        }

        if (totalSelectedDiscounts != null) {
          totalDiscount += totalSelectedDiscounts;
        }

        const newEstimate: Estimate_CreateAPI = {
          status: EstimateStatus.DRAFT, // when is new, this is the default status. it will be update later on view estimate page
          notes: estimateNotes,
          internalNotes: estimateInternalNotes,
          customerID: selectedCustomer.id,
          customerLocationID: selectedCustomerLocation.id,

          // TODO: change this logic when we allow more estimates inside estimatePackages.
          // right now is only one estimate per package
          estimatePackageID: estimatePackageID,
          taskID: null,
          craftRecordID: craftRecordID,
          locationID: selectedSiteKeyLocationID,

          customData: {},
          tags: [],
          discount: totalDiscount !== 0 ? totalDiscount : null,
          createdBy: firebaseUser.uid,
          lastModifiedBy: firebaseUser.uid,

          timestampSentToCustomer: null, // is set to null when create new estimate, it will be update with the "send to customer button" on view estimate page
          timestampApprovedByCustomer: null, // is set to null when create new estimate, will be update later on view estimate page
          timestampExpiration: null, //for now is null, will be updated after MVP, when we will add the expiration field

          deleted: false,
          siteKey: props.siteKey,
          uuid: estimateID,
        };

        //Validate estimate
        const validatedEstimate = EstimateManager.parseCreate(newEstimate);
        logger.info("Validated estimate:", validatedEstimate);

        let validatedEstimateItemList = [];
        const newEstimateItemList: EstimateItem_CreateAPI[] =
          estimateItemList.map((estimateItem) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { toBeEdited, ...rest } = estimateItem;
            return {
              ...rest,
              locationID: selectedSiteKeyLocationID,
              createdBy: firebaseUser.uid,
              lastModifiedBy: firebaseUser.uid,
              estimateID: estimateID!,
              siteKey: props.siteKey,
            };
          });

        validatedEstimateItemList = newEstimateItemList.map((estimateItem) =>
          EstimateItemManager.parseCreate(estimateItem),
        );
        logger.info("Validated estimate item list:", validatedEstimateItemList);

        try {
          await DbWrite.estimates.create(
            validatedEstimate,
            validatedEstimatePackage,
            validatedEstimateItemList,
          );
        } catch (error) {
          logger.error(
            `An error occurred during save an estimate on handleSaveTask`,
            error,
          );
          addMessage({
            id: createToastMessageID(),
            message: "Unable to create estimate.",
            dialog: false,
            type: "error",
          });
        }
      }
      // #endregion create estimate

      //#region use existing estimate
      if (
        siteKeyDoc?.customizations.estimatesEnabled &&
        selectedEstimate !== null &&
        createEstimate === false
      ) {
        estimateID = selectedEstimate.id;
      }
      //#endregion use existing estimate

      // DB 🔥
      try {
        // Create inventory transactions from pendingTransactions
        if (pendingInventoryTransactions.length > 0) {
          await DbWrite.inventoryTransactions.createMultiple(
            props.siteKey,
            pendingInventoryTransactions,
          );
        }
        // Write task doc and also parentRecord doc if not an existing one
        if (craftRecordIdFromParams === null) {
          await DbWrite.tasks.createForCustomer({
            siteKey: props.siteKey,
            taskDoc: taskReady,
            craftRecordDoc: craftRecReady,
            craftRecordID: craftRecordID,
            estimateID: estimateID ?? null,
          });
        } else {
          await DbWrite.tasks.createForCustomer({
            siteKey: props.siteKey,
            taskDoc: taskReady,
            estimateID: estimateID ?? null,
          });
        }

        if (props.setCreateTaskDialogOpen) {
          props.setCreateTaskDialogOpen(false);
        } else {
          // Send them back to the customers table if all went well.
          navigate(`${WORK_RECORD_AND_TASKS_URL}/${craftRecordID}`);
        }
      } catch (e) {
        logger.error("error adding task for customer", e);
        addMessage({
          id: createToastMessageID(),
          message: strings.ERROR_CREATING_CUSTOMER_TASK,
          dialog: false,
          type: "error",
        });
      } finally {
        // Stop the button's loading spinner, regardless of write failure or success.
        setIsSubmitting(false);
      }
    }
  }

  function handleAddEstimateItem(tempEstimateItem: TemporaryEstimateItem) {
    estimateItemList.push(tempEstimateItem);
    setEstimateItemList(estimateItemList);
  }

  function removeEstimateItemFromList(
    estimateItemToBeDeleted: TemporaryEstimateItem,
  ): void {
    const filteredList = estimateItemList.filter(
      (estimateItem) => estimateItem.id !== estimateItemToBeDeleted.id,
    );
    setEstimateItemList(filteredList);
  }

  function editEstimateItemFromList(
    editedEstimateItem: TemporaryEstimateItem,
  ): void {
    const indexOfEstimateItem = estimateItemList.findIndex(
      (estimateItem) => estimateItem.id === editedEstimateItem.id,
    );

    if (indexOfEstimateItem >= 0) {
      setEstimateItemList((oldEstimateList) => {
        oldEstimateList[indexOfEstimateItem] = editedEstimateItem;
        return [...oldEstimateList];
      });
    }
  }

  function turnItemToBeEditable(itemToBeEdited: TemporaryEstimateItem): void {
    const indexOfEstimateItem = estimateItemList.findIndex(
      (estimateItem) => estimateItem.id === itemToBeEdited.id,
    );
    if (indexOfEstimateItem >= 0) {
      setEstimateItemList((oldEstimateList) => {
        oldEstimateList[indexOfEstimateItem].toBeEdited = true;
        return [...oldEstimateList];
      });
    }
  }

  //TODO: dry this up from elsewhere
  async function handleEditCustomer(updateCustomer: customerWithoutTimestamps) {
    if (selectedCustomer == null) {
      return;
    }

    const initialValuesForCustomer = cloneDeep(selectedCustomer);

    /* check the difference between the initial customer doc and the updated one */
    const diffCustomerValues: DocumentData = diffObjects(
      initialValuesForCustomer,
      updateCustomer,
    ).diff;

    if (Object.keys(diffCustomerValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    const validateEditCustomer: ExistingCustomerUpdate = {
      ...diffCustomerValues,
      id: selectedCustomer.id,
      refPath: selectedCustomer.refPath,
      lastModifiedBy: firebaseUser.uid,
      timestampLastModified: Timestamp.now(),
    };

    try {
      await mutateEditCustomer.mutateAsync({
        editCustomer: validateEditCustomer,
      });
      logger.debug("Customer has been updated successfully.");
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(
          updateCustomer.name ?? initialValuesForCustomer.name,
        ),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(`An error occurred during handleSaveNewCustomer`, error);
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  function renderEstimateIconCell(params: ICellRendererParams) {
    return (
      <BaseButtonSecondary
        onClick={() => setSelectedEstimate(params.data)}
        className="mt-0.5 min-w-fit sm:w-36"
        data-testid={`select-customer-${params.data.id}`}
      >
        {strings.SELECT}
      </BaseButtonSecondary>
    );
  }

  async function handleSaveCustomerContact(
    customerContact: CustomerContactFormState,
  ) {
    if (!siteKeyDoc) return;
    if (!selectedCustomer) return;

    await DbWrite.customerContacts.create({
      ...customerContact,
      createdBy: firebaseUser.uid,
      customerID: selectedCustomer.id,
      customerLocationID: null,
      lastModifiedBy: firebaseUser.uid,
      siteKey: siteKeyDoc.id,
    });
  }

  // #endregion Functions

  // #region MatchCustomerHooks

  /* Incoming Call Store */
  const currentCall = useIncomingCallsStore((state) => state.currentCall);
  const clearCurrentCall = useIncomingCallsStore((state) => state.clearCall);
  const alertStyle = useIncomingCallsStore((state) => state.alertStyle());
  const updateCallCustomer = useIncomingCallsStore(
    (state) => state.updateCallCustomer,
  );

  type AlertProps = {
    title: JSX.Element | string;
    message: JSX.Element | string;
    actionButtonText: string;
    style: AlertType;
  };
  const alertPropsMap: Record<AlertStyle, AlertProps> = {
    confirm: {
      style: "info",
      title: (
        <span>
          Confirm customer for call from <strong>{currentCall?.caller}</strong>
        </span>
      ),
      message: (
        <span>
          Confirm <strong>{selectedCustomer?.name}</strong> for this call?
        </span>
      ),

      actionButtonText: "Confirm",
    },
    match: {
      style: "warning",
      title: (
        <span>
          Customer match needed for call from{" "}
          <strong>{currentCall?.caller}</strong>
        </span>
      ),
      message: (
        <span>
          Match <strong>{selectedCustomer?.name}</strong> to this call?
        </span>
      ),
      actionButtonText: "Match",
    },
  };
  const alertProps = alertPropsMap[alertStyle];
  const callAlertStyle = alertStyle === "confirm" ? "info" : "warning";

  const callUpdate = useMutation(
    () => {
      if (!selectedCustomer) {
        throw new Error("Expected customer doc to be present.");
      }
      return updateCallCustomer({
        currentUserID: firebaseUser.uid,
        customerID: selectedCustomer.id,
      });
    },
    {
      onError: () => {
        logger.error("Could not update call document.");
        clearCurrentCall();
      },
    },
  );

  // #endregion MatchCustomerHooks

  // #region CONDITIONAL RENDERING -- NO HOOKS BEYOND THIS POINT ☠️☠️️
  // Display loading clipboard while customer, vehicle and task lists loads.
  if (
    craftRecordLoading ||
    vehicleListIsLoading ||
    scheduledAwaitingTaskListIsLoading ||
    !siteKeyDoc ||
    typesenseLoading ||
    stiltCalendarEventsIsLoading ||
    dailyVehicleIsLoading ||
    zoneListIsLoading
  ) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  // #region SECTION: Components
  const addressError = (
    <div className="mt-2 text-sm">
      <StyledMessage type="error">
        {{ message: strings.REQUIRED_FIELD }}
      </StyledMessage>
    </div>
  );

  const selectCustomerButton = (
    <BaseButtonSecondary
      type="button"
      onClick={() => {
        setCustomerDialogStates({
          ...customerDialogStates,
          selectCustomerDialogOpen: true,
        });
        setSelectedCustomerLocation(null);
      }}
      className="w-full text-primary xs:w-auto"
    >
      <CheckCircle fontSize="small" className="mr-2" />
      {strings.SELECT_EXISTING_CUSTOMER}
    </BaseButtonSecondary>
  );

  const addCustomerButton = (
    <BaseButtonSecondary
      type="button"
      onClick={() => {
        setCustomerDialogStates({
          ...customerDialogStates,
          customerDialogOpen: true,
        });
      }}
      className="w-full text-primary xs:w-auto"
    >
      <AddCircleIcon fontSize="small" className="mr-2" />
      {strings.ADD_NEW_CUSTOMER}
    </BaseButtonSecondary>
  );

  const addressField = getAddressFieldComponent({
    address: address,
    onSelect: handleSelectAddress,
    onChange: (newAddress: string) => {
      if (newAddress === "") {
        setDisplayAddressError(true);
        setAddress(newAddress);
      } else {
        setAddress(newAddress);
        setDisplayAddressError(false);
      }
    },
  });

  const addressFieldBillingInfo = getAddressFieldComponent({
    address: billingAddressLine1,
    onSelect: handleSelectBillingAddress,
    onChange: (newAddress: string) => {
      if (newAddress === "") {
        setDisplayAddressError(true);
        setBillingAddressLine1(newAddress);
      } else {
        setBillingAddressLine1(newAddress);
        setDisplayAddressError(false);
      }
    },
  });

  function getAddressFieldComponent(args: {
    address: string;
    onChange: (value: string) => void;
    onSelect: (address: string, placeID: string) => void;
  }) {
    const addressFieldComponent = (
      <div className="flex w-full items-center gap-8">
        <PlacesAutocomplete
          value={args.address}
          onChange={args.onChange}
          onSelect={args.onSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className="w-full">
              <BaseInputText
                {...getInputProps({
                  admin: true,
                  required: true,
                  inputName: "fullAddress",
                  text: "Address",
                })}
              />
              <div
                className={
                  suggestions.length > 0 || loading
                    ? "absolute z-10 max-w-sm rounded border bg-white p-2"
                    : ""
                }
              >
                {loading ? (
                  <div className="p-2 text-primary">
                    <LoadingSpinner />
                  </div>
                ) : null}

                {suggestions.map((suggestion, suggestionIdx) => {
                  const style = {
                    backgroundColor: suggestion.active
                      ? "var(--primary-opacity-90)"
                      : "#fff",
                    borderRadius: "0.25rem",
                    padding: "5px",
                  };
                  return (
                    <p
                      {...getSuggestionItemProps(suggestion, { style })}
                      key={suggestionIdx}
                    >
                      {suggestion.description}
                    </p>
                  );
                })}
              </div>
              {displayAddressError ? addressError : null}
            </div>
          )}
        </PlacesAutocomplete>
        <BaseButtonSecondary
          type="button"
          onClick={() => setShowManualEntryForm(true)}
          className="w-full text-primary sm:w-56"
        >
          <PencilIcon aria-label="save" className="mr-4 h-6" />
          {strings.MANUAL_ENTRY}
        </BaseButtonSecondary>
      </div>
    );

    return addressFieldComponent;
  }

  const editBillingInfoDialog = (
    <EditBillingInfoDialog
      key={billingInfo.addressLine1}
      billingInfo={billingInfo}
      isSubmitting={isEditingBillingInfo}
      showManualEntryForm={showManualEntryForm}
      closeDialog={() => {
        setEditBillingAddressDialogOpen(false);
        setShowManualEntryForm(false);
      }}
      isDialogOpen={editBillingAddressDialogOpen}
      geocoderResult={geocoderResult[0]}
      handleUpdateBillingInfo={handleUpdateBillingInfo}
    >
      {{
        AddressField: addressFieldBillingInfo,
      }}
    </EditBillingInfoDialog>
  );

  const selectCustomerDialog = (
    <CustomerTableDialog
      isOpen={customerDialogStates.selectCustomerDialogOpen}
      onClose={() => {
        setCustomerDialogStates({
          ...customerDialogStates,
          selectCustomerDialogOpen: false,
        });
      }}
      siteKey={siteKeyDoc.id}
      onSelectCustomer={(c: ExistingCustomer) => {
        setSelectedCustomer(c);
        setCustomerDialogStates({
          ...customerDialogStates,
          selectCustomerDialogOpen: false,
        });
      }}
      addCustomerButton={addCustomerButton}
    />
  );

  const editCustomerDialog = selectedCustomer && (
    <EditCustomerDialog
      isDialogOpen={editCustomerDialogOpen}
      addMembershipsButton={null}
      closeDialog={() => {
        setEditCustomerDialogOpen(false);
      }}
      handleEditCustomer={handleEditCustomer}
      customer={cloneDeep(selectedCustomer)}
    >
      {{}}
    </EditCustomerDialog>
  );

  const addCustomerContactDialog = (
    <AddEditCustomerContactDialog
      closeDialog={() => {
        setAddCustomerContactDialogOpen(false);
      }}
      isDialogOpen={addCustomerContactDialogOpen}
      handleSave={handleSaveCustomerContact}
      handleEdit={async () => {}}
      selectedCustomerContact={null}
    />
  );

  const displayCustomerData = (
    <div className="pt-6 font-bold sm:pt-2">
      {selectedCustomer ? (
        <div className="flex w-full flex-row justify-between">
          <CustomerDetails
            customer={selectedCustomer}
            openEditCustomerDialog={() => setEditCustomerDialogOpen(true)}
            openCallDialog={null}
          />
          <BillingInfoSection
            billingInfo={billingInfo}
            onEditBillingAddress={() => setEditBillingAddressDialogOpen(true)}
          />
          {customerContacts && (
            <CustomerContactSection
              customerContacts={customerContacts}
              primaryContactEmail={primaryContactEmail}
              setPrimaryContactEmail={setPrimaryContactEmail}
              primaryContactPhone={primaryContactPhone}
              setPrimaryContactPhone={setPrimaryContactPhone}
              onContactAdded={() => setAddCustomerContactDialogOpen(true)}
            />
          )}
        </div>
      ) : null}
    </div>
  );

  const addCustomerDialog = (
    <AddNewCustomerDialog
      isDialogOpen={customerDialogStates.customerDialogOpen}
      addNewMembershipButton={null}
      closeDialog={() => {
        setCustomerDialogStates({
          selectCustomerDialogOpen: false,
          customerDialogOpen: false,
        });
        setLocations([]);
        resetLocationDialog();
      }}
      handleSave={handleSaveNewCustomer}
      siteKeyCustomizations={siteKeyDoc.customizations}
      resetMembershipStates={() => {}}
    >
      {{
        AddNewCustomerLocationDialog: addLocationWithinAddCustomerDialog,
      }}
    </AddNewCustomerDialog>
  );

  /** For the customer location section. NOT used within the add customer dialog. */
  const addLocationButton = (
    <BaseButtonSecondary
      type="button"
      onClick={() => {
        if (selectedCustomer) {
          setSelectedCustomerLocation(null);
          setStandaloneLocationDialogOpen(true);
        } else {
          addMessage({
            id: createToastMessageID(),
            message: strings.selectBlankFirst("customer"),
            dialog: false,
            type: "error",
          });
        }
      }}
      className="w-full text-primary xs:w-auto"
    >
      <AddCircleIcon fontSize="small" className="mr-2" />
      {strings.buttons.ADD_NEW_LOCATION}
    </BaseButtonSecondary>
  );

  function addLocationWithinAddCustomerDialog(customerType: Customer["type"]) {
    return (
      <div className="space-y-4">
        {/* New Customer Locations */}
        <div className="flex items-center sm:justify-between">
          {/* Add new location button */}
          <BaseButtonPrimary
            type="button"
            onClick={() => setLocationDialogWithinCustomerDialogOpen(true)}
            className="w-full text-primary sm:w-48"
          >
            <AddCircleIcon fontSize="small" className="mr-2" />
            {strings.ADD_NEW_LOCATION}
          </BaseButtonPrimary>
        </div>
        <AddEditCustomerLocationDialog
          customerLocationDoc={selectedCustomerLocation}
          closeDialog={() => {
            setLocationDialogWithinCustomerDialogOpen(false);
            resetLocationDialog();
            setShowManualEntryForm(false);
          }}
          isDialogOpen={locationDialogWithinCustomerDialogOpen}
          geocoderResult={geocoderResult[0]}
          customerType={customerType}
          isSubmitting={isAddressLoading}
          handleSaveCustomerLocationWithAutocomplete={handleSetLocation}
          handleSaveManualCustomerLocation={handleSaveManualCustomerLocation}
          showManualEntryForm={showManualEntryForm}
        >
          {{
            AddressField: addressField,
            AddMembershipsButton: null,
          }}
        </AddEditCustomerLocationDialog>

        {/* Customer Locations */}
        {locations != null
          ? locations.map((location, idx) => {
              return (
                <div key={idx} className="rounded-md border px-4 py-3">
                  {location?.fullAddress}
                </div>
              );
            })
          : null}
      </div>
    );
  }

  async function handleEditCustomerLocation(
    updateLocation: customerLocationWithoutTimestamps,
  ) {
    if (selectedCustomerLocation == null) {
      logger.error("A customer location is not selected");
      return;
    }
    if (!selectedCustomer) {
      logger.error("Customer doc is null");
      return;
    }
    setIsAddressLoading(true);

    const initialValueForCustomerLocation = cloneDeep(selectedCustomerLocation);

    /* check the difference between the initial customer doc and the updated one */
    const diffCustomerLocationValues: DocumentData = diffObjects(
      initialValueForCustomerLocation,
      updateLocation,
    ).diff;

    if (Object.keys(diffCustomerLocationValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    diffCustomerLocationValues["id"] = selectedCustomerLocation.id;
    diffCustomerLocationValues["refPath"] = selectedCustomerLocation?.refPath;

    /* validate values before sending to DB */
    const validateEditCustomerLocation = CustomerLocationManager.parseUpdate(
      diffCustomerLocationValues,
    );
    // @ts-ignore     ALEX FIXME:
    validateEditCustomerLocation["timestampLastModified"] = Timestamp.now();

    try {
      await DbWrite.customerLocations.update(validateEditCustomerLocation);
      setGeocoderResult([]);
      setSelectedCustomerLocation({
        ...initialValueForCustomerLocation,
        fullAddress: updateLocation["fullAddress"],
      });
      logger.debug("Customer Location has been updated successfully");
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(updateLocation.fullAddress!),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(
        `An error occurred during handleEditCustomerLocation`,
        error,
      );
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    } finally {
      setIsAddressLoading(false);
      setStandaloneLocationDialogOpen(false);
      setShowManualEntryForm(false);
    }
  }

  const confirmUpdateCustomerLocation = updateLocationData &&
    selectedCustomerLocation && (
      <ConfirmUpdateCustomerLocation
        handleConfirmUpdatingCL={async () => {
          setUpdateCustomerLocationConfirmationDialogOpen(false);
          handleEditCustomerLocation(updateLocationData);
        }}
        isOpen={updateCustomerLocationConfirmationDialogOpen}
        onClose={() => {
          setUpdateCustomerLocationConfirmationDialogOpen(false);
          setUpdateLocationData(null);
          setShowManualEntryForm(false);
        }}
      />
    );

  /* Checks to see if the address changes AND if other locations reference this
  address. If so, display a confirmation dialog*/
  async function handleConfirmUpdateAddress(
    updateLocation: customerLocationWithoutTimestamps,
  ) {
    if (!selectedCustomerLocation) {
      return;
    }

    if (selectedCustomerLocation.fullAddress !== updateLocation.fullAddress) {
      setUpdateCustomerLocationConfirmationDialogOpen(true);
      setUpdateLocationData(updateLocation);
    } else {
      handleEditCustomerLocation(updateLocation);
    }
  }

  /** For the customer location section. NOT used within the add customer dialog. */
  const addLocationDialog = selectedCustomer && (
    <StandaloneLocationDialog
      customerLocationDoc={selectedCustomerLocation}
      isDialogOpen={standaloneLocationDialogOpen}
      closeDialog={() => {
        setStandaloneLocationDialogOpen(false);
        resetLocationDialog();
        setShowManualEntryForm(false);
        if (customerLocationOptions && customerLocationOptions?.length === 1) {
          setSelectedCustomerLocation(customerLocationOptions[0]);
        }
      }}
      geocoderResult={geocoderResult[0]}
      customerType={selectedCustomer.type}
      handleSaveCustomerLocationWithAutocomplete={handleSaveCustomerLocation}
      handleSaveManualCustomerLocation={(newLocation: TemporaryLocation) => {
        setIsAddressLoading(true);
        newLocation.latitude = siteKeyDoc.latitude ?? null;
        newLocation.longitude = siteKeyDoc.longitude ?? null;
        handleSaveCustomerLocation(newLocation);
      }}
      showManualEntryForm={showManualEntryForm}
      isSubmitting={isAddressLoading}
      handleEditCustomerLocation={(updateLocation) =>
        handleConfirmUpdateAddress(updateLocation)
      }
    >
      {{
        AddressField: addressField,
        ConfirmUpdateCustomerLocation: confirmUpdateCustomerLocation,
      }}
    </StandaloneLocationDialog>
  );

  const templateInUse = membershipTemplateList.find(
    (template) => template.id === membershipTemplateID,
  );

  const workAndTaskTypesSection = workAndTasksIntersectRef.current && (
    <WorkAndTaskTypesSection
      key={selectedWorkType}
      membershipTemplate={templateInUse}
      existingCraftRecord={craftRecord}
      estimateStatus={estimateDoc?.status}
      workAndTaskMap={workAndTasksIntersectRef.current}
      selectedWorkType={selectedWorkType}
      setSelectedWorkType={setSelectedWorkType}
      setSelectedTaskType={setSelectedTaskType}
    />
  );

  const maxDescriptionCharacter = 3000;
  const notesCharacterCounter = notes
    ? maxDescriptionCharacter - notes.length
    : maxDescriptionCharacter;

  const notesSection = (
    <div className="flex flex-col gap-1">
      <BaseInputTextArea
        admin={false}
        id="setDescription"
        rows={4}
        value={notes !== null ? notes : undefined}
        onChange={(event) => setNotes(event.target.value)}
        // added classes to make the border color match the other inputs on this UI
        className="rounded-md border border-gray-600 leading-none"
      />
      <div className={`text-sm text-gray-500`}>
        <span className={`${notesCharacterCounter < 0 && "text-redFail"}`}>
          {notesCharacterCounter}
        </span>
        /{maxDescriptionCharacter}
      </div>
    </div>
  );

  /** to be displayed within the scheduling section */
  const assignedToField = assignedTo_TaskCF && (
    <AssignedToCustomField
      userList={getSelectedUsers(
        // fresh userList for any multiple-uid input
        userList.current
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((obj) => Object.assign({}, obj)),
        assignedToUIDs,
      )}
      customField={assignedTo_TaskCF}
      handleAssignUsers={(uidList: string[]) => setAssignedToUIDs(uidList)}
      resourceFromCalendarUID={
        resourcesMode === "vehicles" ? null : resourceFromCalendarUID
      }
    />
  );

  const schedulingSection = siteKeyDoc && (
    <SchedulingSection
      dateAndTime={scheduleDateTime}
      setDateAndTime={setScheduleDateTime}
      duration={serviceWindowDuration}
      setDuration={setServiceWindowDuration}
      siteKeyDoc={siteKeyDoc}
      handleClickScheduledByPriority={(val) => setScheduledByPriority(val)}
      assignedToField={assignedToField}
      overlappingTasksUserName={overlappingTasksUserName}
    />
  );

  const calendarConfig: CalendarProps = {
    bubble: stiltTaskBubble(siteKeyDoc),
    cellDuration: 60,
    cellHeight: 50,
    // columnWidth: 100,
    // columnWidthSpec: "Fixed" as any,
    headerHeight: 60,
    headerTextWrappingEnabled: false,
    startDate: calendarDateString,
    dynamicEventRendering: "Disabled" as any,
    timeRangeSelectedHandling: "Enabled" as any,
    useEventBoxes: "Never" as any,
    viewType: "Resources" as any,
    onBeforeHeaderRender: (args: DayPilot.CalendarBeforeHeaderRenderArgs) => {
      customizeHeader(
        args,
        [...calendarTasks, ...stiltEvents],
        resourcesMode,
        DateTime.fromJSDate(scheduleDateTime),
      );
    },
    onBeforeEventRender: (args: any) => {
      if (args.data.backColor) {
        args.data.fontColor = getFontColorForBackground(args.data.backColor);
      }
      if (args.data.dotColor) {
        args.data.areas = [
          {
            right: 2,
            top: "calc(100% - 10px)",
            width: 8,
            height: 8,
            symbol: "icons/daypilot.svg#checkmark-2",
            backColor: args.data.dotColor,
            fontColor: "#ffffff",
            padding: 2,
            style: "border-radius: 50%",
          },
        ];
      }
    },
    onTimeRangeSelected: (args: DayPilot.CalendarTimeRangeSelectedArgs) => {
      const start = args.start.toString();
      const end = args.end.toString();
      const timeDiff = getTimeDifferenceInHours({
        startDate: start,
        endDate: end,
      });
      const resourceID = String(args.resource);
      const startDate = new Date(start);
      setScheduleDateTime(startDate);
      setServiceWindowDuration(Math.round(timeDiff));
      if (resourcesMode === "vehicles") {
        setAssignedVehicle(resourceID);
        if (
          dailyVehicleUsersMap[resourceID] != null &&
          dailyVehicleUsersMap[resourceID].length > 0
        ) {
          setAssignedToUIDs(dailyVehicleUsersMap[resourceID]);
        }
      } else {
        setAssignedToUIDs([resourceID]);
        setResourceFromCalendarUID(resourceID);
      }
      const newCalendarEvent: CalendarEvent = {
        id: scheduledAwaitingTaskList.length + 999999,
        start: start,
        end: end,
        resource: resourceID,
        moveDisabled: false,
        resizeDisabled: false,
        text: "Current Task",
        barColor: "#FF00FF",
        tags: {},
      };
      if (alreadyScheduledEvents.length === calendarTasks.length) {
        //this means that all the events in the calendar, are existing tasks so we can add our current task to the table
        setCalendarTasks((prevValues) => [...prevValues, newCalendarEvent]);
      } else {
        //only a single new task is allowed here, so we need to remove the preious one added, to replace it with the new one
        const newList = [...calendarTasks];
        newList.pop();
        newList.push(newCalendarEvent);
        setCalendarTasks(newList);
      }
      args.control.clearSelection();
    },
    onEventResized: (args: DayPilot.CalendarEventResizedArgs) => {
      const start = args.newStart.toString();
      const end = args.newEnd.toString();
      const timeDiff = getTimeDifferenceInHours({
        startDate: start,
        endDate: end,
      });
      const startDate = new Date(start);
      setScheduleDateTime(startDate);
      setServiceWindowDuration(Math.round(timeDiff));
    },
    onEventMoved: (args: DayPilot.CalendarEventMovedArgs) => {
      const start = args.newStart.toString();
      const startDate = new Date(start);
      const newResourceID = String(args.newResource);
      setScheduleDateTime(startDate);
      if (resourcesMode === "vehicles") {
        setAssignedVehicle(newResourceID);
        if (
          dailyVehicleUsersMap[newResourceID] != null &&
          dailyVehicleUsersMap[newResourceID].length > 0
        ) {
          setAssignedToUIDs(dailyVehicleUsersMap[newResourceID]);
        }
      } else if (
        (resourcesMode === "technicians" || resourcesMode == null) &&
        assignedToUIDs
      ) {
        const clonedAssignedToUIDs = cloneDeep(assignedToUIDs);
        const newAssignedToUIDs = clonedAssignedToUIDs.filter(
          (uid) => uid != resourceFromCalendarUID,
        );
        setAssignedToUIDs([...newAssignedToUIDs, newResourceID]);
      }
      setResourceFromCalendarUID(newResourceID);
    },
  };

  const nowISO = DateTime.now().toISO({
    includeOffset: false,
    suppressMilliseconds: true,
  });
  const cellWidth = 100;
  let scrollHour = DateTime.now().hour.valueOf();
  // Go back 1 hour if not already at midnight
  if (scrollHour !== 0) {
    scrollHour = scrollHour - 1;
  }
  const calendarConfigScheduler: SchedulerProps = {
    businessBeginsHour: 6,
    businessEndsHour: 20,
    cellWidth: cellWidth,
    days: 7,
    eventStackingLineHeight: 85,
    eventTextWrappingEnabled: true,
    scale: "Hour" as any,
    scrollX: cellWidth * scrollHour,
    separators: [{ color: "red", location: nowISO }],
    startDate: calendarDateString,
    dynamicEventRendering: "Disabled" as any,
    timeHeaders: [
      { groupBy: "Day" as any, format: "dddd - M/d/yyyy" },
      { groupBy: "Cell" as any },
    ],
    treeEnabled: true,
    treePreventParentUsage: true,
    useEventBoxes: "Never" as any,
    onBeforeCellRender: (args: any) => {
      if (args.cell.isParent) {
        args.cell.properties.backColor = "#dddddd";
      }
    },
    onBeforeEventRender: (args: any) => {
      if (args.data.backColor) {
        args.data.fontColor = getFontColorForBackground(args.data.backColor);
      }
      if (args.data.dotColor) {
        args.data.areas = [
          {
            right: 2,
            top: "calc(100% - 10px)",
            width: 8,
            height: 8,
            symbol: "icons/daypilot.svg#checkmark-2",
            backColor: args.data.dotColor,
            fontColor: "#ffffff",
            padding: 2,
            style: "border-radius: 50%",
          },
        ];
      }
    },
    bubble: stiltTaskBubble(siteKeyDoc),
    onTimeRangeSelected: (args: DayPilot.SchedulerTimeRangeSelectedArgs) => {
      const start = args.start.toString();
      const end = args.end.toString();
      const timeDiff = getTimeDifferenceInHours({
        startDate: start,
        endDate: end,
      });
      const resourceID = String(args.resource);
      const startDate = new Date(start);
      setScheduleDateTime(startDate);
      setServiceWindowDuration(Math.round(timeDiff));
      if (resourcesMode === "vehicles") {
        setAssignedVehicle(resourceID);
        if (
          dailyVehicleUsersMap[resourceID] != null &&
          dailyVehicleUsersMap[resourceID].length > 0
        ) {
          setAssignedToUIDs(dailyVehicleUsersMap[resourceID]);
        }
      } else {
        setAssignedToUIDs([resourceID]);
        setResourceFromCalendarUID(resourceID);
      }
      const newCalendarEvent: CalendarEvent = {
        id: scheduledAwaitingTaskList.length + 999999,
        start: start,
        end: end,
        resource: resourceID,
        moveDisabled: false,
        resizeDisabled: false,
        text: "Current Task",
        barColor: "#FF00FF",
        tags: {},
      };
      if (alreadyScheduledEvents.length === calendarTasks.length) {
        //this means that all the events in the calendar, are existing tasks so we can add our current task to the table
        setCalendarTasks((prevValues) => [...prevValues, newCalendarEvent]);
      } else {
        //only a single new task is allowed here, so we need to remove the preious one added, to replace it with the new one
        const newList = [...calendarTasks];
        newList.pop();
        newList.push(newCalendarEvent);
        setCalendarTasks(newList);
      }
      args.control.clearSelection();
    },
    onEventResized: (args: DayPilot.SchedulerEventResizedArgs) => {
      const start = args.newStart.toString();
      const end = args.newEnd.toString();
      const timeDiff = getTimeDifferenceInHours({
        startDate: start,
        endDate: end,
      });
      const startDate = new Date(start);
      setScheduleDateTime(startDate);
      setServiceWindowDuration(Math.round(timeDiff));
    },
    onEventMoved: (args: DayPilot.SchedulerEventMovedArgs) => {
      const start = args.newStart.toString();
      const startDate = new Date(start);
      const newResourceID = String(args.newResource);
      setScheduleDateTime(startDate);
      if (resourcesMode === "vehicles") {
        setAssignedVehicle(newResourceID);
        if (
          dailyVehicleUsersMap[newResourceID] != null &&
          dailyVehicleUsersMap[newResourceID].length > 0
        ) {
          setAssignedToUIDs(dailyVehicleUsersMap[newResourceID]);
        }
      } else if (
        (resourcesMode === "technicians" || resourcesMode == null) &&
        assignedToUIDs
      ) {
        const clonedAssignedToUIDs = cloneDeep(assignedToUIDs);
        const newAssignedToUIDs = clonedAssignedToUIDs.filter(
          (uid) => uid != resourceFromCalendarUID,
        );
        setAssignedToUIDs([...newAssignedToUIDs, newResourceID]);
      }
      setResourceFromCalendarUID(newResourceID);
    },
  };

  const taskCalendar = showCalendar ? (
    <DayPilotCalendar
      {...calendarConfig}
      columns={filteredResourcesForCalendar}
      events={[...calendarTasks, ...stiltEvents]}
    />
  ) : null;

  const taskScheduler = showCalendar ? (
    <DayPilotScheduler
      {...calendarConfigScheduler}
      resources={resourcesForScheduler}
      events={[...calendarTasks, ...stiltEvents]}
    />
  ) : null;

  const dotElement = getDotColorWIPTask(
    `${selectedTaskType} - ${craftTitle}`,
    selectedCustomerLocation,
    zoneList,
  );

  const showCalendarButton =
    siteKeyDoc.customizations.schedulingDispatchEnabled === true ? (
      <div className="my-4 flex items-center gap-4">
        <BaseButtonSecondary
          type="button"
          className="w-full justify-center uppercase xs:w-fit"
          onClick={() => setShowCalendar(!showCalendar)}
        >
          {!showCalendar
            ? strings.buttons.SHOW_SCHEDULED_TASKS_CALENDAR
            : strings.buttons.HIDE_SCHEDULED_TASKS_CALENDAR}
        </BaseButtonSecondary>
        {dotElement}
      </div>
    ) : null;

  const customFieldsSection = (
    <CustomFieldSection
      userList={userList.current}
      customFields={customFields}
      handleResponses={handleResponses}
      selectedWorkType={selectedWorkType}
      selectedTaskType={selectedTaskType}
      scheduleDateTime={scheduleDateTime}
      scheduledByPriority={scheduledByPriority}
      hideWorkDetailsSection={craftRecord !== null}
      templateNotes={templateNotes}
    />
  );

  const actionButtons = (
    <>
      <BaseButtonSecondary
        type="button"
        className="w-full justify-center uppercase xs:w-fit"
        onClick={() =>
          props.setCreateTaskDialogOpen && props.setCreateTaskDialogOpen(false)
        }
      >
        {strings.buttons.CANCEL}
      </BaseButtonSecondary>

      <BaseButtonPrimary
        type="submit"
        disabled={isSubmitting}
        isBusy={isSubmitting}
        busyText={strings.buttons.BUSY_CREATING}
        className="w-full justify-center uppercase xs:w-fit"
        onClick={handleSaveTask}
      >
        {strings.buttons.SAVE}
      </BaseButtonPrimary>
    </>
  );

  const customerMembershipPills =
    selectedCustomer && membershipsForCustomer.length !== 0 ? (
      <div className="mt-2 flex items-center space-x-2">
        {membershipsForCustomer
          .filter((m) => m.locationID === null)
          .map((membership) => {
            const template = membershipTemplateList.find(
              (template) => template.id === membership.membershipTemplateID,
            );
            return (
              template && (
                <MembershipPill
                  key={membership.id}
                  membership={membership}
                  title={template.title}
                />
              )
            );
          })}
      </div>
    ) : null;

  const locationMembershipPills =
    selectedCustomerLocation &&
    membershipsForCustomer &&
    membershipsForCustomer.length !== 0 ? (
      <div className="mt-2 flex items-center space-x-2">
        {membershipsForCustomer.map((membership) => {
          const template = membershipTemplateList.find(
            (template) => template.id === membership.membershipTemplateID,
          );
          return (
            template && (
              <MembershipPill
                key={membership.id}
                membership={membership}
                title={template.title}
              />
            )
          );
        })}
      </div>
    ) : null;

  const siteKeyLocationSection = userPermissions &&
    siteKeyLocations.length > 1 &&
    !craftRecord &&
    !estimateDoc && (
      <Fragment>
        <div
          className={`mt-10 flex flex-col ${
            siteKeyLocations.length < 5
              ? "items-center sm:grid sm:grid-cols-3"
              : ""
          } `}
        >
          {userPermissions.defaultLocationID ? (
            <Fragment>
              <span className="pr-2 text-2xl capitalize text-primary">
                Default Site Location:
              </span>
              <span>
                {getSiteLocationTitle(
                  siteKeyLocations,
                  userPermissions.defaultLocationID,
                )}
              </span>
            </Fragment>
          ) : (
            <Fragment>
              {siteKeyLocations.length < 5 ? (
                <span className="pr-2 text-2xl capitalize text-primary">
                  Default Site Location:
                </span>
              ) : null}
              <SiteKeyLocationSection
                siteKeyLocationList={siteKeyLocations}
                selectedSiteKeyLocation={selectedSiteKeyLocationID}
                setSelectedSiteKeyLocation={setSelectedSiteKeyLocationID}
              />
            </Fragment>
          )}
        </div>
        <hr className="mb-8 mt-12 block w-full border border-gray-200" />
      </Fragment>
    );

  const removeAllItemsBtn = estimateItemList.length !== 0 && (
    <ButtonColored
      className={`w-full xs:w-auto`}
      kind="danger"
      onClick={() => setEstimateItemList([])}
    >
      {strings.buttons.REMOVE_ALL_ITEMS}
    </ButtonColored>
  );

  const displayTemporaryEstimateItems =
    estimateItemList.length !== 0 &&
    selectedSiteKeyLocationID &&
    estimateItemList.map((estimateItem) => {
      if (estimateItem.toBeEdited) {
        return (
          <div key={estimateItem.id}>
            <EditEstimateItemLine
              PBItemQueryResultList={pbItemsFromTypesense}
              estimateItem={estimateItem}
              initialEstimateItemMembershipData={
                estimateItem.customData?.membershipData ?? null
              }
              handleEditEstimateItem={editEstimateItemFromList}
              onSearch={onFilterTextBoxChanged}
              handleDeleteEstimateItem={removeEstimateItemFromList}
              currency={siteKeyDoc.customizations.accounting?.currency ?? "USD"}
            />
            <hr className="my-8 block w-full border border-gray-200" />
          </div>
        );
      } else {
        return (
          <div key={estimateItem.id}>
            <EstimateItemInline
              estimateItem={estimateItem}
              photos={estimateItemPhotos.filter(
                (p) => p.estimateItemID === estimateItem.id,
              )}
              currency={siteKeyDoc.customizations.accounting?.currency ?? "USD"}
              handleDeleteEstimateItem={removeEstimateItemFromList}
              turnItemToBeEditable={turnItemToBeEditable}
              allowEdit={true}
            />
            <hr className="my-4 block w-full border border-gray-200" />
          </div>
        );
      }
    });

  const addNewEstimateItemLine = selectedSiteKeyLocationID ? (
    <AddNewEstimateItemSelection
      onSearch={onFilterTextBoxChanged}
      PBItemQueryResultList={pbItemsFromTypesense}
      handleAddEstimateItem={handleAddEstimateItem}
      currency={siteKeyDoc.customizations.accounting?.currency ?? "USD"}
    />
  ) : (
    <div className="text-lg font-bold text-red-600">
      Select a location first
    </div>
  );

  const notesField = (
    <BaseInputTextArea
      admin={false}
      rows={4}
      value={estimateNotes !== null ? estimateNotes : undefined}
      onChange={(event) => setEstimateNotes(event.target.value)}
      // added classes to make the border color match the other inputs on this UI
      className="col-span-2 w-full rounded-md border border-gray-600 leading-none"
      placeholder={strings.PLACEHOLDER_VISIBLE_NOTE}
    />
  );

  const internalNotesField = (
    <BaseInputTextArea
      admin={false}
      rows={4}
      value={estimateInternalNotes !== null ? estimateInternalNotes : undefined}
      onChange={(event) => setEstimateInternalNotes(event.target.value)}
      // added classes to make the border color match the other inputs on this UI
      className="col-span-2 w-full rounded-md border border-gray-600 leading-none"
      placeholder={strings.PLACEHOLDER_INTERNAL_NOTE}
    />
  );

  const discountField = (
    <form autoComplete="off" className="flex items-center gap-4 self-end">
      <BaseInputNumber
        type="number"
        step="0.01"
        className={`block w-20 rounded outline-none focus:ring-1 focus:ring-primaryLight sm:text-sm`}
        defaultValue={globalDiscount == null ? "" : globalDiscount}
        onBlur={(event) => {
          const discount = parseFloat(event.target.value);
          if (discount !== 0 && !isNaN(discount)) {
            setGlobalDiscount(discount);
          } else {
            setGlobalDiscount(null);
          }
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            const discount = parseFloat(
              (event.target as HTMLInputElement).value,
            );
            if (discount !== 0 && !isNaN(discount)) {
              setGlobalDiscount(discount);
            } else {
              setGlobalDiscount(null);
            }
          }
        }}
      />
      <span className="text-lg"> %</span>
    </form>
  );

  function handleTransactionType(
    inventoryTransactionType: InventoryTransactionValues,
    currentInventoryTransaction: InventoryTransaction,
  ) {
    const indexOfInventoryTransaction = pendingInventoryTransactions.findIndex(
      (inventoryTransaction) =>
        inventoryTransaction.inventoryObjectID ===
          currentInventoryTransaction.inventoryObjectID &&
        inventoryTransaction.inventoryLocationID ===
          currentInventoryTransaction.inventoryLocationID,
    );

    if (indexOfInventoryTransaction >= 0) {
      setPendingInventoryTransactions((oldInventoryTransactionList) => {
        oldInventoryTransactionList[indexOfInventoryTransaction].type =
          inventoryTransactionType;
        return [...oldInventoryTransactionList];
      });
    }
  }

  const inventoryTransactionCardsSection = pendingInventoryTransactions.map(
    (inventoryTransaction, idx) => {
      const inventoryObject = inventoryObjects.find(
        (inventoryObject) =>
          inventoryObject.id === inventoryTransaction.inventoryObjectID,
      );
      const currentLocation = inventoryLocations.find(
        (inventoryLocation) =>
          inventoryLocation.id === inventoryTransaction.inventoryLocationID,
      );
      return (
        <div key={idx} className="grid grid-cols-2 rounded-md border px-4 py-3">
          <div className="flex flex-col">
            <span className="text-greenPass">
              {inventoryObject?.title ?? "--"}
            </span>
            {currentLocation ? (
              <span className="text-sm text-cyan-500">
                Location: {currentLocation?.title}
              </span>
            ) : null}
          </div>
          <div className="justify-self-end">
            <div className="flex flex-row items-center justify-end space-x-4">
              <span>Qty: {inventoryTransaction.value}</span>
              <DropdownInventoryTransactionTypes
                initialInventoryTransactionType={inventoryTransaction.type}
                inventoryTransactionTypes={[
                  ...Object.values(OInventoryTransactionTypes),
                ]}
                onSelectionInventoryTransactionType={(
                  inventoryTransactionType,
                ) =>
                  handleTransactionType(
                    inventoryTransactionType,
                    inventoryTransaction,
                  )
                }
              />
            </div>
          </div>
        </div>
      );
    },
  );

  const inventoryTransactions = siteKeyDoc?.customizations?.inventoryEnabled ===
    true && (
    <Accordion elevation={0} expanded={inventorySectionIsExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className="transition-colors"
        onClick={() => {
          setInventorySectionIsExpanded(!inventorySectionIsExpanded);
        }}
      >
        <div className="flex w-full flex-row items-center justify-between">
          <CreateTaskSecondaryHeading text={strings.INVENTORY_TRANSACTION} />
          {pendingInventoryTransactions.length > 0 && (
            <span className="mx-4 pb-2 text-blue-600">
              {`${pendingInventoryTransactions.length} pending inventory transaction${pendingInventoryTransactions.length > 1 ? "s" : ""} from this estimate`}
            </span>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Fragment>{inventoryTransactionCardsSection}</Fragment>
      </AccordionDetails>
    </Accordion>
  );

  const membershipDiscounts = (customerDiscount.length !== 0 ||
    selectedLocationDiscount.length !== 0) && (
    <Fragment>
      <div className="mb-4 mt-8 flex flex-col gap-4 sm:grid sm:grid-cols-3">
        <div className="pr-2 text-2xl capitalize text-primary">
          {strings.MEMBERSHIPS}
        </div>
        <div className="col-span-2 flex flex-col space-y-8 text-lg">
          {/* CUSTOMER */}
          {customerDiscount.length !== 0 ? (
            <div>
              <div className="pb-4 text-xl font-bold text-primary">
                {strings.CUSTOMER} Discounts
              </div>
              <div className="grid grid-cols-2 rounded-md border p-4">
                {customerDiscount.map((template, index) => (
                  <div
                    key={`customer.${index}`}
                    className="flex flex-col gap-4"
                  >
                    <BaseInputCheckbox
                      label={`${template.discount}% - ${template.title}`}
                      value={template.discount}
                      onChange={(event) => {
                        if (event.target.checked === true) {
                          setCustomerDiscount((oldCustomerDiscount) => {
                            oldCustomerDiscount[index].value = true;
                            return [...oldCustomerDiscount];
                          });
                        } else {
                          setCustomerDiscount((oldCustomerDiscount) => {
                            oldCustomerDiscount[index].value = false;
                            return [...oldCustomerDiscount];
                          });
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {/* SELECTED CUSTOMER LOCATION */}
          {selectedLocationDiscount.length !== 0 ? (
            <div>
              <div className="pb-4 text-xl font-bold text-primary">
                {strings.CUSTOMER_LOCATION} Discounts
              </div>
              <div className="grid grid-cols-2 rounded-md border p-4">
                {selectedLocationDiscount.map((template, index) => (
                  <div
                    key={`location.${index}`}
                    className="flex flex-col gap-4"
                  >
                    <BaseInputCheckbox
                      label={`${template.discount}% - ${template.title}`}
                      value={template.discount}
                      onChange={(event) => {
                        if (event.target.checked === true) {
                          setSelectedLocationDiscount(
                            (oldSelectedLocationDiscount) => {
                              oldSelectedLocationDiscount[index].value = true;
                              return [...oldSelectedLocationDiscount];
                            },
                          );
                        } else {
                          setSelectedLocationDiscount(
                            (oldSelectedLocationDiscount) => {
                              oldSelectedLocationDiscount[index].value = false;
                              return [...oldSelectedLocationDiscount];
                            },
                          );
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <hr className="mb-8 mt-12 block w-full border border-gray-200" />
    </Fragment>
  );

  const createEstimateDetails = (
    <CreateEstimateDetails
      selectedCustomerLocation={selectedCustomerLocation}
      membershipDiscountTotal={totalSelectedDiscounts}
      totals={getEstimateTotals(
        estimateItemList,
        selectedCustomer,
        selectedCustomerLocation,
        globalDiscount ?? 0,
        siteKeyDoc.customizations.accounting?.currency ?? "USD",
      )}
      commissions={
        siteKeyDoc?.customizations?.commissions
          ? EstimateManager.getJobCommissionsForDisplay(
              estimateItemList,
              estimateItemList,
              priceBookItemsWithCommissionOverrides,
              siteKeyDoc,
              selectedCustomer?.type ?? "residential",
              globalDiscount ?? 0,
              responses_TSD.allItemsUpsellCommissions === true,
              responses_TSD.sameDayJob === true,
              commissionAdjustments,
            )
          : null
      }
      removeAllItemsButton={removeAllItemsBtn}
      displaySelectedEstimateItems={displayTemporaryEstimateItems}
      addNewEstimateItemLine={addNewEstimateItemLine}
    >
      {{
        NotesField: notesField,
        InternalNotesField: internalNotesField,
        DiscountField: discountField,
        InventoryTransactions: inventoryTransactions,
        MembershipDiscounts: membershipDiscounts,
      }}
    </CreateEstimateDetails>
  );

  const estimateListTable = (
    <CustomerEstimateListTable
      estimateTableData={estimateList}
      customerLocations={selectedCustomerLocations}
      estimateItemList={existingEstimateItems}
      goToViewEstimate={() => {}}
      renderIconCell={renderEstimateIconCell}
      invoiceList={workRecordInvoiceList}
      currency={siteKeyDoc?.customizations.accounting?.currency ?? "USD"}
    />
  );

  const confirmationReminderToggles = (siteKeyDoc.customizations
    .sendJobBookingConfirmationToCustomers ||
    siteKeyDoc.customizations.sendTaskReminderToCustomers) && (
    <div className="mb-4 flex gap-4">
      {siteKeyDoc.customizations.sendJobBookingConfirmationToCustomers && (
        <StyledSwitchGroup
          readableName={strings.SEND_BOOKING_CONFIRMATION_EMAIL}
          tooltipText={strings.BOOKING_CONFIRMATION_EMAIL_HELP_TEXT}
          onBlur={() => {}}
          onChange={() =>
            setSendBookingConfirmationEmail(!sendBookingConfirmationEmail)
          }
          checked={sendBookingConfirmationEmail}
          id="sendBookingConfirmationEmail"
          name={"sendBookingConfirmationEmail"}
        />
      )}
      {siteKeyDoc.customizations.sendTaskReminderToCustomers &&
        siteKeyDoc.customizations.sendJobBookingConfirmationToCustomers && (
          <div className="border-separate border"></div>
        )}
      {siteKeyDoc.customizations.sendTaskReminderToCustomers && (
        <StyledSwitchGroup
          tooltipText={strings.SEND_24H_REMINDER_EMAIL_HELP_TEXT}
          readableName={strings.SEND_24H_REMINDER_EMAIL}
          onBlur={() => {}}
          onChange={() => setSendJobReminderEmail(!sendJobReminderEmail)}
          checked={sendJobReminderEmail}
          id="sendJobReminderEmail"
          name={"sendJobReminderEmail"}
        />
      )}
    </div>
  );
  // #endregion Components

  // #region MatchCustomerAlertComponents

  // Placing these elements here after the loading check to reduce the amount
  // of needed type checking.
  /**
   * Either the info or warning style to prompt the user with an action.
   */
  const MatchCustomerAlertPrompt = (
    <AlertV2
      variant={callAlertStyle}
      title={alertProps.title}
      message={
        selectedCustomer
          ? alertProps.message
          : "Please select or create a customer first"
      }
      onDismiss={clearCurrentCall}
      alignActions={"left"}
      actions={
        <>
          {callUpdate.isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {selectedCustomer ? (
                <AlertV2Button
                  variant={callAlertStyle}
                  onClick={() => callUpdate.mutateAsync()}
                >
                  {alertProps.actionButtonText}
                </AlertV2Button>
              ) : null}
              <AlertV2Button
                variant={callAlertStyle}
                onClick={clearCurrentCall}
              >
                Dismiss
              </AlertV2Button>
            </>
          )}
        </>
      }
    />
  );

  /**
   * A success alert to show the user that the customer has been matched to the call.
   */
  const MatchCustomerAlertSuccess = (
    <AlertV2
      variant={"success"}
      title={"Successfully updated customer for call"}
      onDismiss={() => {
        callUpdate.reset();
        clearCurrentCall();
      }}
    />
  );

  /**
   * An error alert to show the user that the customer could not be matched to the call.
   */
  const ErrorAlert = (
    <AlertV2
      variant={"error"}
      title={"Could not update call document."}
      message={
        "You can dismiss this alert and reselect the call to try again. If the issue persists, please contact support."
      }
      onDismiss={() => {
        callUpdate.reset();
        clearCurrentCall();
      }}
    />
  );

  // #endregion MatchCustomerAlertComponents

  async function handleSetUpMembershipRenewals(membershipIDs: string[]) {
    if (!siteKeyDoc) return;
    if (!selectedSiteKeyLocationID) return;

    if (createEstimate === false) {
      setCreateEstimate(true);
      setEstimateSectionIsExpanded(true);
    }
    // For each membershipID, find the membership template and add the renewal pricebook item to the estimate
    for (const membershipID1 of membershipIDs) {
      const membership = membershipsForCustomer.find(
        (m) => m.id === membershipID1,
      );
      if (membership) {
        const template = membershipTemplateList.find(
          (t) => t.id === membership.membershipTemplateID,
        );
        if (template && template.renewalPriceBookItemID) {
          const renewalItem = await DbRead.priceBookItems.get(
            siteKeyDoc.id,
            template.renewalPriceBookItemID,
          );
          if (renewalItem) {
            const newEstimateItem: TemporaryEstimateItem = {
              priceBookItemID: renewalItem.id,
              title: renewalItem.title,
              description: renewalItem.description,
              units: renewalItem.units,
              unitPrice: renewalItem.unitPrice,
              cost: renewalItem.cost,
              locationID: selectedSiteKeyLocationID,
              discountableForMemberships:
                renewalItem.discountableForMemberships,
              type: renewalItem.type ?? null,
              tags: renewalItem.tags,
              customData: renewalItem.customData,
              taxable: renewalItem.taxable,
              editable: renewalItem.editable,
              discountable: renewalItem.discountable,
              quantity: 1,
              discount: 0,
              deleted: false,
              toBeEdited: renewalItem.editable,
            };
            const membershipData: EstimateItemMembershipData = {
              membershipTemplateID: membership.membershipTemplateID,
              isSameTask: false,
              notes: null,
              selectedStartDate: null,
            };
            newEstimateItem.customData = {
              ...newEstimateItem.customData,
              membershipData,
            };
            newEstimateItem.id = DbRead.randomDocID.get();
            setEstimateItemList((prevList) => [...prevList, newEstimateItem]);
          }
        }
      }
    }
  }

  // #region membershipSection
  const membershipSection = siteKeyDoc?.customizations?.membershipsEnabled ===
    true && (
    // Allow user to checkbox multiple memberships that might apply to this task
    <div className="space-y-4">
      <MembershipTaskLinkingSection
        membershipsForCustomer={membershipsForCustomer.filter(
          (m) => m.customerLocationID === selectedCustomerLocation?.id,
        )}
        selectedMembershipIDs={membershipIDsForTask}
        setMembershipIDs={setMembershipIDsForTask}
        selectedMembershipIDsForRenewal={[]}
        setMembershipIDsForRenewal={(membershipIDs) => {
          if (!selectedSiteKeyLocationID) {
            addMessage({
              id: createToastMessageID(),
              message: "Please select a location first.",
              dialog: false,
              type: "warning",
            });
            return;
          }
          handleSetUpMembershipRenewals(membershipIDs);
        }}
      />
    </div>
  );
  // #endregion membershipSection

  // #region assetsEquipmentSection
  const assetsEquipmentSection = siteKeyDoc?.customizations?.assetsEnabled ===
    true && (
    // Allow user to checkbox multiple memberships that might apply to this task
    <div className="space-y-4">
      <AssetEquipmentListCheckboxes
        assetsForCustomer={assetsForCustomer}
        assetIDs={assetIDs}
        setAssetIDs={setAssetIDs}
      />
    </div>
  );

  // #endregion assetsEquipmentSection

  async function openEditExistingCustomerLocation(
    location: ExistingCustomerLocation,
  ) {
    setSelectedCustomerLocation(location);

    if (location.fullAddress != null) {
      setAddress(location.fullAddress);
    }

    setStandaloneLocationDialogOpen(true);
  }

  const customerLocationSection = (
    <CustomerLocationSection
      allowSelection={craftRecord === null}
      selectedCustomer={selectedCustomer}
      customerLocationOptions={customerLocationOptions}
      selectedCustomerLocation={selectedCustomerLocation}
      setSelectedCustomerLocation={setSelectedCustomerLocation}
      membershipTemplateList={membershipTemplateList}
      customerLocationMemberships={membershipsForCustomer}
      openEditExistingCustomerLocation={openEditExistingCustomerLocation}
    />
  );

  const estimatesSection = (
    <Fragment>
      <div className="-mx-4 mb-4">
        <Accordion elevation={0} expanded={estimateSectionIsExpanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="transition-colors"
            onClick={() => {
              setEstimateSectionIsExpanded(!estimateSectionIsExpanded);
            }}
          >
            <div className="flex w-full flex-row justify-between">
              <CreateTaskSecondaryHeading text={strings.ADD_ESTIMATE} />
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {/*  if there are estimates related to this craftRecordID, show buttons
                  to choose between selecting an existing estimates or create a new one  */}
            {createEstimate === false &&
              showExistingEstimates === false &&
              estimateList.length > 0 && (
                <div className="flex gap-4">
                  <BaseButtonSecondary
                    onClick={() => {
                      setShowExistingEstimates(true);
                      setCreateEstimate(false);
                      setEstimateItemList([]);
                      setEstimateNotes("");
                    }}
                  >
                    Use existing estimate
                  </BaseButtonSecondary>
                  <BaseButtonSecondary
                    onClick={() => {
                      setCreateEstimate(true);
                      setSelectedEstimate(null);
                    }}
                  >
                    Add New estimate
                  </BaseButtonSecondary>
                </div>
              )}
            {/* if create estimate is choosed */}
            {createEstimate && (
              <div className="flex flex-col">
                <ButtonColored
                  className="mx-4 w-14 self-end"
                  busyText={strings.buttons.BUSY_DELETING}
                  kind="danger"
                  onClick={() => {
                    setCreateEstimate(false);
                    setEstimateItemList([]);
                    setEstimateNotes("");
                  }}
                >
                  {strings.buttons.CLEAR}
                </ButtonColored>
                {createEstimateDetails}
              </div>
            )}
            {/* if use existing estimate is choosed */}
            {showExistingEstimates === true && (
              <div className="flex flex-col">
                <div
                  className={`flex ${
                    selectedEstimate
                      ? " items-center justify-between"
                      : "justify-end"
                  }`}
                >
                  {selectedEstimate && (
                    <div className="rounded-md bg-blue-100 px-2 py-1">
                      Estimate created on{" "}
                      {convertToReadableTimestamp(
                        selectedEstimate.timestampCreated,
                      )}{" "}
                      will be moved to this new task
                    </div>
                  )}
                  <ButtonColored
                    className={`mx-4 w-14`}
                    busyText={strings.buttons.BUSY_DELETING}
                    kind="danger"
                    onClick={() => {
                      setShowExistingEstimates(false);
                      setSelectedEstimate(null);
                    }}
                  >
                    {strings.buttons.CLEAR}
                  </ButtonColored>
                </div>
                {estimateListTable}
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
      <hr className="mb-6 mt-4 block w-full border border-gray-200" />
    </Fragment>
  );

  return (
    <>
      <Wrapper apiKey={apiKey ?? ""} libraries={["places"]}>
        {/* ALERT AREA */}
        {callUpdate.isSuccess && (
          <div className="mx-auto pb-2 lg:max-w-5xl">
            {MatchCustomerAlertSuccess}
          </div>
        )}
        {callUpdate.isError && (
          <div className="mx-auto pb-2 lg:max-w-5xl">{ErrorAlert}</div>
        )}
        {currentCall ? (
          <div className="mx-auto pb-2 lg:max-w-5xl">
            {MatchCustomerAlertPrompt}
          </div>
        ) : null}

        <CreateTaskForCustomerPage
          estimatesEnabled={
            siteKeyDoc?.customizations.estimatesEnabled ?? false
          }
          membershipsEnabled={
            siteKeyDoc?.customizations.membershipsEnabled ?? false
          }
          showEstimateSectionFirst={
            siteKeyDoc?.customizations.showEstimateSectionFirst ?? false
          }
          assetsEnabled={siteKeyDoc?.customizations.assetsEnabled ?? false}
          existingCraftRecord={craftRecord}
          addCustomerButton={addCustomerButton}
          addLocationButton={addLocationButton}
          selectCustomerButton={selectCustomerButton}
          displayCustomerData={displayCustomerData}
          workAndTaskTypesSection={workAndTaskTypesSection}
          notesSection={notesSection}
          schedulingSection={schedulingSection}
          customFieldsSection={customFieldsSection}
          actionButtons={actionButtons}
          taskCalendar={
            tableOrientationIsHorizontal ? taskScheduler : taskCalendar
          }
          showCalendarButton={showCalendarButton}
          customerMembershipPills={customerMembershipPills}
          locationMembershipPills={locationMembershipPills}
          siteKeyLocationSection={siteKeyLocationSection}
          isCreatingFromEstimate={state?.estimateDoc != null}
          setCreateTaskDialogOpen={props.setCreateTaskDialogOpen}
          confirmationReminderToggles={confirmationReminderToggles}
          membershipSection={membershipSection}
          assetsSection={assetsEquipmentSection}
          customerLocationSection={customerLocationSection}
          estimatesSection={estimatesSection}
        />
      </Wrapper>
      {/*{customerMembershipDialog}*/}
      {/*{locationMembershipDialog}*/}
      {addCustomerDialog}
      {editCustomerDialog}
      {addLocationDialog}
      {selectCustomerDialog}
      {editBillingInfoDialog}
      {addCustomerContactDialog}
    </>
  );
}

// SECTION: helpers
/**
 * Splits the given custom fields into two lists, one for workRecords and one for
 * tasks. Returns only those that should be shown to the user, which excludes
 * non-editable fields and task fields that were answered in the scheduling section.
 *
 * Scheduling section task fields are: scheduledServiceWindowStart, scheduledServiceWindowEnd,
 * and assignedTo. (assignedTo - if applicable to the current work/task type combo)
 *
 * Also excludes custom fields that do not apply to the current taskStatus.
 *
 */
// Tested
export function getCustomFieldsToDisplay(args: {
  customFields: ExistingCustomField[];
  scheduledByPriority: ScheduleByPriorityType | null;
  scheduleDate: Date;
}): {
  workRecordCFs: ExistingCustomField[];
  taskCFs: ExistingCustomField[];
} {
  const taskStatus = getTaskStatusForApprovedTask(
    args.scheduledByPriority,
    convertJSDateToFSTimestamp(args.scheduleDate),
  );

  const workRecordCFs: ExistingCustomField[] = args.customFields.flatMap(
    (customField) => {
      // Don't show fields that aren't editable
      if (customField.editable !== true) {
        return [];
      }
      // Don't show fields that have hideOnCraftRecordCreation
      if (customField.hideOnCraftRecordCreation === true) {
        return [];
      }

      if (customField.craftRecordOrTask === "craftRecord") {
        return customField;
      } else {
        return [];
      }
    },
  );

  /** tsd = task specific details */
  const tsdToExclude = [
    "scheduledServiceWindowStart",
    "scheduledServiceWindowEnd",
    "assignedTo",
  ];
  // scheduledServiceWindowStart and scheduledServiceWindowEnd values were hardcoded
  // in the scheduling section. Don't want to display these again.

  const taskCFs: ExistingCustomField[] = args.customFields.flatMap(
    (customField) => {
      // Don't show fields that aren't editable
      if (customField.editable !== true) {
        return [];
      }
      if (tsdToExclude.includes(customField.id)) {
        return [];
      }
      if (
        !customField.onTaskStatus?.includes(taskStatus) &&
        // we always want to display taskSpecificNotes when creating a task.
        // taskSpecificNotes is not actually a custom field.
        customField.id !== "taskSpecificNotes"
      ) {
        return [];
      }

      if (customField.craftRecordOrTask === "task") {
        return customField;
      } else {
        return [];
      }
    },
  );

  workRecordCFs.sort((a, b) => a.title.localeCompare(b.title));
  taskCFs.sort((a, b) => a.title.localeCompare(b.title));

  return { workRecordCFs, taskCFs };
}

/**
 * Returns the titles of custom fields that are required but have not been answered.
 *
 * Tested ✅ (could use more tests tho)
 */
export function checkCFsMissingRequiredResponse(args: {
  base: ExistingCustomField[];
  originals: Record<string, Json | Date>;
  responses: CustomFieldResponse;
}): string[] {
  const titles: string[] = [];
  args.base.forEach((cf) => {
    Object.entries(args.originals).forEach(([originalKey, originalValue]) => {
      const isHoursCF = `${cf.id}_hoursValue` === originalKey;
      const isMinutesCF = `${cf.id}_minutesValue` === originalKey;

      if (
        cf.required === true &&
        // ⌄ this is probably the most important line of the entire ƒn
        (cf.id === originalKey || isHoursCF || isMinutesCF)
      ) {
        const type = cf.fieldType;
        const responseValue = args.responses[originalKey];

        // if responseValue is undefined, we've likely received
        // an empty object for args.responses. if we've gotten here,
        // it means they're missing a required response.
        if (responseValue === undefined) {
          titles.push(cf.title);
        }

        switch (type) {
          case "string":
          case "number":
          case "timestamp":
          case "uid":
          case "currency":
          case "hours-minutes":
          case "string-textarea": {
            if (originalValue === responseValue) {
              titles.push(cf.title);
            }
            break;
          }
          case "bool": {
            // there's already a response for boolean types - always
            break;
          }
          case "selection": {
            // if there's only one selection option, we're displaying a checkbox.
            // don't force the user to toggle the checkbox.
            if (
              originalValue === responseValue &&
              Object.keys(cf.selectionOptions).length > 1
            ) {
              titles.push(cf.title);
            }
            break;
          }
          case "multiple-uid":
          case "string-array": {
            // if there's only one defaultValue, the UI will be
            // displaying it. let the user proceed
            if (
              originalValue === responseValue &&
              cf.defaultValue !== null &&
              cf.defaultValue.length > 1
            ) {
              titles.push(cf.title);
            }
            break;
          }
          default:
            const _exhaustivenessCheck: never = type;
            return _exhaustivenessCheck;
        }
      }
      return undefined;
    });
  });

  // need to use a set in case they're missing an hours-minutes field --
  // otherwise it would add that title twice
  const uniqueTitles = [...new Set(titles)];
  return uniqueTitles;
}

/**
 * @returns timestampScheduled, scheduledServiceWindowStart, and
 * scheduledServiceWindowEnd, ready to be sent to the DB.
 * service window fields are returned as ISO datetime strings because we're sending
 * them via cloud functions.
 *
 * Tested ✅
 */
export function getServiceWindowAndScheduledTS(args: {
  scheduledByPriority: ScheduleByPriorityType | null;
  scheduledDateTime: Date;
  serviceWindowDuration: number;
}): {
  scheduledServiceWindowStart: string | null;
  scheduledServiceWindowEnd: string | null;
  timestampScheduled: Timestamp | null;
} {
  let scheduledServiceWindowStart: string | null,
    scheduledServiceWindowEnd: string | null,
    timestampScheduled: Timestamp | null;

  switch (args.scheduledByPriority) {
    case null: {
      const isoStr = args.scheduledDateTime.toISOString();
      timestampScheduled = convertISOToFSTimestamp(isoStr);

      scheduledServiceWindowStart = args.scheduledDateTime.toISOString();
      scheduledServiceWindowEnd = getScheduledServiceWindowEnd(
        isoStr,
        args.serviceWindowDuration,
      );
      break;
    }

    case "Urgent": {
      timestampScheduled = Timestamp.now();
      scheduledServiceWindowStart = Timestamp.now().toDate().toISOString();
      scheduledServiceWindowEnd = getScheduledServiceWindowEnd(
        scheduledServiceWindowStart,
        2,
      );
      break;
    }

    case strings.AWAITING_SCHEDULE:
    case strings.NEXT_OPPORTUNITY:
    case strings.AWAITING_PARTS:
    case strings.AWAITING_ESTIMATE:
    case strings.AWAITING_APPROVAL: {
      timestampScheduled = null;
      scheduledServiceWindowStart = null;
      scheduledServiceWindowEnd = null;
      break;
    }

    default: {
      const _exhaustivenessCheck: never = args.scheduledByPriority;
      return _exhaustivenessCheck;
    }
  }

  return {
    scheduledServiceWindowStart,
    scheduledServiceWindowEnd,
    timestampScheduled,
  };
}

interface IPruneDynamicDetails {
  workRecOriginals: Record<string, Json | Date>;
  taskOriginals: Record<string, Json | Date>;
  responses_CD: CustomFieldResponse;
  responses_TSD: CustomFieldResponse;
}

/**
 * Drops custom fields that the user hasn't answered.
 */
function pruneDynamicDetails(args: IPruneDynamicDetails): {
  prunedCD: Record<string, any>;
  prunedTSD: Record<string, any>;
} {
  const prunedCD = diffObjects(args.workRecOriginals, args.responses_CD).diff;
  const prunedTSD = diffObjects(args.taskOriginals, args.responses_TSD).diff;

  return { prunedCD, prunedTSD };
}

// Calculate if a task has any overlap with another task using the taskSpecificDetails.serviceWindowStart and taskSpecificDetails.serviceWindowEnd
function hasOverlapWithTask(
  task: ExistingTask,
  start: Timestamp,
  end: Timestamp,
): boolean {
  const taskStart = task.taskSpecificDetails
    ?.scheduledServiceWindowStart as string;
  const taskEnd = task.taskSpecificDetails?.scheduledServiceWindowEnd as string;

  if (taskStart && taskEnd && start && end) {
    const timestampStart = convertObjectToFSTimestamp(taskStart);
    const timestampEnd = convertObjectToFSTimestamp(taskEnd);

    return (
      (timestampStart < end && timestampEnd > start) ||
      (start < timestampEnd && end > timestampStart)
    );
  }

  return false;
}

// Calculate if a CalendarEvent has any overlap with another CalendarEvent using the start & end props
function hasOverlapWithCalendarEvent(
  calendarEvent: StiltCalendarEvent,
  start: Timestamp,
  end: Timestamp,
): boolean {
  const eventStart = calendarEvent.start;
  const eventEnd = calendarEvent.end;

  if (eventStart && eventEnd && start && end) {
    return (
      (eventStart <= end && eventEnd >= start) ||
      (start <= eventEnd && end >= eventStart)
    );
  }

  return false;
}

function filterResourcesByTaskType(
  resourcesForCalendar: CalendarColumn[],
  selectedTaskType: string | null,
  resourcesMode: string,
  selectedSiteKeyLocationID: string | null,
): CalendarColumn[] {
  if (selectedTaskType == null || resourcesMode !== "vehicles")
    return resourcesForCalendar;

  const convertedTaskType = getTaskTypeValueFromReadableStr(selectedTaskType);

  const filteredResources: CalendarColumn[] = [];
  resourcesForCalendar.forEach((resource) => {
    if (resource.name === "Unassigned") {
      filteredResources.push(resource);
    }
    if (
      selectedSiteKeyLocationID &&
      resource.locationID === selectedSiteKeyLocationID
    ) {
      if (
        convertedTaskType &&
        resource.taskTypes &&
        (resource.taskTypes.includes(convertedTaskType) ||
          resource.taskTypes.length === 0)
      ) {
        filteredResources.push(resource);
      }
    }
  });

  return filteredResources;
}
