//Libs
import { z } from "zod";
import { useEffect, useMemo, useRef, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Timestamp } from "firebase/firestore";
import isEqual from "lodash/isEqual";

//Local
import StyledMessage from "../StyledMessage";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import * as strings from "../../strings";
import BaseModal from "../BaseModal";
import { ExistingCustomer } from "../../models/customer";
import { ExistingCustomerLocation } from "../../models/customer-location";
import { CustomerDetails } from "../estimates/CustomerDetails";
import { CustomerLocationSection } from "../customers/CreateTask";
import { DbRead, DbWrite } from "../../database";
import {
  ExistingMembership,
  getReadableMembershipStatus,
  isMembershipStatus,
  MembershipManager,
  MembershipStatus,
  membershipStatus,
} from "../../models/membership";
import { useAuthStore } from "../../store/firebase-auth";
import { ExistingMembershipTemplate } from "../../models/membership-template";
import BaseInputTextArea from "../BaseInputTextArea";
import AssetEquipmentListCheckboxes from "./AssetEquipmentListCheckboxes";
import { ExistingAsset } from "../../models/asset";
import { logger } from "../../logging";
import StyledSwitchGroup from "../StyledSwitchGroup";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import BaseInputSelect from "../BaseInputSelect";
import BaseInputDate from "../BaseInputDate";
import { diffObjects } from "../../assets/js/object-diff";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import { StyledTooltip } from "../StyledTooltip";

interface Props {
  isDialogOpen: boolean;
  closeDialog: () => void;

  siteKey: string;
  assetsEnabled: boolean;
  customer: ExistingCustomer;
  customerLocation: ExistingCustomerLocation | null;
  membership: ExistingMembership;
  template: ExistingMembershipTemplate;
}

const EditFormSchema = z.object({
  notes: z.string().max(1000).nullable(),
  automaticallyGenerateTasks: z.boolean(),
  automaticallySendReceipt: z.boolean(),
  membershipEndDate: z.instanceof(Timestamp).nullable(),
  lastCompletedTaskDate: z.instanceof(Timestamp).nullable(),
  nextScheduledTaskDate: z.instanceof(Timestamp).nullable(),
  lastPaymentDate: z.instanceof(Timestamp).nullable(),
  nextInvoiceDate: z.instanceof(Timestamp).nullable(),
  lastPaymentAmount: z.number().nullable(),
  status: z.enum(membershipStatus),
  // these two SHOULDN'T be null, but they're nullable on the model, so we need to account for that
  membershipStartDate: z
    .instanceof(Timestamp)
    .nullable()
    .refine((val) => (val === null ? false : true), {
      message: "Select a membership start date",
    }),
  customerLocationID: z
    .string()
    .nullable()
    .refine((val) => (val === null ? false : true), {
      message: "Select a customer location",
    }),
});
type EditFormState = z.infer<typeof EditFormSchema>;

export default function EditMembershipDialog(props: Props) {
  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  const firebaseUser = useAuthStore((state) => state.firebaseUser);

  const [siteKeyLocationList, isLoadingSKLocs] = useSiteKeyLocationsStore(
    (state) => [state.siteKeyLocationList, state.loading],
  );
  const automatedPaymentsTooltipRef = useRef<HTMLDivElement>(null);

  const [cusLocOptions, setCusLocOptions] = useState<
    ExistingCustomerLocation[]
  >([]);
  const [selectedCusLoc, setSelectedCusLoc] =
    useState<ExistingCustomerLocation | null>(props.customerLocation);

  const [assetOptions, setAssetOptions] = useState<ExistingAsset[]>([]);
  const [assetIDs, setAssetIDs] = useState<string[] | undefined>(
    props.membership.assetIDs ?? [],
  );

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedSiteKeyLoc, setSelectedSiteKeyLoc] =
    useState<ExistingSiteKeyLocation | null>(null);

  const defaultValues = useMemo(
    (): EditFormState => ({
      lastCompletedTaskDate: props.membership.lastCompletedTaskDate,
      lastPaymentAmount: props.membership.lastPaymentAmount,
      lastPaymentDate: props.membership.lastPaymentDate,
      membershipEndDate: props.membership.membershipEndDate,
      membershipStartDate: props.membership.membershipStartDate,
      nextInvoiceDate: props.membership.nextInvoiceDate,
      nextScheduledTaskDate: props.membership.nextScheduledTaskDate,
      notes: props.membership.notes,
      status: isMembershipStatus(props.membership.status)
        ? props.membership.status
        : MembershipStatus.Draft,
      automaticallyGenerateTasks:
        props.membership.automaticallyGenerateTasks ?? false,
      automaticallySendReceipt:
        props.membership.automaticallySendReceipt ?? false,
      customerLocationID: props.membership.customerLocationID,
    }),
    [props.membership],
  );

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
    setValue,
  } = useForm<EditFormState>({
    defaultValues: defaultValues,
    resolver: zodResolver(EditFormSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (isLoadingSKLocs) return;
    setSelectedSiteKeyLoc(
      siteKeyLocationList.find(
        (loc) => loc.id === props.membership.locationID,
      ) ?? null,
    );
  }, [isLoadingSKLocs, props.membership.locationID, siteKeyLocationList]);

  useEffect(() => {
    function getAssetsForCustomerLocation() {
      if (!selectedCusLoc) return undefined;
      const unsubscribeFn = DbRead.assets.subscribeByCustomerLocationID({
        siteKey: props.siteKey,
        customerLocationID: selectedCusLoc.id,
        onChange: setAssetOptions,
        onError: logger.error,
      });
      return unsubscribeFn;
    }
    const unsubscribe = getAssetsForCustomerLocation();
    return () => unsubscribe && unsubscribe();
  }, [props.siteKey, selectedCusLoc]);

  useEffect(() => {
    function getCusLocOptions() {
      const unsubscribeFn = DbRead.customerLocations.subscribeByCustomerID({
        siteKey: props.siteKey,
        customerID: props.customer.id,
        onChange: setCusLocOptions,
        onError: logger.error,
      });
      return unsubscribeFn;
    }
    const unsubscribe = getCusLocOptions();
    return () => unsubscribe && unsubscribe();
  }, [props.customer.id, props.siteKey]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  function closeAndReset() {
    reset();
    props.closeDialog();
  }

  const customerLocationSection = (
    <CustomerLocationSection
      allowSelection={true}
      selectedCustomer={props.customer}
      customerLocationOptions={cusLocOptions}
      selectedCustomerLocation={selectedCusLoc}
      setSelectedCustomerLocation={(location) => {
        setSelectedCusLoc(location);
        setValue("customerLocationID", location?.id ?? null);
      }}
      openEditExistingCustomerLocation={undefined}
      membershipTemplateList={[]}
      customerLocationMemberships={[]}
      useBaseInputSelect
    />
  );

  const onSubmit: SubmitHandler<EditFormState> = async (formValues) => {
    if (!firebaseUser) {
      throw Error("EditMembershipDialog onSubmit: firebaseUser is null");
    }
    if (!selectedSiteKeyLoc || !formValues.customerLocationID) {
      logger.error(
        `EditMembershipDialog onSubmit: missing locationID ${selectedSiteKeyLoc?.id} &/or customerLocationID ${formValues.customerLocationID}`,
      );
      setErrorMessage("Select a site location");
      return;
    }

    const diff = diffObjects(defaultValues, formValues).diff;
    // don't sleep on the assetIDs and locationID
    const changedAssets =
      assetOptions.length && !isEqual(assetIDs, props.membership.assetIDs);
    const changedLocation =
      selectedSiteKeyLoc.id !== props.membership.locationID;

    if (Object.keys(diff).length === 0 && !changedAssets && !changedLocation) {
      return;
    }
    const update: Record<string, unknown> = {
      ...diff,
      lastModifiedBy: firebaseUser.uid,
      timestampLastModified: Timestamp.now(),
    };
    if (changedAssets) update.assetIDs = assetIDs;
    if (changedLocation) update.locationID = selectedSiteKeyLoc.id;

    try {
      const validUpdate = MembershipManager.parsePartial(update);
      logger.info("update membership", validUpdate);
      await DbWrite.memberships.update(
        props.siteKey,
        props.membership.id,
        MembershipManager.convertForFirestore(validUpdate),
      );
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate("Membership"),
        dialog: false,
        type: "success",
      });
      closeAndReset();
    } catch (e) {
      logger.error(e);
      addMessage({
        id: createToastMessageID(),
        message: strings.failedUpdate("membership"),
        dialog: true,
        type: "error",
      });
    }
  };

  const header = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white">
      <h1 className="inline-flex items-center text-xl font-semibold">
        {strings.EDIT_MEMBERSHIP}
      </h1>
      <button type="button" onClick={closeAndReset}>
        <XMarkIcon
          aria-label="close edit membership dialog"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={() => {}} // prevent unintentionally closing dialog
      open={props.isDialogOpen}
      title={header}
      allowOverflowY={true}
      parentDivStyles="inline-block transform overflow-hidden max-w-4xl rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        className="px-4 pb-4 md:px-8 md:pb-6"
      >
        {/* CUSTOMER & MEMBERSHIP TEMPLATE - immutable */}
        <div className="mb-6 flex flex-col items-start justify-between gap-4 xs:flex-row">
          <div className="-mt-4 md:mt-0">
            <CustomerDetails
              customer={props.customer}
              openEditCustomerDialog={null}
              openCallDialog={null}
              shouldConstrainWidth={false}
            />
          </div>
          <div className="shrink-0 xs:text-right">
            <h6 className="text-sm font-semibold text-primary">
              {strings.MEMBERSHIP_TYPE}
            </h6>
            <p className="text-base font-semibold text-gray-700">
              {props.template.title}
            </p>
          </div>
        </div>

        <div className="space-y-8 md:grid md:grid-cols-2 md:gap-6 md:space-y-0">
          {/* CUSTOMER LOCATION */}
          <div>
            {customerLocationSection}
            {errors.customerLocationID?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.customerLocationID.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          {/* SITE LOCATION */}
          {siteKeyLocationList.length > 1 && (
            <div>
              <BaseInputSelect
                id="locationID"
                inputName="locationID"
                text="Site Location"
                admin
                required
                value={selectedSiteKeyLoc?.id ?? ""}
                onChange={(event) => {
                  const loc = siteKeyLocationList.find(
                    (l) => l.id === event.target.value,
                  );
                  setSelectedSiteKeyLoc(loc ?? null);
                }}
              >
                <option value="" disabled>
                  Select Site Location
                </option>

                {siteKeyLocationList.map((skl) => (
                  <option key={skl.id} value={skl.id}>
                    {skl.title}
                  </option>
                ))}
              </BaseInputSelect>
              {errorMessage && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errorMessage }}
                  </StyledMessage>
                </div>
              )}
            </div>
          )}

          {/* STATUS */}
          <div>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <BaseInputSelect
                  id="status"
                  inputName="status"
                  text="Membership Status"
                  admin
                  required
                  {...field}
                  value={field.value ?? ""}
                  onChange={(event) => {
                    field.onChange(
                      event.target.value === "" ? null : event.target.value,
                    );
                  }}
                >
                  <option value="" disabled>
                    Select a Status
                  </option>

                  {membershipStatus.map((status) => (
                    <option key={status} value={status}>
                      {getReadableMembershipStatus(status)}
                    </option>
                  ))}
                </BaseInputSelect>
              )}
            />
            {errors.status?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.status.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          {/* NOTES */}
          <div className="col-span-2 col-start-1">
            <Controller
              name="notes"
              control={control}
              render={({ field }) => (
                <BaseInputTextArea
                  text="Notes"
                  rows={4}
                  inputName="notes"
                  admin={true}
                  required={false}
                  {...field}
                  value={field.value === null ? "" : field.value}
                />
              )}
            />
            {errors.notes?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.notes.message }}
                </StyledMessage>
              </div>
            )}
          </div>
        </div>

        {/* CONDITIONALLY-DISPLAYED FIELDS: assets, task generation, receipt emails */}
        <div className="mt-4 flex flex-wrap gap-4">
          {props.assetsEnabled && assetOptions.length > 0 && (
            // Allow user to specify one or more assets that might apply to this membership
            <div className="max-w-fit space-y-2 rounded-md border border-gray-500 px-3 py-2">
              <h6 className="text-base font-semibold text-primary">
                {strings.SELECT_ASSET}:
              </h6>
              <AssetEquipmentListCheckboxes
                assetsForCustomer={assetOptions}
                assetIDs={assetIDs}
                setAssetIDs={setAssetIDs}
              />
            </div>
          )}

          <div className="flex flex-wrap gap-4">
            {props.template.taskGeneration.length > 0 && (
              <div className="max-w-fit self-start rounded-md border border-gray-500 px-3 py-2">
                <Controller
                  name="automaticallyGenerateTasks"
                  control={control}
                  render={({ field }) => (
                    <StyledSwitchGroup
                      readableName="Automatically Generate Tasks"
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      ref={field.ref}
                      checked={field.value}
                      id="automaticallyGenerateTasks"
                      name={field.name}
                    />
                  )}
                />
              </div>
            )}

            {props.template.invoiceMethod === "automatic" && (
              <StyledTooltip
                title={`${props.template.title} automatically collects membership dues. This toggle controls whether or not receipts will automatically be sent to the customer.`}
              >
                <div
                  ref={automatedPaymentsTooltipRef}
                  className="max-w-fit self-start rounded-md border border-gray-500 px-3 py-2"
                >
                  <Controller
                    name="automaticallySendReceipt"
                    control={control}
                    render={({ field }) => (
                      <StyledSwitchGroup
                        readableName="Email Customer Receipts for Automated Payments"
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        ref={field.ref}
                        checked={field.value}
                        id="automaticallySendReceipt"
                        name={field.name}
                      />
                    )}
                  />
                </div>
              </StyledTooltip>
            )}
          </div>
        </div>

        {/* TIMESTAMP FIELDS */}
        <div className="mt-8 flex flex-wrap gap-x-6 gap-y-10 md:justify-between">
          <div className="space-y-8">
            <Controller
              key="membershipStartDate"
              name="membershipStartDate"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <BaseInputDate
                    inputName="membershipStart"
                    displayText={strings.MEMBERSHIP_START_DATE}
                    required
                    addWrapperStyles="mt-2"
                    overrideWidth="w-48 lg:w-52"
                    value={field.value?.toDate().toISOString() ?? null}
                    onChange={(date) => {
                      if (date) field.onChange(Timestamp.fromDate(date));
                    }}
                  />
                  {error?.message && (
                    <div className="mt-2 text-sm">
                      <StyledMessage type="error">
                        {{ message: error.message }}
                      </StyledMessage>
                    </div>
                  )}
                </div>
              )}
            />

            <Controller
              key="membershipEndDate"
              name="membershipEndDate"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <BaseInputDate
                    inputName="membershipEnd"
                    displayText={strings.MEMBERSHIP_END_DATE}
                    addWrapperStyles="mt-2"
                    overrideWidth="w-48 lg:w-52"
                    value={field.value?.toDate().toISOString() ?? null}
                    onChange={(date) => {
                      if (date) field.onChange(Timestamp.fromDate(date));
                    }}
                  />
                  {error?.message && (
                    <div className="mt-2 text-sm">
                      <StyledMessage type="error">
                        {{ message: error.message }}
                      </StyledMessage>
                    </div>
                  )}
                </div>
              )}
            />
          </div>
          <div className="space-y-8">
            <Controller
              key="lastCompletedTaskDate"
              name="lastCompletedTaskDate"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <BaseInputDate
                    inputName="lastCompletedTask"
                    displayText={strings.LAST_COMPLETED_TASK_DATE}
                    addWrapperStyles="mt-2"
                    overrideWidth="w-48 lg:w-52"
                    value={field.value?.toDate().toISOString() ?? null}
                    onChange={(date) => {
                      if (date) field.onChange(Timestamp.fromDate(date));
                    }}
                  />
                  {error?.message && (
                    <div className="mt-2 text-sm">
                      <StyledMessage type="error">
                        {{ message: error.message }}
                      </StyledMessage>
                    </div>
                  )}
                </div>
              )}
            />

            <Controller
              key="nextScheduledTaskDate"
              name="nextScheduledTaskDate"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <BaseInputDate
                    inputName="nextScheduledTask"
                    displayText={strings.NEXT_SCHEDULED_TASK_DATE}
                    addWrapperStyles="mt-2"
                    overrideWidth="w-48 lg:w-52"
                    value={field.value?.toDate().toISOString() ?? null}
                    onChange={(date) => {
                      if (date) field.onChange(Timestamp.fromDate(date));
                    }}
                  />
                  {error?.message && (
                    <div className="mt-2 text-sm">
                      <StyledMessage type="error">
                        {{ message: error.message }}
                      </StyledMessage>
                    </div>
                  )}
                </div>
              )}
            />
          </div>
          <div className="space-y-8">
            <Controller
              key="lastPaymentDate"
              name="lastPaymentDate"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <BaseInputDate
                    inputName="lastPayment"
                    displayText={strings.LAST_PAYMENT_DATE}
                    addWrapperStyles="mt-2"
                    overrideWidth="w-48 lg:w-52"
                    value={field.value?.toDate().toISOString() ?? null}
                    onChange={(date) => {
                      if (date) field.onChange(Timestamp.fromDate(date));
                    }}
                  />
                  {error?.message && (
                    <div className="mt-2 text-sm">
                      <StyledMessage type="error">
                        {{ message: error.message }}
                      </StyledMessage>
                    </div>
                  )}
                </div>
              )}
            />

            <Controller
              key="nextInvoiceDate"
              name="nextInvoiceDate"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <BaseInputDate
                    inputName="nextInvoice"
                    displayText={strings.NEXT_INVOICE_DATE}
                    addWrapperStyles="mt-2"
                    overrideWidth="w-48 lg:w-52"
                    value={field.value?.toDate().toISOString() ?? null}
                    onChange={(date) => {
                      if (date) field.onChange(Timestamp.fromDate(date));
                    }}
                  />
                  {error?.message && (
                    <div className="mt-2 text-sm">
                      <StyledMessage type="error">
                        {{ message: error.message }}
                      </StyledMessage>
                    </div>
                  )}
                </div>
              )}
            />
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex w-full flex-col items-center justify-between gap-4 pt-8 xs:flex-row">
          <BaseButtonSecondary
            type="button"
            className="w-full justify-center uppercase"
            onClick={closeAndReset}
          >
            {strings.buttons.CANCEL}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            type="submit"
            formNoValidate
            isBusy={isSubmitting}
            busyText={strings.buttons.BUSY_SAVING}
            className="w-full justify-center uppercase"
            data-testid="save item"
          >
            {strings.buttons.SAVE}
          </BaseButtonPrimary>
        </div>
      </form>
    </BaseModal>
  );
}
