// Libs
import { useCallback, useLayoutEffect, useState } from "react";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";

// Local
import CustomerTable from "./CustomerTable";
import {
  ExistingCustomer,
  ExistingCustomerWithLocations,
} from "../../models/customer";
import { useTypesenseStore } from "../../store/typesense";
import { typesenseCustomersQuery } from "../../utils/typesenseQueries";
import LoadingClipboardAnimation from "../LoadingClipBoardAnimation";
import BaseModal from "../BaseModal";
import { SELECT_EXISTING_CUSTOMER } from "../../strings";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";

/** It should really be named SelectCustomerTableDialog. or SelectCustomerDialog. */
export default function CustomerTableDialog(props: {
  siteKey: string;
  customerIDToExclude?: string;
  onSelectCustomer: (customerDoc: ExistingCustomer) => void;
  addCustomerButton: React.ReactNode;
  // DIALOG BASICS
  isOpen: boolean;
  onClose: () => void;
}): JSX.Element {
  const [typesenseSearchKey, typesenseLoading] = useTypesenseStore((state) => [
    state.scopedSearchKey,
    state.loading,
  ]);
  const [typesenseCustomers, setTypesenseCustomers] = useState<
    ExistingCustomerWithLocations[]
  >([]);

  useLayoutEffect(() => {
    async function getCustomers() {
      if (!typesenseSearchKey) return;
      const customers = await typesenseCustomersQuery(typesenseSearchKey, "");
      setTypesenseCustomers(
        customers.filter((c) => c.id !== props.customerIDToExclude),
      );
    }
    getCustomers();
  }, [props.customerIDToExclude, typesenseSearchKey]);

  const onFilterTextBoxChanged = useCallback(async () => {
    if (!typesenseSearchKey) return;
    const searchTerm = (
      document.getElementById("search-customer") as HTMLInputElement
    ).value;

    const customers = await typesenseCustomersQuery(
      typesenseSearchKey,
      searchTerm,
    );
    setTypesenseCustomers(
      customers.filter((c) => c.id !== props.customerIDToExclude),
    );
  }, [props.customerIDToExclude, typesenseSearchKey]);

  return (
    <BaseModal
      open={props.isOpen}
      closeModal={props.onClose}
      allowOverflowY={true}
      title={
        <div className="flex items-center justify-between rounded-t-lg bg-primary p-6 text-xl font-semibold text-white md:px-8">
          <h1>{SELECT_EXISTING_CUSTOMER}</h1>
          <button
            onClick={props.onClose}
            className="focus-visible:outline focus-visible:outline-white"
          >
            <XMarkIcon aria-label="close dialog" className="h-6 w-6" />
          </button>
        </div>
      }
      parentDivStyles="text-left max-w-6xl"
    >
      <div className="relative h-[700px] px-2 lg:px-6">
        {typesenseLoading ? (
          <div className="flex h-full flex-col items-center justify-center">
            <LoadingClipboardAnimation />
          </div>
        ) : (
          <CustomerTable
            customersWithLocations={typesenseCustomers}
            onSelect={props.onSelectCustomer}
            onSearch={onFilterTextBoxChanged}
            addCustomerButton={props.addCustomerButton}
          />
        )}
      </div>
    </BaseModal>
  );
}
