//Libs
import { DocumentData, DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

//Local
import { NotFoundError } from "../error-classes";
import { zFallback } from "../utils/zod-fallback";
import { dropUndefined, guardIsPlainObject } from "../utils";
import {
  CustomerTypes,
  ExistingCustomer,
  existingCustomerSchema,
} from "./customer";
import {
  ExistingCustomerLocation,
  existingCustomerLocationSchema,
} from "./customer-location";
import {
  EstimateItem,
  ExistingEstimateItem,
  existingEstimateItemSchema,
  TemporaryEstimateItem,
} from "./estimate-item";
import { ExistingTask, existingTaskSchema } from "./task";
import { convertObjectToFSTimestamp } from "../utils/convertObjectToFSTimestamp";
import { SiteKey } from "./site-key";
import currencyFormatter from "../currency";
import {
  ExistingStiltPhoto,
  existingStiltPhotoSchemaWithFallbacks,
} from "./stilt-photo";
import { ExistingCommissionAdjustment } from "./commission-adjustment";
import { ExistingPriceBookItem } from "./price-book-item";
import { ExistingCraftRecord } from "./craft-record";
import { ExistingStiltInvoice } from "./invoice";

export interface Estimate {
  status: EstimateStatusTypes;
  notes: string | null;
  discount: number | null;
  internalNotes: string | null;

  customerID: string;
  customerLocationID: string;
  estimatePackageID: string | null;

  taskID: string | null;
  craftRecordID: string | null;
  locationID: string;

  customData: { [key: string]: any };
  tags: string[];

  timestampCreated: Timestamp;
  timestampLastModified: Timestamp;
  createdBy: string;
  lastModifiedBy: string;

  timestampSentToCustomer: Timestamp | null;
  timestampApprovedByCustomer: Timestamp | null;
  timestampExpiration: Timestamp | null;

  estimateNumber?: string;

  deleted: boolean;
}

export interface JobCommissions {
  addOnSales: number;
  baseCommission: number;
  fieldCommission: number;
  salesCommission: number;
}

export interface CommissionReportData {
  id: string;
  date: string;
  employee: string;
  taskType: string;
  message: string;
  invoiceNumber: string;
  invoiceTotal: number;
  customerName: string;
  commissionableSalesBase: number;
  baseCommission: number;
  commissionableSalesAddOn: number;
  addOnCommission: number;
  salesCommission: number;
  salesCommissionRate: number;
  totalCommission: number;
  customerType: string;
  tips: number;
  needsEstimateOnSite: boolean;
  // referenced props
  task?: ExistingTask;
  craftRecord?: ExistingCraftRecord;
  invoice?: ExistingStiltInvoice;
  estimates?: ExistingEstimate[];
  estimateItems?: ExistingEstimateItem[];
}

export interface JobCommissionsForDisplay {
  addOnSales: string;
  baseCommission: string;
  fieldCommission: string;
  baseAndFieldCommissions: string;
  salesCommission: string;
  commissionAdjustments: ExistingCommissionAdjustment[];
}

export interface ExistingEstimate extends Estimate {
  id: string;
  refPath: string;
}

export interface ExistingEstimateUpdate extends Partial<ExistingEstimate> {
  id: string;
  refPath: string;
  timestampLastModified: Timestamp;
  lastModifiedBy: string;
}

export const estimateStatusTypes = [
  "draft",
  "awaitingApproval",
  "approved",
  "onHold",
  "rejected",
  "locked",
] as const;
export type EstimateStatusTypes = (typeof estimateStatusTypes)[number];

export enum EstimateStatus {
  DRAFT = "draft",
  AWAITING_APPROVAL = "awaitingApproval",
  APPROVED = "approved",
  ON_HOLD = "onHold",
  REJECTED = "rejected",
  LOCKED = "locked",
}

/***********************/
// FOR THE API
/***********************/
type withoutTimestamps = Omit<
  Estimate,
  "timestampCreated" | "timestampLastModified"
>;

// Timestamps are added on the backend.
export interface Estimate_CreateAPI extends withoutTimestamps {
  siteKey: string;
  /** the uuid will become the document id. */
  uuid: string;
}

// timestampLastModified is added on the backend. (timestampCreated won't change)
export type Estimate_UpdateAPI = Omit<
  Partial<ExistingEstimate>,
  "timestampCreated" | "timestampLastModified"
>;

export interface EstimateDataForNonAuthUser extends ExistingEstimate {
  customer: ExistingCustomer;
  customerLocation: ExistingCustomerLocation;
  estimateItemList: ExistingEstimateItem[];
  photos: ExistingStiltPhoto[];
  taskDoc: ExistingTask | null;
  userName: string;
  invoiceExists: boolean;
  siteKey: string;
  currency: string;
  merchantLogoURL: string | null;
}

// #region SECTION: Functions
/** Utilities for interacting with Estimate objects  */
export const EstimateManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingEstimate object.
   */
  createFromFirestoreSnapshot: createEstimateFromFirestoreSnapshot,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateEstimate,
  /** Validate an Estimate doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateEstimateWithFallbacks,
  /** Validate a new estimate. For the create endpoint. */
  parseCreate: validateEstimate_Create,
  /** Validate an existing estimate. For the update endpoint. */
  parseUpdate: validateEstimate_Update,
  /** Validate a set of data needed for show estimate to a non auth user */
  parseNonAuthData: validateEstimateDataForNonAuthUser,
  convertUpdateForFirestore: convertEstimateUpdateForFirestore,
  getJobCommissionsForDisplay: getJobCommissionsForDisplay,
};

function getCommissionableSales(
  items: EstimateItem[] | TemporaryEstimateItem[],
  globalDiscount: number,
) {
  let commissionableSales = 0;
  // get the total sales volume of commissionable baseItems
  for (const i of items) {
    if (i.customData.nonCommissionable === true) {
      continue;
    }
    let itemAmount = i.quantity * i.unitPrice * (1 - (i.discount ?? 0) / 100);
    if (i.discountable) {
      itemAmount = itemAmount * (1 - globalDiscount / 100);
    }
    commissionableSales += itemAmount;
  }
  return commissionableSales;
}

function getJobCommissions(
  baseEstimateItems: EstimateItem[] | TemporaryEstimateItem[],
  fieldEstimateItems: EstimateItem[] | TemporaryEstimateItem[],
  priceBookItemsWithCommissionOverrides: ExistingPriceBookItem[],
  siteKeyData: SiteKey,
  customerType: CustomerTypes,
  globalDiscount: number,
  allItemsAtUpsellCommission: boolean,
  sameDayJob: boolean,
): JobCommissions {
  if (!siteKeyData.customizations.commissions) {
    // commissions not supported for the site, return 0
    return {
      addOnSales: 0,
      baseCommission: 0,
      fieldCommission: 0,
      salesCommission: 0,
    };
  }

  type AllTypesOfItems = EstimateItem | TemporaryEstimateItem;
  let baseEstimateItemsAll: AllTypesOfItems[] = [...baseEstimateItems];
  const fieldEstimateItemsAll: AllTypesOfItems[] = [...fieldEstimateItems];

  let salesRate = 0;
  let baseRate = 0;
  let fieldRate = 0;
  let baseCommission = 0;
  let fieldCommission = 0;
  let salesCommission = 0;
  let baseSalesCommissionable = 0;
  let fieldSalesCommissionable = 0;
  let addOnSalesCommissionable = 0;

  // Get sales commission out of the way
  if (siteKeyData.customizations.commissions[customerType].salesRate) {
    salesRate = siteKeyData.customizations.commissions[customerType].salesRate;
  }
  salesCommission =
    getCommissionableSales(fieldEstimateItemsAll, globalDiscount) * salesRate;
  // done with sales commissions

  // Commission rates from the siteKey
  if (sameDayJob) {
    if (siteKeyData.customizations.commissions[customerType].baseRateSameDay) {
      baseRate =
        siteKeyData.customizations.commissions[customerType].baseRateSameDay;
    }
  } else {
    if (siteKeyData.customizations.commissions[customerType].baseRate) {
      baseRate = siteKeyData.customizations.commissions[customerType].baseRate;
    }
  }

  if (siteKeyData.customizations.commissions[customerType].fieldRate) {
    fieldRate = siteKeyData.customizations.commissions[customerType].fieldRate;
  }

  if (allItemsAtUpsellCommission) {
    // Clear baseItems if all items are to be commissioned at the upsell rate.
    // (for example, if this is a follow-up task where the technician up-sold
    // everything during a previous visit)
    baseEstimateItemsAll = [];
  }

  baseSalesCommissionable = getCommissionableSales(
    baseEstimateItemsAll,
    globalDiscount,
  );
  fieldSalesCommissionable = getCommissionableSales(
    fieldEstimateItemsAll,
    globalDiscount,
  );

  if (fieldSalesCommissionable > baseSalesCommissionable) {
    addOnSalesCommissionable =
      fieldSalesCommissionable - baseSalesCommissionable;
  }

  if (fieldSalesCommissionable <= baseSalesCommissionable) {
    baseSalesCommissionable = fieldSalesCommissionable;
    addOnSalesCommissionable = 0;
  }

  // Now we separate out any items that have commission overrides
  const baseEstimateItemsWithOverride = baseEstimateItemsAll.filter((item) =>
    priceBookItemsWithCommissionOverrides.some(
      (pbItem) => pbItem.id === item.priceBookItemID,
    ),
  );
  const fieldEstimateItemsWithOverride = fieldEstimateItemsAll.filter((item) =>
    priceBookItemsWithCommissionOverrides.some(
      (pbItem) => pbItem.id === item.priceBookItemID,
    ),
  );
  const baseEstimateItemsWithoutOverride = baseEstimateItemsAll.filter(
    (item) =>
      !priceBookItemsWithCommissionOverrides.some(
        (pbItem) => pbItem.id === item.priceBookItemID,
      ),
  );
  const fieldEstimateItemsWithoutOverride = fieldEstimateItemsAll.filter(
    (item) =>
      !priceBookItemsWithCommissionOverrides.some(
        (pbItem) => pbItem.id === item.priceBookItemID,
      ),
  );

  // Calculate the commissions for items without overrides
  let baseSalesCommissionableNoOverride = getCommissionableSales(
    baseEstimateItemsWithoutOverride,
    globalDiscount,
  );
  const fieldSalesCommissionableNoOverride = getCommissionableSales(
    fieldEstimateItemsWithoutOverride,
    globalDiscount,
  );
  let addOnSalesCommissionableNoOverride = 0;
  if (fieldSalesCommissionableNoOverride > baseSalesCommissionableNoOverride) {
    addOnSalesCommissionableNoOverride =
      fieldSalesCommissionableNoOverride - baseSalesCommissionableNoOverride;
  }
  if (fieldSalesCommissionableNoOverride <= baseSalesCommissionableNoOverride) {
    // There were no upsells OR the total commissionable amounts were reduced in the field
    baseSalesCommissionableNoOverride = fieldSalesCommissionableNoOverride;
    addOnSalesCommissionableNoOverride = 0;
  }

  const baseCommissionNoOverride = baseRate * baseSalesCommissionableNoOverride;
  const fieldCommissionNoOverride =
    fieldRate * addOnSalesCommissionableNoOverride;

  let baseCommissionWithOverrideAtBaseRate = 0;
  let baseCommissionWithOverrideAtFieldRate = 0;
  let fieldCommissionWithOverride = 0;

  // Now we will add in the items that have commission overrides
  for (const i of fieldEstimateItemsWithOverride) {
    if (i.customData.nonCommissionable === true) {
      continue;
    }
    const priceBookItem = priceBookItemsWithCommissionOverrides.find(
      (pbItem) => pbItem.id === i.priceBookItemID,
    );
    if (!priceBookItem) {
      continue;
    }
    let itemAmount = i.quantity * i.unitPrice * (1 - (i.discount ?? 0) / 100);
    if (i.discountable) {
      itemAmount = itemAmount * (1 - globalDiscount / 100);
    }
    if (customerType === "residential") {
      fieldCommissionWithOverride +=
        (priceBookItem.commissions?.residential?.upsellRate ?? fieldRate) *
        itemAmount;
    } else {
      fieldCommissionWithOverride +=
        (priceBookItem.commissions?.commercial?.upsellRate ?? fieldRate) *
        itemAmount;
    }
  }
  for (const i of baseEstimateItemsWithOverride) {
    if (i.customData.nonCommissionable === true) {
      continue;
    }
    const priceBookItem = priceBookItemsWithCommissionOverrides.find(
      (pbItem) => pbItem.id === i.priceBookItemID,
    );
    if (!priceBookItem) {
      continue;
    }
    let itemAmount = i.quantity * i.unitPrice * (1 - (i.discount ?? 0) / 100);
    if (i.discountable) {
      itemAmount = itemAmount * (1 - globalDiscount / 100);
    }
    if (customerType === "residential") {
      baseCommissionWithOverrideAtBaseRate +=
        (priceBookItem.commissions?.residential?.baseRate ?? baseRate) *
        itemAmount;
    } else {
      baseCommissionWithOverrideAtBaseRate +=
        (priceBookItem.commissions?.commercial?.baseRate ?? baseRate) *
        itemAmount;
    }
  }
  for (const i of baseEstimateItemsWithOverride) {
    if (i.customData.nonCommissionable === true) {
      continue;
    }
    const priceBookItem = priceBookItemsWithCommissionOverrides.find(
      (pbItem) => pbItem.id === i.priceBookItemID,
    );
    if (!priceBookItem) {
      continue;
    }
    let itemAmount = i.quantity * i.unitPrice * (1 - (i.discount ?? 0) / 100);
    if (i.discountable) {
      itemAmount = itemAmount * (1 - globalDiscount / 100);
    }
    if (customerType === "residential") {
      baseCommissionWithOverrideAtFieldRate +=
        (priceBookItem.commissions?.residential?.upsellRate ?? fieldRate) *
        itemAmount;
    } else {
      baseCommissionWithOverrideAtFieldRate +=
        (priceBookItem.commissions?.commercial?.upsellRate ?? fieldRate) *
        itemAmount;
    }
  }

  const addOnCommissionsFromOverrides =
    fieldCommissionWithOverride - baseCommissionWithOverrideAtFieldRate;

  baseCommission =
    baseCommissionNoOverride + baseCommissionWithOverrideAtBaseRate;
  fieldCommission = fieldCommissionNoOverride + addOnCommissionsFromOverrides;

  // OLD LOGIC, KEEPING FOR THE SHORT TERM
  // Because we support multiple lines of the same item, and each line might
  // have a different unitPrice, let's first get a map of each item where
  // priceBookItemID is the key, and the totalAmount (after discounts and
  // before taxes) is the value
  // const fieldItemsMap: Record<string, number> = {};
  // for (const i of fieldEstimateItemsAnyType) {
  //   // Skip if this item is nonCommmissionable
  //   if (i.customData.nonCommissionable === true) {
  //     continue;
  //   }
  //   const pbItemID = i.priceBookItemID;
  //   let itemAmount = i.quantity * i.unitPrice * (1 - (i.discount ?? 0) / 100);
  //   if (i.discountable) {
  //     itemAmount = itemAmount * (1 - globalDiscount / 100);
  //   }
  //   if (fieldItemsMap.pbItemID && typeof fieldItemsMap[pbItemID] === "number") {
  //     fieldItemsMap[pbItemID] = (fieldItemsMap[pbItemID] ?? 0) + itemAmount;
  //   } else {
  //     fieldItemsMap[pbItemID] = itemAmount;
  //   }
  // }
  //
  // for (const key of Object.keys(fieldItemsMap)) {
  //   const matchingBaseItems = baseEstimateItemsAnyType.filter(
  //     (e) => e.priceBookItemID == key,
  //   );
  //   if (matchingBaseItems.length > 0) {
  //     // This item was on the base estimate, let's get the total dollar amount of this item that was sold on the original estimate
  //     let totalBaseAmountSoldForItem = 0;
  //     for (const i of matchingBaseItems) {
  //       let itemAmount =
  //         i.quantity * i.unitPrice * (1 - (i.discount ?? 0) / 100);
  //       if (i.discountable) {
  //         itemAmount = itemAmount * (1 - globalDiscount / 100);
  //       }
  //       totalBaseAmountSoldForItem += itemAmount;
  //     }
  //     // Base commission is calculated off the initial total dollar amount of
  //     // this item sold
  //     baseCommission += baseRate * totalBaseAmountSoldForItem;
  //     // Field rate is calculated on the dollar amount above the base dollar amount
  //     if ((fieldItemsMap[key] ?? 0) > totalBaseAmountSoldForItem) {
  //       fieldCommission +=
  //         fieldRate * ((fieldItemsMap[key] ?? 0) - totalBaseAmountSoldForItem);
  //       addOnSales += (fieldItemsMap[key] ?? 0) - totalBaseAmountSoldForItem;
  //     } else {
  //       fieldCommission += 0;
  //     }
  //   } else {
  //     // This item was not on the base estimate, so the full value is calculated
  //     fieldCommission += fieldRate * (fieldItemsMap[key] ?? 0);
  //     addOnSales += fieldItemsMap[key] ?? 0;
  //   }
  //   // Sales commission is independent of upsell
  //   salesCommission += salesRate * fieldItemsMap[key];
  // }

  return {
    addOnSales: addOnSalesCommissionable,
    baseCommission: baseCommission,
    fieldCommission: fieldCommission,
    salesCommission: salesCommission,
  };
}

function getJobCommissionsForDisplay(
  baseEstimateItems: EstimateItem[] | TemporaryEstimateItem[],
  fieldEstimateItems: EstimateItem[] | TemporaryEstimateItem[],
  priceBookItemsWithCommissionOverrides: ExistingPriceBookItem[],
  siteKeyData: SiteKey,
  customerType: CustomerTypes,
  globalDiscount: number,
  allItemsAtUpsellCommission: boolean,
  sameDayJob: boolean,
  commissionAdjustments: ExistingCommissionAdjustment[],
): JobCommissionsForDisplay {
  const jobCommissions = getJobCommissions(
    baseEstimateItems,
    fieldEstimateItems,
    priceBookItemsWithCommissionOverrides,
    siteKeyData,
    customerType,
    globalDiscount,
    allItemsAtUpsellCommission,
    sameDayJob,
  );
  const currency = siteKeyData.customizations.accounting?.currency ?? "USD";
  return {
    baseCommission: currencyFormatter(jobCommissions.baseCommission, currency),
    fieldCommission: currencyFormatter(
      jobCommissions.fieldCommission,
      currency,
    ),
    baseAndFieldCommissions: currencyFormatter(
      jobCommissions.baseCommission + jobCommissions.fieldCommission,
      currency,
    ),
    addOnSales: currencyFormatter(jobCommissions.addOnSales, currency),
    salesCommission: currencyFormatter(
      jobCommissions.salesCommission,
      currency,
    ),
    commissionAdjustments: commissionAdjustments,
  };
}

/** Drop `id` and `refPath` before saving to the database. Drop undefined values */
function convertEstimateUpdateForFirestore(
  customer: ExistingEstimateUpdate,
): DocumentData {
  // Copy before modifying. Don't want to modify original object.
  const local = Object.assign({}, customer);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, refPath, ...rest } = local;
  const result = dropUndefined(rest);
  return result;
}

/**
 * Convert the Document Snapshot into a validated ExistingEstimate object.
 */
function createEstimateFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingEstimate {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateEstimateWithFallbacks(snapshotData),
  };
}

/* Zod validation schemas */
function validateEstimateWithFallbacks(value: unknown): Estimate {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = estimateSchemaWithFallbacks.parse(value);
  return result;
}

function validateEstimate(value: unknown): Estimate {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = estimateSchema.parse(value);
  return result;
}

function validateEstimate_Create(value: unknown): Estimate_CreateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return estimateSchema_CreateAPI.parse(value);
}

function validateEstimate_Update(value: unknown): Estimate_UpdateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return estimateSchema_UpdateAPI.parse(value);
}

function validateEstimateDataForNonAuthUser(
  value: unknown,
): EstimateDataForNonAuthUser {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }

  const {
    customer,
    customerLocation,
    estimateItemList,
    taskDoc,
    currency,
    ...estimateDoc
  } = value;

  if (!estimateDoc.userName) {
    estimateDoc.userName = "";
  }

  const task: { [key: string]: any } | null = taskDoc as any;

  const dataToBeValidated = {
    ...estimateDoc,
    currency: currency,
    timestampCreated: convertObjectToFSTimestamp(estimateDoc.timestampCreated),
    timestampLastModified: convertObjectToFSTimestamp(
      estimateDoc.timestampLastModified,
    ),
    timestampSentToCustomer: estimateDoc.timestampSentToCustomer
      ? convertObjectToFSTimestamp(estimateDoc.timestampSentToCustomer)
      : null,
    timestampApprovedByCustomer: estimateDoc.timestampApprovedByCustomer
      ? convertObjectToFSTimestamp(estimateDoc.timestampApprovedByCustomer)
      : null,
    timestampExpiration: estimateDoc.timestampExpiration
      ? convertObjectToFSTimestamp(estimateDoc.timestampExpiration)
      : null,

    customer: guardIsPlainObject(customer)
      ? {
          ...customer,
          timestampCreated: convertObjectToFSTimestamp(
            customer.timestampCreated,
          ),
          timestampLastModified: convertObjectToFSTimestamp(
            customer.timestampLastModified,
          ),
        }
      : { customer },

    customerLocation: guardIsPlainObject(customerLocation)
      ? {
          ...customerLocation,
          timestampCreated: convertObjectToFSTimestamp(
            customerLocation.timestampCreated,
          ),
          timestampLastModified: convertObjectToFSTimestamp(
            customerLocation.timestampLastModified,
          ),
        }
      : { customerLocation },

    estimateItemList: Array.isArray(estimateItemList)
      ? estimateItemList.map((estimateItem: any) => {
          return {
            ...estimateItem,
            timestampCreated: convertObjectToFSTimestamp(
              estimateItem.timestampCreated,
            ),
            timestampLastModified: convertObjectToFSTimestamp(
              estimateItem.timestampLastModified,
            ),
          };
        })
      : [estimateItemList],
    taskDoc:
      // eslint-disable-next-line no-nested-ternary
      task && task.exists === true
        ? guardIsPlainObject(task)
          ? {
              ...task,
              sendBookingConfirmationEmail:
                task.sendBookingConfirmationEmail ?? false,
              sendJobReminderEmail: task.sendJobReminderEmail ?? false,
              timestampAwaitingStart: task.timestampAwaitingStart
                ? convertObjectToFSTimestamp(task.timestampAwaitingStart)
                : null,
              timestampCreated: convertObjectToFSTimestamp(
                task.timestampCreated,
              ),
              timestampLastModified: convertObjectToFSTimestamp(
                task.timestampLastModified,
              ),
              timestampScheduled: task.timestampScheduled
                ? convertObjectToFSTimestamp(task.timestampScheduled)
                : null,
              timestampTaskCompleted: task.timestampTaskCompleted
                ? convertObjectToFSTimestamp(task.timestampTaskCompleted)
                : null,
              timestampTaskStarted: task.timestampTaskStarted
                ? convertObjectToFSTimestamp(task.timestampTaskStarted)
                : null,
            }
          : { task }
        : null,
  };

  return estimateDataForNonAuthUserSchema.parse(dataToBeValidated);
}

// #endregion

// #region SECTION: Schemas
// Used when validating data coming from the database.
const estimateSchemaWithFallbacks = z.object({
  status: zFallback(
    z.enum(estimateStatusTypes),
    "draft",
    "estimateSchemaWithFallbacks: 'status'",
  ),
  notes: zFallback(
    z.string().max(8000).nullable(),
    null,
    "estimateSchemaWithFallbacks: 'notes'",
  ),
  internalNotes: zFallback(
    z.string().max(8000).nullable(),
    null,
    "estimateSchemaWithFallbacks: 'internalNotes'",
  ),
  discount: zFallback(
    z.number().nullable(),
    null,
    "estimateSchemaWithFallbacks: 'discount'",
  ),
  customerID: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "estimateSchemaWithFallbacks: 'customerID'",
  ),
  customerLocationID: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "estimateSchemaWithFallbacks: 'customerLocationID'",
  ),
  estimatePackageID: zFallback(
    z.string().min(1).max(200).nullable(),
    null,
    "estimateSchemaWithFallbacks: 'estimatePackageID'",
  ),
  taskID: zFallback(
    z.string().min(1).max(200).nullable(),
    null,
    "estimateSchemaWithFallbacks: 'customerID'",
  ),
  craftRecordID: zFallback(
    z.string().min(1).max(200).nullable(),
    null,
    "estimateSchemaWithFallbacks: 'craftRecordID'",
  ),
  locationID: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "estimateSchemaWithFallbacks: 'locationID'",
  ),
  customData: zFallback(
    z.record(z.any()),
    {},
    "estimateSchemaWithFallbacks: 'customData'",
  ),
  tags: zFallback(
    z.array(z.string().min(1).max(200)),
    [],
    "estimateSchemaWithFallbacks: 'tags'",
  ),
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "estimateSchemaWithFallbacks: 'timestampCreated'",
  ),
  timestampLastModified: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "estimateSchemaWithFallbacks: 'timestampLastModified'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  createdBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "estimateSchemaWithFallbacks: 'createdBy'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  lastModifiedBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "estimateSchemaWithFallbacks: 'lastModifiedBy'",
  ),
  timestampSentToCustomer: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "estimateSchemaWithFallbacks: 'timestampSentToCustomer'",
  ),
  timestampApprovedByCustomer: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "estimateSchemaWithFallbacks: 'timestampApprovedByCustomer'",
  ),
  timestampExpiration: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "estimateSchemaWithFallbacks: 'timestampExpiration'",
  ),
  deleted: zFallback(
    z.boolean(),
    false,
    "estimateSchemaWithFallbacks: 'deleted'",
  ),
  estimateNumber: z.string().optional(),
});

// Used when writing to the DB or reading from the user.
const estimateSchema = z.object({
  status: z.enum(estimateStatusTypes),
  notes: z.string().max(8000).nullable(),
  internalNotes: z.string().max(8000).nullable(),
  discount: z.number().nullable(),
  customerID: z.string().min(1).max(200),
  customerLocationID: z.string().min(1).max(200),
  estimatePackageID: z.string().min(1).max(200).nullable(),
  taskID: z.string().min(1).max(200).nullable(),
  craftRecordID: z.string().min(1).max(200).nullable(),
  locationID: z.string().min(1).max(200),
  customData: z.record(z.any()),
  tags: z.array(z.string().min(1).max(200)),
  timestampCreated: z.instanceof(Timestamp),
  timestampLastModified: z.instanceof(Timestamp),
  createdBy: z.string().min(1).max(200),
  lastModifiedBy: z.string().min(1).max(200),
  timestampSentToCustomer: z.instanceof(Timestamp).nullable(),
  timestampApprovedByCustomer: z.instanceof(Timestamp).nullable(),
  timestampExpiration: z.instanceof(Timestamp).nullable(),
  deleted: z.boolean(),
  estimateNumber: z.string().optional(),
});

/***********************/
// FOR THE API
/***********************/
const withoutTimestampsSchema = estimateSchema.omit({
  timestampCreated: true,
  timestampLastModified: true,
});

// Used for interacting with the Create endpoint.
const estimateSchema_CreateAPI = withoutTimestampsSchema.extend({
  siteKey: z.string().min(1).max(400),
  uuid: z.string().min(1).max(400),
});

// Used for interacting with the Update endpoint
const estimateSchema_UpdateAPI = withoutTimestampsSchema
  .extend({
    id: z.string().min(1).max(200),
    refPath: z.string().min(1).max(400),
  })
  .partial();

const estimateDataForNonAuthUserSchema = estimateSchema.extend({
  id: z.string().min(1).max(200),
  refPath: z.string().min(1).max(400),
  customer: existingCustomerSchema,
  customerLocation: existingCustomerLocationSchema,
  estimateItemList: z.array(existingEstimateItemSchema),
  photos: z.array(existingStiltPhotoSchemaWithFallbacks),
  taskDoc: existingTaskSchema.nullable(),
  userName: z.string().min(0).max(200),
  siteKey: z.string().min(1).max(200),
  invoiceExists: z.boolean(),
  currency: z.string().min(1).max(200),
  merchantLogoURL: z.string().min(1).max(1000).nullable(),
});
