//Libs
import EditIcon from "@mui/icons-material/Edit";
import {
  BusinessSharp,
  Call,
  EmailSharp,
  NotesSharp,
  PersonSharp,
  PhoneSharp,
} from "@mui/icons-material";

//Local
import { ExistingCustomer } from "../../models/customer";
import * as strings from "../../strings";
import { StyledTooltip } from "../StyledTooltip";
import { phoneUtils } from "../../utils/phoneUtils";
import ChipTag from "../ChipTag";
import Link from "../StiltLink";
import * as urls from "../../urls";

export function CustomerDetails({
  customer,
  openEditCustomerDialog,
  openCallDialog,
  shouldConstrainWidth = true,
}: {
  customer: ExistingCustomer;
  title?: boolean;
  openEditCustomerDialog: (() => void) | null;
  openCallDialog: ((phoneNumber: string) => void) | null;
  shouldConstrainWidth?: boolean;
}) {
  return (
    <div
      className={`mr-2 ${shouldConstrainWidth ? "max-w-72" : ""} pt-4 font-bold text-gray-800 md:pt-1`}
    >
      <div className="mb-2 flex flex-row justify-start">
        <div className="mr-4 text-lg font-bold text-primary md:text-xl">
          {strings.CUSTOMER_DETAILS}
        </div>

        {openEditCustomerDialog && (
          <StyledTooltip title="Edit Customer">
            <EditIcon
              aria-label="add customer contact"
              className="h-9 w-9 shrink-0 hover:text-primaryDark"
              onClick={openEditCustomerDialog}
            />
          </StyledTooltip>
        )}
      </div>

      <ul className="space-y-1">
        <li className="flex flex-row items-start">
          {customer.type === "commercial" ? (
            <BusinessSharp className="w-9 px-1" />
          ) : (
            <PersonSharp className="w-9 px-1" />
          )}
          <Link
            to={`${urls.CUSTOMERS_URL}/${customer.id}`}
            className="text-blue-500 underline"
          >
            {customer.name}
          </Link>
        </li>
        {customer.doNotService === true && (
          <span className="text-sm font-bold italic text-red-400">
            {strings.DO_NOT_SERVICE}
          </span>
        )}
        {customer.type === "commercial" ? (
          <>
            <PersonSharp className="w-9 px-1" />
            {customer.firstName} {customer.lastName}
          </>
        ) : null}
        <li className="flex flex-row items-start">
          <PhoneSharp className="w-9 px-1" />
          <div className="flex flex-col">
            {customer.phone?.map((p, phoneIndex) => {
              return (
                <div key={phoneIndex} className="items-center space-x-2">
                  <span>{p ? phoneUtils.display(p) : null}</span>
                  {openCallDialog && (
                    <StyledTooltip title="Initiate Call">
                      <Call
                        className="h-9 w-9 hover:text-primaryDark"
                        onClick={() => openCallDialog(p)}
                      ></Call>
                    </StyledTooltip>
                  )}
                </div>
              );
            })}
          </div>
        </li>
        <li className="flex flex-row items-start">
          <EmailSharp className="w-9 px-1" />
          {customer.email?.join(", ")}
        </li>
        {customer.notes && (
          <>
            <NotesSharp className="w-9 px-1" />
            {customer.notes}
          </>
        )}
        <li className="flex w-fit flex-row space-x-2 capitalize">
          <ChipTag tag={customer.type} />
          {customer.isTaxExempt && <ChipTag tag={strings.TAX_EXEMPT} />}
          {customer.isTaxExemptPST && <ChipTag tag={strings.TAX_EXEMPT_PST} />}
          {customer.isTaxExemptGST && <ChipTag tag={strings.TAX_EXEMPT_GST} />}
        </li>

        {customer.customData["templateNotes"] && (
          <li className="flex flex-col">
            <span className="text-sm font-medium text-gray-400">
              {strings.TEMPLATE_NOTES}
            </span>
            <p>{customer.customData["templateNotes"]}</p>
          </li>
        )}
      </ul>
    </div>
  );
}
