import { XMarkIcon } from "@heroicons/react/24/solid";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseModal from "../BaseModal";
import { CONFIRM_DELETE_TASK } from "../../strings";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleConfirmDeleteTask: () => void;
  isSubmitting: boolean;
}

export default function ConfirmDeleteTaskDialog({
  isOpen,
  onClose,
  handleConfirmDeleteTask,
  isSubmitting,
}: Props): JSX.Element {
  return (
    <BaseModal
      open={isOpen}
      closeModal={onClose}
      title={
        <div className="flex items-center justify-between rounded-t-lg bg-primary p-6 text-xl font-semibold text-white md:px-8">
          <h1>Delete Task?</h1>
          <button
            onClick={onClose}
            className="focus-visible:outline focus-visible:outline-white"
          >
            <XMarkIcon aria-label="close dialog" className="h-6 w-6" />
          </button>
        </div>
      }
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="flex flex-col space-y-8 p-6 md:px-8">
        <p>{CONFIRM_DELETE_TASK}</p>

        <div className="flex items-center justify-end gap-4">
          <BaseButtonSecondary
            type="button"
            onClick={onClose}
            className="uppercase"
          >
            Cancel
          </BaseButtonSecondary>
          <BaseButtonPrimary
            type="button"
            onClick={handleConfirmDeleteTask}
            isBusy={isSubmitting}
            busyText="Deleting..."
            className="uppercase"
          >
            Delete
          </BaseButtonPrimary>
        </div>
      </div>
    </BaseModal>
  );
}
