import { useRef, useState } from "react";
// Local
import BaseButtonSecondary from "../BaseButtonSecondary";
import * as strings from "../../strings";

export type SingleCustomerActions =
  | "deleteCustomer"
  | "mergeCustomer"
  | "generateStatement"
  | "generateSchedule"
  | "manageCardsOnFile";

interface Props {
  handleClick: (actionType: SingleCustomerActions) => Promise<void>;
  isBusy: boolean;
}

export default function SingleCustomerActionsDropdown(props: Props) {
  const dropdown = useRef(null);
  const [isOpen, setOpen] = useState(false);

  function deleteCustomer() {
    // this one opens a dialog, doesn't need to be async
    setOpen(false);
    props.handleClick("deleteCustomer");
  }

  function mergeCustomer() {
    // this one opens a dialog, doesn't need to be async
    setOpen(false);
    props.handleClick("mergeCustomer");
  }

  async function generateStatement() {
    setOpen(false);
    await props.handleClick("generateStatement");
  }

  async function generateSchedule() {
    setOpen(false);
    await props.handleClick("generateSchedule");
  }

  function manageCardsOnFile() {
    // this one opens a dialog, doesn't need to be async
    setOpen(false);
    props.handleClick("manageCardsOnFile");
  }

  return (
    <div>
      <BaseButtonSecondary
        onClick={() => setOpen(!isOpen)}
        ref={dropdown}
        isBusy={props.isBusy}
        onBlur={() => setOpen(false)}
        className="w-56"
        fontSize="text-base"
        height="" // let it be whatever height it wants to be
      >
        <p className="w-full text-left">Customer Actions</p>

        <svg
          className="ml-2 h-5"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </BaseButtonSecondary>

      <div
        className={`relative z-10 w-56 divide-y divide-gray-100 rounded-md bg-white text-left shadow ${
          isOpen ? "" : "hidden"
        }`}
      >
        <ul className="absolute z-10 w-56 divide-y divide-gray-100 rounded-md bg-white text-base shadow">
          <li>
            <a
              onMouseDown={(e) => e.preventDefault()}
              onClick={generateSchedule}
              className="block cursor-pointer p-4 hover:bg-gray-100"
            >
              {strings.GENERATE_SCHEDULE}
            </a>
          </li>
          <li>
            <a
              onMouseDown={(e) => e.preventDefault()}
              onClick={generateStatement}
              className="block cursor-pointer p-4 hover:bg-gray-100"
            >
              {strings.GENERATE_STATEMENT}
            </a>
          </li>
          <li>
            <a
              onMouseDown={(e) => e.preventDefault()}
              onClick={manageCardsOnFile}
              className="block cursor-pointer p-4 hover:bg-gray-100"
            >
              {strings.MANAGE_CARDS_ON_FILE}...
            </a>
          </li>
          <li>
            <a
              onMouseDown={(e) => e.preventDefault()}
              onClick={mergeCustomer}
              className="block cursor-pointer p-4 hover:bg-gray-100"
            >
              {strings.buttons.MERGE}...
            </a>
          </li>
          <li>
            <a
              onMouseDown={(e) => e.preventDefault()}
              onClick={deleteCustomer}
              className="block cursor-pointer p-4 hover:bg-gray-100"
            >
              {strings.buttons.DELETE}...
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
