//Libs
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { RangeValue } from "@react-types/shared";
import {
  CalendarDate,
  ZonedDateTime,
  CalendarDateTime,
} from "@internationalized/date";
import {
  ColDef,
  GridReadyEvent,
  ICellRendererParams,
  RowClickedEvent,
  ValueGetterFunc,
  ValueGetterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

//Local
import SearchBox from "../../components/SearchBox";
import * as strings from "../../strings";
import {
  ExistingStiltPayment,
  getReadablePaymentMethod,
} from "../../models/stilt-payment";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import currencyFormatter from "../../currency";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import UsersCustomFilter from "../../components/tables/UsersCustomFilter";

/** ExistingStiltPayment with invoiceNumber and customerName from the invoice */
type PaymentsTableData = ExistingStiltPayment & {
  invoiceNumber: string;
  customerName: string;
};

interface Props {
  paymentList: PaymentsTableData[];
  dateRange: RangeValue<CalendarDate | ZonedDateTime | CalendarDateTime>;
  isGetPayments: boolean;
  currency: string;
  userDisplayNamesMap: Record<string, string>;
  openInvoiceDialog: (invoiceID: string) => void;
  children: {
    PaymentDateRangePicker: React.ReactNode;
    // TransactionReportDownload: React.ReactNode;
  };
}

export default function PaymentListPage(props: Props) {
  const [gridReady, setGridReady] = useState(false);
  const gridRef = useRef<AgGridReact>(null);
  const onGridReady = useCallback(() => {
    setGridReady(true);
  }, []);

  useLayoutEffect(() => {
    if (gridRef.current && gridReady) {
      gridRef.current.api?.sizeColumnsToFit();
    }
  }, [gridReady]);

  const onFilterTextBoxChanged = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.setGridOption(
        "quickFilterText",
        (document.getElementById("filter-text-box") as HTMLInputElement).value,
      );
    }
  }, []);

  const getAmount = (params: ICellRendererParams): string => {
    return currencyFormatter(params.data.amount, props.currency);
  };

  const renderCustomerName = (params: ICellRendererParams): JSX.Element => {
    return <span className="capitalize">{params.data.customerName}</span>;
  };

  const formattedPaymentMethod = (params: ICellRendererParams): JSX.Element => {
    return (
      <span className="capitalize">
        {getReadablePaymentMethod(params.data.paymentMethod)}
      </span>
    );
  };

  const renderUserName: ValueGetterFunc = useCallback(
    function (params: ValueGetterParams) {
      const displayName = props.userDisplayNamesMap[params.data.createdBy];
      return displayName;
    },
    [props.userDisplayNamesMap],
  );

  return (
    <div className="space-y-8">
      <h1 className="text-5xl font-semibold text-primary">
        {strings.PAYMENTS}
      </h1>
      <div className="ag-theme-alpine h-[1000px] max-h-[70vh] pb-6">
        <div className="flex flex-col-reverse items-center gap-4 sm:flex-row sm:items-end sm:justify-between">
          <SearchBox
            onInput={onFilterTextBoxChanged}
            widthClasses="w-full sm:max-w-sm"
          />
          {props.children.PaymentDateRangePicker}
          {/*{props.children.TransactionReportDownload}*/}
        </div>
        {props.isGetPayments === true ? (
          <div className="flex h-full flex-col items-center justify-center">
            <LoadingClipboardAnimation />
          </div>
        ) : (
          <PaymentListTable
            paymentList={props.paymentList}
            gridRef={gridRef}
            onGridReady={onGridReady}
            getAmount={getAmount}
            formattedPaymentMethod={formattedPaymentMethod}
            renderCustomerName={renderCustomerName}
            openInvoiceDialog={props.openInvoiceDialog}
            renderUserName={renderUserName}
          />
        )}
      </div>
    </div>
  );
}

const commonColProps = {
  minWidth: 150,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass:
    "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase",
};

interface PaymentListTableProps {
  gridRef: React.MutableRefObject<any>;
  onGridReady: (event: GridReadyEvent) => void;
  paymentList: PaymentsTableData[];

  getAmount: (params: ICellRendererParams) => string;
  formattedPaymentMethod: (params: ICellRendererParams) => JSX.Element;
  renderCustomerName: (params: ICellRendererParams) => JSX.Element;
  renderUserName: ValueGetterFunc<any, any>;
  openInvoiceDialog: (invoiceID: string) => void;
}

const PaymentListTable = React.memo(
  ({
    paymentList,
    gridRef,
    onGridReady,
    getAmount,
    formattedPaymentMethod,
    renderCustomerName,
    openInvoiceDialog,
    renderUserName,
  }: PaymentListTableProps) => {
    const columnDefs: ColDef[] = [
      {
        headerName: "Payment Made",
        field: "timestampPaymentMade",
        cellRenderer: (params: ICellRendererParams) =>
          getReadableTimestamp(params.data.timestampPaymentMade),
        getQuickFilterText: (params) =>
          getReadableTimestamp(params.data.timestampPaymentMade),
      },
      {
        headerName: "Invoice #",
        field: "invoiceNumber",
      },
      {
        headerName: "Amount",
        field: "amount",
        cellRenderer: getAmount,
      },
      {
        headerName: "Customer Name",
        field: "customerName",
        cellRenderer: renderCustomerName,
      },
      {
        headerName: "Payment Method",
        field: "paymentMethod",
        cellRenderer: formattedPaymentMethod,
        getQuickFilterText: (params) =>
          getReadablePaymentMethod(params.data.paymentMethod),
      },
      {
        headerName: "Memo",
        field: "memo",
      },
      {
        headerName: "Check #",
        field: "checkNumber",
      },
      {
        field: "createdBy",
        filter: UsersCustomFilter,
        headerName: "Created By",
        cellRenderer: renderUserName,
      },
    ];

    return (
      <AgGridReact
        reactiveCustomComponents
        ref={gridRef}
        onGridReady={onGridReady}
        defaultColDef={commonColProps}
        className="mt-5 shadow"
        rowData={paymentList}
        animateRows={true}
        rowSelection="single"
        rowHeight={50}
        cacheQuickFilter={true}
        columnDefs={columnDefs}
        onRowClicked={(event: RowClickedEvent) =>
          openInvoiceDialog(event.data.invoiceID)
        }
      />
    );
  },
);

function getReadableTimestamp(timestamp: unknown): string {
  if (timestamp != null) {
    return convertToReadableTimestamp(timestamp);
  } else {
    return "";
  }
}
