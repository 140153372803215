//Libs
import React, { ChangeEvent, MouseEventHandler, useState } from "react";

//Local
import { AttachmentManager, ExistingAttachment } from "../../models/attachment";
import * as strings from "../../strings";
import LoadingSpinner from "../LoadingSpinner";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import { useMutation } from "react-query";
import { DocumentData, Timestamp } from "firebase/firestore";
import { DbRead, DbWrite } from "../../database";
import { v4 as uuidv4 } from "uuid";
import {
  getDownloadURL,
  getStorage,
  ref,
  StorageError,
  uploadBytesResumable,
} from "firebase/storage";
import { createToastMessageID } from "../../utils";
import { logger } from "../../logging";
import Gallery from "react-image-gallery";
import { useAuthStore } from "../../store/firebase-auth";
import { User } from "firebase/auth";
import { useToastMessageStore } from "../../store/toast-messages";
import { SiteKeyUserPermissions } from "../../models/site-key-user-permissions";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { WORK_RECORD_AND_TASKS_URL } from "../../urls";
import { ExistingTask } from "../../models/task";
import { useNavigate } from "react-router";

interface Props {
  //DATA
  customerID: string;
  userPermissions: SiteKeyUserPermissions | null;
  siteKey: string;
  closeDialog: () => void;
  photos: any[];
  attachments: ExistingAttachment[];
}

// #region Custom Buttons
const sharedButtonStyles =
  "absolute z-30 rounded-full bg-gray-700/70 text-gray-300 transition hover:bg-gray-800/90 focus:ring-primaryLight focus:ring-offset-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2";

function renderLeftArrow(
  onClick: MouseEventHandler<HTMLButtonElement>,
  disabled: boolean,
): JSX.Element {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${sharedButtonStyles} left-3 top-1/2 -translate-y-1/2`}
    >
      <ChevronLeftIcon aria-label="Previous Slide" className="h-6 w-6" />
    </button>
  );
}

function renderRightArrow(
  onClick: MouseEventHandler<HTMLButtonElement>,
  disabled: boolean,
): JSX.Element {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${sharedButtonStyles} right-3 top-1/2 -translate-y-1/2`}
    >
      <ChevronRightIcon aria-label="Next Slide" className="h-6 w-6" />
    </button>
  );
}

/*
function renderFullscreenBtn(
  onClick: MouseEventHandler<HTMLButtonElement>,
  isFullscreen: boolean,
): JSX.Element {
  const ariaText = !isFullscreen ? "Open Fullscreen" : "Close Fullscreen";

  return (
    <button
      onClick={onClick}
      aria-label={ariaText}
      className={`${sharedButtonStyles} bottom-3 right-3 p-1`}
    >
      {!isFullscreen ? (
        <FullscreenRoundedIcon
          aria-label="expand to fullscreen"
          sx={{ fontSize: 40 }}
        />
      ) : (
        <FullscreenExitRoundedIcon
          aria-label="exit fullscreen mode"
          sx={{ fontSize: 40 }}
        />
      )}
    </button>
  );
}
*/

export default function CustomerPhotosAndAttachments({
  photos,
  siteKey,
  customerID,
  userPermissions,
  attachments,
}: Props) {
  const storage = getStorage();
  const navigate = useNavigate();
  // #region SECTION: Upload stilt photo
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );
  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false);
  const [isUploadingAttachment, setIsUploadingAttachment] = useState(false);

  const mutateUploadStiltPhoto = useMutation(
    async (args: { data: DocumentData; docID: string }) => {
      await DbWrite.photos.add(siteKey, args.docID, args.data);
    },
  );

  function handleInputFileChange(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.id === "upload-attachment") {
      handleAddAttachment(event);
    }
    if (event.target.id === "upload-photo") {
      onUploadPhoto(event);
    }
  }

  function handleAddAttachment(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      setIsUploadingAttachment(true);
      const storage = getStorage();

      const uploadPromises = Array.from(event.target.files).map((file) => {
        return new Promise<void>((resolve, reject) => {
          const storageRef = ref(
            storage,
            `siteKeys/${siteKey}/attachments/${customerID}_${file.name}`,
          );

          const uploadTask = uploadBytesResumable(storageRef, file);

          uploadTask.on(
            "state_changed",
            null,
            (error: StorageError) => {
              switch (error.code) {
                case "storage/quota-exceeded":
                  setIsUploadingAttachment(false);
                  addToastMessage({
                    id: createToastMessageID(),
                    message: strings.UNEXPECTED_ERROR,
                    dialog: false,
                    type: "error",
                  });
                  logger.error(
                    "Quota on Cloud Storage bucket has been exceeded.",
                  );
                  break;
                default:
                  setIsUploadingAttachment(false);
                  addToastMessage({
                    id: createToastMessageID(),
                    message: strings.UNEXPECTED_ERROR,
                    dialog: false,
                    type: "error",
                  });
                  logger.error("Error while uploading attachment");
              }
              reject(error);
            },
            async () => {
              try {
                const downloadURL = await getDownloadURL(
                  uploadTask.snapshot.ref,
                );
                const docData = {
                  url: downloadURL,
                  filename: file.name,
                  authorizedCompanies: [userPermissions?.companyID],
                  customerID: customerID,
                  timestampCreated: Timestamp.now(),
                  createdBy: firebaseUser.uid,
                  deleted: false,
                };

                const attachmentDoc = AttachmentManager.parse(docData);
                await DbWrite.attachments.createForWorkRecord({
                  siteKey,
                  attachmentDoc,
                });

                resolve();
              } catch (err) {
                logger.error(
                  "couldn't get attachment download URL, or couldn't add attachment doc to database --",
                  err,
                );
                addToastMessage({
                  id: createToastMessageID(),
                  message: strings.UNEXPECTED_ERROR,
                  dialog: false,
                  type: "error",
                });
                reject(err);
              }
            },
          );
        });
      });

      Promise.all(uploadPromises)
        .then(() => {
          setIsUploadingAttachment(false);
        })
        .catch(() => {
          setIsUploadingAttachment(false);
        });
    }
  }

  /** Reduced and thumdnail versions are added via backend. */
  function handleUploadStiltPhoto(args: {
    file: File;
    setIsUploading: (val: boolean) => void;
  }): void {
    // Compose new file name.
    const uuidStr = uuidv4();
    const filetype = args.file.type.split("/")[1];
    const filename = `${uuidStr}.${filetype}`;

    const docID = DbRead.randomDocID.get();

    // Add a piece of metadata to the image. On the backend, this piece lets us
    // know that we should create a reduced and thumbnail version of the image.
    // Also need access to the work record ID for the server side code.
    const metadata = {
      customMetadata: {
        requiresCompression: "true",
        fromReactApp: "true",
        stiltPhotoID: docID,
      },
    };

    // Reference to the full path of the file.
    const storageRef = ref(storage, `siteKeys/${siteKey}/${filename}`);
    const uploadTask = uploadBytesResumable(storageRef, args.file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    const unsubscribe = uploadTask.on("state_changed", {
      // the 'next' callback could give progress reports, ie: '79% done'
      next: null,
      error: handleUploadError,
      complete: onUploadComplete,
    });

    // This only gets called if there is an error.
    function handleUploadError(error: StorageError) {
      switch (error.code) {
        case "storage/quota-exceeded":
          args.setIsUploading(false);

          addToastMessage({
            id: createToastMessageID(),
            message: strings.failedUpload("photo to storage"),
            dialog: false,
            type: "error",
          });
          logger.error("Quota on Cloud Storage bucket has been exceeded.");
          break;
        default:
          args.setIsUploading(false);

          addToastMessage({
            id: createToastMessageID(),
            message: strings.failedUpload("photo to storage"),
            dialog: false,
            type: "error",
          });
          logger.error("Error while uploading work record photo:", error);
      }

      // If there has been an error during upload, unsubscribe from uploadTask.on().
      unsubscribe();
    }

    // Upload completed successfully.
    async function onUploadComplete() {
      try {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        await afterUploadComplete_StiltPhoto(downloadURL, docID);

        addToastMessage({
          id: createToastMessageID(),
          message: strings.successfulAdd("photo"),
          dialog: false,
          type: "success",
        });

        // Unsubscribe from uploadTask.on() after upload is complete.
        unsubscribe();
      } catch (err) {
        logger.error(
          "couldn't get the resulting download URLs, or couldn't add stilt photo doc to database --",
          err,
        );
        addToastMessage({
          id: createToastMessageID(),
          message: strings.failedAdd("photo"),
          dialog: false,
          type: "error",
        });
      } finally {
        args.setIsUploading(false);
      }
    }
  }

  async function afterUploadComplete_StiltPhoto(
    photoURL: string,
    docID: string,
  ) {
    // Reduced and thumb are generated on the backend
    const docData = {
      photoURL: photoURL,
      photoURL_reduced: "",
      photoURL_thumb: "",
      timestampCreated: Timestamp.now(),
      customerID: customerID,
      createdBy: firebaseUser.uid,
    };

    await mutateUploadStiltPhoto.mutateAsync({
      data: docData,
      docID: docID,
    });
  }

  function onUploadPhoto(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      setIsUploadingPhoto(true);

      const uploadPromises = Array.from(event.target.files).map((file) => {
        return new Promise<void>((resolve) => {
          handleUploadStiltPhoto({
            file: file,
            setIsUploading: (val: boolean) => {
              setIsUploadingPhoto(val);
              resolve();
            },
          });
        });
      });

      Promise.all(uploadPromises)
        .then(() => {
          setIsUploadingPhoto(false);
        })
        .catch(() => {
          setIsUploadingPhoto(false);
        });
    }
  }

  function goToWorkRecordAndTasksPage(
    craftRecordID: ExistingTask["craftRecordID"],
  ) {
    navigate(`${WORK_RECORD_AND_TASKS_URL}/${craftRecordID}`);
  }

  return (
    <div className="flex flex-col justify-between space-y-4 p-2 text-lg md:flex-row-reverse md:space-y-0">
      <article className="min-w-[450px]">
        <label
          className="flex cursor-pointer items-center space-x-2 pb-2"
          htmlFor="upload-photo"
        >
          {!isUploadingPhoto ? (
            <>
              <span>{strings.PHOTOS}</span>
              <PlusIcon
                className="h-9 w-9 cursor-pointer rounded-full bg-gray-500 p-1.5 text-white transition-colors hover:bg-gray-600 sm:h-10 sm:w-10"
                aria-label={strings.UPLOAD_PHOTO}
              />
            </>
          ) : (
            <>
              <span>{strings.buttons.BUSY_UPLOADING}</span>
              <span className="block h-9 w-9 rounded-full bg-gray-500 p-2 sm:h-10 sm:w-10">
                <LoadingSpinner
                  marginClass="m-0"
                  colorClass="text-white"
                  sizeClass=""
                />
              </span>
            </>
          )}
        </label>
        <input
          id="upload-photo"
          type="file"
          onChange={(ev) => onUploadPhoto(ev)}
          accept="image/jpg, image/jpeg, image/png"
          className="hidden"
          multiple
        />
        {photos.length > 0 && (
          <Gallery
            additionalClass="max-w-[500px]"
            items={photos}
            // infinite={false}
            showPlayButton={false}
            onErrorImageURL="Could not load image."
            startIndex={0}
            showIndex={false}
            renderLeftNav={renderLeftArrow}
            renderRightNav={renderRightArrow}
          />
        )}
      </article>
      <article>
        <label
          className="flex cursor-pointer items-center space-x-2 pb-2"
          htmlFor="upload-attachment"
        >
          {!isUploadingAttachment ? (
            <>
              <span>{strings.ATTACHMENTS}</span>
              <PlusIcon
                className="h-9 w-9 cursor-pointer rounded-full bg-gray-500 p-1.5 text-white transition-colors hover:bg-gray-600 sm:h-10 sm:w-10"
                aria-label={strings.ADD_ATTACHMENT}
              />
            </>
          ) : (
            <>
              <span>{strings.buttons.BUSY_UPLOADING}</span>
              <span className="block h-9 w-9 rounded-full bg-gray-500 p-2 sm:h-10 sm:w-10">
                <LoadingSpinner
                  marginClass="m-0"
                  colorClass="text-white"
                  sizeClass=""
                />
              </span>
            </>
          )}
        </label>
        <input
          id="upload-attachment"
          type="file"
          onChange={(ev) => handleInputFileChange(ev)}
          className="hidden"
          multiple
        />
        {attachments.length > 0 && (
          <div className="flex w-full flex-col gap-2">
            {attachments.map((attachment, index) => (
              <>
                {/*Filename*/}
                <a
                  href={attachment.url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-600 underline"
                >
                  {attachment.filename}
                </a>
                {/* If craftRecordID, then allow user to tap on "on a job" to goToWorkRecordAndTasksPage */}
                {!attachment.craftRecordID && (
                  <p className="text-sm text-gray-600">
                    {convertToReadableTimestamp(attachment.timestampCreated)}
                  </p>
                )}
                {/*  Divider*/}
                {attachment.craftRecordID && (
                  <p className="text-sm text-gray-600">
                    {convertToReadableTimestamp(attachment.timestampCreated)}
                    <span> - </span>
                    <button
                      className="text-blue-500 underline"
                      onClick={() =>
                        goToWorkRecordAndTasksPage(attachment.craftRecordID!)
                      }
                    >
                      on a job
                    </button>
                  </p>
                )}
                {/*  Divider*/}
                {index < attachments.length - 1 && (
                  <hr className="border-gray-300" />
                )}
              </>
            ))}
          </div>
        )}
      </article>
    </div>
  );
}
