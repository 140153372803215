// Libs
import React, {
  Fragment,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import isEqual from "lodash/isEqual";

// Local
import {
  ExistingCustomer,
  ExistingCustomerWithLocations,
} from "../../models/customer";
import { SELECT_CUSTOMER } from "../../strings";
import ChipTag from "../ChipTag";
import SearchBox from "../SearchBox";
import { customerWithLocationsTableCell } from "./CustomerComponents";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";

interface Props {
  onSearch: React.FormEventHandler<HTMLInputElement>;
  customersWithLocations: ExistingCustomerWithLocations[];
  onSelect: (customer: ExistingCustomer) => void;
  addCustomerButton?: React.ReactNode;
}

export default function CustomerTable(props: Props) {
  return (
    <div className="ag-theme-alpine mt-6 flex h-[600px] flex-col">
      <Table {...props} />
    </div>
  );
}

const commonColProps = {
  resizable: true,
  filter: true,
  sortable: true,
  headerClass:
    "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase",
  minWidth: 150,
  flex: 1,
};

interface TableProps {
  onSearch: React.FormEventHandler<HTMLInputElement>;
  customersWithLocations: ExistingCustomerWithLocations[];
  onSelect: (customer: ExistingCustomer) => void;
  addCustomerButton?: React.ReactNode;
}

// Memoized table
const Table = React.memo(
  (props: TableProps) => {
    const [gridReady, setGridReady] = useState(false);
    const gridRef = useRef<AgGridReact>(null);

    const onGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    useLayoutEffect(() => {
      if (gridRef.current && gridReady) {
        gridRef.current.api?.sizeColumnsToFit();
      }
    }, [gridReady]);

    function renderTagsCell(params: ICellRendererParams): JSX.Element | null {
      const tagsList: ExistingCustomer["tags"] = params.value;
      if (Array.isArray(tagsList)) {
        return (
          <div className="-ml-2 flex h-10 items-center">
            {tagsList.map((tag) => {
              if (typeof tag === "string") {
                const key = tag + params.node.data.id;
                return <ChipTag tag={tag} key={key} />;
              } else {
                return null;
              }
            })}
          </div>
        );
      } else {
        return null;
      }
    }

    const renderCustomer = (params: ICellRendererParams): JSX.Element => {
      return customerWithLocationsTableCell(params);
    };

    const columnDefs: ColDef[] = [
      {
        headerName: "Customer",
        cellRenderer: renderCustomer,
        tooltipValueGetter: () => SELECT_CUSTOMER,
        autoHeight: true,
        minWidth: 400,
        flex: 2,
        onCellClicked: (params) => props.onSelect(params.data),
      },
      {
        headerName: "Email",
        field: "email",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        onCellClicked: (params) => props.onSelect(params.data),
      },
      {
        headerName: "Phone #",
        field: "phone",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        onCellClicked: (params) => props.onSelect(params.data),
      },
      {
        headerName: "Tags",
        field: "tags",
        cellRenderer: renderTagsCell,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        onCellClicked: (params) => props.onSelect(params.data),
      },
      {
        headerName: "Notes",
        field: "notes",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        onCellClicked: (params) => props.onSelect(params.data),
      },
    ];

    return (
      <Fragment>
        <div className="flex flex-col-reverse items-end gap-4 sm:flex-row sm:justify-between">
          <SearchBox
            onInput={props.onSearch}
            widthClasses="grow w-full sm:w-auto sm:max-w-sm"
            id="search-customer"
          />
          {props.addCustomerButton}
        </div>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          rowData={props.customersWithLocations}
          defaultColDef={commonColProps}
          columnDefs={columnDefs}
          className="mt-5 shadow"
          animateRows={true}
          rowSelection="single"
          rowHeight={50}
          cacheQuickFilter={true}
        />
      </Fragment>
    );
  },
  (previous, next) => {
    const isCustomerListTheSame = isEqual(
      previous.customersWithLocations,
      next.customersWithLocations,
    );
    // fixes a bug where it would not update the UI if click tabs between two filters where both have 0 results.
    const bothListAreEmpty =
      previous.customersWithLocations.length === 0 &&
      next.customersWithLocations.length === 0;

    return isCustomerListTheSame && !bothListAreEmpty;
  },
);
