//Libs
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { DbRead } from "../../database";

//Local
import MembershipsListPage from "./MembershipsListPage";
import {
  CUSTOMERS_URL,
  WORK_RECORD_AND_TASKS_URL,
  MEMBERSHIPS,
} from "../../urls";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import { ExistingStiltInvoice } from "../../models/invoice";
import * as strings from "../../strings";
import { useAuthStore } from "../../store/firebase-auth";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import {
  ExistingMembership,
  getReadableMembershipStatus,
  MembershipStatus,
} from "../../models/membership";
import { useMembershipTemplatesStore } from "../../store/membership-templates";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Column, Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { formatMembershipDataForExcelExport } from "../../assets/js/formatMembershipDataForExcelExport";
import { useUserDisplayNamesStore } from "../../store/user-display-names-map";
import { StyledTooltip } from "../../components/StyledTooltip";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { CheckIcon } from "@heroicons/react/20/solid";
import { Timestamp } from "firebase/firestore";
import CreateMembershipDialog from "../../components/Memberships/CreateMembershipDialog";

interface Props {
  siteKey: string;
}

// Filter options should be the full list of membership options plus a few additional options
export const membershipFilterOptions = [
  "all",
  "expiringSoon",
  "pastDue",
  "active",
  "draft",
  "awaitingPayment",
  "canceled",
  "expired",
  "suspended",
  "renewed",
] as const;
// Create the types from the TS array 'membershipFilterOptions'.
export type MembershipFilterOptionsValues =
  (typeof membershipFilterOptions)[number];

export function getReadableMembershipFilterOption(
  value: MembershipFilterOptionsValues,
): string {
  switch (value) {
    case "all":
      return "All";
    case "expiringSoon":
      return "Expiring Soon";
    case "pastDue":
      return "Past Due";
    default:
      return getReadableMembershipStatus(value);
  }
}

export default function MembershipsListContainer({ siteKey }: Props) {
  const firebaseUser = useAuthStore((state) => state.firebaseUser);
  const [siteKeyDoc, siteKeyDocLoading] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.loading,
  ]);
  const [membershipTemplateList, isLoadingMembershipTemplateList] =
    useMembershipTemplatesStore((state) => [
      state.membershipTemplates,
      state.loading,
    ]);

  const [membershipDocs, setMembershipDocs] = useState<ExistingMembership[]>(
    [],
  );
  const [filteredMembershipDocs, setFilteredMembershipDocs] = useState<
    ExistingMembership[]
  >([]);

  const [addMembershipDialogOpen, setAddMembershipDialogOpen] =
    useState<boolean>(false);
  const [isBusy, setIsBusy] = useState(false);

  const [selectedFilterOption, setSelectedFilterOption] =
    useState<MembershipFilterOptionsValues>("all");

  const userDisplayNamesMap = useUserDisplayNamesStore(
    (state) => state.userDisplayNames,
  );

  /* QUERIES */

  // Fetch the list of invoices when this component loads.
  useEffect(() => {
    function getMemberships() {
      return DbRead.memberships.subscribeAll({
        status: undefined,
        siteKey: siteKey,
        onChange: setMembershipDocs,
      });
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    const unsubscribePromise = getMemberships();
    return () => unsubscribePromise && unsubscribePromise();
  }, [siteKey]);

  // Fetch the list of invoices when this component loads.
  useEffect(() => {
    setFilteredMembershipDocs(
      membershipDocs.filter((m) => {
        switch (selectedFilterOption) {
          case "all":
            return true;
          case "expiringSoon":
            // Filter for memberships that are active and have an end date that is after today but within the next 30 days
            const today = new Date();
            const thirtyDaysFromNow = new Date(
              today.getTime() + 30 * 24 * 60 * 60 * 1000,
            );
            return (
              m.status === MembershipStatus.Active &&
              m.membershipEndDate !== null &&
              m.membershipEndDate.toMillis() >
                Timestamp.fromDate(today).toMillis() &&
              m.membershipEndDate.toMillis() <
                Timestamp.fromDate(thirtyDaysFromNow).toMillis()
            );
          case "pastDue":
            return (
              m.status === MembershipStatus.Active &&
              m.membershipEndDate !== null &&
              m.membershipEndDate.toMillis() < Timestamp.now().toMillis()
            );
          default:
            return m.status === selectedFilterOption;
        }
      }),
    );
  }, [siteKey, membershipDocs, selectedFilterOption]);

  /* HISTORY */
  const navigate = useNavigate();

  function goToCustomerPage(customerId: string) {
    navigate(`${CUSTOMERS_URL}/${customerId}`);
  }

  function goToWorkRecordAndTasksPage(
    craftRecordID: ExistingStiltInvoice["craftRecordID"],
  ) {
    navigate(`${WORK_RECORD_AND_TASKS_URL}/${craftRecordID}`);
  }

  function goToMembershipDetailsPage(membershipID: string) {
    navigate(`${MEMBERSHIPS}/${membershipID}`);
  }

  function goToPaymentPage(paymentLink: string) {
    window.open(paymentLink, "_blank");
  }

  /**
   * Build an xlsx spreadsheet.
   */
  async function saveSpreadSheet() {
    setIsBusy(true);
    const fileName = strings.MEMBERSHIPS;

    const columns = [
      {
        key: "customerName",
        header: "Customer",
      },
      {
        key: "address",
        header: "Address",
      },
      {
        key: "membershipTemplate",
        header: "Membership Template",
      },
      {
        key: "notes",
        header: "Notes",
      },
      {
        key: "membershipEndDate",
        header: "Membership Start Date",
      },
      {
        key: "membershipStartDate",
        header: "Membership End Date",
      },
      {
        key: "lastCompletedTaskDate",
        header: "Last Task",
      },
      {
        key: "nextScheduledTaskDate",
        header: "Next Task",
      },
      {
        key: "lastPaymentDate",
        header: "Last Payment",
      },
      {
        key: "nextInvoiceDate",
        header: "Next Invoice",
      },
      {
        key: "timestampCreated",
        header: "Timestamp Created",
      },
      {
        key: "timestampLastModified",
        header: "Timestamp Last Modified",
      },
    ] as Column[];

    const wb = new Workbook();
    const sheet = wb.addWorksheet(fileName);

    sheet.columns = columns;

    // Map of membershipTemplate id to membershipTemplate title
    const membershipTemplateTitlesMap: Record<string, string> = {};
    membershipTemplateList.forEach((template) => {
      membershipTemplateTitlesMap[template.id] = template.title;
    });

    const formattedData = await formatMembershipDataForExcelExport(
      filteredMembershipDocs,
      membershipTemplateTitlesMap,
      userDisplayNamesMap,
      siteKey,
    );

    sheet.addRows(formattedData);

    // Insert the user's report name as the title row (first row)
    const customTitle = `${
      userDisplayNamesMap[firebaseUser?.uid ?? ""]
    } [${fileName}]`;
    sheet.insertRow(1, [customTitle]);

    // updated the font for first row.
    sheet.getRow(1).font = { bold: true };
    sheet.getRow(2).font = { bold: true };

    try {
      // write the content using writeBuffer
      const buf = await wb.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${customTitle}.xlsx`);
      setIsBusy(false);
    } catch (error) {
      console.error("Something Went Wrong", error);
    } finally {
      // removing worksheet's instance to create new one
      wb.removeWorksheet(fileName);
    }
  }

  const createMembershipButton = (
    <BaseButtonPrimary
      type="button"
      onClick={() => {
        setAddMembershipDialogOpen(true);
      }}
      className="col-span-2 w-fit justify-self-end uppercase tracking-wider text-primary"
    >
      <AddCircleIcon fontSize="small" className="mr-2" />
      {strings.NEW_MEMBERSHIP}
    </BaseButtonPrimary>
  );

  // const invoiceDateRangePicker = (
  //   <RADateRangePicker value={range} onChange={handleDateRangeChange} />
  // );

  // Display loading clipboard while payments list loads.
  // if (paymentsListIsLoading) {
  //   return (
  //     <div className="flex h-full flex-col items-center justify-center">
  //       <LoadingClipboardAnimation />
  //     </div>
  //   );
  // }

  if (siteKeyDocLoading || isLoadingMembershipTemplateList) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  const actionButtons = (
    <div className="flex gap-4">
      <StyledTooltip title={`Export to Excel`}>
        <button
          onClick={saveSpreadSheet}
          className="rounded-md bg-gray-200 p-2"
        >
          {isBusy && <LoadingSpinner></LoadingSpinner>}
          {!isBusy && (
            <FileDownloadIcon fontSize="medium" className="shrink-0" />
          )}
        </button>
      </StyledTooltip>
    </div>
  );

  const createMembershipDialog = siteKeyDoc && addMembershipDialogOpen && (
    <CreateMembershipDialog
      assetsEnabled={siteKeyDoc.customizations.assetsEnabled === true}
      defaultEmailDuesCollectedReceipt={
        siteKeyDoc.customizations
          .sendAutomatedReceiptToCustomersForMemberships === true
      }
      isDialogOpen={addMembershipDialogOpen}
      customer={null}
      customerLocation={null}
      customerLocationOptions={null}
      locationID={null}
      siteKey={siteKey}
      closeDialog={() => setAddMembershipDialogOpen(false)}
    />
  );

  // Listbox dropdown to select membershipStatus
  const dropdownStatus = (
    <div className="w-52">
      <Listbox
        value={selectedFilterOption}
        onChange={(event) => {
          setSelectedFilterOption(event);
        }}
      >
        <div className="relative z-50">
          <Listbox.Button className="relative h-10 w-full cursor-default rounded-md border border-primary bg-white py-2 pl-3 pr-10 text-left outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primaryLight sm:text-sm">
            <span className="block truncate">
              {getReadableMembershipFilterOption(selectedFilterOption)}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-700"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {membershipFilterOptions.map((status, statusIdx) => (
                <Listbox.Option
                  key={statusIdx}
                  className={({ active, selected }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active || selected
                        ? "bg-primaryOpacity90 text-primary"
                        : "text-gray-700"
                    }`
                  }
                  value={status}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {getReadableMembershipFilterOption(status)}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );

  const membershipCountsByStatus: Record<string, number> = {};
  const membershipCountsByOther: Record<string, number> = {};
  membershipDocs
    .filter(
      (m) =>
        m.status !== MembershipStatus.Expired &&
        m.status !== MembershipStatus.Renewed,
    )
    .forEach((membership) => {
      const status = membership.status;
      if (status in membershipCountsByStatus) {
        membershipCountsByStatus[status] += 1;
      } else {
        membershipCountsByStatus[status] = 1;
      }

      // Add in the pastDue
      if (
        status === MembershipStatus.Active &&
        membership.membershipEndDate !== null &&
        membership.membershipEndDate.toMillis() < Timestamp.now().toMillis()
      ) {
        if ("pastDue" in membershipCountsByOther) {
          membershipCountsByOther["pastDue"] += 1;
        } else {
          membershipCountsByOther["pastDue"] = 1;
        }
      }

      // Add in the expiringSoon
      if (
        status === MembershipStatus.Active &&
        membership.membershipEndDate !== null
      ) {
        const today = new Date();
        const thirtyDaysFromNow = new Date(
          today.getTime() + 30 * 24 * 60 * 60 * 1000,
        );
        if (
          membership.membershipEndDate.toMillis() >
            Timestamp.fromDate(today).toMillis() &&
          membership.membershipEndDate.toMillis() <
            Timestamp.fromDate(thirtyDaysFromNow).toMillis()
        ) {
          if ("expiringSoon" in membershipCountsByOther) {
            membershipCountsByOther["expiringSoon"] += 1;
          } else {
            membershipCountsByOther["expiringSoon"] = 1;
          }
        }
      }
    });

  // Component that shows the total counts of memberships by status
  const membershipMetrics = (
    <div className="flex items-center">
      {Object.entries(membershipCountsByStatus).map(
        ([status, quantity], index) => {
          return (
            <div
              key={index}
              className={`m-1 min-w-fit rounded-full ${getMembershipTagBgColor(status)} px-2.5 py-1 text-sm font-medium capitalize text-white`}
            >
              {getReadableMembershipFilterOption(
                status as MembershipFilterOptionsValues,
              )}
              : {quantity}
            </div>
          );
        },
      )}
      <span className="px-4">/</span>
      {Object.entries(membershipCountsByOther).map(
        ([status, quantity], index) => {
          return (
            <div
              key={index}
              className={`m-1 min-w-fit rounded-full ${getMembershipTagBgColor(status)} px-2.5 py-1 text-sm font-medium capitalize text-white`}
            >
              {getReadableMembershipFilterOption(
                status as MembershipFilterOptionsValues,
              )}
              : {quantity}
            </div>
          );
        },
      )}
    </div>
  );

  return (
    <Fragment>
      <MembershipsListPage
        membershipTemplates={membershipTemplateList}
        memberships={filteredMembershipDocs}
        currency={siteKeyDoc?.customizations.accounting?.currency ?? "USD"}
        CreateMembershipButton={createMembershipButton}
        goToCustomerPage={goToCustomerPage}
        goToWorkRecordAndTasksPage={goToWorkRecordAndTasksPage}
        goToPayment={goToPaymentPage}
        goToMembershipDetailsPage={goToMembershipDetailsPage}
      >
        {{
          ActionButtons: actionButtons,
          StatusDropdown: dropdownStatus,
          MembershipMetrics: membershipMetrics,
          // DropdownSelectionInvoiceList: dropdownSelectionInvoiceList,
          // InvoiceDetailsBox: invoiceDetailsBox,
          // InvoiceDateRangePicker: invoiceDateRangePicker,
        }}
      </MembershipsListPage>
      {/*{handlePaymentLinkDialog}*/}
      {/*{manualPaymentDialog}*/}
      {createMembershipDialog}
    </Fragment>
  );
}

export function getMembershipTagBgColor(value: string) {
  // Values are either the membershipStatus OR membershipFilterOptions (which is a superset of membershipStatus)
  let customBg = "bg-primary text-primaryButtonText";
  switch (value) {
    case "pastDue":
      customBg = "bg-red-700 text-white";
      break;
    case "expiringSoon":
      customBg = "bg-yellow-500 text-white";
      break;
    case "draft":
      customBg = "bg-gray-400 text-white";
      break;
    case "active":
      customBg = "bg-greenPass text-white";
      break;
    case "awaitingPayment":
      customBg = "bg-orangeError text-white";
      break;
    case "canceled":
      customBg = "bg-amber-800 text-white";
      break;
  }
  return customBg;
}
