import React from "react";

interface Props {
  onInput: React.FormEventHandler<HTMLInputElement>;
  /** If omitted, width classes are: `w-full sm:max-w-sm` */
  widthClasses?: string;
  /** Defaults to "Search..." */
  placeholderText?: string;
  /** Defaults to "filter-text-box" */
  id?: string;
}

const SearchBox = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  return (
    <input
      ref={ref}
      autoComplete="off"
      className={`h-10 shrink-0 overflow-hidden rounded-md border border-primary px-2.5
        py-1.5 text-base outline-none placeholder:text-gray-500
        focus:border-primaryLight focus:ring-1 focus:ring-primaryLight ${
          props.widthClasses ?? "w-full sm:max-w-sm"
        }`}
      type="text"
      id={props.id ?? "filter-text-box"}
      placeholder={props.placeholderText ?? "Search..."}
      onInput={props.onInput}
      tabIndex={0}
    />
  );
});

export default SearchBox;
