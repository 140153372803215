//Libs

//Local
import currencyFormatter, { formatAsPercent } from "../../currency";
import {
  ExistingEstimateItem,
  TemporaryEstimateItem,
} from "../../models/estimate-item";
import { ExistingEstimate } from "../../models/estimate";
import { ExistingCustomerLocation } from "../../models/customer-location";
import { ExistingCustomer } from "../../models/customer";
import { StiltLineItemFormData } from "../../models/stilt-payment";
import { StiltLineItem } from "../../models/invoice";

export function getEstimateStatus(
  estimateStatus: ExistingEstimate["status"],
): string {
  let readableStatus: string = "";
  switch (estimateStatus) {
    case "draft":
      readableStatus = "Draft";
      break;
    case "awaitingApproval":
      readableStatus = "Awaiting Approval";
      break;
    case "approved":
      readableStatus = "Estimate Approved";
      break;
    case "onHold":
      readableStatus = "On Hold";
      break;
    case "rejected":
      readableStatus = "Estimate Rejected";
      break;
    case "locked":
      readableStatus = "Estimate Locked";
      break;
  }
  return readableStatus;
}

function getLineItemTotal(args: {
  quantity: number;
  unitPrice: number;
  discountable: boolean;
  discount: number;
  taxable: boolean;
  currency: string;
  customer: ExistingCustomer | null;
  globalDiscount: number;
  totalTaxRate: number;
}) {
  let taxAmountItem = 0;
  let taxAmountItemGST;
  let taxAmountItemPST;
  // let discountAmountItem = 0;
  const subtotalItemWithoutDiscount = args.quantity * args.unitPrice;
  let subtotalItemWithLineDiscount = subtotalItemWithoutDiscount;
  let subtotalItemWithLineAndGlobalDiscount = subtotalItemWithLineDiscount;

  if (args.discountable) {
    // apply any line-level item discount
    subtotalItemWithLineDiscount =
      subtotalItemWithoutDiscount * (1 - (args.discount ?? 0) / 100);
    // apply any global discount
    subtotalItemWithLineAndGlobalDiscount =
      subtotalItemWithLineDiscount * (1 - (args.globalDiscount ?? 0) / 100);
  }

  if (args.currency === "CAD" || args.currency === "cad") {
    // For Canada, need to do GST and PST
    // All goods and services will get the GST
    // Only taxable items will get the PST (provincial sales tax)
    const pst = 0.07;
    const gst = 0.05;

    // First calculate tax with PST (on taxable items)
    if (args.taxable && args.customer?.isTaxExemptPST !== true) {
      // Calculate taxable amount for this line item, including any globalDiscount
      taxAmountItem = subtotalItemWithLineAndGlobalDiscount * pst;
      taxAmountItemPST = subtotalItemWithLineAndGlobalDiscount * pst;
    }

    // Then calculate tax with GST (on all items)
    if (args.customer?.isTaxExemptGST !== true) {
      // Also don't forget the globalDiscount...
      taxAmountItem += subtotalItemWithLineAndGlobalDiscount * gst;
      taxAmountItemGST = subtotalItemWithLineAndGlobalDiscount * gst;
    }
  } else {
    // For US
    if (args.taxable && args.customer?.isTaxExempt !== true) {
      // Calculate taxable amount for this line item
      taxAmountItem = subtotalItemWithLineAndGlobalDiscount * args.totalTaxRate;
    }
  }

  return {
    taxAmountItem,
    subtotalItemWithLineDiscount,
    subtotalItemWithLineAndGlobalDiscount,
    taxAmountItemPST,
    taxAmountItemGST,
  };
}

export function getEstimateLineItemTotal(
  item: ExistingEstimateItem | TemporaryEstimateItem,
  currency: string,
  customer: ExistingCustomer | null,
  globalDiscount: number,
  totalTaxRate: number,
) {
  // DO NOT EDIT THIS FUNCTION WITHOUT ALSO EDITING THE CORRESPONDING FUNCTIONS
  // IN THE FLUTTER AND WEB REPOSITORIES.
  return getLineItemTotal({
    quantity: item.quantity,
    unitPrice: item.unitPrice,
    discountable: item.discountable,
    discount: item.discount ?? 0,
    taxable: item.taxable,
    currency: currency,
    customer: customer,
    globalDiscount: globalDiscount,
    totalTaxRate: totalTaxRate,
  });
}

export function getInvoiceLineItemTotal(args: {
  item: StiltLineItemFormData | StiltLineItem;
  discountable: boolean;
  taxable: boolean;
  currency: string;
  customer: ExistingCustomer | null;
  globalDiscount: number;
  totalTaxRate: number;
}) {
  // DO NOT EDIT THIS FUNCTION WITHOUT ALSO EDITING THE CORRESPONDING FUNCTIONS
  // IN THE FLUTTER AND WEB REPOSITORIES.
  return getLineItemTotal({
    quantity: args.item.quantity,
    unitPrice: args.item.unitPrice,
    discountable: args.discountable,
    discount: args.item.discount ?? 0,
    taxable: args.taxable,
    currency: args.currency,
    customer: args.customer,
    globalDiscount: args.globalDiscount,
    totalTaxRate: args.totalTaxRate,
  });
}

export function getInvoiceTotals(
  invoiceItemList: StiltLineItemFormData[] | StiltLineItem[],
  customer: ExistingCustomer | null,
  customerLocation: ExistingCustomerLocation | null,
  globalDiscount: number,
  currency: string,
) {
  // DO NOT EDIT THIS FUNCTION WITHOUT ALSO EDITING THE CORRESPONDING FUNCTIONS
  // IN THE CF AND WEB REPOSITORIES.

  const totalTaxRate = customerLocation?.totalTaxRate ?? 0;
  let subTotalWithLineDiscount = 0;
  let subTotalWithLineAndGlobalDiscount = 0;
  let totalTaxAmount = 0;

  invoiceItemList.forEach((item) => {
    const {
      taxAmountItem,
      subtotalItemWithLineDiscount,
      subtotalItemWithLineAndGlobalDiscount,
      taxAmountItemPST,
      taxAmountItemGST,
    } = getInvoiceLineItemTotal({
      item: item,
      currency: currency,
      totalTaxRate: totalTaxRate,
      globalDiscount: globalDiscount,
      customer: customer,
      discountable: item?.discountable ?? true,
      taxable: item?.taxable ?? true,
    });

    totalTaxAmount += taxAmountItem;
    subTotalWithLineDiscount += subtotalItemWithLineDiscount;
    subTotalWithLineAndGlobalDiscount += subtotalItemWithLineAndGlobalDiscount;
  });

  const totalEstimate = subTotalWithLineAndGlobalDiscount + totalTaxAmount;

  return {
    subtotal: currencyFormatter(subTotalWithLineDiscount, currency),
    subtotalWithDiscount: currencyFormatter(
      subTotalWithLineAndGlobalDiscount,
      currency,
    ),
    discount: globalDiscount,
    totalEstimate: currencyFormatter(totalEstimate, currency),
    totalTaxAmount: currencyFormatter(totalTaxAmount, currency),
  };
}

export function getEstimateTotals(
  estimateItemList: ExistingEstimateItem[] | TemporaryEstimateItem[],
  customer: ExistingCustomer | null,
  customerLocation: ExistingCustomerLocation | null,
  globalDiscount: number,
  currency: string,
) {
  // DO NOT EDIT THIS FUNCTION WITHOUT ALSO EDITING THE CORRESPONDING FUNCTIONS
  // IN THE CF AND WEB REPOSITORIES.

  const totalTaxRate = customerLocation?.totalTaxRate ?? 0;
  let subTotalWithLineDiscount = 0;
  let subTotalWithLineAndGlobalDiscount = 0;
  let totalTaxAmount = 0;
  let totalTaxAmountPST: number | undefined;
  let totalTaxAmountGST: number | undefined;

  estimateItemList.forEach((item) => {
    const {
      taxAmountItem,
      subtotalItemWithLineDiscount,
      subtotalItemWithLineAndGlobalDiscount,
      taxAmountItemPST,
      taxAmountItemGST,
    } = getEstimateLineItemTotal(
      item,
      currency,
      customer,
      globalDiscount,
      totalTaxRate,
    );

    totalTaxAmount += taxAmountItem;
    subTotalWithLineDiscount += subtotalItemWithLineDiscount;
    subTotalWithLineAndGlobalDiscount += subtotalItemWithLineAndGlobalDiscount;
    if (taxAmountItemPST) {
      if (!totalTaxAmountPST) {
        totalTaxAmountPST = 0;
      }
      totalTaxAmountPST += taxAmountItemPST;
    }
    if (taxAmountItemGST) {
      if (!totalTaxAmountGST) {
        totalTaxAmountGST = 0;
      }
      totalTaxAmountGST += taxAmountItemGST;
    }
  });

  const totalEstimate = subTotalWithLineAndGlobalDiscount + totalTaxAmount;

  return {
    subtotal: currencyFormatter(subTotalWithLineDiscount, currency),
    subtotalWithDiscount: currencyFormatter(
      subTotalWithLineAndGlobalDiscount,
      currency,
    ),
    discount: globalDiscount,
    totalEstimate: currencyFormatter(totalEstimate, currency),
    totalTaxAmount: currencyFormatter(totalTaxAmount, currency),
    taxRate: formatAsPercent(customerLocation?.totalTaxRate ?? 0),
    totalTaxAmountPST: totalTaxAmountPST
      ? currencyFormatter(totalTaxAmountPST, currency)
      : undefined,
    totalTaxAmountGST: totalTaxAmountGST
      ? currencyFormatter(totalTaxAmountGST, currency)
      : undefined,
  };
}

export function getTemporaryItemTotal(
  quantity: number | "",
  unitPrice: number | "",
  discountable: boolean,
  discount: number | "",
): number {
  const unitPriceNumber = unitPrice === "" ? 0 : unitPrice;
  const quantityNumber = quantity === "" ? 0 : quantity;
  const discountNumber = discount === "" ? 0 : discount;

  const totalItemPrice: number = quantityNumber * unitPriceNumber;

  let discountedTotal: number | null = null;
  if (discountable === true && discount !== 0 && discount != null) {
    discountedTotal = totalItemPrice - (totalItemPrice * discountNumber) / 100;
  }
  return discountedTotal != null ? discountedTotal : totalItemPrice;
}
