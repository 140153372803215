//local
// import BaseInputCheckbox from "./components/BaseInputCheckbox";
// import BaseInputNumberInteger from "./components/BaseInputNumberInteger";
// import BaseInputNumberFloat from "./components/BaseInputNumberFloat";
// import BaseInputText from "./components/BaseInputText";
// import BaseInputSelect from "./components/BaseInputSelect";
// import BaseInputDateTime from "./components/BaseInputDateTime";

export const clientUIFields = [
  "boolean",
  "float",
  "integer",
  "string",
  "selection",
  "timestamp",
] as const;
export type ClientUIFields = (typeof clientUIFields)[number];

// export const dynamicFields: Record<ClientUIFields, React.ComponentType<any>> = {
//   boolean: BaseInputCheckbox,
//   float: BaseInputNumberFloat,
//   integer: BaseInputNumberInteger,
//   string: BaseInputText,
//   selection: BaseInputSelect,
//   timestamp: BaseInputDateTime,
// };
