// Libs
import React, { Fragment } from "react";

// Local
import LoadingSpinner from "./LoadingSpinner";

type Ref = HTMLButtonElement;
type Props = {
  className?: string;
  isBusy?: boolean;
  /** default is "Processing..." */
  busyText?: string;
  /** default is text-sm */
  fontSize?: string;
  /** default is h-10 */
  height?: string;
  /** if provided, `busyText` is ignored. */
  overrideLoadingElement?: React.ReactNode;
  primaryColor: string;
  secondaryColor: string;
} & React.ComponentPropsWithRef<"button">;

const BaseButton = React.forwardRef<Ref, Props>(
  (
    {
      isBusy = false,
      busyText = "Processing...",
      className,
      overrideLoadingElement,
      primaryColor,
      secondaryColor,
      ...props
    },
    ref,
  ): JSX.Element => {
    const LoadingElement: React.ReactNode = overrideLoadingElement ?? (
      <Fragment>
        <LoadingSpinner />
        {busyText}
      </Fragment>
    );

    // Calculate darker shade for hover
    const darkenColor = (hex: string) => {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      const darkenAmount = 0.15;
      
      const darkerR = Math.floor(r * (1 - darkenAmount));
      const darkerG = Math.floor(g * (1 - darkenAmount));
      const darkerB = Math.floor(b * (1 - darkenAmount));
      
      return `#${darkerR.toString(16).padStart(2, '0')}${darkerG.toString(16).padStart(2, '0')}${darkerB.toString(16).padStart(2, '0')}`;
    };

    return (
      <button
        {...props}
        ref={ref}
        disabled={isBusy || props.disabled}
        className={`${className ?? ""} ${
          props.disabled && !isBusy
            ? "bg-gray-100 text-gray-500"
            : "hover:brightness-90 transition-all"
        } ${props.fontSize ? props.fontSize : "text-sm"} 
  ${props.height ? props.height : "h-10"} 
        inline-flex items-center justify-center rounded-md px-4 py-2 shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2`}
        style={{
          ...props.style,
          backgroundColor: props.disabled && !isBusy ? undefined : primaryColor,
          color: props.disabled && !isBusy ? undefined : secondaryColor,
          "--tw-ring-color": `${primaryColor}80`,
        } as React.CSSProperties}
      >
        {isBusy ? LoadingElement : props.children}
      </button>
    );
  },
);

export default BaseButton;
