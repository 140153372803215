//Libs
import React, {
  memo,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  GetQuickFilterTextParams,
  GridReadyEvent,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterFunc,
  ValueGetterParams,
} from "ag-grid-community";
import isEqual from "lodash/isEqual";
import { Timestamp } from "@firebase/firestore";
import {
  CalendarDate,
  ZonedDateTime,
  CalendarDateTime,
} from "@internationalized/date";
import { RangeValue } from "@react-types/shared";

//Local
import {
  ExistingStiltInvoice,
  getReadableStiltInvoiceStatus,
} from "../../models/invoice";
import * as strings from "../../strings";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { StyledTooltip } from "../../components/StyledTooltip";
import { ButtonViewCustomer } from "../../components/ButtonViewCustomer";
import { ListIconWithRef } from "../../components/TaskViewButton";
import { ReceiptIconWithRef } from "../../components/ReceiptViewButton";
import { PaymentIconWithSpinner } from "../../components/PaymentButton";
import { getTimeDifferenceInDays } from "../../utils/getTimeDifferenceInDays";
import EmailButtonWithSpinner from "../../components/EmailButtonWithSpinner";
import { PDFIconWithSpinner } from "../../components/PDFButton";
import currencyFormatter from "../../currency";
import SearchBox from "../../components/SearchBox";
import InvoiceStatusCustomFilter from "../../components/tables/InvoiceStatusCustomFilter";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import SiteKeyLocationCustomFilter from "../../components/tables/SiteKeyLocationCustomFilter";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import InvoiceActionDropdown from "../../components/Invoices/InvoiceActionDropdown";
import UsersCustomFilter from "../../components/tables/UsersCustomFilter";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";

export const invoiceActionTypes = [
  "generatePDF",
  "sendEmailInvoice",
  "convertDraftToPending",
  "convertZeroAmountInvoicesToPaid",
  "applyManualPayment",
  "payViaCard",
] as const;
export type InvoiceActionTypes = (typeof invoiceActionTypes)[number];

interface Props {
  /* DATA */
  invoiceList: ExistingStiltInvoice[];
  dateRange: RangeValue<CalendarDate | ZonedDateTime | CalendarDateTime>;
  currency: string;
  isGetInvoices: boolean;
  isGeneratingCustomerStatements: boolean;
  actionsLoading: boolean;
  siteKeyLocationList: ExistingSiteKeyLocation[];
  userDisplayNamesMap: Record<string, string>;
  getLocationTitle: (id: string) => string;
  /* FUNCTIONS */
  goToCustomerPage: (customerID: ExistingStiltInvoice["customerID"]) => void;
  goToViewEstimate: (
    estimateID: string,
    customerID: string,
    customerLocationID: string,
    invoiceID: string,
  ) => Promise<void>;
  goToWorkRecordAndTasksPage: (
    craftRecordID: ExistingStiltInvoice["craftRecordID"],
  ) => void;
  openHandlePaymentDialog: (invoiceID: ExistingStiltInvoice["id"]) => void;
  emailReceipt: (invoiceID: ExistingStiltInvoice["id"]) => Promise<void>;
  getPDF: (invoiceID: ExistingStiltInvoice["id"]) => Promise<void>;
  generateCustomerStatements: () => Promise<void>;
  invoiceActionSelected: (
    actionType: InvoiceActionTypes,
    invoiceIDs: ExistingStiltInvoice[],
  ) => void;
  openInvoiceDialog: (invoiceID: string) => void;
  /* CHILDREN */
  children: {
    DropdownSelectionInvoiceList: React.ReactNode;
    InvoiceDetailsBox: React.ReactNode;
    InvoiceDateRangePicker: React.ReactNode;
    BulkManualPaymentDialog: React.ReactNode;
    MultiPayCreditCardDialog: React.ReactNode;
  };
}

export default function InvoiceListPage(props: Props) {
  const getDaysOverdue = (params: ICellRendererParams): string => {
    if (params.data.amountDue === 0 || params.data.status === "paid") {
      return "";
    }
    if (params.value == null) return "0";
    if (!(params.value instanceof Timestamp)) return "unknown";

    const diff = getTimeDifferenceInDays({
      startDate: params.value,
      endDate: Timestamp.now(),
    });
    return `${diff}`;
  };

  const renderStatus = (params: ICellRendererParams): JSX.Element => {
    let customBg = "bg-primary text-primaryButtonText";
    switch (params.data.status) {
      case "paid":
        customBg = "bg-greenPass text-white";
        break;
      case "partiallyPaid":
        customBg = "bg-orangeError text-white";
        break;
      case "canceled":
        customBg = "bg-gray-200 text-black";
        break;
      case "draft":
        customBg = "bg-gray-200 text-black";
        break;
    }
    return (
      <div className="flex items-center">
        <div
          className={`m-1 rounded-full px-2.5 pb-1 pt-0.5 text-xs font-medium ${customBg} capitalize`}
        >
          {getReadableStiltInvoiceStatus(params.data.status)}
        </div>
      </div>
    );
  };

  // const renderTotalAmount = (params: ICellRendererParams): JSX.Element => {
  //   return (
  //     <div className={params.data.status === "canceled" ? "line-through" : ""}>
  //       {currencyFormatter(params.data.totalAmount, props.currency)}
  //     </div>
  //   );
  // };
  // const renderAmountDue = (params: ICellRendererParams): JSX.Element => {
  //   return (
  //     <div className={params.data.status === "canceled" ? "line-through" : ""}>
  //       {currencyFormatter(params.data.amountDue, props.currency)}
  //     </div>
  //   );
  // };
  const renderAmount = (params: ICellRendererParams): JSX.Element => {
    return (
      <div className={params.data.status === "canceled" ? "line-through" : ""}>
        {currencyFormatter(params.value, props.currency)}
      </div>
    );
  };

  const renderIconCell = useCallback(
    function (params: ICellRendererParams) {
      const receiptIcon = (
        <StyledTooltip title="Send Receipt">
          <EmailButtonWithSpinner
            onClick={async () => await props.emailReceipt(params.data.id)}
          />
        </StyledTooltip>
      );

      return (
        <div className="flex items-center">
          {params.data.craftRecordID !== null ? (
            <StyledTooltip title="View Job">
              <ListIconWithRef
                ref={(ref) => {
                  if (!ref) return;

                  ref.onclick = (e) => {
                    e.stopPropagation();
                    props.goToWorkRecordAndTasksPage(params.data.craftRecordID);
                  };
                }}
              />
            </StyledTooltip>
          ) : null}
          <StyledTooltip title="View Customer">
            <ButtonViewCustomer
              ref={(ref) => {
                if (!ref) return;

                ref.onclick = (e) => {
                  e.stopPropagation();
                  props.goToCustomerPage(params.data.customerID);
                };
              }}
            />
          </StyledTooltip>
          <StyledTooltip title="View Estimate">
            <ReceiptIconWithRef
              ref={(ref) => {
                if (!ref) return;

                ref.onclick = (e) => {
                  e.stopPropagation();
                  props.goToViewEstimate(
                    params.data.estimateID,
                    params.data.customerID,
                    params.data.customerLocationID,
                    params.data.id,
                  );
                };
              }}
            />
          </StyledTooltip>
          {params.data.status !== "paid" &&
          params.data.status !== "canceled" ? (
            <StyledTooltip title="Payment Options">
              <PaymentIconWithSpinner
                onCreate={async () =>
                  props.openHandlePaymentDialog(params.data.id)
                }
              />
            </StyledTooltip>
          ) : null}
          {params.data.status === "paid" ? receiptIcon : null}
          <StyledTooltip title="Download PDF">
            <PDFIconWithSpinner
              onCreate={async () => props.getPDF(params.data.id)}
            />
          </StyledTooltip>
        </div>
      );
    },
    [props],
  );

  const [gridReady, setGridReady] = useState(false);
  const gridRef = useRef<AgGridReact>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  function rowSelectionChanged() {
    setSelectedRows(getSelectedRowData());
  }

  const onGridReady = useCallback(() => {
    setGridReady(true);
  }, []);

  useLayoutEffect(() => {
    if (gridRef.current && gridReady) {
      gridRef.current.api?.sizeColumnsToFit();
    }
  }, [gridReady]);

  function getSelectedRowData() {
    const selectedData = gridRef.current?.api?.getSelectedRows();
    if (selectedData == null) return [];
    return selectedData;
  }

  const onFilterTextBoxChanged = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.setGridOption(
        "quickFilterText",
        (document.getElementById("filter-text-box") as HTMLInputElement).value,
      );
    }
  }, []);

  const actionDropdownButton = (
    <InvoiceActionDropdown
      actionsLoading={props.actionsLoading}
      invoiceActionSelected={props.invoiceActionSelected}
      selectedRows={selectedRows}
    />
  );

  const getSiteKeyLocation = (params: ICellRendererParams): string => {
    return props.getLocationTitle(params.data.locationID);
  };

  const renderUserName: ValueGetterFunc = useCallback(
    function (params: ValueGetterParams) {
      const displayName = props.userDisplayNamesMap[params.data.createdBy];
      if (typeof displayName !== "string") return "";
      return displayName;
    },
    [props.userDisplayNamesMap],
  );

  return (
    <div className="space-y-6">
      {props.children.BulkManualPaymentDialog}
      {props.children.MultiPayCreditCardDialog}
      <div className="flex flex-row items-center justify-between">
        <h1 className="text-5xl font-semibold text-primary">
          {strings.INVOICES}
        </h1>
        <BaseButtonSecondary
          isBusy={props.isGeneratingCustomerStatements}
          onClick={() => props.generateCustomerStatements()}
          children={strings.GENERATE_CUSTOMER_STATEMENTS}
        />
      </div>

      {props.children.InvoiceDetailsBox}
      <div className="ag-theme-alpine h-[1000px] max-h-[58vh] pb-6 lg:max-h-[62vh]">
        <div className="flex flex-col-reverse gap-6 md:flex-row md:items-end md:justify-between">
          <span className="flex gap-4 md:grow">
            {actionDropdownButton}
            <SearchBox
              onInput={onFilterTextBoxChanged}
              widthClasses="shrink w-full max-w-sm"
            />
          </span>
          <span className="flex flex-col items-end gap-4 self-end md:flex-col-reverse lg:flex-row">
            {props.children.InvoiceDateRangePicker}
            {props.children.DropdownSelectionInvoiceList}
          </span>
        </div>
        {props.isGetInvoices === true ? (
          <div className="flex h-full flex-col items-center justify-center">
            <LoadingClipboardAnimation />
          </div>
        ) : (
          <InvoiceListTable
            invoiceList={props.invoiceList}
            dateRange={props.dateRange}
            renderAmount={renderAmount}
            renderIconCell={renderIconCell}
            renderStatus={renderStatus}
            getDaysOverdue={getDaysOverdue}
            handleOpenViewPaymentDialog={props.openInvoiceDialog}
            gridRef={gridRef}
            onGridReady={onGridReady}
            rowSelectionChanged={rowSelectionChanged}
            siteKeyLocationList={props.siteKeyLocationList}
            getSiteKeyLocation={getSiteKeyLocation}
            renderUserName={renderUserName}
          />
        )}
      </div>
    </div>
  );
}

const commonColProps = {
  minWidth: 150,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass:
    "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase",
};

interface InvoiceListTableProps {
  invoiceList: Props["invoiceList"];
  dateRange: Props["dateRange"];
  siteKeyLocationList: Props["siteKeyLocationList"];
  renderAmount: (params: ICellRendererParams) => JSX.Element;
  renderIconCell: (params: ICellRendererParams) => JSX.Element;
  renderStatus: (params: ICellRendererParams) => JSX.Element;
  getSiteKeyLocation: (params: ICellRendererParams) => string;
  getDaysOverdue: (params: ICellRendererParams) => string;
  renderUserName: ValueGetterFunc<any, any>;
  handleOpenViewPaymentDialog: Props["openInvoiceDialog"];
  gridRef: React.MutableRefObject<any>;
  onGridReady: (event: GridReadyEvent) => void;
  rowSelectionChanged: (event: GridReadyEvent) => void;
}

const InvoiceListTable = memo(
  ({
    invoiceList,
    renderAmount,
    renderIconCell,
    renderStatus,
    getDaysOverdue,
    handleOpenViewPaymentDialog,
    renderUserName,
    gridRef,
    onGridReady,
    rowSelectionChanged,
    siteKeyLocationList,
    getSiteKeyLocation,
  }: InvoiceListTableProps) => {
    const columnDefs: ColDef[] = [
      {
        headerName: "Invoice #",
        field: "invoiceNumber",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        onCellClicked: (ev) => handleOpenViewPaymentDialog(ev.data.id),
      },
      {
        headerName: "Customer",
        field: "name",
        onCellClicked: (ev) => handleOpenViewPaymentDialog(ev.data.id),
      },
      {
        field: "amountDue",
        headerName: "Amount Due",
        cellRenderer: renderAmount,
        onCellClicked: (ev) => handleOpenViewPaymentDialog(ev.data.id),
      },
      {
        headerName: "Total Amount",
        field: "totalAmount",
        cellRenderer: renderAmount,
        onCellClicked: (ev) => handleOpenViewPaymentDialog(ev.data.id),
      },
      {
        headerName: "Status",
        field: "status",
        filter: InvoiceStatusCustomFilter,
        cellRenderer: renderStatus,
        cellStyle: {
          height: "100%",
          display: "flex",
          alignItems: "center",
        },
        onCellClicked: (ev) => handleOpenViewPaymentDialog(ev.data.id),
      },
      {
        headerName: "Issue Date",
        field: "issueDate",
        cellRenderer: (params: ICellRendererParams) =>
          convertToReadableTimestamp(params.value),
        getQuickFilterText: (params: GetQuickFilterTextParams) =>
          convertToReadableTimestamp(params.data?.issueDate),
        onCellClicked: (ev) => handleOpenViewPaymentDialog(ev.data.id),
      },
      {
        headerName: "PO Number",
        field: "poNumber",
        valueFormatter: (params: ValueFormatterParams) => params.value,
        onCellClicked: (ev) => handleOpenViewPaymentDialog(ev.data.id),
      },
      {
        headerName: "Days Overdue",
        field: "dueDate",
        cellRenderer: getDaysOverdue,
        onCellClicked: (ev) => handleOpenViewPaymentDialog(ev.data.id),
      },
      {
        field: "timestampSentToCustomer",
        headerName: "Sent to Customer",
        cellRenderer: (params: ICellRendererParams) =>
          getReadableTimestamp(params.value),
        getQuickFilterText: (params: GetQuickFilterTextParams) =>
          getReadableTimestamp(params.data?.timestampSentToCustomer),
        onCellClicked: (ev) => handleOpenViewPaymentDialog(ev.data.id),
      },
      {
        field: "createdBy",
        headerName: "Created By",
        filter: UsersCustomFilter,
        cellRenderer: renderUserName,
        onCellClicked: (ev) => handleOpenViewPaymentDialog(ev.data.id),
      },
      {
        cellRenderer: renderIconCell,
        suppressMovable: true,
        suppressHeaderMenuButton: true,
        sortable: false,
        minWidth: 250,
        flex: 0,
        cellStyle: {
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        },
      },
    ];

    if (siteKeyLocationList.length > 1) {
      columnDefs.splice(columnDefs.length - 1, 0, {
        field: "locationID",
        headerName: "Site Location",
        cellRenderer: getSiteKeyLocation,
        filter: SiteKeyLocationCustomFilter,
      });
    }

    return (
      <AgGridReact
        reactiveCustomComponents
        ref={gridRef}
        onGridReady={onGridReady}
        defaultColDef={commonColProps}
        className="mt-5 shadow"
        rowData={invoiceList}
        animateRows={true}
        suppressRowClickSelection={true}
        rowSelection="multiple"
        rowHeight={50}
        cacheQuickFilter={true}
        columnDefs={columnDefs}
        onSelectionChanged={(event) => {
          rowSelectionChanged(event);
        }}
      />
    );
  },
  (previous, next) => {
    const isInvoiceListTheSame = isEqual(
      previous.invoiceList,
      next.invoiceList,
    );

    return isInvoiceListTheSame;
  },
);

function getReadableTimestamp(timestamp: unknown): string {
  if (timestamp != null) {
    return convertToReadableTimestamp(timestamp);
  } else {
    return "";
  }
}
