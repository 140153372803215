// Libs
import { XMarkIcon } from "@heroicons/react/24/solid";

type DismissibleProps = {
  dismissible?: true;
  onDismiss: () => void;
};
type NotDismissibleProps = {
  dismissible?: false;
};

type Props = {
  type: "success" | "warning" | "error" | "info";
  dismissible?: boolean;
  /** default is text-center */
  overrideTextAlignment?: string;
  /** default allows the parent element to control alignment with items-center */
  overrideIconAlignment?: string;
  children: {
    message: React.ReactNode;
    icon?: React.ReactNode;
    button?: React.ReactNode;
  };
} & (DismissibleProps | NotDismissibleProps);

/**
 * If dismissible is true, you have to pass in an onClick handler.
 * types: success, warning, error, info
 */
export default function StyledMessage(props: Props): JSX.Element {
  const typeStyles: Record<Props["type"], string> = {
    success: "bg-green-100 text-green-800",
    warning: "bg-orange-100 text-orange-800",
    error: "bg-red-100 text-red-900",
    info: "bg-blue-50 text-blue-700",
  };

  const iconColors = {
    success: "text-green-800",
    warning: "text-orange-800",
    error: "text-red-900",
    info: "text-blue-700",
  };

  return (
    <div className={`${typeStyles[props.type]} max-w-fit rounded-md px-4 py-2`}>
      <div
        className={`flex items-center justify-between ${props.overrideTextAlignment ?? "text-center"}`}
      >
        <span className="flex items-center gap-2">
          {props.children.icon && (
            <span
              className={`${props.overrideIconAlignment ?? ""} shrink-0 ${iconColors[props.type]}`}
            >
              {props.children.icon}
            </span>
          )}
          {props.children.message}
        </span>
        {props.dismissible && (
          <span className={`${props.overrideIconAlignment ?? ""} shrink-0`}>
            <XMarkIcon
              aria-label="dismiss message"
              className="ml-3 h-5 w-5 cursor-pointer"
              onClick={props.onDismiss}
            />
          </span>
        )}
      </div>
      {props.children.button && (
        <span className="ml-5 mt-1 block">{props.children.button}</span>
      )}
    </div>
  );
}
