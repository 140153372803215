import React from "react";
import PersonIcon from "@mui/icons-material/Person";

const styles = {
  display: "block",
  height: "38px",
  width: "38px",
  cursor: "pointer",
  padding: "6px",
};

/** Person icon, wrapped in button with ref */
export const ButtonViewCustomer = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithRef<"button">
>((props, ref) => {
  return (
    <button
      ref={ref}
      {...props}
      className="rounded-full text-gray-700 hover:bg-primaryOpacity90"
    >
      <PersonIcon aria-label="view customer button" style={styles} />
    </button>
  );
});
