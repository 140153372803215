//Libs
import {
  Fragment,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useMutation, useQueries, useQuery } from "react-query";
import { DocumentData, Timestamp } from "firebase/firestore";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";
import AddIcon from "@mui/icons-material/Add";
import { User } from "firebase/auth";
import EventIcon from "@mui/icons-material/Event";
import { useNavigate } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/solid";
import {
  DayPilot,
  DayPilotScheduler,
  DayPilotCalendar,
  CalendarProps,
  SchedulerProps,
} from "daypilot-pro-react";
import {
  ArrowLeft,
  ArrowRight,
  PivotTableChart,
  SettingsSuggest,
} from "@mui/icons-material";
import TodayIcon from "@mui/icons-material/Today";
import HideSourceIcon from "@mui/icons-material/HideSource";
import Bubble = DayPilot.Bubble;

//Style
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/css/Calendar.css";

//Local
import SchedulingPage from "./SchedulingPage";
import { DbRead, DbWrite } from "../../database";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import {
  ExistingTask,
  ExistingTaskWithCustomerLocation,
  Task_CreateForCustomer,
  TaskRecordManager,
} from "../../models/task";
import { useUserPermissionsStore } from "../../store/user-permissions";
import { logger as devLogger } from "../../logging";
import SchedulingSearchBox from "../../components/scheduling/SchedulingSearchBox";
import {
  convertFSTimestampToLuxonDT,
  convertISOToFSTimestamp,
  convertLuxonDTToFSTimestamp,
  createToastMessageID,
  dropUndefined,
  getTimeDifferenceInHours,
  isStringArray,
} from "../../utils";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import * as strings from "../../strings";
import { ToastType, useToastMessageStore } from "../../store/toast-messages";
import { diffObjects } from "../../assets/js/object-diff";
import {
  generateResourcesForDayPilotCalendar,
  generateResourcesForDayPilotScheduler,
} from "../../assets/js/scheduling/generateResources";
import {
  CalendarColumn,
  CalendarEvent,
  ColumnOption,
} from "../../models/scheduling-types";
import { generateCalendarEvents } from "../../assets/js/scheduling/generateCalendarEvents";
import { useNavToCreateEstimate } from "../../navigation";
import TaskDetailsDialog from "../../components/scheduling/TaskDetailsDialog";
import TaskCardOpen from "../../components/WorkRecordAndTasks/TaskCardOpen";
import DetailsAndEventsPanels from "../../components/WorkRecordAndTasks/DetailsAndEventsPanels";
import { ExistingCustomField } from "../../models/custom-field";
import { isValidCraftType } from "../../models/craft-types";
import { getTaskCustomFieldList } from "../../components/WorkRecordAndTasks/functions";
import { isValidTaskType } from "../../models/task-types";
import { useUserDisplayNamesStore } from "../../store/user-display-names-map";
import RescheduleTaskDialog from "../../components/WorkRecordAndTasks/RescheduleTaskDialog";
import { useAuthStore } from "../../store/firebase-auth";
import ChangeTaskStatusButton from "../../components/WorkRecordAndTasks/ChangeTaskStatusButton";
import EditTaskDialog from "../../components/WorkRecordAndTasks/EditTaskDialog";
import MultipleUidDialog, {
  IMultipleUid_AssignUser,
} from "../../components/CustomFields/MultipleUidDialog";
import { Json } from "../../models/json-type";
import TaskStatusChangeDialog from "../../components/WorkRecordAndTasks/TaskStatusChangeDialog";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { whiteLabel, WORK_RECORD_AND_TASKS_URL, ZONES_URL } from "../../urls";
import { TaskStatus } from "../../models/task-status";
import AddStiltCalendarEventDialog from "../../components/scheduling/AddStiltCalendarEventDialog";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import { convertToDisplayTime } from "../../utils/convertToDisplayTime";
import {
  ExistingStiltCalendarEvent,
  StiltCalendarEvent,
  StiltCalendarEventManager,
} from "../../models/stilt-calendar-event";
import { startDateIsEarlierThanEndDate } from "../../utils/startDateIsEarlierThanEndDate";
import { generateStiltCalendarEvents } from "../../assets/js/scheduling/generateStiltCalendarEvents";
import SchedulingMultipleSelection from "../../components/SchedulingMultipleSelection";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import EditStiltCalendarEventDialog from "../../components/scheduling/EditStiltCalendarEventDialog";
import BaseModal from "../../components/BaseModal";
import CreateTaskForCustomerContainer from "../Customers/CreateTaskForCustomerContainer";
import { removeSystemUsersFromUserDisplayNameMap } from "../../utils/removeSystemUsersFromUserMap";
import { getPermissionsMap } from "../Admin/UserManagementContainer";
import { StyledTooltip } from "../../components/StyledTooltip";
import { useSiteKeyUsersStore } from "../../store/site-key-users";
import { getFontColorForBackground } from "../../utils/colors";
import currencyFormatter from "../../currency";
import { ExistingSiteKey } from "../../models/site-key";
import PillMetric from "../../components/PillMetric";
import { ExistingEstimate } from "../../models/estimate";
import EstimateListDialog from "../../components/WorkRecordAndTasks/EstimateListForTaskDialog";
import { ICellRendererParams } from "ag-grid-community";
import EmailButtonWithSpinner from "../../components/EmailButtonWithSpinner";
import { InvoiceIconWithSpinner } from "../../components/InvoiceCreateButton";
import { PaymentIconWithSpinner } from "../../components/PaymentButton";
import { PDFIconWithSpinner } from "../../components/PDFButton";
import { ExistingEstimateItem } from "../../models/estimate-item";
import {
  ExistingStiltInvoice,
  StiltInvoice_UpdateAPI,
  StiltInvoiceManager,
  TemplatePaymentTerm,
} from "../../models/invoice";
import { ExistingCustomerLocation } from "../../models/customer-location";
import ViewEstimateDialog from "../../components/WorkRecordAndTasks/ViewEstimateDialog";
import ViewEstimateContainer from "../Estimates/ViewEstimateContainer";
import { ExistingCustomer } from "../../models/customer";
import { generatePDF } from "../../components/Invoices/generatePDF";
import { isWhiteLabel } from "../../white-label-check";
import HandlePaymentDialog from "../../components/Invoices/HandlePaymentDialog";
import ViewInvoiceDialog, {
  ViewInvoiceDialogProps,
} from "../../components/Payments/ViewInvoiceDialog";
import { generatePaymentUniqueLink } from "../../assets/js/generatePaymentUniqueLink";
import { getEmailList } from "../../utils/getEmailList";
import { NewManualPayment } from "../../components/Invoices/RecordManualPaymentDialog";
import {
  APIPaymentSavedCard,
  paymentMethods,
  StiltPayment_CreateAPI,
  StiltPaymentManager,
} from "../../models/stilt-payment";
import EditInvoiceDialog from "../../components/Invoices/EditInvoiceDialog";
import BaseInputSelect from "../../components/BaseInputSelect";
import HandleSendEmailDialog from "../../components/estimates/HandleSendEmailDialog";
import { ExistingCustomerContact } from "../../models/customer-contact";
import ResourcesColumnDropdown from "../../components/ResourcesColumnDropdown";
import {
  AssignedRoute_UpdateAPI,
  ExistingAssignedRoute,
} from "../../models/assigned-route";
import { ExistingZone } from "../../models/zone";
import getCurrentJSDateWithoutSeconds from "../../assets/js/getCurrentJSDateWithoutSeconds";
import ChooseCreateJobOrEventDialog from "../../components/scheduling/ChooseCreateJobOrEventDialog";

interface Props {
  siteKey: string;
}

export default function SchedulingContainer({ siteKey }: Props) {
  const isMounted = useRef(true);

  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const addMessage = useToastMessageStore((state) => state.addToastMessage);
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );

  const [userDisplayNamesMap, userDisplayNamesMapIsLoading] =
    useUserDisplayNamesStore((state) => [
      state.userDisplayNames,
      state.loading,
    ]);

  const siteKeyUsersList = useSiteKeyUsersStore(
    (state) => state.siteKeyUsersList,
  );

  function goToPaymentPage(paymentLink: string) {
    window.open(paymentLink, "_blank");
  }

  const filterUserDisplayNamesMap =
    removeSystemUsersFromUserDisplayNameMap(userDisplayNamesMap);

  const [siteKeyDoc] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.loading,
  ]);
  const [siteKeyLocationList] = useSiteKeyLocationsStore((state) => [
    state.siteKeyLocationList,
    state.getLocationTitle,
  ]);

  const navToCreateEstimateByTask = useNavToCreateEstimate();

  /* HISTORY */
  const navigate = useNavigate();

  // TODO: See if we can actually make this work right....see openJobButton
  // if user got to this page from back button, set selectedDate to the date saved on local storage
  // useEffect(() => {
  //   if (navigationType === "POP") {
  //     const stringSavedDate = localStorage.getItem(
  //       "schedulingContainerCurrentDate",
  //     );
  //
  //     if (typeof stringSavedDate === "string") {
  //       const JSDate = new Date(parseInt(stringSavedDate));
  //       const luxonDate = DateTime.fromJSDate(JSDate);
  //       setSelectedDate(luxonDate.toJSDate());
  //
  //       localStorage.removeItem("schedulingContainerCurrentDate");
  //     }
  //   }
  // }, [navigationType]);

  function goToWorkRecordAndTasksPage(
    craftRecordPath: ExistingTask["craftRecordID"],
  ) {
    const craftRecordID = craftRecordPath.split("/")[3];
    navigate(`${WORK_RECORD_AND_TASKS_URL}/${craftRecordID}`);
  }

  /* QUERIES */
  /**
   * Query to get the list of vehicles
   */
  const vehiclesQueryKey = ["schedulingContainer_Vehicles", siteKey];
  const { data: vehicleList = [], isLoading: vehicleListIsLoading } = useQuery(
    vehiclesQueryKey,
    () => DbRead.vehicles.getAll(siteKey),
  );

  const [selectedTask, setSelectedTask] = useState<ExistingTask | null>(null);

  const workRecordQueryKey = [
    "schedulingContainer_WorkRecord",
    siteKey,
    selectedTask,
  ];
  const { data: workRecord = null } = useQuery(
    workRecordQueryKey,
    () => {
      if (!selectedTask) return null;
      const justTheID = selectedTask.craftRecordID.split("/")[3];
      return DbRead.parentRecords.get(siteKey, justTheID);
    },
    { enabled: !!selectedTask },
  );
  // Get all Event docs that belong to this work record.
  const { data: events = [] } = useQuery(
    ["schedulingContainer_Events", siteKey, userPermissions, workRecord],
    () => {
      if (!userPermissions || !workRecord) return [];
      return DbRead.events.getAllByWorkRecordID({
        siteKey: siteKey,
        userPermissions,
        workRecordID: workRecord.id,
      });
    },
    // The query will not execute until workRecord and userPermissions exist
    { enabled: !!workRecord && !!userPermissions },
  );

  // Get all Estimates docs that belong to this work record.
  const { data: estimateList = [] } = useQuery(
    ["schedulingContainer_Estimates", siteKey, workRecord?.id],
    () => {
      if (!userPermissions || !workRecord) return [];
      return DbRead.estimates.getByCraftRecordId(siteKey, workRecord.id);
    },
    // The query will not execute until workRecord and userPermissions exist
    { enabled: !!workRecord && !!userPermissions },
  );

  //get all the estimate items related to the existing estimates for this work record
  const estimateItemQueryResult = useQueries(
    estimateList.map((estimate) => {
      return {
        queryKey: ["schedulingContainer_EstimateItems", siteKey, estimate.id],
        queryFn: async () =>
          await DbRead.estimateItems.getByEstimateId(siteKey, estimate.id),
      };
    }),
  );

  const list = estimateItemQueryResult.map((estimateItem) => {
    if (estimateItem.data != null) {
      return estimateItem.data;
    } else {
      return [];
    }
  });
  const estimateItemList: ExistingEstimateItem[] = list.flat(1);

  /* STATES & VARIABLES */
  const [isLoading, setIsLoading] = useState(false);
  const [dontRenderDispatchBoard, setDontRenderDispatchBoard] = useState(false);
  const [scheduledAwaitingTaskList, setScheduledAwaitingTaskList] = useState<
    ExistingTaskWithCustomerLocation[]
  >([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [locationsDropdownValue, setLocationsDropdownValue] = useState<
    ColumnOption["value"][]
  >([]);

  const [resourcesDropdownValue, setResourcesDropdownValue] = useState<
    ColumnOption["value"] | null
  >("technicians");
  const [selectedDate, setSelectedDate] = useState<Date>(
    getCurrentJSDateWithoutSeconds(),
  );
  // const [isSavingAssignedVehicle, setIsSavingAssignedVehicle] =
  //   useState<boolean>(false);
  const [taskDetailsDialogOpen, setTaskDetailsDialogOpen] =
    useState<boolean>(false);
  const [openRescheduleTaskDialog, setOpenRescheduleTaskDialog] =
    useState(false);
  // const [isSavingUserToTasks, setIsSavingUserToTasks] =
  //   useState<boolean>(false);
  // const [isOptimizing, setIsOptimizing] = useState<boolean>(false);
  // const [tasksAllocated, setTasksAllocated] = useState<ExistingTask[] | null>(
  //   null,
  // );
  // const [totalDistance] = useState<number>(0);
  const [skTaskCustomFields, setSkTaskCustomFields] = useState<
    ExistingCustomField[]
  >([]);
  const [openEditTaskDialog, setOpenEditTaskDialog] = useState(false);
  const [taskForTaskStatusChangeDialog, setTaskForTaskStatusChangeDialog] =
    useState<ExistingTask | null>(null);
  const [
    originalTaskForTaskStatusChangeDialog,
    setOriginalTaskForTaskStatusChangeDialog,
  ] = useState<ExistingTask | null>(null);
  const [openTaskStatusChangeDialog, setOpenTaskStatusChangeDialog] = useState<
    string | null
  >(null);

  const [showCraftPersistence, setShowCraftPersistence] = useState(false);
  const [nextTaskStatus, setNextTaskStatus] = useState<TaskStatus | null>(null);

  const [totalsLoading, setTotalsLoading] = useState<boolean>(false);

  const [totals, setTotals] = useState<{ [key: string]: number }>({
    scheduledSales: 0,
    invoiceTotals: 0,
    paymentTotals: 0,
    tasks: 0,
  });

  const [addNewEventDialogOpen, setAddNewEventDialogOpen] =
    useState<boolean>(false);
  const [startDateNewCalendarEvent, setStartDateNewCalendarEvent] =
    useState<DateTime>(DateTime.now());
  const [endDateNewCalendarEvent, setEndDateNewCalendarEvent] =
    useState<DateTime>(DateTime.now());
  const [displayStartTime, setDisplayStartTime] = useState<string>("");
  const [displayEndTime, setDisplayEndTime] = useState<string>("");
  const [stiltCalendarEvents, setStiltCalendarEvents] = useState<
    ExistingStiltCalendarEvent[]
  >([]);
  const [selectedStiltEvent, setSelectedStiltEvent] =
    useState<ExistingStiltCalendarEvent | null>(null);
  const [editStiltEventDialogOpen, setEditStiltEventDialogOpen] =
    useState<boolean>(false);

  const [createTaskDialogOpen, setCreateTaskDialogOpen] =
    useState<boolean>(false);
  const startDateIsEarlier = startDateIsEarlierThanEndDate(
    startDateNewCalendarEvent,
    endDateNewCalendarEvent,
  );

  const [schedulerState, setSchedulerState] = useState<DayPilot.Scheduler>();
  const schedulerRef = useRef<DayPilotScheduler | null>(null);
  const calendarRef = useRef<DayPilotCalendar | null>(null);

  const [selectCreationChoiceDialogOpen, setSelectCreationChoiceDialogOpen] =
    useState<boolean>(false);
  const [resourceID, setResourceID] = useState<string | null>(null);
  const [eventDuration, setEventDuration] = useState<number | null>(null);
  const localStorageTableOrientation = localStorage.getItem(
    "tableOrientationIsHorizontal",
  );

  const [tableOrientationIsHorizontal, setTableOrientationIsHorizontal] =
    useState(
      localStorageTableOrientation
        ? JSON.parse(localStorageTableOrientation) === true
        : false,
    );

  const [estimateListDialogOpen, setEstimateListDialogOpen] =
    useState<boolean>(false);
  const [estimateListForTable, setEstimateListForTable] = useState<
    ExistingEstimate[] | null
  >(null);
  const [workRecordInvoiceList, setWorkRecordInvoiceList] = useState<
    ExistingStiltInvoice[]
  >([]);

  const [selectedCustomerLocations, setSelectedCustomerLocations] = useState<
    ExistingCustomerLocation[]
  >([]);
  const [selectedCustomer, setSelectedCustomer] =
    useState<ExistingCustomer | null>(null);

  const [viewEstimateDialogOpen, setViewEstimateDialogOpen] = useState(false);
  const [selectedEstimate, setSelectedEstimate] =
    useState<ExistingEstimate | null>(null);
  const [selectedEstimateLocation, setSelectedEstimateLocation] =
    useState<ExistingCustomerLocation | null>(null);
  const [selectedInvoice, setSelectedInvoice] =
    useState<ExistingStiltInvoice | null>(null);
  const [isHandlePaymentDialogOpen, setIsHandlePaymentDialogOpen] =
    useState(false);
  const [isTableHandlePaymentDialogOpen, setIsTableHandlePaymentDialogOpen] =
    useState(false);
  const [invoicePaymentLink, setInvoicePaymentLink] = useState<string | null>(
    null,
  );
  const [handleSendEmailDialogOpen, setHandleSendEmailDialogOpen] =
    useState(false);
  const [viewInvoiceDialogProps, setViewInvoiceDialogProps] =
    useState<ViewInvoiceDialogProps | null>(null);
  const [isViewInvoiceDialogOpen, setIsViewInvoiceDialogOpen] = useState(false);
  const [editInvoiceDialogOpen, setEditInvoiceDialogOpen] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState<DateTime>(
    DateTime.now(),
  );

  const [selectedDueDate, setSelectedDueDate] = useState<DateTime | null>(null);
  const [selectedIssueDate, setSelectedIssueDate] = useState<DateTime>(
    DateTime.now(),
  );
  const [paymentTerms, setPaymentTerms] = useState<
    ExistingStiltInvoice["paymentTerms"] | null
  >(null);

  const templatesPaymentTerms: Record<string, TemplatePaymentTerm> =
    siteKeyDoc?.customizations.paymentTerms ?? {};

  const [customerContacts, setCustomerContacts] = useState<
    ExistingCustomerContact[] | null
  >(null);

  const [selectedVehicleID, setSelectedVehicleID] = useState<string | null>(
    null,
  );
  const [assignedToDialogOpen, setAssignedToDialogOpen] =
    useState<boolean>(false);

  const [dailyVehicleUsersMap, setDailyVehicleUsersMap] = useState<
    ExistingAssignedRoute["vehicleUsersMap"]
  >({});

  const dispatchBoardResourceTypeDefault: "technicians" | "vehicles" =
    siteKeyDoc?.customizations["dispatchBoardResourceTypeDefault"] ??
    "technicians";

  const [zoneList, setZoneList] = useState<ExistingZone[]>([]);
  const [zoneListIsLoading, setZoneListIsLoading] = useState<boolean>(false);

  const [isResourcesHidden, setIsResourcesHidden] = useState<boolean>(false);
  const [currentCustomer, setCurrentCustomer] =
    useState<ExistingCustomer | null>(null);

  // Fetch the list of zones when this component loads.
  useEffect(() => {
    async function getZones() {
      devLogger.debug("getZones useEffect called ");
      // Query zones via realtime updates. Set the list of zones.
      const unsubscribeAllZones = DbRead.zones.subscribeAll({
        siteKey: siteKey,
        onChange: (zoneList: ExistingZone[]) => {
          setZoneList(zoneList), setZoneListIsLoading(false);
        },
        onError: (error) =>
          devLogger.error(`Error in getZones: ${error.message}`),
      });
      return unsubscribeAllZones;
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    setZoneListIsLoading(true);
    const unsubscribePromise = getZones();
    // Return an anonymous ƒn for cleanup.
    return () => {
      devLogger.debug(
        "(ZoneListContainer) getZones useEffect return ƒn just invoked.",
      );
      unsubscribePromise.then((unsubscribe) => {
        if (unsubscribe) {
          devLogger.debug("Now unsubscribing from zones.");
          unsubscribe();
        }
      });
      devLogger.debug("Done running cleanup ƒn for getZones useEffect.");
    };
  }, [siteKey]);

  useEffect(() => {
    function getDailyVehicleUsersMap() {
      // typeguard
      if (!userPermissions) {
        devLogger.debug("No userPermissions yet...");
        return undefined;
      }
      devLogger.debug(`Running useEffect: ${getDailyVehicleUsersMap.name}.`);

      // Start loading.
      if (isMounted.current) setIsLoading(true);

      // Query for daily assignedRoute via realtime updates.
      const unsubscribeDailyAssignment =
        DbRead.assignedRoutes.subscribeDailyVehicleUsersMap({
          siteKey: siteKey,
          isoDate: DateTime.fromJSDate(selectedDate).toISODate(),
          onChange: setDailyVehicleUsersMap,
          onError: (error) => {
            devLogger.error(
              `Error in ${getDailyVehicleUsersMap.name}: ${error.message}`,
            );
          },
        });

      // If user hasn't navigated away from the page, stop rendering the loading icon.
      if (isMounted.current) {
        setIsLoading(false);
      }
      return unsubscribeDailyAssignment;
    }

    const unsubscribe = getDailyVehicleUsersMap();

    // Return an anonymous ƒn for cleanup.
    return () => {
      devLogger.debug(
        "(SchedulingContainer) getDailyAssignment useEffect return ƒn just invoked.",
      );
      isMounted.current = false;
      if (unsubscribe) unsubscribe();
    };
  }, [selectedDate, siteKey, userPermissions]);

  useEffect(() => {
    if (!siteKeyDoc) return;

    if (
      dispatchBoardResourceTypeDefault === "vehicles" &&
      vehicleList.length === 0
    )
      return;

    setResourcesDropdownValue(dispatchBoardResourceTypeDefault);
  }, [dispatchBoardResourceTypeDefault, siteKeyDoc, vehicleList.length]);

  useEffect(() => {
    function getSelectedCustomerContacts() {
      if (!selectedCustomer) return undefined;
      const unsubscribe = DbRead.customerContacts.subscribeByCustomerID({
        siteKey: siteKey,
        customerID: selectedCustomer.id,
        onChange: setCustomerContacts,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getSelectedCustomerContacts();
    return () => unsubscribeFn && unsubscribeFn();
  }, [selectedCustomer, siteKey]);

  useEffect(() => {
    if (siteKeyUsersList.length === 0) return;

    assignedUserList.current = siteKeyUsersList
      // we don't want system users to be selectable in the multiple-uid dialog
      .filter((user) => user.systemUser !== true)
      .map((user) => ({
        uid: user.id,
        name: user.displayName,
        company: user.companyName,
        avatarUrl: user.userPhoto_URL,
        isAssigned: false,
        assignedVehicle: null,
      }));
  }, [siteKeyUsersList]);

  useEffect(() => {
    async function setNewCustomerLocations() {
      if (typeof selectedTask?.customerID !== "string") return;

      const [selectedCustomer, newLocations] = await Promise.all([
        DbRead.customers.get(siteKey, selectedTask.customerID),
        DbRead.customerLocations.getByCustomerId(
          siteKey,
          selectedTask?.customerID,
        ),
      ]);
      setSelectedCustomer(selectedCustomer);
      setSelectedCustomerLocations(newLocations);
    }

    setNewCustomerLocations();
  }, [siteKey, selectedTask]);

  const calculateDaypilotScrollTime = useCallback((): string => {
    if (!siteKeyDoc) return new Date().toISOString().split(".")[0];

    let todayISO: string;
    try {
      const today = DateTime.now().setZone(siteKeyDoc.timezone);
      if (!today.isValid) todayISO = DateTime.now().toISODate();
      else todayISO = today.toISODate();
    } catch (e) {
      todayISO = DateTime.now().toISODate();
    }

    let hour =
      typeof siteKeyDoc.customizations.dispatchBoardScrollHour === "number"
        ? siteKeyDoc.customizations.dispatchBoardScrollHour
        : DateTime.now().minus({ hours: 1 }).hour;

    if (hour < 0) hour = 0;
    if (hour > 23) hour = 23; // if this runs, dispatchBoardScrollHour needs some love

    const timePart = hour < 10 ? `0${hour}:00:00` : `${hour}:00:00`;

    // DayPilot's date string format - omit everything after seconds
    return `${todayISO}T${timePart}`;
  }, [siteKeyDoc]);

  const scrollTime = calculateDaypilotScrollTime();

  useEffect(() => {
    if (schedulerState) schedulerState.scrollTo(scrollTime);
  }, [schedulerState, scrollTime]);

  useEffect(() => {
    devLogger.debug(
      `useEffect: set localStorage ... `,
      `tableOrientationIsHorizontal= ${tableOrientationIsHorizontal}`,
    );
    localStorage.setItem(
      "tableOrientationIsHorizontal",
      JSON.stringify(tableOrientationIsHorizontal),
    );
  }, [tableOrientationIsHorizontal]);

  useEffect(() => {
    function getCalendarEventListBySelectedDate() {
      // typeguard
      if (!userPermissions) {
        devLogger.debug("No userPermissions yet...");
        return undefined;
      }
      // Start loading.
      if (isMounted.current) setIsLoading(true);

      devLogger.debug(
        `Running useEffect: ${getCalendarEventListBySelectedDate.name}.`,
      );

      //setting the date selected to doesn't care about the hours selected but
      // check the entire day
      const startDate = DateTime.fromJSDate(selectedDate)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
        })
        .minus({ days: 30 });
      const endDate = DateTime.fromJSDate(selectedDate)
        .set({
          hour: 23,
          minute: 59,
          second: 59,
        })
        .plus({ days: 30 });

      // Query for all awaiting scheduled tasks via realtime updates.
      const unsubscribeCalendarEventList =
        DbRead.calendarEvents.subscribeByDate({
          siteKey: siteKey,
          permissions: userPermissions,
          userID: firebaseUser.uid,
          startDate: convertLuxonDTToFSTimestamp(startDate),
          endDate: convertLuxonDTToFSTimestamp(endDate),
          onChange: (events) => {
            if (dontRenderDispatchBoard) {
              return;
            }
            setStiltCalendarEvents(events);
            devLogger.debug(`useEffect stiltCalendarEvents: ${events}`);
          },
          onError: (error) =>
            devLogger.error(
              `Error in ${getCalendarEventListBySelectedDate.name}: ${error.message}`,
            ),
        });

      // If user hasn't navigated away from the page, stop rendering the loading icon.
      if (isMounted.current) {
        setIsLoading(false);
      }
      return unsubscribeCalendarEventList;
    }

    const unsubscribe = getCalendarEventListBySelectedDate();

    // Return an anonymous ƒn for cleanup.
    return () => {
      devLogger.debug(
        "(SchedulingContainer) getCalendarEventListBySelectedDate useEffect return ƒn just invoked.",
      );
      isMounted.current = false;

      if (unsubscribe) unsubscribe();
    };
  }, [
    firebaseUser.uid,
    selectedDate,
    siteKey,
    userPermissions,
    dontRenderDispatchBoard,
  ]);

  useEffect(() => {
    const startTime = convertToDisplayTime(
      startDateNewCalendarEvent.toJSDate(),
    );
    setDisplayStartTime(startTime);
    const endTime = convertToDisplayTime(endDateNewCalendarEvent.toJSDate());
    setDisplayEndTime(endTime);
    devLogger.debug(
      `useEffect result: update display times ... `,
      `startTime= ${startTime}`,
      `endTime= ${endTime}`,
    );
  }, [endDateNewCalendarEvent, startDateNewCalendarEvent]);

  useEffect(() => {
    devLogger.debug(
      `Running useEffect: if start date is older, update end date ... `,
      `startDateIsEarlier= ${startDateIsEarlier}`,
    );
    //if start date is older than end date, update end date with the current start date
    if (!startDateIsEarlier) {
      setEndDateNewCalendarEvent(
        startDateNewCalendarEvent.plus({ seconds: 1 }),
      );
    }
  }, [startDateIsEarlier, startDateNewCalendarEvent]);

  useEffect(() => {
    function getInvoices() {
      if (!workRecord) return undefined;
      if (typeof workRecord.id !== "string") {
        throw new Error(`workRecordID was not a string: ${workRecord.id}`);
      }

      const unsubscribe = DbRead.invoices.subscribeByCraftRecordId(
        siteKey,
        workRecord.id,
        setWorkRecordInvoiceList,
      );
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getInvoices();

    // eslint-disable-next-line consistent-return
    return () => unsubscribeFn && unsubscribeFn();
  }, [siteKey, workRecord]);

  const currentDateString = DateTime.fromJSDate(selectedDate).toISODate();
  const resourcesColumnSelectorOptions: ColumnOption[] = [
    { value: "technicians", text: "Technicians" },
  ];

  const siteUsesVehicles =
    siteKeyDoc?.customizations.vehiclesEnabled === true &&
    vehicleList.length > 0;

  if (siteUsesVehicles) {
    resourcesColumnSelectorOptions.push({
      value: "vehicles",
      text: "Vehicles",
    });
  }

  const locationsColumnSelectorOptions: ColumnOption[] =
    getFormattedSiteLocations(siteKeyLocationList);

  const filteredSiteKeyLocations = getSiteKeyLocationSelected(
    siteKeyLocationList,
    locationsDropdownValue,
  );

  const filteredUsersList = siteKeyUsersList.filter(
    (user) => user.systemUser !== true,
  );

  /* Fetch the list of permissions when this component loads */
  const { data: permissionsMap = {} } = useQuery(
    ["schedulingContainer_permissions", siteKey],
    () => getPermissionsMap(filteredUsersList, siteKey),
    {
      enabled: filteredUsersList.length > 0,
    },
  );

  const resourcesForCalendar = generateResourcesForDayPilotCalendar(
    vehicleList,
    filteredUsersList,
    siteKeyLocationList,
    resourcesDropdownValue,
    permissionsMap,
    dailyVehicleUsersMap,
  );

  const filteredResourcesForCalendar =
    filterCalendarResourcesByLocationDropdownValues(
      resourcesForCalendar,
      filteredSiteKeyLocations,
      locationsDropdownValue,
    );

  const resourcesForScheduler = generateResourcesForDayPilotScheduler(
    vehicleList,
    filteredUsersList,
    filteredSiteKeyLocations,
    resourcesDropdownValue,
    permissionsMap,
  );

  const calendarEvents = generateCalendarEvents({
    allocatedTasks: scheduledAwaitingTaskList,
    vehicleList: vehicleList,
    searchTerm: searchTerm,
    mode: resourcesDropdownValue,
    inactiveUserIDs: siteKeyDoc?.inactiveUsers ?? [],
    unapprovedUserIDs: siteKeyDoc?.unapprovedUsers ?? [],
    zoneList: zoneList,
  });

  if (stiltCalendarEvents.length !== 0) {
    const stiltEvents = generateStiltCalendarEvents({
      stiltCalendarEvents,
    });
    calendarEvents.push(...stiltEvents);
  }

  // Get the company name to display on the UI
  // const companyName = companyList.find(
  //   (company) => company.id === selectedTask?.assignedCompanyID,
  // )?.name;
  // Get the siteKey's customizations for this task
  const targetWorkType = selectedTask?.craftType;
  const targetTaskType = selectedTask?.taskType;
  let taskCustomFields: ExistingCustomField[] = [];
  if (
    isValidCraftType(targetWorkType) &&
    isValidTaskType(targetTaskType) &&
    siteKeyDoc
  ) {
    taskCustomFields = getTaskCustomFieldList({
      siteKey: siteKeyDoc,
      targetWorkType,
      targetTaskType,
    });
  } else {
    taskCustomFields = [];
  }

  // Find the events that pertain to the current task; sort by timestamp
  const eventList = events
    .filter((event) => event.taskID === selectedTask?.id)
    .sort(
      (a, b) => b.timestampCreated.toMillis() - a.timestampCreated.toMillis(),
    );

  const emailList = selectedInvoice
    ? getEmailList(selectedInvoice, selectedCustomer)
    : [];

  /* MUTATIONS */
  const mutateUpdateInvoice = useMutation(
    async (args: { validPartialInvoiceData: StiltInvoice_UpdateAPI }) => {
      await DbWrite.invoices.update(args.validPartialInvoiceData);
    },
  );

  const mutateRecordManualPayment = useMutation(
    async (args: { paymentData: StiltPayment_CreateAPI }) => {
      await DbWrite.payments.manualPayment(args.paymentData);
    },
  );

  const mutateCreateInvoice = useMutation(
    async (args: { siteKey: string; estimateID: string; version: string }) => {
      return DbWrite.invoices.create({
        siteKey: args.siteKey,
        estimateID: args.estimateID,
        version: args.version,
        platform: "web",
      });
    },
  );

  const mutateDeleteTask = useMutation(
    async (args: { taskDoc: ExistingTask }) =>
      await DbWrite.tasks.delete(args.taskDoc),
  );

  const mutateAddNewCalendarEvent = useMutation(
    async (args: { newCalendarEvent: StiltCalendarEvent }) => {
      await DbWrite.calendarEvents.add(siteKey, args.newCalendarEvent);
    },
  );

  const mutateEditStiltEvent = useMutation(
    async (args: { stiltEventID: string; updateEventData: DocumentData }) => {
      await DbWrite.calendarEvents.update(
        args.stiltEventID,
        siteKey,
        args.updateEventData,
      );
    },
  );

  const mutateDeleteStiltEvent = useMutation(
    async (args: { stiltEventID: string }) => {
      await DbWrite.calendarEvents.delete(args.stiltEventID, siteKey);
    },
  );

  /* FUNCTIONS */
  function handlePaymentTermsChange(
    event: React.ChangeEvent<HTMLSelectElement>,
  ) {
    const value = event.target.value;
    if (value === "") {
      setPaymentTerms(null);
    } else {
      setPaymentTerms(value);
      const selectedPaymentTermTemplate = Object.entries(
        templatesPaymentTerms,
      ).find(([key, _]) => key === value);
      if (!selectedPaymentTermTemplate) return;
      const newDueDate = selectedIssueDate.plus({
        days: selectedPaymentTermTemplate[1].daysUntilDue,
      });
      setSelectedDueDate(newDueDate);
    }
  }

  async function handleSaveUpdatedInvoice(
    updatedInvoice: Partial<ExistingStiltInvoice>,
  ) {
    if (!selectedInvoice) return;

    const updatedInvoiceFields: StiltInvoice_UpdateAPI = {
      ...updatedInvoice,
      paymentTerms: paymentTerms,
      dueDate: selectedDueDate ? selectedDueDate.toString() : null,
      issueDate: selectedIssueDate?.toString(),
    };

    const diffInvoiceValues: DocumentData = diffObjects(
      selectedInvoice,
      updatedInvoiceFields,
    ).diff;

    if (Object.keys(diffInvoiceValues).length === 0) {
      devLogger.debug("No values have changed");
      return;
    }

    const editInvoiceToValidate: Partial<ExistingStiltInvoice> = {
      ...diffInvoiceValues,
      id: selectedInvoice.id,
      refPath: selectedInvoice.refPath,
    };

    /* validate values before sending to DB */
    const validatedEditInvoice = StiltInvoiceManager.parseUpdate(
      editInvoiceToValidate,
    );
    devLogger.info("validatedEditInvoice", validatedEditInvoice);

    try {
      await mutateUpdateInvoice.mutateAsync({
        validPartialInvoiceData: validatedEditInvoice,
      });

      devLogger.debug("Payment terms has been updated successfully.");
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(
          `Payment terms for Invoice #${selectedInvoice.invoiceNumber}`,
        ),
        dialog: true,
        type: "success",
      });
      setIsViewInvoiceDialogOpen(false);
    } catch (error) {
      devLogger.error(
        `An error occurred during handleSaveNewPaymentTerms`,
        error,
      );
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: true,
        type: "error",
      });
    }

    setSelectedInvoice(null);
  }

  async function applyManualPayment(
    formValues: NewManualPayment,
  ): Promise<void> {
    if (firebaseUser == null) {
      devLogger.error("firebaseUser is null");
      return;
    }

    if (!selectedInvoice) {
      addMessage({
        id: createToastMessageID(),
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        dialog: true,
        type: "error",
      });
      return;
    }

    const valuesToBeValidated: StiltPayment_CreateAPI = {
      ...formValues,
      stringTimestampPaymentMade: selectedDateTime.toISO(),
      customerID: selectedInvoice.customerID,
      invoiceID: selectedInvoice.id,
      customData: {},
      createdBy: firebaseUser.uid,
      siteKey: siteKey,
      billToCustomerID: selectedInvoice.billToCustomerID,
      locationID: selectedInvoice.locationID,
      deleted: false,
    };

    const validPaymentData =
      StiltPaymentManager.parseCreate(valuesToBeValidated);
    devLogger.info("validPaymentData", validPaymentData);

    try {
      await mutateRecordManualPayment.mutateAsync({
        paymentData: validPaymentData,
      });
      if (
        validPaymentData.amount === selectedInvoice.amountDue &&
        selectedInvoice.status !== "draft" &&
        siteKeyDoc?.customizations.sendAutomatedReceiptToCustomers
      ) {
        if (selectedInvoice.email !== null) {
          addMessage({
            id: createToastMessageID(),
            message: strings.SUCCESS_MANUAL_PAYMENT_WITH_RECEIPT,
            dialog: true,
            type: "success",
          });
        } else {
          addMessage({
            id: createToastMessageID(),
            message: strings.SUCCESS_MANUAL_PAYMENT_NO_EMAIL,
            dialog: true,
            type: "info",
          });
        }
      } else {
        addMessage({
          id: createToastMessageID(),
          message: strings.SUCCESS_MANUAL_PAYMENT_NO_RECEIPT,
          dialog: true,
          type: "success",
        });
      }
    } catch (err) {
      devLogger.error("handleRecordManualPayment", err);
      addMessage({
        id: createToastMessageID(),
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        dialog: true,
        type: "error",
      });
      return;
    }
  }

  async function handleEmailReceipt(emailAddresses: string[]): Promise<void> {
    if (!selectedInvoice) return;
    if (emailList.length === 0) {
      addMessage({
        id: createToastMessageID(),
        message: strings.NO_EMAIL_FOR_CUSTOMER,
        dialog: false,
        type: "error",
      });
      return;
    }

    try {
      await DbWrite.payments.emailReceipt({
        siteKeyID: siteKey,
        invoiceID: selectedInvoice.id,
        customerEmailList: emailAddresses,
      });
      setEstimateListDialogOpen(false);
      addMessage({
        id: createToastMessageID(),
        message: strings.EMAILED_CUSTOMER_RECEIPT,
        dialog: false,
        type: "success",
      });
    } catch (e) {
      devLogger.error("Failed to email receipt to customer", e);
      addMessage({
        id: createToastMessageID(),
        message: strings.ERR_EMAILING_RECEIPT_NOTIFIED,
        dialog: false,
        type: "error",
      });
    } finally {
      setSelectedInvoice(null);
    }
  }

  /** send itemized invoice along with payment link */
  async function emailInvoice(
    email: string[],
    includeJobPhotos: boolean,
  ): Promise<void> {
    if (!selectedCustomer || !selectedInvoice) return;
    if (email.length === 0) {
      addMessage({
        id: createToastMessageID(),
        message: strings.NO_EMAIL_FOR_CUSTOMER,
        type: "error",
        dialog: false,
      });
      return;
    }

    const paymentLink = await generatePaymentUniqueLink(
      siteKey,
      selectedInvoice.id,
      "email",
    );
    if (!paymentLink) {
      addMessage({
        id: createToastMessageID(),
        message: strings.ERROR_PAYMENT_LINK,
        dialog: false,
        type: "error",
      });
      return;
    }

    try {
      await DbWrite.invoices.sendViaEmail({
        siteKey: siteKey,
        invoiceURL: paymentLink,
        customerEmailList: email,
        includeJobPhotos: includeJobPhotos,
      });
      addMessage({
        id: createToastMessageID(),
        message: strings.EMAILED_CUSTOMER_INVOICE,
        dialog: false,
        type: "success",
      });
    } catch (e) {
      addMessage({
        id: createToastMessageID(),
        message: strings.ERR_EMAILING_INVOICE,
        dialog: false,
        type: "error",
      });
      devLogger.error("error emailing customer invoice", e);
    }
  }

  async function sendEmailFromHandlePaymentDialog(
    emails: string[],
    shouldIncludePhotos: boolean,
  ): Promise<void> {
    if (!selectedInvoice) return;
    if (selectedInvoice.amountDue > 0) {
      await emailInvoice(emails, shouldIncludePhotos);
    } else {
      await handleEmailReceipt(emails);
    }
  }

  async function handleDeleteInvoice(
    siteKeyID: string,
    invoiceID: string,
  ): Promise<void> {
    return DbWrite.invoices.delete(siteKeyID, invoiceID);
  }

  async function handleRefund(
    paymentID: string,
    refundAmount: number,
  ): Promise<void> {
    // try/catch is in the InvoiceSummary component; don't want it here
    return DbWrite.payments.issueRefund(siteKey, paymentID, refundAmount);
  }

  async function handleEditInvoiceDialogOpen() {
    if (selectedInvoice) {
      setEditInvoiceDialogOpen(true);
    }
  }

  function onClickHandlePaymentButton(invoiceID: ExistingStiltInvoice["id"]) {
    const invoiceDoc = workRecordInvoiceList.find(
      (invoice) => invoice.id === invoiceID,
    );
    setIsHandlePaymentDialogOpen(true);
    setSelectedInvoice(invoiceDoc ?? null);
  }

  function closeViewInvoiceDialog(): void {
    setIsViewInvoiceDialogOpen(false);
    setViewInvoiceDialogProps(null);
  }

  async function handleGoToPaymentPage(): Promise<void> {
    if (invoicePaymentLink != null) {
      goToPaymentPage(invoicePaymentLink);
    } else {
      if (!selectedInvoice) return;
      const paymentLink = await generatePaymentUniqueLink(
        siteKey,
        selectedInvoice.id,
        "web",
      );
      if (paymentLink) {
        goToPaymentPage(paymentLink);
      } else {
        addMessage({
          id: createToastMessageID(),
          message: strings.ERROR_PAYMENT_LINK,
          dialog: false,
          type: "error",
        });
      }
    }
  }

  async function handleCreateInvoice(estimate: ExistingEstimate) {
    if (!isWhiteLabel(whiteLabel)) throw new Error(`Unexpected white label`);
    try {
      const { paymentURL, invoiceData } = await mutateCreateInvoice.mutateAsync(
        {
          siteKey: siteKey,
          estimateID: estimate.id,
          version: whiteLabel,
        },
      );
      setIsHandlePaymentDialogOpen(true);
      setInvoicePaymentLink(paymentURL);
      setSelectedInvoice(invoiceData);
    } catch (error) {
      devLogger.error("error on handleCreateInvoice", error);
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  async function getEstimatePDF(estimateID: string) {
    if (!siteKeyDoc) return;
    await generatePDF.estimate(siteKeyDoc, [estimateID]);
  }

  function resetSelectedEstimate() {
    setSelectedEstimate(null);
    setSelectedEstimateLocation(null);
    setSelectedInvoice(null);
  }

  async function handleGoToViewEstimate(estimateDoc: ExistingEstimate) {
    const invoiceDoc = workRecordInvoiceList.find(
      (invoice) => invoice.estimateID === estimateDoc.id,
    );

    const locationDoc = selectedCustomerLocations.find(
      (location) => location.id === estimateDoc.customerLocationID,
    );

    setSelectedEstimate(estimateDoc);
    setSelectedEstimateLocation(locationDoc ?? selectedCustomerLocations[0]);
    setSelectedInvoice(invoiceDoc ?? null);
    setViewEstimateDialogOpen(true);
  }

  function handleOpenEstimateListDialog(taskID?: ExistingTask["id"]) {
    if (taskID) {
      const estimatesForCurrentTask: ExistingEstimate[] = estimateList.filter(
        (estimate) => estimate.taskID === taskID,
      );

      setEstimateListForTable(estimatesForCurrentTask);
      setEstimateListDialogOpen(true);
    } else {
      setEstimateListForTable(estimateList);
      setEstimateListDialogOpen(true);
    }
  }

  function resetCalendarEventDatesAndResource() {
    setStartDateNewCalendarEvent(DateTime.now().minus({ hours: 1 }));
    setEndDateNewCalendarEvent(DateTime.now());
    setResourceID(null);
  }

  async function handleCreateFollowUpTask(
    task: ExistingTask,
    followUpTaskStatus: TaskStatus | null,
  ) {
    if (firebaseUser == null) {
      devLogger.error("firebaseUser is null");
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, refPath, ...rest } = task;

    const newTask: Task_CreateForCustomer = {
      ...rest,
      createdBy: firebaseUser.uid,
      lastModifiedBy: firebaseUser.uid,
      urgent: false,
      nextOpportunity: false,
      workOrder: "",
      crewCount: 0,
      durations: {},
      holdDurations: {},
      taskStatus: followUpTaskStatus ?? rest.taskStatus,
      taskSpecificDetails: {},
      timestampCreated: Timestamp.now().toMillis(),
      timestampLastModified: Timestamp.now().toMillis(),
      timestampScheduled: null,
      timestampAwaitingStart: null,
      timestampTaskCompleted: null,
      timestampTaskStarted: null,
    };

    // Convert to doc data
    const taskReady = TaskRecordManager.convertNewForFirestore(newTask);
    devLogger.info("taskReady", taskReady);

    await DbWrite.tasks.createForCustomer({
      siteKey: siteKey,
      taskDoc: taskReady,
      estimateID: null,
    });
  }

  async function handleUpdateTask(updateData: DocumentData, taskID: string) {
    await DbWrite.tasks.update(updateData, siteKey, taskID);
  }

  async function handleUpdateTSD(updateData: DocumentData, refPath: string) {
    await DbWrite.taskSpecificDetails.update(refPath, updateData);
  }

  async function handleCreateNewEstimate(taskDoc: ExistingTask) {
    if (taskDoc.customerID) {
      const customer = await DbRead.customers.get(siteKey, taskDoc.customerID);
      navToCreateEstimateByTask(customer, taskDoc);
    }
  }

  async function handleAssignUsersToVehicle(selectedUserIDs: string[]) {
    if (!selectedVehicleID) return;
    //write on assignedRoute collection the vehicleID with the selectedUserIDs
    dailyVehicleUsersMap[selectedVehicleID] = selectedUserIDs;

    const dataToSend: AssignedRoute_UpdateAPI = {
      assignedRouteID: DateTime.fromJSDate(selectedDate).toISODate(),
      siteKey: siteKey,
      vehicleUsersMap: dailyVehicleUsersMap,
    };

    //when write to db nees to send a customID (the selectedDate.toIsoDate()) & the dailyAssignment record
    try {
      await DbWrite.assignedRoutes.update(dataToSend);
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(
          `Assigned Route for the vehicle: ${selectedVehicleID} for the day ${DateTime.fromJSDate(selectedDate).toISODate()}`,
        ),
        dialog: true,
        type: "success",
      });

      //reset to false the isAssigned prop
      resetAssignedUserList(assignedUserList.current);
    } catch (error) {
      devLogger.error(
        `An error occurred during handleAssignUsersToVehicle for updating assigned route`,
        error,
      );
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: true,
        type: "error",
      });
    }
  }

  // async function optimize() {
  //   const result = await allocateTask(
  //     null,
  //     vehicleList,
  //     scheduledAwaitingTaskList,
  //     currentDateString
  //   );
  //   setTasksAllocated(result);
  // }

  function onChangeDatePicker(date: Date | null): void {
    if (date) {
      const luxonDate = DateTime.fromJSDate(date);
      setSelectedDate(luxonDate.toJSDate());
    }
  }

  // async function saveAssignedVehicleIDs() {
  //   setIsSavingAssignedVehicle(true);
  //   if (tasksWithAssignedVehicleID.length === 0) {
  //     return;
  //   }
  //   try {
  //     const promises = tasksWithAssignedVehicleID.map((task) => {
  //       const currentTask = scheduledAwaitingTaskList.find(
  //         (schedTask) => schedTask.id === task.id
  //       );
  //       if (currentTask) {
  //         const tsdDiff: DocumentData = diffObjects(
  //           currentTask.taskSpecificDetails,
  //           task.taskSpecificDetails
  //         ).diff;
  //
  //         return DbWrite.taskSpecificDetails.update(task.refPath, tsdDiff);
  //       } else {
  //         return null;
  //       }
  //     });
  //     await Promise.all(promises);
  //   } catch (error) {
  // devLogger.error(`An error occurred during saveAssignedVehicleIDs`, error);
  // addMessage({
  //   id: createToastMessageID(),
  //   message: strings.UNEXPECTED_ERROR,
  //   type: "error"
  // });
  //   } finally {
  //     setIsSavingAssignedVehicle(false);
  //   }
  // }

  async function handleDeleteTask(id: string): Promise<void> {
    if (!selectedTask) return;
    try {
      await mutateDeleteTask.mutateAsync({ taskDoc: selectedTask });
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulDelete(`task ${selectedTask.title}`),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      devLogger.error(`error while deleting this task:`, id, error);
      addMessage({
        id: createToastMessageID(),
        message: strings.ERR_DELETE_TASK,
        dialog: false,
        type: "error",
      });
    }
  }

  const assignedUserList = useRef<IMultipleUid_AssignUser[]>([]);

  async function handleEditTask(values: Record<string, Json>) {
    if (!selectedTask) return;

    const { membershipIDs, description, ...tsd } = values;

    const changedDescription = selectedTask.description !== description;
    const changedMembershipIDs = selectedTask.membershipIDs !== membershipIDs;
    const tsdDiff = diffObjects(selectedTask.taskSpecificDetails, tsd).diff;
    const changedTSD = Object.keys(tsdDiff).length > 0;

    if (!changedDescription && !changedTSD && !changedMembershipIDs) return;

    let taskUpdate = {};
    if (changedDescription || membershipIDs) {
      taskUpdate = {
        description: description,
        membershipIDs: membershipIDs,
        // @ts-ignore
        isMembershipTask: membershipIDs && membershipIDs?.length > 0,
        timestampLastModified: Timestamp.now(),
        lastModifiedBy: firebaseUser.uid,
      };
    } else {
      taskUpdate = {
        timestampLastModified: Timestamp.now(),
        lastModifiedBy: firebaseUser.uid,
      };
    }

    const xtData = dropUndefined(taskUpdate);

    // try/catch within the dialog
    await handleUpdateTask(xtData, selectedTask.id);

    if (changedTSD) {
      await handleUpdateTSD(tsdDiff, selectedTask.refPath);
    }

    addMessage({
      id: createToastMessageID(),
      message: strings.successfulUpdate("Task"),
      dialog: false,
      type: "success",
    });
  }

  async function handleOpenRTD(taskID: string): Promise<void> {
    const task = scheduledAwaitingTaskList.find((task) => task.id === taskID);
    if (!task) return;

    // selectedTask was set when the user clicked the task, but we need to
    // overwrite that - we need the task with the customerLocation data on it
    setSelectedTask(task);
    setOpenRescheduleTaskDialog(true);
  }

  /** Find the task they clicked, get the siteKey's customFields for that task type,
   * and open the dialog */
  function handleOpenEditTaskDialog(taskID: string): void {
    const foundTask = scheduledAwaitingTaskList.find(
      (task) => task.id === taskID,
    );
    if (!foundTask || !siteKeyDoc) return;

    const targetWorkType = foundTask.craftType;
    const targetTaskType = foundTask.taskType;
    if (isValidCraftType(targetWorkType) && isValidTaskType(targetTaskType)) {
      const result = getTaskCustomFieldList({
        siteKey: siteKeyDoc,
        targetWorkType,
        targetTaskType,
      });
      setSkTaskCustomFields(result);
    } else {
      devLogger.error(
        `craftType or taskType was invalid. craftType: ${targetWorkType} ... taskType: ${targetTaskType}`,
      );
      setSkTaskCustomFields([]);
    }

    setSelectedTask(foundTask);
    setOpenEditTaskDialog(true);
  }

  async function calendarEventClicked(event: DayPilot.Event["data"]) {
    if (event.tags.taskID) {
      const clickedTask = scheduledAwaitingTaskList.find(
        (task) => task.id === event.tags.taskID,
      );
      if (clickedTask) {
        setSelectedTask(clickedTask);
        setTaskDetailsDialogOpen(true);
      }
    } else if (event.tags.stiltEventID) {
      const stiltEvent = stiltCalendarEvents.find(
        (stiltEvent) => stiltEvent.id === event.tags.stiltEventID,
      );
      if (stiltEvent) {
        setSelectedStiltEvent(stiltEvent);
        setEditStiltEventDialogOpen(true);
        setStartDateNewCalendarEvent(
          convertFSTimestampToLuxonDT(stiltEvent.start),
        );
        setEndDateNewCalendarEvent(convertFSTimestampToLuxonDT(stiltEvent.end));
      }
    }
  }

  async function updateStiltEvent(
    updateData: UpdateStiltEventType,
    stiltEventToUpdate: ExistingStiltCalendarEvent,
  ): Promise<void> {
    const stiltEventDiff = diffObjects(stiltEventToUpdate, updateData).diff;

    const validatedUpdateData =
      StiltCalendarEventManager.parseUpdate(stiltEventDiff);

    await mutateEditStiltEvent.mutateAsync({
      stiltEventID: stiltEventToUpdate.id,
      updateEventData: validatedUpdateData,
    });
  }

  // TODO: extract
  async function updateTask(args: {
    taskID: string;
    newStart: string;
    newEnd: string;
    newResourceID?: string;
    originalResourceID?: string;
  }): Promise<string> {
    const taskToBeUpdated = scheduledAwaitingTaskList.find(
      (task) => task.id === args.taskID,
    );
    if (!taskToBeUpdated) throw new Error("Task not found");

    const initialTask = {
      timestampScheduled: taskToBeUpdated.timestampScheduled,
    };
    const updateTask = {
      timestampScheduled: convertISOToFSTimestamp(args.newStart),
    };
    const taskDiff: DocumentData = diffObjects(initialTask, updateTask).diff;

    const updateData = {
      ...taskDiff,
      timestampLastModified: Timestamp.now(),
      lastModifiedBy: firebaseUser.uid,
    };

    // Conditionally add items to taskSpecificDetails. Not all of these fields
    // may exist for the site, so we should only add the ones that were already
    // there
    const originalTaskDetails = taskToBeUpdated.taskSpecificDetails;
    const updatedTaskSpecificDetails: { [key: string]: any } = {};
    if (originalTaskDetails["scheduledServiceWindowStart"]) {
      const lux = DateTime.fromISO(args.newStart);
      if (!lux.isValid) {
        throw new Error(
          `Unable to parse date given for scheduledServiceWindowStart. Invalid args.newStart: ${args.newStart}`,
        );
      }
      const start = lux.toISO({
        includeOffset: true,
        suppressMilliseconds: true,
      });
      updatedTaskSpecificDetails["scheduledServiceWindowStart"] = start;
    }
    if (originalTaskDetails["scheduledServiceWindowEnd"]) {
      const lux = DateTime.fromISO(args.newEnd);
      if (!lux.isValid) {
        throw new Error(
          `Unable to parse date given for scheduledServiceWindowEnd. Invalid args.newEnd: ${args.newEnd}`,
        );
      }
      const end = lux.toISO({
        includeOffset: true,
        suppressMilliseconds: true,
      });
      updatedTaskSpecificDetails["scheduledServiceWindowEnd"] = end;
    }
    if (originalTaskDetails["lockAssignedVehicle"]) {
      updatedTaskSpecificDetails["lockAssignedVehicle"] = true;
    }

    if (args.originalResourceID != null && args.newResourceID != null) {
      // ^ when updateTask is called from calendarEventResized and calendarEventMoved
      // ... are these ever undefined when i think they're strings?? TODO:
      if (resourcesDropdownValue === "technicians") {
        if (isStringArray(originalTaskDetails["assignedTo"])) {
          const assignedTo = originalTaskDetails["assignedTo"];
          const index = assignedTo.indexOf(args.originalResourceID);
          if (index > -1) assignedTo.splice(index, 1); // remove old tech (if applicable)
          assignedTo.push(args.newResourceID); // add new tech
          updatedTaskSpecificDetails["assignedTo"] = assignedTo;
        }
      } else if (resourcesDropdownValue === "vehicles" && siteUsesVehicles) {
        updatedTaskSpecificDetails["assignedVehicleID"] = args.newResourceID;
        if (
          dailyVehicleUsersMap[args.newResourceID] != null &&
          dailyVehicleUsersMap[args.newResourceID].length > 0
        ) {
          updatedTaskSpecificDetails["assignedTo"] =
            dailyVehicleUsersMap[args.newResourceID];
        } else {
          updatedTaskSpecificDetails["assignedTo"] = [];
        }
      }
    } else if (args.originalResourceID == null && args.newResourceID != null) {
      if (resourcesDropdownValue === "technicians") {
        /* this happens when the task start from unassigned position & get moved to a new resource */
        const assignedTo: string[] = originalTaskDetails[
          "assignedTo"
        ] as string[];
        assignedTo.push(args.newResourceID);
        updatedTaskSpecificDetails["assignedTo"] = assignedTo;
      } else if (resourcesDropdownValue === "vehicles" && siteUsesVehicles) {
        /* this happens when the task start from unassigned position & get moved to a new resource */
        updatedTaskSpecificDetails["assignedVehicleID"] = args.newResourceID;
        if (
          dailyVehicleUsersMap[args.newResourceID] != null &&
          dailyVehicleUsersMap[args.newResourceID].length > 0
        ) {
          updatedTaskSpecificDetails["assignedTo"] =
            dailyVehicleUsersMap[args.newResourceID];
        } else {
          updatedTaskSpecificDetails["assignedTo"] = [];
        }
      }
    }

    /** for comparing the original taskSpecificDetails to the ones constructed above. */
    const compareTaskDetails: { [key: string]: any } = {};
    if (originalTaskDetails["scheduledServiceWindowStart"]) {
      compareTaskDetails["scheduledServiceWindowStart"] =
        originalTaskDetails["scheduledServiceWindowStart"];
    }
    if (originalTaskDetails["scheduledServiceWindowEnd"]) {
      compareTaskDetails["scheduledServiceWindowEnd"] =
        originalTaskDetails["scheduledServiceWindowEnd"];
    }
    if (originalTaskDetails["assignedVehicleID"]) {
      compareTaskDetails["assignedVehicleID"] =
        originalTaskDetails["assignedVehicleID"];
    }
    if (originalTaskDetails["lockAssignedVehicle"]) {
      compareTaskDetails["lockAssignedVehicle"] =
        originalTaskDetails["lockAssignedVehicle"];
    }

    const tsdDiff: DocumentData = diffObjects(
      compareTaskDetails,
      updatedTaskSpecificDetails,
    ).diff;

    // if there were no changes, don't update TODO:
    await handleUpdateTask(updateData, taskToBeUpdated.id);
    // Task doc needs to update before TSD. That's why we're not using Promise.all
    // if there were no changes, don't update TODO:
    await handleUpdateTSD(tsdDiff, taskToBeUpdated.refPath);
    return taskToBeUpdated.title;
  }

  /** Update the task doc's scheduling-related values */
  async function handleRescheduleTask(updatedTask: ExistingTask) {
    if (!selectedTask) {
      devLogger.error("taskDoc is null (and it shouldn't be)");
      return;
    }

    const { taskSpecificDetails, ...rest } = updatedTask;

    const taskDiff: DocumentData = diffObjects(selectedTask, rest).diff;
    const tsdDiff: DocumentData = diffObjects(
      selectedTask.taskSpecificDetails,
      taskSpecificDetails,
    ).diff;

    const taskNoChange = Object.keys(taskDiff).length === 0;
    const tsdNoChange = Object.keys(tsdDiff).length === 0;
    // return early if there's been no change
    if (taskNoChange && tsdNoChange) return;

    const updateData = {
      ...taskDiff,
      timestampLastModified: Timestamp.now(),
      lastModifiedBy: firebaseUser.uid,
    };

    // Don't want a try/catch here, that's handled within the dialog component.
    await handleUpdateTask(updateData, selectedTask.id);
    // Task doc needs to update before TSD. That's why we're not using Promise.all
    await handleUpdateTSD(tsdDiff, selectedTask.refPath);

    addMessage({
      id: createToastMessageID(),
      message: strings.successfulUpdate("Task"),
      dialog: false,
      type: "success",
    });
  }

  async function handleSaveNewCalendarEvent(
    formValues: Omit<StiltCalendarEvent, "start" | "end">,
  ): Promise<void> {
    if (!startDateIsEarlier) {
      addMessage({
        id: createToastMessageID(),
        message: strings.ERROR_START_DATE_OLDER,
        dialog: false,
        type: "error",
      });
      return;
    }
    const newCalendarEvent: StiltCalendarEvent = {
      ...formValues,
      start: convertLuxonDTToFSTimestamp(startDateNewCalendarEvent),
      end: convertLuxonDTToFSTimestamp(endDateNewCalendarEvent),
    };

    await mutateAddNewCalendarEvent.mutateAsync({
      newCalendarEvent,
    });
  }

  async function handleDeleteCalendarEvent() {
    if (!selectedStiltEvent) {
      throw new Error("handleDeleteCalendarEvent: No event selected");
    }

    await mutateDeleteStiltEvent.mutateAsync({
      stiltEventID: selectedStiltEvent.id,
    });
  }

  async function handleEditCalendarEvent(
    formValues: Omit<StiltCalendarEvent, "start" | "end">,
  ) {
    if (!selectedStiltEvent) {
      throw new Error("handleEditCalendarEvent: No event selected");
    }
    const updateData = {
      ...formValues,
      start: convertLuxonDTToFSTimestamp(startDateNewCalendarEvent),
      end: convertLuxonDTToFSTimestamp(endDateNewCalendarEvent),
    };

    await updateStiltEvent(updateData, selectedStiltEvent);
  }

  // async function assignVehicleTasksToUser() {
  //   setIsSavingUserToTasks(true);
  //   vehicleList.forEach((vehicle) => {
  //     const defaultSiteKeyUserID = vehicle.defaultSiteKeyUserID;
  //     const truck = vehicle.id;
  //     tasksWithAssignedVehicleID.forEach((task, index) => {
  //       if (task.taskSpecificDetails.assignedVehicleID === truck) {
  //         task.taskSpecificDetails["assignedTo"] = defaultSiteKeyUserID;
  //         //update the tasks list
  //         tasksWithAssignedVehicleID[index] = task;
  //       }
  //     });
  //   });
  //
  //   try {
  //     const promises = tasksWithAssignedVehicleID.map((task) => {
  //       const currentTask = scheduledAwaitingTaskList.find(
  //         (schedTask) => schedTask.id === task.id
  //       );
  //       if (currentTask) {
  //         const tsdDiff: DocumentData = diffObjects(
  //           currentTask.taskSpecificDetails,
  //           task.taskSpecificDetails
  //         ).diff;
  //
  //         return DbWrite.taskSpecificDetails.update(task.refPath, tsdDiff);
  //       } else {
  //         return null;
  //       }
  //     });
  //
  //     if (promises.length === 0) {
  //       devLogger.debug("Values aren't changed");
  //       return;
  //     }
  //
  //     await Promise.all(promises);
  //     addMessage({
  //       id: createToastMessageID(),
  //       message: strings.USERS_ASSIGNED_SUCCESSFULLY,
  //       type: "success",
  //     });
  //   } catch (error) {
  //     devLogger.error(`An error occurred during saveAssignedVehicleIDs`, error);
  //     addMessage({
  //       id: createToastMessageID(),
  //       message: strings.UNEXPECTED_ERROR,
  //       type: "error",
  //     });
  //   } finally {
  //     setIsSavingUserToTasks(false);
  //   }
  // }

  function sameDay(d1: Date, d2: Date) {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }

  const getEstimateInvoicePaymentTotals = useCallback(
    async function getEstimateInvoicePaymentTotals(tasks: ExistingTask[]) {
      let scheduledSales = 0;
      let invoiceTotals = 0;
      let paymentTotals = 0;
      let taskTotals = 0;
      setTotalsLoading(true);

      // Filter tasks is locationID is in the locationsDropdownValue
      if (locationsDropdownValue.length > 0) {
        tasks = tasks.filter((task) =>
          locationsDropdownValue.includes(task.locationID),
        );
      }
      // Create an array of promises to process tasks in parallel
      const taskPromises = tasks.map(async (task) => {
        if (
          task.timestampScheduled &&
          sameDay(
            task.timestampScheduled.toDate(),
            DateTime.fromJSDate(selectedDate)
              .set({
                hour: 7,
                minute: 0,
                second: 0,
              })
              .toJSDate(),
          )
        ) {
          taskTotals += 1;

          // Get the estimates and invoices
          const [estimates, invoices] = await Promise.all([
            DbRead.estimates.getByTaskId(siteKey, task.id),
            DbRead.invoices.getByTaskId(siteKey, task.id),
          ]);

          scheduledSales += getScheduledSaleForTask(estimates);

          for (const invoice of invoices) {
            invoiceTotals += invoice.totalAmount;
            paymentTotals += invoice.totalAmount - invoice.amountDue;
          }
        }
      });

      // Wait for all tasks to complete
      await Promise.all(taskPromises);

      setTotalsLoading(false);
      const data = {
        scheduledSales: scheduledSales,
        invoiceTotals: invoiceTotals,
        paymentTotals: paymentTotals,
        tasks: taskTotals,
      };
      setTotals({ ...data });
    },
    [locationsDropdownValue, selectedDate, siteKey],
  );

  useEffect(() => {
    function getScheduledAwaitingTaskList() {
      // typeguard
      if (!userPermissions) {
        devLogger.debug("No userPermissions yet...");
        return undefined;
      }
      devLogger.debug(
        `Running useEffect: ${getScheduledAwaitingTaskList.name}.`,
      );

      // Start loading.
      if (isMounted.current) setIsLoading(true);

      //setting the date selected to doesn't care about the hours selected but
      // check the entire day
      const startDate = DateTime.fromJSDate(selectedDate).set({
        hour: 0,
        minute: 0,
        second: 0,
      });
      const selectedDatePlus5 = DateTime.fromJSDate(selectedDate).plus({
        day: 5,
      });
      const endDate = selectedDatePlus5.set({
        hour: 23,
        minute: 59,
        second: 59,
      });

      // Query for all awaiting scheduled tasks via realtime updates.
      const unsubscribeScheduledAwaitingTaskList =
        DbRead.tasks.subscribeScheduledAwaitingTaskList({
          siteKey: siteKey,
          permissions: userPermissions,
          startDate: convertLuxonDTToFSTimestamp(startDate),
          endDate: convertLuxonDTToFSTimestamp(endDate),
          onChange: async (list) => {
            if (dontRenderDispatchBoard) {
              return;
            }
            const tasksWithCustomerLocations: ExistingTaskWithCustomerLocation[] =
              [];
            const customerLocationIDs = list.flatMap(
              (task) => task.customerLocationID ?? [],
            );
            const uniqueCustomerLocationIDs = Array.from(
              new Set(customerLocationIDs),
            );
            // Promises all customerLocations
            const customerLocationPromises = uniqueCustomerLocationIDs.map(
              (clID) => DbRead.customerLocations.getSingle(siteKey, clID),
            );
            const customerLocations = await Promise.all(
              customerLocationPromises,
            );
            for (const task of list) {
              const taskWithCL: ExistingTaskWithCustomerLocation = task;
              // query customerLocation for each task
              if (task.customerLocationID) {
                const cL = customerLocations.find(
                  (cl) => cl.id === task.customerLocationID,
                );
                taskWithCL.customerLocation = cL;
                tasksWithCustomerLocations.push(taskWithCL);
              }
            }
            setScheduledAwaitingTaskList(tasksWithCustomerLocations);
            getEstimateInvoicePaymentTotals(list);
          },
          onError: (error) =>
            devLogger.error(
              `Error in ${getScheduledAwaitingTaskList.name}: ${error.message}`,
            ),
        });

      // If user hasn't navigated away from the page, stop rendering the loading icon.
      if (isMounted.current) {
        setIsLoading(false);
      }
      return unsubscribeScheduledAwaitingTaskList;
    }

    const unsubscribe = getScheduledAwaitingTaskList();

    // Return an anonymous ƒn for cleanup.
    return () => {
      devLogger.debug(
        "(SchedulingContainer) getScheduledAwaitingTaskList useEffect return ƒn just invoked.",
      );
      isMounted.current = false;
      if (unsubscribe) unsubscribe();
    };
  }, [
    getEstimateInvoicePaymentTotals,
    selectedDate,
    siteKey,
    userPermissions,
    dontRenderDispatchBoard,
  ]);

  // Retrieve customer from selected invoice. For paying with a saved card.
  useEffect(() => {
    async function getCustomer() {
      if (!selectedInvoice) return;

      const customer = await DbRead.customers.get(
        siteKey,
        selectedInvoice.customerID,
      );
      setCurrentCustomer(customer);
    }

    getCustomer();
  }, [selectedInvoice, siteKey]);

  /**
   * Calendar events (as displayed on UI) and StiltCalendarEvents are not of
   * a one-to-one relationship. This function returns the StiltCalendarEvent that
   * corresponds to the calendar event that the user interacted with.
   */
  function getOriginalStiltEvent(
    stiltEventID: string,
    originalResourceID: string,
  ): ExistingStiltCalendarEvent | null {
    const found = stiltCalendarEvents.find(
      (ev) =>
        ev.id === stiltEventID &&
        (ev.assignedTo.includes(originalResourceID) ||
          ev.assignedVehicleIDs?.includes(originalResourceID)),
    );

    return found ?? null;
  }

  // useEffect(() => {
  //   // function watchScheduledAwaitingTaskList() {
  //   //   const filtered = scheduledAwaitingTaskList.filter((task) => {
  //   //     const timestampScheduled = task.timestampScheduled;
  //   //     const dateTimeScheduled = timestampScheduled
  //   //       ? DateTime.fromMillis(timestampScheduled.toMillis())
  //   //       : null;
  //   //     return dateTimeScheduled
  //   //       ? dateTimeScheduled.toISODate() === currentDateString
  //   //       : false;
  //   //   });
  //   //   setTasksAllocated(filtered);
  //   // }
  //   setTasksAllocated(scheduledAwaitingTaskList);

  //   // watchScheduledAwaitingTaskList();
  // }, [scheduledAwaitingTaskList]);

  // marked async because we need to make a DB call in containers that don't
  // already have access to the current work record.
  async function handleOpenTaskStatusChangeDialogDueToScheduleChange(args: {
    updatedTask: ExistingTask;
    originalTask: ExistingTask;
  }) {
    if (!siteKeyDoc) {
      // should never happen
      devLogger.error(
        `missing siteKey doc while opening task status change dialog. task id: ${args.updatedTask.id}`,
      );
      return;
    }

    // Get the siteKey's customizations for this task
    const targetWorkType = args.updatedTask.craftType;
    const targetTaskType = args.updatedTask.taskType;
    if (isValidCraftType(targetWorkType) && isValidTaskType(targetTaskType)) {
      const taskCustomFields = getTaskCustomFieldList({
        siteKey: siteKeyDoc,
        targetWorkType,
        targetTaskType,
      });
      setSkTaskCustomFields(taskCustomFields);
    }

    setTaskForTaskStatusChangeDialog(args.updatedTask);
    setOriginalTaskForTaskStatusChangeDialog(args.originalTask);
    setOpenTaskStatusChangeDialog(args.originalTask.id);
  }

  function existingEstimatesForTask(taskID: ExistingTask["id"]): boolean {
    const result = estimateList.filter(
      (estimate) => estimate.taskID === taskID,
    );
    if (result.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  async function payWithCardOnFile(args: {
    invoiceID: string;
    expiry: string;
    lastFour: number;
    amount: number;
  }): Promise<void> {
    if (!siteKeyDoc) throw Error("payWithCardOnFile missing siteKeyDoc");

    const processor = siteKeyDoc.customizations.accounting?.ziftAccountID
      ? "zift"
      : "paya";
    const data: APIPaymentSavedCard = {
      siteKeyID: siteKey,
      invoiceID: args.invoiceID,
      amount: args.amount,
      cardLastFour: args.lastFour,
      cardExpiry: args.expiry,
    };
    const valid = StiltPaymentManager.parseCreateWithSavedCard(data);
    await DbWrite.payments.createWithSavedCard(valid, processor);
  }

  /* COMPONENTS */
  const assignedToDialog = assignedUserList.current.length > 0 && (
    <MultipleUidDialog
      open={assignedToDialogOpen}
      closeDialog={() => {
        setAssignedToDialogOpen(false);
        setSelectedVehicleID(null);
        resetAssignedUserList(assignedUserList.current);
      }}
      userList={assignedUserList.current}
      handleSubmit={(event) => {
        const selectedList = event.filter((user) => user.isAssigned);
        const selectedUserIDs = selectedList.map((user) => user.uid);
        handleAssignUsersToVehicle(selectedUserIDs);
      }}
    />
  );

  const resourcesColumnDropdown = (
    <ResourcesColumnDropdown
      onSelection={(selectedValue: ColumnOption["value"]) =>
        setResourcesDropdownValue(selectedValue)
      }
      optionList={resourcesColumnSelectorOptions}
      initialValue={resourcesDropdownValue}
    />
  );

  const locationsColumnDropdown = locationsColumnSelectorOptions.length > 1 && (
    <SchedulingMultipleSelection
      onSelection={(multipleSelectionValue: ColumnOption["value"][]) =>
        setLocationsDropdownValue(multipleSelectionValue)
      }
      optionList={locationsColumnSelectorOptions}
      initialValue={locationsDropdownValue}
    />
  );

  const searchBox = (
    <SchedulingSearchBox
      placeholder="Search Tasks..."
      handleSearchTerm={setSearchTerm}
    />
  );

  const totalsMetrics = siteKeyDoc?.customizations?.estimatesEnabled &&
    siteKeyDoc?.customizations?.invoicesEnabled && (
      <Fragment>
        <div className="flex flex-row">
          <PillMetric
            key="totalTasks"
            text="Tasks"
            value={totals.tasks?.toString() ?? "0"}
            color="gray"
            // isLoading={totalsLoading}
          />
          <PillMetric
            key="totalEstimates"
            text="Estimates"
            value={currencyFormatter(
              totals.scheduledSales,
              siteKeyDoc?.customizations?.accounting?.currency ?? "USD",
            )}
            color="blue"
            // isLoading={totalsLoading}
          />
          <PillMetric
            key="totalInvoices"
            text="Invoiced"
            value={currencyFormatter(
              totals.invoiceTotals,
              siteKeyDoc?.customizations?.accounting?.currency ?? "USD",
            )}
            color="orange"
            // isLoading={totalsLoading}
          />
          <PillMetric
            key="totalPayments"
            text="Received"
            value={currencyFormatter(
              totals.paymentTotals,
              siteKeyDoc?.customizations?.accounting?.currency ?? "USD",
            )}
            color="green"
            // isLoading={totalsLoading}
          />
        </div>
      </Fragment>
    );

  const datePicker = (
    <div className="flex flex-row items-center">
      <StyledTooltip title="Previous Day">
        <button
          type="button"
          onClick={() => {
            const previousDay = DateTime.fromJSDate(selectedDate)
              .minus({ day: 1 })
              .set({ hour: 6 });
            setSelectedDate(previousDay.toJSDate());
          }}
          className="mr-2 h-10 rounded-md border border-gray-300 bg-white px-4 text-gray-800 shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2"
        >
          <ArrowLeft />
        </button>
      </StyledTooltip>
      {!sameDay(selectedDate, DateTime.now().toJSDate()) && (
        <StyledTooltip title="Today">
          <button
            type="button"
            onClick={() => {
              setSelectedDate(getCurrentJSDateWithoutSeconds());
            }}
            className="ml-1 mr-2 inline-flex h-10 items-center justify-center rounded-md border border-gray-300 bg-white p-2 text-gray-600 shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2"
          >
            <TodayIcon />
          </button>
        </StyledTooltip>
      )}
      <div className="w-60">
        <DatePicker
          selected={selectedDate}
          onChange={onChangeDatePicker}
          showTimeSelect={false}
          dateFormat="cccc - M/d/yyyy"
          customInput={<SchedulingButton />}
        />
      </div>
      <StyledTooltip title="Next Day">
        <button
          type="button"
          onClick={() => {
            const nextDay = DateTime.fromJSDate(selectedDate)
              .plus({ day: 1 })
              .set({ hour: 6 });
            setSelectedDate(nextDay.toJSDate());
          }}
          className="mx-2 h-10 rounded-md border border-gray-300 bg-white px-4 text-gray-800 shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2"
        >
          <ArrowRight />
        </button>
      </StyledTooltip>
    </div>
  );

  const taskLocation = selectedCustomerLocations.find(
    (location) => location.id === originalTaskForTaskStatusChangeDialog?.id,
  );

  const invoicesForTask = taskForTaskStatusChangeDialog
    ? workRecordInvoiceList.filter(
        (invoice) =>
          invoice.taskID === taskForTaskStatusChangeDialog.id &&
          invoice.status !== "canceled",
      )
    : [];

  /**
   * this taskStatus change dialog is part of the reschedule task flow.
   * not intended to be used with the change taskStatus button.
   */
  const taskStatusChangeDialog = workRecord &&
    taskForTaskStatusChangeDialog &&
    originalTaskForTaskStatusChangeDialog &&
    selectedTask && (
      <TaskStatusChangeDialog
        // DIALOG BASICS
        open={openTaskStatusChangeDialog === selectedTask.id}
        onClose={() => {
          setOpenTaskStatusChangeDialog(null);
          setShowCraftPersistence(false);
          setNextTaskStatus(null);
        }}
        // DATA
        showCraftPersistence={showCraftPersistence}
        workRecordTitle={workRecord.title}
        task={selectedTask}
        originalTask={selectedTask}
        isReschedulingTask={false}
        siteKeyCustomFields={taskCustomFields}
        nextTaskStatus={nextTaskStatus}
        userList={assignedUserList.current}
        userDisplayNamesMap={filterUserDisplayNamesMap}
        uid={firebaseUser.uid}
        handleUpdateTask={handleUpdateTask}
        handleUpdateTSD={handleUpdateTSD}
        customerLocation={taskLocation ?? selectedCustomerLocations[0]}
        emailList={emailList}
        createFollowUpTask={handleCreateFollowUpTask}
        invoices={invoicesForTask}
      />
    );

  const editTaskDialog = selectedTask && (
    <EditTaskDialog
      isOpen={openEditTaskDialog}
      onClose={() => {
        setOpenEditTaskDialog(false);
      }}
      siteKey={siteKey}
      task={selectedTask}
      membershipsEnabled={
        siteKeyDoc?.customizations?.membershipsEnabled === true
      }
      assetsEnabled={siteKeyDoc?.customizations?.assetsEnabled === true}
      siteKeyCustomFields={skTaskCustomFields}
      handleEditTask={handleEditTask}
      userList={assignedUserList.current}
      userDisplayNamesMap={filterUserDisplayNamesMap}
      customerContacts={customerContacts}
    />
  );

  const startDatePicker = (
    <div className="grid grid-cols-2 gap-4">
      <div className="flex items-center justify-between gap-4">
        <label className="whitespace-nowrap text-gray-500">Start Date: </label>
        <div>
          <DatePicker
            selected={startDateNewCalendarEvent.toJSDate()}
            onChange={(date: Date) => {
              const luxonDate = DateTime.fromJSDate(date);
              setStartDateNewCalendarEvent(luxonDate);
            }}
            showTimeSelect={true}
            customInput={<SchedulingButton />}
          />
        </div>
      </div>
      <div className="flex items-center justify-end gap-4 whitespace-nowrap">
        <label className="text-gray-500">Start Time: </label>
        <span>{displayStartTime}</span>
      </div>
    </div>
  );

  const endDatePicker = (
    <div className="grid grid-cols-2 gap-4">
      <div className="flex items-center justify-between gap-4">
        <label className="whitespace-nowrap text-gray-500">End Date: </label>
        <div>
          <DatePicker
            selected={endDateNewCalendarEvent.toJSDate()}
            onChange={(date: Date) => {
              const luxonDate = DateTime.fromJSDate(date);
              setEndDateNewCalendarEvent(luxonDate);
            }}
            showTimeSelect={true}
            customInput={<SchedulingButton />}
            minDate={startDateNewCalendarEvent.toJSDate()}
          />
        </div>
      </div>
      <div className="flex items-center justify-end gap-4 whitespace-nowrap">
        <label className="text-gray-500">End Time: </label>
        <span>{displayEndTime}</span>
      </div>
    </div>
  );

  const addStiltCalendarEventDialog = addNewEventDialogOpen && (
    <AddStiltCalendarEventDialog
      isOpen={addNewEventDialogOpen}
      onClose={() => {
        setAddNewEventDialogOpen(false);
        resetCalendarEventDatesAndResource();
      }}
      assignedTo={resourceID}
      assignedVehicleIDs={resourceID}
      resourcesMode={
        resourcesDropdownValue === "vehicles" && siteUsesVehicles
          ? "vehicles"
          : "technicians"
      }
      siteUsesVehicles={siteUsesVehicles}
      vehicleList={vehicleList}
      handleSave={handleSaveNewCalendarEvent}
      userList={assignedUserList.current}
    >
      {{
        StartDatePicker: startDatePicker,
        EndDatePicker: endDatePicker,
      }}
    </AddStiltCalendarEventDialog>
  );

  const editStiltCalendarEventDialog = editStiltEventDialogOpen &&
    selectedStiltEvent && (
      <EditStiltCalendarEventDialog
        isOpen={editStiltEventDialogOpen}
        stiltEvent={selectedStiltEvent}
        onClose={() => {
          setEditStiltEventDialogOpen(false);
          setSelectedStiltEvent(null);
          resetCalendarEventDatesAndResource();
        }}
        handleSave={handleEditCalendarEvent}
        handleDelete={handleDeleteCalendarEvent}
        userList={assignedUserList.current}
        vehicleList={vehicleList}
      >
        {{
          StartDatePicker: startDatePicker,
          EndDatePicker: endDatePicker,
        }}
      </EditStiltCalendarEventDialog>
    );

  const openJobButton = selectedTask && (
    <BaseButtonPrimary
      className="flex gap-2 uppercase"
      onClick={() => {
        // const lux = DateTime.fromJSDate(selectedDate);
        // localStorage.setItem(
        //   "schedulingContainerCurrentDate",
        //   JSON.stringify(lux.toMillis()),
        // );
        goToWorkRecordAndTasksPage(selectedTask.craftRecordID);
      }}
    >
      {strings.buttons.OPEN_JOB}
    </BaseButtonPrimary>
  );

  // const saveAllocateVehiclesBtn = (
  //   <BaseButtonPrimary
  //     className="flex gap-2 uppercase"
  //     onClick={saveAssignedVehicleIDs}
  //     isBusy={isSavingAssignedVehicle}
  //     busyText={strings.buttons.BUSY_SAVING}
  //   >
  //     {strings.buttons.SAVE}
  //   </BaseButtonPrimary>
  // );

  const createTaskBtn = (
    <BaseButtonPrimary
      className="flex gap-2 uppercase"
      onClick={() => setCreateTaskDialogOpen(true)}
    >
      <AddIcon />
      {strings.CREATE_JOB}
    </BaseButtonPrimary>
  );

  const createTaskDialog = createTaskDialogOpen && (
    <BaseModal
      open={createTaskDialogOpen}
      closeModal={() => {}}
      allowOverflowY={true}
      title={
        <div className="flex items-center justify-between rounded-t-lg bg-primary p-6 text-xl font-semibold text-white md:px-8">
          <h1>{strings.CREATE_JOB}</h1>
          <button
            onClick={() => setCreateTaskDialogOpen(false)}
            className="focus-visible:outline focus-visible:outline-white"
          >
            <XMarkIcon aria-label="close dialog" className="h-6 w-6" />
          </button>
        </div>
      }
      parentDivStyles="text-left max-w-md md:max-w-5xl h-screen"
    >
      <div className="mt-8">
        <CreateTaskForCustomerContainer
          siteKey={siteKey}
          setCreateTaskDialogOpen={setCreateTaskDialogOpen}
          startDate={startDateNewCalendarEvent.toJSDate()}
          eventDuration={eventDuration}
          assignedTo={resourceID}
        />
      </div>
    </BaseModal>
  );

  const addNewEventBtn = (
    <BaseButtonSecondary
      className="flex gap-2 uppercase"
      onClick={() => {
        setStartDateNewCalendarEvent(DateTime.now());
        setEndDateNewCalendarEvent(DateTime.now().plus({ hours: 1 }));
        setAddNewEventDialogOpen(true);
      }}
    >
      <EventIcon />
      {strings.buttons.ADD_NEW_EVENT}
    </BaseButtonSecondary>
  );

  const configureZonesBtn = (
    <BaseButtonSecondary
      className="flex gap-2 uppercase"
      onClick={() => navigate(`${ZONES_URL}`)}
    >
      <SettingsSuggest />
      {strings.ZONES}
    </BaseButtonSecondary>
  );

  const hideResourcesNotInUseBtn = (
    <StyledTooltip title="Hide vehicles/technicians with no tasks">
      <BaseButtonSecondary
        className={`${isResourcesHidden && "bg-yellow-400 hover:bg-yellow-200"}`}
        onClick={() => {
          setIsResourcesHidden(!isResourcesHidden);
          if (calendarRef != null) {
            calendarRef.current?.control.columns.filter(!isResourcesHidden);
          }
          if (schedulerRef != null) {
            schedulerRef.current?.control.rows.filter(!isResourcesHidden);
          }
        }}
      >
        <HideSourceIcon className="text-gray-600" />
      </BaseButtonSecondary>
    </StyledTooltip>
  );

  const selectCreationChoiceDialog = (
    <ChooseCreateJobOrEventDialog
      open={selectCreationChoiceDialogOpen}
      onClose={() => {
        setSelectCreationChoiceDialogOpen(false);
      }}
      onEventSelected={() => {
        setSelectCreationChoiceDialogOpen(false);
        setAddNewEventDialogOpen(true);
      }}
      onJobSelected={() => {
        setSelectCreationChoiceDialogOpen(false);
        setCreateTaskDialogOpen(true);
      }}
    />
  );

  function renderEstimateIconCell(params: ICellRendererParams) {
    const currentInvoice = workRecordInvoiceList.find(
      (invoice) => invoice.estimateID === params.data.id,
    );
    let iconToDisplay: JSX.Element;
    if (currentInvoice && currentInvoice.status !== "paid") {
      iconToDisplay = (
        <StyledTooltip title="Handle Payment">
          <PaymentIconWithSpinner
            onCreate={async () => {
              setIsTableHandlePaymentDialogOpen(true);
              setSelectedInvoice(currentInvoice);
            }}
          />
        </StyledTooltip>
      );
    } else if (currentInvoice && currentInvoice.status === "paid") {
      iconToDisplay = (
        <StyledTooltip title="Send Receipt">
          <EmailButtonWithSpinner
            onClick={async () => {
              setSelectedInvoice(currentInvoice);
              setHandleSendEmailDialogOpen(true);
            }}
          />
        </StyledTooltip>
      );
    } else {
      iconToDisplay = (
        <StyledTooltip title="Create Invoice">
          <InvoiceIconWithSpinner
            onCreate={async () => {
              await handleCreateInvoice(params.data);
            }}
          />
        </StyledTooltip>
      );
    }

    return (
      <div className="flex items-center gap-2">
        {iconToDisplay}
        <StyledTooltip title="Download PDF">
          <PDFIconWithSpinner
            onCreate={async () => getEstimatePDF(params.data.id)}
          />
        </StyledTooltip>
      </div>
    );
  }

  const viewEstimateDialog = selectedEstimate &&
    selectedCustomer &&
    selectedEstimateLocation && (
      <ViewEstimateDialog
        isOpen={viewEstimateDialogOpen}
        onClose={() => {
          resetSelectedEstimate();
          setViewEstimateDialogOpen(false);
        }}
        estimate={selectedEstimate}
      >
        {{
          ViewEstimatePage: (
            <ViewEstimateContainer
              renderedInDialog={true}
              siteKey={siteKey}
              onEstimateDeleted={() => {
                setEstimateListDialogOpen(false);
              }}
              optionalDocs={{
                estimateID: selectedEstimate.id,
                customerDoc: selectedCustomer,
                customerLocationDoc: selectedEstimateLocation,
                invoiceDoc: selectedInvoice,
              }}
            />
          ),
        }}
      </ViewEstimateDialog>
    );

  const manualPaymentDatePicker = selectedInvoice && (
    <DatePicker
      selected={selectedDateTime.toJSDate()}
      onChange={(date: Date) => {
        const luxonDate = DateTime.fromJSDate(date);
        setSelectedDateTime(luxonDate);
      }}
      showTimeSelect
      customInput={<SchedulingButton />}
    />
  );

  /** this one opens from within ViewInvoiceDialog */
  const handlePaymentDialog = isHandlePaymentDialogOpen &&
    siteKeyDoc &&
    selectedInvoice &&
    userPermissions &&
    currentCustomer && (
      <HandlePaymentDialog
        isDialogOpen={isHandlePaymentDialogOpen}
        closeDialog={() => {
          setIsHandlePaymentDialogOpen(false);
          setInvoicePaymentLink(null);
        }}
        goToPaymentPage={handleGoToPaymentPage}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin}
        invoiceID={selectedInvoice.id}
        invoiceStatus={selectedInvoice.status}
        invoiceAmount={selectedInvoice.amountDue}
        invoiceSentToCustomer={selectedInvoice.timestampSentToCustomer}
        customer={currentCustomer}
        payWithCardOnFile={payWithCardOnFile}
        defaultIncludePhotos={
          siteKeyDoc.customizations.defaultIncludeJobPhotos ?? false
        }
        emailList={getEmailList(selectedInvoice, currentCustomer)}
        sendEmail={sendEmailFromHandlePaymentDialog}
        paymentMethods={
          siteKeyDoc.customizations.manualPaymentMethods ?? [...paymentMethods]
        }
        applyManualPayment={applyManualPayment}
      >
        {{
          DatePicker: manualPaymentDatePicker,
        }}
      </HandlePaymentDialog>
    );

  /** this one opens when the Estimate List table row's action button is clicked */
  const tableHandlePaymentDialog = isTableHandlePaymentDialogOpen &&
    siteKeyDoc &&
    selectedInvoice &&
    userPermissions &&
    currentCustomer && (
      <HandlePaymentDialog
        isDialogOpen={isTableHandlePaymentDialogOpen}
        closeDialog={() => setIsTableHandlePaymentDialogOpen(false)}
        goToPaymentPage={handleGoToPaymentPage}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin}
        invoiceID={selectedInvoice.id}
        invoiceStatus={selectedInvoice.status}
        invoiceAmount={selectedInvoice.amountDue}
        invoiceSentToCustomer={selectedInvoice.timestampSentToCustomer}
        customer={currentCustomer}
        payWithCardOnFile={payWithCardOnFile}
        defaultIncludePhotos={
          siteKeyDoc.customizations.defaultIncludeJobPhotos ?? false
        }
        emailList={getEmailList(selectedInvoice, currentCustomer)}
        sendEmail={sendEmailFromHandlePaymentDialog}
        paymentMethods={
          siteKeyDoc.customizations.manualPaymentMethods ?? [...paymentMethods]
        }
        applyManualPayment={applyManualPayment}
      >
        {{
          DatePicker: manualPaymentDatePicker,
        }}
      </HandlePaymentDialog>
    );

  const handleSendReceiptDialog = selectedInvoice && (
    <HandleSendEmailDialog
      defaultIncludeJobPhotos={
        siteKeyDoc?.customizations.defaultIncludeJobPhotos ?? false
      }
      isDialogOpen={handleSendEmailDialogOpen}
      closeDialog={() => setHandleSendEmailDialogOpen(false)}
      sendEmailReceipt={handleEmailReceipt}
      title={strings.SEND_RECEIPT_TO_CUSTOMER}
      merchantName={siteKeyDoc?.name ?? ""}
      timestampSentToCustomer={selectedInvoice.timestampSentToCustomer}
      customerEmailList={emailList}
    />
  );

  const dueDatePicker = selectedInvoice && (
    <div className="flex flex-col">
      {selectedDueDate ? `Due Date` : "Due Date Not Selected"}
      <div className="mt-2 flex items-center justify-between gap-4">
        <DatePicker
          selected={
            selectedDueDate
              ? selectedDueDate.toJSDate()
              : DateTime.now().toJSDate()
          }
          onChange={(date: Date) => {
            const luxonDate = DateTime.fromJSDate(date);
            setSelectedDueDate(luxonDate);
            setPaymentTerms(null);
          }}
          customInput={<SchedulingButton />}
        />
      </div>
    </div>
  );

  const issueDatePicker = selectedInvoice && (
    <div className="flex flex-col">
      {selectedIssueDate ? `Issue Date` : "Issue Date Not Selected"}
      <div className="mt-2 flex items-center justify-between gap-4">
        <DatePicker
          selected={
            selectedIssueDate
              ? selectedIssueDate.toJSDate()
              : DateTime.now().toJSDate()
          }
          onChange={(date: Date) => {
            const luxonDate = DateTime.fromJSDate(date);
            setSelectedIssueDate(luxonDate);
            setPaymentTerms(null);
          }}
          customInput={<SchedulingButton />}
        />
      </div>
    </div>
  );

  const paymentTermsSelector = (
    <BaseInputSelect
      inputName="paymentTerms"
      text="Payment Terms"
      admin={true}
      required={true}
      className="capitalize"
      value={paymentTerms === null ? "" : paymentTerms}
      onChange={handlePaymentTermsChange}
    >
      <option value="">None</option>
      {Object.entries(templatesPaymentTerms).map(([key, paymentTerm]) => {
        return (
          <option key={key} value={key}>
            {paymentTerm.title}
          </option>
        );
      })}
    </BaseInputSelect>
  );

  const editInvoiceDialog = selectedInvoice && (
    <EditInvoiceDialog
      isDialogOpen={editInvoiceDialogOpen}
      closeDialog={() => setEditInvoiceDialogOpen(false)}
      invoiceDoc={selectedInvoice}
      handleSave={handleSaveUpdatedInvoice}
      dueDate={dueDatePicker}
      issueDate={issueDatePicker}
      paymentTerms={paymentTermsSelector}
    />
  );

  const viewInvoiceDialog = isViewInvoiceDialogOpen &&
    viewInvoiceDialogProps &&
    userPermissions &&
    !userDisplayNamesMapIsLoading && (
      <ViewInvoiceDialog
        isOpen={isViewInvoiceDialogOpen}
        onClose={closeViewInvoiceDialog}
        invoiceID={viewInvoiceDialogProps["invoiceID"]}
        siteKeyID={viewInvoiceDialogProps["siteKeyID"]}
        merchantLogoURL={viewInvoiceDialogProps["merchantLogoURL"]}
        merchantName={viewInvoiceDialogProps["merchantName"]}
        handleRefund={handleRefund}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin === true}
        editInvoice={handleEditInvoiceDialogOpen}
        sendEmail={emailInvoice}
        handlePayment={onClickHandlePaymentButton}
        deleteInvoice={handleDeleteInvoice}
        userDisplayNamesMap={userDisplayNamesMap}
        handleDeletePayment={handleDeletePayment}
      >
        {{
          EditInvoiceDialog: editInvoiceDialog,
          HandlePaymentDialog: handlePaymentDialog,
        }}
      </ViewInvoiceDialog>
    );

  // Display loading clipboard while data loads.
  if (vehicleListIsLoading || isLoading || !siteKeyDoc || zoneListIsLoading) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  const calendarConfig: CalendarProps = {
    // columnWidthSpec: "Fixed" as any,
    // columnWidth: 100,
    bubble: stiltTaskBubble(siteKeyDoc),
    cellDuration: 60,
    // cellWidth: 120,
    cellHeight: 50,
    eventDeleteHandling: "Disabled" as any,
    headerHeight: 60,
    headerTextWrappingEnabled: false,
    heightSpec: "Parent100Pct" as any,
    startDate: currentDateString,
    timeRangeSelectedHandling: "Enabled" as any,
    useEventBoxes: "Never" as any,
    viewType: "Resources" as any,
    dynamicEventRendering: "Disabled" as any,
    onColumnFilter: (args: DayPilot.CalendarColumnFilterArgs) => {
      args.visible = !args.column.events.isEmpty();
    },
    onBeforeHeaderRender: (args: DayPilot.CalendarBeforeHeaderRenderArgs) => {
      customizeHeader(
        args,
        calendarEvents,
        resourcesDropdownValue,
        DateTime.fromJSDate(selectedDate),
      );
    },
    onEventClicked: (args: DayPilot.CalendarEventClickedArgs) => {
      calendarEventClicked(args.e.data);
    },
    onEventMoved: async (args: DayPilot.CalendarEventMovedArgs) => {
      await calendarEventMoved({
        daypilot: args,
        getOriginalStiltEvent: getOriginalStiltEvent,
        updateTask: updateTask,
        updateStiltEvent: updateStiltEvent,
        addMessage: addMessage,
        resourcesMode: resourcesDropdownValue,
      });
      setDontRenderDispatchBoard(false);
    },
    onEventMoving: (_args: DayPilot.CalendarEventMovingArgs) => {
      setDontRenderDispatchBoard(true);
    },
    onBeforeEventRender: (args: DayPilot.CalendarBeforeEventRenderArgs) => {
      if (args.data.backColor) {
        args.data.fontColor = getFontColorForBackground(args.data.backColor);
      }
      if (args.data.dotColor) {
        args.data.areas = [
          {
            right: 2,
            top: "calc(100% - 10px)",
            width: 8,
            height: 8,
            symbol: "icons/daypilot.svg#checkmark-2",
            backColor: args.data.dotColor,
            fontColor: "#ffffff",
            padding: 2,
            style: "border-radius: 50%",
          },
        ];
      }
    },
    onTimeRangeSelected: (daypilot: DayPilot.CalendarTimeRangeSelectedArgs) => {
      handleTimeRangeSelected({
        daypilot,
        setEndDateNewCalendarEvent,
        setStartDateNewCalendarEvent,
        setResourceID,
        setEventDuration,
        setSelectCreationChoiceDialogOpen,
      });
    },
    onEventResized: async (args: DayPilot.CalendarEventResizedArgs) => {
      await calendarEventResized({
        daypilot: args,
        getOriginalStiltEvent: getOriginalStiltEvent,
        updateTask: updateTask,
        updateStiltEvent: updateStiltEvent,
        addMessage: addMessage,
      });
    },
    onHeaderClicked: (args: DayPilot.CalendarHeaderClickedArgs) => {
      if (resourcesDropdownValue === "vehicles") {
        const selectedVehicle = args.column.id as string;
        setSelectedVehicleID(selectedVehicle);
        setAssignedToDialogOpen(true);
        const assignedUsersForSelectedVehicle =
          dailyVehicleUsersMap[selectedVehicle];

        for (const [vehicleID, assignedUsers] of Object.entries(
          dailyVehicleUsersMap,
        )) {
          if (selectedVehicle === vehicleID) continue;

          const vehicleDoc = vehicleList.filter(
            (vehicle) => vehicle.id === vehicleID,
          );
          assignedUserList.current.forEach((user) => {
            if (assignedUsers.includes(user.uid)) {
              user.assignedVehicle = vehicleDoc[0].title;
            }
          });
        }

        if (assignedUsersForSelectedVehicle?.length > 0) {
          assignedUserList.current.forEach((user) => {
            if (assignedUsersForSelectedVehicle.includes(user.uid)) {
              user.isAssigned = true;
            }
          });
        }
      }
    },
  };

  const nowISO = DateTime.now().toISO({
    includeOffset: false,
    suppressMilliseconds: true,
  });
  const cellWidth = 100;

  const calendarConfigScheduler: SchedulerProps = {
    businessBeginsHour: 6,
    businessEndsHour: 20,
    cellWidth: cellWidth,
    days: 7,
    eventStackingLineHeight: 85,
    eventTextWrappingEnabled: true,
    dynamicEventRendering: "Disabled" as any,
    heightSpec: "Parent100Pct" as any,
    scale: "Hour" as any,
    separators: [{ color: "red", location: nowISO }],
    startDate: currentDateString,
    timeHeaders: [
      { groupBy: "Day" as any, format: "dddd - M/d/yyyy" },
      { groupBy: "Cell" as any },
    ],
    treeEnabled: true,
    treePreventParentUsage: true,
    useEventBoxes: "Never" as any,
    onRowFilter: (args: DayPilot.SchedulerRowFilterArgs) => {
      args.visible = !args.row.events.isEmpty();
    },
    onEventClicked: (args: DayPilot.SchedulerEventClickedArgs) => {
      calendarEventClicked(args.e.data);
    },
    onEventMoved: async (args: DayPilot.SchedulerEventMovedArgs) => {
      await calendarEventMoved({
        daypilot: args,
        getOriginalStiltEvent: getOriginalStiltEvent,
        updateTask: updateTask,
        updateStiltEvent: updateStiltEvent,
        addMessage: addMessage,
        resourcesMode: resourcesDropdownValue,
      });
    },
    onBeforeCellRender: (args: DayPilot.SchedulerBeforeCellRenderArgs) => {
      if (args.cell.isParent) {
        args.cell.properties.backColor = "#dddddd";
      }
    },
    onBeforeEventRender: (args: DayPilot.SchedulerBeforeEventRenderArgs) => {
      if (args.data.backColor) {
        args.data.fontColor = getFontColorForBackground(args.data.backColor);
      }
      if (args.data.dotColor) {
        args.data.areas = [
          {
            right: 2,
            top: "calc(100% - 10px)",
            width: 8,
            height: 8,
            symbol: "icons/daypilot.svg#checkmark-2",
            backColor: args.data.dotColor,
            fontColor: "#ffffff",
            padding: 2,
            style: "border-radius: 50%",
          },
        ];
      }
    },
    bubble: stiltTaskBubble(siteKeyDoc),
    onTimeRangeSelected: (
      daypilot: DayPilot.SchedulerTimeRangeSelectedArgs,
    ) => {
      handleTimeRangeSelected({
        daypilot,
        setEndDateNewCalendarEvent,
        setStartDateNewCalendarEvent,
        setResourceID,
        setEventDuration,
        setSelectCreationChoiceDialogOpen,
      });
    },
    onEventResized: async (args: DayPilot.SchedulerEventResizedArgs) => {
      await calendarEventResized({
        daypilot: args,
        getOriginalStiltEvent: getOriginalStiltEvent,
        updateTask: updateTask,
        updateStiltEvent: updateStiltEvent,
        addMessage: addMessage,
      });
    },
  };

  /** vertical */
  const dayPilotCalendar = (
    <DayPilotCalendar
      {...calendarConfig}
      columns={filteredResourcesForCalendar}
      events={calendarEvents}
      ref={calendarRef}
    />
  );

  /** horizontal */
  const dayPilotScheduler = (
    <DayPilotScheduler
      {...calendarConfigScheduler}
      resources={resourcesForScheduler}
      events={calendarEvents}
      ref={schedulerRef}
      controlRef={setSchedulerState}
    />
  );

  const tableOrientationBtn = (
    <StyledTooltip title="Change Table Orientation">
      <BaseButtonSecondary
        type="button"
        onClick={() =>
          setTableOrientationIsHorizontal(!tableOrientationIsHorizontal)
        }
        className="rounded-md p-2"
      >
        <PivotTableChart></PivotTableChart>
      </BaseButtonSecondary>
    </StyledTooltip>
  );

  const rescheduleTaskDialog = siteKeyDoc &&
    userPermissions &&
    selectedTask && (
      <RescheduleTaskDialog
        isOpen={openRescheduleTaskDialog}
        onClose={() => {
          setOpenRescheduleTaskDialog(false);
          setSelectedTask(null);
        }}
        handleRescheduleTask={handleRescheduleTask}
        siteKey={siteKeyDoc}
        siteKeyUserPermissions={userPermissions}
        task={selectedTask}
        handleOpenTaskStatusChangeDialogDueToScheduleChange={
          handleOpenTaskStatusChangeDialogDueToScheduleChange
        }
        permissionsMap={permissionsMap}
        scheduledAwaitingTaskList={scheduledAwaitingTaskList}
        siteKeyUsersList={siteKeyUsersList}
        siteLocationList={siteKeyLocationList}
        vehicleList={vehicleList}
      />
    );

  const estimateListForTaskDialog = estimateListDialogOpen &&
    estimateListForTable && (
      <EstimateListDialog
        isOpen={estimateListDialogOpen}
        onClose={() => setEstimateListDialogOpen(false)}
        estimateListForTable={estimateListForTable}
        handleGoToViewEstimate={handleGoToViewEstimate}
        customerLocations={selectedCustomerLocations}
        renderIconCell={renderEstimateIconCell}
        estimateItemList={estimateItemList}
        invoiceList={workRecordInvoiceList}
        currency={siteKeyDoc?.customizations.accounting?.currency ?? "USD"}
      >
        {{
          ViewEstimateDialog: viewEstimateDialog,
          HandlePaymentDialog: tableHandlePaymentDialog,
          ViewInvoiceDialog: viewInvoiceDialog,
          ConfirmationDialog: null,
          HandleSendReceiptDialog: handleSendReceiptDialog,
          InvoiceListForWRDialog: null,
        }}
      </EstimateListDialog>
    );

  const taskDetailsDialog = selectedTask && userPermissions && siteKeyDoc && (
    <TaskDetailsDialog
      title={selectedTask.title}
      isDialogOpen={taskDetailsDialogOpen}
      closeDialog={() => {
        setTaskDetailsDialogOpen(false);
        setSelectedTask(null);
      }}
    >
      {{
        OpenJobButton: openJobButton,
        TaskCard: (
          <TaskCardOpen
            key={selectedTask.id}
            task={selectedTask}
            existingEstimatesForTask={existingEstimatesForTask(selectedTask.id)}
            handleDeleteTask={handleDeleteTask}
            handleOpenRTD={handleOpenRTD}
            handleOpenEditTaskDialog={handleOpenEditTaskDialog}
            handleCreateNewEstimate={handleCreateNewEstimate}
            handleOpenEstimateListDialog={handleOpenEstimateListDialog}
          >
            {{
              detailsAndEventsPanels: (
                <DetailsAndEventsPanels
                  details={selectedTask.taskSpecificDetails}
                  siteKeyCustomFields={taskCustomFields}
                  userDisplayNamesMap={filterUserDisplayNamesMap}
                  events={eventList}
                  vehicleList={vehicleList}
                />
              ),
              changeTaskStatusButton: (
                <ChangeTaskStatusButton
                  task={selectedTask}
                  userPermissions={userPermissions}
                  siteKeyDoc={siteKeyDoc}
                  uid={firebaseUser.uid}
                  handleUpdateTask={handleUpdateTask}
                  setOpenTaskStatusChangeDialog={setOpenTaskStatusChangeDialog}
                  setShowCraftPersistence={setShowCraftPersistence}
                  setNextTaskStatus={setNextTaskStatus}
                />
              ),
            }}
          </TaskCardOpen>
        ),
        EditTaskDialog: editTaskDialog,
        RescheduleTaskDialog: rescheduleTaskDialog,
        TaskStatusChangeDialog: taskStatusChangeDialog,
        EstimateListForTaskDialog: estimateListForTaskDialog,
      }}
    </TaskDetailsDialog>
  );

  return (
    <Fragment>
      <SchedulingPage>
        {{
          SearchBox: searchBox,
          Totals: totalsMetrics,
          DayPilotCalendar: tableOrientationIsHorizontal
            ? dayPilotScheduler
            : dayPilotCalendar,
          SelectColumn: resourcesColumnDropdown,
          LocationsColumn: locationsColumnDropdown,
          // OptimizeBtn: optimizeBtn,
          // AssignBtn: assignBtn,
          ZonesBtn: configureZonesBtn,
          HideResourcesBtn: hideResourcesNotInUseBtn,
          NewEventBtn: addNewEventBtn,
          CreateTaskBtn: createTaskBtn,
          // SaveAllocateVehiclesBtn: saveAllocateVehiclesBtn,
          DatePicker: datePicker,
          TableOrientationBtn: tableOrientationBtn,
        }}
      </SchedulingPage>
      {taskDetailsDialog}
      {addStiltCalendarEventDialog}
      {editStiltCalendarEventDialog}
      {createTaskDialog}
      {selectCreationChoiceDialog}
      {assignedToDialog}
    </Fragment>
  );
}

// SECTION: HELPERS

type UpdateStiltEventType = {
  start: Timestamp;
  end: Timestamp;
  assignedTo?: string[];
  assignedVehicleIDs?: string[];
};

type Ref = HTMLButtonElement;

export const SchedulingButton = forwardRef<
  Ref,
  React.ComponentPropsWithRef<"button">
>(({ value, onClick }, ref) => (
  <button
    className={`inline-flex items-center justify-center rounded-md border border-gray-400 bg-white px-3 py-2 transition-colors hover:border-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2`}
    onClick={onClick}
    ref={ref}
    id="dateAndTime"
    data-testid="dateTimeButton"
  >
    <CalendarMonthIcon className="mr-2 text-gray-600" />
    {value}
  </button>
));

export const isTimestamp = (timestamp: any): timestamp is Timestamp => {
  return (
    typeof timestamp === "object" && timestamp.seconds && timestamp.nanoseconds
  );
};

function getFormattedSiteLocations(
  siteKeyLocationList: ExistingSiteKeyLocation[],
) {
  const columnOptions: ColumnOption[] = siteKeyLocationList.map(
    (siteLocation) => {
      return {
        text: siteLocation.title,
        value: siteLocation.id,
      };
    },
  );
  return columnOptions;
}

function getSiteKeyLocationSelected(
  siteKeyLocationList: ExistingSiteKeyLocation[],
  locationsDropdownValue: ColumnOption["value"][],
): ExistingSiteKeyLocation[] {
  if (locationsDropdownValue.length === 0 || siteKeyLocationList.length === 1) {
    return siteKeyLocationList;
  } else {
    const result: ExistingSiteKeyLocation[] = [];
    locationsDropdownValue.forEach((selectedLocation) => {
      const found = siteKeyLocationList.find(
        (siteLocation) => siteLocation.id === selectedLocation,
      );

      if (found) {
        result.push(found);
      }
    });
    return result;
  }
}

export function stiltTaskBubble(siteKey: ExistingSiteKey) {
  return new Bubble({
    animated: true,
    showAfter: 2,
    theme: "bubble_default",
    position: "Mouse",
    onLoad: async (args) => {
      args.async = true;
      const e = args.source;

      let htmlString = "";
      if (e.data.tags.craftRecordRefPath) {
        const craftRecordData = await DbRead.parentRecords.getByRefPath(
          e.data.tags.craftRecordRefPath,
        );
        htmlString +=
          "<b>Job Title: </b>" +
          (craftRecordData?.title ?? "--") +
          "<br/>" +
          "<b>Job Description: </b>" +
          (craftRecordData?.description ?? "--") +
          "<br/>" +
          "<b>Task Description: </b>" +
          (e.data.tags.task?.description ?? "--") +
          "<br/><br/>";
        if (e.data.tags.task.id) {
          const estimates = await DbRead.estimates.getByTaskId(
            e.data.tags.craftRecordRefPath.split("/")[1],
            e.data.tags.task.id,
          );
          estimates.sort(
            (a, b) =>
              a.timestampCreated.toMillis() - b.timestampCreated.toMillis(),
          );
          let estimateCount = 1;
          for (const estimate of estimates) {
            const estimateItems = await DbRead.estimateItems.getByEstimateId(
              e.data.tags.craftRecordRefPath.split("/")[1],
              estimate.id,
            );
            let estimateItemsString = "";
            estimateItems.forEach(
              (e) => (estimateItemsString += e.title + "<br/>"),
            );
            if (estimateCount === 1) {
              htmlString += "<b>Original Estimate";
            } else {
              htmlString += "<b>Estimate " + estimateCount;
            }
            htmlString +=
              ": " +
              currencyFormatter(
                estimate.customData.totalAmount ?? 0,
                siteKey.customizations?.accounting?.currency ?? "usd",
              ) +
              "</b>" +
              "<br/>" +
              "<b>Line Items: </b>" +
              "<br/>" +
              estimateItemsString +
              "<br/>";
            estimateCount += 1;
          }
        }
        if (craftRecordData?.customerID) {
          const customerData = await DbRead.customers.get(
            e.data.tags.craftRecordRefPath.split("/")[1],
            craftRecordData?.customerID,
          );

          htmlString +=
            "<b>Customer Name: </b>" +
            (customerData?.name ?? "--") +
            "<br/>" +
            "<b>Customer Phone: </b>" +
            (customerData?.phone?.join(", ") ?? "--") +
            "<br/>";
        }
      }

      args.html = htmlString;
      args.loaded();
    },
  });
}

function resetAssignedUserList(assignedUserList: IMultipleUid_AssignUser[]) {
  assignedUserList.forEach((user) => {
    user.isAssigned = false;
    user.assignedVehicle = null;
  });
}

function filterCalendarResourcesByLocationDropdownValues(
  resourcesForCalendar: CalendarColumn[],
  filteredSiteKeyLocations: ExistingSiteKeyLocation[],
  locationsDropdownValue: string[],
): CalendarColumn[] {
  if (locationsDropdownValue.length === 0) return resourcesForCalendar;

  const filteredColumn: CalendarColumn[] = [];
  const locationIDs = filteredSiteKeyLocations.map((s) => s.id);

  resourcesForCalendar.forEach((resource) => {
    if (resource.name === "Unassigned") {
      filteredColumn.push(resource);
    }

    if (locationIDs.includes(resource.locationID)) {
      filteredColumn.push(resource);
    }
  });

  return filteredColumn;
}

export function customizeHeader(
  args: DayPilot.CalendarBeforeHeaderRenderArgs,
  calendarEvents: CalendarEvent[],
  resourcesDropdownValue: string | null,
  selectedDate: DateTime,
) {
  args.header.areas = [];

  let portable: boolean = false;
  let dotColorList: string[] = [];
  const dotColorElements: DayPilot.AreaData[] = [];

  if (args.column.data.id != null) {
    if (resourcesDropdownValue === "vehicles") {
      const filteredEvents = calendarEvents.filter((event) => {
        // Return true if event.resrouce and args.column.data.id are the same and also if event.start is greater or equal to the selected date
        return (
          event.resource === (args.column.data.id as string) &&
          DateTime.fromISO(event.start).toISODate() === selectedDate.toISODate()
        );
      });

      portable = filteredEvents.some((event) => {
        return event.portable === true;
      });

      filteredEvents.forEach((event) => {
        if (event.dotColor && event.dotColor !== "#eeeeee") {
          dotColorList = [...new Set([...dotColorList, event.dotColor])];
        }
      });
    } else if (resourcesDropdownValue === "technicians") {
      const filteredEvents = calendarEvents.filter(
        (event) => event.resource === (args.column.data.id as string),
      );

      portable = filteredEvents.some((event) => {
        return event.portable === true;
      });

      filteredEvents.forEach((event) => {
        if (event.dotColor && event.dotColor !== "#eeeeee") {
          dotColorList = [...new Set([...dotColorList, event.dotColor])];
        }
      });
    }

    if (portable === true) {
      args.header.areas.push({
        left: "calc(50% - 30px)",
        top: 3,
        width: 50,
        fontColor: `var(--primary)`,
        text: "Portable",
        symbol: "",
        style:
          "font-size:12px;border-radius:9999px;background-color:rgb(34 211 238 / 1);padding-left:0.5rem;padding-right:0.5rem;",
      });
    }

    if (dotColorList.length > 0) {
      dotColorList.forEach((dotColor, idx) => {
        dotColorElements.push({
          right: 5 + 10 * idx,
          bottom: 3,
          width: 8,
          height: 8,
          background: dotColor,
          style: "border-radius:9999px;",
        });
      });
      args.header.areas.push(...dotColorElements);
    }
  }
}

function getScheduledSaleForTask(estimates: ExistingEstimate[]): number {
  if (estimates.length === 0) return 0;

  if (estimates.length === 1) {
    return typeof estimates[0].customData.totalAmount === "number"
      ? estimates[0].customData.totalAmount
      : 0;
  }

  const lockedEstimates = estimates.filter((e) => e.status === "locked");

  if (lockedEstimates.length === 1) {
    return typeof lockedEstimates[0].customData.totalAmount === "number"
      ? lockedEstimates[0].customData.totalAmount
      : 0;
  } else {
    // const xEstimates = cloneDeep(estimates);
    // i don't think we actually care if we mutate the estimates list...
    const sortedEstimates = estimates.sort((a, b) =>
      a.timestampCreated < b.timestampCreated ? 1 : -1,
    );

    return typeof sortedEstimates[0].customData.totalAmount === "number"
      ? sortedEstimates[0].customData.totalAmount
      : 0;
  }
}

function handleTimeRangeSelected(args: {
  daypilot:
    | DayPilot.SchedulerTimeRangeSelectedArgs
    | DayPilot.CalendarTimeRangeSelectedArgs;
  setSelectCreationChoiceDialogOpen: (val: boolean) => void;
  setEventDuration: (val: number) => void;
  setResourceID: (val: string) => void;
  setStartDateNewCalendarEvent: (date: DateTime) => void;
  setEndDateNewCalendarEvent: (date: DateTime) => void;
}): void {
  args.setSelectCreationChoiceDialogOpen(true);

  const start = args.daypilot.start.toString();
  const end = args.daypilot.end.toString();
  const timeDiff = getTimeDifferenceInHours({
    startDate: start,
    endDate: end,
  });

  args.setEventDuration(timeDiff);
  args.setResourceID(args.daypilot.resource.toString());
  args.setStartDateNewCalendarEvent(DateTime.fromISO(start));
  args.setEndDateNewCalendarEvent(DateTime.fromISO(end));
  args.daypilot.control.clearSelection();
}

async function calendarEventMoved(args: {
  daypilot: DayPilot.CalendarEventMovedArgs | DayPilot.SchedulerEventMovedArgs;
  resourcesMode: string | null;
  getOriginalStiltEvent: (
    stiltEventID: string,
    originalResourceID: string,
  ) => ExistingStiltCalendarEvent | null;
  updateTask: (args: {
    taskID: string;
    newStart: string;
    newEnd: string;
    newResourceID: string;
    originalResourceID: string;
  }) => Promise<string>;
  updateStiltEvent: (
    updateData: UpdateStiltEventType,
    stiltEventToUpdate: ExistingStiltCalendarEvent,
  ) => Promise<void>;
  addMessage: (message: ToastType) => void;
}): Promise<void> {
  const newStart = args.daypilot.newStart.toStringSortable();
  const newEnd = args.daypilot.newEnd.toStringSortable();
  const newResourceID: string = args.daypilot.newResource.toString();
  const originalResourceID: string = args.daypilot.e.data.tags.resourceID;

  let stiltEventID: string | null = null;
  let taskID: string | null = null;
  if (args.daypilot.e.data.tags.taskID) {
    taskID = args.daypilot.e.data.tags.taskID;
  } else {
    stiltEventID = args.daypilot.e.data.tags.stiltEventID;
  }

  if (taskID) {
    try {
      const taskTitle = await args.updateTask({
        taskID,
        newStart,
        newEnd,
        newResourceID,
        originalResourceID,
      });
      args.addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(`Task: ${taskTitle}`),
        dialog: false,
        type: "success",
      });
    } catch (e) {
      if (e instanceof Error) {
        devLogger.warn(e);
        devLogger.error(
          `calendarEventMoved (Task): ${taskID}, ${e.name}: ${e.message}`,
        );
      } else {
        devLogger.error(`calendarEventMoved (Task): ${taskID}`, e);
      }

      args.addMessage({
        id: createToastMessageID(),
        message: strings.failedUpdate(
          `task: ${args.daypilot.e.data.text ?? "[unknown task title]"}`,
        ),
        dialog: false,
        type: "error",
      });
    }
  } else if (stiltEventID) {
    const originalStiltEvent = args.getOriginalStiltEvent(
      stiltEventID,
      originalResourceID,
    );

    if (!originalStiltEvent) return;

    // SETUP TIMESTAMPS
    let startTimestamp: Timestamp;
    let endTimestamp: Timestamp;
    try {
      startTimestamp = convertISOToFSTimestamp(newStart);
    } catch (e) {
      startTimestamp = Timestamp.now();
    }
    try {
      endTimestamp = convertISOToFSTimestamp(newEnd);
      // handle the case of endTimestamp being earlier than startTimestamp
      if (startTimestamp.toMillis() > endTimestamp.toMillis()) {
        endTimestamp = Timestamp.fromMillis(
          startTimestamp.toMillis() + 3600000, // 1 hour later
        );
      }
    } catch (e) {
      endTimestamp = Timestamp.fromMillis(
        startTimestamp.toMillis() + 3600000, // 1 hour later
      );
    }

    const updateData: UpdateStiltEventType = {
      start: startTimestamp,
      end: endTimestamp,
    };

    // SETUP RESOURCE IDS
    let resourceIDs: string[];
    try {
      resourceIDs = getResourceIDsFromCalendarEventMoved({
        daypilot: args.daypilot,
        originalStiltEvent,
        resourcesMode: args.resourcesMode,
      });
    } catch (e) {
      args.addMessage({
        id: createToastMessageID(),
        message: strings.failedUpdate(
          `calendar event: ${originalStiltEvent.title}`,
        ),
        dialog: false,
        type: "error",
      });
      return;
    }
    if (args.resourcesMode === "technicians") {
      updateData.assignedTo = resourceIDs;
    }
    if (args.resourcesMode === "vehicles") {
      updateData.assignedVehicleIDs = resourceIDs;
    }

    try {
      await args.updateStiltEvent(updateData, originalStiltEvent);
      args.addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(
          `Calendar event: ${originalStiltEvent.title}`,
        ),
        dialog: false,
        type: "success",
      });
    } catch (e) {
      if (e instanceof Error) {
        devLogger.warn(e);
        devLogger.error(
          `calendarEventMoved: failed to update event ${stiltEventID}, ${e.name}: ${e.message}`,
        );
      } else {
        devLogger.error(
          `calendarEventMoved: failed to update event ${stiltEventID}`,
          e,
        );
      }
      args.addMessage({
        id: createToastMessageID(),
        message: strings.failedUpdate(
          `calendar event: ${originalStiltEvent.title}`,
        ),
        dialog: false,
        type: "error",
      });
    }
  } else {
    devLogger.error(
      "calendarEventMoved: expected a string for stiltEventID or for taskID",
    );
    args.addMessage({
      id: createToastMessageID(),
      message: strings.UNEXPECTED_ERROR,
      dialog: false,
      type: "error",
    });
  }
}

/**
 * A calendar event can be assigned to more than one resource. To avoid losing
 * resources associated with an event, we need to make use of the resources mode
 * and we need to do some comparisons between the OG Stilt event and the data
 * that daypilot gives us.
 *
 * @throws if resourcesMode isn't "technicians" or "vehicles"
 * @throws if mode is "vehicles" and original assignedVehicleIDs is undefined or zero-length
 * @throws if mode is "technicians" and original assignedTo is zero-length
 */
function getResourceIDsFromCalendarEventMoved(args: {
  daypilot: DayPilot.CalendarEventMovedArgs | DayPilot.SchedulerEventMovedArgs;
  resourcesMode: string | null;
  originalStiltEvent: StiltCalendarEvent;
}): string[] {
  const afterMovedResourceID = args.daypilot.newResource.toString();
  const beforeMovedResourceID = args.daypilot.e.data.tags.resourceID;

  let originalResourceIDs: string[];
  // Determine validity / value of originalResourceIDs --
  if (args.resourcesMode === "technicians") {
    originalResourceIDs = args.originalStiltEvent.assignedTo;
    if (originalResourceIDs.length === 0) {
      throw Error("No technician resourceIDs found");
    }
  } else if (args.resourcesMode === "vehicles") {
    const xoriginalResIDs = args.originalStiltEvent.assignedVehicleIDs;
    if (!xoriginalResIDs || xoriginalResIDs.length === 0) {
      throw Error("No vehicle resourceIDs found");
    }
    originalResourceIDs = xoriginalResIDs;
  } else {
    throw Error(`Unexpected resourcesMode: ${args.resourcesMode}`);
  }

  // Comparisons --
  if (originalResourceIDs.length === 1) return [afterMovedResourceID];
  if (afterMovedResourceID == beforeMovedResourceID) return originalResourceIDs;

  const updatedIDs = originalResourceIDs.filter(
    (id) => id !== beforeMovedResourceID,
  );
  updatedIDs.push(afterMovedResourceID);

  return [...new Set(updatedIDs)];
}

async function calendarEventResized(args: {
  daypilot:
    | DayPilot.CalendarEventResizedArgs
    | DayPilot.SchedulerEventResizedArgs;
  getOriginalStiltEvent: (
    stiltEventID: string,
    originalResourceID: string,
  ) => ExistingStiltCalendarEvent | null;
  updateTask: (args: {
    taskID: string;
    newStart: string;
    newEnd: string;
  }) => Promise<string>;
  updateStiltEvent: (
    updateData: UpdateStiltEventType,
    stiltEventToUpdate: ExistingStiltCalendarEvent,
  ) => Promise<void>;
  addMessage: (message: ToastType) => void;
}): Promise<void> {
  const newStart = args.daypilot.newStart.toString();
  const newEnd = args.daypilot.newEnd.toString();

  const taskID =
    typeof args.daypilot.e.data.tags.taskID === "string"
      ? args.daypilot.e.data.tags.taskID
      : null;
  const stiltEventID =
    typeof args.daypilot.e.data.tags.stiltEventID === "string"
      ? args.daypilot.e.data.tags.stiltEventID
      : null;

  if (taskID) {
    try {
      const taskTitle = await args.updateTask({ taskID, newStart, newEnd });
      args.addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(`Task: ${taskTitle}`),
        dialog: false,
        type: "success",
      });
    } catch (e) {
      if (e instanceof Error) {
        devLogger.warn(e);
        devLogger.error(
          `calendarEventResized (Task): ${taskID}, ${e.name}: ${e.message}`,
        );
      } else {
        devLogger.error(`calendarEventResized (Task): ${taskID}`, e);
      }

      args.addMessage({
        id: createToastMessageID(),
        message: strings.failedUpdate(
          `task: ${args.daypilot.e.data.text ?? "[unknown task title]"}`,
        ),
        dialog: false,
        type: "error",
      });
    }
  } else if (stiltEventID) {
    const originalStiltEvent = args.getOriginalStiltEvent(
      stiltEventID,
      args.daypilot.e.data.tags.resourceID,
    );

    if (!originalStiltEvent) return;

    // SETUP TIMESTAMPS
    let startTimestamp: Timestamp;
    let endTimestamp: Timestamp;
    try {
      startTimestamp = convertISOToFSTimestamp(newStart);
    } catch (e) {
      startTimestamp = Timestamp.now();
    }
    try {
      endTimestamp = convertISOToFSTimestamp(newEnd);
      // handle the case of endTimestamp being earlier than startTimestamp
      if (startTimestamp.toMillis() > endTimestamp.toMillis()) {
        endTimestamp = Timestamp.fromMillis(
          startTimestamp.toMillis() + 3600000, // 1 hour later
        );
      }
    } catch (e) {
      endTimestamp = Timestamp.fromMillis(
        startTimestamp.toMillis() + 3600000, // 1 hour later
      );
    }

    const updateData: UpdateStiltEventType = {
      start: startTimestamp,
      end: endTimestamp,
    };

    try {
      await args.updateStiltEvent(updateData, originalStiltEvent);
      args.addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(
          `Calendar event: ${originalStiltEvent.title}`,
        ),
        dialog: false,
        type: "success",
      });
    } catch (e) {
      if (e instanceof Error) {
        devLogger.warn(e);
        devLogger.error(
          `calendarEventResized: failed to update event ${stiltEventID}, ${e.name}: ${e.message}`,
        );
      } else {
        devLogger.error(
          `calendarEventResized: failed to update event ${stiltEventID}`,
          e,
        );
      }
      args.addMessage({
        id: createToastMessageID(),
        message: strings.failedUpdate(
          `calendar event: ${originalStiltEvent.title}`,
        ),
        dialog: false,
        type: "error",
      });
    }
  } else {
    devLogger.error(
      "calendarEventResized: expected a string for stiltEventID or for taskID",
    );
    args.addMessage({
      id: createToastMessageID(),
      message: strings.UNEXPECTED_ERROR,
      dialog: false,
      type: "error",
    });
  }
}

async function handleDeletePayment(
  siteKeyID: string,
  paymentID: string,
): Promise<void> {
  return DbWrite.payments.delete(siteKeyID, paymentID);
}
