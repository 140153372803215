// Libs
import {
  ColDef,
  GetQuickFilterTextParams,
  ICellRendererParams,
  ITooltipParams,
  RowClassParams,
  RowClassRules,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Timestamp } from "firebase/firestore";
import isEqual from "lodash/isEqual";
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";

// Local
import { FeedbackPlusExtraType } from "./FeedbackListContainer";
import * as strings from "../../strings";
import { convertFSTimestampToLuxonDT } from "../../utils";
import { StyledTooltip } from "../../components/StyledTooltip";
import { ListIconWithRef } from "../../components/TaskViewButton";
import { ButtonViewCustomer } from "../../components/ButtonViewCustomer";
import SearchBox from "../../components/SearchBox";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
// This is for tables that use percentage-based heights - ie `h-full min-h-[500px]`
import "../../assets/css/ag-grid-inject-height.css";

interface Props {
  // DATA
  feedbackPlusExtraList: FeedbackPlusExtraType[];

  // FUNCTIONS
  viewCustomer: (id: string) => void;
  // viewTask: (workRecID: string, taskID:string) => void;
  viewTask: (workRecID: string) => void;
  handleOpenModal: (id: string) => void;
}

interface TableProps {
  feedbackPlusExtraList: Props["feedbackPlusExtraList"];
  handleOpenModal: (id: string) => void;

  // COMPONENTS (Cells)
  children: {
    renderCustomerPleased: (params: ICellRendererParams) => "Yes" | "No" | "";
    renderTimeOpened: (params: ICellRendererParams) => string;
    renderTimeLastSent: (params: ICellRendererParams) => string;
    renderIconButtons: (params: ICellRendererParams) => JSX.Element;
  };
}

export default function FeedbackListPage(props: Props): JSX.Element {
  const renderCustomerPleased = (params: ICellRendererParams) => {
    if (params.data.customerPleased === true) return "Yes";
    if (params.data.customerPleased === false) return "No";
    return "";
  };

  const renderTimeOpened = (params: ICellRendererParams) => {
    const tsOpened = params.data.timestampCustomerOpened;
    if (tsOpened) {
      const lux = convertFSTimestampToLuxonDT(tsOpened);
      return lux.toLocal().toFormat("LL/dd/yy hh:mm a");
    } else {
      return "";
    }
  };

  const renderTimeLastSent = (params: ICellRendererParams) => {
    const tsLastSent = params.data.timestampLastSent;
    if (tsLastSent) {
      const lux = convertFSTimestampToLuxonDT(tsLastSent);
      return lux.toLocal().toFormat("LL/dd/yy hh:mm a");
    } else {
      return "";
    }
  };

  const viewTask = props.viewTask;
  const viewCustomer = props.viewCustomer;
  const renderIconButtons = useCallback(
    function (params: ICellRendererParams) {
      return (
        <div className="flex gap-2">
          <StyledTooltip title="View Customer">
            <ButtonViewCustomer
              onClick={() => viewCustomer(params.data.customerID)}
            />
          </StyledTooltip>

          <StyledTooltip title="View Task">
            <ListIconWithRef
              onClick={() => viewTask(params.data.craftRecordID)}
            />
          </StyledTooltip>
        </div>
      );
    },
    [viewTask, viewCustomer],
  );

  return (
    <>
      <h1 className="mb-6 text-3xl font-semibold text-primary sm:text-5xl">
        {strings.FEEDBACK}
      </h1>
      <div className="ag-theme-alpine flex h-full min-h-[600px] flex-col pb-20">
        <TheTable
          feedbackPlusExtraList={props.feedbackPlusExtraList}
          handleOpenModal={props.handleOpenModal}
        >
          {{
            renderCustomerPleased,
            renderTimeOpened,
            renderTimeLastSent,
            renderIconButtons,
          }}
        </TheTable>
      </div>
    </>
  );
}

const getRowClassRules: RowClassRules = {
  "ag-green": function (params: RowClassParams) {
    return params.data.customerPleased === true;
  },
  "ag-red": function (params: RowClassParams) {
    return params.data.customerPleased === false;
  },
};

/**
 * These properties are added to every column. They can be overridden on a per
 * column basis.
 */
const commonColProps = {
  resizable: true,
  filter: true,
  sortable: true,
  headerClass:
    "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase",
  tooltipValueGetter: (params: ITooltipParams) =>
    params.valueFormatted ?? params.value,
  cellStyle: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
};

const TheTable = React.memo(
  (props: TableProps) => {
    const [gridReady, setGridReady] = useState(false);
    const gridRef = useRef<any>(null);

    const onGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    useLayoutEffect(() => {
      if (gridRef.current && gridReady) {
        gridRef.current.api?.sizeColumnsToFit();
      }
    }, [gridReady]);

    const onSearch = useCallback(() => {
      gridRef.current!.api.setQuickFilter(
        (document.getElementById("search-box") as HTMLInputElement).value,
      );
    }, []);

    const getTimestampTooltipAndFilterText = (
      params: ITooltipParams | GetQuickFilterTextParams,
    ): string => {
      const ts = params.value;
      if (ts instanceof Timestamp) {
        const lux = convertFSTimestampToLuxonDT(ts);
        return lux.toLocal().toFormat("LL/dd/yy hh:mm a");
      } else {
        return "";
      }
    };

    const getBooleanTooltipAndFilterText = (
      params: ITooltipParams | GetQuickFilterTextParams,
    ): string => {
      if (params.value === true) return "Yes";
      if (params.value === false) return "No";
      return "";
    };

    const colDefs: ColDef[] = [
      {
        headerName: "customer name",
        field: "customerName",
        onCellClicked: (ev) => props.handleOpenModal(ev.data.id),
      },
      {
        headerName: "customer phone",
        field: "customerPhone",
        onCellClicked: (ev) => props.handleOpenModal(ev.data.id),
      },
      {
        headerName: "customer pleased?",
        field: "customerPleased",
        cellRenderer: props.children.renderCustomerPleased,
        tooltipValueGetter: getBooleanTooltipAndFilterText,
        getQuickFilterText: getBooleanTooltipAndFilterText,
        onCellClicked: (ev) => props.handleOpenModal(ev.data.id),
      },
      {
        headerName: "# emails sent",
        field: "countSentToCustomer",
        onCellClicked: (ev) => props.handleOpenModal(ev.data.id),
      },
      {
        headerName: "email last sent",
        field: "timestampLastSent",
        cellRenderer: props.children.renderTimeLastSent,
        tooltipValueGetter: getTimestampTooltipAndFilterText,
        getQuickFilterText: getTimestampTooltipAndFilterText,
        onCellClicked: (ev) => props.handleOpenModal(ev.data.id),
      },
      {
        headerName: "time opened",
        field: "timestampCustomerOpened",
        cellRenderer: props.children.renderTimeOpened,
        tooltipValueGetter: getTimestampTooltipAndFilterText,
        getQuickFilterText: getTimestampTooltipAndFilterText,
        onCellClicked: (ev) => props.handleOpenModal(ev.data.id),
      },
      {
        cellRenderer: props.children.renderIconButtons,
        suppressMovable: true,
        suppressHeaderMenuButton: true,
        sortable: false,
        minWidth: 84,
        maxWidth: 120,
      },
      {
        // FOR SORTING BY timestampCreated VALUES
        field: "timestampCreated",
        hide: true,
        sort: "desc",
      },
    ];

    return (
      <>
        <SearchBox
          onInput={onSearch}
          id="search-box"
          widthClasses="w-full xs:max-w-sm"
        />
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={commonColProps}
          className="mt-6 shadow"
          rowData={props.feedbackPlusExtraList}
          animateRows={true}
          rowSelection="single"
          rowHeight={50}
          rowClassRules={getRowClassRules}
          cacheQuickFilter={true}
          columnDefs={colDefs}
          // gridOptions={}
        />
      </>
    );
  },
  (previous, next) => {
    const isFeedbackListTheSame = isEqual(
      previous.feedbackPlusExtraList,
      next.feedbackPlusExtraList,
    );

    return isFeedbackListTheSame;
  },
);
