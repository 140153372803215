//Libs
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { Wrapper } from "@googlemaps/react-wrapper";
import { User } from "firebase/auth";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import debounce from "lodash/debounce";
import { PencilIcon } from "@heroicons/react/24/solid";

//Local
import { DbRead, DbWrite } from "../../database";
import CustomerListPage from "./CustomerListPage";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import {
  Customer,
  CustomerManager,
  ExistingCustomerWithLocations,
  Customer_CreateAPI,
  CustomerTypes,
} from "../../models/customer";
import BaseInputText from "../../components/BaseInputText";
import LoadingSpinner from "../../components/LoadingSpinner";
import StyledMessage from "../../components/StyledMessage";
import * as strings from "../../strings";
import {
  CustomerLocationManager,
  CustomerLocation_CreateAPI,
} from "../../models/customer-location";
import AddEditCustomerLocationDialog, {
  TemporaryLocation,
} from "../../components/customers/AddEditCustomerLocationDialog";
import { useAuthStore } from "../../store/firebase-auth";
import { logger } from "../../logging";
import { createToastMessageID } from "../../utils";
import { useToastMessageStore } from "../../store/toast-messages";
import { StyledTooltip } from "../../components/StyledTooltip";
import { TrashIconWithSpinner } from "../../components/ButtonDeleteTrash";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import { CUSTOMERS_URL } from "../../urls";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import AddNewCustomerForm from "../../components/customers/AddNewCustomerForm";
import { useNavToCreateTask, useStiltNav } from "../../navigation";
import { useMembershipTemplatesStore } from "../../store/membership-templates";
import MembershipsAndDiscountsDialog from "../../components/customers/MembershipsDialog";
import { getMembershipIdsCount } from "../../assets/js/memberships/getMembershipIdsCount";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { useTypesenseStore } from "../../store/typesense";
import { typesenseCustomersQuery } from "../../utils/typesenseQueries";
import SearchBox from "../../components/SearchBox";
import AlertV2, { AlertType } from "../../components/AlertV2";
import { useIncomingCallsStore, AlertStyle } from "../../store/incoming-calls";
import CustomerSchedulesDropdown from "../../components/customers/CustomerSchedulesDropdown";

interface Props {
  siteKey: string;
}

const DEBOUNCE_WAIT_MS = 300;

export default function CustomerListContainer({ siteKey }: Props) {
  const apiKey = import.meta.env.VITE_APP_GOOGLE_MAP_KEY;
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const addMessage = useToastMessageStore((state) => state.addToastMessage);
  const navToCreateTask = useNavToCreateTask();
  const [membershipTemplateList, isLoadingMembershipTemplateList] =
    useMembershipTemplatesStore((state) => [
      state.membershipTemplates,
      state.loading,
    ]);
  const [siteKeyDoc] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.loading,
  ]);

  const [typesenseSearchKey, typesenseLoading] = useTypesenseStore((state) => [
    state.scopedSearchKey,
    state.loading,
  ]);

  /* NAVIGATION LOCATION STATE */
  const stillNav = useStiltNav();
  const locationState = stillNav.useLocationState();
  const initialSearchQuery = locationState["query"];

  /* HISTORY */
  const navigate = useNavigate();

  function handleGoToCustomerPage(customerId: string) {
    navigate(`${CUSTOMERS_URL}/${customerId}`);
  }

  /* USE STATES */
  const [addCustomerFormOpen, setAddCustomerFormOpen] =
    useState<boolean>(false);
  const [addCustomerLocationDialogOpen, setAddCustomerLocationDialogOpen] =
    useState<boolean>(false);
  const [locations, setLocations] = useState<TemporaryLocation[]>([]);
  const [address, setAddress] = useState<string>("");
  const [geocoderResult, setGeocoderResult] = useState<
    google.maps.GeocoderResult[]
  >([]);

  const [displayAddressError, setDisplayAddressError] =
    useState<boolean>(false);
  const [isAddressLoading, setIsAddressLoading] = useState<boolean>(false);

  /* membership customer section */
  const [
    addCustomerMembershipsDialogOpen,
    setAddCustomerMembershipsDialogOpen,
  ] = useState<boolean>(false);
  const [customerTemporaryMemberships, setCustomerTemporaryMemberships] =
    useState<Record<string, any>[]>([]);
  const [newCustomerMembershipIds, setNewCustomerMembershipIds] = useState<
    string[]
  >([]);

  const customerMembershipIdsCount = getMembershipIdsCount(
    newCustomerMembershipIds,
  );

  /* membership location section */
  const [
    addLocationMembershipsDialogOpen,
    setAddLocationMembershipsDialogOpen,
  ] = useState<boolean>(false);
  const [locationTemporaryMemberships, setLocationTemporaryMemberships] =
    useState<Record<string, any>[]>([]);
  const [newLocationMembershipIds, setNewLocationMembershipIds] = useState<
    string[]
  >([]);

  const [isGeneratingCustomerSchedules, setIsGeneratingCustomerSchedules] =
    useState(false);

  const locationMembershipIdsCount = getMembershipIdsCount(
    newLocationMembershipIds,
  );

  const [showManualEntryForm, setShowManualEntryForm] =
    useState<boolean>(false);

  /* USE EFFECT */

  /* MUTATIONS - CUSTOMER */
  /**
   * For adding a new customer to DB & optionally his locations
   */
  const mutateAddCustomer = useMutation(
    async (args: {
      validCustomer: Customer_CreateAPI;
      validLocationList: CustomerLocation_CreateAPI[] | null;
    }) => {
      if (args.validLocationList == null) {
        await DbWrite.customers.create(args.validCustomer);
      } else {
        await DbWrite.customers.create(args.validCustomer);
        await DbWrite.customerLocations.create(args.validLocationList);
        /* after the customer is saved, make the location object empty */
        setLocations([]);
      }
    },
  );

  // const mutateAddMemberships = useMutation(
  //   async (args: { validMembershipList: Membership_CreateAPI[] }) => {
  //     await DbWrite.memberships.create(args.validMembershipList);
  //   },
  // );

  /* FUNCTIONS - GENERAL */
  function resetLocationDialog() {
    setAddress("");
    setGeocoderResult([]);
  }

  function resetMembershipForAddLocation() {
    setLocationTemporaryMemberships([]);
    setNewLocationMembershipIds([]);
  }

  const [customersFromTypesense, setCustomersFromTypesense] = useState<
    ExistingCustomerWithLocations[]
  >([]);

  const [searchName, setSearchName] = useState<string>("");

  useLayoutEffect(() => {
    async function getCustomers() {
      if (!typesenseSearchKey) return;
      const customers = await typesenseCustomersQuery(typesenseSearchKey, "");
      setCustomersFromTypesense(customers);
    }

    getCustomers();
  }, [typesenseSearchKey]);

  useEffect(() => {
    async function queryCustomers() {
      if (!typesenseSearchKey) return;
      const customers = await typesenseCustomersQuery(
        typesenseSearchKey,
        searchName,
      );
      setCustomersFromTypesense(customers);
    }

    queryCustomers();
  }, [searchName, typesenseSearchKey]);

  const onFilterTextBoxChanged = useCallback(
    debounce(async () => {
      if (!typesenseSearchKey) return;
      const searchTerm = (
        document.getElementById("filter-text-box") as HTMLInputElement
      ).value;

      const customers = await typesenseCustomersQuery(
        typesenseSearchKey,
        searchTerm,
      );
      setCustomersFromTypesense(customers);
    }, DEBOUNCE_WAIT_MS),
    [typesenseSearchKey],
  );

  /*
   * Used to trigger a search query and set the value of the input field initially
   * when navigating to the page with location state. (i.e. navigate('/customers', { query: 'search' }))
   */
  const searchBoxRef = useRef<HTMLInputElement>(null);
  const debouncedInitialSearchQuery = useCallback(
    debounce(async (searchQuery: string) => {
      if (!typesenseSearchKey) return;
      const searchBox = searchBoxRef.current;
      if (searchBox == null) return;
      const searchTerm = searchQuery;
      searchBox.value = searchQuery;

      const customers = await typesenseCustomersQuery(
        typesenseSearchKey,
        searchTerm,
      );
      setCustomersFromTypesense(customers);
    }, DEBOUNCE_WAIT_MS + 200), // NOTE: debounce wait time intentionally set longer than onFilterTextBoxChanged
    [typesenseSearchKey],
  );
  /* Trigger the typesense query on initial load if there's a query in location state */
  useEffect(() => {
    const searchBox = searchBoxRef.current;
    if (initialSearchQuery && searchBox) {
      debouncedInitialSearchQuery(initialSearchQuery);
    }
  }, [initialSearchQuery, searchBoxRef.current]);

  /* FUNCTIONS - CUSTOMER */
  async function handleSaveNewCustomer(
    formValues: Omit<
      Customer,
      | "timestampCreated"
      | "timestampLastModified"
      | "createdBy"
      | "lastModifiedBy"
    >,
  ) {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    const customerID = DbRead.randomDocID.get();

    const newCustomer: Customer_CreateAPI = {
      name: formValues.name,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      phone: formValues.phone,
      notes: formValues.notes,
      type: formValues.type,
      createdBy: firebaseUser.uid,
      lastModifiedBy: firebaseUser.uid,
      tags: formValues.tags,
      customData: formValues.customData,
      isTaxExempt: formValues.isTaxExempt,
      notificationGroups: formValues.notificationGroups,
      website: formValues.website,
      quickbooksID: formValues.quickbooksID,
      deleted: formValues.deleted,
      siteKey: siteKey,
      uuid: customerID,
      customerLocations: {},
    };

    if (
      siteKeyDoc?.customizations?.accounting?.currency === "CAD" ||
      siteKeyDoc?.customizations?.accounting?.currency === "cad"
    ) {
      newCustomer.isTaxExemptGST = formValues.isTaxExemptGST ?? false;
      newCustomer.isTaxExemptPST = formValues.isTaxExemptPST ?? false;
    }

    // let validatedMembershipList: Membership_CreateAPI[] = [];
    // if (newCustomerMembershipIds.length > 0) {
    //   newCustomer.customData["membershipTemplateIDs"] =
    //     newCustomerMembershipIds;
    //
    //   const newMembershipDocList: Membership_CreateAPI[] =
    //     newCustomerMembershipIds.map((newMembershipId) => {
    //       const currentMembershipTemplate = membershipTemplateList.find(
    //         (membershipTemplate) => membershipTemplate.id === newMembershipId,
    //       );
    //       return {
    //         customerID: customerID,
    //         customerLocationID: null,
    //         membershipTemplateID: newMembershipId,
    //         status: "awaitingPayment",
    //         notes: null,
    //         createdBy: firebaseUser.uid,
    //         lastModifiedBy: firebaseUser.uid,
    //         siteKey: siteKey,
    //         frequency: currentMembershipTemplate
    //           ? currentMembershipTemplate.frequency
    //           : "indefinite",
    //       };
    //     });
    //   validatedMembershipList = newMembershipDocList.map((membership) =>
    //     MembershipManager.parseCreate(membership),
    //   );
    //   logger.info(
    //     "Validated customer membership list:",
    //     validatedMembershipList,
    //   );
    // }

    //Validate
    const validatedCustomer = CustomerManager.parseCreate(newCustomer);
    logger.info("Validated customer:", validatedCustomer);

    let validatedLocationList = null;
    if (locations.length !== 0) {
      const newLocationList: CustomerLocation_CreateAPI[] = locations.map(
        (location) => {
          const customerLocationID = DbRead.randomDocID.get();

          // if (location.customData["membershipTemplateIDs"]) {
          //   const newMembershipDocList: Membership_CreateAPI[] =
          //     location.customData["membershipTemplateIDs"].map(
          //       (newMembershipId: string) => {
          //         const currentMembershipTemplate = membershipTemplateList.find(
          //           (membershipTemplate) =>
          //             membershipTemplate.id === newMembershipId,
          //         );
          //
          //         return {
          //           customerID: customerID,
          //           customerLocationID: customerLocationID,
          //           membershipTemplateID: newMembershipId,
          //           status: "awaitingPayment",
          //           notes: null,
          //           createdBy: firebaseUser.uid,
          //           lastModifiedBy: firebaseUser.uid,
          //           siteKey: siteKey,
          //           frequency: currentMembershipTemplate
          //             ? currentMembershipTemplate.frequency
          //             : "indefinite",
          //         };
          //       },
          //     );
          //   const validObjectList = newMembershipDocList.map((membership) =>
          //     MembershipManager.parseCreate(membership),
          //   );
          //   validatedMembershipList.push(...validObjectList);
          // }

          return {
            ...location,
            siteKey: siteKey,
            uuid: customerLocationID,
            customerID: customerID,
            createdBy: firebaseUser.uid,
            lastModifiedBy: firebaseUser.uid,
          };
        },
      );
      validatedLocationList = newLocationList.map((location) =>
        CustomerLocationManager.parseCreate(location),
      );
      logger.info("Validated locations list:", validatedLocationList);
    }

    //DB
    try {
      // if (validatedMembershipList.length > 0) {
      //   const promises = [
      //     mutateAddCustomer.mutateAsync({
      //       validCustomer: validatedCustomer,
      //       validLocationList: validatedLocationList,
      //     }),
      //     mutateAddMemberships.mutateAsync({
      //       validMembershipList: validatedMembershipList,
      //     }),
      //   ];
      //   await Promise.all(promises);
      // } else {
      await mutateAddCustomer.mutateAsync({
        validCustomer: validatedCustomer,
        validLocationList: validatedLocationList,
      });
      // }
      handleGoToCustomerPage(customerID);
    } catch (error) {
      logger.error(`An error occurred during handleSaveNewCustomer`, error);
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  async function handleGenerateCustomerSchedules(
    customerType: CustomerTypes | null,
  ): Promise<void> {
    setIsGeneratingCustomerSchedules(true);
    try {
      const responseData =
        await DbRead.customers.generateBatchCustomerSchedules(
          siteKey,
          customerType,
        );

      if (responseData.code === 200) {
        if (typeof responseData?.data === "string") {
          window.open(responseData.data, "_blank")?.focus();
        } else {
          addMessage({
            id: createToastMessageID(),
            dialog: false,
            message: responseData?.message?.toString(),
            type: "error",
          });
        }
      } else {
        logger.error(
          "Error in handleGenerateCustomerSchedules: ",
          responseData,
        );
        addMessage({
          id: createToastMessageID(),
          dialog: false,
          message: strings.UNEXPECTED_ERROR,
          type: "error",
        });
      }
      setIsGeneratingCustomerSchedules(false);
    } catch (error) {
      logger.error("Error in handleGenerateCustomerSchedules: ", error);
      setIsGeneratingCustomerSchedules(false);
    }
  }

  /* FUNCTIONS - CUSTOMER LOCATIONS */
  async function handleSelectAddress(selectedAddress: string) {
    if (selectedAddress === "") {
      setDisplayAddressError(true);
    } else {
      const results = await geocodeByAddress(selectedAddress);
      setGeocoderResult(results);
      setAddress(results[0].formatted_address);
    }
  }

  function closeAndresetLocation() {
    /* close the location dialog */
    setAddCustomerLocationDialogOpen(false);
    resetLocationDialog();
    resetMembershipForAddLocation();
    setShowManualEntryForm(false);
  }

  function handleTemporarySaveNewCustomerLocation(
    newLocationItem: TemporaryLocation,
  ) {
    setIsAddressLoading(true);

    newLocationItem.customData["membershipTemplateIDs"] =
      newLocationMembershipIds;

    setLocations([...locations, newLocationItem]);
    closeAndresetLocation();
    setIsAddressLoading(false);
  }

  function handleSaveManualCustomerLocation(
    newLocationItem: TemporaryLocation,
  ) {
    setIsAddressLoading(true);

    //for now use the siteKey latitude or longitude, later we will allow the user to pinpoint the location on a map
    newLocationItem.latitude = siteKeyDoc?.latitude ?? null;
    newLocationItem.longitude = siteKeyDoc?.longitude ?? null;

    newLocationItem.customData["membershipTemplateIDs"] =
      newLocationMembershipIds;

    setLocations([...locations, newLocationItem]);
    closeAndresetLocation();
    setIsAddressLoading(false);
  }

  function onDeleteTemporaryLocation(
    locationToBeDeleted: TemporaryLocation,
  ): void {
    if (locationToBeDeleted.googlePlaceID == null) {
      const filteredLocations = locations.filter(
        (location) =>
          location.addressLine1 !== locationToBeDeleted.addressLine1,
      );
      setLocations(filteredLocations);
    } else {
      const filteredLocations = locations.filter(
        (location) =>
          location.googlePlaceID !== locationToBeDeleted.googlePlaceID,
      );
      setLocations(filteredLocations);
    }
  }

  /* Incoming Call Store */
  const currentCall = useIncomingCallsStore((state) => state.currentCall);
  const clearCurrentCall = useIncomingCallsStore((state) => state.clearCall);
  const alertStyle = useIncomingCallsStore((state) => state.alertStyle());

  type AlertProps = {
    title: JSX.Element | string;
    actionButtonText: string;
    style: AlertType;
  };
  const alertPropsMap: Record<AlertStyle, AlertProps> = {
    confirm: {
      style: "info",
      title: (
        <span>
          Confirm customer for call from <strong>{currentCall?.caller}</strong>
        </span>
      ),
      actionButtonText: "Confirm",
    },
    match: {
      style: "warning",
      title: (
        <span>
          Customer match needed for call from{" "}
          <strong>{currentCall?.caller}</strong>
        </span>
      ),
      actionButtonText: "Match",
    },
  };
  const alertProps = alertPropsMap[alertStyle];
  const callAlertStyle = alertStyle === "confirm" ? "info" : "warning";

  /* RENDER LOADING */
  if (isLoadingMembershipTemplateList || typesenseLoading || !siteKeyDoc) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  /* COMPONENTS */
  const addressError = (
    <div className="mt-2 text-sm">
      <StyledMessage type="error">
        {{ message: strings.REQUIRED_FIELD }}
      </StyledMessage>
    </div>
  );

  const addressField = (
    <div className="flex w-full items-center gap-8">
      <PlacesAutocomplete
        value={address}
        onChange={(newAddress: string) => {
          if (newAddress === "") {
            setDisplayAddressError(true);
            setAddress(newAddress);
          } else {
            setAddress(newAddress);
            setDisplayAddressError(false);
          }
        }}
        onSelect={handleSelectAddress}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="w-full">
            <BaseInputText
              {...getInputProps({
                admin: true,
                required: true,
                inputName: "fullAddress",
                text: "Address",
              })}
            />
            <div
              className={
                suggestions.length > 0 || loading
                  ? "absolute z-10 max-w-sm rounded border bg-white p-2"
                  : ""
              }
            >
              {loading ? (
                <div className="p-2 text-primary">
                  <LoadingSpinner />
                </div>
              ) : null}

              {suggestions.map((suggestion, suggestionIdx) => {
                const style = {
                  backgroundColor: suggestion.active
                    ? "var(--primary-opacity-90)"
                    : "#fff",
                  borderRadius: "0.25rem",
                  padding: "5px",
                };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, { style })}
                    key={suggestionIdx}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
            {displayAddressError ? addressError : null}
          </div>
        )}
      </PlacesAutocomplete>
      <BaseButtonSecondary
        type="button"
        onClick={() => setShowManualEntryForm(true)}
        className="w-full text-primary sm:w-56"
      >
        <PencilIcon aria-label="save" className="mr-4 h-6" />
        {strings.MANUAL_ENTRY}
      </BaseButtonSecondary>
    </div>
  );

  function addLocationDialog(customerType: Customer["type"]) {
    return (
      <div className="col-span-3 flex flex-col gap-8 sm:grid sm:grid-cols-3">
        <BaseButtonPrimary
          type="button"
          onClick={() => setAddCustomerLocationDialogOpen(true)}
          className="w-full text-primary sm:w-48"
        >
          <AddCircleIcon fontSize="small" className="mr-2" />
          {strings.ADD_NEW_LOCATION}
        </BaseButtonPrimary>
        <AddEditCustomerLocationDialog
          closeDialog={() => {
            setAddCustomerLocationDialogOpen(false);
            resetLocationDialog();
            resetMembershipForAddLocation();
            setShowManualEntryForm(false);
          }}
          isSubmitting={isAddressLoading}
          isDialogOpen={addCustomerLocationDialogOpen}
          geocoderResult={geocoderResult[0]}
          customerType={customerType}
          handleSaveCustomerLocationWithAutocomplete={
            handleTemporarySaveNewCustomerLocation
          }
          handleSaveManualCustomerLocation={handleSaveManualCustomerLocation}
          showManualEntryForm={showManualEntryForm}
          customerLocationDoc={null}
        >
          {{
            AddressField: addressField,
            AddMembershipsButton: addLocationMembershipButton,
          }}
        </AddEditCustomerLocationDialog>

        {/* Customer Locations */}
        {locations != null
          ? locations.map((location, idx) => {
              return (
                <div
                  key={idx}
                  className="flex items-center rounded-md border px-4 py-3"
                >
                  <span className="mr-auto">{location?.fullAddress}</span>
                  <StyledTooltip title="Delete Address" enterDelay={1200}>
                    <TrashIconWithSpinner
                      onDelete={async () => onDeleteTemporaryLocation(location)}
                    />
                  </StyledTooltip>
                </div>
              );
            })
          : null}
      </div>
    );
  }

  const addNewCustomerButton = (
    <BaseButtonPrimary
      type="button"
      onClick={() => setAddCustomerFormOpen(true)}
      className="w-full sm:w-56"
    >
      <AddCircleIcon fontSize="small" className="mr-2" />
      {strings.ADD_NEW_CUSTOMER}
    </BaseButtonPrimary>
  );

  const createNewTaskButton = (
    <BaseButtonSecondary
      type="button"
      onClick={() => navToCreateTask()}
      className="w-full sm:w-56"
    >
      <AddCircleIcon fontSize="small" className="mr-2" />
      {strings.NEW_TASK}
    </BaseButtonSecondary>
  );

  const addCustomerMembershipButton = membershipTemplateList.length > 0 && (
    <Fragment>
      <BaseButtonSecondary
        type="button"
        onClick={() => setAddCustomerMembershipsDialogOpen(true)}
        className="col-start-1 my-2 w-full self-center text-primary sm:w-48"
      >
        <AddCircleIcon fontSize="small" className="mr-2" />
        {strings.ADD_MEMBERSHIPS}
      </BaseButtonSecondary>
      {Object.keys(customerMembershipIdsCount).length > 0
        ? Object.entries(customerMembershipIdsCount).map(([key, value]) => {
            const currentTemplate = membershipTemplateList.find(
              (template) => template.id === key,
            );
            return (
              <div
                key={key}
                className="rounded-md border p-4 text-base font-bold capitalize text-primary"
              >
                {value} x {currentTemplate?.title}
              </div>
            );
          })
        : null}
    </Fragment>
  );

  const addLocationMembershipButton = membershipTemplateList.length > 0 && (
    <Fragment>
      <BaseButtonSecondary
        type="button"
        onClick={() => setAddLocationMembershipsDialogOpen(true)}
        className="w-full text-primary sm:w-48"
      >
        <AddCircleIcon fontSize="small" className="mr-2" />
        {strings.ADD_MEMBERSHIPS}
      </BaseButtonSecondary>
      {Object.keys(locationMembershipIdsCount).length > 0 ? (
        <div className="grid grid-cols-2 gap-4">
          {Object.entries(locationMembershipIdsCount).map(([key, value]) => {
            const currentTemplate = membershipTemplateList.find(
              (template) => template.id === key,
            );
            return (
              <div
                key={key}
                className="rounded-md border p-4 text-base font-bold capitalize text-primary"
              >
                {value} x {currentTemplate?.title}
              </div>
            );
          })}
        </div>
      ) : null}
    </Fragment>
  );

  const customerMembershipDialog = (
    <MembershipsAndDiscountsDialog
      closeDialog={() => setAddCustomerMembershipsDialogOpen(false)}
      setNewMembershipIds={setNewCustomerMembershipIds}
      isDialogOpen={addCustomerMembershipsDialogOpen}
      membershipTemplates={membershipTemplateList}
      membershipIDsCount={customerMembershipIdsCount}
      setTemporaryMemberships={setCustomerTemporaryMemberships}
      temporaryMemberships={customerTemporaryMemberships}
    />
  );

  const locationMembershipDialog = (
    <MembershipsAndDiscountsDialog
      closeDialog={() => setAddLocationMembershipsDialogOpen(false)}
      setNewMembershipIds={setNewLocationMembershipIds}
      isDialogOpen={addLocationMembershipsDialogOpen}
      membershipTemplates={membershipTemplateList}
      membershipIDsCount={locationMembershipIdsCount}
      setTemporaryMemberships={setLocationTemporaryMemberships}
      temporaryMemberships={locationTemporaryMemberships}
    />
  );

  const addNewCustomerForm = addCustomerFormOpen ? (
    <AddNewCustomerForm
      siteKeyCustomizations={siteKeyDoc.customizations}
      addMembershipsButton={addCustomerMembershipButton}
      className="mb-12 flex flex-col gap-8 rounded-md border p-8 sm:grid sm:grid-cols-3"
      handleSave={handleSaveNewCustomer}
      setSearchName={setSearchName}
      setAddCustomerFormOpen={() => {
        setLocations([]);
        resetLocationDialog();
        resetMembershipForAddLocation();
        setAddCustomerFormOpen(false);
      }}
      resetMembershipStates={() => {
        setCustomerTemporaryMemberships([]);
        setNewCustomerMembershipIds([]);
      }}
    >
      {{
        AddNewCustomerLocationDialog: addLocationDialog,
      }}
    </AddNewCustomerForm>
  ) : null;

  const customerScheduleButton = (
    <CustomerSchedulesDropdown
      customerTypeSelected={handleGenerateCustomerSchedules}
      isLoading={isGeneratingCustomerSchedules}
    />
  );

  /* RENDER CONTENT */
  return (
    <Wrapper apiKey={apiKey ?? ""} libraries={["places"]}>
      {currentCall ? (
        <div className="pb-2">
          <AlertV2
            variant={callAlertStyle}
            title={alertProps.title}
            onDismiss={clearCurrentCall}
          />
        </div>
      ) : null}

      <CustomerListPage
        customerListWithLocations={customersFromTypesense}
        goToCustomerPage={handleGoToCustomerPage}
        createNewTaskButton={createNewTaskButton}
        addNewCustomerButton={addNewCustomerButton}
        customerScheduleButton={customerScheduleButton}
        searchBoxElement={
          <SearchBox ref={searchBoxRef} onInput={onFilterTextBoxChanged} />
        }
      >
        {{
          AddNewCustomerForm: addNewCustomerForm,
        }}
      </CustomerListPage>
      {customerMembershipDialog}
      {locationMembershipDialog}
    </Wrapper>
  );
}
