// #region Imports
// Lib
import {
  addDoc,
  and,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FirestoreError,
  getDoc,
  getDocs,
  getFirestore,
  getCountFromServer,
  limit,
  onSnapshot,
  or,
  orderBy,
  query,
  setDoc,
  startAt,
  Timestamp,
  updateDoc,
  where,
  writeBatch,
  type QueryConstraint,
  endAt,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, getIdToken } from "firebase/auth";
import axios from "axios";
import {
  getLocalTimeZone,
  CalendarDate,
  ZonedDateTime,
  CalendarDateTime,
} from "@internationalized/date";
import { RangeValue } from "@react-types/shared";

// Local
import { NotFoundError } from "./error-classes";
import {
  ChecklistItemManager,
  ExistingChecklistItem,
} from "./models/checklist-item";
import {
  ChecklistPhotoManager,
  ExistingChecklistPhoto,
} from "./models/checklist-photo";
import {
  ChecklistResponseManager,
  ExistingChecklistResponse,
} from "./models/checklist-response";
import { CraftRecordManager, ExistingCraftRecord } from "./models/craft-record";
import { CraftTypes } from "./models/craft-types";
import {
  ExistingRootUser,
  RootUserManager,
  RootUsersMapParams,
} from "./models/root-user";
import { ExistingServerJob, NewServerJob } from "./models/server-job";
import {
  ExistingSiteKeyLocation,
  SiteKeyLocation,
  SiteKeyLocationManager,
} from "./models/site-key-location";
import {
  ExistingSiteKeyUserPermissions,
  SiteKeyUserPermissions,
  SiteKeyUserPermissionsManager,
} from "./models/site-key-user-permissions";
import { ExistingTask, TaskRecordManager } from "./models/task";
import { OTaskStatus, TaskStatus } from "./models/task-status";
import {
  ExistingSiteKeyUserDoc,
  SiteKeyUserDoc,
  SiteKeyUsersManager,
} from "./models/site-key-users";
import { logger as devLogger } from "./logging";
import {
  ExistingSiteKeyCompany,
  SiteKeyCompany,
  SiteKeyCompanyRecordManager,
} from "./models/site-key-companies";
import {
  EditSiteKeyDetailsState,
  ExistingSiteKey,
  SiteKey,
  SiteKeyManager,
} from "./models/site-key";
import { WhiteLabel } from "./white-label-check";
import { z } from "zod";
import { guardIsPlainObject } from "./utils/isPlainObject";
import {
  CustomFieldDocData,
  CustomFieldManager,
  CustomFieldToDelete,
  ExistingCustomField,
} from "./models/custom-field";
import {
  NewSiteConfigManager,
  NewSiteConfigState,
} from "./models/new-site-config";
import { ApplyToExistingSiteFormState } from "./Pages/ApplyToExistingSitePage";
import {
  ComplianceRequirement_CreateAPI,
  ComplianceRequirementManager,
  ExistingComplianceRequirement,
} from "./models/compliance-requirement";
import {
  ComplianceResponse_CreateAPI,
  ComplianceResponse_ReviewAPI,
  ComplianceResponseManager,
  ExistingComplianceResponse,
} from "./models/compliance-response";
import {
  Attachment_CreateAPI,
  AttachmentManager,
  ExistingAttachment,
} from "./models/attachment";
import {
  Customer,
  Customer_CreateAPI,
  Customer_UpdateAPI,
  CustomerManager,
  CustomerTypes,
  ExistingCustomer,
  ExistingCustomerUpdate,
} from "./models/customer";
import {
  CustomerLocation,
  CustomerLocation_CreateAPI,
  CustomerLocation_UpdateAPI,
  CustomerLocationManager,
  ExistingCustomerLocation,
} from "./models/customer-location";
import {
  ExistingPriceBookItem,
  PBItem_CreateAPI,
  PBItem_UpdateAPI,
  PriceBookItemManager,
} from "./models/price-book-item";
import {
  APIPaymentSavedCard,
  CreateMultiPayment,
  ExistingStiltPayment,
  StiltPayment_CreateAPI,
  StiltPaymentFormData,
  StiltPaymentManager,
} from "./models/stilt-payment";
import {
  ExistingStiltInvoice,
  StiltInvoice_UpdateAPI,
  StiltInvoiceManager,
  StiltInvoiceStatus,
} from "./models/invoice";
import {
  CommissionReportData,
  Estimate,
  Estimate_CreateAPI,
  Estimate_UpdateAPI,
  EstimateDataForNonAuthUser,
  EstimateManager,
  EstimateStatus,
  ExistingEstimate,
  ExistingEstimateUpdate,
} from "./models/estimate";
import {
  EstimateItem,
  EstimateItem_CreateAPI,
  EstimateItem_UpdateAPI,
  EstimateItemManager,
  ExistingEstimateItem,
} from "./models/estimate-item";
import {
  EstimatePackage,
  EstimatePackage_CreateAPI,
} from "./models/estimate-package";
import { Json } from "./models/json-type";
import {
  ExistingInventoryTransaction,
  InventoryTransaction,
  InventoryTransactionManager,
} from "./models/inventory-transaction";
import {
  ExistingInventoryObject,
  InventoryObject_CreateAPI,
  InventoryObjectManager,
} from "./models/inventory-object";
import {
  ExistingInventoryLocation,
  InventoryLocation,
  InventoryLocationManager,
} from "./models/inventory-location";
import {
  ExistingVehicle,
  Vehicle_CreateAPI,
  Vehicle_UpdateAPI,
  VehicleManager,
} from "./models/vehicle";
import { EventRecordManager, ExistingEvent } from "./models/event";
import {
  ExistingMembershipTemplate,
  MembershipTemplate_CreateAPI,
  MembershipTemplate_UpdateAPI,
  MembershipTemplateManager,
} from "./models/membership-template";
import {
  ExistingMembership,
  // Membership_CreateAPI,
  // Membership_UpdateAPI,
  MembershipManager,
  MembershipStatus,
} from "./models/membership";
import { DuplicateJob } from "./models/duplicate-job";
import {
  ExistingFeedback,
  FeedbackFormData,
  FeedbackManager,
  FeedbackUpdate,
} from "./models/feedback";
import { DateTime } from "luxon";
import {
  ExistingStiltCalendarEvent,
  StiltCalendarEvent,
  StiltCalendarEventManager,
} from "./models/stilt-calendar-event";
import moment from "moment/moment";
import { TaskTypes } from "./models/task-types";
import {
  ReportConfig,
  ReportConfigManager,
  ReportData,
  ReportDataManager,
  ReportSpec,
  ReportSpecManager,
} from "./models/reports";
import {
  CustomerContact,
  CustomerContact_CreateAPI,
  CustomerContact_UpdateAPI,
  CustomerContactManager,
  ExistingCustomerContact,
} from "./models/customer-contact";
import { ExistingSignature, SignatureManager } from "./models/signature";
import { ExistingStiltPhoto, StiltPhotoManager } from "./models/stilt-photo";
import { AssetManager, ExistingAsset } from "./models/asset";
import {
  ExistingStiltPhoneCall,
  StiltPhoneCallManager,
  type CallStatus,
} from "./models/calls";
import {
  DocType,
  ExistingCustomerAccountingSyncTableData,
  ExistingPBItemAccountingSyncTableData,
  ExistingStiltInvoiceAccountingSyncTableData,
  ExistingStiltPaymentAccountingSyncTableData,
} from "./models/quickbook";
import { ExistingNote, NoteManager } from "./models/note";
import { ChatRoomManager, ExistingChatRoom } from "./models/chat_room";
import { ChatMessageManager, ExistingChatMessage } from "./models/chat_message";
import {
  AssignedRoute_UpdateAPI,
  AssignedRouteManager,
  ExistingAssignedRoute,
} from "./models/assigned-route";
import {
  ExistingPriceBookItemCategory,
  PBItemCategory_CreateAPI,
  PBItemCategory_UpdateAPI,
  PriceBookItemCategoryManager,
} from "./models/price-book-item-category";
import {
  ExistingGLAccount,
  GLAccount_CreateAPI,
  GLAccountManager,
} from "./models/gl-account";
import {
  CommissionAdjustment_CreateAPI,
  CommissionAdjustment_UpdateAPI,
  CommissionAdjustmentManager,
  ExistingCommissionAdjustment,
} from "./models/commission-adjustment";
import {
  ExistingZone,
  Zone_CreateAPI,
  Zone_UpdateAPI,
  ZoneManager,
} from "./models/zone";
import isPlainObject from "lodash/isPlainObject";
import { ExistingKpiDocument, KpiManager, KpiSpec } from "./models/kpis";
import {
  Platform,
  CreateEstimateUniqueLink,
  CreatePaymentUniqueLink,
  CreateMultiPaymentUniqueLink,
} from "./models/unique-link";
import {
  ExistingLightspeedTransaction,
  LightspeedTransactionManager,
} from "./models/lightspeed";
import { SelfCheckoutFormConfig } from "./Pages/PaymentsSelfCheckout/PaymentsSelfCheckoutContainer";
import { SelfCheckoutPayment } from "./components/Payments/SelfCheckoutForm";
import { convertNestedObjToDotSeparatedFields } from "./utils/convertNestedObjToDotSeparatedFields";
import { QBOCustomer } from "node-quickbooks";

// #endregion Imports

// TODO(Dan): add docs
export function timestampNow() {
  return Timestamp.now();
}

// EXPRESS API CONSTANTS
const projectName = import.meta.env.VITE_APP_FIREBASE_PROJECT;
let apiBaseURL = `https://us-central1-${projectName}.cloudfunctions.net/api`;
if (import.meta.env.VITE_APP_USE_FUNCTIONS_EMULATOR === "true") {
  apiBaseURL = `http://localhost:5001/${projectName}/us-central1/api`;
}

/**
 * Contains functions for reading from the database, generally organized by collection.
 */
export const DbRead = {
  randomDocID: {
    /** Use this to get an id in the format that firestore uses, instead of using uuid */
    get: getRandomDocID,
  },
  checklistItems: {
    getAll: getAllChecklistItems,
    subscribeAll: subscribeAllChecklistItems,
    subscribeByChecklistID: subscribeChecklistItemsByCraftRecordID,
    getByChecklistID: getChecklistItemsByCraftRecordID,
    getItem: getSingleChecklistItem,
  },
  parentRecords: {
    get: getCraftRecord,
    getByRefPath: getCraftRecordByRefPath,
    getAllByCustomerID: getAllCraftRecordsByCustomerID,
    getAll: getAllCraftRecords,
    getAllDeleted: getAllDeletedCraftRecords,
    subscribe: subscribeCraftRecord,
    subscribeDeleted: subscribeDeletedCraftRecord,
    subscribeAllByCustomerID: subscribeAllCraftRecordsByCustomerID,
    subscribeAllInDateRange: subscribeAllParentRecordsInDateRange,
  },
  parentRecordPhotos: {
    getAll: getAllParentRecordPhotos,
    downloadAll: downloadAllPhotosForSingleParentRecord,
    subscribeAll: subscribeAllParentRecordPhotos,
  },
  photos: {
    getByEstimateItemID: getStiltPhotoByEstimateItemID,
    subscribeByCustomerID: subscribeStiltPhotoByCustomerID,
  },
  calls: {
    subscribeAllOpen: subscribeAllOpenCalls,
    subscribeRecent: subscribeRecentCalls,
    subscribeSingleCustomer: subscribeAllCallsForASingleCustomer,
    get: getSingleCall,
    list: listCallsQuery,
    count: countListCallsQuery,
  },
  checklist: {
    get: getCraftRecord,
    subscribeAll: subscribeAllChecklists,
    subscribeAllSortedTSLastModified:
      subscribeAllChecklistsSortedTSLastModified,
  },
  checklistResponses: {
    getAllByTaskID: getAllChecklistResponsesByTaskID,
    getByItemID: getAllChecklistResponsesByChecklistItemID,
    subscribeAllByTaskID: subscribeAllChecklistResponsesByTaskID,
  },
  checklistPhotos: {
    getAllByTaskID: getAllChecklistPhotosByTaskID,
    subscribeAllByTaskID: subscribeAllChecklistPhotosByTaskID,
  },
  customFields: {
    getAllCustomFields: getAllCustomFieldsOfASiteKey,
  },
  customToken: {
    get: getCustomToken,
    createTokenKey: createTokenKey,
  },
  typesense: {
    getSearchKey: getTypesenseSearchKey,
  },
  signatures: {
    get: getSignatures,
  },
  rootUser: {
    get: getRootUser,
    /** Users can only read their own root user documents. */
    subscribe: subscribeRootUser,
    generateRootUsersMap: generateRootUsersMap,
  },
  user: {
    getAllUsers: getAllUsers,
    getSiteKeyPermissions: getSiteKeyPermissions,
    getUserDoc: getUserDoc,
  },
  serverJob: {
    listByTemplateID: listServerJobsByTemplateID,
  },
  siteKeyLocations: {
    getAll: getAllSiteKeyLocations,
    get: getSingleSiteKeyLocation,
  },
  kpisv2: {
    getKpiSpecs: getKpiSpecs,
    getAll: getKpiDocs,
    getAllInDateRange: getKpiDocsInDateRange,
  },
  tasks: {
    getAllByWorkRecord: getAllTasksByWorkRecordID,
    getAllForChecklist: getAllChecklistTasks,
    getSingleChecklistTasks: getAllTasksOfASingleChecklist,
    getDocByID: getSingleTaskDocumentByTaskID,
    getSingleCustomerTasks: getAllTasksOfASingleCustomer,
    getAllDeleted: getAllDeletedTasks,
    getAllOpen: getAllOpenTasks,
    getAllWithStatus: getAllTasksWithStatus,
    getAllMembershipTasks: getAllMembershipTasks,
    getAllUrgent: getAllUrgentTasks,
    getAllByTaskType: getAllTasksByTaskType,
    getAllBacklog: getAllBacklogTasks,
    getAllByAssignedUser: getAllByAssignedUser,
    getAllCreatedInDateRange: getallTasksCreatedInDateRange,
    getAllByAssignedUserInDateRange: getAllTasksByAssignedUserInDateRange,
    subscribeSingleChecklistTasks: subscribeAllTasksOfASingleChecklist,
    subscribeChecklistCompletedTasks: subscribeAllChecklistCompletedTasks,
    subscribeChecklistUncompletedTasks: subscribeAllChecklistUncompletedTasks,
    subscribeAllTasksInDateRange: subscribeAllTasksInDateRange,
    subscribeOneByID: subscribeToOneDocumentByTaskID,
    subscribeScheduledAwaitingTaskList: subscribeScheduledAwaitingTaskList,
    subscribeSingleCustomerTasks: subscribeAllTasksOfASingleCustomer,
    subscribeByTaskStatus: subscribeByTaskStatus,
    subscribeAllOpen: subscribeAllOpenTasks,
    subscribeAllBacklog: subscribeAllBacklogTasks,
    subscribeAllUrgent: subscribeAllUrgentTasks,
    subscribeAllByTaskType: subscribeAllTasksByTaskType,
    subscribeAllByAssignedUser: subscribeAllTasksByAssignedUser,
    subscribeAllMembershipTasks: subscribeAllMembershipTasks,
  },
  aggregates: {
    getUserDisplayNames: getUserDisplayNames,
  },
  events: {
    getAllByWorkRecordID: getAllEventsByWorkRecordID,
    subscribeAllByWorkRecordID: subscribeAllEventsByWorkRecordID,
  },
  siteKeyCompanies: {
    /** Only works for isSiteAdmin and isPlantPersonnel */
    adminGetAll: adminGetSiteKeyCompanies,
    getCompaniesForContractor: getCompaniesForContractor,
  },
  siteKey: {
    get: getSiteKeyDoc,
    subscribe: subscribeSiteKeyDoc,
    getRootUserListOfSiteKeyDocs: getRootUserListOfSiteKeyDocs,
  },
  /** Collection that stores templates for creating a siteKey. */
  newSiteConfig: {
    get: getSiteKeyConfig,
    getAll: getAllSiteKeyConfigTemplates,
  },
  complianceRequirements: {
    getAll: getAllComplianceRequirements,
  },
  complianceResponses: {
    getAll: getAllComplianceResponses,
  },
  attachments: {
    getAllForCompliance: getComplianceAttachments,
    getAllForWorkRecord: getWorkRecordAttachments,
    subscribeAllForWorkRecord: subscribeWorkRecordAttachments,
    subscribeAllForCustomer: subscribeAttachmentsForCustomer,
  },
  customers: {
    get: getCustomer,
    subscribeAll: subscribeAllCustomers,
    subscribe: subscribeSingleCustomer,
    subscribeAllByAccountingSync: subscribeAllCustomersByAccountingSync,
    getQuickbooksCustomersByName: getQuickbooksCustomersByName,
    generateCustomerStatement: generateCustomerStatement,
    generateBatchCustomerStatements: generateBatchCustomerStatements,
    generateCustomerSchedule: generateCustomerSchedule,
    generateBatchCustomerSchedules: generateBatchCustomerSchedules,
  },
  payments: {
    get: getPayment,
    getSelfCheckoutConfig: getSelfCheckoutConfig,
    getMultiPayCreditCardData: getMultiPayCreditCardData,
    getAll: getAllPayments,
    getAllByInvoiceID: getAllPaymentsByInvoiceID,
    subscribeAllByInvoiceID: subscribeAllPaymentsByInvoiceID,
    getAllByCustomerID: getAllPaymentsByCustomerID,
    proceedWithSelfCheckout: proceedWithSelfCheckout,
    subscribeInDateRange: subscribeAllPaymentsInDateRange,
    subscribeAllByAccountingSync: subscribeAllPaymentsByAccountingSync,
  },
  customerLocations: {
    getByCustomerId: getCustomerLocationsByCustomerId,
    getAllTheSameBillToCustomerID:
      getCustomerLocationsWithSameBillToCustomerLocationID,
    // getAll: getAllCustomerLocations,
    subscribeAll: subscribeAllCustomerLocations,
    subscribe: subscribeSingleCustomerLocation,
    getSingle: getSingleCustomerLocation,
    subscribeByCustomerID: subscribeCustomerLocationsByCustomerId,
  },
  customerContacts: {
    subscribeByCustomerID: subscribeCustomerContactsByCustomerId,
  },
  priceBookItems: {
    getAll: getAllPriceBookItems,
    getAllWithCommissionsOverride: getAllPriceBookItemsWithCommissionsOverride,
    subscribeAll: subscribeAllPriceBookItems,
    get: getSinglePriceBookItem,
    subscribeAllByAccountingSync: subscribeAllPricebookItemsByAccountingSync,
  },
  priceBookItemCategories: {
    subscribeAll: subscribeAllPriceBookItemCategories,
  },
  commissions: {
    getCommissionReportData: getCommissionReportData,
  },
  commissionAdjustments: {
    subscribeByEstimateID: subscribeCommissionAdjustmentsByEstimateID,
  },
  invoices: {
    subscribeAllInvoicesInDateRange: subscribeAllInvoicesInDateRange,
    subscribeAllByAccountingSync: subscribeAllInvoicesByAccountingSync,
    subscribeByEstimateID: subscribeSingleInvoiceByEstimateID,
    subscribeByMembershipID: subscribeInvoicesByMembershipID,
    subscribeByCustomerID: subscribeInvoicesByCustomerID,
    getByCustomerId: getInvoiceListByCustomerId,
    getByEstimateId: getInvoiceListByEstimateId,
    getByTaskId: getInvoiceListByTaskId,
    subscribeByCraftRecordId: subscribeAllInvoicesByCraftRecordID,
    getSingle: getSingleInvoice,
    subscribe: subscribeSingleInvoice,
    getByInvoiceNumber: getInvoiceByInvoiceNumber,
  },
  estimates: {
    getAll: getAllEstimates,
    getByEstimateId: getSingleEstimateByEstimateId,
    getByTaskId: getEstimateListByTaskId,
    getByCustomerId: getEstimateListByCustomerId,
    getByCraftRecordId: getEstimateListByCraftRecordId,
    getEstimateData: getEstimateData,
    subscribeAll: subscribeAllEstimates,
    subscribeAllWithStatus: subscribeAllEstimatesWithStatus,
    subscribeByEstimateId: subscribeSingleEstimateByEstimateId,
    subscribeByCustomerID: subscribeEstimatesByCustomerID,
    subscribeByCraftRecordId: subscribeEstimateListByCraftRecordId,
  },
  estimateItems: {
    getByEstimateId: getEstimateItemsByEstimateId,
    subscribeByEstimateId: subscribeEstimateItemsByEstimateId,
  },
  lightspeedTransactions: {
    getAllPending: getAllPendingLightspeedTransactions,
    subscribeAllPending: subscribeAllPendingLightspeedTransactions,
    subscribeAllPushed: subscribeAllPushedLightspeedTransactions,
  },
  inventoryTransactions: {
    getAll: getAllInventoryTransactions,
    subscribe: subscribeInventoryTransactions,
  },
  inventoryObjects: {
    getAll: getAllInventoryObjects,
    subscribeAll: subscribeAllInventoryObjects,
  },
  inventoryLocations: {
    getAll: getAllInventoryLocations,
    subscribeAll: subscribeAllInventoryLocations,
  },
  vehicles: {
    getAll: getAllVehicles,
    subscribeAll: subscribeAllVehicles,
  },
  zones: {
    subscribeAll: subscribeAllZones,
  },
  membershipTemplates: {
    getAll: getAllMembershipTemplates,
    subscribe: subscribeMembershipTemplate,
  },
  memberships: {
    getByTemplateId: getMembershipsByTemplateID,
    getByCustomerId: getMembershipsByCustomerID,
    getAll: getAllMemberships,
    subscribeAll: subscribeAllMemberships,
    subscribe: subscribeMembership,
    subscribeByCustomerID: subscribeMembershipsByCustomerID,
  },
  assets: {
    getByCustomerId: getAssetsByCustomerID,
    // getAll: getAllAssets,
    subscribeAll: subscribeAllAssets,
    subscribeByCustomerID: subscribeAssetsByCustomerID,
    subscribeByCustomerLocationID: subscribeAssetsByCustomerLocationID,
    // subscribe: subscribeAsset,
  },
  notes: {
    subscribeByCustomerID: subscribeAllNotesByCustomerID,
  },
  chatRooms: {
    subscribeByUserID: subscribeAllChatRoomsByUserID,
  },
  chatMessages: {
    subscribeByRoomID: subscribeAllMessagesByRoomID,
  },
  feedbacks: {
    /** for unauthenticated `request-review` route. */
    getFormData: getFeedbackFormData,
    subscribeAll: subscribeAllFeedback,
    subscribeByWorkRecordID: subscribeFeedbackByWorkRecordID,
  } as const,

  calendarEvents: {
    subscribeByDate: subscribeCalendarEventByDate,
    getAllByAssignedUserForOverlap:
      getAllCalendarEventsByAssignedUserForOverlap,
  },
  reports: {
    getTypesWithSpec: getReportTypesWithSpec,
    subscribeConfigsByUID: subscribeReportConfigsByUID,
    subscribeReportDataByUID: subscribeReportDataByUID,
    subscribeUserListReportConfigs: subscribleUserListByReportType,
  },
  assignedRoutes: {
    subscribeDailyVehicleUsersMap: subscribeDailyVehicleUsersMap,
  },
  gLAccounts: {
    subscribeAll: subscribeAllGLAccounts,
  },
};

/**
 * Contains functions that write to the database, generally organized by collection.
 */
export const DbWrite = {
  checklistItems: {
    add: addChecklistItem,
    update: updateChecklistItem,
    removeSingleFromOneCraftRec: removeChecklistItemFromOneCraftRecord,
    removeSingleFromAllCraftRecs: removeChecklistItemFromAllCraftRecords,
  },
  checklistResponses: {
    /**
     * Deprecated. Client code should not be creating single response documents.
     * Use generate instead.
     * @deprecated
     */
    add: addChecklistResponse,
    updateComment: updateChecklistResponseComment,
    updateResponseValue: updateChecklistResponseValue,
    generate: generateChecklistResponses,
  },
  checklistPhotos: {
    add: addChecklistPhotoDoc,
    /** Sets 'deleted' field to true */
    delete: deleteChecklistPhotoDoc,
  },
  photos: {
    add: addStiltPhotoDoc,
    /** Sets 'deleted' field to true */
    // delete: deleteChecklistPhotoDoc,
  },
  kpis: {
    recalculate: recalculateKpiData,
  },
  notes: {
    add: addNote,
    update: updateNote,
    /** Sets 'deleted' field to true */
    delete: deleteNote,
  },
  chatRoom: {
    add: addChatRoom,
  },
  chatMessage: {
    add: addChatMessage,
  },
  calls: {
    clearCallStatus: clearCallStatus,
    updateCustomerAndAgent: updateCustomerAndAgent,
    initiateOutboundCall: initiateOutboundCall,
    updateCallStatus: updateCallStatus,
  },
  checklist: {
    add: addCraftRecord,
    update: updateChecklist,
    updateItemOrder: updateChecklistItemsOrder,
  },
  assets: {
    add: addAsset,
    update: updateAsset,
    delete: deleteAsset,
  },
  parentRecords: {
    /** use when you need to assign an ID to the document. */
    addWithID: addCraftRecordWithID,
    update: updateCraftRecord,
    delete: deleteParentRecord,
    duplicate: duplicateJob,
    restore: restoreWorkRecord,
  },
  parentRecordPhotos: {
    add: addParentRecordPhoto,
    /** ACTUALLY deletes the photo. */
    delete: deleteParentRecordPhoto,
  },
  craftDetails: {
    setDefaults: (refPath: string) => updateCraftDetails({ refPath: refPath }),
    update: (refPath: string, data: Record<string, any>) =>
      updateCraftDetails({ refPath: refPath, ...data }),
  },
  customFields: {
    deleteCustomField: deleteCustomField,
    add: addCustomField,
  },
  rootUser: {
    createAccount: createAccount,
    sendVerificationLink: sendVerificationLink,
    update: updateRootUser,
  },
  serverJobs: {
    add: addServerJob,
    delete: deleteServerJob,
  },
  siteKeyCompanies: {
    /** Only works for isSiteAdmin and isPlantPersonnel */
    adminAddNew: adminAddNewSiteKeyCompany,
    adminUpdateLogo: adminUpdateCompanyLogo,
    adminEditCompany: adminEditCompany,
  },
  siteKeyLocations: {
    adminAddNew: adminAddNewSiteKeyLocation,
    adminEditLocation: adminEditLocation,
  },
  tasks: {
    add: addTask,
    update: updateTaskStatus,
    delete: deleteSingleTask,
    restore: restoreTask,
    createForCustomer: createTaskForCustomer,
  },
  taskSpecificDetails: {
    setDefaults: (refPath: string) =>
      updateTaskSpecificDetails({ refPath: refPath }),
    update: (refPath: string, data: Record<string, Json>) =>
      updateTaskSpecificDetails({ refPath: refPath, ...data }),
  },
  user: {
    add: addNewUser,
    updateUserPhoto: updateUserPhotoURL,
    updateUserPhone: updateUserPhone,
    updateSiteKeyUser: updateSiteKeyUserDoc,
    updateSiteKeyUserPermissions: updateSiteKeyUserPermissions,
    sendPasswordResetLink: sendPasswordResetLink,
    resetUserPassword: resetUserPassword,
    sendAppDownloadLinkToDesktopUser: sendAppDownloadLinkToDesktopUser,
    sendAppDownloadLinks: sendAppDownloadLinks,
    applyToSiteKey: applyToSiteKey,
  },
  siteKey: {
    edit: editSiteKey,
    create: createSiteKey,
    setCallForwarding: setCallForwarding,
  },
  complianceRequirements: {
    create: createComplianceRequirement,
    /** changes "deleted" field to true */
    delete: deleteComplianceRequirement,
  },
  complianceResponses: {
    create: createComplianceResponse,
    /** changes "deleted" field to true */
    delete: deleteComplianceResponse,
    review: reviewComplianceResponse,
  },
  attachments: {
    /** To be used with the compliance feature. */
    createForCompliance: createComplianceAttachment,
    /** To be used with the compliance feature. Changes "deleted" field to true */
    deleteForCompliance: deleteComplianceAttachment,
    /** To be used for attachments associated with work records */
    createForWorkRecord: createWorkRecordAttachment,
    /** To be used for attachments associated with work records. Changes "deleted" field to true */
    deleteForWorkRecord: deleteWorkRecordAttachment,
  },
  /** Collection that stores templates for creating a siteKey. */
  newSiteConfig: {
    create: createSiteTemplate,
  },
  customers: {
    createViaAPI: createCustomerViaAPI,
    create: createCustomer,
    update: updateCustomer,
    updateViaAPI: updateCustomerViaAPI,
    confirmSameQBOCustomer: confirmSameQBOCustomer,
    restoreDeletedCustomerInQB: restoreDeletedCustomerInQB,
    merge: mergeCustomer,
    /** changes 'deleted' to true */
    delete: deleteCustomer,
  },
  customerLocations: {
    createViaAPI: createCustomerLocationsViaAPI,
    updateViaAPI: updateCustomerLocationViaAPI,
    create: createCustomerLocations,
    update: updateCustomerLocation,
    /** changes 'deleted' to true */
    delete: deleteCustomerLocation,
  },
  customerContacts: {
    // createViaAPI: createCustomerLocationsViaAPI,
    create: createCustomerContact,
    update: updateCustomerContact,
    /** changes 'deleted' to true */
    delete: deleteCustomerContact,
  },
  priceBookItems: {
    create: createPriceBookItem,
    update: updatePriceBookItem,
    /** changes 'deleted' to true */
    delete: deletePriceBookItem,
    /** this only applies to the Burgess site */
    syncLightspeed: syncBurgessLightspeed,
  },
  priceBookItemCategories: {
    create: createPriceBookItemCategory,
    update: updatePriceBookItemCategory,
  },
  commissionAdjustments: {
    create: createCommissionAdjustment,
    update: updateCommissionAdjustment,
  },
  estimatePackages: {
    createViaAPI: createEstimatePackageViaAPI,
    create: createEstimatePackage,
  },
  estimates: {
    createViaAPI: createEstimateViaAPI,
    create: createEstimate,
    update: updateEstimate,
    duplicate: duplicateEstimate,
    updateViaAPI: updateEstimateViaAPI,
    generateUniqueEstimateLink: generateUniqueEstimateLink,
    sendViaEmail: sendEstimateToCustomerViaEmail,
    customerUpdate: updateEstimateStatusByCustomer,
    /** changes 'deleted' to true */
    delete: deleteEstimate,
  },
  estimateItems: {
    createViaAPI: createEstimateItemViaAPI,
    create: createEstimateItem,
    update: updateEstimateItem,
    /** changes 'deleted' to true */
    delete: deleteEstimateItem,
  },
  payments: {
    manualPayment: recordManualPayment,
    manualBulkPayment: recordManualBulkPayment,
    generateMultiPaymentCreditCardLink: generateMultiPaymentCreditCardLink,
    emailReceipt: emailReceiptToCustomer,
    issueRefund: issueRefundForPayment,
    delete: deletePayment,
    generateTransactionReport: generateTransactionReport,
    createWithSavedCard: createPaymentWithSavedCard,
  },
  invoices: {
    generatePaymentLink: generatePaymentUniqueLink,
    // getPDF: getInvoicePDF,  if you comment this back in, you need to comment the backend function back in, too.
    create: createInvoiceFromEstimate,
    update: updateStiltInvoice,
    sendViaEmail: sendInvoiceToCustomerViaEmail,
    batchUpdateStatus: batchUpdateInvoiceStatus,
    delete: deleteInvoice,
  },
  inventoryObjects: {
    create: createInventoryObject,
  },
  inventoryLocations: {
    create: createInventoryLocation,
  },
  inventoryTransactions: {
    create: createInventoryTransaction,
    createMultiple: createMultipleInventoryTransactions,
  },
  membershipTemplates: {
    create: createMembershipTemplate,
    update: updateMembershipTemplate,
    /** Set status to 'canceled' */
    // delete: deleteMemberships,
  },
  memberships: {
    create: createMembership,
    update: updateMembership,
    /** Set status to 'canceled' */
    delete: deleteMembership,
    retryCollectDues: retryCollectDues,
  },
  feedbacks: {
    handleResponse: handleFeedbackResponse,
  },
  calendarEvents: {
    add: addCalendarEvent,
    update: updateCalendarEvent,
    delete: deleteCalendarEvent,
  },
  reports: {
    saveConfig: saveReportConfig,
    deleteConfig: deleteReportConfig,
    deleteData: deleteReportData,
    generateReportData: generateReportData,
    sendEmail: sendReportDownloadEmail,
  },
  emails: {
    sendCustomEmail: sendCustomEmail,
  },
  vehicles: {
    create: createVehicle,
    update: updateVehicle,
    /** changes 'deleted' to true */
    delete: deleteVehicle,
  },
  zones: {
    create: createZone,
    update: updateZone,
    delete: deleteZone,
  },
  assignedRoutes: {
    update: updateVehicleUsersMap,
  },
  gLAccounts: {
    create: createGLAccount,
  },
  codat: {
    refresh: refreshCodatData,
  },
  qbo: {
    connect: connectToQBO,
    disconnect: disconnectFromQBO,
  },
};

/**
 * Use this to get an ID in the format that Firestore uses, instead of using uuid
 * @returns A random document ID.
 */
function getRandomDocID(): string {
  const db = getFirestore();
  const collectionRef = collection(db, "temporary");
  const docRef = doc(collectionRef);
  return docRef.id;
}

async function getKpiDocs(
  siteKey: string,
  _kpiType: string,
): Promise<ExistingKpiDocument[]> {
  const db = getFirestore();
  const q = query(collection(db, "siteKeys", siteKey, "kpisV2"));
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((snapshot) => {
    return KpiManager.createFromFirestoreSnapshot(snapshot);
  });
}

async function getKpiSpecs(siteKey: string): Promise<Record<string, KpiSpec>> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");
  const idToken = await getIdToken(user);
  const endpoint = `kpis`;
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;
  try {
    const response = await axios.get(fullApiRoute, {
      headers: { authorization: `Bearer ${idToken}` },
      params: { siteKey },
    });
    return response.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
    }
    throw err;
  }
}

async function getKpiDocsInDateRange(
  siteKey: string,
  kpiType: string,
  startDate: string,
  endDate: string,
): Promise<ExistingKpiDocument[]> {
  const db = getFirestore();

  // The kpiDocs have a dateString that's in the format of yyyy-MM
  // We need to loop through all the months between the start and end date and query each document
  // to see if it falls within the range.
  // startDate and endDate are in the format of yyyy-MM-dd
  const startOfMonthOfStartDate = moment(startDate).startOf("month");
  const startOfMonthAfterEndDate = moment(endDate)
    .startOf("month")
    .add(1, "month");

  // build an array of dateStrings in yyyy-MM format from startOfMonthOfStartDate to startOfMonthAfterEndDate
  const dateStrings = [];
  const currentMonth = startOfMonthOfStartDate;
  while (currentMonth.isBefore(startOfMonthAfterEndDate)) {
    dateStrings.push(currentMonth.format("YYYY-MM"));
    currentMonth.add(1, "month");
  }

  // Loop through dateStrings and get all the kpiDocs for each month
  const kpiDocs = [];

  for (const dateString of dateStrings) {
    const q = query(
      collection(db, "siteKeys", siteKey, "kpisV2"),
      where("kpiType", "==", kpiType),
      where("dateString", "==", dateString),
    );
    const querySnapshot = await getDocs(q);
    kpiDocs.push(
      ...querySnapshot.docs.map((snapshot) => {
        return KpiManager.createFromFirestoreSnapshot(snapshot);
      }),
    );
  }

  return kpiDocs;
}

/**
 * Returns all the Checklist tasks in the tasks collection for the given siteKey.
 * Limited to 1000.
 * TODO: add pagination instead of hard coding a limit.
 */
async function getAllChecklistTasks(
  siteKey: string,
  permissions: SiteKeyUserPermissions,
): Promise<ExistingTask[]> {
  const { isSiteAdmin, isPlantPersonnel } = permissions.permissions;
  if (isSiteAdmin || isPlantPersonnel) {
    return getAllChecklistTasks_admin(siteKey);
  } else {
    return getAllChecklistTasks_company(siteKey, permissions);
  }
}

async function getAllChecklistTasks_admin(
  siteKey: string,
): Promise<ExistingTask[]> {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", siteKey, "tasks"),
    where("craftType", "==", CraftTypes.CHECKLISTS),
    limit(1000),
  );
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((snapshot) => {
    return TaskRecordManager.createFromFirestoreSnapshot(snapshot);
  });
}

async function getAllChecklistTasks_company(
  siteKey: string,
  permissions: SiteKeyUserPermissions,
): Promise<ExistingTask[]> {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", siteKey, "tasks"),
    where("craftType", "==", CraftTypes.CHECKLISTS),
    where("assignedCompanyID", "==", permissions.companyID),
    limit(1000),
  );
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((snapshot) => {
    return TaskRecordManager.createFromFirestoreSnapshot(snapshot);
  });
}

interface ISubscribeAllChecklistCompletedTasks {
  siteKey: string;
  permissions: SiteKeyUserPermissions;
  onChange: (checklistsTaskList: ExistingTask[]) => void;
  onError?: (error: FirestoreError) => void;
}

/**
 * Subscribe to realtime updates for 1000 completed task documents.
 * @returns removeListeners ƒn, for cleanup.
 */
function subscribeAllChecklistCompletedTasks(
  args: ISubscribeAllChecklistCompletedTasks,
): () => void {
  const { isSiteAdmin, isPlantPersonnel } = args.permissions.permissions;
  if (isSiteAdmin || isPlantPersonnel) {
    return subscribeAllChecklistCompletedTasks_admin(args);
  } else {
    return subscribeAllChecklistCompletedTasks_company(args);
  }
}

function subscribeAllChecklistCompletedTasks_admin(
  args: ISubscribeAllChecklistCompletedTasks,
): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    where("craftType", "==", CraftTypes.CHECKLISTS),
    where("taskStatus", "==", TaskStatus.COMPLETE),
    limit(1000),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const completedTasksList: ExistingTask[] = querySnapshot.docs.map(
        (snapshot) => TaskRecordManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(completedTasksList);
    },
    args.onError,
  );

  return removeListeners;
}

function subscribeAllChecklistCompletedTasks_company(
  args: ISubscribeAllChecklistCompletedTasks,
): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    where("craftType", "==", CraftTypes.CHECKLISTS),
    where("taskStatus", "==", TaskStatus.COMPLETE),
    where("assignedCompanyID", "==", args.permissions.companyID),
    limit(1000),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const completedTasksList: ExistingTask[] = querySnapshot.docs.map(
        (snapshot) => TaskRecordManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(completedTasksList);
    },
    args.onError,
  );

  return removeListeners;
}

interface ISubscribeAllTasksInDateRange {
  siteKey: string;
  permissions: SiteKeyUserPermissions;
  dateRange: RangeValue<CalendarDate | ZonedDateTime | CalendarDateTime>;
  onChange: (checklistsTaskList: ExistingTask[]) => void;
  onError?: (error: FirestoreError) => void;
}

/**
 * Subscribe to realtime updates for all checklist task documents.
 * Different queries for company users vs admin/plant
 * @returns removeListeners ƒn, for cleanup.
 */
function subscribeAllTasksInDateRange(
  args: ISubscribeAllTasksInDateRange,
): () => void {
  const { isSiteAdmin, isPlantPersonnel } = args.permissions.permissions;
  if (isSiteAdmin || isPlantPersonnel) {
    return subscribeAllTasksInDateRange_admin(args);
  } else {
    return subscribeAllTasksInDateRange_company(args);
  }
}

function subscribeAllTasksInDateRange_admin(
  args: ISubscribeAllTasksInDateRange,
): () => void {
  const db = getFirestore();

  const startDate = args.dateRange.start.toDate(getLocalTimeZone());
  const endDate = args.dateRange.end.toDate(getLocalTimeZone());
  // Add 1 day to the endDate so that the query is inclusive of the end date
  // (otherwise the query will be until 12AM of the endDate and invoices on the
  // endDate will not be returned
  endDate.setDate(endDate.getDate() + 1);
  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    where("craftType", "==", CraftTypes.CHECKLISTS),
    where("timestampCreated", ">=", Timestamp.fromDate(startDate)),
    where("timestampCreated", "<=", Timestamp.fromDate(endDate)),
    orderBy("timestampCreated", "desc"),
  );
  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const allTasksList: ExistingTask[] = querySnapshot.docs.map((snapshot) =>
        TaskRecordManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(allTasksList);
    },
    args.onError,
  );

  return removeListeners;
}

function subscribeAllTasksInDateRange_company(
  args: ISubscribeAllTasksInDateRange,
): () => void {
  const db = getFirestore();

  const startDate = args.dateRange.start.toDate(getLocalTimeZone());
  const endDate = args.dateRange.end.toDate(getLocalTimeZone());
  // Add 1 day to the endDate so that the query is inclusive of the end date
  // (otherwise the query will be until 12AM of the endDate and invoices on the
  // endDate will not be returned
  endDate.setDate(endDate.getDate() + 1);
  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    where("craftType", "==", CraftTypes.CHECKLISTS),
    where("assignedCompanyID", "==", args.permissions.companyID),
    where("timestampCreated", ">=", Timestamp.fromDate(startDate)),
    where("timestampCreated", "<=", Timestamp.fromDate(endDate)),
    orderBy("timestampCreated", "desc"),
  );
  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const allTasksList: ExistingTask[] = querySnapshot.docs.map((snapshot) =>
        TaskRecordManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(allTasksList);
    },
    args.onError,
  );

  return removeListeners;
}

interface ISubscribeAllChecklistUncompletedTasks {
  siteKey: string;
  permissions: SiteKeyUserPermissions;
  onChange: (checklistsTaskList: ExistingTask[]) => void;
  onError?: (error: FirestoreError) => void;
}

/**
 * Subscribe to realtime updates for all uncompleted task documents.
 * Different queries for company users vs admin/plant
 * @returns removeListeners ƒn, for cleanup.
 */
function subscribeAllChecklistUncompletedTasks(
  args: ISubscribeAllChecklistUncompletedTasks,
): () => void {
  const { isSiteAdmin, isPlantPersonnel } = args.permissions.permissions;
  if (isSiteAdmin || isPlantPersonnel) {
    return subscribeAllChecklistUncompletedTasks_admin(args);
  } else {
    return subscribeAllChecklistUncompletedTasks_company(args);
  }
}

function subscribeAllChecklistUncompletedTasks_admin(
  args: ISubscribeAllChecklistUncompletedTasks,
): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    where("craftType", "==", CraftTypes.CHECKLISTS),
    where("taskStatus", "!=", TaskStatus.COMPLETE),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const uncompletedTasksList: ExistingTask[] = querySnapshot.docs.map(
        (snapshot) => TaskRecordManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(uncompletedTasksList);
    },
    args.onError,
  );

  return removeListeners;
}

function subscribeAllChecklistUncompletedTasks_company(
  args: ISubscribeAllChecklistUncompletedTasks,
): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    where("craftType", "==", CraftTypes.CHECKLISTS),
    where("assignedCompanyID", "==", args.permissions.companyID),
    where("taskStatus", "!=", TaskStatus.COMPLETE),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const uncompletedTasksList: ExistingTask[] = querySnapshot.docs.map(
        (snapshot) => TaskRecordManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(uncompletedTasksList);
    },
    args.onError,
  );

  return removeListeners;
}

/** Subscribe to changes on a single task document. */
function subscribeToOneDocumentByTaskID(
  siteKey: string,
  taskID: string,
  onChange: (taskDoc: ExistingTask) => void,
  onError?: (error: FirestoreError) => void,
) {
  const db = getFirestore();
  const docRef = doc(db, "siteKeys", siteKey, "tasks", taskID);

  const unsubscribe = onSnapshot(
    docRef,
    (snapshot) => {
      // Convert Firestore snapshot to our local data structure.
      const taskDoc: ExistingTask =
        TaskRecordManager.createFromFirestoreSnapshot(snapshot);
      onChange(taskDoc);
    },
    onError,
  );

  return unsubscribe;
}

interface IGetAllTasksOfASingleChecklist {
  siteKey: string;
  permissions: SiteKeyUserPermissions;
  checklistPath: string;
}

/**
 * Returns all the tasks of a single Checklist for the given siteKey.
 * Calls different queries for company users vs admin/plant personnel
 */
async function getAllTasksOfASingleChecklist(
  args: IGetAllTasksOfASingleChecklist,
): Promise<ExistingTask[]> {
  const { isSiteAdmin, isPlantPersonnel } = args.permissions.permissions;
  if (isSiteAdmin || isPlantPersonnel) {
    return getAllTasksOfASingleChecklist_admin(args);
  } else {
    return getAllTasksOfASingleChecklist_company(args);
  }
}

async function getAllTasksOfASingleChecklist_admin(
  args: IGetAllTasksOfASingleChecklist,
): Promise<ExistingTask[]> {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    where("craftRecordID", "==", args.checklistPath),
  );
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((snapshot) => {
    return TaskRecordManager.createFromFirestoreSnapshot(snapshot);
  });
}

async function getAllTasksOfASingleChecklist_company(
  args: IGetAllTasksOfASingleChecklist,
): Promise<ExistingTask[]> {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    where("craftRecordID", "==", args.checklistPath),
    where("assignedCompanyID", "==", args.permissions.companyID),
  );
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((snapshot) => {
    return TaskRecordManager.createFromFirestoreSnapshot(snapshot);
  });
}

interface ISubscribeAllTasksOfASingleCustomer {
  siteKey: string;
  permissions: SiteKeyUserPermissions;
  customerID: string;
  onChange: (taskList: ExistingTask[]) => void;
  onError?: (error: FirestoreError) => void;
}

/**
 * Subscribe to real-time updates for task documents that match the given customerID.
 * Runs different queries depend is user is admin/plant or company user.
 * @returns removeListeners function, for cleanup.
 */
function subscribeAllTasksOfASingleCustomer(
  args: ISubscribeAllTasksOfASingleCustomer,
): () => void {
  const { isSiteAdmin, isPlantPersonnel } = args.permissions.permissions;
  if (isSiteAdmin || isPlantPersonnel) {
    return subscribeAllTasksOfASingleCustomer_admin(args);
  } else {
    return subscribeAllTasksOfASingleCustomer_company(args);
  }
}

function subscribeAllTasksOfASingleCustomer_admin(
  args: ISubscribeAllTasksOfASingleCustomer,
): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    where("customerID", "==", args.customerID),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const taskList: ExistingTask[] = querySnapshot.docs.map((snapshot) =>
        TaskRecordManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(taskList);
    },
    args.onError,
  );

  return removeListeners;
}

function subscribeAllTasksOfASingleCustomer_company(
  args: ISubscribeAllTasksOfASingleCustomer,
): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    where("customerID", "==", args.customerID),
    where("assignedCompanyID", "==", args.permissions.companyID),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const taskList: ExistingTask[] = querySnapshot.docs.map((snapshot) =>
        TaskRecordManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(taskList);
    },
    args.onError,
  );

  return removeListeners;
}

interface ISubscribeAllTasksOfASingleChecklist {
  siteKey: string;
  checklistPath: string;
  permissions: SiteKeyUserPermissions;
  onChange: (taskList: ExistingTask[]) => void;
  onError?: (error: FirestoreError) => void;
}

/**
 * Subscribe to real-time updates for task documents that match the given checklistPath.
 * Runs different queries depend is user is admin/plant or company user.
 * @returns removeListeners function, for cleanup.
 */
function subscribeAllTasksOfASingleChecklist(
  args: ISubscribeAllTasksOfASingleChecklist,
): () => void {
  const { isSiteAdmin, isPlantPersonnel } = args.permissions.permissions;
  if (isSiteAdmin || isPlantPersonnel) {
    return subscribeAllTasksOfASingleChecklist_admin(args);
  } else {
    return subscribeAllTasksOfASingleChecklist_company(args);
  }
}

function subscribeAllTasksOfASingleChecklist_admin(
  args: ISubscribeAllTasksOfASingleChecklist,
): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    where("craftRecordID", "==", args.checklistPath),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const taskList: ExistingTask[] = querySnapshot.docs.map((snapshot) =>
        TaskRecordManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(taskList);
    },
    args.onError,
  );

  return removeListeners;
}

function subscribeAllTasksOfASingleChecklist_company(
  args: ISubscribeAllTasksOfASingleChecklist,
): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    where("craftRecordID", "==", args.checklistPath),
    where("assignedCompanyID", "==", args.permissions.companyID),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const taskList: ExistingTask[] = querySnapshot.docs.map((snapshot) =>
        TaskRecordManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(taskList);
    },
    args.onError,
  );

  return removeListeners;
}

/**
 * @param siteKey
 * @param taskID
 * @returns A single task document, based on the given taskID
 */
async function getSingleTaskDocumentByTaskID(
  siteKey: string,
  taskID: string,
): Promise<ExistingTask> {
  const db = getFirestore();
  const docRef = doc(db, "siteKeys", siteKey, "tasks", taskID);
  const docSnapshot = await getDoc(docRef);
  return TaskRecordManager.createFromFirestoreSnapshot(docSnapshot);
}

/**
 * Replace the array of checklist item IDs for the craft record (checklist).
 * @param refPath String path to the craft record document.
 * @param checklistItemIDList Array of string IDs for checklist items.
 */
async function updateChecklistItemsOrder(
  refPath: string,
  checklistItemIDList: string[],
) {
  await DbWrite.craftDetails.update(refPath, {
    checklistItems: checklistItemIDList,
  });
}

async function getAllSiteKeyLocations(
  siteKey: string,
): Promise<ExistingSiteKeyLocation[]> {
  // Create the database object (based on project initialized in init-firebase.ts)
  const db = getFirestore();
  // Await the asynchronous function, returns a QuerySnapshot object from database.
  const querySnapshot = await getDocs(
    collection(db, "siteKeys", siteKey, "locations"),
  );

  // For each document snapshot in the query snapshot, apply the function.
  return querySnapshot.docs.map((snapshot) =>
    SiteKeyLocationManager.createFromFirestoreSnapshot(snapshot),
  );
}

/**
 * Fetch a root user document from the database without subscribing to real-time
 * updates.
 * @param uid The user's unique Firebase ID.
 */
async function getRootUser(uid: string): Promise<ExistingRootUser> {
  const db = getFirestore();
  const docRef = doc(db, "users", uid);
  const snapshot = await getDoc(docRef);
  if (!snapshot.exists)
    throw new NotFoundError(`Document not found. Path: ${docRef}`);
  return RootUserManager.createFromFirestoreSnapshot(snapshot);
}

/**
 * Fetch a root user document from the database without subscribing to real-time
 * updates.
 * @param uid The user's unique Firebase ID.
 * @param onSuccess the function to call when a document is found or updated.
 * @returns an unsubscribe function.
 */
function subscribeRootUser(
  uid: string,
  onSuccess: (rootUserDoc: ExistingRootUser) => void,
  onError: (error: Error) => void,
): () => void {
  const db = getFirestore();
  const docRef = doc(db, "users", uid);
  const unsubscribe = onSnapshot(
    docRef,
    (snapshot) => {
      if (snapshot.exists()) {
        const rootUserDoc =
          RootUserManager.createFromFirestoreSnapshot(snapshot);
        onSuccess(rootUserDoc);
      } else {
        throw new NotFoundError(`Document not found at ${snapshot.ref.path}`);
      }
    },
    onError,
  );

  return unsubscribe;
}

/**
 * Fetch a user display names document from the database without subscribing to real-time
 * updates.
 * @param siteKey .
 */
async function getUserDisplayNames(
  siteKey: string,
): Promise<Record<string, string>> {
  const db = getFirestore();
  const docRef = doc(db, "siteKeys", siteKey, "aggregates", "userDisplayNames");
  const snapshot = await getDoc(docRef);
  if (!snapshot.exists())
    throw new NotFoundError(`Document not found. Path: ${docRef}`);
  return snapshot.data();
}

/**
 * Returns all the items in the checklistItems collection for the given siteKey.
 * Omits deleted checklist items.
 */
async function getAllChecklistItems(
  siteKey: string,
): Promise<ExistingChecklistItem[]> {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    siteKey,
    "checklistItems",
  );
  const limitedQuery = query(
    collectionReference,
    where("deleted", "==", false),
    limit(5),
  );
  // Await the asynchronous function, returns a QuerySnapshot object from database.
  const querySnapshot = await getDocs(limitedQuery);
  // For each document snapshot in the query snapshot, apply the function.
  return querySnapshot.docs.map((snapshot) =>
    ChecklistItemManager.createFromFirestoreSnapshot(snapshot),
  );
}

/**
 * Removes a checklist item from a single craft record.
 */
async function removeChecklistItemFromOneCraftRecord(
  siteKey: string,
  craftRecordID: string,
  checklistItemID: string,
) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "checklistItemRemoveOrSetDeleted");
  return callable({
    siteKey: siteKey,
    craftRecordID: craftRecordID,
    checklistItemID: checklistItemID,
  });
}

async function generateBatchCustomerStatements(
  siteKey: string,
): Promise<string> {
  const functions = getFunctions();
  const callable = httpsCallable(
    functions,
    "generateBatchCustomerStatementPDF",
  );
  const response = await callable({
    siteKey: siteKey,
  });
  const data = response.data as any;
  if (typeof data?.data === "string") {
    return data?.data;
  } else {
    throw new Error(
      `URL was not a string. Received response: ${JSON.stringify(
        response.data,
        null,
        2,
      )}`,
    );
  }
}

async function generateBatchCustomerSchedules(
  siteKey: string,
  customerType: CustomerTypes | null,
): Promise<any> {
  const functions = getFunctions();
  const callable = httpsCallable(
    functions,
    "generateBatchCustomerSchedulesPDF",
    {
      timeout: 240000,
    },
  );
  const response = await callable({
    siteKey: siteKey,
    customerType: customerType,
  });
  return response.data as any;
}

async function generateCustomerStatement(
  siteKey: string,
  customerID: string,
): Promise<string> {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "generateCustomerStatementPDF");
  const response = await callable({
    siteKey: siteKey,
    customerID: customerID,
  });
  const data = response.data as any;
  if (typeof data?.data === "string") {
    return data?.data;
  } else {
    throw new Error(
      `URL was not a string. Received response: ${JSON.stringify(
        response.data,
        null,
        2,
      )}`,
    );
  }
}

async function generateCustomerSchedule(
  siteKey: string,
  customerID: string,
): Promise<any> {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "generateCustomerSchedulePDF");
  const response = await callable({
    siteKey: siteKey,
    customerID: customerID,
  });
  return response.data as any;
}

/**
 * Removes a checklist item from ALL craft records. Sets the item's "deleted" property to true.
 */
async function removeChecklistItemFromAllCraftRecords(
  siteKey: string,
  checklistItemID: string,
) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "checklistItemRemoveOrSetDeleted");
  return callable({
    siteKey: siteKey,
    checklistItemID: checklistItemID,
  });
}

// ^ Tested this ƒn visually on 11/29/21, since it's not on the UI yet.

/**
 * Returns all checklist responses in the checklistResponses collection for the given checklistItemID. Deleted responses not included.
 */
async function getAllChecklistResponsesByChecklistItemID(
  siteKey: string,
  checklistItemID: string,
): Promise<ExistingChecklistResponse[]> {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", siteKey, "checklistResponses"),
    where("checklistItemID", "==", checklistItemID),
    where("deleted", "==", false),
  );

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((snapshot) =>
    ChecklistResponseManager.createFromFirestoreSnapshot(snapshot),
  );
}

/**
 * @param siteKey
 * @param taskID
 * @returns All checklist response documents that match the given taskID. Deleted responses are omitted.
 */
async function getAllChecklistResponsesByTaskID(
  siteKey: string,
  taskID: string,
): Promise<ExistingChecklistResponse[]> {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", siteKey, "checklistResponses"),
    where("taskID", "==", taskID),
    where("deleted", "==", false),
  );

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((snapshot) =>
    ChecklistResponseManager.createFromFirestoreSnapshot(snapshot),
  );
}

/**
 * Subscribe to real-time updates for checklistResponse documents that match the given taskID. Omits deleted responses.
 * @param onChange A function to call when data updates.
 * @returns removeListeners function, for cleanup.
 */
function subscribeAllChecklistResponsesByTaskID(
  siteKey: string,
  taskID: string,
  onChange: (checklistResponseList: ExistingChecklistResponse[]) => void,
  onError?: (error: FirestoreError) => void,
): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", siteKey, "checklistResponses"),
    where("taskID", "==", taskID),
    where("deleted", "==", false),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const responseList: ExistingChecklistResponse[] = querySnapshot.docs.map(
        (snapshot) =>
          ChecklistResponseManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      onChange(responseList);
    },
    onError,
  );

  return removeListeners;
}

/**
 * Returns a single item in the checklistItems collection for the given siteKey and itemID.
 */
async function getSingleChecklistItem(
  siteKey: string,
  itemID: string,
): Promise<ExistingChecklistItem> {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const docRef = doc(db, "siteKeys", siteKey, "checklistItems", itemID);
  // Await the asynchronous function, returns a QuerySnapshot object from database.
  const snapshot = await getDoc(docRef);
  return ChecklistItemManager.createFromFirestoreSnapshot(snapshot);
}

interface ISubscribeAllChecklistItems {
  siteKey: string;
  permissions: SiteKeyUserPermissions;
  /* A function to call when data updates. */
  onChange: (checklistItems: ExistingChecklistItem[]) => void;
  onError?: (error: FirestoreError) => void;
}

/**
 * Subscribe to real-time updates for all documents in the checklistItems
 * collection. Must pass a callback to execute when data is updated.
 * Omits deleted checklist items.
 * @returns a remove listeners function.
 */
function subscribeAllChecklistItems(
  args: ISubscribeAllChecklistItems,
): () => void {
  const {
    permissions: { isSiteAdmin, isPlantPersonnel },
  } = args.permissions;
  // Need to use different Firestore queries depending on a user's permissions.
  if (isSiteAdmin || isPlantPersonnel) {
    const removeListeners = subscribeAllChecklistItems_admin(args);
    return removeListeners;
  } else {
    const removeListeners = subscribeAllChecklistItems_company(args);
    return removeListeners;
  }
}

function subscribeAllChecklistItems_admin(
  args: Omit<ISubscribeAllChecklistItems, "permissions">,
) {
  const db = getFirestore();
  const collectionRef = collection(
    db,
    "siteKeys",
    args.siteKey,
    "checklistItems",
  );
  const q = query(collectionRef, limit(100), where("deleted", "==", false));
  const removeListeners = onSnapshot(q, (querySnapshot) => {
    // Convert the Firestore snapshots to our local data structures.
    const checklistItems: ExistingChecklistItem[] = querySnapshot.docs.map(
      (docSnapshot) =>
        ChecklistItemManager.createFromFirestoreSnapshot(docSnapshot),
    );
    // Call the provided function, typically to update the UI with the new data.
    args.onChange(checklistItems);
  });
  return removeListeners;
}

function subscribeAllChecklistItems_company(args: ISubscribeAllChecklistItems) {
  const db = getFirestore();
  const collectionRef = collection(
    db,
    "siteKeys",
    args.siteKey,
    "checklistItems",
  );
  const q = query(
    collectionRef,
    limit(100),
    where("deleted", "==", false),
    where("authorizedCompanies", "array-contains", args.permissions.companyID),
  );

  const removeListeners = onSnapshot(q, (querySnapshot) => {
    // Convert the Firestore snapshots to our local data structures.
    const checklistItems: ExistingChecklistItem[] = querySnapshot.docs.map(
      (docSnapshot) =>
        ChecklistItemManager.createFromFirestoreSnapshot(docSnapshot),
    );
    // Call the provided function, typically to update the UI with the new data.
    args.onChange(checklistItems);
  });
  return removeListeners;
}

/**
 * Subscribe to real-time updates for checklistItems belonging to a specific
 * Checklist craft record collection. Must pass a callback to execute when
 * data is updated.
 * Omits deleted checklist items.
 * @param onChange A function to call when data updates.
 * @returns a remove listeners function.
 */
function subscribeChecklistItemsByCraftRecordID(
  siteKey: string,
  checkListID: string,
  onChange: (checklistItems: ExistingChecklistItem[]) => void,
  onError?: (error: FirestoreError) => void,
): () => void {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", siteKey, "checklistItems"),
    where("craftRecordIDs", "array-contains", checkListID),
    where("deleted", "==", false),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const checklistItems: ExistingChecklistItem[] = querySnapshot.docs.map(
        (docSnapshot) =>
          ChecklistItemManager.createFromFirestoreSnapshot(docSnapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      onChange(checklistItems);
    },
    onError,
  );
  return removeListeners;
}

/**
 * @param siteKey
 * @param checklistID
 * @returns All checklist items that match the given checklistID. Deleted responses are omitted.
 */
async function getChecklistItemsByCraftRecordID(
  siteKey: string,
  checklistID: string,
): Promise<ExistingChecklistItem[]> {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", siteKey, "checklistItems"),
    where("craftRecordIDs", "array-contains", checklistID),
    where("deleted", "==", false),
  );
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((snapshot) => {
    return ChecklistItemManager.createFromFirestoreSnapshot(snapshot);
  });
}

interface ISubscribeAllChecklists {
  siteKey: string;
  permissions: SiteKeyUserPermissions;
  onChange: (craftRecordList: ExistingCraftRecord[]) => void;
  onError?: (error: FirestoreError) => void;
}

/**
 * Subscribe to changes for checklists in the parentRecords collection.
 */
function subscribeAllChecklists(args: ISubscribeAllChecklists): () => void {
  const {
    permissions: { isSiteAdmin, isPlantPersonnel },
  } = args.permissions;
  if (isSiteAdmin || isPlantPersonnel) {
    return subscribeAllChecklists_admin(args);
  } else {
    return subscribeAllChecklists_company(args);
  }
}

/**
 * Query design for admins or personnel who can read data across companies (all data on a site).
 */
function subscribeAllChecklists_admin(
  args: ISubscribeAllChecklists,
): () => void {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "parentRecords"),
    where("craftType", "==", CraftTypes.CHECKLISTS),
  );

  // Subscribe to changes
  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const craftRecordList: ExistingCraftRecord[] = querySnapshot.docs.map(
        (docSnapshot) =>
          CraftRecordManager.createFromFirestoreSnapshot(docSnapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(craftRecordList);
    },
    args.onError,
  );

  return removeListeners;
}

/**
 * Query design for company users.
 */
function subscribeAllChecklists_company(
  args: ISubscribeAllChecklists,
): () => void {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "parentRecords"),
    where("craftType", "==", CraftTypes.CHECKLISTS),
    where("authorizedCompanies", "array-contains", args.permissions.companyID),
  );

  // Subscribe to changes
  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const craftRecordList: ExistingCraftRecord[] = querySnapshot.docs.map(
        (docSnapshot) =>
          CraftRecordManager.createFromFirestoreSnapshot(docSnapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(craftRecordList);
    },
    args.onError,
  );

  return removeListeners;
}

interface ISubscribeAllChecklistsSortedTSLastModified {
  siteKey: string;
  permissions: SiteKeyUserPermissions;
  onChange: (craftRecordList: ExistingCraftRecord[]) => void;
  onError?: (error: FirestoreError) => void;
}

/**
 * Subscribes to parent record updates for checklists sorted by timestamp last modified.
 */
function subscribeAllChecklistsSortedTSLastModified(
  args: ISubscribeAllChecklistsSortedTSLastModified,
): () => void {
  const {
    permissions: { isSiteAdmin, isPlantPersonnel },
  } = args.permissions;
  if (isSiteAdmin || isPlantPersonnel) {
    return subscribeAllChecklistsSortedTSLastModified_admin(args);
  } else {
    return subscribeAllChecklistsSortedTSLastModified_company(args);
  }
}

function subscribeAllChecklistsSortedTSLastModified_admin(
  args: ISubscribeAllChecklistsSortedTSLastModified,
): () => void {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();

  const q = query(
    collection(db, "siteKeys", args.siteKey, "parentRecords"),
    where("craftType", "==", CraftTypes.CHECKLISTS),
    orderBy("timestampLastModified", "desc"),
  );

  // Subscribe to changes
  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const craftRecordList: ExistingCraftRecord[] = querySnapshot.docs.map(
        (docSnapshot) =>
          CraftRecordManager.createFromFirestoreSnapshot(docSnapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(craftRecordList);
    },
    args.onError,
  );

  return removeListeners;
}

/**
 * Query for users with read all access (i.e. site admins and plant personnel typically);
 */
function subscribeAllChecklistsSortedTSLastModified_company(
  args: ISubscribeAllChecklistsSortedTSLastModified,
): () => void {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();

  // "!=" REQUIRES ADDING AN INDEX TO FIRESTORE
  const q = query(
    collection(db, "siteKeys", args.siteKey, "parentRecords"),
    where("craftType", "==", CraftTypes.CHECKLISTS),
    where("authorizedCompanies", "array-contains", args.permissions.companyID),
    orderBy("timestampLastModified", "desc"),
  );

  // Subscribe to changes
  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const craftRecordList: ExistingCraftRecord[] = querySnapshot.docs.map(
        (docSnapshot) =>
          CraftRecordManager.createFromFirestoreSnapshot(docSnapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(craftRecordList);
    },
    args.onError,
  );

  return removeListeners;
}

/**
 * Add a new checklistItem to the collection for the siteKey.
 * @returns the Document ID for the new item.
 */

/* async function addChecklistItem(
  siteKey: string,
  data: DocumentData
): Promise<string> {
  const db = getFirestore();
  const docRef = await addDoc(
    collection(db, "siteKeys", siteKey, "checklistItems"),
    data
  );

  return docRef.id;
} */

async function addChecklistItem(
  siteKey: string,
  craftRecordID: string,
  data: DocumentData,
) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "addNewChecklistItem");
  return callable({
    siteKey: siteKey,
    craftRecordID: craftRecordID,
    item: data,
  });
}

/**
 * Update a checklistItem to the collection for the siteKey.
 */
async function updateChecklistItem(
  siteKey: string,
  checklistID: string,
  data: DocumentData,
  itemID: string,
) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "updateChecklistItem");
  return callable({
    siteKey: siteKey,
    craftRecordID: checklistID,
    item: data,
    checklistItemId: itemID,
  });
}

/**
 * Add a new checklistResponse to the collection for the siteKey.
 * @returns the Document ID for the new item.
 */
async function addChecklistResponse(
  siteKey: string,
  data: DocumentData,
): Promise<string> {
  const db = getFirestore();
  const docRef = await addDoc(
    collection(db, "siteKeys", siteKey, "checklistResponses"),
    data,
  );

  return docRef.id;
}

async function generateChecklistResponses(args: {
  siteKey: string;
  taskID: string;
  craftRecordID: string;
}): Promise<void> {
  const functions = getFunctions();
  const genResponses = httpsCallable(functions, "generateChecklistResponses");
  const payload = {
    ...args,
  };
  await genResponses(payload);
}

/**
 * Add a new checklistPhoto document to the given siteKey.
 * @returns A document reference on successful write.
 */
async function addChecklistPhotoDoc(
  siteKey: string,
  data: DocumentData,
): Promise<DocumentReference> {
  const db = getFirestore();
  const collectionRef = collection(db, "siteKeys", siteKey, "checklistPhotos");
  const docRef = await addDoc(collectionRef, data);

  return docRef;
}

/**
 * Add a new checklistPhoto document to the given siteKey.
 * @returns A document reference on successful write.
 */
async function addStiltPhotoDoc(
  siteKey: string,
  docID: string,
  data: DocumentData,
): Promise<void> {
  const db = getFirestore();
  const collectionRef = doc(db, "siteKeys", siteKey, "photos", docID);
  return await setDoc(collectionRef, data);
}

/**
 * Add a new checklistPhoto document to the given siteKey.
 * @returns A document reference on successful write.
 */
async function addNote(siteKey: string, data: DocumentData): Promise<void> {
  const db = getFirestore();
  const docRef = await addDoc(
    collection(db, "siteKeys", siteKey, "notes"),
    data,
  );
  return await setDoc(docRef, data);
}

/**
 * Add a new checklistPhoto document to the given siteKey.
 * @returns A document reference on successful write.
 */
async function addChatRoom(siteKey: string, data: DocumentData): Promise<void> {
  const db = getFirestore();
  const docRef = await addDoc(
    collection(db, "siteKeys", siteKey, "chatRooms"),
    data,
  );
  return await setDoc(docRef, data);
}

/**
 * Add a new checklistPhoto document to the given siteKey.
 * @returns A document reference on successful write.
 */
async function addChatMessage(
  siteKey: string,
  roomID: string,
  data: DocumentData,
): Promise<void> {
  const db = getFirestore();
  const docRef = await addDoc(
    collection(db, "siteKeys", siteKey, "chatRooms", roomID, "messages"),
    data,
  );
  return await setDoc(docRef, data);
}

/**
 * 'Delete' the given checklist photo document. (Sets the deleted field to true.)
 */
async function deleteChecklistPhotoDoc(
  siteKey: string,
  docID: string,
): Promise<void> {
  const db = getFirestore();
  const docPath = doc(db, "siteKeys", siteKey, "checklistPhotos", docID);
  await updateDoc(docPath, { deleted: true });
}

/**
 * Create a new asset.
 * @returns A document reference on successful write.
 */
async function addAsset(
  siteKey: string,
  data: DocumentData,
): Promise<DocumentReference> {
  const db = getFirestore();
  const docRef = await addDoc(
    collection(db, "siteKeys", siteKey, "assets"),
    data,
  );
  console.log(docRef.id);
  return docRef;
}

/**
 * Create a new parent record / craft record.
 * @returns A document reference on successful write.
 */
async function addCraftRecord(
  siteKey: string,
  data: DocumentData,
): Promise<DocumentReference> {
  const db = getFirestore();
  const docRef = await addDoc(
    collection(db, "siteKeys", siteKey, "parentRecords"),
    data,
  );
  return docRef;
}

/**
 * Create a new parent record / craft record / work record, with an assigned doc ID.
 * @returns Document reference
 */
async function addCraftRecordWithID(
  docID: string,
  siteKey: string,
  data: DocumentData,
) {
  const db = getFirestore();
  const docRef = doc(db, "siteKeys", siteKey, "parentRecords", docID);
  await setDoc(docRef, data);
  return docRef;
}

async function updateCraftRecord(
  workRecordID: string,
  siteKey: string,
  data: DocumentData,
) {
  const db = getFirestore();
  const docPath = doc(db, "siteKeys", siteKey, "parentRecords", workRecordID);
  return updateDoc(docPath, data);
}

/**
 * Update a Checklist to the collection for the siteKey.
 */
async function updateChecklist(
  siteKey: string,
  data: DocumentData,
  checklistID: string,
): Promise<DocumentReference> {
  const db = getFirestore();
  const docPath = doc(db, "siteKeys", siteKey, "parentRecords", checklistID);
  await updateDoc(docPath, data);
  return docPath;
}

/**
 * Create a new Task.
 * @returns A document reference on successful write.
 */
async function addTask(
  siteKey: string,
  data: DocumentData,
): Promise<DocumentReference> {
  const db = getFirestore();
  const docRef = await addDoc(
    collection(db, "siteKeys", siteKey, "tasks"),
    data,
  );
  return docRef;
}

/**
 * Return and convert a document snapshot into a CraftRecord from the
 * parentRecords collection.
 */
async function getCraftRecord(
  siteKey: string,
  craftRecordId: string,
): Promise<ExistingCraftRecord> {
  const db = getFirestore();
  const docRef = doc(db, "siteKeys", siteKey, "parentRecords", craftRecordId);
  const snapshot = await getDoc(docRef);
  return CraftRecordManager.createFromFirestoreSnapshot(snapshot);
}

/**
 * Returns null if it can't find the craft record.
 */
async function getCraftRecordByRefPath(
  craftRecordPath: string,
): Promise<ExistingCraftRecord | null> {
  const db = getFirestore();
  const docRef = doc(db, craftRecordPath);
  try {
    const snapshot = await getDoc(docRef);
    return CraftRecordManager.createFromFirestoreSnapshot(snapshot);
  } catch (e) {
    return null;
  }
}

interface ISubscribeAllParentRecordPhotos {
  siteKey: string;
  permissions: SiteKeyUserPermissions;
  workRecordID: string;
  onChange: (stiltPhotoList: ExistingStiltPhoto[]) => void;
  onError?: (error: FirestoreError) => void;
}

function subscribeAllParentRecordPhotos(
  args: ISubscribeAllParentRecordPhotos,
): () => void {
  const { isSiteAdmin, isPlantPersonnel } = args.permissions.permissions;
  if (isSiteAdmin || isPlantPersonnel) {
    return subscribeAllParentRecordPhotos_readAll(args);
  } else {
    return subscribeAllParentRecordPhotos_read(args);
  }
}

/** WARNING: THIS UNDERGOES NO VALIDATION. Will be refactored in the future. */
function subscribeAllParentRecordPhotos_readAll(
  args: ISubscribeAllParentRecordPhotos,
): () => void {
  const db = getFirestore();
  const q = query(
    collection(
      db,
      "siteKeys",
      args.siteKey,
      "parentRecords",
      args.workRecordID,
      "photos",
    ),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const photoList: any[] = querySnapshot.docs.map((snapshot) => {
        return { id: snapshot.id, ...snapshot.data() };
      });
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(photoList);
    },
    args.onError,
  );

  return removeListeners;
}

/** WARNING: THIS UNDERGOES NO VALIDATION. Will be refactored in the future. */
function subscribeAllParentRecordPhotos_read(
  args: ISubscribeAllParentRecordPhotos,
): () => void {
  const db = getFirestore();
  const q = query(
    collection(
      db,
      "siteKeys",
      args.siteKey,
      "parentRecords",
      args.workRecordID,
      "photos",
    ),
    where("authorizedCompanies", "array-contains", args.permissions.companyID),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const photoList: any[] = querySnapshot.docs.map((snapshot) => {
        return { id: snapshot.id, ...snapshot.data() };
      });
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(photoList);
    },
    args.onError,
  );

  return removeListeners;
}

/** WARNING: THIS UNDERGOES NO VALIDATION. Will be refactored in the future. */
async function getAllParentRecordPhotos(args: {
  siteKey: string;
  workRecordID: string;
  userPermissions: SiteKeyUserPermissions;
}): Promise<ExistingStiltPhoto[]> {
  const { isSiteAdmin, isPlantPersonnel } = args.userPermissions.permissions;
  if (isSiteAdmin || isPlantPersonnel) {
    return getAllParentRecordPhotos_readAll(args.siteKey, args.workRecordID);
  } else {
    return getAllParentRecordPhotos_read(
      args.siteKey,
      args.workRecordID,
      args.userPermissions,
    );
  }
}

/** WARNING: THIS UNDERGOES NO VALIDATION. Will be refactored in the future. */
async function getAllParentRecordPhotos_readAll(
  siteKey: string,
  workRecordID: string,
) {
  const db = getFirestore();
  const q = query(
    collection(
      db,
      "siteKeys",
      siteKey,
      "parentRecords",
      workRecordID,
      "photos",
    ),
  );
  const querySnap = await getDocs(q);

  return querySnap.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  }) as any[];
}

/** WARNING: THIS UNDERGOES NO VALIDATION. Will be refactored in the future. */
async function getAllParentRecordPhotos_read(
  siteKey: string,
  workRecordID: string,
  userPermissions: SiteKeyUserPermissions,
) {
  const db = getFirestore();
  const q = query(
    collection(
      db,
      "siteKeys",
      siteKey,
      "parentRecords",
      workRecordID,
      "photos",
    ),
    where("authorizedCompanies", "array-contains", userPermissions.companyID),
  );
  const querySnap = await getDocs(q);

  return querySnap.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  }) as any[];
}

async function addParentRecordPhoto(args: {
  siteKey: string;
  workRecordID: string;
  photoDoc: DocumentData;
}) {
  const db = getFirestore();
  const collPath = collection(
    db,
    "siteKeys",
    args.siteKey,
    "parentRecords",
    args.workRecordID,
    "photos",
  );
  return addDoc(collPath, args.photoDoc);
}

/** Deletes the doc referenced by the given photoID. ACTUALLY deletes it. Gone. */
async function deleteParentRecordPhoto(args: {
  siteKey: string;
  workRecordID: string;
  photoID: string;
}): Promise<void> {
  const db = getFirestore();
  const docRef = doc(
    db,
    "siteKeys",
    args.siteKey,
    "parentRecords",
    args.workRecordID,
    "photos",
    args.photoID,
  );
  return deleteDoc(docRef);
}

/**
 * Subscribe to real-time changes in a craft record document.
 */
function subscribeCraftRecord(
  siteKey: string,
  craftRecordId: string,
  onChange: (craftRecord: ExistingCraftRecord | undefined) => void,
  onError?: (error: Error) => void,
): () => void {
  const db = getFirestore();
  const docRef = doc(db, "siteKeys", siteKey, "parentRecords", craftRecordId);
  const unsubscribe = onSnapshot(
    docRef,
    (snapshot) => {
      if (!snapshot.exists()) {
        onChange(undefined);
        return;
      }
      const craftRecord =
        CraftRecordManager.createFromFirestoreSnapshot(snapshot);
      onChange(craftRecord);
    },
    onError,
  );

  return unsubscribe;
}

/**
 * Subscribe to real-time changes in a craft record document.
 */
function subscribeDeletedCraftRecord(
  siteKey: string,
  craftRecordId: string,
  onChange: (craftRecord: ExistingCraftRecord) => void,
  onError?: (error: Error) => void,
): () => void {
  const db = getFirestore();
  const docRef = doc(
    db,
    "siteKeys",
    siteKey,
    "deletedParentRecords",
    craftRecordId,
  );
  const unsubscribe = onSnapshot(
    docRef,
    (snapshot) => {
      const craftRecord =
        CraftRecordManager.createFromFirestoreSnapshot(snapshot);
      onChange(craftRecord);
    },
    onError,
  );

  return unsubscribe;
}

async function getCustomToken(tokenKey: string) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "getCustomToken");
  return callable({ tokenKey: tokenKey });
}

async function getTypesenseSearchKey(siteKey: string) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "getTypesenseSearchKey");
  return callable({ siteKey: siteKey });
}

async function createTokenKey(): Promise<string> {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "createCustomToken");
  const response = await callable();
  const tokenKey = (response.data as any).tokenKey;
  if (typeof tokenKey === "string") {
    return tokenKey;
  } else {
    throw new Error(
      `Error generating new custom token key. tokenKey: ${tokenKey}, data: ${JSON.stringify(
        response.data,
        null,
        2,
      )}`,
    );
  }
}

interface UpdateCraftDetailsParams {
  refPath: string;

  [k: string]: any;
}

/**
 * Update the "craftDetails" map for a Craft Record. These are the customizable, dynamic fields for the Craft Record.
 * Often unique to a particular site. Validated on the server against the site key's customizations map.
 */
async function updateCraftDetails({
  refPath,
  ...rest
}: UpdateCraftDetailsParams) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "updateCraftDetails");
  return callable({ refPath: refPath, ...rest });
}

interface UpdateTaskSpecificDetailsParams {
  refPath: string;

  [k: string]: any;
}

/**
 * Update the "taskSpecificDetails" map for a Task. These are the customizable, dynamic fields for the Task.
 * Often unique to a particular site, craft type, and task type. Validated on the server against the site
 * key's customizations map.
 */
async function updateTaskSpecificDetails({
  refPath,
  ...rest
}: UpdateTaskSpecificDetailsParams) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "updateTaskSpecificDetails");
  return callable({ refPath: refPath, ...rest });
}

/**
 * @param siteKey
 * @param taskID
 * @returns All checklist photo documents that match the given taskID. Deleted responses are omitted.
 */
async function getAllChecklistPhotosByTaskID(
  siteKey: string,
  taskID: string,
): Promise<ExistingChecklistPhoto[]> {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", siteKey, "checklistPhotos"),
    where("taskID", "==", taskID),
    where("deleted", "==", false),
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((snapshot) =>
    ChecklistPhotoManager.createFromFirestoreSnapshot(snapshot),
  );
}

/**
 * Subscribe to real-time updates for checklistPhoto documents that
 * match the given taskID. Omits deleted photos.
 * @param onChange A function to call when data updates.
 * @returns removeListeners function, for cleanup.
 */
function subscribeAllChecklistPhotosByTaskID(
  siteKey: string,
  taskID: string,
  onChange: (checklistPhotoList: ExistingChecklistPhoto[]) => void,
  onError?: (error: FirestoreError) => void,
): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", siteKey, "checklistPhotos"),
    where("taskID", "==", taskID),
    where("deleted", "==", false),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const photosList: ExistingChecklistPhoto[] = querySnapshot.docs.map(
        (snapshot) =>
          ChecklistPhotoManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      onChange(photosList);
    },
    onError,
  );

  return removeListeners;
}

async function updateChecklistResponseComment(
  commentsUpdate: DocumentData,
  siteKey: string,
  responseID: string,
): Promise<void> {
  const db = getFirestore();
  const docPath = doc(
    db,
    "siteKeys",
    siteKey,
    "checklistResponses",
    responseID,
  );
  const result = updateDoc(docPath, commentsUpdate);
  return result;
}

async function updateChecklistResponseValue(
  responseValueUpdate: DocumentData,
  siteKey: string,
  responseID: string,
): Promise<DocumentReference> {
  const db = getFirestore();
  const docPath = doc(
    db,
    "siteKeys",
    siteKey,
    "checklistResponses",
    responseID,
  );
  await updateDoc(docPath, responseValueUpdate);
  return docPath;
}

async function updateTaskStatus(
  taskUpdate: DocumentData,
  siteKey: string,
  taskID: string,
): Promise<DocumentReference> {
  const db = getFirestore();
  const docPath = doc(db, "siteKeys", siteKey, "tasks", taskID);
  await updateDoc(docPath, taskUpdate);
  return docPath;
}

/**
 * Returns an existing site key permissions doc.
 * @throws NotFoundError
 */
async function getSiteKeyPermissions(
  siteKey: string,
  uid: string,
): Promise<ExistingSiteKeyUserPermissions> {
  const db = getFirestore();
  const docRef = doc(
    db,
    "siteKeys",
    siteKey,
    "siteKeyUsers",
    uid,
    "privateColl",
    "privateDoc",
  );
  const snapshot = await getDoc(docRef);
  if (snapshot.exists() !== true) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }
  return SiteKeyUserPermissionsManager.fromFirestore(snapshot);
}

/**
 * Returns a user doc for the given site key.
 * @throws NotFoundError
 */
async function getUserDoc(
  siteKey: string,
  uid: string,
): Promise<ExistingSiteKeyUserDoc> {
  const db = getFirestore();
  const docRef = doc(db, "siteKeys", siteKey, "siteKeyUsers", uid);
  const snapshot = await getDoc(docRef);
  if (snapshot.exists() !== true) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }
  return SiteKeyUsersManager.createFromSnapshot(snapshot);
}

async function addServerJob(data: NewServerJob): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");
  const idToken = await getIdToken(user);
  const endpoint = "server-job";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;
  try {
    await axios.post(fullApiRoute, data, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

async function deleteServerJob(siteKey: string, jobID: string): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");
  const idToken = await getIdToken(user);
  const endpoint = "server-job";
  const fullApiRoute = `${apiBaseURL}/${endpoint}/${siteKey}/${jobID}`;
  try {
    await axios.delete(fullApiRoute, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
    }
    throw err;
  }
}

async function listServerJobsByTemplateID(
  siteKey: string,
  craftRecordID: string,
): Promise<ExistingServerJob[]> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");
  const idToken = await getIdToken(user);
  const endpoint = `server-job/${siteKey}/${craftRecordID}`;
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;
  try {
    const response = await axios.get(fullApiRoute, {
      headers: { authorization: `Bearer ${idToken}` },
    });
    // todo: would like to refactor this api to return list as an array within the data object. data: {jobs: []} instead of data: [].
    // or is tsoa and axios handling this for us?
    return response.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
    }
    throw err;
  }
}

async function getCommissionReportData(
  siteKey: string,
  startDate: string,
  endDate: string,
): Promise<Record<string, CommissionReportData>> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");
  const idToken = await getIdToken(user);
  const endpoint = `commissions`;
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;
  try {
    const response = await axios.get(fullApiRoute, {
      headers: { authorization: `Bearer ${idToken}` },
      params: { siteKey, startDate, endDate },
    });
    // todo: would like to refactor this api to return list as an array within the data object. data: {jobs: []} instead of data: [].
    // or is tsoa and axios handling this for us?
    return response.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
    }
    throw err;
  }
}

async function getSingleSiteKeyLocation(
  siteKey: string,
  locationID: string,
): Promise<ExistingSiteKeyLocation> {
  const db = getFirestore();
  const docRef = doc(db, "siteKeys", siteKey, "locations", locationID);
  const docSnapshot = await getDoc(docRef);
  return SiteKeyLocationManager.createFromFirestoreSnapshot(docSnapshot);
}

/**
 * Returns all the users doc for the given site key.
 * Will only work for isSiteAdmin or isPlantPersonnel.
 * @throws NotFoundError
 */
async function getAllUsers(args: {
  siteKey: ExistingSiteKey;
  filterInactiveUnapproved?: boolean;
}): Promise<ExistingSiteKeyUserDoc[]> {
  const db = getFirestore();
  const q = query(collection(db, "siteKeys", args.siteKey.id, "siteKeyUsers"));
  const querySnapshot = await getDocs(q);
  let usersList = querySnapshot.docs.map((snapshot) => {
    return SiteKeyUsersManager.createFromSnapshot(snapshot);
  });

  if (args.filterInactiveUnapproved) {
    usersList = usersList.filter(
      (u) =>
        !args.siteKey.inactiveUsers?.includes(u.id) &&
        !args.siteKey.unapprovedUsers?.includes(u.id),
    );
  }

  return usersList;
}

/**
 * Returns all the signature docs for the provided estimate/invoice/task
 * @throws NotFoundError
 */
async function getSignatures(args: {
  siteKey: string;
  estimateID?: string;
  invoiceID?: string;
  taskID?: string;
}): Promise<ExistingSignature[]> {
  const db = getFirestore();

  const queryConstraints = [];

  if (args.estimateID) {
    queryConstraints.push(where("estimateID", "==", args.estimateID));
  }
  if (args.invoiceID) {
    queryConstraints.push(where("invoiceID", "==", args.invoiceID));
  }
  if (args.taskID) {
    queryConstraints.push(where("taskID", "==", args.taskID));
  }

  const q = query(
    collection(db, "siteKeys", args.siteKey, "signatures"),
    ...queryConstraints,
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((snapshot) => {
    return SignatureManager.createFromFirestoreSnapshot(snapshot);
  });
}

/**
 * Returns all the companies docs for the given site key.
 * @throws NotFoundError
 */
async function adminGetSiteKeyCompanies(
  siteKey: string,
): Promise<ExistingSiteKeyCompany[]> {
  const db = getFirestore();
  const q = query(collection(db, "siteKeys", siteKey, "siteKeyCompanies"));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((snapshot) => {
    return SiteKeyCompanyRecordManager.createFromFirestoreSnapshot(snapshot);
  });
}

/**
 * Returns two companies doc, one for the contractor company and one for the managing company.
 * @throws NotFoundError
 */
async function getCompaniesForContractor(
  siteKey: string,
  managingCompanyID: string,
  contractorCompanyID: string,
): Promise<ExistingSiteKeyCompany[]> {
  const db = getFirestore();
  const docRefForManaging = doc(
    db,
    "siteKeys",
    siteKey,
    "siteKeyCompanies",
    managingCompanyID,
  );
  const docRefForContractor = doc(
    db,
    "siteKeys",
    siteKey,
    "siteKeyCompanies",
    contractorCompanyID,
  );

  const readPromises = [
    getDoc(docRefForManaging),
    getDoc(docRefForContractor),
  ] as const;

  const arrayOfDocSnapshots = await Promise.all(readPromises);

  const result = arrayOfDocSnapshots.map((snapshot) => {
    return SiteKeyCompanyRecordManager.createFromFirestoreSnapshot(snapshot);
  });
  return result;
}

/**
 * Returns a site key doc for the given site key.
 */
async function getSiteKeyDoc(siteKey: string): Promise<ExistingSiteKey> {
  const db = getFirestore();
  const docRef = doc(db, "siteKeys", siteKey);
  const docSnapshot = await getDoc(docRef);
  return SiteKeyManager.createFromFirestoreSnapshot(docSnapshot);
}

/**
 * Returns a site key doc for the given site key.
 */
function subscribeSiteKeyDoc(args: {
  siteKey: string;
  onChange: (siteKey: ExistingSiteKey) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const docRef = doc(db, "siteKeys", args.siteKey);
  return onSnapshot(
    docRef,
    (snapshot) => {
      const siteKey = SiteKeyManager.createFromFirestoreSnapshot(snapshot);
      args.onChange(siteKey);
    },
    args.onError,
  );
}

/**
 * Create a new user from the Admin UI.
 */
async function addNewUser(
  siteKey: string,
  whiteLabel: WhiteLabel,
  userDoc: SiteKeyUserDoc,
  permissionsDoc: SiteKeyUserPermissions,
): Promise<{
  status: number;
  response: string;
  passphrase: string | null;
}> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "users";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  const allData = { siteKey, whiteLabel, ...userDoc, ...permissionsDoc };

  try {
    const response = await axios.post(fullApiRoute, allData, {
      headers: { authorization: `Bearer ${idToken}` },
    });
    return {
      status: response.data.theStatusCode,
      response: response.data.theResponse,
      passphrase: response.data.thePassphrase,
    };
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/* Update the userPhoto_URL field for the given user id*/
async function updateUserPhotoURL(
  value: string,
  siteKey: string,
  userID: string,
): Promise<void> {
  const db = getFirestore();
  const docPath = doc(db, "siteKeys", siteKey, "siteKeyUsers", userID);
  const result = updateDoc(docPath, { userPhoto_URL: value });
  return result;
}

/**
 * Update the phone number on site key user doc
 */
async function updateUserPhone(
  newPhoneNumber: string,
  siteKey: string,
  userID: string,
): Promise<void> {
  const db = getFirestore();
  const docPath = doc(db, "siteKeys", siteKey, "siteKeyUsers", userID);
  const result = updateDoc(docPath, { phone: newPhoneNumber });
  return result;
}

/* update a user doc to the collection for the siteKey */
async function updateSiteKeyUserDoc(
  siteKey: string,
  data: DocumentData,
  userID: string,
): Promise<void> {
  const db = getFirestore();
  const docPath = doc(db, "siteKeys", siteKey, "siteKeyUsers", userID);
  console.log(data, siteKey, userID);
  const result = await updateDoc(docPath, data);
  return result;
}

/* update a user doc to the collection for the siteKey */
async function updateRootUser(
  siteKey: string,
  data: DocumentData,
  userID: string,
): Promise<void> {
  const db = getFirestore();
  const docPath = doc(db, "users", userID);
  console.log(data, siteKey, userID);
  const result = await updateDoc(docPath, data);
  return result;
}

async function updateSiteKeyUserPermissions(
  siteKey: string,
  data: DocumentData,
  userID: string,
): Promise<void> {
  const db = getFirestore();
  const docPath = doc(
    db,
    "siteKeys",
    siteKey,
    "siteKeyUsers",
    userID,
    "privateColl",
    "privateDoc",
  );
  const result = await updateDoc(docPath, data);
  return result;
}

async function sendPasswordResetLink(args: {
  email: string;
  version: string;
}): Promise<void> {
  const functions = getFunctions();
  const genResponses = httpsCallable(functions, "sendPasswordResetLink");
  const payload = {
    ...args,
  };
  await genResponses(payload);
}

interface ResetUserPasswordResponse {
  result: string;
  isError: boolean;
}

async function resetUserPassword(args: {
  uid: string;
  siteKey: string;
}): Promise<ResetUserPasswordResponse> {
  const functions = getFunctions();
  const resetUserPasswordCallable = httpsCallable(
    functions,
    "resetUserPassword",
  );
  try {
    const result = await resetUserPasswordCallable({
      uid: args.uid,
      siteKey: args.siteKey,
    });
    const response = result.data as string;
    return { result: response, isError: false };
  } catch (error: any) {
    return {
      result: `Error resetting user password: ${error.message}`,
      isError: true,
    };
  }
}

interface DownloadLinkResult {
  downloadLink: string | null;
  isDownloadLinkError: boolean;
}

async function sendAppDownloadLinkToDesktopUser(args: {
  version: string;
  platform: string;
}): Promise<DownloadLinkResult> {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "sendAppDownloadLinkToDesktopUser");
  try {
    const response = await callable({
      version: args.version,
      platform: args.platform,
    });
    const downloadLink = response.data as string;
    if (!downloadLink) {
      throw new Error("Download link not retrieved.");
    }

    return { downloadLink, isDownloadLinkError: false };
  } catch (error) {
    devLogger.error("Error in retrieving download link: ", error);
    return { downloadLink: null, isDownloadLinkError: true };
  }
}

/**
 * Send app download links reminder to user
 */
async function sendAppDownloadLinks(
  siteKey: string,
  whiteLabel: WhiteLabel,
  email: string,
  uid: string,
): Promise<{
  status: number;
  response: string;
}> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "download-links";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;
  const allData = { siteKey, whiteLabel, email, uid };

  try {
    const response = await axios.post(fullApiRoute, allData, {
      headers: { authorization: `Bearer ${idToken}` },
    });
    return { status: response.data.status, response: response.data.response };
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/**
 * Get a map of root users
 */
async function generateRootUsersMap(
  siteKey: string,
): Promise<Record<string, RootUsersMapParams>> {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "generateRootUsersMap");
  const response = await callable({
    siteKey: siteKey,
  });

  const data = response.data;

  const unverifiedRootUsersMap: Record<string, unknown> = {};

  //check if data is an object
  if (guardIsPlainObject(data)) {
    //for each root user doc, convert the firebase timestamp to a new timestamp object as suggested here
    //https://stackoverflow.com/questions/56420690/firestore-timestamp-passed-through-callable-functions/56421056#56421056
    Object.entries(data).forEach(([key, value]) => {
      const { appLastOpenedTimestamp, ...rest } = value as any;
      unverifiedRootUsersMap[key] = {
        appLastOpenedTimestamp:
          appLastOpenedTimestamp !== null
            ? new Timestamp(
                appLastOpenedTimestamp._seconds,
                appLastOpenedTimestamp._nanoseconds,
              )
            : null,
        ...rest,
      };
    });
  } else {
    devLogger.error(`RootUsersMap is not an object`, data);
  }

  //set the schema for validate the converted root users map
  const validateDataSchema = z.record(
    z.object({
      appLastOpenedTimestamp: z.instanceof(Timestamp).nullable(),
      currentBundleID: z.string().max(200).nullable(),
      currentAppVersion: z.number().nullable(),
    }),
  );

  //validate the root users map
  const rootUsersMap = validateDataSchema.parse(unverifiedRootUsersMap);

  return rootUsersMap;
}

/**
 * Get a list of SiteKey documents where the user is approved based on the list of site keys on root user private doc
 */
async function getRootUserListOfSiteKeyDocs(): Promise<ExistingSiteKey[]> {
  const functions = getFunctions();
  const callable = httpsCallable<unknown, ExistingSiteKey[]>(
    functions,
    "getListOfSiteKeysDocs",
  );

  const response = await callable();

  const unverifiedData = response.data;

  const siteKeyDocList = unverifiedData.map((data) => {
    const validData = SiteKeyManager.parseWithFallbacks(data);
    const id = data.id;
    const refPath = data.refPath;
    return { id, refPath, ...validData };
  });

  return siteKeyDocList;
}

/**
 * Delete the given custom field.
 */
async function deleteCustomField(
  siteKey: string,
  documentID: string,
  customFieldDeletionParams: CustomFieldToDelete,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "custom-field";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;
  const allData = { siteKey, documentID, ...customFieldDeletionParams };

  try {
    await axios.delete(fullApiRoute, {
      headers: { authorization: `Bearer ${idToken}` },
      data: allData,
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/**
 * Send altered siteKey fields to Firestore.
 */
async function editSiteKey(
  siteKey: string,
  data: EditSiteKeyDetailsState,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);
  const endpoint = "site-keys";
  const fullApiRoute = `${apiBaseURL}/${endpoint}/${siteKey}`;
  try {
    await axios.put(fullApiRoute, data, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
    }
    throw err;
  }
}

async function setCallForwarding(
  siteKey: string,
  callForwardingEnabled: boolean,
): Promise<void> {
  const functions = getFunctions();
  console.log(
    `siteKey: ${siteKey} callForwardingEnabled: ${callForwardingEnabled}`,
  );
  const genResponses = httpsCallable(functions, "setCallForwarding");
  await genResponses({
    siteKey: siteKey,
    callForwardingEnabled: callForwardingEnabled,
  });
}

/**
 * Returns all the customField collection.
 */
async function getAllCustomFieldsOfASiteKey(
  siteKey: string,
): Promise<ExistingCustomField[]> {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", siteKey, "customFields"),
    where("deleted", "==", false),
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((snapshot) => {
    return CustomFieldManager.createFromFirestoreSnapshot(snapshot);
  });
}

/**
 * Add a custom field.
 */
async function addCustomField(
  siteKey: string,
  customFieldData: CustomFieldDocData,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "custom-field";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;
  const data = { siteKey, ...customFieldData };

  try {
    await axios.put(fullApiRoute, data, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/**
 * Add a new site key company.
 */
async function adminAddNewSiteKeyCompany(
  siteKey: string,
  company: DocumentData,
): Promise<string> {
  const db = getFirestore();
  const docRef = await addDoc(
    collection(db, "siteKeys", siteKey, "siteKeyCompanies"),
    company,
  );
  return docRef.id;
}

/* Edit a site key company */
async function adminEditCompany(
  siteKey: string,
  companyID: string,
  companyDoc: DocumentData,
): Promise<void> {
  const db = getFirestore();
  const docPath = doc(db, "siteKeys", siteKey, "siteKeyCompanies", companyID);
  const result = await updateDoc(docPath, companyDoc);
  return result;
}

/**
 * Update the company logo on site key company doc
 */
async function adminUpdateCompanyLogo(
  siteKey: string,
  companyID: string,
  companyLogoURL: string,
): Promise<void> {
  const db = getFirestore();
  const docPath = doc(db, "siteKeys", siteKey, "siteKeyCompanies", companyID);
  const result = updateDoc(docPath, { logoPhotoURL: companyLogoURL });
  return result;
}

/**
 * Add a new site key location.
 */
async function adminAddNewSiteKeyLocation(
  siteKey: string,
  location: DocumentData,
): Promise<string> {
  const db = getFirestore();
  const docRef = await addDoc(
    collection(db, "siteKeys", siteKey, "locations"),
    location,
  );

  return docRef.id;
}

/* Edit a site key location */
async function adminEditLocation(
  siteKey: string,
  locationID: string,
  locationDoc: DocumentData,
): Promise<void> {
  const db = getFirestore();
  const docPath = doc(db, "siteKeys", siteKey, "locations", locationID);
  const result = await updateDoc(docPath, locationDoc);
  return result;
}

/* Create an account */
async function createAccount(payload: DocumentData): Promise<void> {
  const functions = getFunctions();
  const genResponses = httpsCallable(functions, "createAccount");
  await genResponses(payload);
}

/* send email verification link */
async function sendVerificationLink(args: { version: string }): Promise<void> {
  const functions = getFunctions();
  const genResponses = httpsCallable(functions, "sendVerificationLink");
  const payload = { ...args };
  await genResponses(payload);
}

/**
 * Create a new siteKey and the associated documents.
 *
 * (Atomic write -> `siteKey` document. `location` document(s). `siteKeyCompany`
 * document(s). `siteKeyUser` document, `userLocations` collection, and siteKeyUser's
 * `privateDoc` (user permissions).)
 *
 * @returns Status code and message. Message property contains siteKeyID if adding
 * the site was successful, otherwise contains null.
 */
async function createSiteKey(data: {
  siteKeyDoc: SiteKey;
  locationDocList: SiteKeyLocation[];
  companyDocList: SiteKeyCompany[];
}): Promise<{ message: string | null; status: number }> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);
  const endpoint = "create-site";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;
  try {
    const response = await axios.post(fullApiRoute, data, {
      headers: { authorization: `Bearer ${idToken}` },
    });
    return { message: response.data.message, status: response.data.status };
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/**
 * Get a NewSiteConfig document, for creating a new siteKey.
 * @param docID Name of the document you want to retrieve from the `newSiteConfig` collection.
 *
 * This ƒn will work for anyone who is logged in.
 */
async function getSiteKeyConfig(docID: string): Promise<NewSiteConfigState> {
  const db = getFirestore();
  const docRef = doc(db, "newSiteConfig", docID);
  const docSnapshot = await getDoc(docRef);
  return NewSiteConfigManager.createFromFirestore(docSnapshot);
}

/**
 * Get a list of NewSiteConfig document, for creating a new siteKey.
 *
 * This ƒn will work for anyone who is logged in.
 */
async function getAllSiteKeyConfigTemplates(): Promise<NewSiteConfigState[]> {
  const db = getFirestore();
  //await the asynchronous functin, returns a QuerySnapshot object from database
  const querySnapshot = await getDocs(collection(db, "newSiteConfig"));
  //for each document snapshot in the query snapshot, apply the function
  return querySnapshot.docs.map((snapshot) =>
    NewSiteConfigManager.createFromFirestore(snapshot),
  );
}

/**
 * HTTPS Function for requesting approval to join a Site Key.
 * @param data - client submitted data.
 */
async function applyToSiteKey(formValues: ApplyToExistingSiteFormState) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "applyToSiteKey");
  const response = await callable(formValues);
  const result = response.data;
  return result;
}

/** Create a compliance requirement. */
async function createComplianceRequirement(
  requirementDoc: ComplianceRequirement_CreateAPI,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "compliance/requirements";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(fullApiRoute, requirementDoc, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/** Delete a compliance requirement. (Set deleted to true) */
async function deleteComplianceRequirement(
  siteKey: string,
  requirementID: ExistingComplianceRequirement["id"],
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = `compliance/requirements/${siteKey}/${requirementID}`;
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.delete(fullApiRoute, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/** Get all compliance requirements (whose deleted field is set to false) */
async function getAllComplianceRequirements(
  siteKey: string,
  userPermissions: SiteKeyUserPermissions,
): Promise<ExistingComplianceRequirement[]> {
  const { complianceRequirements_read } = userPermissions.permissions;
  if (complianceRequirements_read === true) {
    const db = getFirestore();
    const q = query(
      collection(db, "siteKeys", siteKey, "complianceRequirements"),
      where("deleted", "==", false),
    );
    const snapshot = await getDocs(q);

    return snapshot.docs.map((snap) =>
      ComplianceRequirementManager.createFromSnapshot(snap),
    );
  } else {
    // If this block executes, the user isn't gonna see the compliance page at all.
    // They're given a message saying they don't have permission.
    return [];
  }
}

/** Create a compliance response. */
async function createComplianceResponse(
  responseDoc: ComplianceResponse_CreateAPI,
  docID: string,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "compliance/responses";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  const requestBody = { docID, ...responseDoc };

  try {
    await axios.post(fullApiRoute, requestBody, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/** Delete a compliance response. (Set deleted to true) */
async function deleteComplianceResponse(
  siteKey: string,
  responseID: ExistingComplianceResponse["id"],
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = `compliance/responses/${siteKey}/${responseID}`;
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.delete(fullApiRoute, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/** Get all compliance responses (whose deleted field is set to false) */
async function getAllComplianceResponses(
  siteKey: string,
  userPermissions: SiteKeyUserPermissions,
): Promise<ExistingComplianceResponse[]> {
  const { complianceResponses_readAll, complianceResponses_read } =
    userPermissions.permissions;
  if (complianceResponses_readAll === true) {
    return getAllComplianceResponses_readAll(siteKey);
  } else if (complianceResponses_read === true) {
    return getAllComplianceResponses_read(siteKey, userPermissions);
  } else {
    // If this block executes, the user isn't gonna see the compliance page at all.
    // They're given a message saying they don't have permission.
    return [];
  }
}

/** User whose permissions doc says they can readAll responses. */
async function getAllComplianceResponses_readAll(
  siteKey: string,
): Promise<ExistingComplianceResponse[]> {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", siteKey, "complianceResponses"),
    where("deleted", "==", false),
  );
  const snapshot = await getDocs(q);

  return snapshot.docs.map((snap) =>
    ComplianceResponseManager.createFromSnapshot(snap),
  );
}

/** User whose permissions doc says they can read responses for a specific company. */
async function getAllComplianceResponses_read(
  siteKey: string,
  permissions: SiteKeyUserPermissions,
): Promise<ExistingComplianceResponse[]> {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", siteKey, "complianceResponses"),
    where("deleted", "==", false),
    where("siteKeyCompanyID", "==", permissions.companyID),
  );
  const snapshot = await getDocs(q);

  return snapshot.docs.map((snap) =>
    ComplianceResponseManager.createFromSnapshot(snap),
  );
}

/** Review a compliance response. */
async function reviewComplianceResponse(
  reviewResponse: ComplianceResponse_ReviewAPI,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "compliance/responses";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.put(fullApiRoute, reviewResponse, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/**
 * Create an attachment document. The backend checks that the user can create
 * compliance responses.
 */
async function createComplianceAttachment(
  siteKey: string,
  attachmentDoc: Attachment_CreateAPI,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = `attachments/${siteKey}`;
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(fullApiRoute, attachmentDoc, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/**
 * Delete an attachment. (Set deleted to true)
 * The backend checks that the user can delete compliance responses.
 */
async function deleteComplianceAttachment(
  siteKey: string,
  attachmentID: ExistingAttachment["id"],
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = `attachments/${siteKey}/${attachmentID}`;
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.delete(fullApiRoute, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/** Get all compliance attachments (that the user is allowed to read). */
async function getComplianceAttachments(
  siteKey: string,
  userPermissions: SiteKeyUserPermissions,
  complianceResponseIDs: string[],
): Promise<ExistingAttachment[]> {
  const { complianceResponses_readAll } = userPermissions.permissions;
  if (complianceResponses_readAll === true) {
    return getComplianceAttachments_readAll(siteKey, complianceResponseIDs);
  } else {
    return getComplianceAttachments_read(
      siteKey,
      userPermissions,
      complianceResponseIDs,
    );
  }
}

/* User whose permissions doc says they can readAll responses, so also all attachments */
async function getComplianceAttachments_readAll(
  siteKey: string,
  complianceResponseIDs: string[],
): Promise<ExistingAttachment[]> {
  const db = getFirestore();
  const snapPromises = complianceResponseIDs.map((id) => {
    const q = query(
      collection(db, "siteKeys", siteKey, "attachments"),
      where("deleted", "==", false),
      where("complianceResponseID", "==", id),
    );
    return getDocs(q);
  });
  const querySnapshotList = await Promise.all(snapPromises);

  const snapshotList = querySnapshotList.flatMap((snapshot) => {
    // Drop the snapshot if there aren't any docs in it. Notice we're using flatMap here.
    if (snapshot.empty) return [];
    return snapshot.docs;
  });

  // Convert to the usual data structure.
  return snapshotList.map((snap) => AttachmentManager.createFromSnapshot(snap));
}

/* User whose permissions doc says they can read responses, so their attachments, for a specific company */
async function getComplianceAttachments_read(
  siteKey: string,
  userPermissions: SiteKeyUserPermissions,
  complianceResponseIDs: string[],
): Promise<ExistingAttachment[]> {
  const db = getFirestore();
  const snapPromises = complianceResponseIDs.map((id) => {
    const q = query(
      collection(db, "siteKeys", siteKey, "attachments"),
      where("deleted", "==", false),
      where("complianceResponseID", "==", id),
      where("authorizedCompanies", "array-contains", userPermissions.companyID),
    );
    return getDocs(q);
  });
  const querySnapshotList = await Promise.all(snapPromises);

  const snapshotList = querySnapshotList.flatMap((snapshot) => {
    // Drop the snapshot if there aren't any docs in it. Notice we're using flatMap here.
    if (snapshot.empty) return [];
    return snapshot.docs;
  });

  // Convert to the usual data structure.
  return snapshotList.map((snap) => AttachmentManager.createFromSnapshot(snap));
}

interface ISubscribeWorkRecordAttachments {
  siteKey: string;
  permissions: SiteKeyUserPermissions;
  workRecordID: string;
  onChange: (attachmentList: ExistingAttachment[]) => void;
  onError?: (error: FirestoreError) => void;
}

function subscribeWorkRecordAttachments(
  args: ISubscribeWorkRecordAttachments,
): () => void {
  const { isSiteAdmin, isPlantPersonnel } = args.permissions.permissions;
  if (isSiteAdmin || isPlantPersonnel) {
    return subscribeWorkRecordAttachments_readAll(args);
  } else {
    return subscribeWorkRecordAttachments_read(args);
  }
}

function subscribeWorkRecordAttachments_readAll(
  args: ISubscribeWorkRecordAttachments,
): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "attachments"),
    where("deleted", "==", false),
    where("craftRecordID", "==", args.workRecordID),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const attachmentList: ExistingAttachment[] = querySnapshot.docs.map(
        (snapshot) => AttachmentManager.createFromSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(attachmentList);
    },
    args.onError,
  );

  return removeListeners;
}

function subscribeAttachmentsForCustomer(args: {
  siteKey: string;
  customerID: string;
  onChange: (attachmentList: ExistingAttachment[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "attachments"),
    where("deleted", "==", false),
    where("customerID", "==", args.customerID),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const attachmentList: ExistingAttachment[] = querySnapshot.docs.map(
        (snapshot) => AttachmentManager.createFromSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(attachmentList);
    },
    args.onError,
  );

  return removeListeners;
}

function subscribeWorkRecordAttachments_read(
  args: ISubscribeWorkRecordAttachments,
): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "attachments"),
    where("deleted", "==", false),
    where("craftRecordID", "==", args.workRecordID),
    where("authorizedCompanies", "array-contains", args.permissions.companyID),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const attachmentList: ExistingAttachment[] = querySnapshot.docs.map(
        (snapshot) => AttachmentManager.createFromSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(attachmentList);
    },
    args.onError,
  );

  return removeListeners;
}

/** Get all attachments that belong to the given work record. */
async function getWorkRecordAttachments(args: {
  siteKey: string;
  userPermissions: SiteKeyUserPermissions;
  workRecordID: string;
}): Promise<ExistingAttachment[]> {
  const { isSiteAdmin, isPlantPersonnel } = args.userPermissions.permissions;
  if (isSiteAdmin || isPlantPersonnel) {
    return getWorkRecordAttachments_readAll({
      siteKey: args.siteKey,
      workRecordID: args.workRecordID,
    });
  } else {
    return getWorkRecordAttachments_read({
      siteKey: args.siteKey,
      userPermissions: args.userPermissions,
      workRecordID: args.workRecordID,
    });
  }
}

/** User is site admin or plant personnel. They can view all attachments. */
async function getWorkRecordAttachments_readAll(args: {
  siteKey: string;
  workRecordID: string;
}): Promise<ExistingAttachment[]> {
  const db = getFirestore();

  const q = query(
    collection(db, "siteKeys", args.siteKey, "attachments"),
    where("deleted", "==", false),
    where("craftRecordID", "==", args.workRecordID),
  );
  const querySnap = await getDocs(q);

  return querySnap.docs.map((snap) =>
    AttachmentManager.createFromSnapshot(snap),
  );
}

/** User is a contractor. They can view attachments that their company "owns". */
async function getWorkRecordAttachments_read(args: {
  siteKey: string;
  userPermissions: SiteKeyUserPermissions;
  workRecordID: string;
}): Promise<ExistingAttachment[]> {
  const db = getFirestore();

  const q = query(
    collection(db, "siteKeys", args.siteKey, "attachments"),
    where("deleted", "==", false),
    where("craftRecordID", "==", args.workRecordID),
    where(
      "authorizedCompanies",
      "array-contains",
      args.userPermissions.companyID,
    ),
  );
  const querySnap = await getDocs(q);

  return querySnap.docs.map((snap) =>
    AttachmentManager.createFromSnapshot(snap),
  );
}

async function createWorkRecordAttachment(args: {
  siteKey: string;
  attachmentDoc: DocumentData;
}) {
  const db = getFirestore();
  const collPath = collection(db, "siteKeys", args.siteKey, "attachments");
  return addDoc(collPath, args.attachmentDoc);
}

async function deleteWorkRecordAttachment(args: {
  siteKey: string;
  attachmentID: string;
}) {
  const db = getFirestore();
  const docPath = doc(
    db,
    "siteKeys",
    args.siteKey,
    "attachments",
    args.attachmentID,
  );
  return updateDoc(docPath, { deleted: true });
}

/**
 * Create site template for usage with the "create a site" module. Works for those
 * whose email is verified and whitelisted.
 */
async function createSiteTemplate(newSiteConfig: DocumentData): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "create-site-template";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(fullApiRoute, newSiteConfig, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/**
 * Returns all the customers in the customers collection for the given siteKey.
 */
function subscribeAllOpenCalls(args: {
  siteKey: string;
  onChange: (customers: ExistingStiltPhoneCall[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const collectionReference = collection(db, "siteKeys", args.siteKey, "calls");
  const q = query(
    collectionReference,
    where("callStatus", "in", ["queued", "ringing", "in-progress", "busy"]),
    orderBy("timestampCreated", "desc"),
    limit(20),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      const callsList = querySnap.docs.map((snapshot) =>
        StiltPhoneCallManager.createFromFirestoreSnapshot(snapshot),
      );
      args.onChange(callsList);
    },
    args.onError,
  );

  return removeListeners;
}

/**
 * Subscribes to open calls in the last X minutes. Default 5.
 */
function subscribeRecentCalls(args: {
  siteKey: string;
  onChange: (customers: ExistingStiltPhoneCall[]) => void;
  onError?: (error: FirestoreError) => void;
  /**
   * Default is 5 minutes.
   */
  minutes?: number;
  openCalls?: boolean;
  direction?: "inbound" | "outbound";
}): () => void {
  // Default to 5 minutes
  const minutes = args.minutes || 5;
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const collectionReference = collection(db, "siteKeys", args.siteKey, "calls");
  const timestampXMinutesAgo = Timestamp.fromDate(
    DateTime.now().minus({ minutes }).toJSDate(),
  );

  const queryConstraints = [
    where("timestampCreated", ">=", timestampXMinutesAgo),
    orderBy("timestampCreated", "desc"),
  ];

  if (args.openCalls) {
    queryConstraints.push(
      where("callStatus", "in", ["queued", "ringing", "in-progress", "busy"]),
    );
  }

  if (args.direction === "inbound") {
    queryConstraints.push(where("direction", "==", "inbound"));
  } else if (args.direction === "outbound") {
    queryConstraints.push(
      where("direction", "in", ["outbound", "outbound-api"]),
    );
  }

  const q = query(collectionReference, ...queryConstraints);

  const removeListeners = onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      const callsList = querySnap.docs.map((snapshot) =>
        StiltPhoneCallManager.createFromFirestoreSnapshot(snapshot),
      );
      args.onChange(callsList);
    },
    args.onError,
  );

  return removeListeners;
}

function subscribeAllCallsForASingleCustomer(args: {
  siteKey: string;
  customerID: string;
  onChange: (customerCalls: ExistingStiltPhoneCall[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const collectionReference = collection(db, "siteKeys", args.siteKey, "calls");
  const q = query(
    collectionReference,
    where("customerID", "==", args.customerID),
    orderBy("timestampCreated", "desc"),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      const callsList = querySnap.docs.map((snapshot) =>
        StiltPhoneCallManager.createFromFirestoreSnapshot(snapshot),
      );
      args.onChange(callsList);
    },
    args.onError,
  );

  return removeListeners;
}

/**
 * Returns all the customers in the customers collection for the given siteKey.
 */
function subscribeAllCustomers(args: {
  siteKey: string;
  onChange: (customers: ExistingCustomer[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    args.siteKey,
    "customers",
  );
  const q = query(
    collectionReference,
    where("deleted", "==", false),
    orderBy("timestampLastModified", "desc"),
    limit(500),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      const customersList = querySnap.docs.map((snapshot) =>
        CustomerManager.createFromFirestoreSnapshot(snapshot),
      );
      args.onChange(customersList);
    },
    args.onError,
  );

  return removeListeners;
}

function subscribeAllCustomersByAccountingSync(args: {
  siteKey: string;
  onChange: (customers: ExistingCustomerAccountingSyncTableData[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    args.siteKey,
    "customers",
  );

  const q = query(
    collectionReference,
    or(
      where("accountingSync.syncStatus", "!=", "SYNCED"),
      where("accountingSync.isAwaitingSync", "==", true),
    ),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      const customersList: ExistingCustomerAccountingSyncTableData[] =
        querySnap.docs.map((snapshot) => {
          const customer =
            CustomerManager.createFromFirestoreSnapshot(snapshot);
          return { ...customer, docType: DocType.CUSTOMERS };
        });
      args.onChange(customersList);
    },
    args.onError,
  );

  return removeListeners;
}

/**
 * Returns all the customers in the customers collection for the given siteKey.
 */
function subscribeAllCustomerLocations(args: {
  siteKey: string;
  onChange: (customers: ExistingCustomerLocation[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    args.siteKey,
    "customerLocations",
  );
  const q = query(
    collectionReference,
    where("deleted", "==", false),
    orderBy("timestampLastModified", "desc"),
    limit(1000),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      const customerLocationsList = querySnap.docs.map((snapshot) =>
        CustomerLocationManager.createFromFirestoreSnapshot(snapshot),
      );
      args.onChange(customerLocationsList);
    },
    args.onError,
  );

  return removeListeners;
}

/**
 * Returns the customer with the given customerId from the customers collection for the given siteKey.
 */
async function getCustomer(
  siteKey: string,
  customerId: string,
): Promise<ExistingCustomer> {
  // Create the database object
  const db = getFirestore();
  const docReference = doc(db, "siteKeys", siteKey, "customers", customerId);
  // Await the asynchronous function, returns a DocumentSnapshot object from database.
  const snapshot = await getDoc(docReference);

  return CustomerManager.createFromFirestoreSnapshot(snapshot);
}

// /**
//  * Returns all the customer locations in the customerLocations collection for the given siteKey.
//  */
// async function getAllCustomerLocations(
//   siteKey: string
// ): Promise<ExistingCustomerLocation[]> {
//   // Create the database object (based on project initialized in init-firesbase.ts)
//   const db = getFirestore();
//   const collectionReference = collection(
//     db,
//     "siteKeys",
//     siteKey,
//     "customerLocations"
//   );
//   const q = query(collectionReference, where("deleted", "==", false));
//   // Await the asynchronous function, returns a QuerySnapshot object from database.
//   const querySnapshot = await getDocs(q);
//   // For each document snapshot in the query snapshot, apply the function.
//   return querySnapshot.docs.map((snapshot) =>
//     CustomerLocationManager.createFromFirestoreSnapshot(snapshot)
//   );
// }

/**
 * Returns the customer location(s) for the given customerId, in the customerLocations collection for the given siteKey.
 */
async function getCustomerLocationsByCustomerId(
  siteKey: string,
  customerId: string,
): Promise<ExistingCustomerLocation[]> {
  // Create the database object
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    siteKey,
    "customerLocations",
  );
  const q = query(
    collectionReference,
    where("customerID", "==", customerId),
    where("deleted", "==", false),
  );
  // Await the asynchronous function, returns a QuerySnapshot object from database.
  const querySnapshot = await getDocs(q);
  // For each document snapshot in the query snapshot, apply the function.
  const locationsList = querySnapshot.docs.map((snapshot) =>
    CustomerLocationManager.createFromFirestoreSnapshot(snapshot),
  );
  return locationsList;
}

/**
 * Subscribe to realtime updates for customer location documents that match the
 * given customerID.
 * @returns removeListeners ƒn for cleanup
 */
function subscribeCustomerLocationsByCustomerId(args: {
  siteKey: string;
  customerID: string;
  onChange: (locations: ExistingCustomerLocation[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const collRef = collection(db, "siteKeys", args.siteKey, "customerLocations");
  const q = query(
    collRef,
    where("customerID", "==", args.customerID),
    where("deleted", "==", false),
  );

  return onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      const locationsList = querySnap.docs.map((snapshot) =>
        CustomerLocationManager.createFromFirestoreSnapshot(snapshot),
      );
      args.onChange(locationsList);
    },
    args.onError,
  );
}

/**
 * Subscribe to realtime updates for customer location documents that match the
 * given customerID.
 * @returns removeListeners ƒn for cleanup
 */
function subscribeCustomerContactsByCustomerId(args: {
  siteKey: string;
  customerID: string;
  onChange: (locations: ExistingCustomerContact[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const collRef = collection(db, "siteKeys", args.siteKey, "customerContacts");
  const q = query(
    collRef,
    where("customerID", "==", args.customerID),
    where("deleted", "==", false),
  );

  return onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      const contactsList = querySnap.docs.map((snapshot) =>
        CustomerContactManager.createFromFirestoreSnapshot(snapshot),
      );
      args.onChange(contactsList);
    },
    args.onError,
  );
}

/**
 * Subscribe to realtime updates for customer document with the
 * given customerID.
 * @returns removeListeners ƒn for cleanup
 */
function subscribeSingleCustomer(args: {
  siteKey: string;
  customerID: string;
  onChange: (customer: ExistingCustomer) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const docRef = doc(
    db,
    "siteKeys",
    args.siteKey,
    "customers",
    args.customerID,
  );

  const unsubscribe = onSnapshot(
    docRef,
    (snapshot) => {
      // Convert Firestore snapshot to our local data structure.
      const customerDoc: ExistingCustomer =
        CustomerManager.createFromFirestoreSnapshot(snapshot);
      args.onChange(customerDoc);
    },
    args.onError,
  );

  return unsubscribe;
}

/**
 * Subscribe to realtime updates for customerLocation document with the
 * given customerID.
 * @returns removeListeners ƒn for cleanup
 */
function subscribeSingleCustomerLocation(args: {
  siteKey: string;
  customerLocationID: string;
  onChange: (locations: ExistingCustomerLocation) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const docRef = doc(
    db,
    "siteKeys",
    args.siteKey,
    "customerLocations",
    args.customerLocationID,
  );

  const unsubscribe = onSnapshot(
    docRef,
    (snapshot) => {
      // Convert Firestore snapshot to our local data structure.
      const customerLocationDoc: ExistingCustomerLocation =
        CustomerLocationManager.createFromFirestoreSnapshot(snapshot);
      args.onChange(customerLocationDoc);
    },
    args.onError,
  );

  return unsubscribe;
}

/**
 * Subscribe to realtime updates for customerLocation document with the
 * given customerID.
 * @returns removeListeners ƒn for cleanup
 */
function subscribeSingleInvoice(args: {
  siteKey: string;
  invoiceID: string;
  onChange: (locations: ExistingStiltInvoice) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const docRef = doc(db, "siteKeys", args.siteKey, "invoices", args.invoiceID);

  const unsubscribe = onSnapshot(
    docRef,
    (snapshot) => {
      // Convert Firestore snapshot to our local data structure.
      const invoiceDoc: ExistingStiltInvoice =
        StiltInvoiceManager.createFromFirestoreSnapshot(snapshot);
      args.onChange(invoiceDoc);
    },
    args.onError,
  );

  return unsubscribe;
}

/**
 * Subscribe to realtime updates for membership document with the
 * given doc ID.
 * @returns removeListeners ƒn for cleanup
 */
function subscribeMembership(args: {
  siteKey: string;
  membershipDocID: string;
  onChange: (locations: ExistingMembership) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const docRef = doc(
    db,
    "siteKeys",
    args.siteKey,
    "memberships",
    args.membershipDocID,
  );

  const unsubscribe = onSnapshot(
    docRef,
    (snapshot) => {
      // Convert Firestore snapshot to our local data structure.
      const doc: ExistingMembership =
        MembershipManager.createFromFirestoreSnapshot(snapshot);
      args.onChange(doc);
    },
    args.onError,
  );

  return unsubscribe;
}

/**
 * Subscribe to realtime updates for membershipTemplate document with the
 * given doc ID.
 * @returns removeListeners ƒn for cleanup
 */
function subscribeMembershipTemplate(args: {
  siteKey: string;
  templateID: string;
  onChange: (locations: ExistingMembershipTemplate) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const docRef = doc(
    db,
    "siteKeys",
    args.siteKey,
    "membershipTemplates",
    args.templateID,
  );

  const unsubscribe = onSnapshot(
    docRef,
    (snapshot) => {
      // Convert Firestore snapshot to our local data structure.
      const doc: ExistingMembershipTemplate =
        MembershipTemplateManager.createFromFirestoreSnapshot(snapshot);
      args.onChange(doc);
    },
    args.onError,
  );

  return unsubscribe;
}

/** Add new customer. */
async function createCustomerViaAPI(
  customerDoc: Customer_CreateAPI,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "customer";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(fullApiRoute, customerDoc, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/** Add new customer. */
async function createCustomer(customerDoc: Customer_CreateAPI): Promise<void> {
  const db = getFirestore();
  const { uuid, siteKey, ...rest } = customerDoc;
  const docRef = doc(db, "siteKeys", siteKey, "customers", uuid);
  const updateData: Customer = {
    ...rest,
    customData: {},
    timestampLastModified: Timestamp.now(),
    timestampCreated: Timestamp.now(),
  };

  await setDoc(docRef, updateData);
  return;
}

/** Update a customer doc */
async function updateCustomerViaAPI(
  partialCustomerDoc: Customer_UpdateAPI,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "customer";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.patch(fullApiRoute, partialCustomerDoc, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/** Sets the Stilt customer's customData.accountingRefID to the Quickbooks customer's ID */
async function confirmSameQBOCustomer(data: {
  uid: string;
  siteKeyID: string;
  customerID: string;
  qboCustomerID: string;
}): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "accounting-sync/confirmCustomerMatch";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(fullApiRoute, data, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/** Sets the customer back to active in QBO */
async function restoreDeletedCustomerInQB(data: {
  uid: string;
  siteKeyID: string;
  customerID: string;
}): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "accounting-sync/restoreDeletedCustomer";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(fullApiRoute, data, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/** Update a customer doc via direct write. If present, converts customData, customerLocations,
 * customerContacts, and accountingSync to dot-separated field paths */
async function updateCustomer(
  customerDoc: ExistingCustomerUpdate,
): Promise<void> {
  const db = getFirestore();
  const siteKey = customerDoc.refPath.split("/")[1];
  const customerID = customerDoc.refPath.split("/")[3];
  const docRef = doc(db, "siteKeys", siteKey, "customers", customerID);
  const updateData = CustomerManager.convertUpdateForFirestore(customerDoc);

  let customDataUpdate: Record<string, any> = {};
  if (updateData.customData) {
    customDataUpdate = convertNestedObjToDotSeparatedFields(
      updateData.customData,
      "customData",
    );
  }
  let customerLocationsUpdate: Record<string, any> = {};
  if (updateData.customerLocations) {
    customerLocationsUpdate = convertNestedObjToDotSeparatedFields(
      updateData.customerLocations,
      "customerLocations",
    );
  }
  let customerContactsUpdate: Record<string, any> = {};
  if (updateData.customerContacts) {
    customerContactsUpdate = convertNestedObjToDotSeparatedFields(
      updateData.customerContacts,
      "customerContacts",
    );
  }
  let accountingSyncUpdate: Record<string, any> = {};
  if (updateData.accountingSync) {
    accountingSyncUpdate = convertNestedObjToDotSeparatedFields(
      updateData.accountingSync,
      "accountingSync",
    );
  }
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    customData,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    customerLocations,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    customerContacts,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    accountingSync,
    ...finalData
  } = updateData;

  await updateDoc(docRef, {
    ...finalData,
    ...customDataUpdate,
    ...customerLocationsUpdate,
    ...customerContactsUpdate,
    ...accountingSyncUpdate,
  });
  return;
}

/** Delete a customer doc */
async function deleteCustomer(
  siteKey: string,
  customerID: ExistingCustomer["id"],
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = `customer/${siteKey}/${customerID}`;
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.delete(fullApiRoute, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/**
 * Add new customer location docs.
 * @returns string[], containing document IDs
 */
async function createCustomerLocationsViaAPI(
  customerLocationDocs: CustomerLocation_CreateAPI[],
): Promise<any> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "customer-location";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    const response = await axios.post(fullApiRoute, customerLocationDocs, {
      headers: { authorization: `Bearer ${idToken}` },
    });
    return response.data.result;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/**
 * Add new customer location docs.
 * @returns string[], containing document IDs
 */
async function createCustomerLocations(
  customerLocationDocs: CustomerLocation_CreateAPI[],
): Promise<string[]> {
  const db = getFirestore();
  const docRefIDs: string[] = [];
  for (const customerLocation of customerLocationDocs) {
    const { uuid, siteKey, ...rest } = customerLocation;
    const docRef = doc(db, "siteKeys", siteKey, "customerLocations", uuid);
    const updateData: CustomerLocation = {
      ...rest,
      customData: {},
      timestampLastModified: Timestamp.now(),
      timestampCreated: Timestamp.now(),
    };

    await setDoc(docRef, updateData);
    docRefIDs.push(docRef.id);
  }

  return docRefIDs;
}

/** Update a customer location doc */
async function updateCustomerLocationViaAPI(
  partialCustomerLocationDoc: CustomerLocation_UpdateAPI,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "customer-location";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.patch(fullApiRoute, partialCustomerLocationDoc, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/** Update a customer location doc via direct write. If present, converts
 * customData and accountingSync to dot-separated field paths */
async function updateCustomerLocation(
  partialCustomerLocationDoc: CustomerLocation_UpdateAPI,
): Promise<void> {
  if (!partialCustomerLocationDoc.refPath) {
    throw Error("Must provide refPath to update a customer location");
  }
  const db = getFirestore();
  const siteKey = partialCustomerLocationDoc.refPath.split("/")[1];
  const clID = partialCustomerLocationDoc.refPath.split("/")[3];
  const docRef = doc(db, "siteKeys", siteKey, "customerLocations", clID);
  const updateData = CustomerLocationManager.convertForFirestore(
    partialCustomerLocationDoc,
  );
  let customDataUpdate: Record<string, any> = {};
  if (updateData.customData) {
    customDataUpdate = convertNestedObjToDotSeparatedFields(
      updateData.customData,
      "customData",
    );
  }
  let accountingSyncUpdate: Record<string, any> = {};
  if (updateData.accountingSync) {
    accountingSyncUpdate = convertNestedObjToDotSeparatedFields(
      updateData.accountingSync,
      "accountingSync",
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { customData, accountingSync, ...finalData } = updateData;

  await updateDoc(docRef, {
    ...finalData,
    ...customDataUpdate,
    ...accountingSyncUpdate,
  });
  return;
}

/** Delete a customer location doc  (Set deleted to true) */
async function deleteCustomerLocation(
  siteKey: string,
  customerLocationID: ExistingCustomerLocation["id"],
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = `customer-location/${siteKey}/${customerLocationID}`;
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.delete(fullApiRoute, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/**
 * Add new customer location docs.
 * @returns string[], containing document IDs
 */
async function createCustomerContact(
  customerContact: CustomerContact_CreateAPI,
): Promise<void> {
  const db = getFirestore();
  const { siteKey, ...rest } = customerContact;
  const updateData: CustomerContact = {
    ...rest,
    customData: {},
    timestampLastModified: Timestamp.now(),
    timestampCreated: Timestamp.now(),
  };
  await addDoc(
    collection(db, "siteKeys", siteKey, "customerContacts"),
    updateData,
  );
}

/**
 * Add new customer location docs.
 * @returns string[], containing document IDs
 */
async function updateCustomerContact(
  siteKey: string,
  customerContactID: ExistingCustomerContact["id"],
  customerContact: CustomerContact_UpdateAPI,
): Promise<void> {
  const db = getFirestore();
  const updateData: Partial<ExistingCustomerContact> = {
    ...customerContact,
    timestampLastModified: Timestamp.now(),
  };

  await updateDoc(
    doc(db, "siteKeys", siteKey, "customerContacts", customerContactID),
    updateData,
  );
}

/** Delete a customer contact doc  (Set deleted to true) */
async function deleteCustomerContact(
  siteKey: string,
  uid: string,
  customerContactID: ExistingCustomerContact["id"],
): Promise<void> {
  const db = getFirestore();
  const docPath = doc(
    db,
    "siteKeys",
    siteKey,
    "customerContacts",
    customerContactID,
  );
  await updateDoc(docPath, {
    deleted: true,
    timestampLastModified: Timestamp.now(),
    lastModifiedBy: uid,
  });
}

/**
 * Returns the payment form data with the given paymentId from the payments collection for the given siteKey.
 */
async function getPayment(
  paymentID: string,
  tipAmount?: number,
  paymentAmount?: number,
): Promise<StiltPaymentFormData> {
  const functions = getFunctions();
  const genPaymentData = httpsCallable(functions, "getPaymentFormData");
  const data: { [key: string]: any } = {
    paymentID,
  };
  if (tipAmount) {
    data.tipAmount = tipAmount;
  }
  if (paymentAmount) {
    data.paymentAmount = paymentAmount;
  }
  const paymentData = await genPaymentData(data);

  const result = StiltPaymentManager.validateFormData(paymentData["data"]);

  return result;
}

/**
 * Returns the payment form data with the given paymentId from the payments collection for the given siteKey.
 */
async function getSelfCheckoutConfig(
  siteKey: string,
): Promise<SelfCheckoutFormConfig> {
  const endpoint = "self-checkout/config";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  const fullApiRouteWithQueryParams = `${fullApiRoute}?siteKey=${siteKey}`;

  try {
    const response = await axios.get(fullApiRouteWithQueryParams);
    const result = response.data as SelfCheckoutFormConfig;

    return result;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/**
 * Returns quickbooks customers that have the same name as the given customer doc
 */
async function getQuickbooksCustomersByName(gazz: {
  siteKeyID: string;
  customerIDs: string[];
}): Promise<Record<string, QBOCustomer[]>> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "accounting-sync/customersByName";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  // Add siteKey and refPathList to the query string
  const fullApiRouteWithQueryParams = `${fullApiRoute}?siteKeyID=${gazz.siteKeyID}&customerIDs=${gazz.customerIDs.join(",")}`;

  try {
    const result = await axios.get(fullApiRouteWithQueryParams, {
      headers: { authorization: `Bearer ${idToken}` },
    });
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/**
 * Returns the payment form data with the given paymentId from the payments collection for the given siteKey.
 */
async function getMultiPayCreditCardData(paymentID: string): Promise<string> {
  const functions = getFunctions();
  const genPaymentData = httpsCallable(functions, "getMultiPaymentFormData");
  const paymentData = await genPaymentData(paymentID);

  console.log(paymentData);
  const data = paymentData["data"];

  if (!isPlainObject(data)) {
    throw new Error("Payment data is empty");
  }

  // @ts-ignore
  const paymentLink = data["paymentURL"];

  // type check paymentLink as string
  if (typeof paymentLink !== "string") {
    throw new Error("Payment link is not a string");
  }

  return paymentLink;
}

/**
 * Returns the payment form data with the given paymentId from the payments collection for the given siteKey.
 */
async function proceedWithSelfCheckout(args: {
  siteKey: string;
  data: SelfCheckoutPayment;
}): Promise<string> {
  const endpoint = "self-checkout";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  const { siteKey, data } = args;

  // Build the query string from the args
  const queryString = `siteKey=${siteKey}&totalAmount=${data.totalAmount.toString()}&referenceNumber=${data.referenceNumber}&customerName=${data.customerName}&returnURL=${data.returnURL}&street=${data.street}&city=${data.city}&state=${data.state}&zipCode=${data.zipCode}&countryCode=${data.countryCode}&phone=${data.phone}&email=${data.email}`;
  const fullApiRouteWithQueryParams = `${fullApiRoute}?${queryString}`;

  try {
    const response = await axios.get(fullApiRouteWithQueryParams);
    return response.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/**
 * Returns all the payments in the payments collection for the given siteKey.
 */
async function getAllPayments(
  siteKey: string,
): Promise<ExistingStiltPayment[]> {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", siteKey, "payments"),
    where("deleted", "==", false),
  );
  // Await the asynchronous function, returns a QuerySnapshot object from database.
  const querySnapshot = await getDocs(q);
  // For each document snapshot in the query snapshot, apply the function.
  return querySnapshot.docs.map((snapshot) =>
    StiltPaymentManager.createFromFirestoreSnapshot(snapshot),
  );
}

/**
 * Returns all the price book items in the priceBookItems collection for the given siteKey.
 */
async function getAllPriceBookItems(
  siteKey: string,
): Promise<ExistingPriceBookItem[]> {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    siteKey,
    "priceBookItems",
  );
  const q = query(collectionReference, where("deleted", "==", false));
  // Await the asynchronous function, returns a QuerySnapshot object from database.
  const querySnapshot = await getDocs(q);
  // For each document snapshot in the query snapshot, apply the function.
  return querySnapshot.docs.map((snapshot) =>
    PriceBookItemManager.createFromFirestoreSnapshot(snapshot),
  );
}

/**
 * Returns all the price book items in the priceBookItems collection for the given siteKey.
 */
async function getAllPriceBookItemsWithCommissionsOverride(
  siteKey: string,
): Promise<ExistingPriceBookItem[]> {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    siteKey,
    "priceBookItems",
  );
  const q = query(collectionReference, orderBy("commissions", "asc"));
  // Await the asynchronous function, returns a QuerySnapshot object from database.
  const querySnapshot = await getDocs(q);
  // For each document snapshot in the query snapshot, apply the function.
  return querySnapshot.docs.map((snapshot) =>
    PriceBookItemManager.createFromFirestoreSnapshot(snapshot),
  );
}

/**
 * Returns all the customers in the customers collection for the given siteKey.
 */
function subscribeAllPriceBookItems(args: {
  siteKey: string;
  onChange: (pricebookItems: ExistingPriceBookItem[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    args.siteKey,
    "priceBookItems",
  );
  const q = query(collectionReference, where("deleted", "==", false));

  const removeListeners = onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      const pbItemsList = querySnap.docs.map((snapshot) =>
        PriceBookItemManager.createFromFirestoreSnapshot(snapshot),
      );
      args.onChange(pbItemsList);
    },
    args.onError,
  );

  return removeListeners;
}

/**
 * Returns all the price book item categories for the given siteKey.
 */
function subscribeCommissionAdjustmentsByEstimateID(args: {
  siteKey: string;
  estimateID: string;
  onChange: (commissionAdjustments: ExistingCommissionAdjustment[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    args.siteKey,
    "commissionAdjustments",
  );
  const q = query(
    collectionReference,
    where("estimateID", "==", args.estimateID),
    where("deleted", "==", false),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      const list = querySnap.docs.map((snapshot) =>
        CommissionAdjustmentManager.createFromFirestoreSnapshot(snapshot),
      );
      args.onChange(list);
    },
    args.onError,
  );

  return removeListeners;
}

/**
 * Returns all the price book item categories for the given siteKey.
 */
function subscribeAllPriceBookItemCategories(args: {
  siteKey: string;
  onChange: (pricebookItems: ExistingPriceBookItemCategory[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    args.siteKey,
    "priceBookItemCategories",
  );
  const q = query(collectionReference);

  const removeListeners = onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      const pbItemCategoriesList = querySnap.docs.map((snapshot) =>
        PriceBookItemCategoryManager.fromSnapshot(snapshot),
      );
      args.onChange(pbItemCategoriesList);
    },
    args.onError,
  );

  return removeListeners;
}

function subscribeAllPricebookItemsByAccountingSync(args: {
  siteKey: string;
  onChange: (pricebookItems: ExistingPBItemAccountingSyncTableData[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    args.siteKey,
    "priceBookItems",
  );
  const q = query(
    collectionReference,
    and(
      where("deleted", "==", false),
      or(
        where("accountingSync.syncStatus", "!=", "SYNCED"),
        where("accountingSync.isAwaitingSync", "==", true),
      ),
    ),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      const pbItemsList: ExistingPBItemAccountingSyncTableData[] =
        querySnap.docs.map((snapshot) => {
          const pricebookItem =
            PriceBookItemManager.createFromFirestoreSnapshot(snapshot);
          return { ...pricebookItem, docType: DocType.PRICEBOOKITEMS };
        });
      args.onChange(pbItemsList);
    },
    args.onError,
  );

  return removeListeners;
}

async function getSinglePriceBookItem(
  siteKey: string,
  priceBookItemID: string,
): Promise<ExistingPriceBookItem | null> {
  try {
    // Create the database object
    const db = getFirestore();
    const docReference = doc(
      db,
      "siteKeys",
      siteKey,
      "priceBookItems",
      priceBookItemID,
    );
    // Await the asynchronous function, returns a DocumentSnapshot object from database.
    const snapshot = await getDoc(docReference);

    return PriceBookItemManager.createFromFirestoreSnapshot(snapshot);
  } catch (e) {
    return null;
  }
}

/* Add new price book item */
async function createPriceBookItem(pbItemDoc: PBItem_CreateAPI): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "price-book-item";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(fullApiRoute, pbItemDoc, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/* Update a price book item doc */
async function updatePriceBookItem(
  partialPBItem: PBItem_UpdateAPI,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "price-book-item";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.put(fullApiRoute, partialPBItem, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/* Delete a price book item doc (set delete to true) */
async function deletePriceBookItem(
  siteKey: string,
  priceBookItemID: ExistingPriceBookItem["id"],
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = `price-book-item/${siteKey}/${priceBookItemID}`;
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.delete(fullApiRoute, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/* Add new price book item category */
async function createPriceBookItemCategory(
  pbItemCategoryDoc: PBItemCategory_CreateAPI,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "price-book-item-category";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(fullApiRoute, pbItemCategoryDoc, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/* Update a price book item category doc */
async function updatePriceBookItemCategory(
  partialPBItemCategory: PBItemCategory_UpdateAPI,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "price-book-item-category";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.put(fullApiRoute, partialPBItemCategory, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/* Add new commission adjustment */
async function createCommissionAdjustment(
  commissionAdjustment: CommissionAdjustment_CreateAPI,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "commission-adjustment";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(fullApiRoute, commissionAdjustment, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/* Add new commission adjustment */
async function recalculateKpiData(
  siteKey: string,
  kpiType: string | null,
  startDate: string,
  endDate: string,
): Promise<void> {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "batchCreateKpiUpdates", {
    timeout: 240000,
  });
  await callable({
    siteKey: siteKey,
    kpiType: kpiType,
    startDate: startDate,
    endDate: endDate,
  });
  return;
}

/* Update a price book item category doc */
async function updateCommissionAdjustment(
  partialCommissionAdjustment: CommissionAdjustment_UpdateAPI,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "commission-adjustment";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.put(fullApiRoute, partialCommissionAdjustment, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/* Add new estimate */
async function createEstimateViaAPI(
  estimateDoc: Estimate_CreateAPI,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "estimate";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(fullApiRoute, estimateDoc, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/* Add new estimate */
async function createEstimate(
  estimateDoc: Estimate_CreateAPI,
  estimatePackageDoc: EstimatePackage_CreateAPI,
  estimateItems: EstimateItem_CreateAPI[],
): Promise<void> {
  // We'll batch the estimatePackage, estimate, and estimateItems into 1
  // Firestore writeBatch to ensure all either pass or fail together
  const db = getFirestore();
  const batch = writeBatch(db);

  function addEstimatePackageToBatch() {
    const { uuid, siteKey, ...rest } = estimatePackageDoc;
    const docRef = doc(db, "siteKeys", siteKey, "estimatePackages", uuid);
    const data: EstimatePackage = {
      ...rest,
      timestampLastModified: Timestamp.now(),
      timestampCreated: Timestamp.now(),
    };
    batch.set(docRef, data);
  }

  function addEstimateToBatch() {
    const { uuid, siteKey, ...rest } = estimateDoc;
    const docRef = doc(db, "siteKeys", siteKey, "estimates", uuid);
    const data: Estimate = {
      ...rest,
      timestampLastModified: Timestamp.now(),
      timestampCreated: Timestamp.now(),
    };
    batch.set(docRef, data);
  }

  function addEstimateItemsToBatch() {
    for (const estimateItem of estimateItems) {
      const { siteKey, ...rest } = estimateItem;
      let docRef = doc(collection(db, "siteKeys", siteKey, "estimateItems"));
      if (estimateItem.id) {
        docRef = doc(db, "siteKeys", siteKey, "estimateItems", estimateItem.id);
      }
      const updateData: EstimateItem = {
        ...rest,
        timestampLastModified: Timestamp.now(),
        timestampCreated: Timestamp.now(),
      };
      batch.set(docRef, updateData);
    }
  }

  // First the estimatePackage
  addEstimatePackageToBatch();

  // Then the estimate
  addEstimateToBatch();

  // Then the estimateItems
  addEstimateItemsToBatch();

  // BATCH COMMIT
  await batch.commit();
  return;
}

/* Dupliacte an existing estimate */
async function duplicateEstimate(
  existingEstimateDoc: ExistingEstimate,
  existingEstimateItems: ExistingEstimateItem[],
  uid: string,
  siteKey: string,
): Promise<string> {
  const db = getFirestore();
  const batch = writeBatch(db);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, refPath, ...rest } = existingEstimateDoc;

  const estimateDoc = {
    ...rest,
    customData: {},
    status: EstimateStatus.DRAFT,
    timestampCreated: Timestamp.now(),
    timestampLastModified: Timestamp.now(),
    createdBy: uid,
    lastModifiedBy: uid,
    timestampSentToCustomer: null,
    timestampApprovedByCustomer: null,
    timestampExpiration: null,
    deleted: false,
  };
  const uuid = DbRead.randomDocID.get();

  function addEstimateToBatch() {
    const docRef = doc(db, "siteKeys", siteKey, "estimates", uuid);
    batch.set(docRef, estimateDoc);
  }

  function addEstimateItemsToBatch() {
    for (const estimateItem of existingEstimateItems) {
      const docRef = doc(collection(db, "siteKeys", siteKey, "estimateItems"));
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, refPath, ...rest } = estimateItem;
      const updateData: EstimateItem = {
        ...rest,
        estimateID: uuid,
        createdBy: uid,
        lastModifiedBy: uid,
        timestampLastModified: Timestamp.now(),
        timestampCreated: Timestamp.now(),
      };
      batch.set(docRef, updateData);
    }
  }

  function addEstimateUpdateToBatch() {
    const docRef = doc(
      db,
      "siteKeys",
      siteKey,
      "estimates",
      existingEstimateDoc.id,
    );

    batch.update(docRef, {
      status: EstimateStatus.LOCKED,
      lastModifiedBy: uid,
      timestampLastModified: Timestamp.now(),
    });
  }

  // Then the estimate
  addEstimateToBatch();

  // Then the estimateItems
  addEstimateItemsToBatch();

  // Add existing estimate update to batch
  addEstimateUpdateToBatch();

  // BATCH COMMIT
  await batch.commit();
  return uuid;
}

/* Add new estimate item */
async function createEstimateItemViaAPI(
  estimateItemDoc: EstimateItem_CreateAPI[],
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "estimate-item";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(fullApiRoute, estimateItemDoc, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/* Add new estimate item */
async function createEstimateItem(
  estimateItemDoc: EstimateItem_CreateAPI[],
): Promise<void> {
  const db = getFirestore();
  const batch = writeBatch(db);
  for (const estimateItem of estimateItemDoc) {
    const { siteKey, ...rest } = estimateItem;
    const docRef = doc(collection(db, "siteKeys", siteKey, "estimateItems"));
    const updateData: EstimateItem = {
      ...rest,
      timestampLastModified: Timestamp.now(),
      timestampCreated: Timestamp.now(),
    };
    batch.set(docRef, updateData);
  }

  await batch.commit();
  return;
}

/* Add new estimate package */
async function createEstimatePackageViaAPI(
  estimatePackageDoc: EstimatePackage_CreateAPI,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "estimate-package";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(fullApiRoute, estimatePackageDoc, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/* Add new estimate package */
async function createEstimatePackage(
  estimatePackageDoc: EstimatePackage_CreateAPI,
): Promise<void> {
  const db = getFirestore();
  const { uuid, siteKey, ...rest } = estimatePackageDoc;
  const docRef = doc(db, "siteKeys", siteKey, "estimatePackages", uuid);
  const updateData: EstimatePackage = {
    ...rest,
    timestampLastModified: Timestamp.now(),
    timestampCreated: Timestamp.now(),
  };

  await setDoc(docRef, updateData);
  return;
}

interface IGetAllTasksOfASingleCustomer {
  siteKey: string;
  permissions: SiteKeyUserPermissions;
  customerID: string;
}

/**  Returns all the tasks of a single customer for the given siteKey
 * and customerID
 */
async function getAllTasksOfASingleCustomer(
  args: IGetAllTasksOfASingleCustomer,
): Promise<ExistingTask[]> {
  const { isSiteAdmin, isPlantPersonnel } = args.permissions.permissions;
  if (isSiteAdmin || isPlantPersonnel) {
    return getAllTasksOfASingleCustomer_admin(args);
  } else {
    return getAllTasksOfASingleCustomer_company(args);
  }
}

async function getAllTasksOfASingleCustomer_admin(
  args: IGetAllTasksOfASingleCustomer,
): Promise<ExistingTask[]> {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    where("customerID", "==", args.customerID),
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((snapshot) => {
    return TaskRecordManager.createFromFirestoreSnapshot(snapshot);
  });
}

async function getAllTasksOfASingleCustomer_company(
  args: IGetAllTasksOfASingleCustomer,
): Promise<ExistingTask[]> {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    where("customerID", "==", args.customerID),
    where("assignedCompanyID", "==", args.permissions.companyID),
  );
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((snapshot) => {
    return TaskRecordManager.createFromFirestoreSnapshot(snapshot);
  });
}

/** Get all tasks associated with the given workRecord doc id. */
async function getAllTasksByWorkRecordID(args: {
  siteKey: string;
  workRecordID: string;
  userPermissions: SiteKeyUserPermissions;
}): Promise<ExistingTask[]> {
  const { isSiteAdmin, isPlantPersonnel } = args.userPermissions.permissions;
  if (isSiteAdmin || isPlantPersonnel) {
    return getAllTasksByWorkRecordID_readAll({
      siteKey: args.siteKey,
      workRecordID: args.workRecordID,
    });
  } else {
    return getAllTasksByWorkRecordID_read({
      siteKey: args.siteKey,
      workRecordID: args.workRecordID,
      userPermissions: args.userPermissions,
    });
  }
}

/** User is site admin or plant personnel. They can view all tasks. */
async function getAllTasksByWorkRecordID_readAll(args: {
  siteKey: string;
  workRecordID: string;
}): Promise<ExistingTask[]> {
  const db = getFirestore();
  const craftPath = `siteKeys/${args.siteKey}/parentRecords/${args.workRecordID}`;
  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    where("craftRecordID", "==", craftPath),
  );
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((snapshot) => {
    return TaskRecordManager.createFromFirestoreSnapshot(snapshot);
  });
}

/** User is a contractor. They can view all tasks that their company "owns". */
async function getAllTasksByWorkRecordID_read(args: {
  siteKey: string;
  workRecordID: string;
  userPermissions: SiteKeyUserPermissions;
}): Promise<ExistingTask[]> {
  const db = getFirestore();
  const craftPath = `siteKeys/${args.siteKey}/parentRecords/${args.workRecordID}`;
  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    where("craftRecordID", "==", craftPath),
    where("assignedCompanyID", "==", args.userPermissions.companyID),
  );
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((snapshot) => {
    return TaskRecordManager.createFromFirestoreSnapshot(snapshot);
  });
}

interface ISubscribeAllEventsByWorkRecordID {
  siteKey: string;
  permissions: SiteKeyUserPermissions;
  workRecordID: string;
  onChange: (eventList: ExistingEvent[]) => void;
  onError?: (error: FirestoreError) => void;
}

function subscribeAllEventsByWorkRecordID(
  args: ISubscribeAllEventsByWorkRecordID,
): () => void {
  const { isSiteAdmin, isPlantPersonnel } = args.permissions.permissions;
  if (isSiteAdmin || isPlantPersonnel) {
    return subscribeAllEventsByWorkRecordID_readAll(args);
  } else {
    return subscribeAllEventsByWorkRecordID_read(args);
  }
}

function subscribeAllEventsByWorkRecordID_readAll(
  args: ISubscribeAllEventsByWorkRecordID,
): () => void {
  const db = getFirestore();
  const craftPath = `siteKeys/${args.siteKey}/parentRecords/${args.workRecordID}`;
  const q = query(
    collection(db, "siteKeys", args.siteKey, "events"),
    where("craftRecordID", "==", craftPath),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const eventList: ExistingEvent[] = querySnapshot.docs.map((snapshot) =>
        EventRecordManager.createFromSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(eventList);
    },
    args.onError,
  );

  return removeListeners;
}

function subscribeAllEventsByWorkRecordID_read(
  args: ISubscribeAllEventsByWorkRecordID,
): () => void {
  const db = getFirestore();
  const craftPath = `siteKeys/${args.siteKey}/parentRecords/${args.workRecordID}`;
  const q = query(
    collection(db, "siteKeys", args.siteKey, "events"),
    where("craftRecordID", "==", craftPath),
    where("assignedCompanyID", "==", args.permissions.companyID),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const eventList: ExistingEvent[] = querySnapshot.docs.map((snapshot) =>
        EventRecordManager.createFromSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(eventList);
    },
    args.onError,
  );

  return removeListeners;
}

/** Get all events associated with the given workRecord doc id. */
async function getAllEventsByWorkRecordID(args: {
  siteKey: string;
  workRecordID: string;
  userPermissions: SiteKeyUserPermissions;
}): Promise<ExistingEvent[]> {
  const { isSiteAdmin, isPlantPersonnel } = args.userPermissions.permissions;
  if (isSiteAdmin || isPlantPersonnel) {
    return getAllEventsByWorkRecordID_readAll({
      siteKey: args.siteKey,
      workRecordID: args.workRecordID,
    });
  } else {
    return getAllEventsByWorkRecordID_read({
      siteKey: args.siteKey,
      workRecordID: args.workRecordID,
      userPermissions: args.userPermissions,
    });
  }
}

/** User is site admin or plant personnel. They can view all events. */
async function getAllEventsByWorkRecordID_readAll(args: {
  siteKey: string;
  workRecordID: string;
}): Promise<ExistingEvent[]> {
  const db = getFirestore();
  const craftPath = `siteKeys/${args.siteKey}/parentRecords/${args.workRecordID}`;
  const q = query(
    collection(db, "siteKeys", args.siteKey, "events"),
    where("craftRecordID", "==", craftPath),
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((snapshot) => {
    return EventRecordManager.createFromSnapshot(snapshot);
  });
}

/** User is a contractor. They can view all events that their company "owns". */
async function getAllEventsByWorkRecordID_read(args: {
  siteKey: string;
  workRecordID: string;
  userPermissions: SiteKeyUserPermissions;
}): Promise<ExistingEvent[]> {
  const db = getFirestore();
  const craftPath = `siteKeys/${args.siteKey}/parentRecords/${args.workRecordID}`;
  const q = query(
    collection(db, "siteKeys", args.siteKey, "events"),
    where("craftRecordID", "==", craftPath),
    where("assignedCompanyID", "==", args.userPermissions.companyID),
  );
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((snapshot) => {
    return EventRecordManager.createFromSnapshot(snapshot);
  });
}

/**
 * Returns the estimate with the given estimateId from the estimate collection for the given siteKey.
 */
function subscribeSingleEstimateByEstimateId(args: {
  siteKey: string;
  estimateId: string;
  onChange: (locations: ExistingEstimate) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const docRef = doc(
    db,
    "siteKeys",
    args.siteKey,
    "estimates",
    args.estimateId,
  );

  const unsubscribe = onSnapshot(
    docRef,
    (snapshot) => {
      // Convert Firestore snapshot to our local data structure.
      const estimateDoc: ExistingEstimate =
        EstimateManager.createFromFirestoreSnapshot(snapshot);
      args.onChange(estimateDoc);
    },
    args.onError,
  );

  return unsubscribe;
}

/**
 * Returns the estimate with the given estimateId from the estimate collection for the given siteKey.
 */
async function getSingleEstimateByEstimateId(
  siteKey: string,
  estimateId: string,
): Promise<ExistingEstimate> {
  const db = getFirestore();
  const docReference = doc(db, "siteKeys", siteKey, "estimates", estimateId);
  // Await the asynchronous function, returns a DocumentSnapshot object from database.
  const snapshot = await getDoc(docReference);
  return EstimateManager.createFromFirestoreSnapshot(snapshot);
}

/**
 * Returns the estimate item(s) for the given estimateId, in the estimateItems collection for the given siteKey.
 */
async function getEstimateItemsByEstimateId(
  siteKey: string,
  estimateId: string,
): Promise<ExistingEstimateItem[]> {
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    siteKey,
    "estimateItems",
  );
  const q = query(
    collectionReference,
    where("estimateID", "==", estimateId),
    where("deleted", "==", false),
  );

  // Await the asynchronous function, returns a QuerySnapshot object from database.
  const querySnapshot = await getDocs(q);

  // For each document snapshot in the query snapshot, apply the function.
  const estimateItems = querySnapshot.docs.map((snapshot) =>
    EstimateItemManager.createFromFirestoreSnapshot(snapshot),
  );
  return estimateItems;
}

function subscribeEstimateItemsByEstimateId(args: {
  siteKey: string;
  estimateId: string;
  onChange: (locations: ExistingEstimateItem[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    args.siteKey,
    "estimateItems",
  );
  const q = query(
    collectionReference,
    where("estimateID", "==", args.estimateId),
    where("deleted", "==", false),
    orderBy("timestampCreated", "asc"),
  );

  return onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      const estimateItemsList = querySnap.docs.map((snapshot) =>
        EstimateItemManager.createFromFirestoreSnapshot(snapshot),
      );
      args.onChange(estimateItemsList);
    },
    args.onError,
  );
}

/**
 * Returns the estimate(s) for the given customerId, in the estimates collection for the given siteKey.
 */
async function getEstimateListByCustomerId(
  siteKey: string,
  customerId: string,
): Promise<ExistingEstimate[]> {
  const db = getFirestore();
  const collectionReference = collection(db, "siteKeys", siteKey, "estimates");
  const q = query(
    collectionReference,
    where("customerID", "==", customerId),
    where("deleted", "==", false),
  );

  // Await the asynchronous function, returns a QuerySnapshot object from database.
  const querySnapshot = await getDocs(q);

  // For each document snapshot in the query snapshot, apply the function.
  const estimateList = querySnapshot.docs.map((snapshot) =>
    EstimateManager.createFromFirestoreSnapshot(snapshot),
  );
  return estimateList;
}

/**
 * Returns the estimate(s) for the given customerId, in the estimates collection for the given siteKey.
 */
async function getEstimateListByTaskId(
  siteKey: string,
  taskId: string,
): Promise<ExistingEstimate[]> {
  const db = getFirestore();
  const collectionReference = collection(db, "siteKeys", siteKey, "estimates");
  const q = query(
    collectionReference,
    where("taskID", "==", taskId),
    where("deleted", "==", false),
  );

  // Await the asynchronous function, returns a QuerySnapshot object from database.
  const querySnapshot = await getDocs(q);

  // For each document snapshot in the query snapshot, apply the function.
  const estimateList = querySnapshot.docs.map((snapshot) =>
    EstimateManager.createFromFirestoreSnapshot(snapshot),
  );
  return estimateList;
}

async function updateEstimateViaAPI(
  partialEstimateDoc: Estimate_UpdateAPI,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "estimate";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.put(fullApiRoute, partialEstimateDoc, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/** Update an estimate doc via direct write. If present, converts customData to dot-separated field paths */
async function updateEstimate(
  partialEstimateDoc: ExistingEstimateUpdate,
): Promise<void> {
  const db = getFirestore();
  const siteKey = partialEstimateDoc.refPath.split("/")[1];
  const estimateID = partialEstimateDoc.refPath.split("/")[3];
  const docRef = doc(db, "siteKeys", siteKey, "estimates", estimateID);
  const updateData =
    EstimateManager.convertUpdateForFirestore(partialEstimateDoc);
  let customDataUpdate: Record<string, any> = {};
  if (updateData.customData) {
    customDataUpdate = convertNestedObjToDotSeparatedFields(
      updateData.customData,
      "customData",
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { customData, ...finalData } = updateData;

  await updateDoc(docRef, { ...finalData, ...customDataUpdate });
  return;
}

interface ISubscribeAllInvoicesInDateRange {
  siteKey: string;
  dateRange: RangeValue<CalendarDate | ZonedDateTime | CalendarDateTime>;
  onChange: (stiltInvoiceList: ExistingStiltInvoice[]) => void;
  onError?: (error: FirestoreError) => void;
}

async function subscribeAllInvoicesInDateRange(
  params: ISubscribeAllInvoicesInDateRange,
): Promise<() => void> {
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    params.siteKey,
    "invoices",
  );
  const startDate = params.dateRange.start.toDate(getLocalTimeZone());
  const endDate = params.dateRange.end.toDate(getLocalTimeZone());
  // Add 1 day to the endDate so that the query is inclusive of the end date
  // (otherwise the query will be until 12AM of the endDate and invoices on the
  // endDate will not be returned
  endDate.setDate(endDate.getDate() + 1);
  const q = query(
    collectionReference,
    where("issueDate", ">=", Timestamp.fromDate(startDate)),
    where("issueDate", "<=", Timestamp.fromDate(endDate)),
    orderBy("issueDate", "desc"),
  );
  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const invoiceList: ExistingStiltInvoice[] = querySnapshot.docs.map(
        (snapshot: DocumentSnapshot<DocumentData, DocumentData>) =>
          StiltInvoiceManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      params.onChange(invoiceList);
    },
    params.onError,
  );

  return removeListeners;
}

interface ISubscribeAllParentRecordsInDateRange {
  siteKey: string;
  open: boolean;
  dateRange: RangeValue<CalendarDate | ZonedDateTime | CalendarDateTime>;
  onChange: (stiltInvoiceList: ExistingCraftRecord[]) => void;
  onError?: (error: FirestoreError) => void;
}

function subscribeAllParentRecordsInDateRange(
  params: ISubscribeAllParentRecordsInDateRange,
): () => void {
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    params.siteKey,
    "parentRecords",
  );
  const startDate = params.dateRange.start.toDate(getLocalTimeZone());
  const endDate = params.dateRange.end.toDate(getLocalTimeZone());
  // Add 1 day to the endDate so that the query is inclusive of the end date
  // (otherwise the query will be until 12AM of the endDate and invoices on the
  // endDate will not be returned
  endDate.setDate(endDate.getDate() + 1);
  const q = query(
    collectionReference,
    where("timestampRecordCreated", ">=", Timestamp.fromDate(startDate)),
    where("timestampRecordCreated", "<=", Timestamp.fromDate(endDate)),
    where("open", "==", params.open),
    orderBy("timestampRecordCreated", "desc"),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const craftRecordsList: ExistingCraftRecord[] = querySnapshot.docs.map(
        (snapshot: DocumentSnapshot<DocumentData, DocumentData>) =>
          CraftRecordManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      params.onChange(craftRecordsList);
    },
    params.onError,
  );

  return removeListeners;
}

function subscribeAllInvoicesByAccountingSync(args: {
  siteKey: string;
  onChange: (invoices: ExistingStiltInvoiceAccountingSyncTableData[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    args.siteKey,
    "invoices",
  );

  const q = query(
    collectionReference,
    or(
      where("accountingSync.syncStatus", "!=", "SYNCED"),
      where("accountingSync.isAwaitingSync", "==", true),
    ),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const invoiceList: ExistingStiltInvoiceAccountingSyncTableData[] =
        querySnapshot.docs.map(
          (snapshot: DocumentSnapshot<DocumentData, DocumentData>) => {
            const invoice =
              StiltInvoiceManager.createFromFirestoreSnapshot(snapshot);
            return { ...invoice, docType: DocType.INVOICES };
          },
        );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(invoiceList);
    },
    args.onError,
  );

  return removeListeners;
}

interface ISubscribeAllPaymentsInDateRange {
  siteKey: string;
  dateRange: RangeValue<CalendarDate | ZonedDateTime | CalendarDateTime>;
  onChange: (stiltPayments: ExistingStiltPayment[]) => void;
  onError?: (error: FirestoreError) => void;
}

async function subscribeAllPaymentsInDateRange(
  params: ISubscribeAllPaymentsInDateRange,
): Promise<() => void> {
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    params.siteKey,
    "payments",
  );
  const startDate = params.dateRange.start.toDate(getLocalTimeZone());
  const endDate = params.dateRange.end.toDate(getLocalTimeZone());
  // Add 1 day to the endDate so that the query is inclusive of the end date
  // (otherwise the query will be until 12AM of the endDate and invoices on the
  // endDate will not be returned
  endDate.setDate(endDate.getDate() + 1);
  const q = query(
    collectionReference,
    where("timestampPaymentMade", ">=", Timestamp.fromDate(startDate)),
    where("timestampPaymentMade", "<=", Timestamp.fromDate(endDate)),
    where("deleted", "==", false),
    orderBy("timestampPaymentMade", "desc"),
  );
  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const paymentsList: ExistingStiltPayment[] = querySnapshot.docs.map(
        (snapshot: DocumentSnapshot<DocumentData, DocumentData>) =>
          StiltPaymentManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      params.onChange(paymentsList);
    },
    params.onError,
  );

  return removeListeners;
}

interface ISubscribeAllPaymentsByInvoiceID {
  siteKey: string;
  invoiceID: string;
  onChange: (stiltPayments: ExistingStiltPayment[]) => void;
  onError?: (error: FirestoreError) => void;
}

function subscribeAllPaymentsByInvoiceID(
  params: ISubscribeAllPaymentsByInvoiceID,
): () => void {
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    params.siteKey,
    "payments",
  );

  const q = query(
    collectionReference,
    where("invoiceID", "==", params.invoiceID),
    where("deleted", "==", false),
  );
  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const paymentsList: ExistingStiltPayment[] = querySnapshot.docs.map(
        (snapshot: DocumentSnapshot<DocumentData, DocumentData>) =>
          StiltPaymentManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      params.onChange(paymentsList);
    },
    params.onError,
  );

  return removeListeners;
}

function subscribeAllPaymentsByAccountingSync(args: {
  siteKey: string;
  onChange: (payments: ExistingStiltPaymentAccountingSyncTableData[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    args.siteKey,
    "payments",
  );

  const q = query(
    collectionReference,
    and(
      where("deleted", "==", false),
      or(
        where("accountingSync.syncStatus", "!=", "SYNCED"),
        where("accountingSync.isAwaitingSync", "==", true),
      ),
    ),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const paymentsList: ExistingStiltPaymentAccountingSyncTableData[] =
        querySnapshot.docs.map(
          (snapshot: DocumentSnapshot<DocumentData, DocumentData>) => {
            const payment =
              StiltPaymentManager.createFromFirestoreSnapshot(snapshot);
            return { ...payment, docType: DocType.PAYMENTS };
          },
        );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(paymentsList);
    },
    args.onError,
  );

  return removeListeners;
}

async function recordManualPayment(paymentData: StiltPayment_CreateAPI) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "recordManualPayment");
  return callable(paymentData);
}

async function recordManualBulkPayment(args: {
  payments: CreateMultiPayment[];
  siteKey: string;
}) {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "multi-payment";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(fullApiRoute, args, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

async function getSingleCustomerLocation(
  siteKey: string,
  customerLocationID: string,
): Promise<ExistingCustomerLocation> {
  // Create the database object
  const db = getFirestore();
  const docReference = doc(
    db,
    "siteKeys",
    siteKey,
    "customerLocations",
    customerLocationID,
  );
  // Await the asynchronous function, returns a DocumentSnapshot object from database.
  const snapshot = await getDoc(docReference);

  return CustomerLocationManager.createFromFirestoreSnapshot(snapshot);
}

async function generatePaymentUniqueLink(
  args: CreatePaymentUniqueLink,
): Promise<string> {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "generateUniqueInvoiceLink");
  const response = await callable(args);
  const paymentID = response.data;
  if (typeof paymentID === "string") {
    return paymentID;
  } else {
    throw new Error(
      `Error generating new payment id. paymentID: ${paymentID}, data: ${JSON.stringify(
        response.data,
        null,
        2,
      )}`,
    );
  }
}

async function generateMultiPaymentCreditCardLink(
  args: CreateMultiPaymentUniqueLink,
): Promise<string> {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "generateMultiPaymentLink");
  const response = await callable(args);
  const uniqueLink = response.data;
  if (typeof uniqueLink === "string") {
    return uniqueLink;
  } else {
    throw new Error(
      `Error generating multi-pay credit card url: ${uniqueLink}, data: ${JSON.stringify(
        response.data,
        null,
        2,
      )}`,
    );
  }
}

async function createInvoiceFromEstimate(args: {
  siteKey: string;
  estimateID: string;
  version: string;
  platform: Platform;
}) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "convertEstimateToInvoice");
  const response = await callable(args);
  const responseData: any = response.data;
  const paymentURL: string = z
    .string()
    .min(1)
    .max(500)
    .parse(responseData?.paymentURL);
  const invoiceData: ExistingStiltInvoice = StiltInvoiceManager.convertFromCF(
    responseData?.invoiceData,
  );

  return {
    paymentURL,
    invoiceData,
  };
}

/**
 * Returns the invoice(s) for the given customerId, in the invoices collection for the given siteKey.
 */
async function getInvoiceListByCustomerId(
  siteKey: string,
  customerId: string,
): Promise<ExistingStiltInvoice[]> {
  const db = getFirestore();
  const collectionReference = collection(db, "siteKeys", siteKey, "invoices");
  const q = query(collectionReference, where("customerID", "==", customerId));

  // Await the asynchronous function, returns a QuerySnapshot object from database.
  const querySnapshot = await getDocs(q);

  // For each document snapshot in the query snapshot, apply the function.
  const invoiceList = querySnapshot.docs.map((snapshot) =>
    StiltInvoiceManager.createFromFirestoreSnapshot(snapshot),
  );
  return invoiceList;
}

function subscribeAllPendingLightspeedTransactions(args: {
  siteKey: string;
  onChange: (vehicleList: ExistingLightspeedTransaction[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    args.siteKey,
    "lightspeedTransactions",
  );

  const q = query(
    collectionReference,
    where("timestampPushed", "==", null),
    limit(100),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const txns: ExistingLightspeedTransaction[] = querySnapshot.docs.map(
        (docSnapshot) =>
          LightspeedTransactionManager.createFromFirestoreSnapshot(docSnapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(txns);
    },
    args.onError,
  );
  return removeListeners;
}

function subscribeAllPushedLightspeedTransactions(args: {
  siteKey: string;
  startDate: Date;
  endDate: Date;
  onChange: (vehicleList: ExistingLightspeedTransaction[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    args.siteKey,
    "lightspeedTransactions",
  );

  const q = query(
    collectionReference,
    where("timestampPushed", ">=", Timestamp.fromDate(args.startDate)),
    where("timestampPushed", "<", Timestamp.fromDate(args.endDate)),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const txns: ExistingLightspeedTransaction[] = querySnapshot.docs.map(
        (docSnapshot) =>
          LightspeedTransactionManager.createFromFirestoreSnapshot(docSnapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(txns);
    },
    args.onError,
  );
  return removeListeners;
}

async function getAllPendingLightspeedTransactions(siteKey: string) {
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    siteKey,
    "lightspeedTransactions",
  );
  const dbQuery = query(
    collectionReference,
    where("timestampPushed", "==", null),
  );

  const snapshot = await getDocs(dbQuery);
  return snapshot.docs.map((snap) =>
    LightspeedTransactionManager.createFromFirestoreSnapshot(snap),
  );
}

async function getAllInventoryTransactions(siteKey: string) {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    siteKey,
    "inventoryTransactions",
  );

  const snapshot = await getDocs(collectionReference);
  return snapshot.docs.map((snap) =>
    InventoryTransactionManager.createFromFirestoreSnapshot(snap),
  );
}

async function getAllInventoryObjects(siteKey: string) {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    siteKey,
    "inventoryObjects",
  );

  const snapshot = await getDocs(collectionReference);
  return snapshot.docs.map((snap) =>
    InventoryObjectManager.createFromFirestoreSnapshot(snap),
  );
}

async function getAllInventoryLocations(siteKey: string) {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    siteKey,
    "inventoryLocations",
  );

  const snapshot = await getDocs(collectionReference);
  return snapshot.docs.map((snap) =>
    InventoryLocationManager.createFromFirestoreSnapshot(snap),
  );
}

/** Add new inventory location. */
async function createInventoryLocation(
  siteKey: string,
  inventoryLocation: InventoryLocation,
): Promise<void> {
  const db = getFirestore();
  const collRef = collection(db, "siteKeys", siteKey, "inventoryLocations");
  const updateData: InventoryLocation = {
    ...inventoryLocation,
    latitude: null,
    longitude: null,
  };
  console.log(updateData);

  await addDoc(collRef, updateData);
  return;
}

/** Add new inventory transaction. */
async function createInventoryTransaction(
  siteKey: string,
  inventoryTransaction: InventoryTransaction,
): Promise<void> {
  const db = getFirestore();
  const collRef = collection(db, "siteKeys", siteKey, "inventoryTransactions");
  await addDoc(collRef, inventoryTransaction);
  return;
}

/** Add new inventory transaction. */
async function createMultipleInventoryTransactions(
  siteKey: string,
  inventoryTransactions: InventoryTransaction[],
): Promise<void> {
  const db = getFirestore();
  const collRef = collection(db, "siteKeys", siteKey, "inventoryTransactions");
  const batch = writeBatch(db);

  inventoryTransactions.forEach((inventoryTransaction) => {
    const docRef = doc(collRef);
    console.log(inventoryTransaction);
    batch.set(docRef, inventoryTransaction);
  });

  await batch.commit();

  return;
}

async function createInventoryObject(
  inventoryObjectDoc: InventoryObject_CreateAPI,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "inventory-object";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(fullApiRoute, inventoryObjectDoc, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

export async function manuallyPushLightspeedTransactions(gazz: {
  siteKeyID: string;
  refPathList: string[];
}) {
  const functions = getFunctions();
  const callable = httpsCallable(
    functions,
    "manuallyPushLightspeedTransactions",
    {
      timeout: 240000,
    },
  );
  return callable({ siteKey: gazz.siteKeyID, refPathList: gazz.refPathList });
}

export async function hitAcctSync(gazz: {
  siteKeyID: string;
  refPathList: string[];
}): Promise<string[]> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "accounting-sync";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    const result = await axios.post(fullApiRoute, gazz, {
      headers: { authorization: `Bearer ${idToken}` },
    });

    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

async function refreshCodatData(siteKeyID: string): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "refresh-codat";
  const fullApiRoute = `${apiBaseURL}/${endpoint}/${siteKeyID}`;

  try {
    await axios.get(fullApiRoute, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

async function syncBurgessLightspeed(siteKey: string) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "triggerSyncBurgessLightspeed", {
    timeout: 240000,
  });
  return callable({ siteKey });
}

function subscribeAllVehicles(args: {
  siteKey: string;
  onChange: (vehicleList: ExistingVehicle[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    args.siteKey,
    "vehicles",
  );

  const q = query(collectionReference, where("deleted", "==", false));

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const checklistItems: ExistingVehicle[] = querySnapshot.docs.map(
        (docSnapshot) =>
          VehicleManager.createFromFirestoreSnapshot(docSnapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(checklistItems);
    },
    args.onError,
  );
  return removeListeners;
}

function subscribeAllInventoryLocations(args: {
  siteKey: string;
  onChange: (vehicleList: ExistingInventoryLocation[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    args.siteKey,
    "inventoryLocations",
  );

  const q = query(collectionReference);

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const checklistItems: ExistingInventoryLocation[] =
        querySnapshot.docs.map((docSnapshot) =>
          InventoryLocationManager.createFromFirestoreSnapshot(docSnapshot),
        );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(checklistItems);
    },
    args.onError,
  );
  return removeListeners;
}

function subscribeAllInventoryObjects(args: {
  siteKey: string;
  onChange: (vehicleList: ExistingInventoryObject[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    args.siteKey,
    "inventoryObjects",
  );

  const q = query(collectionReference);

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const inventoryObjects: ExistingInventoryObject[] =
        querySnapshot.docs.map((docSnapshot) =>
          InventoryObjectManager.createFromFirestoreSnapshot(docSnapshot),
        );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(inventoryObjects);
    },
    args.onError,
  );
  return removeListeners;
}

function subscribeInventoryTransactions(args: {
  siteKey: string;
  parentRecordID: string | null;
  taskID: string | null;
  invoiceID: string | null;
  onChange: (inventoryTransactionsList: ExistingInventoryTransaction[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    args.siteKey,
    "inventoryTransactions",
  );

  const queryConstraints = [];

  if (args.parentRecordID !== null) {
    queryConstraints.push(where("parentRecordID", "==", args.parentRecordID));
  }
  if (args.taskID !== null) {
    queryConstraints.push(where("taskID", "==", args.taskID));
  }
  if (args.invoiceID !== null) {
    queryConstraints.push(where("invoiceID", "==", args.invoiceID));
  }
  const q = query(collectionReference, ...queryConstraints);

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const inventoryTransactionsList: ExistingInventoryTransaction[] =
        querySnapshot.docs.map((docSnapshot) =>
          InventoryTransactionManager.createFromFirestoreSnapshot(docSnapshot),
        );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(inventoryTransactionsList);
    },
    args.onError,
  );
  return removeListeners;
}

function subscribeAllZones(args: {
  siteKey: string;
  onChange: (zoneList: ExistingZone[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const collectionReference = collection(db, "siteKeys", args.siteKey, "zones");

  const q = query(collectionReference);

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const zones: ExistingZone[] = querySnapshot.docs.map((docSnapshot) =>
        ZoneManager.createFromFirestoreSnapshot(docSnapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(zones);
    },
    args.onError,
  );
  return removeListeners;
}

async function getAllVehicles(siteKey: string): Promise<ExistingVehicle[]> {
  const db = getFirestore();
  const collectionReference = collection(db, "siteKeys", siteKey, "vehicles");
  const snapshot = await getDocs(collectionReference);
  return snapshot.docs.map((snap) =>
    VehicleManager.createFromFirestoreSnapshot(snap),
  );
}

interface ISubscribeScheduledAwaitingTaskList {
  siteKey: string;
  permissions: SiteKeyUserPermissions;
  startDate: Timestamp;
  endDate: Timestamp;
  onChange: (TaskList: ExistingTask[]) => void;
  onError?: (error: FirestoreError) => void;
}

function subscribeScheduledAwaitingTaskList(
  args: ISubscribeScheduledAwaitingTaskList,
): () => void {
  const { isSiteAdmin, isPlantPersonnel } = args.permissions.permissions;
  if (isSiteAdmin || isPlantPersonnel) {
    return subscribeScheduledAwaitingTaskList_admin(args);
  } else {
    return subscribeScheduledAwaitingTaskList_company(args);
  }
}

function subscribeScheduledAwaitingTaskList_admin(
  args: ISubscribeScheduledAwaitingTaskList,
): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    where("timestampScheduled", ">=", args.startDate),
    where("timestampScheduled", "<", args.endDate),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const scheduledTaskList: ExistingTask[] = querySnapshot.docs.map(
        (snapshot) => TaskRecordManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(
        scheduledTaskList.filter((t) => t.taskStatus !== OTaskStatus.CANCELED),
      );
    },
    args.onError,
  );

  return removeListeners;
}

function subscribeByTaskStatus(args: {
  siteKey: string;
  taskStatus: TaskStatus[];
  permissions: SiteKeyUserPermissions;
  startDate: Date | null;
  endDate: Date | null;
  dateParam:
    | "timestampCreated"
    | "timestampLastModified"
    | "timestampScheduled"
    | "timestampTaskCompleted"
    | "timestampTaskStarted"
    | null;
  onChange: (TaskList: ExistingTask[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const { isSiteAdmin, isPlantPersonnel } = args.permissions.permissions;

  const queryConstraints = [where("taskStatus", "in", args.taskStatus)];

  if (!isSiteAdmin && !isPlantPersonnel) {
    queryConstraints.push(
      where("assignedCompanyID", "==", args.permissions.companyID),
    );
  }

  if (args.startDate && args.dateParam) {
    queryConstraints.push(
      where(args.dateParam as string, ">=", Timestamp.fromDate(args.startDate)),
    );
  }

  if (args.endDate && args.dateParam) {
    queryConstraints.push(
      where(args.dateParam as string, "<", Timestamp.fromDate(args.endDate)),
    );
  }

  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    ...queryConstraints,
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const taskList: ExistingTask[] = querySnapshot.docs.map((snapshot) =>
        TaskRecordManager.createFromFirestoreSnapshot(snapshot),
      );
      args.onChange(taskList);
    },
    args.onError,
  );

  return removeListeners;
}

function subscribeAllOpenTasks(args: {
  siteKey: string;
  permissions: SiteKeyUserPermissions;
  onChange: (TaskList: ExistingTask[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const { isSiteAdmin, isPlantPersonnel } = args.permissions.permissions;

  const queryConstraints = [where("taskStatus", "<", TaskStatus.COMPLETE)];

  if (!isSiteAdmin && !isPlantPersonnel) {
    queryConstraints.push(
      where("assignedCompanyID", "==", args.permissions.companyID),
    );
  }

  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    ...queryConstraints,
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const taskList: ExistingTask[] = querySnapshot.docs.map((snapshot) =>
        TaskRecordManager.createFromFirestoreSnapshot(snapshot),
      );
      args.onChange(taskList);
    },
    args.onError,
  );

  return removeListeners;
}

function subscribeAllUrgentTasks(args: {
  siteKey: string;
  permissions: SiteKeyUserPermissions;
  onChange: (TaskList: ExistingTask[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const { isSiteAdmin, isPlantPersonnel } = args.permissions.permissions;

  const queryConstraints = [
    where("taskStatus", "<", TaskStatus.COMPLETE),
    where("urgent", "==", true),
  ];

  if (!isSiteAdmin && !isPlantPersonnel) {
    queryConstraints.push(
      where("assignedCompanyID", "==", args.permissions.companyID),
    );
  }

  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    ...queryConstraints,
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const taskList: ExistingTask[] = querySnapshot.docs.map((snapshot) =>
        TaskRecordManager.createFromFirestoreSnapshot(snapshot),
      );
      args.onChange(taskList);
    },
    args.onError,
  );

  return removeListeners;
}

function subscribeAllTasksByTaskType(args: {
  siteKey: string;
  permissions: SiteKeyUserPermissions;
  taskType: TaskTypes[];
  onChange: (TaskList: ExistingTask[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const { isSiteAdmin, isPlantPersonnel } = args.permissions.permissions;

  const queryConstraints = [
    where("taskStatus", "<", TaskStatus.COMPLETE),
    where("taskType", "in", args.taskType),
  ];

  if (!isSiteAdmin && !isPlantPersonnel) {
    queryConstraints.push(
      where("assignedCompanyID", "==", args.permissions.companyID),
    );
  }

  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    ...queryConstraints,
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const taskList: ExistingTask[] = querySnapshot.docs.map((snapshot) =>
        TaskRecordManager.createFromFirestoreSnapshot(snapshot),
      );
      args.onChange(taskList);
    },
    args.onError,
  );

  return removeListeners;
}

function subscribeAllTasksByAssignedUser(args: {
  siteKey: string;
  permissions: SiteKeyUserPermissions;
  uid: string;
  onChange: (TaskList: ExistingTask[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const { isSiteAdmin, isPlantPersonnel } = args.permissions.permissions;

  const queryConstraints = [
    where("taskStatus", "<", TaskStatus.COMPLETE),
    where("taskSpecificDetails.assignedTo", "array-contains", args.uid),
  ];

  if (!isSiteAdmin && !isPlantPersonnel) {
    queryConstraints.push(
      where("assignedCompanyID", "==", args.permissions.companyID),
    );
  }

  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    ...queryConstraints,
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const taskList: ExistingTask[] = querySnapshot.docs.map((snapshot) =>
        TaskRecordManager.createFromFirestoreSnapshot(snapshot),
      );
      args.onChange(taskList);
    },
    args.onError,
  );

  return removeListeners;
}

function subscribeAllMembershipTasks(args: {
  siteKey: string;
  permissions: SiteKeyUserPermissions;
  onChange: (TaskList: ExistingTask[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const { isSiteAdmin, isPlantPersonnel } = args.permissions.permissions;

  const queryConstraints = [
    where("taskStatus", "<", TaskStatus.COMPLETE),
    where("isMembershipTask", "==", true),
  ];

  if (!isSiteAdmin && !isPlantPersonnel) {
    queryConstraints.push(
      where("assignedCompanyID", "==", args.permissions.companyID),
    );
  }

  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    ...queryConstraints,
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const taskList: ExistingTask[] = querySnapshot.docs.map((snapshot) =>
        TaskRecordManager.createFromFirestoreSnapshot(snapshot),
      );
      args.onChange(taskList);
    },
    args.onError,
  );

  return removeListeners;
}

function subscribeAllBacklogTasks(args: {
  siteKey: string;
  permissions: SiteKeyUserPermissions;
  onChange: (TaskList: ExistingTask[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const { isSiteAdmin, isPlantPersonnel } = args.permissions.permissions;

  const db = getFirestore();

  const queryConstraints = [where("taskStatus", "==", TaskStatus.AWAITING)];

  if (!isSiteAdmin && !isPlantPersonnel) {
    queryConstraints.push(
      where("assignedCompanyID", "==", args.permissions.companyID),
    );
  }

  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    ...queryConstraints,
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const taskList: ExistingTask[] = querySnapshot.docs.map((snapshot) =>
        TaskRecordManager.createFromFirestoreSnapshot(snapshot),
      );
      const filteredTaskList = taskList.filter((t) => {
        return (
          (t.timestampScheduled &&
            moment(t.timestampScheduled.seconds * 1000).valueOf() <
              moment().startOf("day").valueOf()) ||
          t.nextOpportunity
        );
      });
      args.onChange(filteredTaskList);
    },
    args.onError,
  );

  return removeListeners;
}

function subscribeScheduledAwaitingTaskList_company(
  args: ISubscribeScheduledAwaitingTaskList,
): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    where("timestampScheduled", ">=", args.startDate),
    where("timestampScheduled", "<", args.endDate),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const scheduledTaskList: ExistingTask[] = querySnapshot.docs.map(
        (snapshot) => TaskRecordManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(scheduledTaskList);
    },
    args.onError,
  );

  return removeListeners;
}

/**
 * Returns all the membership documents in the memberships collection for the given siteKey.
 */
async function getAllMemberships(
  siteKey: string,
): Promise<ExistingMembership[]> {
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    siteKey,
    "memberships",
  );
  const snapshot = await getDocs(collectionReference);
  return snapshot.docs.map((snap) =>
    MembershipManager.createFromFirestoreSnapshot(snap),
  );
}

/**
 * Subscribe to realtime updates for all membership documents for the given siteKey.
 * @returns removeListeners ƒn for cleanup
 */
function subscribeAllMemberships(args: {
  siteKey: string;
  status?: string;
  onChange: (feedbackList: ExistingMembership[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();

  const queryConstraints = [];

  if (args.status) {
    queryConstraints.push(where("status", "==", args.status));
  }

  const q = query(
    collection(db, "siteKeys", args.siteKey, "memberships"),
    ...queryConstraints,
  );

  return onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      const membershipList = querySnap.docs.map(
        MembershipManager.createFromFirestoreSnapshot,
      );
      args.onChange(membershipList);
    },
    args.onError,
  );
}

/**
 * Subscribe to realtime updates for all membership documents for the given customerID.
 * @returns removeListeners ƒn for cleanup
 */
function subscribeMembershipsByCustomerID(args: {
  siteKey: string;
  customerID: string;
  onChange: (feedbackList: ExistingMembership[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "memberships"),
    where("customerID", "==", args.customerID),
    orderBy("timestampCreated", "desc"),
  );
  return onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      const membershipList = querySnap.docs.map(
        MembershipManager.createFromFirestoreSnapshot,
      );
      args.onChange(membershipList);
    },
    args.onError,
  );
}

/**
 * Subscribe to realtime updates for all asset documents for the given siteKey.
 * @returns removeListeners ƒn for cleanup
 */
function subscribeAllAssets(args: {
  siteKey: string;
  onChange: (feedbackList: ExistingAsset[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const q = query(collection(db, "siteKeys", args.siteKey, "assets"));
  return onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      const assetList = querySnap.docs.map(
        AssetManager.createFromFirestoreSnapshot,
      );
      args.onChange(assetList);
    },
    args.onError,
  );
}

/**
 * Subscribe to realtime updates for all asset documents for the given siteKey.
 * @returns removeListeners ƒn for cleanup
 */
function subscribeAssetsByCustomerID(args: {
  siteKey: string;
  customerID: string;
  onChange: (feedbackList: ExistingAsset[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "assets"),
    where("customerID", "==", args.customerID),
  );
  return onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      const assetList = querySnap.docs.map(
        AssetManager.createFromFirestoreSnapshot,
      );
      args.onChange(assetList);
    },
    args.onError,
  );
}

/**
 * Subscribe to realtime updates for all asset documents for the given siteKey.
 * @returns removeListeners ƒn for cleanup
 */
function subscribeAllNotesByCustomerID(args: {
  siteKey: string;
  customerID: string;
  onChange: (feedbackList: ExistingNote[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "notes"),
    where("customerID", "==", args.customerID),
    where("deleted", "==", false),
  );
  return onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      console.log(querySnap.docs);
      const noteList = querySnap.docs.map(
        NoteManager.createFromFirestoreSnapshot,
      );
      args.onChange(noteList);
    },
    args.onError,
  );
}

/**
 * Subscribe to realtime updates for all asset documents for the given siteKey.
 * @returns removeListeners ƒn for cleanup
 */
function subscribeAllChatRoomsByUserID(args: {
  siteKey: string;
  userID: string;
  onChange: (feedbackList: ExistingChatRoom[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "chatRooms"),
    where("userIds", "array-contains", args.userID),
    orderBy("timestampLastModified", "desc"),
  );
  return onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      console.log(querySnap.docs);
      const roomList = querySnap.docs.map(
        ChatRoomManager.createFromFirestoreSnapshot,
      );
      args.onChange(roomList);
    },
    args.onError,
  );
}

/**
 * Subscribe to realtime updates for all asset documents for the given siteKey.
 * @returns removeListeners ƒn for cleanup
 */
function subscribeAllMessagesByRoomID(args: {
  siteKey: string;
  roomID: string;
  onChange: (feedbackList: ExistingChatMessage[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const q = query(
    collection(
      db,
      "siteKeys",
      args.siteKey,
      "chatRooms",
      args.roomID,
      "messages",
    ),
    orderBy("timestampLastModified", "asc"),
  );
  return onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      console.log(querySnap.docs);
      const messageList = querySnap.docs.map(
        ChatMessageManager.createFromFirestoreSnapshot,
      );
      args.onChange(messageList);
    },
    args.onError,
  );
}

/**
 * Subscribe to realtime updates for all asset documents for the given siteKey.
 * @returns removeListeners ƒn for cleanup
 */
function subscribeAssetsByCustomerLocationID(args: {
  siteKey: string;
  customerLocationID: string;
  onChange: (feedbackList: ExistingAsset[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "assets"),
    where("customerLocationID", "==", args.customerLocationID),
  );
  return onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      const assetList = querySnap.docs.map(
        AssetManager.createFromFirestoreSnapshot,
      );
      args.onChange(assetList);
    },
    args.onError,
  );
}

/**
 * Returns all the membership templates in the membershipTemplates collection for the given siteKey.
 */
async function getAllMembershipTemplates(
  siteKey: string,
): Promise<ExistingMembershipTemplate[]> {
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    siteKey,
    "membershipTemplates",
  );
  const snapshot = await getDocs(collectionReference);
  return snapshot.docs.map((snap) =>
    MembershipTemplateManager.createFromFirestoreSnapshot(snap),
  );
}

async function getSingleInvoice(
  siteKey: string,
  invoiceID: string,
): Promise<ExistingStiltInvoice> {
  const db = getFirestore();
  const docReference = doc(db, "siteKeys", siteKey, "invoices", invoiceID);

  const snapshot = await getDoc(docReference);
  return StiltInvoiceManager.createFromFirestoreSnapshot(snapshot);
}

function subscribeEstimateListByCraftRecordId(
  siteKey: string,
  craftRecordID: string,
  onChange: (estimateList: ExistingEstimate[]) => void,
  onError?: (error: FirestoreError) => void,
): () => void {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const collectionReference = collection(db, "siteKeys", siteKey, "estimates");
  const q = query(
    collectionReference,
    where("craftRecordID", "==", craftRecordID),
    where("deleted", "==", false),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const estimateList: ExistingEstimate[] = querySnapshot.docs.map(
        (docSnapshot) =>
          EstimateManager.createFromFirestoreSnapshot(docSnapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      onChange(estimateList);
    },
    onError,
  );
  return removeListeners;
}

function subscribeEstimatesByCustomerID(
  siteKey: string,
  customerID: string,
  onChange: (estimateList: ExistingEstimate[]) => void,
  onError?: (error: FirestoreError) => void,
): () => void {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const collectionReference = collection(db, "siteKeys", siteKey, "estimates");
  const q = query(
    collectionReference,
    where("customerID", "==", customerID),
    where("deleted", "==", false),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const estimateList: ExistingEstimate[] = querySnapshot.docs.map(
        (docSnapshot) =>
          EstimateManager.createFromFirestoreSnapshot(docSnapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      onChange(estimateList);
    },
    onError,
  );
  return removeListeners;
}

/**
 * Returns the estimate(s) for the given craftRecordId, in the estimates collection for the given siteKey.
 */
async function getEstimateListByCraftRecordId(
  siteKey: string,
  craftRecordId: string,
): Promise<ExistingEstimate[]> {
  const db = getFirestore();
  const collectionReference = collection(db, "siteKeys", siteKey, "estimates");
  const q = query(
    collectionReference,
    where("craftRecordID", "==", craftRecordId),
    where("deleted", "==", false),
  );

  // Await the asynchronous function, returns a QuerySnapshot object from database.
  const querySnapshot = await getDocs(q);

  // For each document snapshot in the query snapshot, apply the function.
  const estimateList = querySnapshot.docs.map((snapshot) =>
    EstimateManager.createFromFirestoreSnapshot(snapshot),
  );
  return estimateList;
}

async function updateEstimateItem(
  updateEstimateItemList: EstimateItem_UpdateAPI[],
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "estimate-item";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.put(fullApiRoute, updateEstimateItemList, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/** Delete an estimate item. (Set deleted to true) */
async function deleteEstimateItem(
  siteKey: string,
  estimateItemID: ExistingEstimateItem["id"],
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = `estimate-item/${siteKey}/${estimateItemID}`;
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.delete(fullApiRoute, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

async function getInvoiceListByEstimateId(
  siteKey: string,
  estimateID: string,
): Promise<ExistingStiltInvoice[]> {
  const db = getFirestore();
  const collectionReference = collection(db, "siteKeys", siteKey, "invoices");
  const q = query(
    collectionReference,
    where("estimateID", "==", estimateID),
    where("status", "!=", "canceled"),
  );

  // Await the asynchronous function, returns a QuerySnapshot object from database.
  const querySnapshot = await getDocs(q);

  // For each document snapshot in the query snapshot, apply the function.
  const invoiceList = querySnapshot.docs.map((snapshot) =>
    StiltInvoiceManager.createFromFirestoreSnapshot(snapshot),
  );
  return invoiceList;
}

async function getInvoiceListByTaskId(
  siteKey: string,
  taskID: string,
): Promise<ExistingStiltInvoice[]> {
  const db = getFirestore();
  const collectionReference = collection(db, "siteKeys", siteKey, "invoices");
  const q = query(
    collectionReference,
    where("taskID", "==", taskID),
    where("status", "!=", "canceled"),
  );

  // Await the asynchronous function, returns a QuerySnapshot object from database.
  const querySnapshot = await getDocs(q);

  // For each document snapshot in the query snapshot, apply the function.
  const invoiceList = querySnapshot.docs.map((snapshot) =>
    StiltInvoiceManager.createFromFirestoreSnapshot(snapshot),
  );
  return invoiceList;
}

function subscribeAllInvoicesByCraftRecordID(
  siteKey: string,
  craftRecordID: string,
  onChange: (feedbackList: ExistingStiltInvoice[]) => void,
  onError?: (error: FirestoreError) => void,
): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", siteKey, "invoices"),
    where("craftRecordID", "==", craftRecordID),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const invoiceList: ExistingStiltInvoice[] = querySnapshot.docs.map(
        (snapshot) => StiltInvoiceManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      onChange(invoiceList);
    },
    onError,
  );

  return removeListeners;
}

function subscribeSingleInvoiceByEstimateID(
  siteKey: string,
  estimateID: string,
  onChange: (invoiceList: ExistingStiltInvoice) => void,
  onError?: (error: FirestoreError) => void,
): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", siteKey, "invoices"),
    where("estimateID", "==", estimateID),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const invoiceList: ExistingStiltInvoice[] = querySnapshot.docs.map(
        (snapshot) => StiltInvoiceManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      onChange(invoiceList[0]);
    },
    onError,
  );

  return removeListeners;
}

function subscribeInvoicesByCustomerID(args: {
  siteKey: string;
  customerID: string;
  onChange: (invoiceList: ExistingStiltInvoice[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const { siteKey, customerID, onChange, onError } = args;
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", siteKey, "invoices"),
    where("customerID", "==", customerID),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const invoiceList: ExistingStiltInvoice[] = querySnapshot.docs.map(
        (snapshot) => StiltInvoiceManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      onChange(invoiceList);
    },
    onError,
  );

  return removeListeners;
}

function subscribeInvoicesByMembershipID(args: {
  siteKey: string;
  membershipID: string;
  onChange: (invoiceList: ExistingStiltInvoice) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const { siteKey, membershipID, onChange, onError } = args;
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", siteKey, "invoices"),
    where("customData.membershipIDs", "array-contains", membershipID),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const invoiceList: ExistingStiltInvoice[] = querySnapshot.docs.map(
        (snapshot) => StiltInvoiceManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      onChange(invoiceList[0]);
    },
    onError,
  );

  return removeListeners;
}

function subscribeAllCraftRecordsByCustomerID(args: {
  siteKey: string;
  customerID: string;
  onChange: (craftRecordList: ExistingCraftRecord[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const { siteKey, customerID, onChange, onError } = args;
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    siteKey,
    "parentRecords",
  );
  const q = query(collectionReference, where("customerID", "==", customerID));

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const craftRecordList: ExistingCraftRecord[] = querySnapshot.docs.map(
        (snapshot) => CraftRecordManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      onChange(craftRecordList);
    },
    onError,
  );

  return removeListeners;
}

async function getAllCraftRecordsByCustomerID(
  siteKey: string,
  customerID: string,
): Promise<ExistingCraftRecord[]> {
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    siteKey,
    "parentRecords",
  );
  const q = query(collectionReference, where("customerID", "==", customerID));

  // Await the asynchronous function, returns a QuerySnapshot object from database.
  const querySnapshot = await getDocs(q);

  // For each document snapshot in the query snapshot, apply the function.
  const craftRecordList = querySnapshot.docs.map((snapshot) =>
    CraftRecordManager.createFromFirestoreSnapshot(snapshot),
  );
  return craftRecordList;
}

/* Add new membership template */
async function createMembershipTemplate(
  membershipTemplateDoc: MembershipTemplate_CreateAPI,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "membership-template";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;
  try {
    await axios.post(fullApiRoute, membershipTemplateDoc, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/* Update a membership template doc */
async function updateMembershipTemplate(
  partialMembershipTemplate: MembershipTemplate_UpdateAPI,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "membership-template";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;
  try {
    await axios.put(fullApiRoute, partialMembershipTemplate, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/**
 * Add new membership doc.
 * @returns string[], containing document IDs
 */
async function createMembership(
  siteKey: string,
  data: DocumentData,
): Promise<DocumentReference> {
  const db = getFirestore();
  const docRef = await addDoc(
    collection(db, "siteKeys", siteKey, "memberships"),
    data,
  );
  console.log(docRef.id);
  return docRef;
}

//
// async function createMemberships(
//   membershipDocs: Membership_CreateAPI[],
// ): Promise<any> {
//   const auth = getAuth();
//   const user = auth.currentUser;
//   if (!user) throw Error("No Firebase user was found");
//
//   const idToken = await getIdToken(user);
//
//   const endpoint = "membership";
//   const fullApiRoute = `${apiBaseURL}/${endpoint}`;
//
//   try {
//     const response = await axios.post(fullApiRoute, membershipDocs, {
//       headers: { authorization: `Bearer ${idToken}` },
//     });
//     console.log("response, db file", response);
//     return response.data.result;
//   } catch (err) {
//     if (axios.isAxiosError(err)) {
//       devLogger.info(err.response?.data);
//       devLogger.info(err.response?.status);
//       devLogger.info(err.response?.headers);
//       throw err;
//     }
//     throw err;
//   }
// }

/**
 * Update asset doc.
 * @returns string[], containing document IDs
 */
async function updateAsset(siteKey: string, data: DocumentData): Promise<void> {
  const db = getFirestore();
  const docPath = doc(db, "siteKeys", siteKey, "assets", data.id);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, refPath, ...rest } = data;
  await updateDoc(docPath, rest);
  return;
}

/**
 * Delete asset doc.
 * @returns string[], containing document IDs
 */
async function deleteAsset(siteKey: string, assetID: string): Promise<void> {
  const db = getFirestore();
  const docPath = doc(db, "siteKeys", siteKey, "assets", assetID);
  await deleteDoc(docPath);
  return;
}

/**
 * Update membership doc.
 */
async function updateMembership(
  siteKey: string,
  id: string,
  /** id and refPath should not be included. */
  data: DocumentData,
): Promise<void> {
  const db = getFirestore();
  const docPath = doc(db, "siteKeys", siteKey, "memberships", id);
  await updateDoc(docPath, data);
}

/** Delete a membership doc. (Set status to 'canceled') */
async function deleteMembership(
  siteKey: string,
  membershipID: string,
): Promise<void> {
  const db = getFirestore();
  const docPath = doc(db, "siteKeys", siteKey, "memberships", membershipID);
  await updateDoc(docPath, { status: MembershipStatus.Canceled });
}

/**
 * Returns the membership(s) for the given templateId, customerID and CustomerLocationID, in the membership collection for the given siteKey.
 */
async function getMembershipsByTemplateID(
  siteKey: string,
  customerID: string,
  customerLocationID: string | null,
  templateID: string,
): Promise<ExistingMembership[]> {
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    siteKey,
    "memberships",
  );
  const q = query(
    collectionReference,
    where("customerID", "==", customerID),
    where("customerLocationID", "==", customerLocationID),
    where("membershipTemplateID", "==", templateID),
    where("status", "!=", "canceled"),
    orderBy("status"),
    orderBy("timestampCreated", "asc"), //I want to get the oldest docs first
  );

  // Await the asynchronous function, returns a QuerySnapshot object from database.
  const querySnapshot = await getDocs(q);

  // For each document snapshot in the query snapshot, apply the function.
  const membershipList = querySnapshot.docs.map((snapshot) =>
    MembershipManager.createFromFirestoreSnapshot(snapshot),
  );
  return membershipList;
}

/**
 * Returns the membership(s) for the given templateId, customerID and CustomerLocationID, in the membership collection for the given siteKey.
 */
async function getMembershipsByCustomerID(
  siteKey: string,
  customerID: string,
): Promise<ExistingMembership[]> {
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    siteKey,
    "memberships",
  );
  const q = query(
    collectionReference,
    where("customerID", "==", customerID),
    where("status", "!=", "canceled"),
    orderBy("status"),
    orderBy("timestampCreated", "asc"), //I want to get the oldest docs first
  );

  // Await the asynchronous function, returns a QuerySnapshot object from database.
  const querySnapshot = await getDocs(q);

  // For each document snapshot in the query snapshot, apply the function.
  const membershipList = querySnapshot.docs.map((snapshot) =>
    MembershipManager.createFromFirestoreSnapshot(snapshot),
  );
  return membershipList;
}

/**
 * Returns the membership(s) for the given templateId, customerID and CustomerLocationID, in the membership collection for the given siteKey.
 */
async function getAssetsByCustomerID(
  siteKey: string,
  customerID: string,
): Promise<ExistingAsset[]> {
  const db = getFirestore();
  const collectionReference = collection(db, "siteKeys", siteKey, "assets");
  const q = query(collectionReference, where("customerID", "==", customerID));

  // Await the asynchronous function, returns a QuerySnapshot object from database.
  const querySnapshot = await getDocs(q);

  // For each document snapshot in the query snapshot, apply the function.
  const equipmentList = querySnapshot.docs.map((snapshot) =>
    AssetManager.createFromFirestoreSnapshot(snapshot),
  );
  return equipmentList;
}

/**
 * Delete the given task.
 */
async function deleteSingleTask(taskDoc: ExistingTask) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "deleteTask");
  return callable(taskDoc);
}

/**
 * Returns all the craft record docs for the given site key.
 * @throws NotFoundError
 */
async function getAllCraftRecords(
  siteKey: string,
  open: boolean | null,
  craftType: number | null,
): Promise<ExistingCraftRecord[]> {
  const db = getFirestore();

  const queryConstraints = [];

  if (open !== null) {
    queryConstraints.push(where("open", "==", open));
  }
  if (craftType !== null) {
    queryConstraints.push(where("craftType", "==", craftType));
  }
  const q = query(
    collection(db, "siteKeys", siteKey, "parentRecords"),
    ...queryConstraints,
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((snapshot) => {
    return CraftRecordManager.createFromFirestoreSnapshot(snapshot);
  });
}

/**
 * Returns all the craft record docs for the given site key.
 * @throws NotFoundError
 */
async function getAllDeletedCraftRecords(
  siteKey: string,
): Promise<ExistingCraftRecord[]> {
  const db = getFirestore();
  const q = query(collection(db, "siteKeys", siteKey, "deletedParentRecords"));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((snapshot) => {
    return CraftRecordManager.createFromFirestoreSnapshot(snapshot);
  });
}

/**
 * Returns all the task docs for the given site key.
 * @throws NotFoundError
 */
async function getAllDeletedTasks(
  siteKey: string,
  startDate: Date,
  endDate: Date,
): Promise<ExistingTask[]> {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", siteKey, "deletedTasks"),
    where("timestampCreated", "<", Timestamp.fromDate(endDate)),
    where("timestampCreated", ">=", Timestamp.fromDate(startDate)),
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((snapshot) => {
    return TaskRecordManager.createFromFirestoreSnapshot(snapshot);
  });
}

/**
 * Restore a deleted task.
 */
async function restoreTask(refPath: string) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "restoreTask");
  return callable({ refPath });
}

/**
 * Restore a deleted work record.
 */
async function restoreWorkRecord(refPath: string) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "restoreParentRecord");
  return callable({ refPath });
}

/**
 * Returns all the open task docs for the given site key.
 * open tasks are tasks that haven't been completed or canceled yet
 * @throws NotFoundError
 */
async function getAllOpenTasks(
  siteKey: string,
  permissions: SiteKeyUserPermissions,
): Promise<ExistingTask[]> {
  const { isSiteAdmin, isPlantPersonnel } = permissions.permissions;

  const db = getFirestore();

  const queryConstraints = [where("taskStatus", "<", TaskStatus.COMPLETE)];

  if (!isSiteAdmin && !isPlantPersonnel) {
    queryConstraints.push(
      where("assignedCompanyID", "==", permissions.companyID),
    );
  }

  const q = query(
    collection(db, "siteKeys", siteKey, "tasks"),
    ...queryConstraints,
  );

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((snapshot) =>
    TaskRecordManager.createFromFirestoreSnapshot(snapshot),
  );
}

/**
 * Returns all the tasks with status that is one of the provided statuses
 * @throws NotFoundError
 */
async function getAllTasksWithStatus(args: {
  siteKey: string;
  permissions: SiteKeyUserPermissions;
  taskStatus: TaskStatus[];
  startDate: Date | null;
  endDate: Date | null;
  dateParam:
    | "timestampCreated"
    | "timestampLastModified"
    | "timestampScheduled"
    | "timestampTaskCompleted"
    | "timestampTaskStarted"
    | null;
}): Promise<ExistingTask[]> {
  const { isSiteAdmin, isPlantPersonnel } = args.permissions.permissions;

  const db = getFirestore();

  const queryConstraints = [where("taskStatus", "in", args.taskStatus)];

  if (!isSiteAdmin && !isPlantPersonnel) {
    queryConstraints.push(
      where("assignedCompanyID", "==", args.permissions.companyID),
    );
  }

  if (args.startDate && args.dateParam) {
    queryConstraints.push(
      where(args.dateParam as string, ">=", Timestamp.fromDate(args.startDate)),
    );
  }

  if (args.endDate && args.dateParam) {
    queryConstraints.push(
      where(args.dateParam as string, "<", Timestamp.fromDate(args.endDate)),
    );
  }

  const q = query(
    collection(db, "siteKeys", args.siteKey, "tasks"),
    ...queryConstraints,
  );

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((snapshot) =>
    TaskRecordManager.createFromFirestoreSnapshot(snapshot),
  );
}

/**
 * Returns all the tasks with status that is one of the provided statuses
 * @throws NotFoundError
 */
async function getAllMembershipTasks(
  siteKey: string,
  permissions: SiteKeyUserPermissions,
): Promise<ExistingTask[]> {
  const { isSiteAdmin, isPlantPersonnel } = permissions.permissions;

  const db = getFirestore();

  const queryConstraints = [
    where("isMembershipTask", "==", true),
    where("taskStatus", "<", OTaskStatus.COMPLETE),
  ];

  if (!isSiteAdmin && !isPlantPersonnel) {
    queryConstraints.push(
      where("assignedCompanyID", "==", permissions.companyID),
    );
  }

  const q = query(
    collection(db, "siteKeys", siteKey, "tasks"),
    ...queryConstraints,
  );

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((snapshot) =>
    TaskRecordManager.createFromFirestoreSnapshot(snapshot),
  );
}

/**
 * Returns all the tasks with status that is one of the provided statuses
 * @throws NotFoundError
 */
async function getAllByAssignedUser(
  siteKey: string,
  permissions: SiteKeyUserPermissions,
  uid: string,
): Promise<ExistingTask[]> {
  const db = getFirestore();

  const queryConstraints = [
    where("taskSpecificDetails.assignedTo", "array-contains", uid),
    where("taskStatus", "<", OTaskStatus.COMPLETE),
  ];

  const q = query(
    collection(db, "siteKeys", siteKey, "tasks"),
    ...queryConstraints,
  );

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((snapshot) =>
    TaskRecordManager.createFromFirestoreSnapshot(snapshot),
  );
}

/**
 * Returns all the tasks with status that is one of the provided statuses
 * @throws NotFoundError
 */
async function getAllTasksByAssignedUserInDateRange(
  siteKey: string,
  uid: string,
  startOfDayForServiceWindowStart: Timestamp,
  endOfDayForServiceWindowStart: Timestamp,
): Promise<ExistingTask[]> {
  const db = getFirestore();

  const queryConstraints = [
    where("taskSpecificDetails.assignedTo", "array-contains", uid),
    where("timestampScheduled", ">=", startOfDayForServiceWindowStart),
    where("timestampScheduled", "<=", endOfDayForServiceWindowStart),
  ];

  const q = query(
    collection(db, "siteKeys", siteKey, "tasks"),
    ...queryConstraints,
  );

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((snapshot) =>
    TaskRecordManager.createFromFirestoreSnapshot(snapshot),
  );
}

/**
 * Returns all the tasks with timestampCreated within the date range
 * @throws NotFoundError
 */
async function getallTasksCreatedInDateRange(
  siteKey: string,
  startDate: Timestamp,
  endDate: Timestamp,
): Promise<ExistingTask[]> {
  const db = getFirestore();

  const queryConstraints = [
    where("timestampCreated", ">=", startDate),
    where("timestampCreated", "<=", endDate),
  ];

  const q = query(
    collection(db, "siteKeys", siteKey, "tasks"),
    ...queryConstraints,
  );

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((snapshot) =>
    TaskRecordManager.createFromFirestoreSnapshot(snapshot),
  );
}

/**
 * Returns all the tasks that are nextOpportunity or have pased their scheduled
 * date
 * @throws NotFoundError
 */
async function getAllBacklogTasks(
  siteKey: string,
  permissions: SiteKeyUserPermissions,
): Promise<ExistingTask[]> {
  const { isSiteAdmin, isPlantPersonnel } = permissions.permissions;

  const db = getFirestore();

  const queryConstraints = [where("taskStatus", "==", TaskStatus.AWAITING)];

  if (!isSiteAdmin && !isPlantPersonnel) {
    queryConstraints.push(
      where("assignedCompanyID", "==", permissions.companyID),
    );
  }

  const q = query(
    collection(db, "siteKeys", siteKey, "tasks"),
    ...queryConstraints,
  );

  const querySnapshot = await getDocs(q);

  const awaitingTasks = querySnapshot.docs.map((snapshot) =>
    TaskRecordManager.createFromFirestoreSnapshot(snapshot),
  );

  // Filter if the task is nextOpportunity OR if the scheduleDate is before today
  return awaitingTasks.filter((t) => {
    return (
      (t.timestampScheduled &&
        moment(t.timestampScheduled.seconds * 1000).valueOf() <
          moment().startOf("day").valueOf()) ||
      t.nextOpportunity
    );
  });
}

/**
 * Returns all the tasks with taskType that is one of the provided statuses
 * @throws NotFoundError
 */
async function getAllTasksByTaskType(
  siteKey: string,
  permissions: SiteKeyUserPermissions,
  taskType: TaskTypes[],
): Promise<ExistingTask[]> {
  const { isSiteAdmin, isPlantPersonnel } = permissions.permissions;

  const db = getFirestore();

  const queryConstraints = [where("taskType", "in", taskType)];

  if (!isSiteAdmin && !isPlantPersonnel) {
    queryConstraints.push(
      where("assignedCompanyID", "==", permissions.companyID),
    );
  }

  const q = query(
    collection(db, "siteKeys", siteKey, "tasks"),
    ...queryConstraints,
  );

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((snapshot) =>
    TaskRecordManager.createFromFirestoreSnapshot(snapshot),
  );
}

/**
 * Returns all the open urgent tasks for the given site key.
 * @throws NotFoundError
 */
async function getAllUrgentTasks(
  siteKey: string,
  permissions: SiteKeyUserPermissions,
): Promise<ExistingTask[]> {
  const { isSiteAdmin, isPlantPersonnel } = permissions.permissions;

  const db = getFirestore();

  const queryConstraints = [
    where("taskStatus", "<", TaskStatus.COMPLETE),
    where("urgent", "==", true),
  ];

  if (!isSiteAdmin && !isPlantPersonnel) {
    queryConstraints.push(
      where("assignedCompanyID", "==", permissions.companyID),
    );
  }

  const q = query(
    collection(db, "siteKeys", siteKey, "tasks"),
    ...queryConstraints,
  );

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((snapshot) =>
    TaskRecordManager.createFromFirestoreSnapshot(snapshot),
  );
}

/**
 * Delete parent record.
 */
async function deleteParentRecord(refPath: string) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "deleteParentRecord");
  return callable({ refPath });
}

/** Returns zip signedURL */
async function downloadAllPhotosForSingleParentRecord(args: {
  siteKey: string;
  workRecordID: string;
}): Promise<string> {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "getZippedParentRecordPhotos");
  const response = await callable(args);
  if (typeof response.data === "string") return response.data;
  devLogger.error(
    "Expected downloadAllPhotosForSingleParentRecord callable to return a string",
    response,
  );
  throw new Error(`Unable to get zip archive`);
}

/**
 * Returns photo documents with estimateItemID
 */
async function getStiltPhotoByEstimateItemID(args: {
  siteKey: string;
  estimateItemID: string;
}): Promise<ExistingStiltPhoto[]> {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "photos"),
    where("estimateItemID", "==", args.estimateItemID),
  );
  // Await the asynchronous function, returns a QuerySnapshot object from database.
  const querySnapshot = await getDocs(q);

  // For each document snapshot in the query snapshot, apply the function.
  return querySnapshot.docs.map((snapshot) =>
    StiltPhotoManager.createFromFirestoreSnapshot(snapshot),
  );
}

/**
 * Returns photo documents with estimateItemID
 */
function subscribeStiltPhotoByCustomerID(args: {
  siteKey: string;
  customerID: string;
  onChange: (photoList: ExistingStiltPhoto[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "photos"),
    where("customerID", "==", args.customerID),
  );
  return onSnapshot(
    q,
    (querySnap) => {
      const photoList = querySnap.docs.map(
        StiltPhotoManager.createFromFirestoreSnapshot,
      );
      args.onChange(photoList);
    },
    args.onError,
  );
}

/** sends invoice to customer via email */
async function sendInvoiceToCustomerViaEmail(args: {
  siteKey: string;
  invoiceURL: string;
  customerEmailList: string[];
  includeJobPhotos: boolean;
}) {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "email-customer-invoice";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(fullApiRoute, args, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

async function getEstimateData(
  estimateUniqueLink: string,
): Promise<EstimateDataForNonAuthUser | undefined> {
  const functions = getFunctions();
  const getEstimateData = httpsCallable(functions, "getEstimateData");
  const nonAuthEstimateData = await getEstimateData({
    estimateUniqueLink: estimateUniqueLink,
  });
  return EstimateManager.parseNonAuthData(nonAuthEstimateData["data"]);
}

async function generateUniqueEstimateLink(
  args: CreateEstimateUniqueLink & { version: string },
): Promise<string> {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "generateUniqueEstimateLink");
  const response = await callable(args);
  const estimateUniqueLink = response.data;
  if (typeof estimateUniqueLink === "string") {
    return estimateUniqueLink;
  } else {
    throw new Error(
      `Error generating new estimate unique id. estimateUniqueLink: ${estimateUniqueLink}, data: ${JSON.stringify(
        response.data,
        null,
        2,
      )}`,
    );
  }
}

/** Delete an estimate doc */
async function deleteEstimate(
  siteKey: string,
  estimateIDToDelete: ExistingEstimate["id"],
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = `estimate/${siteKey}/${estimateIDToDelete}`;
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.delete(fullApiRoute, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/** send estimate to customer via email */
async function sendEstimateToCustomerViaEmail(args: {
  siteKey: string;
  estimateID: string;
  customerEmailList: string[];
  emailSubject: string;
  emailBody: string;
  version: string;
}) {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "email-customer-estimate";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(fullApiRoute, args, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/** allow a non auth user to update estimate status */
async function updateEstimateStatusByCustomer(args: {
  siteKey: string;
  estimateID: string;
  newStatus: EstimateStatus;
}) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "updateEstimateStatusByCustomer");
  return callable({
    siteKey: args.siteKey,
    estimateID: args.estimateID,
    newStatus: args.newStatus,
  });
}

// async function getInvoiceListByCraftRecordId(
//   siteKey: string,
//   craftRecordID: string,
// ): Promise<ExistingStiltInvoice[]> {
//   const db = getFirestore();
//   const collectionReference = collection(db, "siteKeys", siteKey, "invoices");
//   const q = query(
//     collectionReference,
//     where("craftRecordID", "==", craftRecordID),
//   );

//   // Await the asynchronous function, returns a QuerySnapshot object from database.
//   const querySnapshot = await getDocs(q);

//   // For each document snapshot in the query snapshot, apply the function.
//   const invoiceList = querySnapshot.docs.map((snapshot) =>
//     StiltInvoiceManager.createFromFirestoreSnapshot(snapshot),
//   );
//   return invoiceList;
// }

/** sends receipt to customer via email */
async function emailReceiptToCustomer(args: {
  siteKeyID: string;
  invoiceID: string;
  customerEmailList: string[];
}) {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "email-customer-receipt";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(fullApiRoute, args, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/** sends receipt to customer via email */
async function generateTransactionReport(args: {
  siteKeyID: string;
  // fromDate: string;
  // toDate: string;
}) {
  // ISO date for yesterday at 8:30pm
  const yesterday = moment()
    .subtract(1, "days")
    .startOf("day")
    .add(20, "hours")
    .add(30, "minutes");

  // ISO date for day before yesterday at 8:30pm
  const dayBeforeYesterday = moment()
    .subtract(6, "days")
    .startOf("day")
    .add(20, "hours")
    .add(30, "minutes");

  const functions = getFunctions();
  const callable = httpsCallable(functions, "getCreditCardTransactionReport");
  return callable({
    siteKey: args.siteKeyID,
    fromDate: dayBeforeYesterday.toISOString(),
    toDate: yesterday.toISOString(),
  });
}

async function createTaskForCustomer({
  siteKey,
  taskDoc,
  craftRecordDoc,
  craftRecordID,
  estimateID,
}: {
  siteKey: string;
  taskDoc: DocumentData;
  craftRecordDoc?: DocumentData;
  craftRecordID?: string;
  estimateID: string | null;
}) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "createTaskForCustomer");
  return callable({
    siteKey,
    taskDoc,
    craftRecordDoc,
    craftRecordID,
    estimateID,
  });
}

async function duplicateJob(payload: DuplicateJob) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "duplicateJob");
  return callable(payload);
}

/** Returns feedback form data for an unauthenticated user */
async function getFeedbackFormData(
  uniqueLinkID: string,
): Promise<FeedbackFormData> {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "getFeedbackFormData");
  const feedbackFormData = await callable({ id: uniqueLinkID });
  return FeedbackManager.parseFormData(feedbackFormData["data"]);
}

/**
 * Handle feedback responses for unauthenticated users
 *
 * This is for updating a feedback doc, specifically for unauthenticated users.
 * They're allowed to update a specific subset of the feedback doc. (Don't use
 * this func in places other than the /review-request unauthed route.)
 */
async function handleFeedbackResponse(args: {
  siteKeyID: string;
  feedbackID: string;
  updateData: Partial<FeedbackUpdate>;
}) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "handleFeedbackResponse");
  return callable({
    siteKeyID: args.siteKeyID,
    feedbackID: args.feedbackID,
    ...args.updateData,
  });
}

/**
 * Subscribe to realtime updates for all feedback documents for the given siteKey.
 * @returns removeListeners ƒn for cleanup
 */
function subscribeAllFeedback(args: {
  siteKeyID: string;
  onChange: (feedbackList: ExistingFeedback[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const q = query(collection(db, "siteKeys", args.siteKeyID, "feedbacks"));
  return onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      const feedbackList = querySnap.docs.map(
        FeedbackManager.createFromSnapshot,
      );
      args.onChange(feedbackList);
    },
    args.onError,
  );
}

/**
 * Subscribe to realtime updates for feedback documents matching the given siteKey
 * and work record ID.
 * @returns removeListeners ƒn for cleanup
 */
function subscribeFeedbackByWorkRecordID(args: {
  siteKeyID: string;
  workRecordID: string;
  onChange: (feedbackList: ExistingFeedback[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKeyID, "feedbacks"),
    where("craftRecordID", "==", args.workRecordID),
  );
  return onSnapshot(
    q,
    (querySnap) => {
      // convert snapshots to our local data structure
      const feedbackList = querySnap.docs.map(
        FeedbackManager.createFromSnapshot,
      );
      args.onChange(feedbackList);
    },
    args.onError,
  );
}

/* Merge a customerDoc and all the related docs, into another customer */
async function mergeCustomer(args: {
  siteKey: string;
  customerIDToKeep: ExistingCustomer["id"];
  customerIDToMerge: ExistingCustomer["id"];
}) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "mergeCustomer");
  return callable(args);
}

async function getAllEstimates(siteKey: string): Promise<ExistingEstimate[]> {
  // Create the database object (based on project initialized in init-firesbase.ts)
  const db = getFirestore();
  const collectionReference = query(
    collection(db, "siteKeys", siteKey, "estimates"),
    orderBy("timestampLastModified", "desc"),
  );

  const snapshot = await getDocs(collectionReference);
  return snapshot.docs.map((snap) =>
    EstimateManager.createFromFirestoreSnapshot(snap),
  );
}

function subscribeAllEstimates(
  siteKey: string,
  onChange: (taskDoc: ExistingEstimate[]) => void,
  onError?: (error: FirestoreError) => void,
): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", siteKey, "estimates"),
    where("deleted", "==", false),
    orderBy("timestampLastModified", "desc"),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const estimates: ExistingEstimate[] = querySnapshot.docs.map((snapshot) =>
        EstimateManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      onChange(estimates);
    },
    onError,
  );

  return removeListeners;
}

function subscribeAllEstimatesWithStatus(
  siteKey: string,
  estimateStatus: EstimateStatus[],
  onChange: (taskDoc: ExistingEstimate[]) => void,
  onError?: (error: FirestoreError) => void,
): () => void {
  const db = getFirestore();

  const q = query(
    collection(db, "siteKeys", siteKey, "estimates"),
    where("status", "in", estimateStatus),
    where("deleted", "==", false),
    orderBy("timestampLastModified", "desc"),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const estimates: ExistingEstimate[] = querySnapshot.docs.map((snapshot) =>
        EstimateManager.createFromFirestoreSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      onChange(estimates);
    },
    onError,
  );

  return removeListeners;
}

/** Returns all payments matching the given invoiceID. */
async function getAllPaymentsByInvoiceID(
  siteKeyID: string,
  invoiceID: string,
): Promise<ExistingStiltPayment[]> {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", siteKeyID, "payments"),
    where("invoiceID", "==", invoiceID),
    where("deleted", "==", false),
  );
  const querySnap = await getDocs(q);
  return querySnap.docs.map(StiltPaymentManager.createFromFirestoreSnapshot);
}

/** Returns all payments matching the given invoiceID. */
async function getAllPaymentsByCustomerID(
  siteKeyID: string,
  customerID: string,
): Promise<ExistingStiltPayment[]> {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", siteKeyID, "payments"),
    where("customerID", "==", customerID),
    where("deleted", "==", false),
  );
  const querySnap = await getDocs(q);
  return querySnap.docs.map(StiltPaymentManager.createFromFirestoreSnapshot);
}

async function issueRefundForPayment(
  siteKeyID: string,
  paymentID: string,
  refundAmount: number,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "issue-refund";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(
      fullApiRoute,
      { siteKeyID, paymentID, refundAmount },
      {
        headers: { authorization: `Bearer ${idToken}` },
      },
    );
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/**
 * Add new calendar event doc.
 * @returns A document reference on successful write.
 */
async function addCalendarEvent(
  siteKey: string,
  calendarEventDoc: DocumentData,
): Promise<any> {
  const db = getFirestore();
  const docRef = await addDoc(
    collection(db, "siteKeys", siteKey, "calendarEvents"),
    calendarEventDoc,
  );
  return docRef;
}

interface ISubscribeCalendarEventsByDate {
  siteKey: string;
  permissions: SiteKeyUserPermissions;
  userID: string;
  startDate: Timestamp;
  endDate: Timestamp;
  onChange: (stiltCalendarEventList: ExistingStiltCalendarEvent[]) => void;
  onError?: (error: FirestoreError) => void;
}

/**
 * Subscribe to realtime updates for calendar event documents matching the given siteKey and dates.
 * @returns removeListeners ƒn, for cleanup.
 */
function subscribeCalendarEventByDate(
  args: ISubscribeCalendarEventsByDate,
): () => void {
  const { isSiteAdmin, isPlantPersonnel } = args.permissions.permissions;
  if (isSiteAdmin || isPlantPersonnel) {
    return subscribeCalendarEventByDate_admin(args);
  } else {
    return subscribeCalendarEventByDate_user(args);
  }
}

function subscribeCalendarEventByDate_admin(
  args: ISubscribeCalendarEventsByDate,
): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "calendarEvents"),
    where("start", ">", args.startDate),
    where("start", "<", args.endDate),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const calendarEventList: ExistingStiltCalendarEvent[] =
        querySnapshot.docs.map((snapshot) =>
          StiltCalendarEventManager.createFromFirestoreSnapshot(snapshot),
        );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(calendarEventList);
    },
    args.onError,
  );

  return removeListeners;
}

function subscribeCalendarEventByDate_user(
  args: ISubscribeCalendarEventsByDate,
): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", args.siteKey, "calendarEvents"),
    where("start", ">", args.startDate),
    where("start", "<", args.endDate),
    where("assignedTo", "array-contains", args.userID),
  );

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const calendarEventList: ExistingStiltCalendarEvent[] =
        querySnapshot.docs.map((snapshot) =>
          StiltCalendarEventManager.createFromFirestoreSnapshot(snapshot),
        );
      // Call the provided function, typically to update the UI with the new data.
      args.onChange(calendarEventList);
    },
    args.onError,
  );

  return removeListeners;
}

async function updateCalendarEvent(
  stiltEventID: string,
  siteKey: string,
  updateEventData: DocumentData,
) {
  const db = getFirestore();
  const docPath = doc(db, "siteKeys", siteKey, "calendarEvents", stiltEventID);
  return updateDoc(docPath, updateEventData);
}

async function deleteCalendarEvent(stiltEventID: string, siteKey: string) {
  const db = getFirestore();
  const docPath = doc(db, "siteKeys", siteKey, "calendarEvents", stiltEventID);
  return deleteDoc(docPath);
}

/** Get PDF URL for the given invoice */
// async function getInvoicePDF(args: {
//   siteKeyID: string;
//   invoiceID: string;
//   whiteLabel: string;
// }): Promise<string> {
//   const functions = getFunctions();
//   const callable = httpsCallable(functions, "getInvoicePdfUrl");
//   const response = await callable(args);
//   const url = response.data;
//   if (typeof url === "string") {
//     return url;
//   } else {
//     throw new Error(
//       `URL was not a string. Received response: ${JSON.stringify(
//         response.data,
//         null,
//         2,
//       )}`,
//     );
//   }
// }

/**
 * Returns the customer location(s) for the given billToCustomerID, in the customerLocations collection for the given siteKey.
 */
async function getCustomerLocationsWithSameBillToCustomerLocationID(
  siteKey: string,
  billToCustomerLocationID: string,
): Promise<ExistingCustomerLocation[]> {
  // Create the database object
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    siteKey,
    "customerLocations",
  );
  const q = query(
    collectionReference,
    where("billToCustomerLocationID", "==", billToCustomerLocationID),
    where("deleted", "==", false),
  );
  // Await the asynchronous function, returns a QuerySnapshot object from database.
  const querySnapshot = await getDocs(q);
  // For each document snapshot in the query snapshot, apply the function.
  const locationsList = querySnapshot.docs.map((snapshot) =>
    CustomerLocationManager.createFromFirestoreSnapshot(snapshot),
  );
  // Return the list, but remove the supplied customerLocation (in case a
  // customerLocation has itsself listed as billToCustomerLocationID)
  return locationsList.filter((l) => l.id !== billToCustomerLocationID);
}

async function updateStiltInvoice(
  partialStiltInvoiceDoc: StiltInvoice_UpdateAPI,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "invoice";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.put(fullApiRoute, partialStiltInvoiceDoc, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

async function batchUpdateInvoiceStatus(
  siteKey: string,
  invoiceIDs: string[],
  newStatus: StiltInvoiceStatus,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "invoice/batch/status";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  const data = {
    siteKey,
    invoiceIDs,
    status: newStatus,
  };

  try {
    await axios.put(fullApiRoute, data, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/**
 * Delete an invoice --
 * * Stilt invoice - set status to CANCELED
 * * Codat invoice, if applicable - set status to VOID
 */
async function deleteInvoice(
  siteKey: string,
  invoiceID: string,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = `invoice/${siteKey}/${invoiceID}`;
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.delete(fullApiRoute, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/**
 * Delete a payment --
 * * Set deleted to true
 */
async function deletePayment(
  siteKey: string,
  paymentID: string,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = `payment/${siteKey}/${paymentID}`;
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.delete(fullApiRoute, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/**
 * Send an email with the report's download link to the owner's email
 * address.
 */
async function sendReportDownloadEmail(siteKey: string, reportDataId: string) {
  const functions = getFunctions();
  const emailReportDownloadURL = httpsCallable(
    functions,
    "emailReportDownloadURL",
  );
  return emailReportDownloadURL({ siteKey, reportDataId });
}

/** Return an object of report specifications, keys are report types */
async function getReportTypesWithSpec(
  siteKey: string,
): Promise<Record<string, ReportSpec>> {
  const functions = getFunctions();
  const listReportTypes = httpsCallable(functions, "listReportTypes");

  const result = await listReportTypes({ siteKey });
  return ReportSpecManager.parseResponse(result.data);
}

/**
 * Subscribe to real-time updates for reportConfigs belonging to the specified
 * user. Must pass a callback to execute when data is updated.
 * @param onChange A function to call when data updates.
 * @returns a remove listeners function.
 */
function subscribeReportConfigsByUID(args: {
  siteKey: string;
  uid: string;
  onChange: (configList: ReportConfig[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, `siteKeys/${args.siteKey}/reportConfigs`),
    where("user", "==", args.uid),
  );

  const removeListener = onSnapshot(
    q,
    (querySnapshot) => {
      const configs = querySnapshot.docs.map((docSnapshot) =>
        ReportConfigManager.fromSnapshot(docSnapshot),
      );
      args.onChange(configs);
    },
    args.onError,
  );
  return removeListener;
}

/**
 * Subscribe to real-time updates for which users are subscribed to the given
 * reportType. Must pass a callback to execute when data is updated.
 * @param onChange A function to call when data updates.
 * @returns a remove listeners function.
 */
function subscribleUserListByReportType(args: {
  siteKey: string;
  reportType: string;
  siteKeyUserPermissions: ExistingSiteKeyUserPermissions | null;
  onChange: (users: string[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  if (args.siteKeyUserPermissions?.permissions.isSiteAdmin !== true) {
    args.onChange([]);
    // ^ ^ ^ REFACTOR: see if we can do this check wherever fn is called
  }

  const db = getFirestore();
  const q = query(
    collection(db, `siteKeys/${args.siteKey}/reportConfigs`),
    where("type", "==", args.reportType),
    where("subscribed", "==", true),
  );

  const removeListener = onSnapshot(
    q,
    (querySnapshot) => {
      const users = querySnapshot.docs.map((docSnapshot) => {
        const configDoc = ReportConfigManager.fromSnapshot(docSnapshot);
        return configDoc.user;
      });
      args.onChange(users);
    },
    args.onError,
  );

  return removeListener;
}

/**
 * Subscribe to real-time updates for reportData documents that match the given
 * UID and siteKey. Must pass a callback to execute when data is updated.
 * @param onChange A function to call when data updates.
 * @returns a remove listeners function.
 */
function subscribeReportDataByUID(args: {
  siteKey: string;
  uid: string;
  onChange: (list: ReportData[]) => void;
  onError?: (error: FirestoreError) => void;
}): () => void {
  const db = getFirestore();
  const q = query(
    collection(db, `siteKeys/${args.siteKey}/reportData`),
    where("user", "==", args.uid),
  );

  const removeListener = onSnapshot(
    q,
    (querySnapshot) => {
      const reportDataList = querySnapshot.docs.map((docSnapshot) =>
        ReportDataManager.fromSnapshot(docSnapshot),
      );
      args.onChange(reportDataList);
    },
    args.onError,
  );

  return removeListener;
}

async function saveReportConfig(configuration: ReportConfig, siteKey: string) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "saveReportConfig");

  return callable({
    siteKey: siteKey,
    reportConfig: configuration,
  });
}

async function deleteReportConfig(
  siteKey: string,
  configId: string,
): Promise<void> {
  const functions = getFunctions();
  const deleteReportConfigCallable = httpsCallable(
    functions,
    "deleteReportConfig",
  );

  try {
    await deleteReportConfigCallable({
      siteKey: siteKey,
      reportConfigId: configId,
    });
  } catch (error) {
    console.error("Error deleting report config:", error);
    throw error;
  }
}

async function deleteReportData(
  reportDataId: string,
  siteKey: string,
): Promise<void> {
  const functions = getFunctions();
  const deleteReportDataCallable = httpsCallable(functions, "deleteReportData");

  try {
    await deleteReportDataCallable({
      siteKey: siteKey,
      reportDataId: reportDataId,
    });
  } catch (error) {
    console.error("Error deleting report data:", error);
    throw error;
  }
}

async function generateReportData(args: {
  siteKey: string;
  configuration: ReportConfig;
}): Promise<ReportData> {
  const { siteKey, configuration } = args;
  const functions = getFunctions();
  const generateReportCallable = httpsCallable(functions, "generateReport", {
    timeout: 540000,
  });

  try {
    const response = await generateReportCallable({
      siteKey: siteKey,
      reportConfig: configuration,
    });

    return response.data as ReportData;
  } catch (error) {
    console.error("Error generating report data:", error);
    throw error;
  }
}

/**
 * Set the call status to "cleared" on a call document.
 */
async function clearCallStatus(args: {
  siteKey: string;
  callDocID: string;
}): Promise<void> {
  const db = getFirestore();
  const docPath = doc(db, "siteKeys", args.siteKey, "calls", args.callDocID);
  await updateDoc(docPath, { callStatus: "cleared" });
}

/**
 * Update the callStatus field on a call document.
 */
async function updateCallStatus(args: {
  siteKey: string;
  callDocID: string;
  status: CallStatus;
}): Promise<void> {
  const db = getFirestore();
  const docPath = doc(db, "siteKeys", args.siteKey, "calls", args.callDocID);
  await updateDoc(docPath, { callStatus: args.status });
}

/**
 * From backend Express/TSOA controller
 */
interface UpdateCallCustomerSnapshotRequest {
  siteKey: string;
  /** siteKeys/{siteKey}/calls/{callDocID} */
  callDocID: string;
  /** siteKeys/{siteKey}/customers/{customerID} */
  customerID: string;
}

/**
 * Calls the OpenAPI endpoint to update the customerID, userAgentID, and triggers
 * updating the customerSnapshot on the call document.
 */
async function updateCustomerAndAgent(args: {
  siteKey: string;
  callDocID: string;
  customerID: string;
  /**
   * Optionally update the agentUserID on the call document.
   */
  agentUserID?: string;
}) {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");
  const idToken = await getIdToken(user);

  const endpoint = "calls/updateCallCustomerSnapshot";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  const requestBody: UpdateCallCustomerSnapshotRequest = {
    siteKey: args.siteKey,
    callDocID: args.callDocID,
    customerID: args.customerID,
    // TODO: add agentUserID logic to backend
    // agentUserID: args.agentUserID,
  };

  try {
    await axios.post(fullApiRoute, requestBody, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/**
 * Removes a checklist item from a single craft record.
 */
async function initiateOutboundCall(args: {
  siteKey: string;
  customerPhoneNumber: string;
  customerID: string;
  userSipAccount: string;
}) {
  const functions = getFunctions();
  const callable = httpsCallable(functions, "twilioOutboundCallConference");
  return callable({
    siteKey: args.siteKey,
    customerPhoneNumber: args.customerPhoneNumber,
    customerID: args.customerID,
    userSipAccount: args.userSipAccount,
  });
}

/**
 * List documents in the call collection filtered by input parameters.
 *
 * Drops calls that are unable to be parsed.
 */
async function listCallsQuery(args: {
  siteKey: string;
  callStatus?: string;
  startAt?: Timestamp;
  endAt?: Timestamp;
  /**
   * Ordred by timestampCreated. Defaults to "desc"
   */
  order?: "asc" | "desc";
}): Promise<ExistingStiltPhoneCall[]> {
  const db = getFirestore();

  const DEFAULT_CALL_ORDER = "desc";

  const order = args.order ?? DEFAULT_CALL_ORDER;
  const start = args.startAt ?? Timestamp.now();
  const end =
    args.endAt ??
    Timestamp.fromDate(DateTime.now().minus({ days: 30 }).toJSDate());

  console.log({
    order,
    start,
    end,
  });

  // Build the query constaints
  const queryConstraints: QueryConstraint[] = [];

  if (args.callStatus) {
    queryConstraints.push(where("callStatus", "==", args.callStatus));
  }
  queryConstraints.push(orderBy("timestampCreated", order));
  queryConstraints.push(startAt(start));
  queryConstraints.push(endAt(end));

  // Create the query
  const coll = collection(db, "siteKeys", args.siteKey, "calls");
  const q = query(coll, ...queryConstraints);

  const querySnapshot = await getDocs(q);

  console.log("querySnapshot", querySnapshot.docs.length);

  const calls: ExistingStiltPhoneCall[] = [];
  for (const doc of querySnapshot.docs) {
    try {
      const call = StiltPhoneCallManager.createFromFirestoreSnapshot(doc);
      calls.push(call);
    } catch (err) {
      devLogger.error("Error parsing call doc:", err);
    }
  }

  return calls;
}

/**
 * Separate function to return a count of documents in the call collection.
 *
 * Used for pagination UI elements.
 */
async function countListCallsQuery(args: {
  siteKey: string;
  callStatus?: string;
}): Promise<number> {
  const db = getFirestore();
  const coll = collection(db, "siteKeys", args.siteKey, "calls");
  const queryConstraints: QueryConstraint[] = [];
  if (args.callStatus) {
    queryConstraints.push(where("callStatus", "==", args.callStatus));
  }
  const q = query(coll, ...queryConstraints);
  const result = await getCountFromServer(q);
  return result.data().count;
}

async function getSingleCall(args: {
  siteKey: string;
  callDocId: string;
}): Promise<ExistingStiltPhoneCall> {
  const db = getFirestore();
  const docPath = doc(db, "siteKeys", args.siteKey, "calls", args.callDocId);
  const docSnap = await getDoc(docPath);
  return StiltPhoneCallManager.createFromFirestoreSnapshot(docSnap);
}

async function sendCustomEmail(args: {
  siteKey: string;
  customerEmailList: string[];
  emailSubject: string;
  emailBody: string;
  customerID: string;
}) {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "custom-email";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(fullApiRoute, args, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

async function getAllCalendarEventsByAssignedUserForOverlap(
  siteKey: string,
  uid: string,
  startOfDayForServiceWindowStart: Timestamp,
  endOfDayForServiceWindowStart: Timestamp,
): Promise<StiltCalendarEvent[]> {
  const db = getFirestore();

  // We want to widen the time range in case a calendar event has someone blocked out for a whole week or for multiple days

  // go back 30 days from the start of the service window
  const startOfDayForServiceWindowStartMinus30Days = Timestamp.fromDate(
    DateTime.fromMillis(startOfDayForServiceWindowStart.toMillis())
      .minus({ days: 30 })
      .toJSDate(),
  );
  // go ofrward 30 days from the end of the service window
  const endOfDayForServiceWindowStartPlus30Days = Timestamp.fromDate(
    DateTime.fromMillis(endOfDayForServiceWindowStart.toMillis())
      .plus({ days: 30 })
      .toJSDate(),
  );

  const queryConstraints = [
    where("assignedTo", "array-contains", uid),
    where("start", ">=", startOfDayForServiceWindowStartMinus30Days),
    where("end", "<=", endOfDayForServiceWindowStartPlus30Days),
  ];

  const q = query(
    collection(db, "siteKeys", siteKey, "calendarEvents"),
    ...queryConstraints,
  );

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((snapshot) =>
    StiltCalendarEventManager.createFromFirestoreSnapshot(snapshot),
  );
}

/* Add new vehicle */
async function createVehicle(vehicleDoc: Vehicle_CreateAPI): Promise<void> {
  console.log("vehicleDoc", vehicleDoc);
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "vehicle";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(fullApiRoute, vehicleDoc, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/* Update a vehicle doc */
async function updateVehicle(partialVehicle: Vehicle_UpdateAPI): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "vehicle";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.put(fullApiRoute, partialVehicle, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/* Delete a vehicle doc (set delete to true) */
async function deleteVehicle(
  siteKey: string,
  vehicleID: ExistingVehicle["id"],
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = `vehicle/${siteKey}/${vehicleID}`;
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.delete(fullApiRoute, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/* Add new vehicle */
async function createZone(zoneDoc: Zone_CreateAPI): Promise<void> {
  console.log("zoneDoc", zoneDoc);
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "zone";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(fullApiRoute, zoneDoc, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/* Update a vehicle doc */
async function updateZone(partialZone: Zone_UpdateAPI): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "zone";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.put(fullApiRoute, partialZone, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/* Delete a vehicle doc (set delete to true) */
async function deleteZone(
  siteKey: string,
  zoneID: ExistingZone["id"],
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = `zone/${siteKey}/${zoneID}`;
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.delete(fullApiRoute, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

interface ISubscribeDailyAssignmentInDateRange {
  siteKey: string;
  isoDate: string;
  onChange: (assignedRoute: ExistingAssignedRoute["vehicleUsersMap"]) => void;
  onError?: (error: FirestoreError) => void;
}

function subscribeDailyVehicleUsersMap(
  params: ISubscribeDailyAssignmentInDateRange,
): () => void {
  const db = getFirestore();
  const docRef = doc(
    db,
    "siteKeys",
    params.siteKey,
    "assignedRoutes",
    params.isoDate,
  );

  const removeListeners = onSnapshot(
    docRef,
    (snapshot) => {
      if (snapshot.data()) {
        const assignedRoute =
          AssignedRouteManager.createFromFirestoreSnapshot(snapshot);
        params.onChange(assignedRoute.vehicleUsersMap);
      } else {
        // if the assignedRoute doesn't exist yet, send an empty object as value
        params.onChange({});
      }
    },
    params.onError,
  );

  return removeListeners;
}

/* Update a assignedRoutes vehicleUsersMap */
async function updateVehicleUsersMap(
  assignedRoute: AssignedRoute_UpdateAPI,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "assigned-route";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.put(fullApiRoute, assignedRoute, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/**
 * 'Delete' the given note document. (Sets the deleted field to true.)
 */
async function deleteNote(siteKey: string, noteID: string): Promise<void> {
  const db = getFirestore();
  const docPath = doc(db, "siteKeys", siteKey, "notes", noteID);
  await updateDoc(docPath, { deleted: true });
}

/**
 * Update the given note document.
 */
async function updateNote(
  siteKey: string,
  noteID: string,
  updateData: DocumentData,
): Promise<void> {
  const db = getFirestore();
  const docPath = doc(db, "siteKeys", siteKey, "notes", noteID);
  await updateDoc(docPath, updateData);
}

interface ISubscribeAllGLAccounts {
  siteKey: string;
  onChange: (gLAccountList: ExistingGLAccount[]) => void;
  onError?: (error: FirestoreError) => void;
}

async function subscribeAllGLAccounts(
  params: ISubscribeAllGLAccounts,
): Promise<() => void> {
  const db = getFirestore();
  const collectionReference = collection(
    db,
    "siteKeys",
    params.siteKey,
    "generalLedgerAccounts",
  );

  const q = query(collectionReference, where("deleted", "==", false));

  const removeListeners = onSnapshot(
    q,
    (querySnapshot) => {
      // Convert the Firestore snapshots to our local data structures.
      const gLAccountList: ExistingGLAccount[] = querySnapshot.docs.map(
        (snapshot: DocumentSnapshot<DocumentData, DocumentData>) =>
          GLAccountManager.fromSnapshot(snapshot),
      );
      // Call the provided function, typically to update the UI with the new data.
      params.onChange(gLAccountList);
    },
    params.onError,
  );

  return removeListeners;
}

/* Add new general ledger account */
async function createGLAccount(
  gLAccountDoc: GLAccount_CreateAPI,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "general-ledger-account";
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(fullApiRoute, gLAccountDoc, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

async function createPaymentWithSavedCard(
  paymentData: APIPaymentSavedCard,
  paymentProcessor: "zift" | "paya",
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = `paymentSavedCard/${paymentProcessor}`;
  const fullApiRoute = `${apiBaseURL}/${endpoint}`;

  try {
    await axios.post(fullApiRoute, paymentData, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

/** Returns list of invoices matching the given invoiceNumber. */
async function getInvoiceByInvoiceNumber(
  siteKeyID: string,
  invoiceNumber: string,
): Promise<ExistingStiltInvoice[]> {
  const db = getFirestore();
  const q = query(
    collection(db, "siteKeys", siteKeyID, "invoices"),
    where("invoiceNumber", "==", invoiceNumber),
  );
  const querySnap = await getDocs(q);
  return querySnap.docs.map(StiltInvoiceManager.createFromFirestoreSnapshot);
}

/** Launches the two step process of connecting a Stilt-user's QBO company to the Stilt QBO App. */
async function connectToQBO(siteKeyID: string): Promise<string> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "qbo/getAuthUri";
  const fullApiRoute = `${apiBaseURL}/${endpoint}/${siteKeyID}`;

  try {
    const response = await axios.get(fullApiRoute, {
      headers: { authorization: `Bearer ${idToken}` },
    });
    // console.log(response);
    if (typeof response.data === "string") {
      return response.data;
    } else {
      throw Error(
        `Expected string, received ${JSON.stringify(response.data, null, 2)}`,
      );
    }
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

async function disconnectFromQBO(siteKeyID: string): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "qbo/disconnect";
  const fullApiRoute = `${apiBaseURL}/${endpoint}/${siteKeyID}`;

  try {
    await axios.get(fullApiRoute, {
      headers: { authorization: `Bearer ${idToken}` },
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}

async function retryCollectDues(
  siteKey: string,
  membershipID: string,
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("No Firebase user was found");

  const idToken = await getIdToken(user);

  const endpoint = "membership/collect-dues";
  const fullApiRoute = `${apiBaseURL}/${endpoint}/${siteKey}/${membershipID}`;

  try {
    await axios.post(
      fullApiRoute,
      {},
      {
        headers: { authorization: `Bearer ${idToken}` },
      },
    );
  } catch (err) {
    if (axios.isAxiosError(err)) {
      devLogger.info(err.response?.data);
      devLogger.info(err.response?.status);
      devLogger.info(err.response?.headers);
      throw err;
    }
    throw err;
  }
}
