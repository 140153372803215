// Libs
import { ChangeEvent, MouseEventHandler, useRef, useState } from "react";
import {
  ArrowDownTrayIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import Gallery from "react-image-gallery";

// Local
import { StyledTooltip } from "../StyledTooltip";
import { createToastMessageID } from "../../utils";
import { useToastMessageStore } from "../../store/toast-messages";
import { logger } from "../../logging";
import * as strings from "../../strings";
import LoadingSpinner from "../LoadingSpinner";
import placeholder_image from "../../images/no-image-placeholder.png";

//Styles
import "./PhotoViewer.css";

interface Props {
  photos: ValidPhoto[];
  handleDeletePhoto: (id: string) => Promise<void>;
  handleUploadPhoto: (args: {
    files: FileList;
    setIsUploading: (val: boolean) => void;
  }) => void;
  handleDownloadPhotos: () => Promise<void>;
}

export type ValidPhoto = {
  id: string;
  original: string;
  thumbnail: string;
  description?: string;
};

export default function PhotoViewer(props: Props): JSX.Element {
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );

  const [isDeletingPhoto, setIsDeletingPhoto] = useState(false);
  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false);
  const [isDownloadingPhotos, setIsDownloadingPhotos] = useState(false);
  const photoRef = useRef<Gallery>(null);

  async function onDeletePhoto(index: number) {
    const currentPhoto = props.photos[index];

    setIsDeletingPhoto(true);

    try {
      await props.handleDeletePhoto(currentPhoto.id);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.successfulDelete("Photo"),
        dialog: false,
        type: "success",
      });
    } catch (e) {
      logger.error(
        `error deleting work record image, ID: ${currentPhoto.id} .. error:`,
        e,
      );
      addToastMessage({
        id: createToastMessageID(),
        message: strings.failedDelete("photo"),
        dialog: false,
        type: "error",
      });
    } finally {
      setIsDeletingPhoto(false);
    }
  }

  function onUploadPhoto(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      setIsUploadingPhoto(true);

      props.handleUploadPhoto({
        files: event.target.files,
        setIsUploading: setIsUploadingPhoto,
      });
    }
  }

  async function onDownloadAll() {
    setIsDownloadingPhotos(true);

    try {
      await props.handleDownloadPhotos();
      addToastMessage({
        id: createToastMessageID(),
        message: strings.successfulDownload("photos"),
        dialog: false,
        type: "success",
      });
    } catch (e) {
      logger.error("Error downloading work record photos:", e);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.failedDownload("photos"),
        dialog: false,
        type: "error",
      });
    } finally {
      setIsDownloadingPhotos(false);
    }
  }

  function renderLeftArrow(
    onClick: MouseEventHandler<HTMLButtonElement>,
    disabled: boolean,
  ): JSX.Element {
    return (
      <button
        disabled={disabled}
        onClick={onClick}
        className={`${sharedButtonStyles} left-3 top-1/2 -translate-y-1/2`}
      >
        <ChevronLeftIcon aria-label="Previous Slide" className="h-6 w-6" />
      </button>
    );
  }

  function renderRightArrow(
    onClick: MouseEventHandler<HTMLButtonElement>,
    disabled: boolean,
  ): JSX.Element {
    return (
      <button
        disabled={disabled}
        onClick={onClick}
        className={`${sharedButtonStyles} right-3 top-1/2 -translate-y-1/2`}
      >
        <ChevronRightIcon aria-label="Next Slide" className="h-6 w-6" />
      </button>
    );
  }

  const sharedButtonStyles =
    "absolute z-30 rounded-full bg-gray-700/70 text-gray-300 transition hover:bg-gray-800/90 focus:ring-primaryLight focus:ring-offset-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2";

  return (
    <div className="relative flex min-w-[450px] max-w-[650px] flex-col items-center justify-start px-4 pt-2 md:pt-1">
      {props.photos.length > 0 ? (
        <Gallery
          additionalClass="max-w-[400px]"
          items={props.photos}
          // infinite={false}
          showPlayButton={false}
          onErrorImageURL="Could not load image."
          startIndex={0}
          showIndex={false}
          renderLeftNav={renderLeftArrow}
          renderRightNav={renderRightArrow}
          ref={photoRef}
        />
      ) : (
        <img
          src={placeholder_image}
          alt="placeholder image for gallery"
          className="w-full flex-shrink-0 object-cover"
        />
      )}

      <div className="absolute bottom-2 left-4 flex gap-5">
        {/* CREATE */}
        <StyledTooltip title={strings.UPLOAD_PHOTO}>
          <label htmlFor="upload-photo">
            {!isUploadingPhoto ? (
              <PlusIcon
                className="h-9 w-9 cursor-pointer rounded-full bg-gray-500 p-1.5 text-white transition-colors hover:bg-gray-600 sm:h-10 sm:w-10"
                aria-label={strings.UPLOAD_PHOTO}
              />
            ) : (
              <span className="block h-9 w-9 rounded-full bg-gray-500 p-2 sm:h-10 sm:w-10">
                <LoadingSpinner
                  marginClass="m-0"
                  colorClass="text-white"
                  sizeClass="h-5 w-5 sm:h-6 sm:w-6"
                />
              </span>
            )}
          </label>
        </StyledTooltip>
        <input
          id="upload-photo"
          type="file"
          onChange={(ev) => onUploadPhoto(ev)}
          accept="image/jpg, image/jpeg, image/png"
          className="hidden"
          multiple
        />

        {/* DELETE */}
        {props.photos.length > 0 && (
          <button
            className={`rounded-full bg-red-50 transition-colors ${
              isDeletingPhoto ? "cursor-auto" : "hover:bg-red-100"
            }`}
            disabled={isDeletingPhoto}
            onClick={async () => {
              const currentIndex = photoRef.current?.getCurrentIndex();
              if (currentIndex == null) return;
              await onDeletePhoto(currentIndex);
            }}
          >
            {isDeletingPhoto ? (
              <span className="block w-9 p-2 sm:w-10">
                <LoadingSpinner
                  marginClass="m-0"
                  colorClass="text-red-700"
                  sizeClass="h-5 w-5 sm:h-6 sm:w-6"
                />
              </span>
            ) : (
              <StyledTooltip title={strings.DELETE_PHOTO}>
                <TrashIcon
                  aria-label="delete photo"
                  className="w-9 flex-shrink-0 fill-current p-2 text-red-700 sm:w-10"
                />
              </StyledTooltip>
            )}
          </button>
        )}
      </div>

      {/* DOWNLOAD ALL PHOTOS */}
      <div className="absolute bottom-2 right-4 flex">
        {props.photos.length > 0 && (
          <button
            className={`rounded-full bg-gray-500 transition-colors ${
              isDownloadingPhotos ? "cursor-auto" : "hover:bg-gray-600"
            }`}
            disabled={isDownloadingPhotos}
            onClick={onDownloadAll}
          >
            {isDownloadingPhotos ? (
              <span className="block w-9 p-2 sm:w-10">
                <LoadingSpinner
                  marginClass="m-0"
                  colorClass="text-white"
                  sizeClass="h-5 w-5 sm:h-6 sm:w-6"
                />
              </span>
            ) : (
              <StyledTooltip title={strings.DOWNLOAD_ALL_PHOTOS}>
                <ArrowDownTrayIcon
                  aria-label="download all photos"
                  className="w-9 flex-shrink-0 fill-current p-2 text-white sm:w-10"
                />
              </StyledTooltip>
            )}
          </button>
        )}
      </div>
    </div>
  );
}
