//Libs
import { DocumentSnapshot, Timestamp, DocumentData } from "firebase/firestore";
import { z } from "zod";

//Local
import { NotFoundError } from "../error-classes";
import { dropUndefined, guardIsPlainObject } from "../utils";
import { zFallback } from "../utils/zod-fallback";
import {
  CustomerLocation,
  ExistingCustomerLocation,
} from "./customer-location";
import { ExistingCustomerContact } from "./customer-contact";
import {
  AccountingSyncStiltDoc,
  AccountingSyncStiltDocSchema,
  AccountingSyncStiltDocSchemaWithFallbacks,
} from "./accounting-sync";

// #region SECTION: Types & Interfaces
// Tell us if the given value (customer.type) is present in the
// customerTypes array or not.
export function isCustomerType(value: unknown): value is CustomerTypes {
  return customerTypes.includes(value as any);
}

export const customerTypes = ["residential", "commercial"] as const;
// Create the types from the TS array 'customerTypes'.
export type CustomerTypes = (typeof customerTypes)[number];

export interface BillingInfo {
  name: string;
  email: string;
  phone: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  zipCode: string;
  state: string;
}

export const billingInfoSchema: z.ZodType<BillingInfo> = z.object({
  name: z.string(),
  email: z.string(),
  phone: z.string(),
  addressLine1: z.string(),
  addressLine2: z.string(),
  city: z.string(),
  zipCode: z.string(),
  state: z.string(),
});

export interface ServiceAddress {
  addressLine1: string;
  addressLine2: string;
  city: string;
  zipCode: string;
  state: string;
}

export const serviceAddressSchema: z.ZodType<ServiceAddress> = z.object({
  addressLine1: z.string(),
  addressLine2: z.string(),
  city: z.string(),
  zipCode: z.string(),
  state: z.string(),
});

export interface CardOnFile {
  /** payment processor's identifier - allows us to actually charge a card */
  token: string;
  /** MM/YY */
  expiry: string;
  lastFour: number;
  /** name on card */
  name: string;
  type: string;
  isPrimary?: boolean;
}
const cardOnFileSchema: z.ZodType<CardOnFile> = z.object({
  token: z.string().min(1).max(100),
  expiry: z.string().min(1).max(20),
  lastFour: z.number().positive(),
  name: z.string().min(1).max(200),
  type: z.string().min(1).max(50),
  isPrimary: z.boolean().optional(),
});

export interface Customer {
  name: string;
  firstName: string | null;
  lastName: string | null;
  email: string[] | null;
  phone: string[] | null;
  notes: string | null;
  type: CustomerTypes;
  timestampCreated: Timestamp;
  timestampLastModified: Timestamp;
  createdBy: string;
  lastModifiedBy: string;
  tags: string[];
  customData: { [key: string]: any };
  isTaxExempt: boolean;
  isTaxExemptGST?: boolean;
  isTaxExemptPST?: boolean;
  doNotService?: boolean;
  notificationGroups: string[];
  website: string | null;
  quickbooksID: string | null;
  deleted: boolean;
  customerLocations: { [key: string]: any };
  customerContacts?: { [key: string]: any };
  billingInfo?: BillingInfo;
  accountingSync?: AccountingSyncStiltDoc;
  balance?: number;
  defaultSendBookingConfirmationEmail?: boolean;
  defaultSendJobReminderEmail?: boolean;
  soldBy?: string[];
  soldBySalesCommission?: number;
  cardsOnFile?: CardOnFile[];
}

export interface ExistingCustomer extends Customer {
  id: string;
  refPath: string;
}

export interface ExistingCustomerUpdate extends Partial<ExistingCustomer> {
  id: string;
  refPath: string;
  timestampLastModified: Timestamp;
  lastModifiedBy: string;
}

export interface ExistingCustomerWithLocations extends ExistingCustomer {
  customerLocations: ExistingCustomerLocation[];
  membershipTitlesCount: Record<string, number>;
}

/***********************/
// FOR THE API
/***********************/
export type customerWithoutTimestamps = Omit<
  Customer,
  "timestampCreated" | "timestampLastModified"
>;

// Timestamps are added on the backend.
export interface Customer_CreateAPI extends customerWithoutTimestamps {
  siteKey: string;
  /** the uuid will become the document id. */
  uuid: string;
}

// timestampLastModified is added on the backend. (timestampCreated won't change)
export type Customer_UpdateAPI = Omit<
  Partial<ExistingCustomer>,
  "timestampCreated" | "timestampLastModified"
>;
// #endregion Types & Interfaces

// #region SECTION: Functions
/** Utilities for interacting with Customer objects  */
export const CustomerManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingCustomer object.
   */
  createFromFirestoreSnapshot: createCustomerFromFirestoreSnapshot,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateCustomer,
  /** Validate a Customer doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateCustomerWithFallbacks,
  /** Validate a new customer. For the create endpoint. */
  parseCreate: validateCustomer_Create,
  /** Validate an existing customer. For the update endpoint. */
  parseUpdate: validateCustomer_Update,
  convertUpdateForFirestore: convertCustomerUpdateForFirestore,
  fromTypesense: fromTypesense,
};

/**
 * Convert the Document Snapshot into a validated ExistingCustomer object.
 */
function createCustomerFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingCustomer {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();

  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateCustomerWithFallbacks({
      ...snapshotData,
    }),
  };
}

function fromTypesense(hit: any): ExistingCustomerWithLocations {
  const customerLocations: ExistingCustomerLocation[] = [];
  for (const tsCustomerLocation of hit.document.customerLocationsArray) {
    const { ...data } = tsCustomerLocation;
    data["refPath"] = `siteKeys/${hit.siteKey}/customerLocations/${data.id}`;
    customerLocations.push(data);
  }
  const customerContacts: ExistingCustomerContact[] = [];
  if (hit.document.customerContactsArray) {
    for (const tsCustomerContact of hit.document.customerContactsArray) {
      const { ...data } = tsCustomerContact;
      data["refPath"] = `siteKeys/${hit.siteKey}/customerContacts/${data.id}`;
      customerContacts.push(data);
    }
  }

  return {
    customerLocations: customerLocations,
    customerContacts: customerContacts,
    billingInfo: hit.document.billingInfo,
    membershipTitlesCount: {},
    createdBy: hit.document.createdBy,
    customData: hit.document.customData,
    deleted: hit.document.deleted,
    email: hit.document.email,
    firstName: hit.document.firstName ?? null,
    id: hit.document.id,
    isTaxExempt: hit.document.isTaxExempt,
    isTaxExemptGST: hit.document.isTaxExemptGST ?? false,
    isTaxExemptPST: hit.document.isTaxExemptPST ?? false,
    doNotService: hit.document.doNotService ?? false,
    lastModifiedBy: hit.document.lastModifiedBy,
    lastName: hit.document.lastName ?? null,
    name: hit.document.name,
    notes: hit.document.notes ?? null,
    notificationGroups: hit.document.notificationGroups,
    phone: hit.document.phone,
    quickbooksID: hit.document.quickbooksID ?? null,
    refPath: `siteKeys/${hit.document.siteKey}/customers/${hit.document.id}`,
    tags: hit.document.tags,
    timestampCreated: hit.document.timestampCreated,
    timestampLastModified: hit.document.timestampLastModified,
    type: hit.document.type,
    website: hit.document.website ?? null,
  };
}

/** Drop `id` and `refPath` before saving to the database. Drop undefined values */
function convertCustomerUpdateForFirestore(
  customer: ExistingCustomerUpdate,
): DocumentData {
  // Copy before modifying. Don't want to modify original object.
  const local = Object.assign({}, customer);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, refPath, ...rest } = local;
  const result = dropUndefined(rest);
  return result;
}

/** Zod validation schemas */
function validateCustomerWithFallbacks(value: unknown): Customer {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = customerSchemaWithFallbacks.parse(value);
  return result;
}

function validateCustomer(value: unknown): Customer {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = customerSchema.parse(value);
  return result;
}

function validateCustomer_Create(value: unknown): Customer_CreateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return customerSchema_CreateAPI.parse(value);
}

function validateCustomer_Update(value: unknown): Customer_UpdateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return customerSchema_UpdateAPI.parse(value);
}

// #endregion

// #region SECTION: Schemas
// Used when validating data coming from the database.
const customerSchemaWithFallbacks = z.object({
  name: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "CustomerSchemaWithFallbacks: 'name'",
  ),
  firstName: zFallback(
    z.string().max(200).nullable(),
    null,
    "CustomerSchemaWithFallbacks: 'firstName'",
  ),
  lastName: zFallback(
    z.string().max(200).nullable(),
    null,
    "CustomerSchemaWithFallbacks: 'lastName'",
  ),
  email: zFallback(
    z.array(z.string()).nullable(),
    null,
    "CustomerSchemaWithFallbacks: 'email'",
  ),
  phone: zFallback(
    z.array(z.string().max(200)).nullable(),
    null,
    "CustomerSchemaWithFallbacks: 'phone'",
  ),
  notes: zFallback(
    z.string().max(10000).nullable(),
    null,
    "CustomerSchemaWithFallbacks: 'notes'",
  ),
  type: zFallback(
    z.enum(customerTypes),
    "residential",
    "CustomerSchemaWithFallbacks: 'type'",
  ),
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "CustomerSchemaWithFallbacks: 'timestampCreated'",
  ),
  timestampLastModified: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "CustomerSchemaWithFallbacks: 'timestampLastModified'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  createdBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "CustomerSchemaWithFallbacks: 'createdBy'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  lastModifiedBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "CustomerSchemaWithFallbacks: 'lastModifiedBy'",
  ),
  tags: zFallback(
    z.array(z.string().min(0).max(200)),
    [],
    "CustomerSchemaWithFallbacks: 'tags'",
  ),
  customData: zFallback(
    z.record(z.any()),
    {},
    "CustomerSchemaWithFallbacks: 'customData'",
  ),
  customerLocations: zFallback(
    z.record(z.any()),
    {},
    "CustomerSchemaWithFallbacks: 'customerLocations'",
  ),
  customerContacts: zFallback(
    z.record(z.any()).optional(),
    {},
    "CustomerSchemaWithFallbacks: 'customerContacts'",
  ),
  billingInfo: zFallback(
    billingInfoSchema.optional(),
    undefined,
    "CustomerSchemaWithFallbacks: 'billingInfo'",
  ),
  isTaxExempt: zFallback(
    z.boolean(),
    false,
    "CustomerSchemaWithFallbacks: 'isTaxExempt'",
  ),
  isTaxExemptGST: zFallback(
    z.boolean().optional(),
    undefined,
    "CustomerSchemaWithFallbacks: 'isTaxExemptGST'",
  ),
  isTaxExemptPST: zFallback(
    z.boolean().optional(),
    undefined,
    "CustomerSchemaWithFallbacks: 'isTaxExemptPST'",
  ),
  doNotService: zFallback(
    z.boolean().optional(),
    undefined,
    "CustomerSchemaWithFallbacks: 'doNotService'",
  ),
  notificationGroups: zFallback(
    z.array(z.string().min(1).max(200)),
    [],
    "CustomerSchemaWithFallbacks: 'notificationGroups'",
  ),
  website: zFallback(
    z.string().max(200).nullable(),
    null,
    "CustomerSchemaWithFallbacks: 'website'",
  ),
  quickbooksID: zFallback(
    z.string().max(200).nullable(),
    null,
    "CustomerSchemaWithFallbacks: 'quickbooksID'",
  ),
  deleted: zFallback(
    z.boolean(),
    false,
    "CustomerSchemaWithFallbacks: 'deleted'",
  ),
  accountingSync: AccountingSyncStiltDocSchemaWithFallbacks.optional(),
  balance: z.number().optional(),
  defaultSendBookingConfirmationEmail: zFallback(
    z.boolean().optional(),
    false,
    "CustomerSchemaWithFallbacks: 'defaultSendBookingConfirmationEmail'",
  ),
  defaultSendJobReminderEmail: zFallback(
    z.boolean().optional(),
    false,
    "CustomerSchemaWithFallbacks: 'defaultSendJobReminderEmail'",
  ),
  soldBy: zFallback(
    z.array(z.string()).optional(),
    undefined,
    "CustomerSchemaWithFallbacks: 'soldByUser'",
  ),
  soldBySalesCommission: zFallback(
    z.number().optional(),
    undefined,
    "CustomerSchemaWithFallbacks: 'soldByUserSalesCommission'",
  ),
  cardsOnFile: zFallback(
    z.array(cardOnFileSchema).optional(),
    undefined,
    "CustomerSchemaWithFallbacks: 'cardsOnFile'",
  ),
});

// Used when writing to the DB or reading from the user.
const customerSchema = z.object({
  name: z.string().min(1).max(200),
  firstName: z.string().max(200).nullable(),
  lastName: z.string().max(200).nullable(),
  email: z.array(z.string().email()).nullable(),
  phone: z.array(z.string().max(200)).nullable(),
  notes: z.string().max(10000).nullable(),
  type: z.enum(customerTypes),
  timestampCreated: z.instanceof(Timestamp),
  timestampLastModified: z.instanceof(Timestamp),
  createdBy: z.string().min(1).max(200),
  lastModifiedBy: z.string().min(1).max(200),
  tags: z.array(z.string().min(1).max(200)),
  customData: z.record(z.any()),
  isTaxExempt: z.boolean(),
  isTaxExemptGST: z.boolean().optional(),
  isTaxExemptPST: z.boolean().optional(),
  doNotService: z.boolean().optional(),
  notificationGroups: z.array(z.string().min(1).max(200)),
  website: z.string().max(200).nullable(),
  quickbooksID: z.string().max(200).nullable(),
  deleted: z.boolean(),
  customerLocations: z.record(z.any()),
  customerContacts: z.record(z.any()).optional(),
  billingInfo: billingInfoSchema.optional(),
  accountingSync: AccountingSyncStiltDocSchema.optional(),
  balance: z.number().optional(),
  defaultSendBookingConfirmationEmail: z.boolean().optional(),
  defaultSendJobReminderEmail: z.boolean().optional(),
  soldBy: z.array(z.string()).optional(),
  soldBySalesCommission: z.number().optional(),
  cardsOnFile: z.array(cardOnFileSchema).optional(),
});

/***********************/
// FOR THE API
/***********************/
const withoutTimestampsSchema = customerSchema.omit({
  timestampCreated: true,
  timestampLastModified: true,
});

// Used for interacting with the Create endpoint.
const customerSchema_CreateAPI = withoutTimestampsSchema.extend({
  siteKey: z.string().min(1).max(400),
  uuid: z.string().min(1).max(400),
});

// Used for interacting with the Update endpoint
const customerSchema_UpdateAPI = withoutTimestampsSchema
  .extend({
    id: z.string().min(1).max(200),
    refPath: z.string().min(1).max(400),
  })
  .partial();

export const existingCustomerSchema = customerSchema.extend({
  id: z.string().min(1).max(200),
  refPath: z.string().min(1).max(400),
});

// #endregion

export function getBillingInfoFromCustomerAndLocations(
  customer: Customer,
  customerLocations: CustomerLocation[],
) {
  if (customer.billingInfo) {
    return customer.billingInfo;
  }
  return {
    name: customer.name,
    email: customer.email ? (customer.email[0] ?? "") : "",
    phone: customer.phone ? (customer.phone[0] ?? "") : "",
    addressLine1: customerLocations[0]?.addressLine1 ?? "",
    addressLine2: customerLocations[0]?.addressLine2 ?? "",
    city: customerLocations[0]?.city ?? "",
    zipCode: customerLocations[0]?.zipCode ?? "",
    state: customerLocations[0]?.state ?? "",
  };
}
