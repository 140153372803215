import { DocumentSnapshot, Timestamp } from "firebase/firestore";
import { NotFoundError } from "../error-classes";
import { guardIsPlainObject } from "../utils";
import { z } from "zod";

export const KpiManager = {
  createFromFirestoreSnapshot: createKpiFromFirestoreSnapshot,
};

// siteKeys/{siteKey}/kpisV2/{kpiDocID}
export interface KpiDocument {
  date: Timestamp;
  dateString: string; // YYYYMMDD
  kpiType: string;
  collection: KpiCollection;
  categoryType: KpiCategoryType;
  customData: Record<string, any>;
  commonAxisValues: string[] | null; // so we can query kpiDocs that contain these values
  data: Record<string, any>[];
  dataByLocationID?: Record<string, Record<string, any>[]>;
}

export interface ExistingKpiDocument extends KpiDocument {
  id: string;
  refPath: string;
}

const kpiCollections = ["tasks", "invoices"] as const;

type KpiCollection = (typeof kpiCollections)[number];

const kpiCategoryTypes = [
  "raw",
  "userID",
  "taskType",
  "craftType",
  "account",
  "custom",
] as const;

export type KpiCategoryType = (typeof kpiCategoryTypes)[number];

export const chartTypes = [
  "barChart",
  "barChartForAll",
  "barChartByCategory",
  "stackedBarChart",
  "donutChartWithTotal",
  "donutChartWithAverage",
  "donutChartWithTotalAndAverage",
] as const;

export type ChartType = (typeof chartTypes)[number];

type FieldsOfInterest = string[];

export interface KpiSpec {
  // The handler function will be the function that gets called to actually
  // update the kpiDoc. This should contain the math and logic to compute
  // various values/metrics
  handlerFunction: (args: {
    siteKey: string;
    dateString: string;
  }) => Promise<KpiDocument | ExistingKpiDocument>;
  fieldsOfInterest: FieldsOfInterest;
  collection: KpiCollection;
  categoryType: KpiCategoryType;
  commonAxisField: string;
  validationFunction?: () => boolean; // Optional validation function
  reportTitle: string;
  header: string;
  subheader: string;
  formatting: "integer" | "currency" | "percent" | "double";
  chartTypes: ChartType[];
}

function createKpiFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingKpiDocument {
  if (!snapshot.exists) {
    throw new NotFoundError("Document does not exist.");
  }
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateKpiDocument(snapshot.data()),
  };
}

function validateKpiDocument(value: unknown): KpiDocument {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = kpiDocumentSchema.parse(value);
  return result;
}

// Used when writing to the DB or reading from the user.
const kpiDocumentSchema = z.object({
  date: z.instanceof(Timestamp),
  dateString: z.string(),
  kpiType: z.string(),
  collection: z.enum(kpiCollections),
  categoryType: z.enum(kpiCategoryTypes),
  customData: z.record(z.unknown()),
  commonAxisValues: z.array(z.string()).nullable(),
  data: z.array(z.record(z.unknown())),
  dataByLocationID: z.record(z.array(z.record(z.unknown()))).optional(),
});
