//Libs
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useState,
} from "react";
import { DocumentData } from "firebase/firestore";
import { useMutation } from "react-query";
import { User } from "firebase/auth";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

//Local
import { useMembershipTemplatesStore } from "../../store/membership-templates";
import AddEditMembershipTemplatePage from "./AddEditMembershipTemplatePage";
import {
  AddNewMembershipTemplate,
  ExistingMembershipTemplate,
  MembershipTemplateManager,
  MembershipTemplate_CreateAPI,
  MembershipTemplate_UpdateAPI,
  TaskGeneration,
} from "../../models/membership-template";
import { useAuthStore } from "../../store/firebase-auth";
import { logger } from "../../logging";
import { createToastMessageID } from "../../utils";
import { useToastMessageStore } from "../../store/toast-messages";
import { DbWrite } from "../../database";
import { diffObjects } from "../../assets/js/object-diff";
import { useUserPermissionsStore } from "../../store/user-permissions";
import AddNewEstimateItemSelection from "../../components/estimates/AddNewEstimateItemSelection";
import { useTypesenseStore } from "../../store/typesense";
import { typesensePriceBookItemsQuery } from "../../utils/typesenseQueries";
import { ExistingPriceBookItem } from "../../models/price-book-item";
import { TemporaryEstimateItem } from "../../models/estimate-item";
import { ExistingSiteKey } from "../../models/site-key";
import currencyFormatter from "../../currency";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import * as strings from "../../strings";
import { MEMBERSHIP_TEMPLATES } from "../../urls";
import AddEditTaskGenerationDialog from "./AddEditTaskGenerationDialog";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import { getReadableCraftType } from "../../models/craft-types";
import { getReadableTaskType } from "../../models/task-types";
import { getReadableTaskStatus } from "../../models/task-status";

interface Props {
  siteKey: string;
}

export type TemporaryTaskGeneration = TaskGeneration & { id: string };

export default function AddEditMembershipTemplateContainer({ siteKey }: Props) {
  /* HISTORY & LOCATIONS */
  const navigate = useNavigate();
  function handleGoToMembershipTemplateListPage() {
    navigate(`${MEMBERSHIP_TEMPLATES}`);
    resetPricebookItems();
    setTaskGenerationList([]);
  }
  const location = useLocation();
  const { state } = location;
  const membershipTemplateDoc: ExistingMembershipTemplate | null = state
    ? state.membershipTemplateDoc
    : null;
  /* STORES */
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const addMessage = useToastMessageStore((state) => state.addToastMessage);
  const fetchMembershipTemplateList = useMembershipTemplatesStore(
    (state) => state.fetch,
  );
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const [typesenseSearchKey, typesenseLoading] = useTypesenseStore((state) => [
    state.scopedSearchKey,
    state.loading,
  ]);
  const [siteKeyDoc, siteKeyDocIsLoading] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.loading,
  ]);
  const siteKeyLocationList = useSiteKeyLocationsStore(
    (state) => state.siteKeyLocationList,
  );

  /* USE STATES */
  const [pbItemsFromTypesense, setPbItemsFromTypesense] = useState<
    ExistingPriceBookItem[]
  >([]);

  /* pricebook item id selection section */
  const [pricebookIDSectionOpen, setPricebookIDSectionOpen] =
    useState<boolean>(false);
  const [pricebookItem, setPricebookItem] =
    useState<ExistingPriceBookItem | null>(null);

  /* renewal pricebook item id selection section */
  const [renewalPricebookIDSectionOpen, setRenewalPricebookIDSectionOpen] =
    useState<boolean>(false);
  const [renewalPricebookItem, setRenewalPricebookItem] =
    useState<ExistingPriceBookItem | null>(null);

  /* task generation section */
  const [addEditTaskGenerationDialogOpen, setAddEditTaskGenerationDialogOpen] =
    useState<boolean>(false);
  const [taskGenerationList, setTaskGenerationList] = useState<
    TemporaryTaskGeneration[]
  >([]);
  const [taskGenerationDoc, setTaskGenerationDoc] =
    useState<TemporaryTaskGeneration | null>(null);

  /* USE EFFECTS */
  useLayoutEffect(() => {
    async function getPBItems() {
      if (!typesenseSearchKey) return;
      const pbItems = await typesensePriceBookItemsQuery(
        typesenseSearchKey,
        "",
      );

      setPbItemsFromTypesense(pbItems);
      if (membershipTemplateDoc) {
        const pricebookItem = pbItems.find(
          (pBItem) => pBItem.id === membershipTemplateDoc.priceBookItemID,
        );

        const renewalPricebookItem = pbItems.find(
          (pBItem) =>
            pBItem.id === membershipTemplateDoc.renewalPriceBookItemID,
        );

        const taskGenerationList: TemporaryTaskGeneration[] =
          membershipTemplateDoc.taskGeneration.map((task) => {
            return {
              ...task,
              id: uuidv4(),
            };
          });

        setPricebookItem(pricebookItem ?? null);
        setRenewalPricebookItem(renewalPricebookItem ?? null);
        setTaskGenerationList(taskGenerationList);
      }
    }

    getPBItems();
  }, [typesenseSearchKey, membershipTemplateDoc]);

  /* MUTATIONS */
  /**
   * For adding a membership template on DB
   */
  const mutateAddMembershipTemplate = useMutation(
    async (args: { validMembershipTemplate: MembershipTemplate_CreateAPI }) => {
      await DbWrite.membershipTemplates.create(args.validMembershipTemplate);
    },
    {
      onSuccess: () => {
        handleGoToMembershipTemplateListPage();
        if (siteKeyDoc && userPermissions) {
          return fetchMembershipTemplateList(siteKeyDoc, userPermissions);
        } else return undefined;
      },
    },
  );

  /**
   * For edit an existing membership template on the DB
   */
  const mutateEditMembershipTemplate = useMutation(
    async (args: { editMembershipTemplate: MembershipTemplate_UpdateAPI }) => {
      await DbWrite.membershipTemplates.update(args.editMembershipTemplate);
    },
    {
      onSuccess: () => {
        handleGoToMembershipTemplateListPage();
        if (siteKeyDoc && userPermissions) {
          return fetchMembershipTemplateList(siteKeyDoc, userPermissions);
        } else return undefined;
      },
    },
  );

  /* FUNCTIONS */

  async function handleSaveNewMembershipTemplate(
    formValues: AddNewMembershipTemplate,
  ) {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    const newMembershiptemplate: MembershipTemplate_CreateAPI = {
      ...formValues,
      taskGeneration: taskGenerationList,
      priceBookItemID: pricebookItem?.id ?? null,
      renewalPriceBookItemID: renewalPricebookItem?.id ?? null,
      createdBy: firebaseUser.uid,
      lastModifiedBy: firebaseUser.uid,
      siteKey: siteKey,
    };

    //Validate
    const validatedMembershipTemplate = MembershipTemplateManager.parseCreate(
      newMembershiptemplate,
    );
    logger.info("Validated Membership Template:", validatedMembershipTemplate);

    //DB
    try {
      await mutateAddMembershipTemplate.mutateAsync({
        validMembershipTemplate: validatedMembershipTemplate,
      });
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulAdd(validatedMembershipTemplate.title),
        dialog: false,
        type: "success",
      });
      resetPricebookItems();
      setTaskGenerationList([]);
    } catch (error) {
      logger.error(
        `An error occurred during handleSaveNewMembershipTemplate`,
        error,
      );
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  async function handleEditMembershipTemplate(
    updateMembershipTemplate: Partial<ExistingMembershipTemplate>,
  ) {
    if (membershipTemplateDoc == null) return;

    updateMembershipTemplate["lastModifiedBy"] = firebaseUser.uid;
    updateMembershipTemplate["priceBookItemID"] = pricebookItem?.id;
    updateMembershipTemplate["renewalPriceBookItemID"] =
      renewalPricebookItem?.id;
    updateMembershipTemplate["taskGeneration"] = taskGenerationList;

    const diffMembershipTemplateValues: DocumentData = diffObjects(
      membershipTemplateDoc,
      updateMembershipTemplate,
    ).diff;

    if (Object.keys(diffMembershipTemplateValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    diffMembershipTemplateValues["id"] = membershipTemplateDoc.id;
    diffMembershipTemplateValues["refPath"] = membershipTemplateDoc.refPath;

    /* validate values from the form */
    const validateEditMembershipTemplate =
      MembershipTemplateManager.parseUpdate(diffMembershipTemplateValues);

    try {
      await mutateEditMembershipTemplate.mutateAsync({
        editMembershipTemplate: validateEditMembershipTemplate,
      });
      logger.debug("Membership Template has been updated successfully.");
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(
          validateEditMembershipTemplate.title ?? membershipTemplateDoc.title,
        ),
        dialog: false,
        type: "success",
      });
      resetPricebookItems();
      setTaskGenerationList([]);
    } catch (error) {
      logger.error(
        `An error occurred during handleEditMembershipTemplate`,
        error,
      );
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  function handleSetPricebookItemID(tempEstimateItem: TemporaryEstimateItem) {
    const pbItem = pbItemsFromTypesense.find(
      (pbItem) => pbItem.id === tempEstimateItem.priceBookItemID,
    );
    setPricebookItem(pbItem ?? null);
    setPricebookIDSectionOpen(false);
  }

  function handleSetRenewalPricebookItemID(
    tempEstimateItem: TemporaryEstimateItem,
  ) {
    const pbItem = pbItemsFromTypesense.find(
      (pbItem) => pbItem.id === tempEstimateItem.priceBookItemID,
    );
    setRenewalPricebookItem(pbItem ?? null);
    setRenewalPricebookIDSectionOpen(false);
  }

  function resetPricebookItems() {
    setPricebookItem(null);
    setRenewalPricebookItem(null);
  }

  const onFilterTextBoxChanged = useCallback(
    async (searchTerm: string) => {
      if (!typesenseSearchKey) return;

      const pbItems = await typesensePriceBookItemsQuery(
        typesenseSearchKey,
        searchTerm,
      );
      setPbItemsFromTypesense(pbItems);
    },
    [typesenseSearchKey],
  );

  function handleAddNewTask(newTask: TemporaryTaskGeneration) {
    logger.info("newTask", newTask);
    const copyOfPrevList = [...taskGenerationList];
    copyOfPrevList.push(newTask);
    setTaskGenerationList(copyOfPrevList);
  }

  function handleEditTask(editTask: TemporaryTaskGeneration) {
    const copyOfTaskGenList = [...taskGenerationList];
    const indexToBeEdited = copyOfTaskGenList.findIndex(
      (task) => task.id === editTask.id,
    );
    copyOfTaskGenList[indexToBeEdited] = editTask;
    setTaskGenerationList(copyOfTaskGenList);
  }

  function deleteTask(idToBeDeleted: string) {
    const copyOfTaskGenList = [...taskGenerationList];
    const filteredTaskList = copyOfTaskGenList.filter(
      (task) => task.id !== idToBeDeleted,
    );
    setTaskGenerationList(filteredTaskList);
  }

  function openEditTaskDialog(idToBeEdited: string) {
    const copyOfTaskGenList = [...taskGenerationList];
    const taskToBeEdited = copyOfTaskGenList.find(
      (task) => task.id === idToBeEdited,
    );
    if (taskToBeEdited) {
      setTaskGenerationDoc(taskToBeEdited);
      setAddEditTaskGenerationDialogOpen(true);
    }
  }

  /* COMPONENTS */
  const selectPricebookItemIDSection = getSelectPricebookItemIDSection(
    pricebookItem,
    pricebookIDSectionOpen,
    setPricebookIDSectionOpen,
    onFilterTextBoxChanged,
    pbItemsFromTypesense,
    handleSetPricebookItemID,
    siteKeyDoc,
    setPricebookItem,
  );

  const selectRenewalPricebookItemIDSection =
    getSelectRenewalPricebookItemIDSection(
      renewalPricebookItem,
      renewalPricebookIDSectionOpen,
      setRenewalPricebookIDSectionOpen,
      onFilterTextBoxChanged,
      pbItemsFromTypesense,
      handleSetRenewalPricebookItemID,
      siteKeyDoc,
      setRenewalPricebookItem,
    );

  const addTaskGenerationSection = getAddTaskGenerationSection(
    setAddEditTaskGenerationDialogOpen,
    taskGenerationList,
    deleteTask,
    openEditTaskDialog,
  );

  const addEditTaskGenerationDialog = siteKeyDoc && (
    <AddEditTaskGenerationDialog
      isDialogOpen={addEditTaskGenerationDialogOpen}
      closeDialog={() => {
        setAddEditTaskGenerationDialogOpen(false);
      }}
      taskGenerationDoc={taskGenerationDoc}
      siteKeyDoc={siteKeyDoc}
      siteKeyLocationList={siteKeyLocationList}
      handleAddNewTask={handleAddNewTask}
      handleEditTask={handleEditTask}
    />
  );

  /* RENDER LOADING */
  if (typesenseLoading || siteKeyDocIsLoading) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  return (
    <Fragment>
      <AddEditMembershipTemplatePage
        handleSaveNewMembershipTemplate={handleSaveNewMembershipTemplate}
        handleEditMembershipTemplate={handleEditMembershipTemplate}
        membershipTemplateDoc={membershipTemplateDoc ?? null}
        selectPricebookItemIDSection={selectPricebookItemIDSection}
        selectRenewalPricebookItemIDSection={
          selectRenewalPricebookItemIDSection
        }
        handleGoToMembershipTemplateListPage={
          handleGoToMembershipTemplateListPage
        }
        addTaskGenerationSection={addTaskGenerationSection}
      />
      {addEditTaskGenerationDialog}
    </Fragment>
  );
}

function getSelectPricebookItemIDSection(
  pricebookItem: ExistingPriceBookItem | null,
  pricebookIDSectionOpen: boolean,
  setPricebookIDSectionOpen: Dispatch<SetStateAction<boolean>>,
  onFilterTextBoxChanged: (searchTerm: string) => Promise<void>,
  pbItemsFromTypesense: ExistingPriceBookItem[],
  handleSetPricebookItemID: (tempEstimateItem: TemporaryEstimateItem) => void,
  siteKeyDoc: ExistingSiteKey | null,
  setPricebookItem: Dispatch<SetStateAction<ExistingPriceBookItem | null>>,
): JSX.Element {
  const currency = siteKeyDoc?.customizations.accounting?.currency ?? "USD";

  if (pricebookItem == null) {
    if (pricebookIDSectionOpen === false) {
      return (
        <BaseButtonPrimary
          type="button"
          onClick={() => setPricebookIDSectionOpen(true)}
          className="w-full text-primary sm:w-auto"
        >
          <AddCircleIcon fontSize="small" className="mr-2" />
          {strings.SELECT_PRICEBOOK_ITEM}
        </BaseButtonPrimary>
      );
    } else {
      return (
        <div className="mb-4">
          <AddNewEstimateItemSelection
            onSearch={onFilterTextBoxChanged}
            PBItemQueryResultList={pbItemsFromTypesense}
            handleAddEstimateItem={handleSetPricebookItemID}
            currency={currency}
          />
        </div>
      );
    }
  } else {
    return (
      <div className="relative grid grid-cols-[5fr,2fr,1fr] rounded-2xl border p-4 pt-5">
        <div className="absolute -top-4 left-3 bg-white px-2">
          Pricebook Item ID
        </div>
        {/* TITLE AND DESCRIPTION */}
        <div className="flex w-full items-center">
          <div className="flex flex-col space-y-2">
            <span className="block whitespace-normal text-base font-medium leading-5">
              {pricebookItem.title}
            </span>
            <span className="block whitespace-normal text-sm leading-5 text-gray-400">
              {pricebookItem.description}
            </span>
            {pricebookItem.discountable === false && (
              <p className={`text-sm italic`}>* Non-discountable</p>
            )}
          </div>
        </div>
        {/* AMOUNT */}
        <div className="flex items-center justify-self-end">
          <div className="text-lg font-bold">
            {currencyFormatter(pricebookItem.unitPrice, currency)}
          </div>
        </div>
        {/* DELETE BUTTON */}
        <button
          className="h-fit w-fit place-self-center justify-self-end rounded-full hover:bg-red-50"
          onClick={() => setPricebookItem(null)}
        >
          <TrashIcon
            aria-label="delete button"
            className="block h-10 w-10 cursor-pointer p-2 text-red-700"
          />
        </button>
      </div>
    );
  }
}

function getSelectRenewalPricebookItemIDSection(
  renewalPricebookItem: ExistingPriceBookItem | null,
  renewalPricebookIDSectionOpen: boolean,
  setRenewalPricebookIDSectionOpen: Dispatch<SetStateAction<boolean>>,
  onFilterTextBoxChanged: (searchTerm: string) => Promise<void>,
  pbItemsFromTypesense: ExistingPriceBookItem[],
  handleSetRenewalPricebookItemID: (
    tempEstimateItem: TemporaryEstimateItem,
  ) => void,
  siteKeyDoc: ExistingSiteKey | null,
  setRenewalPricebookItem: Dispatch<
    SetStateAction<ExistingPriceBookItem | null>
  >,
): JSX.Element {
  const currency = siteKeyDoc?.customizations.accounting?.currency ?? "USD";

  if (renewalPricebookItem == null) {
    if (renewalPricebookIDSectionOpen === false) {
      return (
        <BaseButtonPrimary
          type="button"
          onClick={() => setRenewalPricebookIDSectionOpen(true)}
          className="w-full text-primary sm:w-auto"
        >
          <AddCircleIcon fontSize="small" className="mr-2" />
          {strings.SELECT_RENEWAL_PRICEBOOK_ITEM}
        </BaseButtonPrimary>
      );
    } else {
      return (
        <div className="mb-4">
          <AddNewEstimateItemSelection
            onSearch={onFilterTextBoxChanged}
            PBItemQueryResultList={pbItemsFromTypesense}
            handleAddEstimateItem={handleSetRenewalPricebookItemID}
            currency={currency}
          />
        </div>
      );
    }
  } else {
    return (
      <div className="relative grid grid-cols-[5fr,2fr,1fr] rounded-2xl border p-4 pt-5">
        <div className="absolute -top-4 left-3 bg-white px-2">
          Renewal Pricebook Item ID
        </div>
        {/* TITLE AND DESCRIPTION */}
        <div className="flex w-full items-center">
          <div className="flex flex-col space-y-2">
            <span className="block whitespace-normal text-base font-medium leading-5">
              {renewalPricebookItem.title}
            </span>
            <span className="block whitespace-normal text-sm leading-5 text-gray-400">
              {renewalPricebookItem.description}
            </span>
            {renewalPricebookItem.discountable === false && (
              <p className={`text-sm italic`}>* Non-discountable</p>
            )}
          </div>
        </div>
        {/* AMOUNT */}
        <div className="flex items-center justify-self-end">
          <div className="text-lg font-bold">
            {currencyFormatter(renewalPricebookItem.unitPrice, currency)}
          </div>
        </div>
        {/* DELETE BUTTON */}
        <button
          className="h-fit w-fit place-self-center justify-self-end rounded-full hover:bg-red-50"
          onClick={() => setRenewalPricebookItem(null)}
        >
          <TrashIcon
            aria-label="delete button"
            className="block h-10 w-10 cursor-pointer p-2 text-red-700"
          />
        </button>
      </div>
    );
  }
}

function getAddTaskGenerationSection(
  setAddTaskGenerationDialogOpen: Dispatch<SetStateAction<boolean>>,
  taskGenerationList: TemporaryTaskGeneration[],
  deleteTask: (id: string) => void,
  editTask: (id: string) => void,
) {
  return (
    <div className="col-span-2 grid grid-cols-3 gap-4">
      <BaseButtonPrimary
        type="button"
        onClick={() => setAddTaskGenerationDialogOpen(true)}
        className="col-span-3 w-fit"
      >
        {strings.ADD_TASK_TEMPLATE}
      </BaseButtonPrimary>
      {taskGenerationList.map((task) => {
        return (
          <div
            key={task.id}
            className="relative grid grid-cols-[5fr,2fr,1fr] rounded-2xl border p-4 pt-5"
          >
            <div className="flex w-full items-center">
              <div className="flex flex-col space-y-2">
                <span className="block whitespace-normal text-base font-medium leading-5">
                  <span className="text-gray-400">Job: </span>
                  {getReadableCraftType(task.craftType)}
                </span>
                <span className="block whitespace-normal text-base font-medium leading-5">
                  <span className="text-gray-400">Type: </span>
                  {getReadableTaskType(task.taskType)}
                </span>
                <span className="block whitespace-normal text-base font-medium leading-5">
                  <span className="text-gray-400">Status: </span>
                  {getReadableTaskStatus(task.taskStatus)}
                </span>
              </div>
            </div>
            <div className="flex items-center justify-self-end">
              <span className="block whitespace-normal text-sm leading-5 text-gray-400">
                {task.description}
              </span>
            </div>

            {/* ACTION BUTTONS */}
            <div className="ml-2 flex flex-col">
              <button
                type="button"
                className="h-fit w-fit place-self-center justify-self-end rounded-full hover:bg-primaryOpacity90"
                onClick={() => editTask(task.id)}
              >
                <PencilIcon
                  aria-label="edit button"
                  className="block h-10 w-10 cursor-pointer p-2 text-gray-700"
                />
              </button>
              <button
                type="button"
                className="h-fit w-fit place-self-center justify-self-end rounded-full hover:bg-red-50"
                onClick={() => deleteTask(task.id)}
              >
                <TrashIcon
                  aria-label="delete button"
                  className="block h-10 w-10 cursor-pointer p-2 text-red-700"
                />
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}
